import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export const groupDataCollectorsExportTooltipContent: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>Use this button to export data collectors in this group.</Text>
  </Box>
)

export const groupDetailsTooltipContent: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>This page allows you to view and edit the details of this group.</Text>
  </Box>
)

export const groupDataCollectorsTooltipContent: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>This page allows you to view and manage the data collectors in this group.</Text>
  </Box>
)

export const groupProjectsTooltipContent: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>This page allows you to view and manage the projects in this group.</Text>
  </Box>
)

export const groupTasksReportedTooltipContent: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>This page allows you to view and manage the tasks reported in this group.</Text>
  </Box>
)

export const addDatacollectorToGroupTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      Add an <b>existing Data Collector</b> to this Group. Remember, Data Collectors can only be
      assigned to one Group.
    </Text>
  </Box>
)

export const createDatacollectorToGroupTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      <b>Create</b> a <b>New Data Collector</b> and add them to this Group. If you have already
      created your Data Collector and want to add them to this Group, select Add Data Collector.
    </Text>
  </Box>
)
