import { Flex, Textarea, TextareaProps } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { MdTextFields } from 'react-icons/md'
import { Text } from '../../../../typography'
import { FieldContainer } from '../components'
import { LabelProps } from '../styles'

export type ConnectedTextareaProps = LabelProps &
  TextareaProps & {
    label?: string
    showIsValidValue?: boolean
    name: string
    hint?: string
    leftIcon?: React.ElementType
  }

const ConnectedTextarea: React.FC<ConnectedTextareaProps> = ({
  label,
  showIsValidValue,
  hint,
  leftIcon,
  ...rest
}) => {
  const [{ onBlur: onFieldBlur, ...field }, meta] = useField(rest.name)
  const [isFocused, setIsFocused] = React.useState(false)
  const showError = meta.touched && meta.error
  const inputRef = React.useRef<HTMLTextAreaElement>(null)
  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FieldContainer
        leftIcon={leftIcon ?? MdTextFields}
        isFocused={isFocused}
        showError={showError}
        inputRef={inputRef}
        setIsFocused={setIsFocused}
        label={label}
        name={field.name}
      >
        <Textarea
          {...field}
          fontSize="sm"
          resize="none"
          onFocus={() => {
            setIsFocused(true)
          }}
          focusBorderColor="accent.500"
          ref={inputRef}
          id={field.name}
          {...rest}
          onBlur={(e) => {
            setIsFocused(false)
            onFieldBlur(e)
          }}
        />
      </FieldContainer>
      {!showError && hint ? (
        <Text color="text.300" textAlign="right" fontSize="0.8rem">
          {hint}
        </Text>
      ) : null}
      {showError ? (
        <Text color="red.500" fontSize="xs" textAlign="left">
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  )
}

export default ConnectedTextarea

ConnectedTextarea.defaultProps = {
  mb: 2,
  variant: 'unstyled'
}
