import { useToast } from '@chakra-ui/react'
import { TasksTable } from 'components'
import { ERROR_TOAST, taskFilters } from '../../../../constants'

import { TaskEntity, useGetTasksQuery } from 'generated/graphql'
import React from 'react'
import { useParams } from 'react-router-dom'

const Tasks: React.FC = () => {
  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(0)

  const { id } = useParams<{ id: string }>()
  const toast = useToast()

  const onError = (response: any) => {
    const errorMessage = response.message
    toast({ title: errorMessage, ...ERROR_TOAST })
  }
  const {
    data: tasks,
    loading: tasksLoading,
    refetch
  } = useGetTasksQuery({
    onError,
    variables: {
      filters: {
        baorider: { id },
        id: { $contains: search }
      },
      pagination: {
        start,
        limit
      }
    },
    fetchPolicy: 'cache-and-network'
  })
  const data = tasks?.getBaoleaderTasksWithCount?.data
  const countTasks = tasks?.getBaoleaderTasksWithCount?.count

  const onFilter = async (where: { [key: string]: string | number | string[] }) => {
    try {
      await refetch({ filters: { ...where, baorider: { id } } })
    } catch (e) {
      console.error(e)
    }
  }
  const onReset = async () => {
    await refetch({ filters: {} })
  }
  return (
    <TasksTable
      loading={tasksLoading}
      data={(data || []) as TaskEntity[]}
      onFilter={onFilter}
      count={countTasks || 0}
      onSearch={(search) => {
        setSearch(search)
      }}
      visibleFilters={taskFilters.filter((filter) => filter !== 'Data Collector')}
      searchValue={search}
      onReset={onReset}
      setLimit={setLimit}
      setPage={setPage}
      currentPage={page}
      start={start}
      setStart={setStart}
      limit={limit}
    />
  )
}

export default Tasks
