import { Tabs, TabList, TabPanels, TabPanel, TabsProps } from '@chakra-ui/react'
import { CustomTab } from 'components'
import React from 'react'

type Tab = {
  title?: string | undefined
  icon?: JSX.Element | undefined
  loading?: boolean | undefined
  bg?: string | undefined
}
type CustomTabProps = {
  tabs: Tab[]
  tabPanels: {
    component: JSX.Element
  }[]
} & Omit<TabsProps, 'children'>

const CustomTabContainer: React.FC<CustomTabProps> = ({ tabs, tabPanels, ...rest }) => {
  return (
    <Tabs colorScheme="green" {...rest}>
      <TabList borderColor="background.200">
        {tabs?.map((tab, index) => (
          <CustomTab tab={tab} key={index} />
        ))}
      </TabList>
      <TabPanels>
        {tabPanels.map((tabPanel, index) => (
          <TabPanel key={index} px={0} pt={6}>
            {tabPanel.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export default CustomTabContainer
