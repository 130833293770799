import { HStack, Skeleton } from '@chakra-ui/react'
import React from 'react'

const CardListLoader: React.FC = () => {
  const arr = Array.from(Array(4).keys())
  const innerArr = Array.from(Array(4).keys())
  return (
    <>
      {arr.map((item) => (
        <HStack mb={24} spacing={8} key={item}>
          <HStack spacing={6} w="100%">
            {innerArr.map((item) => (
              <Skeleton rounded="sm" key={item} height="160px" w="100%" />
            ))}
          </HStack>
        </HStack>
      ))}
    </>
  )
}

export default CardListLoader
