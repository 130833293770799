import {
  IconButton,
  ModalProps,
  Modal,
  ModalContent,
  ModalOverlay,
  Heading,
  Image,
  Container,
  Center,
  Box
} from '@chakra-ui/react'
import * as React from 'react'
import { X } from 'react-feather'
import { images } from '../../../theme'
import Card from '../../UI/Card'
import CardHeader from '../../UI/Card/CardHeader'
import { HStack } from '@chakra-ui/react'

type ModalWrapProps = ModalProps & {
  title: string
  image?: any
  rightElement?: React.ReactNode
}

const ModalWrap: React.FC<ModalWrapProps> = ({
  children,
  title,
  onClose,
  isOpen,
  image,
  rightElement,
  ...rest
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay bg="blackAlpha.500" backdropFilter="blur(2px) hue-rotate(90deg)" />
      <ModalContent shadow="none" justifyContent="center" bg="transparent">
        <Card
          overflow="auto"
          bg="gray.100"
          rounded="xl"
          borderWidth={0}
          justifyContent="flex-start"
        >
          <Container py={6} maxW="container.lg">
            <CardHeader
              border="none"
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Heading as="h5" size="lg">
                {title}
              </Heading>
              <IconButton onClick={onClose} aria-label="Close Modal" size="sm" icon={<X />} />
            </CardHeader>
            <HStack spacing={8}>
              <Box pb={8} flex={1.4}>
                {children}
              </Box>
              {rightElement ? (
                rightElement
              ) : (
                <Center flex={1}>
                  <Image
                    rounded="2xl"
                    draggable="false"
                    objectFit="contain"
                    src={image ? image : images.logo}
                    boxSize="45%"
                  />
                </Center>
              )}
            </HStack>
          </Container>
        </Card>
      </ModalContent>
    </Modal>
  )
}

export default ModalWrap

ModalWrap.defaultProps = {
  title: 'Modal Heading',
  size: '6xl',
  motionPreset: 'scale',
  isCentered: true,
  preserveScrollBarGap: true
}
