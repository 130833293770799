import { Checkbox, HStack, IconButton, useDisclosure, Text } from '@chakra-ui/react'
import { AlertModal } from 'components'

import React from 'react'
import { VscClose, VscAdd } from 'react-icons/vsc'
import { Enum_Datalist_Status, DataListEntity } from '../../../../../../../generated/graphql'

type ListItemProps = {
  onClick: () => void
  el: DataListEntity
  isActive: boolean
  onChange: () => void
  deactivateList: (id: string) => Promise<void>
  activateList: (id: string) => Promise<void>
}

const ListItem: React.FC<ListItemProps> = ({
  onClick,
  deactivateList,
  isActive,
  onChange,
  el,
  activateList
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isActiveOpen, onOpen: onActiveOpen, onClose: onActiveClose } = useDisclosure()
  return (
    <HStack
      rounded="lg"
      cursor="pointer"
      bg={isActive ? 'green.50' : 'background.100'}
      w="100%"
      px={4}
      py={2}
      key={el?.id}
    >
      <AlertModal
        onConfirm={() => {
          deactivateList(el?.id || '')
          onClose()
        }}
        isOpen={isOpen}
        title="Archive List"
        confirmButtonText="Archive"
        activatorStatus="deactivate"
        onClose={onClose}
      >{`Deactivate ${el?.attributes?.name}`}</AlertModal>
      <AlertModal
        onConfirm={() => {
          activateList(el?.id || '')
          onClose()
        }}
        title="Activate"
        activatorStatus="activate"
        isOpen={isActiveOpen}
        onClose={onActiveClose}
      >{`Activate ${el?.attributes?.name}`}</AlertModal>
      <Checkbox
        size="lg"
        display="flex"
        isChecked={isActive}
        onChange={onChange}
        colorScheme="green"
        borderRadius="md"
        cursor="pointer"
      />
      <Text
        fontWeight="500"
        _before={{
          content: '""',
          boxSize: 1.5,
          display: 'inline-flex',
          mr: 2,
          borderRadius: 'full',
          bg: el?.attributes?.status === Enum_Datalist_Status.Active ? `green.300` : `red.200`
        }}
        p={2}
        onClick={onClick}
        w="100%"
        fontSize="xs"
      >
        {el?.attributes?.name}
      </Text>

      {el?.attributes?.status === Enum_Datalist_Status.Active ? (
        <IconButton
          aria-label="Delete"
          size="xs"
          onClick={onOpen}
          colorScheme="red"
          icon={<VscClose />}
        />
      ) : (
        <IconButton
          aria-label="Add"
          size="xs"
          onClick={onActiveOpen}
          colorScheme="green"
          icon={<VscAdd />}
        />
      )}
    </HStack>
  )
}

export default ListItem
