import { Flex, Heading } from '@chakra-ui/react'
import React from 'react'

import { useCountCompanyMembersQuery } from '../../generated/graphql'
import { CustomTabContainer, PageListContainer } from '../../layouts'

import { CompanyDetails, Team, SettingsTab } from './components'

import { pluralHandler } from 'utils'

import { FaCreditCard, FaUsers } from 'react-icons/fa'
import { FcAbout, FcElectricalSensor, FcSettings } from 'react-icons/fc'
import { useLocation } from 'react-router-dom'
import SubscriptionTab from './components/SubscriptionTab'
import { useIsSubscribed } from 'hooks'

import { companyToolTip, settingsToolTip, tasksHelpPopover, teamToolTip } from './help'

const Settings: React.FC = () => {
  const { data: countMembers, loading, refetch: refetchMemberCount } = useCountCompanyMembersQuery()
  const count = countMembers?.countCompanyMembers

  // get the tab index from the url and transform it to an integer
  const location = useLocation()
  const tabIndex = parseInt(new URLSearchParams(location.search).get('tab') || '0')
  const { isManual } = useIsSubscribed()
  const onRefetch = React.useCallback(async () => {
    await refetchMemberCount()
  }, [refetchMemberCount])

  const TAB_OPTIONS = [
    {
      title: 'Settings',
      icon: <FcSettings />,
      loading,
      bg: 'background.100',
      tooltip: { label: settingsToolTip }
    },
    {
      title: 'Company Details',
      icon: <FcAbout />,
      loading,
      bg: 'background.100',
      tooltip: { label: companyToolTip }
    },
    {
      title: 'Team',
      icon: <FaUsers />,
      loading,
      bg: 'background.100',
      tooltip: { label: teamToolTip }
    },
    {
      ...(!isManual && {
        title: 'Subscription',
        icon: <FaCreditCard />,
        loading,
        bg: 'background.100'
      })
    }
  ]
  const tabPanels = [
    {
      component: <SettingsTab />
    },
    {
      component: <CompanyDetails />
    },
    {
      component: <Team onCreateMember={onRefetch} />
    },
    {
      component: <SubscriptionTab />
    }
  ]
  const stats = React.useMemo(
    () => [
      {
        title: 'Company Members',
        loading,
        icon: FcElectricalSensor,
        value: `${count || 0} ${pluralHandler('Member', count || 0)}`
      }
    ],
    [loading, count]
  )

  return (
    <PageListContainer
      title="Settings"
      showNoProjectBanner={false}
      icon={<FcSettings />}
      loading={loading}
      stats={stats}
    >
      <Flex py={8} justify="left" align="center">
        <Heading size="xl" color="gray.600">
          Settings{' '}
        </Heading>
        {tasksHelpPopover}
      </Flex>

      <CustomTabContainer
        tabs={TAB_OPTIONS}
        defaultIndex={tabIndex >= 0 && tabIndex < TAB_OPTIONS.length ? tabIndex : 0}
        isLazy
        tabPanels={tabPanels}
      />
    </PageListContainer>
  )
}

export default Settings
