import styled from '@emotion/styled'
import { color, space, SpaceProps } from 'styled-system'
import { theme } from '../../../../theme'

export const DatePickerSelect = styled.select<SpaceProps>`
  ${space};
  height: 36px;
  font-size: small;
`

export const RangeDatePickerWrapper = styled.div`
  width: 100%;
  border-radius: 0.25rem;

  .DayPickerNavigation_button {
    margin-top: 0.5rem;
    border: none;
  }
  .CalendarDay {
    border: none;
    font-size: small;
  }
  .DateRangePicker {
    position: relative;
    display: flex;
    width: 100%;
    > div {
      width: 100%;
    }
  }
  .DateInput {
    flex: 1;
    background-color: transparent;
  }
  .DateRangePickerInput {
    display: flex;
    background-color: ${theme.colors.gray[100]};
  }
  .DateInput_input {
    background-color: transparent;
    display: flex;
    text-align: center;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: small;
    font-weight: 400;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray[100]};
  }
  .DateRangePickerInput_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
  }
  .CalendarDay__selected_span {
    background: #4a8652;
    border: 1px double #2c6734;
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #2c6734;
    border: 1px double #508a58;
    color: #fff;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #2c6734;
    border: 1px double #508a58;
    color: #fff;
  }
`

export const DatePickerWrapper = styled.div`
  width: 100%;
  .DateInput {
    display: flex;
    width: 100%;
  }
  .DateInput_input {
    ${color};
    display: flex;
    font-weight: 400;
    border-radius: 6px;
    font-style: normal;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    outline: none;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0.25rem;
    border: none;
    background-color: #fff;
    &:hover {
      border-color: ${theme.colors.gray[300]};
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  .DateInput_input__focused {
    box-shadow: 0 0 0 1px ${theme.colors.brand[500]};
    border-color: ${theme.colors.brand[500]};
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${theme.colors.brand[500]};
    border: 1px double ${theme.colors.brand[500]};
    color: #fff;
  }
  .SingleDatePicker {
    display: flex;
  }
  .SingleDatePicker_picker {
    z-index: 9999;
  }
  .SingleDatePicker > div,
  .SingleDatePicker > div > .SingleDatePickerInput {
    width: 100%;
  }
  .SingleDatePickerInput__withBorder {
    border: none;
    height: 40px;
  }
  .CalendarMonth_caption {
    padding-top: 16px;
  }
`
