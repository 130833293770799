import { Box, Tooltip, useDisclosure, useToast, Text, HStack } from '@chakra-ui/react'

import React from 'react'

import { MdAdd } from 'react-icons/md'
import { AlertIndicator, CardListLoader, EmptyListHandler, Pagination } from '../../../components'

import {
  useCreateBaoleaderMutation,
  UsersPermissionsUser,
  useGetCompanyLeadersQuery,
  UsersPermissionsUserEntity,
  useGetCompanyLeadersCountQuery,
  Enum_Userspermissionsuser_Status,
  useGetCompanyInfoQuery
} from '../../../generated/graphql'

import SettingsWrapper from './SettingsWrapper'

import UserCard from './UserCard'
import { SimpleGrid } from '@chakra-ui/react'
import { SUCCESS_TOAST, ERROR_TOAST, LEADER } from '../../../constants'
import { get } from 'lodash'
import AddUserModal from './AddUserModal'
import { PrimaryButton } from 'components/UI/Buttons'
import { useHistory } from 'react-router-dom'

const Team: React.FC<{
  onCreateMember?: () => Promise<void>
}> = ({ onCreateMember }) => {
  const history = useHistory()
  const toast = useToast()
  const [limit, setLimit] = React.useState(() => (history.location.state as any)?.limit || 12)
  const [start, setStart] = React.useState(() => (history.location.state as any)?.currentPage || 0)

  const pagination = { limit, start }

  const { data: leadersCount } = useGetCompanyLeadersCountQuery({
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    }
  })

  const {
    data,
    refetch,
    loading: leadersLoading
  } = useGetCompanyLeadersQuery({
    variables: {
      pagination
    },
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    },
    fetchPolicy: 'cache-first'
  })
  const members = data?.getCompanyLeaders || []
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [createBaoleader, { loading }] = useCreateBaoleaderMutation({
    onCompleted: async () => {
      toast({ title: LEADER + ' created.', ...SUCCESS_TOAST })
      await refetch()
    },
    onError: (err) => {
      toast({
        title: get(err, 'graphQLErrors[0].message', 'Unable to add ' + LEADER + '.'),
        ...ERROR_TOAST
      })
    }
  })

  const count = leadersCount?.getCompanyLeadersCount || 0
  const addBaoleader = async (
    baoleader: UsersPermissionsUser & { community: string; title: string }
  ) => {
    try {
      return await createBaoleader({
        variables: {
          input: {
            firstName: baoleader?.firstName || '',
            lastName: baoleader?.lastName || '',
            email: baoleader?.email || '',
            role: baoleader?.role?.data?.id || '',
            title: baoleader?.title || ''
          }
        }
      })
    } catch (error) {
      return
    }
  }

  const { data: companyInfo } = useGetCompanyInfoQuery()
  const [showDataCollectorLimitAlert, setShowDataCollectorLimitAlert] = React.useState(false)
  const [showLimitReachedAlert, setShowLimitReachedAlert] = React.useState(false)
  const [teamMembersAllowed, setTeamMembersAllowed] = React.useState(0)
  const [numberOfActiveDataCollectors, setNumberOfActiveDataCollectors] = React.useState(0)
  const [numberOfActiveTeamMembers, setNumberOfActiveTeamMembers] = React.useState(0)

  const tooltipContent = (
    <Box px={4} py={6}>
      <Text>User this button to add new admins to the system.</Text>
    </Box>
  )

  React.useEffect(() => {
    if (companyInfo) {
      const numberOfTeamMembersAllowed =
        companyInfo.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data?.attributes
          ?.ProductFeature?.baoriders_allowed || 10

      const activeTeamMembers =
        companyInfo.getCompanyInfo?.team_members?.data.filter((member) => {
          return member.attributes?.status === Enum_Userspermissionsuser_Status.Active
        }) || []

      const numberOfActiveDataCollectors = activeTeamMembers.filter((member) => {
        return member.attributes?.role?.data?.attributes?.type === 'baorider'
      }).length

      const numberOfActiveTeamMembers = activeTeamMembers.length

      if (numberOfTeamMembersAllowed - 1 === numberOfActiveTeamMembers) {
        setShowDataCollectorLimitAlert(true)
      } else if (numberOfTeamMembersAllowed <= numberOfActiveTeamMembers) {
        setShowLimitReachedAlert(true)
      }

      setNumberOfActiveDataCollectors(numberOfActiveDataCollectors)
      setNumberOfActiveTeamMembers(numberOfActiveTeamMembers)
      setTeamMembersAllowed(numberOfTeamMembersAllowed)
    }
  }, [companyInfo])

  return (
    <>
      {showDataCollectorLimitAlert && (
        <AlertIndicator my={5} status="info">
          You currently have {numberOfActiveDataCollectors} active data collectors and{' '}
          {numberOfActiveTeamMembers - numberOfActiveDataCollectors} active administrators. You can
          add {teamMembersAllowed - numberOfActiveTeamMembers} more team member(s) to your account
          on your current subscription plan. You can add as many users as you like to your
          Subscription - Adding users over the number included in your subscription will cost an
          additional £
          {companyInfo?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data
            ?.attributes?.ProductFeature?.cost_per_baorider || 9}{' '}
          per team member, per month.
        </AlertIndicator>
      )}
      {showLimitReachedAlert && (
        <AlertIndicator my={5} status="info">
          You have reached the maximum number of active team members allowed on your current
          subscription plan. Adding more team members will cost £
          {companyInfo?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data
            ?.attributes?.ProductFeature?.cost_per_baorider || 9}{' '}
          / team member, per month.
        </AlertIndicator>
      )}
      <SettingsWrapper
        heading="Team"
        leftHeader={
          <Tooltip
            bg={'white'}
            textColor={'blackAlpha.900'}
            label={tooltipContent}
            hasArrow
            placement="left"
          >
            <HStack>
              <PrimaryButton onClick={() => onOpen()} leftIcon={<MdAdd />}>
                Add user
              </PrimaryButton>
            </HStack>
          </Tooltip>
        }
      >
        {leadersLoading ? (
          <CardListLoader />
        ) : !members?.length ? (
          <EmptyListHandler subTitle="" />
        ) : (
          <SimpleGrid columnGap={8} rowGap={20} templateColumns="repeat(4, 1fr)">
            {members.map((member) => (
              <UserCard
                key={member?.id}
                refetch={refetch}
                user={member as UsersPermissionsUserEntity}
              />
            ))}
          </SimpleGrid>
        )}

        <Box py={16}>
          <Pagination
            setLimit={setLimit}
            start={start}
            setStart={setStart}
            count={count}
            limit={limit}
          />
        </Box>

        <AddUserModal
          {...{ isOpen, onOpen, onClose, loading }}
          additionalCost={
            companyInfo?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data
              ?.attributes?.ProductFeature?.cost_per_baorider || 9
          }
          teamMembersRemaining={teamMembersAllowed - numberOfActiveTeamMembers}
          onSubmit={async (baoleader) => {
            try {
              const member = await addBaoleader(baoleader)
              await onCreateMember?.()

              return member
            } catch (e) {
              console.log(e)
            }
          }}
        />
      </SettingsWrapper>
    </>
  )
}

export default Team
