import { Box, Text, Link } from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'

export const groupTitleHelperPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        The <b>Groups</b> page is where you can <b>create, view</b> and <b>manage</b> all your
        <b>Groups</b>. Your <b>Groups</b> are a way of <b>organising</b> your <b>Data Collectors</b>{' '}
        into <b>Teams, Communities</b> that you work with or perhaps groups of <b>Volunteers</b>.
        When Data Collectors are assigned to Groups, you can see all the Tasks that the members of
        your Group have submitted in one place. You can also monitor the projects and SDGs that your
        Group is contributing to. Data Collectors can only be assigned to one Group at a time. This
        is an optional feature - use it in the way that works best for your organisation.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const createGroupTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      <b>Use</b> this button to <b>create</b> a new group.
    </Text>
  </Box>
)
