import { Image, Text, Heading } from '@chakra-ui/react'
import * as React from 'react'
import { images } from '../../../theme'
import Card from '../../UI/Card'
import RevealFlex from '../../layouts/RevealFlex/index'
import { CardProps } from '../../UI/Card/index'

type EmptyListHandlerProps = {
  title?: string
  subTitle?: string
  image?: any
  withImage?: boolean
  action?: React.ReactNode
} & CardProps

const EmptyListHandler: React.FC<EmptyListHandlerProps> = ({
  title,
  subTitle,
  image,
  withImage,
  action,
  ...rest
}) => {
  return (
    <Card
      px={4}
      py={withImage ? 16 : 0}
      flex={1}
      width="100%"
      align="center"
      maxWidth="100%"
      shadow="none"
      justify="center"
      border="none"
      flexDirection="column"
      {...rest}
    >
      <RevealFlex>
        {withImage ? (
          <Image
            src={image ? image : images.empty}
            draggable={false}
            boxSize={80}
            maxWidth="100%"
            height="auto"
          />
        ) : (
          <></>
        )}

        <Heading as="h2" my={3} textAlign="center" fontSize="2xl" fontWeight="semibold">
          {title}
        </Heading>
        <Text fontSize="sm" as="h4" textAlign="center">
          {subTitle}
        </Text>
        {action}
      </RevealFlex>
    </Card>
  )
}

export default EmptyListHandler

EmptyListHandler.defaultProps = {
  title: 'Nothing to see here, yet.',
  subTitle: 'Go ahead and create your first record to get started.',
  withImage: true,
  action: <></>,
  bg: 'transparent'
}
