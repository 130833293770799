import React from 'react'

import { Laptop } from './components'

type MenuProps = {}

const Menu: React.FC<MenuProps> = ({}) => {
  return <Laptop />
}

export default Menu
