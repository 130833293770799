import { Avatar, AvatarBadge, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useAppContext } from '../../../../context/AppProvider'
import { useAuthContext } from '../../../../context/AuthProvider'
import { MenuItem, Tooltip } from './styles'
import { useHistory } from 'react-router-dom'

export const MenuUserProfile = () => {
  const { user } = useAuthContext()
  const { drawerOpen } = useAppContext()
  const history = useHistory()
  const { isLive } = useAppContext()
  return (
    <Flex
      align="center"
      justify={!drawerOpen ? 'center' : undefined}
      width="100%"
      shadow={!drawerOpen ? 'md' : undefined}
      bg="transparent"
      onClick={() => history.push('/auth/settings')}
      cursor="pointer"
    >
      <Avatar
        src={user?.attributes?.profilePicture?.data?.attributes?.url}
        size="sm"
        name={`${user?.attributes?.firstName} ${user?.attributes?.lastName}`}
        mr={2}
      >
        <AvatarBadge boxSize=".8rem" bg={isLive ? 'green.500' : 'red.300'} />
      </Avatar>

      {drawerOpen && (
        <VStack width="100%" spacing={0} alignItems="flex-start" ml={2}>
          <Heading wordBreak="break-all" noOfLines={1} as="h4" fontSize="sm">
            {user?.attributes?.firstName} {user?.attributes?.lastName}
          </Heading>
          <Text color="gray.600" wordBreak="break-all" noOfLines={1} fontSize="x-small">
            {user?.attributes?.email}
          </Text>
        </VStack>
      )}
    </Flex>
  )
}

type SideBarItemProps = {
  title: string
  to: string
  color: string
  hoverColor: string
  accentColor: string
  icon: React.ReactNode
  tooltipColor?: string
  tooltipBg?: string
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  accentColor,
  color,
  hoverColor,
  icon,
  title,
  to,
  tooltipColor,
  tooltipBg
}) => {
  const { drawerOpen } = useAppContext()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const variants = {
    open: {
      x: 0,
      transition: {
        x: { stiffness: 200, velocity: -100 }
      }
    },
    closed: {
      x: isTabletOrMobile ? -50 : 0,
      transition: {
        x: { stiffness: 200 }
      }
    }
  }

  return (
    <MenuItem
      to={to}
      color={color}
      bg={accentColor}
      variants={variants}
      hovercolor={hoverColor}
      accentcolor={accentColor}
      activeClassName="active-nav-link"
    >
      <Flex
        height="50px"
        alignItems="center"
        justifyContent="center"
        className="sidebar-nav-item-wrapper"
      >
        <Flex className="icon-wrap">{icon}</Flex>
        <AnimatePresence>
          {drawerOpen && (
            <Text ml={4} color={color} fontWeight="semibold" isTruncated fontSize="xs">
              {title}
            </Text>
          )}
        </AnimatePresence>
        {!drawerOpen && !isTabletOrMobile && (
          <Tooltip bg={tooltipBg || 'gray.800'} className="sidebar-tooltip">
            <Text fontSize={13} fontWeight="semibold" color={tooltipColor || 'white'}>
              {title}
            </Text>
          </Tooltip>
        )}
      </Flex>
    </MenuItem>
  )
}

export default SideBarItem
