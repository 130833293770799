import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Select,
  SelectProps
} from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { Text } from '../../../../typography'
import { LabelProps } from '../styles'
import { useState } from 'react'
import { sortBy } from 'lodash'
export type OptionType = {
  name: string
  code: any
  flag: any
}

export type ConnectedPhoneInputProps = LabelProps &
  SelectProps & {
    label?: string
    showIsValidValue?: boolean
    name: string
    hint?: string
    country: any
    options: OptionType[]
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    resetNumber?: boolean
  }
const codeProps = {
  fontSize: 'xs',
  fontWeight: 'bold',
  ml: 2
}
const ConnectedPhoneInput: React.FC<ConnectedPhoneInputProps> = ({
  label,
  country,
  options,
  hint,
  setFieldValue,
  resetNumber,
  ...rest
}) => {
  const [{ onBlur: onFieldBlur, ...field }, meta] = useField(rest.name)
  const [selectedCountry, setSelectedCountry] = useState(country || '')
  const [code, setCode] = useState(country?.code?.root + country?.code?.suffixes || '')
  const [number, setNumber] = useState('')
  const [isFocused, setIsFocused] = React.useState(false)
  const showError = meta.touched && meta.error
  const inputRef = React.useRef<HTMLInputElement>(null)
  React.useEffect(() => {
    const fullNumber = `${code}${number && number.startsWith('0') ? number.substring(1) : number}`
    setFieldValue(field.name, fullNumber)
  }, [code, number])

  React.useEffect(() => {
    setNumber('')
  }, [resetNumber])

  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FormControl>
        <Flex
          rounded="lg"
          bg="gray.100"
          p={1}
          align="center"
          border="2px solid"
          borderColor={isFocused ? 'brand.300' : showError ? 'red.500' : 'transparent'}
        >
          <Box bg="gray.200" mr={3} position="relative" rounded="lg">
            <Select
              top={0}
              left={0}
              bottom={0}
              zIndex={41}
              opacity={0}
              icon={<></>}
              fontSize="sm"
              width="100%"
              size="lg"
              position="absolute"
              id={field.name + '.code'}
              value={field.value ?? ''}
              onChange={(event) => {
                const findCountry = options.find(
                  (selectedCountry) => selectedCountry.name === event.target.value
                )
                const suffixes =
                  findCountry?.code.suffixes != undefined ? findCountry?.code.suffixes : ''

                const fullCode = findCountry?.code.root + suffixes
                setCode(findCountry ? fullCode : '')
                setSelectedCountry(findCountry ? findCountry.flag : event.target.value)
              }}
            >
              {sortBy(options, ['name']).map((country, index) => (
                <option key={index} value={country.name}>
                  {country.name + country.flag}
                </option>
              ))}
            </Select>

            <Flex p={4} width="100%" alignItems="center">
              {selectedCountry?.flag ? (
                <>
                  <Box overflow="hidden" rounded="full">
                    {selectedCountry.flag}
                  </Box>
                  <Text {...codeProps}>{code}</Text>
                </>
              ) : (
                <>
                  <Box overflow="hidden" rounded="full">
                    {selectedCountry}
                  </Box>
                  <Text {...codeProps}>{code}</Text>
                </>
              )}
            </Flex>
          </Box>
          <Box width="100%">
            {label && (
              <FormLabel
                m={0}
                color={isFocused ? 'gray.500' : showError ? 'red.500' : 'gray.400'}
                fontSize="x-small"
                htmlFor={field.name}
              >
                {label}
              </FormLabel>
            )}
            <InputGroup>
              <Input
                type="number"
                id={field.name + '.number'}
                fontSize="sm"
                width="100%"
                variant="unstyled"
                ref={inputRef}
                onFocus={() => {
                  setIsFocused(true)
                }}
                placeholder="e.g. 123456789"
                {...field}
                value={number}
                onBlur={(e) => {
                  setIsFocused(false)
                  onFieldBlur(e)
                }}
                onChange={(e) => {
                  setNumber(e.target.value)
                }}
              />
            </InputGroup>
          </Box>
        </Flex>
        {!showError && hint ? (
          <Text color="text.300" textAlign="right" fontSize="0.8rem">
            {hint}
          </Text>
        ) : null}
        {showError ? (
          <Text color="red.500" fontSize="xs" textAlign="right">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedPhoneInput

ConnectedPhoneInput.defaultProps = {
  mb: 2,
  options: []
}
