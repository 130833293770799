import { Flex, HStack, Text } from '@chakra-ui/react'
import { FormWrapper, FloatingButtonWrapper } from 'components'
import { ConnectedFormGroup, ConnectedPasswordGroup } from 'components/forms/FormElements'
import { useAuthContext } from 'context/AuthProvider'
import { Form, Formik, FormikProps } from 'formik'

import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { theme } from 'theme'
import { formatError } from 'utils'
import * as Yup from 'yup'
import posthog from 'posthog-js'

type RegisterProps = {}

const RegisterFormValidation = Yup.object().shape({
  firstName: Yup.string().required('Your first name is required'),
  lastName: Yup.string().required('Your last name is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('An email address is required'),
  password: Yup.string()
    .min(8, 'Password has to be longer than 8 characters')
    .required('A password is required'),
  confirmPassword: Yup.string()
    .required('Confirm your password')
    .oneOf([Yup.ref('password')], 'Password does not match'),
  companyName: Yup.string().required('Company Name is required')
})

type RegisterValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  companyName: string
}

const Register: React.FC<RegisterProps> = () => {
  const { register, user } = useAuthContext()
  const history = useHistory()

  React.useEffect(() => {
    if (user?.attributes?.confirmed) {
      // this part of the code needs to change to push to the "pre-onboarding" page, where we gather the job role, and payment details
      history.push('/auth/user-management')
    } else if (user) {
      posthog.capture('user_registered')
      history.push('/auth/onboarding')
    }
    // eslint-disable-next-line
  }, [user])

  return (
    <FormWrapper
      title="Register"
      pt={28}
      slideShowStyle={{
        maxW: '30rem'
      }}
    >
      <Flex w="100%" direction="column">
        <Formik
          validationSchema={RegisterFormValidation}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            companyName: ''
          }}
          onSubmit={async (
            { firstName, lastName, email, password, companyName },
            { setStatus, setSubmitting }
          ) => {
            console.log('Registering user')
            setStatus(null)
            try {
              setSubmitting(true)
              if (register) {
                await register(firstName, lastName, email, password, companyName)
              }
              setSubmitting(false)
            } catch (error) {
              setStatus(formatError(error))
            }
          }}
        >
          {({ isSubmitting, status }: FormikProps<RegisterValues>) => (
            <Form style={{ width: '100%', marginTop: '2vh' }}>
              <FloatingButtonWrapper
                isLoading={isSubmitting}
                status={status}
                buttonTitle="Register"
              >
                <HStack mb={2}>
                  <ConnectedFormGroup
                    name="firstName"
                    label="First Name"
                    placeholder="John"
                    mb={0}
                    w="100%"
                  />
                  <ConnectedFormGroup
                    name="lastName"
                    label="Last Name"
                    placeholder="Smith"
                    mb={0}
                    w="100%"
                  />
                </HStack>

                <ConnectedFormGroup name="email" label="Email" placeholder="Email" type="email" />
                <ConnectedFormGroup
                  name="companyName"
                  label="Company Name"
                  placeholder="Company Name"
                  type="text"
                />

                <HStack mb={2}>
                  <ConnectedPasswordGroup
                    name="password"
                    label="Password"
                    placeholder="Password"
                    mb={0}
                  />
                  <ConnectedPasswordGroup
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    mb={0}
                  />
                </HStack>
              </FloatingButtonWrapper>
            </Form>
          )}
        </Formik>
        <Flex mt={16} align="center" justify="center">
          <Text fontSize="sm">
            Already signed up?{' '}
            <Link style={{ color: theme.colors.blue[500] }} to="/">
              Login
            </Link>{' '}
          </Text>
        </Flex>
      </Flex>
    </FormWrapper>
  )
}

export default Register
