import { Heading, Flex, HStack, Box, Image, Text } from '@chakra-ui/react'
import { Card } from 'components'
import { GetBaoleaderTaskQuery } from 'generated/graphql'
import React from 'react'

import { VscCloudDownload } from 'react-icons/vsc'
import { images } from 'theme'
import { getImageByFileExtension } from 'utils'

type EvidenceDocumentsProps = {
  data: GetBaoleaderTaskQuery | undefined
}

const EvidenceDocuments: React.FC<EvidenceDocumentsProps> = ({ data }) => {
  return data?.getBaoleaderTask?.attributes?.documents &&
    data?.getBaoleaderTask?.attributes?.documents?.data?.length > 0 ? (
    <Card p={6} width="100%">
      <Heading mb={4} fontSize="sm" fontWeight="semibold">
        Documents
      </Heading>

      <Box>
        {data?.getBaoleaderTask?.attributes?.documents?.data?.map((file, index) => (
          <a target="_blank" href={file?.attributes?.url} rel="noreferrer" download key={index}>
            <HStack p={4} spacing={2} my={1} rounded="md" bg="gray.100">
              <Image
                src={
                  file?.attributes?.mime?.includes('image/')
                    ? file?.attributes?.formats?.thumbnail?.url || images.logoNoText
                    : getImageByFileExtension(file?.attributes?.name || '')
                }
                objectFit="contain"
                w="1.5rem"
                alt="Document image"
              />

              <Flex align="center" justify="space-between" w="100%">
                <Text fontSize="x-small" isTruncated color="gray.500">
                  {file?.attributes?.name}
                </Text>
                <VscCloudDownload />
              </Flex>
            </HStack>
          </a>
        ))}
      </Box>
    </Card>
  ) : (
    <></>
  )
}

export default EvidenceDocuments
