import { HStack, VStack } from '@chakra-ui/react'
import { SearchBar } from 'components'
import { SecondaryButton } from 'components/UI/Buttons'
import { Enum_Componentfaqfaq_Category } from 'generated/graphql'
import React, { memo } from 'react'

type HelpSearchBarProps = {
  setFilters: React.Dispatch<
    React.SetStateAction<{
      search: string
      category: string
    }>
  >
  filters: {
    search: string
    category: string
  }
}

const HelpSearchBar: React.FC<HelpSearchBarProps> = ({ setFilters, filters }) => {
  return (
    <VStack alignItems="flex-start" w="100%" mb={10}>
      <SearchBar
        variant="flushed"
        onSearch={(text) => {
          setFilters((prev) => ({ ...prev, search: text }))
        }}
      />
      <HStack>
        <SecondaryButton
          height={10}
          onClick={() => {
            setFilters((prev) => ({ ...prev, category: '' }))
          }}
          {...(!filters.category && { backgroundColor: 'green.50' })}
        >
          All
        </SecondaryButton>
        {Object.values(Enum_Componentfaqfaq_Category).map((category) => {
          const isActive = filters.category === category
          return (
            <SecondaryButton
              height={10}
              onClick={() => {
                setFilters((prev) => ({ ...prev, category }))
              }}
              {...(isActive && { backgroundColor: 'green.50' })}
              key={category}
            >
              {category.replace(/_/g, ' ')}
            </SecondaryButton>
          )
        })}
      </HStack>
    </VStack>
  )
}

export default memo(HelpSearchBar, (prev, next) => {
  return (
    prev.filters.search === next.filters.search && prev.filters.category === next.filters.category
  )
})
