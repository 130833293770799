import React from 'react'
import { Box, RadioProps, useRadio } from '@chakra-ui/react'

type RadioCardProps = RadioProps

const RadioCard: React.FC<RadioCardProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        _checked={{
          bg: 'green.300',
          color: 'white',
          borderColor: 'green.500'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        px={4}
        py={5}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default RadioCard
