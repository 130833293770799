import { Flex, useToast, Heading, HStack, Box, Tooltip } from '@chakra-ui/react'
import React from 'react'

import { MdAdd } from 'react-icons/md'
import { Link, useHistory } from 'react-router-dom'
import { pluralHandler } from 'utils'
import { CardListLoader, Pagination, SearchBar } from '../../../components'

import {
  ProjectEntity,
  Maybe,
  useGetProjectsQuery,
  useCountCompanyProjectsQuery
} from '../../../generated/graphql'
import { PageListContainer } from '../../../layouts'

import { EmptyListHandler } from 'components'
import { ERROR_TOAST } from '../../../constants'
import ProjectsGrid from '../../../components/features/ProjectsGrid'
import { PrimaryButton } from 'components/UI/Buttons'
import { FcParallelTasks } from 'react-icons/fc'
import { projectsTitleHelperPopover, createProjectTooltipContent } from './help'

const Projects: React.FC<{
  containerData?: Maybe<ProjectEntity>[]
  canCreate?: boolean
  canSearch?: boolean
  canPaginate?: boolean
  containerCount?: number
}> = ({ containerData, canCreate, containerCount, canSearch, canPaginate }) => {
  const toast = useToast()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const history = useHistory()
  const [limit, setLimit] = React.useState(() => (history.location.state as any)?.limit || 12)
  const [start, setStart] = React.useState(() => (history.location.state as any)?.currentPage || 0)

  const pagination = { limit, start }

  const [search, setSearch] = React.useState('')
  const { data: projectsCount, loading: loadingCount } = useCountCompanyProjectsQuery({
    variables: {
      filters: {
        name: { $contains: search }
      }
    },
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    }
  })
  const { data: projects, loading } = useGetProjectsQuery({
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        name: { $contains: search }
      },
      pagination
    }
  })

  const data = containerData || projects?.getBaoleaderProjects
  const count = containerCount || projectsCount?.countCompanyProjects || 0

  const stats = React.useMemo(
    () => [
      {
        title: 'Projects Overview',
        icon: FcParallelTasks,
        value: `${count} ${pluralHandler('Project', count)}`
      }
    ],
    [loadingCount, count]
  )

  const tooltipTrigger = (
    <HStack>
      <Link to="/auth/create-project">
        <PrimaryButton leftIcon={<MdAdd />}>Create a new project</PrimaryButton>
      </Link>
    </HStack>
  )

  const contents = (
    <>
      <Flex align="center" justify="space-between" w="100%" mb={10}>
        <Flex align="center">
          <Heading as="h3" size="xl" color="gray.600">
            Projects{' '}
          </Heading>
          {projectsTitleHelperPopover}
        </Flex>

        <HStack spacing={6}>
          {canSearch && (
            <SearchBar
              placeholder="Search Project"
              value={search}
              onSearch={(value) => setSearch(value)}
            />
          )}
          {canCreate && (
            <Tooltip
              bg={'white'}
              textColor={'blackAlpha.900'}
              label={createProjectTooltipContent}
              hasArrow
              placement="top"
            >
              {tooltipTrigger}
            </Tooltip>
          )}
        </HStack>
      </Flex>

      {loading ? (
        <CardListLoader />
      ) : !data?.length ? (
        <EmptyListHandler
          title={containerData ? 'No Projects Yet' : 'No Projects'}
          subTitle={
            containerData
              ? 'This page will automatically populate once you add members to the group.'
              : 'No projects found'
          }
        />
      ) : (
        <ProjectsGrid projets={data as Maybe<ProjectEntity>[]} />
      )}
      {canPaginate && (
        <Box py={16}>
          <Pagination
            setLimit={setLimit}
            start={start}
            setStart={setStart}
            count={count}
            limit={limit}
            paginationEntries={[8, 12, 24, 48, 96]}
          />
        </Box>
      )}
    </>
  )

  return containerData ? (
    contents
  ) : (
    <PageListContainer title="Projects" icon={<FcParallelTasks />} loading={loading} stats={stats}>
      {contents}
    </PageListContainer>
  )
}

Projects.defaultProps = {
  canCreate: true,
  canSearch: true,
  canPaginate: true
}

export default Projects
