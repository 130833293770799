import { Box, useDisclosure } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { ModalWrap } from '../../components'
import { Video } from '../../generated/graphql'

type VideoModalProviderProps = {
  setCurrentVideo: (video: Video) => void
  onOpen: () => void
  currentVideo: Video | null
}
const VideoModalContext = React.createContext<Partial<VideoModalProviderProps>>({})

export const useVideoModalContext = () => React.useContext(VideoModalContext)

const VideoPlayerWrapper = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;

  div:nth-of-type(1) {
    height: 100% !important;
    width: 100% !important;
  }
`
const VideoModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentVideo, setCurrentVideo] = useState<Video | null>(null)
  return (
    <VideoModalContext.Provider value={{ setCurrentVideo, onOpen, currentVideo }}>
      <ModalWrap isOpen={isOpen} onClose={onClose} title={currentVideo?.title as string} size="xl">
        {currentVideo && (
          <VideoPlayerWrapper>
            <ReactPlayer
              url={currentVideo?.videoLink?.data?.attributes?.url}
              playing={true}
              width="100%"
              height="100%"
              controls={true}
            />
          </VideoPlayerWrapper>
        )}
      </ModalWrap>
      {children}
    </VideoModalContext.Provider>
  )
}

export default VideoModalProvider
