import {
  SimpleGrid,
  Image,
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Stack,
  VStack,
  Text
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Card, Stat, StatusBadge, TitleValuePair } from 'components'
import GoogleMap from 'lib/map/components/GoogleMap'
import { GetSingleBaoleaderCommunityQuery, Maybe } from 'generated/graphql'
import React from 'react'
import { VscLocation, VscPin } from 'react-icons/vsc'
import { images } from 'theme'

const StyledMap = styled.div`
  width: 100%;
  height: 100%;
  > div,
  > div > div {
    width: 100%;
    height: 100%;
  }
`
type CommunityDetailsProps = {
  storedCommunity: GetSingleBaoleaderCommunityQuery | undefined
  communityLoading: boolean
  infos: (
    | {
        title: string
        value: Maybe<string> | undefined
        loading: boolean
      }
    | {
        title: string
        value: number
        loading: boolean
      }
  )[]
}

const CommunityDetails: React.FC<CommunityDetailsProps> = ({
  storedCommunity,
  communityLoading,
  infos
}) => {
  const logoThumbnail =
    storedCommunity?.getSingleBaoleaderCommunity?.attributes?.featured_image?.data?.attributes
      ?.formats?.thumbnail?.url
  return (
    <SimpleGrid templateColumns={{ md: '2fr 1fr', sm: '1fr' }} spacing={10}>
      <VStack spacing={6}>
        <Card width="100%">
          <Flex direction="column" height="100%" width="100%" flex={1}>
            <Box width="100%" px={6}>
              <Flex w="100%" py={4} direction="row" align="center">
                <Flex w="100%" direction="row" align="center">
                  <Skeleton isLoaded={!communityLoading} rounded="2xl" mr={3}>
                    <Image
                      src={logoThumbnail ? logoThumbnail : images.logoNoText}
                      boxSize={10}
                      draggable="false"
                      loading="lazy"
                      rounded="2xl"
                      objectFit="cover"
                    />
                  </Skeleton>
                  <VStack alignItems="flex-start" spacing={1}>
                    <HStack spacing={4} w="100%">
                      <Heading as="h5" fontWeight="semibold" size="xs">
                        <Skeleton isLoaded={!communityLoading} minW={50}>
                          {storedCommunity?.getSingleBaoleaderCommunity?.attributes?.name}
                        </Skeleton>
                      </Heading>
                      <StatusBadge indicator fontSize="xs">
                        {storedCommunity?.getSingleBaoleaderCommunity?.attributes?.type}
                      </StatusBadge>
                    </HStack>
                    <Skeleton isLoaded={!communityLoading}>
                      <Text fontSize="x-small" noOfLines={2} color="gray.500" width="100%">
                        {storedCommunity?.getSingleBaoleaderCommunity?.attributes?.description}
                      </Text>
                    </Skeleton>
                  </VStack>
                </Flex>
                <Flex direction="row" align="center" justify="flex-end">
                  <Skeleton isLoaded={!communityLoading}></Skeleton>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <SimpleGrid p={8} gap={8} templateColumns="repeat(3, 1fr)">
            {infos.map(({ title, value, loading }, index) => (
              <TitleValuePair title={title} key={index} loading={loading} value={value} />
            ))}
          </SimpleGrid>
        </Card>
        <Card width="100%" overflow="hidden">
          <Stack spacing={1} px={6} pt={4} pb={6} fontSize="xs">
            <Skeleton w="fit-content" isLoaded={!communityLoading}>
              <Heading as="h6" color="gray.600" mb={4} size="sm">
                <Text fontSize="sm">Location</Text>
              </Heading>
            </Skeleton>

            <Flex justify="space-between" align="center" fontSize="xs">
              <Stat
                label="Latitude"
                icon={<VscLocation />}
                loading={communityLoading}
                value={
                  storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location?.latitude || 0
                }
              />
              <Stat
                label="Longitude"
                loading={communityLoading}
                icon={<VscLocation />}
                value={
                  storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location?.longitude || 0
                }
              />
              <Stat
                label="Accuracy"
                loading={communityLoading}
                icon={<VscPin />}
                value={
                  storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location?.accuracy || 0
                }
              />
            </Flex>
          </Stack>
          <Skeleton isLoaded={!communityLoading}>
            <Box
              w="100%"
              overflow="hidden"
              borderRadius="0 0 1rem 1rem"
              minH="385px"
              h="385px"
              background="rgba(0,0,0,0.1)"
            >
              <StyledMap>
                <GoogleMap
                  lat={storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location?.latitude}
                  lng={
                    storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location?.longitude
                  }
                  markers={[
                    ...(storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location
                      ?.latitude &&
                    storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location?.longitude
                      ? [
                          {
                            lat: storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location
                              ?.latitude,
                            lng: storedCommunity?.getSingleBaoleaderCommunity?.attributes?.location
                              ?.longitude
                          }
                        ]
                      : [])
                  ]}
                />
              </StyledMap>
            </Box>
          </Skeleton>
        </Card>
      </VStack>
      <VStack spacing={6}>
        <Skeleton isLoaded={!communityLoading} rounded="2xl">
          <Center>
            <Image
              objectFit="contain"
              rounded="2xl"
              draggable="false"
              loading="lazy"
              w="50%"
              src={logoThumbnail ? logoThumbnail : images.logoNoText}
              alt="featured image"
            />
          </Center>
        </Skeleton>
      </VStack>
    </SimpleGrid>
  )
}

export default CommunityDetails
