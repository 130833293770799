import * as React from 'react'
import _, { find } from 'lodash'
import { Button, Center, Flex, Icon, IconButton, HStack, Switch, Select } from '@chakra-ui/react'
import { FormControl, FormLabel, Input, Text } from '@chakra-ui/react'

import { SupportedDataFieldType, DataFieldPropertyType } from '../types'
import { Card, CustomTooltip } from 'components'
import {
  VscClose,
  VscAdd,
  VscArrowUp,
  VscArrowDown,
  VscListFlat,
  VscQuestion
} from 'react-icons/vsc'
import { X } from 'react-feather'
import { swapArrayLocs } from 'utils'
import { usePresence, motion } from 'framer-motion'
import EditedIndicator from './EditedIndicator'
import { isDifferent } from '../utils/utils'
import { isEqual } from 'lodash'
import { DataListEntity } from '../../../generated/graphql'
import { OptionType } from 'types'
import { OutlineButton } from '../../../components/UI/Buttons/index'
import { requiredFieldHelperTooltip } from '../create/help'

const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1 }

type DynamicInputFieldProps = {
  collectionLength: number
  errors: OptionType | undefined
  setInputFields: React.Dispatch<React.SetStateAction<SupportedDataFieldType[]>>
  inputField: SupportedDataFieldType
  inputIndex: number
  original?: Array<SupportedDataFieldType>
  loading?: boolean
  activeDataLists?: DataListEntity[]
  differences?: any[]
  onDataListOpen?: () => void
  handleRemoveField: (inputIndex: number) => void
  handleDeleteOption: (inputIndex: number, propertyIndex: number, optionIndex: number) => void
  handleOptionLabelChange: (
    event: any,
    inputIndex: number,
    propertyIndex: number,
    optionIndex: number
  ) => void
  handleAddOption: (inputIndex: number, propertyIndex: number) => void
  handleInputChange: (event: any, inputIndex: number, propertyIndex: number) => void
}

const DynamicInputField: React.FC<DynamicInputFieldProps> = ({
  inputField,
  inputIndex,
  errors,
  handleRemoveField,
  collectionLength,
  original,
  setInputFields,
  onDataListOpen,
  handleDeleteOption,
  differences,
  loading,
  handleOptionLabelChange,
  handleAddOption,
  handleInputChange,
  activeDataLists
}) => {
  const moveDown = () => {
    setInputFields((prev) => {
      const arr = [...prev]
      swapArrayLocs(arr, inputIndex, inputIndex + 1)
      return arr
    })
  }
  const moveUp = () => {
    setInputFields((prev) => {
      const arr = [...prev]
      swapArrayLocs(arr, inputIndex, inputIndex - 1)
      return arr
    })
  }

  const [isPresent, safeToRemove] = usePresence()

  const animations = {
    layout: true,
    initial: 'out',
    style: {
      width: '100%'
    },
    animate: isPresent ? 'in' : 'out',
    key: inputIndex,
    position: isPresent ? 'static' : 'absolute',
    variants: {
      in: { opacity: 1, y: 0 },
      out: { opacity: 0, y: '10%', zIndex: 4 }
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition
  }
  const foundIsRequired = find(differences, (field) => field[inputIndex] !== undefined)

  const prevIsRequired =
    original?.[inputIndex]?.properties?.[inputField?.properties?.length - 1]?.isRequired
  const currentIsRequired =
    foundIsRequired?.[inputIndex]?.properties?.[inputField?.properties?.length - 1]?.isRequired
  return (
    <motion.div {...animations}>
      <Card rounded="xl" p={4} mt={12} width="100%">
        <Flex justify="space-between" mt={-6} transform="translateY(-50%)">
          <Center px={3} bg="green.100" border="2px solid white" rounded="lg">
            <Icon as={() => (inputField.icon ? inputField.icon : <VscQuestion size={8} />)} />
            <Text py={1} px={2} fontWeight="500" rounded="lg" fontSize="x-small" textAlign="left">
              {inputField?.display_title}
            </Text>
          </Center>
          <Flex minW="50%" justify="space-between">
            <CustomTooltip label={requiredFieldHelperTooltip}>
              <Center>
                <FormControl
                  onChange={(event: any) => {
                    setInputFields((prev) => {
                      const values = [...prev]
                      // @ts-ignore
                      values[inputIndex].properties[prev[inputIndex]?.properties?.length - 1] = {
                        isRequired: event.target.checked
                      }

                      return values
                    })
                  }}
                  display="flex"
                  mr={10}
                  py={1}
                  px={2}
                  rounded="lg"
                  bg="gray.200"
                  border="2px solid white"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {isDifferent(differences ?? [], `${inputIndex}`) &&
                    !isEqual(prevIsRequired, currentIsRequired) && (
                      <EditedIndicator
                        prev={`${prevIsRequired}`}
                        loading={loading}
                        current={`${currentIsRequired}`}
                      />
                    )}
                  <FormLabel
                    fontSize="x-small"
                    cursor="pointer"
                    htmlFor={`required-field-${inputIndex}`}
                    mb={0}
                    mx={2}
                  >
                    Required
                  </FormLabel>

                  <Switch
                    id={`required-field-${inputIndex}`}
                    isChecked={Boolean(
                      inputField?.properties?.[inputField?.properties?.length - 1]?.isRequired
                    )}
                    defaultChecked={Boolean(
                      inputField?.properties?.[inputField?.properties?.length - 1]?.isRequired
                    )}
                    colorScheme="green"
                    name="isRequired"
                  />
                </FormControl>
              </Center>
            </CustomTooltip>
            <HStack>
              {inputIndex < collectionLength - 1 && (
                <Center
                  bg="gray.100"
                  border="4px solid white"
                  boxSize={8}
                  onClick={moveDown}
                  rounded="full"
                  alignSelf="right"
                >
                  <VscArrowDown style={{ cursor: 'pointer' }} size={10} />
                </Center>
              )}
              {inputIndex > 0 && (
                <Center
                  bg="gray.100"
                  onClick={moveUp}
                  border="4px solid white"
                  boxSize={8}
                  rounded="full"
                  alignSelf="right"
                >
                  <VscArrowUp style={{ cursor: 'pointer' }} size={10} />
                </Center>
              )}
              <HStack>
                <Center
                  bg="red.100"
                  border="5px solid white"
                  boxSize={8}
                  ml={4}
                  onClick={() => handleRemoveField(inputIndex)}
                  rounded="xl"
                  alignSelf="right"
                >
                  <X style={{ cursor: 'pointer' }} size={10} />
                </Center>
              </HStack>
            </HStack>
          </Flex>
        </Flex>
        <Flex flexDirection="row" flexWrap="wrap">
          {(inputField.properties as DataFieldPropertyType[])?.map(
            (property: DataFieldPropertyType, propertyIndex: number) => {
              if (inputField?.properties?.[propertyIndex]?.isRequired !== undefined) return
              if (Object.keys(property)[0] === 'option') {
                const prevLength = (
                  original?.[inputIndex]?.properties?.[propertyIndex].option as OptionType[]
                )?.length
                const currentLength = (property?.option as OptionType[])?.length
                return (
                  <Flex flexDirection="column" flex="100%" key={`property-${propertyIndex}`}>
                    <Flex m={3} flex={'100%'} flexDirection="column">
                      {isDifferent(differences ?? [], `${inputIndex}`) &&
                        !isEqual(prevLength, currentLength) && (
                          <HStack>
                            <EditedIndicator
                              prev={`${prevLength}`}
                              loading={loading}
                              current={`${currentLength}`}
                            />
                            <Text fontSize="x-small">Options</Text>
                          </HStack>
                        )}
                      <Text
                        width="100%"
                        mb={3}
                        color="green.400"
                        fontSize="x-small"
                        fontWeight="500"
                      >
                        Options ({(property?.option as OptionType[])?.length})
                      </Text>

                      {(property['option'] as OptionType[])?.map(
                        (option: OptionType, optionIndex: number) => {
                          const prev = (
                            original?.[inputIndex]?.properties?.[propertyIndex]
                              .option as OptionType[]
                          )?.[optionIndex]?.option
                          const current = option['option']

                          return (
                            <Flex
                              width="45%"
                              key={`option-${optionIndex}`}
                              my={5}
                              pt={5}
                              flexDirection="column"
                              borderRadius="xl"
                              position="relative"
                            >
                              <Flex
                                mt={-2}
                                align="center"
                                justify="space-between"
                                zIndex={123}
                                top={0}
                                left={0}
                                w="100%"
                                position="absolute"
                                transform="translateY(-50%)"
                              >
                                <Center
                                  px={2}
                                  alignSelf="left"
                                  bg="gray.100"
                                  border="5px solid"
                                  borderColor="gray.100"
                                  rounded="md"
                                >
                                  <Icon as={() => inputField.icon} />
                                  <Text fontSize="x-small" ml={2} textAlign="left" fontWeight="500">
                                    Option {optionIndex + 1}
                                  </Text>
                                </Center>
                                <IconButton
                                  aria-label="close"
                                  rounded="md"
                                  border="3px solid"
                                  bg="red.100"
                                  borderColor="gray.100"
                                  size="xs"
                                  onClick={() =>
                                    handleDeleteOption(inputIndex, propertyIndex, optionIndex)
                                  }
                                  icon={<VscClose />}
                                />
                              </Flex>
                              {isDifferent(differences ?? [], `${inputIndex}`) &&
                                !isEqual(prev, current) && (
                                  <EditedIndicator
                                    prev={`${prev}`}
                                    loading={loading}
                                    current={`${current}`}
                                  />
                                )}
                              <Input
                                name={`${inputIndex}-option-${optionIndex}`}
                                value={option['option'] as string}
                                fontSize="xs"
                                variant="filled"
                                px={4}
                                focusBorderColor="green.200"
                                placeholder="Enter option label"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleOptionLabelChange(
                                    event,
                                    inputIndex,
                                    propertyIndex,
                                    optionIndex
                                  )
                                }
                              />
                            </Flex>
                          )
                        }
                      )}
                    </Flex>
                    <Button
                      width={'43%'}
                      ml={3}
                      fontSize="x-small"
                      p={4}
                      variant="ghost"
                      colorScheme="green"
                      leftIcon={<VscAdd />}
                      onClick={() => handleAddOption(inputIndex, propertyIndex)}
                    >
                      Add option
                    </Button>
                  </Flex>
                )
              }
              if (Object.keys(property)[0] === 'listId') {
                const current = `${Object.values(property)[0]}`
                const prev = `${original?.[inputIndex]?.properties[propertyIndex]?.listId}`
                return (
                  <FormControl
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, inputIndex, propertyIndex)
                    }}
                    flex={'0 45%'}
                    m={3}
                    key={propertyIndex}
                  >
                    <FormLabel fontSize="x-small" htmlFor={Object.keys(property)[0]}>
                      Select a list
                    </FormLabel>
                    {isDifferent(differences ?? [], `${inputIndex}`) && !isEqual(prev, current) && (
                      <EditedIndicator prev={`${prev}`} loading={loading} current={`${current}`} />
                    )}
                    <HStack>
                      <Select
                        fontSize="x-small"
                        name={Object.keys(property)[0]}
                        variant="filled"
                        value={Object.values(property)[0] as string}
                        placeholder="Select a list"
                      >
                        {activeDataLists?.map((list) => (
                          <option value={list?.id || ''} key={list?.id}>
                            {list?.attributes?.name}
                          </option>
                        ))}
                      </Select>
                      <OutlineButton
                        variant="outline"
                        colorScheme="green"
                        fontSize="x-small"
                        w={200}
                        onClick={onDataListOpen}
                        leftIcon={<VscListFlat />}
                      >
                        Manage List
                      </OutlineButton>
                    </HStack>
                  </FormControl>
                )
              }
              if (Object.keys(property)[0] === 'number_of_stars') {
                return <></>
              }

              const prev = `${
                Object.values(original?.[inputIndex]?.properties?.[propertyIndex] ?? {})?.[0]
              }`
              const current = `${Object.values(property)[0]}`
              return (
                <FormControl
                  flex={'0 45%'}
                  m={3}
                  key={propertyIndex}
                  isInvalid={!!errors?.[Object.keys(property)[0]]}
                >
                  <FormLabel fontSize="x-small" htmlFor={Object.keys(property)[0]}>
                    {Object.keys(property)[0]}
                  </FormLabel>
                  {isDifferent(differences ?? [], `${inputIndex}`) && !isEqual(prev, current) && (
                    <EditedIndicator prev={`${prev}`} loading={loading} current={`${current}`} />
                  )}

                  <Input
                    name={Object.keys(property)[0]}
                    required={Object.keys(property)[0] === 'metric' ? true : false}
                    fontSize="xs"
                    variant="filled"
                    px={4}
                    focusBorderColor="green.200"
                    value={Object.values(property)[0] as string}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(event, inputIndex, propertyIndex)
                    }}
                  />
                </FormControl>
              )
            }
          )}
        </Flex>
      </Card>
    </motion.div>
  )
}

DynamicInputField.defaultProps = {
  loading: false
}

export default React.memo(DynamicInputField)
