import { Badge, BadgeProps, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { VscLink } from 'react-icons/vsc'
import { Enum_Task_Status, Maybe } from '../../../generated/graphql'
import { statusHelper } from '../../../utils'
import * as VscIcons from 'react-icons/vsc'
import { getStatusLabel } from 'features/datacollectors/utils'

type StatusBadgeProps = BadgeProps & {
  status?: Maybe<Enum_Task_Status> | string
  indicator?: boolean
  withIcon?: boolean
}
const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  indicator,
  children,
  colorScheme,
  onClick,
  withIcon,
  ...rest
}) => {
  const { color, title, icon } = statusHelper(status || '')
  const hasStatus = !indicator && status
  // turn the icon string into a component
  const IconComponent = (VscIcons as any)[icon] as React.ElementType
  return (
    <Badge
      variant="subtle"
      textTransform="capitalize"
      _before={{
        ...((status || indicator) && !withIcon
          ? {
              content: '""',
              boxSize: 1,
              display: 'inline-flex',
              mr: 2,
              borderRadius: 'full',
              bg: !hasStatus ? `${colorScheme}.500` : `${color}.500`
            }
          : {})
      }}
      p={0}
      color={!hasStatus ? `${colorScheme}.700` : `${color}.700`}
      borderRadius="md"
      isTruncated
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize="xx-small"
      cursor={onClick ? 'pointer' : undefined}
      colorScheme={!hasStatus ? colorScheme : color}
      onClick={onClick}
      bg="transparent"
      {...rest}
    >
      {withIcon && (
        <Icon
          as={IconComponent}
          color={!hasStatus ? `${colorScheme}.600` : `${color}.600`}
          boxSize={3}
          mr={2}
        />
      )}
      <Text wordBreak="break-all" mr={onClick ? 2 : 0}>
        {status && title ? getStatusLabel(title) : children}
      </Text>
      {onClick && <VscLink />}
    </Badge>
  )
}
StatusBadge.defaultProps = {
  colorScheme: 'blue'
}
export default StatusBadge
