import { Box, Container, Flex, Heading, Image, useToast } from '@chakra-ui/react'
import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import {
  ProjectInput,
  useCreateProjectMutation,
  useGetSingleBaoleaderProjectQuery
} from '../../../generated/graphql'
import { PageWrap } from '../../../layouts'
import { images } from '../../../theme'

import { VscAdd } from 'react-icons/vsc'
import { BackButton, TableIcon } from 'components'
import { useAuthContext } from '../../../context/AuthProvider/index'
import { FaProjectDiagram } from 'react-icons/fa'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'
import { CreateProjectForm } from './components'
import posthog from 'posthog-js'
import { createProjectsButtonHelperPopover, createProjectsHelperPopover } from './help'

const CreateProject: React.FC<{}> = () => {
  const { id: copiedProjectId } = useParams<{ id: string }>()
  let copiedProject
  const toast = useToast()
  const history = useHistory()
  const onError = (error: any) => toast({ title: `${error}`, ...ERROR_TOAST })

  const [createProject, { loading: isLoading }] = useCreateProjectMutation({
    onCompleted: ({ createBaoleaderProject }) => {
      posthog.capture('dashbopard_project_created', {
        project_id: createBaoleaderProject?.id
      })
      toast({ title: 'Project created.', ...SUCCESS_TOAST })
      if (copiedProjectId) {
        history.push(`/auth/projects/${createBaoleaderProject?.id}`)
        history.push(`/auth/project/${createBaoleaderProject?.id}`)
      } else {
        history.push(`/auth/project/${createBaoleaderProject?.id}`)
      }
    },
    onError
  })
  const { user } = useAuthContext()

  const location = useLocation<{ from: string }>()
  if (copiedProjectId) {
    const { data } = useGetSingleBaoleaderProjectQuery({
      variables: { id: copiedProjectId },
      onError
    })
    copiedProject = data?.getSingleBaoleaderProject
  }

  const submitForm = async ({ featured_image, ...rest }: any) => {
    await createProject({
      variables: {
        input: {
          ...rest,
          featured_image: featured_image,
          baoleaders: [user?.id]
        } as ProjectInput
      }
    })
  }

  const actionBar = () => {
    return (
      <Flex justify="center" align="center" mt={6} w="100%">
        <SecondaryButton
          onClick={() => {
            if (location?.state?.from) {
              history.push(location?.state?.from)
            } else {
              history.push('/auth/projects')
            }
          }}
          mr={3}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton leftIcon={<VscAdd />} w="100%" isLoading={isLoading} type="submit">
          Create Project
        </PrimaryButton>
        {createProjectsButtonHelperPopover}
      </Flex>
    )
  }

  return (
    <PageWrap align="center" title="Create Project" justify="center">
      <Container my={8} maxW="1600" px={16}>
        <Flex>
          <BackButton title=" Back to the list of projects" />
        </Flex>
      </Container>
      <Box bg="background.100" borderRadius="md" pb={24} width="100%">
        <Container maxW="1600" px={16} pt={10} position="relative">
          <TableIcon position="right" icon={<FaProjectDiagram />} />
          <Flex direction="column" width="100%">
            <Flex align="center">
              <Heading color="gray.600">Create a project</Heading>
              {createProjectsHelperPopover}
            </Flex>

            <Flex mt={10} w="100%">
              <CreateProjectForm
                actionBar={actionBar()}
                initialValues={{
                  name: copiedProject ? copiedProject?.attributes?.name : '',
                  statement: copiedProject ? copiedProject?.attributes?.statement : '',
                  outcomes: copiedProject ? copiedProject?.attributes?.outcomes : '',
                  reward: copiedProject ? copiedProject?.attributes?.reward : '',
                  collaborators: copiedProject ? copiedProject?.attributes?.collaborators : ''
                }}
                submitForm={submitForm}
              />
              <Flex justify="center" flex={1}>
                <Image
                  borderRadius="full"
                  objectFit="contain"
                  draggable="false"
                  src={images.logo}
                  boxSize="40%"
                />
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </PageWrap>
  )
}

export default CreateProject
