import { IconButton, Text, useToast, VStack } from '@chakra-ui/react'
import { useField } from 'formik'
import { Variants } from 'framer-motion'
import * as React from 'react'
import { RefreshCcw, Trash2 } from 'react-feather'
import { FillLoader, MotionFlex } from '../../..'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'
import { EMPTY_FILE } from '../../../../constants/index'
import { UploadFile } from '../../../../generated/graphql'
import strapiHelpers from '../../../../utils/strapiHelpers'
import { AddFileButton, AddFileWrapper, HiddenInput, Wrapper } from './styles'
import { Card } from 'components'
import { VscCloudUpload } from 'react-icons/vsc'
import { PrimaryButton } from 'components/UI/Buttons'

type ImageUploaderProps = {
  name: string
  withButton?: boolean
  onUploadComplete?: (file: UploadFile) => void
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ name, onUploadComplete, withButton }) => {
  const [stateFile, setStateFile] = React.useState<File | undefined>()
  const [uploading, setUploading] = React.useState(false)
  const [field, meta, helpers] = useField<UploadFile>(name)

  const inputRef = React.createRef<HTMLInputElement>()

  const toast = useToast()

  React.useEffect(() => {
    const handleUpload = async (file: File) => {
      setUploading(true)
      try {
        const strapiFile = await strapiHelpers.upload(file)
        toast({ title: 'Image has been uploaded.', ...SUCCESS_TOAST, position: 'bottom' })
        helpers.setValue(strapiFile.data[0])
        if (onUploadComplete) {
          onUploadComplete(strapiFile.data[0])
        }
        setUploading(false)
      } catch (e) {
        setUploading(false)
        toast({
          title: 'Something went wrong while uploading your photo.',
          ...ERROR_TOAST,
          position: 'bottom'
        })
      }
    }
    if (stateFile) {
      handleUpload(stateFile)
    }
    // eslint-disable-next-line
  }, [stateFile])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      setStateFile(files[0])
    }
  }

  const variants: Variants = {
    in: {
      opacity: 1
    },
    initial: {
      opacity: 0
    },
    hover: {
      backgroundColor: 'gray.200',
      cursor: 'pointer'
    }
  }

  const localUrl = stateFile ? window.URL.createObjectURL(stateFile) : ''

  const buttonsVariant: Variants = {
    initial: {
      y: 60,
      opacity: 0,
      pointerEvents: 'none'
    },
    hover: {
      y: stateFile || field.value ? 0 : 60,
      opacity: 1,
      pointerEvents: 'auto',
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  const handleRefresh = () => {
    if (inputRef?.current) {
      inputRef.current.click()
    }
  }
  return (
    <Card {...(withButton && { mb: 4, p: 5, pb: 10 })}>
      <Wrapper>
        <AddFileWrapper
          animate="in"
          rounded="2xl"
          initial="initial"
          whileHover="hover"
          variants={variants}
          backgroundImage={`url(${field.value ? field.value.url : localUrl})`}
        >
          <AddFileButton
            rounded="2xl"
            width="100%"
            align="center"
            htmlFor={name}
            justify="center"
            borderWidth={0}
            minHeight="300px"
          >
            {!stateFile && !field.value && (
              <VStack flex={1} height="100%" fontSize="sm" justify="center" align="center">
                <VscCloudUpload size={40} />
                <Text fontWeight={500} fontSize="xs">
                  Add Picture
                </Text>
                <Text color="gray.500" fontSize="xs">
                  Photos must be less than <b>5 MB</b>
                </Text>
              </VStack>
            )}
            <MotionFlex
              p={4}
              right={0}
              bottom={0}
              position="absolute"
              variants={buttonsVariant}
              justifyContent="space-around"
            >
              <IconButton
                mr={3}
                rounded="md"
                icon={<Trash2 />}
                aria-label="Clear Image"
                onClick={() =>
                  field.value ? helpers.setValue(EMPTY_FILE) : setStateFile(undefined)
                }
                fontSize="sm"
                colorScheme="green"
              />
              <IconButton
                rounded="md"
                icon={<RefreshCcw />}
                onClick={handleRefresh}
                aria-label="Update Image"
                fontSize="sm"
                colorScheme="green"
              />
            </MotionFlex>
            {uploading && <FillLoader bg="rgba(0,0,0,0.2)" position="absolute" />}
          </AddFileButton>
        </AddFileWrapper>
        <HiddenInput
          ref={inputRef}
          onChange={onChange}
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          multiple
          name={name}
          id={name}
        />

        {meta.touched && meta.error ? (
          <Text color="red.500" fontSize="xs" textAlign="right">
            {meta.error}
          </Text>
        ) : null}
        {withButton && (
          <PrimaryButton
            mt={4}
            onClick={() => inputRef?.current?.click()}
            alignSelf="center"
            width="70%"
            position="absolute"
            bottom={0}
            transform="translateY(50%)"
          >
            {meta.touched && meta.error ? 'Please upload a photo' : 'Upload Photo'}
          </PrimaryButton>
        )}
      </Wrapper>
    </Card>
  )
}

export default React.memo(ImageUploader)
