import { Enum_Subscription_Status, useGetCompanyInfoQuery, useMeQuery } from '../generated/graphql'

const useIsSubscribed = () => {
  const { data: company, loading } = useGetCompanyInfoQuery()
  const { data: user } = useMeQuery()

  const subscriptionStatus = company?.getCompanyInfo?.paymentMethod
    ? company?.getCompanyInfo?.subscription?.data?.attributes?.status || 'not subscribed'
    : Enum_Subscription_Status.Incomplete
  const isManual =
    company?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data?.attributes
      ?.isManual || false
  return {
    isSubscribed:
      (subscriptionStatus === Enum_Subscription_Status.Active ||
        subscriptionStatus === Enum_Subscription_Status.Trialing) &&
      company?.getCompanyInfo?.paymentMethod,
    subscriptionStatus,
    loading,
    isManual,
    billingInformationCompleted:
      user?.myUser?.attributes?.onboardingInformation?.billingInformationCompleted,
    cancelAtPeriodEnd:
      company?.getCompanyInfo?.subscription?.data?.attributes?.cancel_at_period_end,
    memberLimit:
      company?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data?.attributes
        ?.ProductFeature?.baoriders_allowed,
    extraAmount:
      company?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data?.attributes
        ?.ProductFeature?.cost_per_baorider
  }
}

export default useIsSubscribed
