import { AlertModal } from 'components'
import { Text } from '@chakra-ui/react'
import { Enum_Tasktype_Status } from 'generated/graphql'
import React from 'react'
import { TaskTypeEntity } from '../../../generated/graphql'
import { DATA_COLLECTOR } from '../../../constants'

type DeactivateModalProps = {
  taskType: TaskTypeEntity
  title?: string
  updatingTaskType?: boolean
  isOpen: boolean
  onClose: () => void
  handleDeleteTaskType: () => void
}

const DeactivateModal: React.FC<DeactivateModalProps> = ({
  taskType,
  handleDeleteTaskType,
  isOpen,
  onClose,
  title,
  updatingTaskType
}) => {
  const activationStatus =
    taskType?.attributes?.status === Enum_Tasktype_Status.Active ? 'deactivate' : 'activate'
  const isActive = activationStatus === 'activate'
  return (
    <AlertModal
      {...{ isOpen, onClose }}
      title={title ? title : `${activationStatus === 'deactivate' ? 'Turn Off' : 'Turn On'} Task`}
      loading={updatingTaskType}
      confirmButtonText={isActive ? 'Turn on' : 'Turn off'}
      activatorStatus={activationStatus}
      onConfirm={handleDeleteTaskType}
    >
      Are you sure you want to {activationStatus} this task?
      {!isActive && (
        <>
          <br />
          <br />
          <Text fontWeight="semibold">
            There may be projects attached to this task, this action will make this task unavailable
            to all {DATA_COLLECTOR}s.
          </Text>
        </>
      )}
      {isActive && (
        <>
          <br />
          <br />
          <Text fontWeight="semibold">
            There may be projects attached to this task - make sure you turn on this task in the
            project as well.
          </Text>
        </>
      )}
    </AlertModal>
  )
}

export default DeactivateModal
