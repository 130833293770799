import { Skeleton, Tab, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'

type CustomTabProps = {
  tab: {
    title?: string | undefined
    icon?: JSX.Element | undefined
    loading?: boolean | undefined
    bg?: string | undefined
    tooltip?: {
      label: React.ReactNode
      bg?: string
      textColor?: string
      hasArrow?: boolean
      placement?: 'top' | 'right' | 'bottom' | 'left'
    }
  }
}

const CustomTab: React.FC<CustomTabProps> = ({ tab }) => {
  const tabContent = (
    <Skeleton isLoaded={!tab.loading}>
      <Tab fontWeight={500} px={0} mr={8} py={2} fontSize="xs">
        {tab.icon} <Text ml={2}>{tab.title}</Text>
      </Tab>
    </Skeleton>
  )

  return tab.tooltip ? (
    <Tooltip
      bg={tab.tooltip.bg || 'white'}
      textColor={tab.tooltip.textColor || 'blackAlpha.900'}
      label={tab.tooltip.label}
      hasArrow={tab.tooltip.hasArrow ?? true}
      placement={tab.tooltip.placement || 'top'}
    >
      {tabContent}
    </Tooltip>
  ) : (
    tabContent
  )
}

export default CustomTab
