import { Flex, FormLabel, InputProps } from '@chakra-ui/react'
import { useField } from 'formik'
import moment from 'moment'
import * as React from 'react'
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { DATE_FORMAT } from '../../../../constants'
import { Text } from '../../../../typography'
import { LabelProps } from '../styles'
import { DatePickerSelect, RangeDatePickerWrapper } from './styles'

type ConnectedDatePickerProps = LabelProps &
  InputProps & {
    label?: string
    name: string
  }

const ConnectedRangeDatePicker: React.FC<ConnectedDatePickerProps> = ({
  label,
  isDisabled,
  onChange,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name)
  const [dates, setDates] = React.useState(() =>
    field && field.value && field.value?.length
      ? [moment(field.value[0]), moment(field.value[1])]
      : []
  )
  const [focused, setFocus] = React.useState<'startDate' | 'endDate' | null>(null)

  const renderYears = () => {
    const arr = []
    for (let i = -100; i <= 5; i++) {
      arr.push(
        <option key={moment().year() + i} value={moment().year() + i}>
          {moment().year() + i}
        </option>
      )
    }
    return arr
  }

  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      {label && (
        <FormLabel htmlFor={field.name}>
          <Text fontSize="xs">{label}</Text>
        </FormLabel>
      )}
      <RangeDatePickerWrapper>
        <DateRangePicker
          startDate={dates[0]}
          startDateId="startDate"
          endDate={dates[1]}
          endDateId="endDate"
          focusedInput={focused}
          hideKeyboardShortcutsPanel={true}
          displayFormat={DATE_FORMAT}
          endDatePlaceholderText="To"
          startDatePlaceholderText="From"
          numberOfMonths={1}
          onFocusChange={(focused) => setFocus(focused)}
          onDatesChange={({ startDate, endDate }) => {
            const dateFormat = 'lll'
            const newDates = [
              startDate ? moment(startDate?.toDate(), dateFormat) : moment(),
              endDate ? moment(endDate?.toDate(), dateFormat) : moment()
            ]

            setDates(newDates)
            helpers.setValue(newDates)
            onChange &&
              onChange({
                target: { value: newDates }
              } as any)
          }}
          // id={field.name}
          disabled={isDisabled}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
            return (
              <Flex flexDirection="row" justifyContent="center">
                <DatePickerSelect
                  mr={2}
                  name={field.name}
                  value={month.month()}
                  id="date"
                  onChange={(e) => {
                    onMonthSelect(month, e.target.value)
                  }}
                >
                  {moment.months().map((lbl, value) => (
                    <option key={value} value={value}>
                      {lbl}
                    </option>
                  ))}
                </DatePickerSelect>
                <DatePickerSelect
                  name={field.name}
                  value={month.year()}
                  onChange={(e) => {
                    onYearSelect(month, e.target.value)
                  }}
                >
                  {renderYears()}
                </DatePickerSelect>
              </Flex>
            )
          }}
        />
      </RangeDatePickerWrapper>
      {meta.touched && meta.error ? (
        <Text color="red.500" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  )
}

export default ConnectedRangeDatePicker

ConnectedRangeDatePicker.defaultProps = {
  mb: 2,
  fontWeight: 'lighter'
}
