import {
  Center,
  Spinner,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Heading,
  VStack
} from '@chakra-ui/react'
import { EmptyListHandler } from 'components'
import { ComponentFaqFaq } from 'generated/graphql'
import React from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'

type FAQProps = {
  FAQs: ComponentFaqFaq[]
  loading: boolean
}

const FAQ: React.FC<FAQProps> = ({ FAQs, loading }) => {
  const groupedFAQs = FAQs.reduce((acc: any, faq) => {
    const key = faq.category || ''
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(faq)
    return acc
  }, {})

  if (loading) {
    return (
      <Center>
        <Spinner colorScheme="green" />
      </Center>
    )
  }
  return (
    <VStack flex={1} spacing={10} w="100%" alignItems="flex-start">
      {FAQs?.length ? (
        Object.keys(groupedFAQs).map((category) => (
          <Box w="100%" key={category}>
            <Heading mb={5} size="md" color="gray.500" mt={8}>
              {category.replace(/_/g, ' ')}
            </Heading>
            <Accordion w="100%" allowToggle>
              {groupedFAQs[category].map((faq: any) => (
                <AccordionItem borderColor="transparent" w="100%" key={faq?.id}>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          px={0}
                          w="100%"
                          py={4}
                          fontWeight="extrabold"
                          fontSize="md"
                        >
                          <Text color="gray.400">
                            {isExpanded ? <FaMinus fontSize="14px" /> : <FaPlus fontSize="14px" />}
                          </Text>
                          <Box flex="1" w="100%" color="gray.700" pl={4} textAlign="left">
                            {faq?.question}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text
                          color="gray.500"
                          fontSize="sm"
                          lineHeight="1.5"
                          textAlign="left"
                          w="100%"
                        >
                          {faq?.answer}
                        </Text>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        ))
      ) : (
        <EmptyListHandler
          title="No Training FAQs"
          subTitle="There are no training videos available at this time."
        />
      )}
    </VStack>
  )
}

export default FAQ
