import { DefaultEventsMap } from '@socket.io/component-emitter'
import io, { Socket } from 'socket.io-client'
import { API_HOST } from '../../constants'

const socketOptions = {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 10000,
  reconnectionAttempts: Infinity
}

let socket: Socket<DefaultEventsMap, DefaultEventsMap>

export const connectSocket = (query = {}) => {
  socket = io(API_HOST, {
    ...socketOptions,
    query
  })
  return socket
}

export const disconnectSocket = () => {
  socket.disconnect()
}

export const getSocket = () => {
  return socket
}

export default io
