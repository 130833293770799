import { Stack, Heading, Flex, Box } from '@chakra-ui/react'
import { Card, Stat } from 'components'
import GoogleMap from 'lib/map/components/GoogleMap'
import React from 'react'

import { VscLocation, VscPin } from 'react-icons/vsc'

import styled from '@emotion/styled'
const StyledMap = styled.div`
  width: 100%;
  height: 100%;
  > div,
  > div > div {
    width: 100%;
    height: 100%;
  }
`

type LocationProps = {
  location: {
    latitude: number
    longitude: number
    accuracy: number
  }
}

const Location: React.FC<LocationProps> = ({ location }) => {
  return (
    <Card width="100%" border="none">
      <Box>
        <Stack spacing={1} px={6} pt={4} pb={6} fontSize="xs">
          <Heading as="h6" color="gray.600" mb={4} size="sm">
            Location
          </Heading>

          <Flex justify="space-between" align="center" fontSize="xs">
            <Stat label="Latitude" icon={<VscLocation />} value={location?.latitude} />
            <Stat label="Longitude" icon={<VscLocation />} value={location?.longitude} />
            <Stat label="Accuracy" icon={<VscPin />} value={location?.accuracy} />
          </Flex>
        </Stack>
        {location?.latitude !== 0 && location?.longitude !== 0 && location?.accuracy !== 0 && (
          <Box
            w="100%"
            overflow="hidden"
            borderRadius="0 0 1rem 1rem"
            minH="385px"
            h="385px"
            background="rgba(0,0,0,0.1)"
          >
            <StyledMap>
              <GoogleMap
                lat={location?.latitude}
                lng={location?.longitude}
                markers={[
                  ...(location?.latitude && location?.longitude
                    ? [
                        {
                          lat: location?.latitude,
                          lng: location?.longitude
                        }
                      ]
                    : [])
                ]}
              />
            </StyledMap>
          </Box>
        )}
      </Box>
    </Card>
  )
}

export default Location
