import { Box, Container, Flex, Center } from '@chakra-ui/react'

import React from 'react'

import Menu from './Menu'

import { BaotreeLogo } from 'components'

type NavProps = {}

const Nav: React.FC<NavProps> = ({}) => {
  return (
    <Box
      py={2}
      w="100%"
      zIndex={294}
      position="fixed"
      top={10}
      left={0}
      transition="all .4s ease-in-out 0s"
    >
      <Container maxW="container.lg">
        <Flex justify="space-between" align="center">
          <Center>
            <BaotreeLogo bottom={0} w={200} right={0} top={0} left={0} position="relative" />
            <Menu />
          </Center>
        </Flex>
      </Container>
    </Box>
  )
}

export default Nav
