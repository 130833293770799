import { Text, Heading, Image, VStack, HStack, Alert, AlertIcon, Button } from '@chakra-ui/react'
import { BaotreeLogo } from 'components'
import { PageWrap } from 'layouts'
import React from 'react'

import { images } from 'theme'
import { VscRefresh, VscArrowLeft } from 'react-icons/vsc'

type ErrorBoundaryProps = {
  children: React.ReactNode
}
type State = {
  error: React.ErrorInfo | null
  errorInfo: React.ErrorInfo | null
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  state: State = { error: null, errorInfo: null }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <PageWrap bg="white" justify="center" align="center" title="Error" p={4} flexDir="column">
          <HStack>
            <Image src={images.error} objectFit="contain" alt="Baotree logo" w={500} />
            <VStack maxW={500} spacing={4} alignItems="flex-start">
              <Heading as="h3" textAlign="left" mb={4}>
                Error
              </Heading>
              <Text fontSize="sm">
                Please send a screenshot of this error page to customers@baotree.io THEN you can
                refresh the page or go back to the homepage by using the buttons below.
              </Text>
              <Alert fontSize="sm" status="error">
                <AlertIcon />
                {this.state.error && this.state.error.toString()}
              </Alert>
              <Alert fontSize="sm" variant="top-accent" status="error">
                <Text noOfLines={8}>{this.state.errorInfo.componentStack}</Text>
              </Alert>
              <HStack spacing={3}>
                <Button
                  colorScheme="green"
                  leftIcon={<VscArrowLeft />}
                  variant="ghost"
                  fontSize="sm"
                  onClick={() => window.location.replace('/')}
                >
                  Go to home page
                </Button>

                <Button
                  colorScheme="green"
                  leftIcon={<VscRefresh />}
                  fontSize="sm"
                  onClick={() => window.location.reload()}
                >
                  Refresh Page
                </Button>
              </HStack>
            </VStack>
          </HStack>
          <BaotreeLogo />
        </PageWrap>
      )
    }

    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
