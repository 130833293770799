import { useDisclosure, useToast } from '@chakra-ui/react'

import { get } from 'lodash'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { DATA_COLLECTOR, ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'

import {
  Enum_Userspermissionsuser_Status,
  useCountRidersQuery,
  useCreateBaoriderMutation,
  useGetBaoridersQuery,
  useGetCompanyInfoQuery,
  useUpdateBaoriderMutation
} from '../../../generated/graphql'
import { PageListContainer } from '../../../layouts'

import { UsersPermissionsUserEntity } from '../../../generated/graphql'
import { DataCollectorsTable, DataCollectorsModal, DCOptionsModal } from '../components'
import { FcPhoneAndroid } from 'react-icons/fc'
import { AlertIndicator } from 'components'
import {
  addDataCollectorTooltipContent,
  dataCollectorsTitleHelperPopover,
  exportExcelTooltipContent
} from './help'

const DataCollectors = () => {
  const toast = useToast()
  const history = useHistory()
  const historyState = history.location.state as any

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: historyState?.isCreateModalOpen || false,
    onClose: () => {
      history.replace({
        state: { isCreateModalOpen: false }
      })
    }
  })
  const {
    isOpen: dcOptionsIsOpen,
    onOpen: dcOptionsOnOpen,
    onClose: dcOptionsOnClose
  } = useDisclosure()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedFilter, setSelectedFilter] = React.useState('')
  const [whereProps, setWhereProps] = React.useState<any>({
    status: [Enum_Userspermissionsuser_Status.Active, Enum_Userspermissionsuser_Status.Archived]
  })

  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)

  const {
    data,
    refetch,
    loading: loadingVerifiers
  } = useGetBaoridersQuery({
    variables: {
      pagination: {
        limit,
        start
      },
      filters:
        searchTerm && selectedFilter
          ? { [`${selectedFilter}`]: { $contains: searchTerm }, ...whereProps }
          : { ...whereProps }
    },
    onError,
    fetchPolicy: 'cache-first'
  })

  const { data: companyInfo } = useGetCompanyInfoQuery()

  const [showDataCollectorLimitAlert, setShowDataCollectorLimitAlert] = React.useState(false)
  const [showLimitReachedAlert, setShowLimitReachedAlert] = React.useState(false)
  const [teamMembersAllowed, setTeamMembersAllowed] = React.useState(0)
  const [numberOfActiveDataCollectors, setNumberOfActiveDataCollectors] = React.useState(0)
  const [numberOfActiveTeamMembers, setNumberOfActiveTeamMembers] = React.useState(0)

  useEffect(() => {
    if (companyInfo) {
      const numberOfTeamMembersAllowed =
        companyInfo.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data?.attributes
          ?.ProductFeature?.baoriders_allowed || 10

      const activeTeamMembers =
        companyInfo.getCompanyInfo?.team_members?.data.filter((member) => {
          return member.attributes?.status === Enum_Userspermissionsuser_Status.Active
        }) || []

      const numberOfActiveDataCollectors = activeTeamMembers.filter((member) => {
        return member.attributes?.role?.data?.attributes?.type === 'baorider'
      }).length

      const numberOfActiveTeamMembers = activeTeamMembers.length

      if (numberOfTeamMembersAllowed - 1 === numberOfActiveTeamMembers) {
        setShowDataCollectorLimitAlert(true)
      } else if (numberOfTeamMembersAllowed <= numberOfActiveTeamMembers) {
        setShowLimitReachedAlert(true)
      }

      setNumberOfActiveDataCollectors(numberOfActiveDataCollectors)
      setNumberOfActiveTeamMembers(numberOfActiveTeamMembers)
      setTeamMembersAllowed(numberOfTeamMembersAllowed)
    }
  }, [companyInfo])

  const { data: count } = useCountRidersQuery({
    variables: {
      where:
        searchTerm && selectedFilter
          ? { [`${selectedFilter}`]: { $contains: searchTerm }, ...whereProps }
          : { ...whereProps }
    }
  })

  const [createBaorider, { loading: createLoading }] = useCreateBaoriderMutation({
    onCompleted: () => {
      toast({ title: DATA_COLLECTOR + ' created.', ...SUCCESS_TOAST })
    },
    onError: (err: any) => {
      toast({
        title: get(err, 'graphQLErrors[0].message', 'Unable to add ' + DATA_COLLECTOR),
        ...ERROR_TOAST
      })
    }
  })
  const [editBaorider, { loading: editLoading }] = useUpdateBaoriderMutation({
    onCompleted: () => {
      toast({ title: DATA_COLLECTOR + ' updated.', ...SUCCESS_TOAST })
    },
    onError
  })

  const onFilter = async (filterValues: { [key: string]: string | number }) => {
    try {
      setWhereProps(filterValues)
    } catch (e) {
      console.error(e)
    }
  }

  const isLoading = createLoading || editLoading
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [editableBaorider, setEditableBaorider] = React.useState({} as UsersPermissionsUserEntity)

  const addBaorider = async (baorider: any & { community: string }) => {
    try {
      const user = await createBaorider({
        variables: {
          input: {
            firstName: baorider?.firstName || '',
            lastName: baorider?.lastName || '',
            gender: baorider?.gender || '',
            phoneNumber: baorider?.phoneNumber || '',
            community: baorider.community || '',
            isVerifier: false
          },
          sendInvite: true
        }
      })
      await refetch()
      return user.data?.createBaorider?.baorider?.id
    } catch (error) {
      console.log(error)
    }
  }

  const updateBaorider = async (baorider: any) => {
    try {
      const user = await editBaorider({
        variables: {
          id: baorider?.id || '',
          baorider: {
            firstName: baorider?.firstName,
            lastName: baorider?.lastName,
            phoneNumber: baorider?.phoneNumber
          }
        }
      })
      await refetch()

      return user.data?.updateBaorider.baorider?.id
    } catch (error) {
      console.log(error)
    }
  }
  const stats = React.useMemo(
    () => [
      {
        title: `${DATA_COLLECTOR} Overview`,
        icon: FcPhoneAndroid,
        value: `${count?.countRiders || 0} ${DATA_COLLECTOR}${
          count?.countRiders && count?.countRiders > 1 ? 's' : ''
        }`
      }
    ],
    [count?.countRiders]
  )
  return (
    <PageListContainer
      title="Baoriders"
      icon={<FcPhoneAndroid />}
      loading={loadingVerifiers}
      stats={stats}
    >
      {showDataCollectorLimitAlert && (
        <AlertIndicator status="info">
          You currently have {numberOfActiveDataCollectors} active data collectors and{' '}
          {numberOfActiveTeamMembers - numberOfActiveDataCollectors} active administrators. You can
          add {teamMembersAllowed - numberOfActiveTeamMembers} more team member(s) to your account
          on your current subscription plan. You can add as many users as you like to your
          Subscription - Adding users over the number included in your subscription will cost an
          additional £
          {companyInfo?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data
            ?.attributes?.ProductFeature?.cost_per_baorider || 9}{' '}
          per team member, per month.
        </AlertIndicator>
      )}
      {showLimitReachedAlert && (
        <AlertIndicator status="info">
          You have reached the limit of the users included in your current subscription package.
          Adding more team members as users will cost an additional £
          {companyInfo?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data
            ?.attributes?.ProductFeature?.cost_per_baorider || 9}{' '}
          per team member, per month.
        </AlertIndicator>
      )}
      <DataCollectorsTable
        loading={loadingVerifiers}
        setSearchTerm={setSearchTerm}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        searchTerm={searchTerm}
        setLimit={setLimit}
        setStart={setStart}
        limit={limit}
        start={start}
        count={count?.countRiders || 0}
        title={`${DATA_COLLECTOR}`}
        baoriders={(data?.getBaoriders || []) as UsersPermissionsUserEntity[]}
        onFilter={onFilter}
        onReset={async () => {
          await refetch({ filters: {} })
        }}
        onCreate={onOpen}
        onClick={(baorider: UsersPermissionsUserEntity) => {
          history.push(`/auth/baoriders/${baorider.id}`)
        }}
        addDatacollectorTooltip={addDataCollectorTooltipContent}
        dataCollectorsHelpPopover={dataCollectorsTitleHelperPopover}
        exportExcelTooltipContent={exportExcelTooltipContent}
      />

      <DataCollectorsModal
        additionalCost={
          companyInfo?.getCompanyInfo?.subscription?.data?.attributes?.product_plan?.data
            ?.attributes?.ProductFeature?.cost_per_baorider || 9
        }
        teamMembersRemaining={teamMembersAllowed - numberOfActiveTeamMembers}
        {...{
          isOpen,
          onOpen,
          onClose,
          editableBaorider,
          isEditMode
        }}
        isLoading={isLoading}
        onSubmit={async (baorider: any, addAnother: boolean) => {
          let user
          if (isEditMode) {
            user = await updateBaorider(baorider)
            setIsEditMode(false)
          } else {
            user = await addBaorider(baorider)
          }
          if (addAnother) {
            setEditableBaorider({} as UsersPermissionsUserEntity)
          } else {
            dcOptionsOnOpen()
            onClose()
          }
          return user
        }}
      />
      <DCOptionsModal
        {...{
          isOpen: dcOptionsIsOpen,
          onClose: dcOptionsOnClose,
          callBack: async () => {
            onOpen()
            dcOptionsOnClose()
          }
        }}
      />
    </PageListContainer>
  )
}

export default DataCollectors
