import { HStack } from '@chakra-ui/react'
import { UserView } from 'components'
import {
  UsersPermissionsUserEntity,
  GetBaoleaderTaskQuery,
  EvidenceEntity
} from 'generated/graphql'
import React from 'react'
import { Maybe } from '../../../../generated/graphql'
import { useHistory } from 'react-router-dom'

type EvidenceStatProps = {
  data: Maybe<GetBaoleaderTaskQuery> | undefined
  evidence: Maybe<EvidenceEntity> | undefined
}

const EvidenceStat: React.FC<EvidenceStatProps> = ({ data, evidence }) => {
  const history = useHistory()
  return (
    <HStack spacing={5} position="absolute" top={0} transform="translateY(-60%)" mr={14} right={0}>
      {evidence?.attributes?.baorider?.data && (
        <UserView
          user={evidence?.attributes?.baorider.data as UsersPermissionsUserEntity}
          task_origin={data?.getBaoleaderTask?.attributes?.task_origin}
          onClick={() =>
            history.push(`/auth/baoriders/${evidence?.attributes?.baorider?.data?.id}`)
          }
        />
      )}
    </HStack>
  )
}

export default EvidenceStat
