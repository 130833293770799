import {
  Avatar,
  Box,
  Button,
  Container,
  Image,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import {
  BackButton,
  EmptyListHandler,
  FloatingButtonWrapper,
  FloatingCard,
  TasksTable
} from '../../../components'
import ConnectedFormGroup from '../../../components/forms/FormElements/ConnectedFormGroup'

import ConnectedSelect, { OptionType } from '../../../components/forms/FormElements/ConnectedSelect'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import {
  Enum_Userspermissionsuser_Status,
  TaskEntity,
  useCountTasksQuery,
  useGetSingleBaoleaderCommunityQuery,
  useGetSingleCommunityMemberQuery,
  useGetTasksQuery,
  useUpdateCommunityMemberMutation
} from '../../../generated/graphql'
import { PageWrap } from '../../../layouts'
import { CommunityMember } from '../../../utils/types'
import { addCommunityMemberSchema } from '../../../utils/validationSchemas'

import { VscAccount, VscFolder, VscCheck } from 'react-icons/vsc'
import { Activity } from 'react-feather'
import { pluralHandler } from 'utils'

import moment from 'moment'
import { PrimaryButton, OutlineButton } from '../../../components/UI/Buttons/index'
import { images } from 'theme'

const CommunityMemberProfile = () => {
  const { id, communityId } = useParams<{ id: string; communityId: string }>()
  const toast = useToast()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] = useState(false)
  const [showReactivateConfirmation, setShowReactivateConfirmation] = useState(false)

  const {
    data: storedMember,
    refetch: refetchMemeber,
    loading
  } = useGetSingleCommunityMemberQuery({
    variables: { where: { id, community: communityId } },
    onError
  })
  const { data: storedCommunity } = useGetSingleBaoleaderCommunityQuery({
    variables: { id: communityId },
    onError
  })
  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(0)
  const { data: count, refetch: refetchCount } = useCountTasksQuery({
    variables: {
      filters: {
        community_member: { id },
        id: { $contains: search }
      }
    }
  })
  const { data: tasks, refetch } = useGetTasksQuery({
    onError,
    variables: {
      pagination: {
        limit,
        start
      },
      filters: {
        community_member: { id }
      }
    }
  })
  const data = tasks?.getBaoleaderTasksWithCount?.data

  const [updateMember] = useUpdateCommunityMemberMutation({
    onCompleted: () => {
      toast({ title: 'Group member updated.', ...SUCCESS_TOAST })
    },
    onError
  })
  const [didFilter, setDidFilter] = React.useState(false)
  const onFilter = async (where: { [key: string]: string | number | string[] }) => {
    setDidFilter(true)
    try {
      await refetchCount({ filters: { ...where, community_member: { id } } })
      await refetch({ filters: where })
    } catch (e) {
      console.log(e)
    }
  }
  const onReset = async () => {
    await refetch({ filters: {} })
  }

  const submitForm = async (values: CommunityMember) => {
    await updateMember({
      variables: {
        id,
        input: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          phoneNumber: values?.phoneNumber,
          gender: values?.gender
        }
      }
    })
    await refetchMemeber({ where: { id, community: communityId } })
  }

  const deactivateUser = async () => {
    await updateMember({
      variables: {
        id,
        input: {
          status: Enum_Userspermissionsuser_Status.Archived,
          blocked: true
        }
      }
    })
    await refetchMemeber({ where: { id, community: communityId } })
  }

  const reactivateUser = async () => {
    await updateMember({
      variables: {
        id,
        input: {
          status: Enum_Userspermissionsuser_Status.Active,
          blocked: false
        }
      }
    })
    await refetchMemeber({ where: { id, community: communityId } })
  }
  const fullName = `${storedMember?.getSingleCommunityMember?.member?.attributes?.firstName} ${storedMember?.getSingleCommunityMember?.member?.attributes?.lastName}`

  return (
    <PageWrap direction="column" title="Create Project" align="center" p={0} m={0}>
      <Container my={8} maxW="1600" px={16}>
        <Flex>
          <BackButton
            title={
              !loading
                ? `Back to ${storedCommunity?.getSingleBaoleaderCommunity?.attributes?.name}`
                : undefined
            }
          />
        </Flex>
      </Container>
      <Box bg="background.100" minH="90vh" pb={24} width="100%">
        <Container maxW="1600" px={16} pt={10} position="relative">
          <HStack
            spacing={5}
            position="absolute"
            top={0}
            transform="translateY(-60%)"
            mr={14}
            right={0}
          >
            <FloatingCard
              icon={<Activity size={20} />}
              title="Tasks Reported"
              loading={loading}
              value={`${data?.length || 0} ${pluralHandler('task', data?.length || 0)} reported`}
            />
          </HStack>

          <Flex my={10} direction="column" w="100%">
            <Flex align="center">
              <Skeleton isLoaded={!loading} borderRadius="full">
                <Avatar
                  size="md"
                  src={
                    storedMember?.getSingleCommunityMember?.member?.attributes?.profilePicture?.data
                      ?.attributes?.formats?.small?.url
                  }
                  name={fullName}
                />
              </Skeleton>
              <VStack align="flex-start" spacing={0} ml={4}>
                <Skeleton isLoaded={!loading}>
                  <Heading size="md">{fullName}</Heading>
                </Skeleton>
                <Skeleton isLoaded={!loading}>
                  <Text color="gray.500" fontSize="xs">
                    {storedMember?.getSingleCommunityMember?.member?.attributes?.email}
                  </Text>
                </Skeleton>
              </VStack>
            </Flex>
          </Flex>
          <Tabs colorScheme="green">
            <TabList>
              <Tab fontSize="xs">
                <Flex align="center">
                  <VscAccount />
                  <Text ml={2}>Profile</Text>
                </Flex>
              </Tab>
              <Tab fontSize="xs">
                <Flex align="center">
                  <VscFolder />
                  <Text ml={2}>Tasks</Text>
                </Flex>
              </Tab>
            </TabList>
            <TabPanels bg="gray.100">
              <TabPanel p={0}>
                <Flex py={6} w="100%">
                  <Formik
                    initialValues={
                      storedMember?.getSingleCommunityMember?.member?.attributes as CommunityMember
                    }
                    validationSchema={addCommunityMemberSchema}
                    onSubmit={submitForm}
                    enableReinitialize={true}
                  >
                    {({ handleSubmit }) => (
                      <Form
                        style={{
                          flex: 1
                        }}
                        onSubmit={handleSubmit}
                      >
                        <FloatingButtonWrapper
                          button={
                            <HStack width="100%">
                              {storedMember?.getSingleCommunityMember?.member?.attributes
                                ?.status === Enum_Userspermissionsuser_Status.Active && (
                                <OutlineButton
                                  colorScheme="red"
                                  onClick={() => setShowDeactivateConfirmation(true)}
                                >
                                  Deactivate this user?
                                </OutlineButton>
                              )}
                              {storedMember?.getSingleCommunityMember?.member?.attributes
                                ?.status !== Enum_Userspermissionsuser_Status.Active && (
                                <OutlineButton
                                  colorScheme="red"
                                  onClick={() => setShowReactivateConfirmation(true)}
                                >
                                  Reactivate this user?
                                </OutlineButton>
                              )}

                              <PrimaryButton flex={1} type="submit">
                                Update
                              </PrimaryButton>
                            </HStack>
                          }
                        >
                          <Flex mb={8} w="100%">
                            <Flex w="50%" justify="flex-start" direction="column">
                              <Text mb={1} fontSize="xs" fontWeight="bold">
                                Basic Profile
                              </Text>
                              <Text color="gray.500" fontSize="xs">
                                Update basic profile information here.
                              </Text>
                            </Flex>
                            <Flex w="50%" justify="flex-end" direction="column" textAlign="right">
                              <Text fontSize="xs" mb={1} fontWeight="bold">
                                {
                                  storedMember?.getSingleCommunityMember?.member?.attributes
                                    ?.firstName
                                }{' '}
                                {
                                  storedMember?.getSingleCommunityMember?.member?.attributes
                                    ?.lastName
                                }
                              </Text>

                              <Text fontSize="xs" color="gray.500">
                                Joined{' '}
                                {moment(
                                  storedMember?.getSingleCommunityMember?.member?.attributes
                                    ?.createdAt
                                ).format('LL')}
                              </Text>
                            </Flex>
                          </Flex>

                          <ConnectedFormGroup
                            name="firstName"
                            label="First name *"
                            placeholder="Enter first name"
                          />
                          <ConnectedFormGroup
                            name="lastName"
                            label="Last name *"
                            placeholder="Enter last name"
                          />
                          <ConnectedFormGroup
                            name="phoneNumber"
                            label="Phone Number *"
                            placeholder="Enter phone number"
                            showIsValidValue
                          />

                          <ConnectedSelect
                            name="gender"
                            label="Gender"
                            placeholder="Select Gender"
                            showIsValidValue
                            options={
                              [
                                { label: 'Male', value: 'Male' },
                                { label: 'Female', value: 'Female' }
                              ] as OptionType[]
                            }
                          />
                        </FloatingButtonWrapper>
                      </Form>
                    )}
                  </Formik>
                  <Flex justify="center" flex={1}>
                    <Image
                      borderRadius="full"
                      objectFit="contain"
                      draggable="false"
                      src={images.logo}
                      boxSize="40%"
                    />
                  </Flex>
                </Flex>
              </TabPanel>
              <TabPanel p={2}>
                <Flex py={6} direction="column">
                  {!didFilter && data?.length === 0 ? (
                    <EmptyListHandler />
                  ) : (
                    <TasksTable
                      loading={loading}
                      data={(data || []) as TaskEntity[]}
                      onFilter={onFilter}
                      count={count?.countTasks || 0}
                      onReset={onReset}
                      onSearch={(search) => {
                        setSearch(search)
                      }}
                      searchValue={search}
                      setLimit={setLimit}
                      currentPage={page}
                      setPage={setPage}
                      start={start}
                      setStart={setStart}
                      limit={limit}
                    />
                  )}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Modal
            isOpen={showDeactivateConfirmation}
            onClose={() => setShowDeactivateConfirmation(false)}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontWeight="500">Deactivate User</ModalHeader>
              <ModalCloseButton />
              <Flex flexDirection="column" p={6}>
                <Heading fontSize="md">Are you sure you want to deactivate this user?</Heading>
                <Text fontSize="sm" mt={2}>
                  By deactivating this user, they will no longer be part of the platform
                </Text>
              </Flex>
              <ModalFooter>
                <Button mr={3} fontSize="sm" onClick={() => setShowDeactivateConfirmation(false)}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  fontSize="sm"
                  leftIcon={<VscCheck size={14} />}
                  onClick={async () => {
                    await deactivateUser()
                    setShowDeactivateConfirmation(false)
                  }}
                >
                  Yes, deactivate
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={showReactivateConfirmation}
            onClose={() => setShowReactivateConfirmation(false)}
          >
            <ModalOverlay />
            <ModalContent borderRadius={5}>
              <ModalHeader fontWeight="500">Reactivate User</ModalHeader>
              <ModalCloseButton />
              <Flex flexDirection="column" p={6}>
                <Heading fontSize="lg">Are you sure you want to reactivate this user ?</Heading>
                <Text fontSize="md" mt={2}>
                  By reactivating a user, they will be able to be part of the platform
                </Text>
              </Flex>
              <ModalFooter>
                <Button mr={3} fontSize="sm" onClick={() => setShowReactivateConfirmation(false)}>
                  Cancel
                </Button>
                <Button
                  colorScheme="green"
                  leftIcon={<VscCheck size={14} />}
                  fontSize="sm"
                  onClick={async () => {
                    await reactivateUser()
                    setShowReactivateConfirmation(false)
                  }}
                >
                  Yes, reactivate
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Container>
      </Box>
    </PageWrap>
  )
}

export default CommunityMemberProfile
