import {
  Badge,
  Flex,
  Text,
  useDisclosure,
  Heading,
  SimpleGrid,
  VStack,
  Image,
  HStack,
  Skeleton
} from '@chakra-ui/react'
import React from 'react'
import { images } from 'theme'
import { Card, EmptyListHandler } from '../../../components'
import { useGetCompanyInfoQuery, Company } from '../../../generated/graphql'
import DetailsModal from './DetailsModal'

const SectionDetail: React.FC<{ title: string; details: string }> = ({ title, details }) => {
  return (
    <Flex flexDirection="column" mb={4}>
      <Text
        color="gray.500"
        fontWeight="semibold"
        textTransform="uppercase"
        mb={1}
        fontSize="x-small"
      >
        {title}
      </Text>
      <Text fontSize="xs">{details}</Text>
    </Flex>
  )
}

const CompanyDetails: React.FC = ({}) => {
  const { data, loading } = useGetCompanyInfoQuery()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const company = data?.getCompanyInfo
  if (!company && !loading) {
    return <EmptyListHandler subTitle="" />
  }
  return (
    <>
      <Flex direction="column">
        <SimpleGrid gap={10} templateColumns="2fr 1fr">
          <VStack spacing={10} w="100%" alignItems="flex-start">
            <Card width="100%" p={6}>
              <Flex w="100%" direction="row" align="center">
                <Image
                  src={images.logoNoText}
                  boxSize={10}
                  rounded="2xl"
                  objectFit="cover"
                  mr={3}
                />
                <VStack alignItems="flex-start" spacing={0}>
                  <HStack spacing={4} w="100%">
                    <Heading as="h5" fontWeight="semibold" size="xs">
                      <Skeleton isLoaded={!loading} minW={50}>
                        {company?.name}
                      </Skeleton>
                    </Heading>
                  </HStack>
                  <Skeleton isLoaded={!loading}>
                    <Text fontSize="x-small" noOfLines={2} color="gray.500" width="100%">
                      {company?.website}
                    </Text>
                  </Skeleton>
                </VStack>
              </Flex>
              <SimpleGrid mt={6} templateColumns="repeat(3, 1fr)">
                <SectionDetail title="Company Name" details={company?.name || ''} />
                <SectionDetail title="Organization ID Number" details={company?.id_number || ''} />
                <SectionDetail
                  title="Wildlife Association Number"
                  details={company?.wildlife_association_number || ''}
                />
                <SectionDetail title="PIN Registration" details={company?.pin_registration || ''} />
                <SectionDetail title="Company website link" details={company?.website || ''} />
              </SimpleGrid>
            </Card>
          </VStack>
          <VStack>
            <Image src={images.logoNoText} width="30%" rounded="full" objectFit="cover" mr={3} />
          </VStack>
        </SimpleGrid>

        <Text color="brand.500" fontSize="xs" my={6} cursor="pointer" onClick={onOpen}>
          Need to change details?
        </Text>

        <Flex flexDirection="row" flexWrap="wrap" maxWidth="50%" mt={2}>
          {company?.affiliates &&
            company.affiliates.map((item: string, index: number) => (
              <Badge variant="solid" key={index} colorScheme="blue" p={2} mr={2} textAlign="center">
                {item}
              </Badge>
            ))}
        </Flex>
      </Flex>
      <DetailsModal {...{ isOpen, onClose, onOpen, company: company as Company }} />
    </>
  )
}

export default CompanyDetails
