import { Flex, Image, Text, useToast } from '@chakra-ui/react'
import { Form, Formik, FormikProps } from 'formik'
import * as React from 'react'
import { VscMail } from 'react-icons/vsc'

import * as Yup from 'yup'

import { images } from 'theme'
import ReactGA from 'react-ga4'
import { FormWrapper, FloatingButtonWrapper } from 'components'
import { ConnectedFormGroup } from 'components/forms/FormElements'
import { SUCCESS_TOAST } from '../../../constants'

import { H3 } from 'typography'
import strapiHelpers from 'utils/strapiHelpers'
import { formatError } from 'utils'

const ForgotPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('An email address is required')
})

type ForgotPasswordProps = {}

type InitialValues = {
  email: string
}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const toast = useToast()

  return (
    <FormWrapper withBack image={images.baotreeHomeBg2} title="Forgot Password">
      <Flex width="100%" direction="column" justify="center" align="center">
        <Image src={images.logo} height="50px" mb={3} />

        <H3 textAlign="center" mb={4}>
          Forgot Password
        </H3>
      </Flex>
      <Formik
        validationSchema={ForgotPasswordValidation}
        initialValues={{
          email: ''
        }}
        onSubmit={async ({ email }, { setSubmitting, setStatus }) => {
          setStatus(null)
          try {
            setSubmitting(true)
            await strapiHelpers.forgotPassword(email)
            setSubmitting(false)
            toast({ title: 'Email sent. Please check your inbox.', ...SUCCESS_TOAST })
            ReactGA.event({
              category: 'Forgot Password',
              action: 'Forgot Password'
            })
          } catch (error) {
            setStatus(formatError(error))
          }
        }}
      >
        {({ isSubmitting, status }: FormikProps<InitialValues>) => (
          <Form autoComplete="off" style={{ width: '100%' }}>
            <FloatingButtonWrapper
              isLoading={isSubmitting}
              buttonTitle={status ? 'Resend' : 'Send reset password link'}
              status={status}
            >
              <Flex mb={6} justify="center">
                <Text fontSize="xs" textAlign="center">
                  Enter your email address below and we&apos;ll send you a link to reset your
                  password.
                </Text>
              </Flex>
              <ConnectedFormGroup
                name="email"
                leftIcon={VscMail}
                label="Email"
                placeholder="e.g. hello@baotree.io"
              />
            </FloatingButtonWrapper>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  )
}

export default ForgotPassword
