import {
  ComponentFaqFaq,
  ComponentTrainingVideoTrainingVideo,
  TrainingVideoEntity,
  useHelpQuery
} from 'generated/graphql'
import { CustomTabContainer, PageListContainer } from 'layouts'
import React from 'react'
import { FcVideoProjector, FcFaq, FcQuestions } from 'react-icons/fc'
import { TrainingVideos, SearchBar, FAQs } from './components'
import { Sidetab } from '@typeform/embed-react'
import { CONTACT_SUPPORT_FORM_ID } from '../../constants'
import { theme } from 'theme'
import { Flex, Heading } from '@chakra-ui/react'

const Help: React.FC = () => {
  const [filters, setFilters] = React.useState<{
    search: string
    category: string
  }>({
    search: '',
    category: ''
  })
  const { loading, data } = useHelpQuery()
  const [helpResults, setHelpResults] = React.useState<TrainingVideoEntity>(
    () => data?.trainingVideo?.data as TrainingVideoEntity
  )

  React.useEffect(() => {
    const filteredData = data?.trainingVideo?.data?.attributes?.training_videos?.filter(
      (video: any) => {
        const searchByAll =
          video.title?.toLowerCase().includes(filters.search.toLowerCase()) ||
          video.category?.toLowerCase().includes(filters.search.toLowerCase()) ||
          video?.tags?.some((tag: any) =>
            tag?.option?.toLowerCase()?.includes(filters.search.toLowerCase())
          ) ||
          video.description?.toLowerCase()?.includes(filters.search.toLowerCase())
        const filterByCategory = video.category
          ?.toLowerCase()
          .includes(filters.category.toLowerCase())

        if (filters.search && filters.category) {
          return searchByAll && filterByCategory
        }
        if (filters.search) {
          return searchByAll
        }
        if (filters.category) {
          return filterByCategory
        }

        return true
      }
    )
    const filteredFAQs = data?.trainingVideo?.data?.attributes?.FAQs?.filter((faq: any) => {
      const searchByAll =
        faq?.question?.toLowerCase().includes(filters.search.toLowerCase()) ||
        faq?.answer?.toLowerCase().includes(filters.search.toLowerCase()) ||
        faq?.category?.toLowerCase().includes(filters.search.toLowerCase()) ||
        faq?.tags?.some((tag: any) =>
          tag?.option?.toLowerCase()?.includes(filters.search.toLowerCase())
        )
      const filterByCategory = faq.category?.toLowerCase().includes(filters.category.toLowerCase())

      if (filters.search && filters.category) {
        return searchByAll && filterByCategory
      }
      if (filters.search) {
        return searchByAll
      }
      if (filters.category) {
        return filterByCategory
      }
      return true
    })
    setHelpResults((prev) => ({
      ...prev,
      attributes: {
        ...prev?.attributes,
        training_videos: filteredData,
        FAQs: filteredFAQs
      }
    }))
  }, [
    data?.trainingVideo?.data?.attributes?.training_videos,
    data?.trainingVideo?.data?.attributes?.FAQs,
    filters
  ])

  const tabs = React.useMemo(
    () => [
      {
        title: 'Training Videos',
        icon: <FcVideoProjector />,
        loading: false,
        bg: 'background.100'
      },
      {
        title: 'FAQs',
        icon: <FcFaq />,
        loading: false,
        bg: 'background.100'
      }
    ],
    []
  )

  const tabPanels = React.useMemo(
    () => [
      {
        component: (
          <TrainingVideos
            loading={loading}
            videos={
              (helpResults?.attributes?.training_videos as ComponentTrainingVideoTrainingVideo[]) ||
              []
            }
          />
        )
      },
      {
        component: (
          <FAQs
            loading={loading}
            FAQs={(helpResults?.attributes?.FAQs as ComponentFaqFaq[]) || []}
          />
        )
      }
    ],
    [helpResults, loading]
  )

  return (
    <PageListContainer
      title="Help"
      showNoProjectBanner={false}
      icon={<FcQuestions />}
      loading={loading}
      stats={[]}
    >
      <Flex py={8} justify="space-between" align="center">
        <Heading color="gray.600">Help and Training</Heading>
      </Flex>

      <Sidetab
        id={CONTACT_SUPPORT_FORM_ID}
        buttonColor={theme.colors.brand[400]}
        buttonText="Contact Us"
      />
      <SearchBar filters={filters} setFilters={setFilters} />
      <CustomTabContainer
        onChange={(index) => {
          console.log(index)
        }}
        tabs={tabs}
        isLazy
        tabPanels={tabPanels}
      />
    </PageListContainer>
  )
}
export default Help
