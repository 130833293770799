import { Box, Text, Link } from '@chakra-ui/react'
import HelpPopoverTrigger from 'components/UI/HelpPopoverTrigger'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'

export const tasksHelpPopoverContent: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        The <b>Task Feed</b> is a live-feed of the data being submitted by your teams. It is a
        record of everything that has been added to your Baotree dashboard and you find all of your
        Tasks here. Use this page to <b>search for specific tasks, filter tasks</b> and{' '}
        <b>download data</b> for analysis.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const excelTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      <b>Download</b> your data from the dashboard to <b>analyse</b> in Excel.
    </Text>
    <Text>
      You can select the information you want to download by using the filters. Click the{' '}
      <b>Task Filters</b> option, to the right, to see the filters.
    </Text>
  </Box>
)
