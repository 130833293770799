import { Box, Flex, Heading, Image } from '@chakra-ui/react'
import React from 'react'

import ReactPlayer from 'react-player'
import { Video } from '../../../../generated/graphql'
import { images } from 'theme'

interface Props {
  autoPlay?: any
  loop?: any
  muted?: any
  video: Video | undefined | null
  controls?: boolean
  onEnd: () => void
}
const VideoPlayer: React.FC<Props> = ({ controls, children, autoPlay, onEnd, video }) => {
  return (
    <Flex direction="column" align="center">
      {video ? (
        <Flex width="100%" direction="column" justify="center">
          <Heading fontSize="sm" color="gray.500" mb={2}>
            {video?.title}
          </Heading>
          <Box overflow="hidden" rounded="3xl">
            <ReactPlayer
              url={video?.videoLink?.data?.attributes?.url}
              playing={autoPlay}
              onEnded={onEnd}
              width="100%"
              height="100%"
              controls={controls}
            />
          </Box>
        </Flex>
      ) : (
        <Image width="50%" src={images.logoNoText} />
      )}
      {children}
    </Flex>
  )
}
VideoPlayer.defaultProps = {
  controls: true,
  autoPlay: false
}
export default VideoPlayer
