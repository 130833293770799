export enum IPField {
  ip = 'ip',
  city = 'city',
  region = 'region',
  region_code = 'region_code',
  country_code = 'country_code',
  country_name = 'country_name',
  country_code_iso3 = 'country_code_iso3',
  country_capital = 'country_capital',
  country_tld = 'country_tld',
  continent_code = 'continent_code',
  in_eu = 'in_eu',
  postal = 'postal',
  latitude = 'latitude',
  longitude = 'longitude',
  timezone = 'timezone',
  utc_offset = 'utc_offset',
  country_calling_code = 'country_calling_code',
  currency = 'currency',
  currency_name = 'currency_name',
  languages = 'languages',
  asn = 'asn',
  org = 'org'
}

export type IPResponse = {
  [IPField.ip]?: string
  [IPField.city]?: string
  [IPField.region]?: string
  [IPField.region_code]?: string
  [IPField.country_code]?: string
  [IPField.country_name]?: string
  [IPField.country_code_iso3]?: string
  [IPField.country_capital]?: string
  [IPField.country_tld]?: string
  [IPField.continent_code]?: string
  [IPField.in_eu]?: boolean
  [IPField.postal]?: string
  [IPField.latitude]?: number
  [IPField.longitude]?: number
  [IPField.timezone]?: string
  [IPField.utc_offset]?: string
  [IPField.country_calling_code]?: string
  [IPField.currency]?: string
  [IPField.currency_name]?: string
  [IPField.languages]?: string
  [IPField.asn]?: string
  [IPField.org]?: string
}
