import { theme as chakraTheme, extendTheme } from '@chakra-ui/react'
import images from './images'

const theme = extendTheme({
  ...chakraTheme,
  fonts: {
    body: 'Roboto, sans-serif',
    mono: 'Roboto Mono, sans-serif',
    heading: 'Roboto, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    lightGreen: {
      100: 'rgba(44,103,52,0.2)'
    },
    gray: {
      ...chakraTheme.colors.gray,
      100: '#FAFAFA'
    },
    google: {
      50: '#FF5E57',
      100: '#FF5E57',
      200: '#FF5E57',
      300: '#FF5E57',
      400: '#FF5E57',
      500: '#FF5E57'
    },
    brand: {
      50: '#a6a6a6',
      100: '#8c8c8c',
      200: '#737373',
      300: chakraTheme.colors.green[400],
      400: '#1b4543',
      500: '#2C6734',
      600: '#201418',
      700: '#201418',
      800: '#201418',
      900: '#201418'
    },
    accent: {
      50: '#fff7db',
      100: '#ffe5ae',
      200: '#fdd57f',
      300: '#fbc44e',
      400: '#fab31f',
      500: '#FAB423',
      600: '#af7700',
      700: '#7e5500',
      800: '#4c3300',
      900: '#1d1000'
    },
    success: {
      50: '#e3fbee',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509'
    },
    text: {
      50: '#313131',
      100: '#313131',
      200: '#313131',
      300: '#313131',
      400: '#000000',
      500: '#000000',
      700: '#000000',
      800: '#000000',
      900: '#000000'
    },
    background: {
      50: '#fefefe',
      100: '#fafafa',
      200: '#f0f0f0',
      300: '#fafafa',
      400: '#fafafa',
      500: '#fafafa',
      600: '#fafafa',
      700: '#fafafa',
      800: '#fafafa',
      900: '#fafafa'
    }
  },
  boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.4)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
})

export { theme, images }
