import { Flex, Button, HStack } from '@chakra-ui/react'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'
import { DATA_COLLECTOR } from '../../../../constants'

import React from 'react'

import { MdOutlineFilterAlt } from 'react-icons/md'
import { VscCloudDownload } from 'react-icons/vsc'
//@ts-ignore
import ReactExport from 'react-data-export'
import { generateExcelDateSet } from 'features/datacollectors/utils'
import {
  Enum_Userspermissionsuser_Status,
  Maybe,
  UsersPermissionsUserEntity,
  useGetCommunitiesQuery,
  useGetProjectsQuery
} from 'generated/graphql'
import { ConnectedSelect } from 'components/forms/FormElements'

import { OptionType } from 'components/forms/FormElements/ConnectedSelect'
import CustomTooltip from 'components/UX/CustomTooltip'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

export type VisibleFiltersType = 'status' | 'project' | 'group'

type TableActionsProps = {
  onExport?: () => void
  onReset?: () => void
  showFilters: boolean
  numberOfFilters: number
  resetForm: (values: any) => void
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>
  baoriders: Maybe<UsersPermissionsUserEntity>[]
  submitForm: () => void
  visibleFilters?: VisibleFiltersType[]
  exportExcelTooltipContent?: React.ReactNode
}

const TableActions: React.FC<TableActionsProps> = ({
  onReset,
  resetForm,
  setShowFilters,
  baoriders,
  showFilters,
  numberOfFilters,
  submitForm,
  visibleFilters,
  exportExcelTooltipContent
}) => {
  const dataSet = generateExcelDateSet(baoriders)
  const { data: projects, loading: projectsLoading } = useGetProjectsQuery()
  const { data: groups, loading: groupsLoading } = useGetCommunitiesQuery()

  const exportExcel = (
    <HStack>
      <ExcelFile
        filename={DATA_COLLECTOR + ' report'}
        element={
          <PrimaryButton leftIcon={<VscCloudDownload />} mr={3}>
            Export Excel
          </PrimaryButton>
        }
      >
        <ExcelSheet dataSet={dataSet} name={DATA_COLLECTOR + ' report'} />
      </ExcelFile>
    </HStack>
  )
  return (
    <Flex
      direction="row"
      flex={1}
      width="100%"
      key={Math.random()}
      align="center"
      justify="flex-end"
    >
      <Flex align="center" flex={1}>
        <ConnectedSelect
          label="Status"
          placeholder="All"
          mr={2}
          name="status"
          options={
            [
              { label: 'Active', value: Enum_Userspermissionsuser_Status.Active },
              { label: 'Archived', value: Enum_Userspermissionsuser_Status.Archived }
            ] as OptionType[]
          }
          onChange={() => submitForm()}
        />
        {visibleFilters?.includes('project') && (
          <ConnectedSelect
            label="Project"
            loading={projectsLoading}
            placeholder="All"
            mr={2}
            name="project"
            options={
              projects?.getBaoleaderProjects?.map((project) => ({
                label: project?.attributes?.name,
                value: project?.id
              })) as OptionType[]
            }
            onChange={() => submitForm()}
          />
        )}
        {visibleFilters?.includes('group') && (
          <ConnectedSelect
            label="Group"
            placeholder="All"
            loading={groupsLoading}
            mr={2}
            name="group"
            options={
              groups?.getBaoleaderCommunities?.map((group) => ({
                label: group?.attributes?.name,
                value: group?.id
              })) as OptionType[]
            }
            onChange={() => submitForm()}
          />
        )}
      </Flex>
      <CustomTooltip label={exportExcelTooltipContent}>{exportExcel}</CustomTooltip>

      <SecondaryButton
        mr={2}
        onClick={() => {
          onReset?.()
          resetForm({})
        }}
      >
        Reset
      </SecondaryButton>

      <Button
        fontSize="xs"
        variant="outline"
        leftIcon={<MdOutlineFilterAlt />}
        onClick={() => setShowFilters(!showFilters)}
      >
        {numberOfFilters}
      </Button>
    </Flex>
  )
}

TableActions.defaultProps = {
  visibleFilters: ['status', 'project', 'group']
}

export default TableActions
