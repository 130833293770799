import { Flex, Text } from '@chakra-ui/react'
import { Form, Formik, FormikProps } from 'formik'
import { LocationDescriptorObject } from 'history'
import * as React from 'react'
import { VscMail } from 'react-icons/vsc'
import ReactGA from 'react-ga4'
import { Link, useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { FormWrapper, FloatingButtonWrapper } from 'components'
import {
  ConnectedFormGroup,
  ConnectedPasswordGroup,
  ConnectedCheckbox
} from 'components/forms/FormElements'
import { useAuthContext } from 'context/AuthProvider'
import { formatError } from 'utils'

const LoginFormValidation = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('An email address is required'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters')
    .required('A password is required')
})

type LoginProps = {}

type InitialValues = {
  email: string
  password: string
}

const Login: React.FC<LoginProps> = () => {
  const { login, isAuthenticated, user } = useAuthContext()
  const history = useHistory()
  const location = useLocation<{ email?: string; redirectTo?: LocationDescriptorObject }>()

  React.useEffect(() => {
    if (isAuthenticated) {
      let to: LocationDescriptorObject = { pathname: '/auth/' }
      if (location.state?.redirectTo) {
        to = { pathname: location.state.redirectTo.pathname }
      }
      history.push(to)
    }
  }, [isAuthenticated, user])

  return (
    <FormWrapper title="Login">
      <Flex w="100%" direction="column">
        <Formik
          validationSchema={LoginFormValidation}
          initialValues={{
            email: location.state?.email || '',
            password: '',
            rememberMe: false
          }}
          onSubmit={async ({ email, password, rememberMe }, { setSubmitting, setStatus }) => {
            setStatus(null)
            try {
              setSubmitting(true)
              if (login) {
                await login(email, password, rememberMe)
              }
              ReactGA.event({
                category: 'Admin login',
                action: 'Login'
              })
              setSubmitting(false)
            } catch (error: any) {
              console.log({ error })
              setStatus(formatError(error))
            }
          }}
        >
          {({ isSubmitting, status }: FormikProps<InitialValues>) => (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FloatingButtonWrapper isLoading={isSubmitting} status={status} buttonTitle="Login">
                <ConnectedFormGroup
                  name="email"
                  leftIcon={VscMail}
                  label="Email address"
                  placeholder="Email"
                />
                <ConnectedPasswordGroup
                  name="password"
                  label="Password"
                  px={4}
                  placeholder="***********"
                />
                <Flex align="center" justify="center" direction="row">
                  <Flex width="50%" align="center" justify="flex-start" pt={1}>
                    <ConnectedCheckbox mt={1} name="rememberMe" label="Remember Me" />
                  </Flex>
                  <Flex width="50%" align="center" justify="flex-end">
                    <Link to="/forgot-password">
                      <Text mb={1} fontSize="xs" fontWeight="bold">
                        Forgot Password?
                      </Text>
                    </Link>
                  </Flex>
                </Flex>
              </FloatingButtonWrapper>
              <Link to="/terms-and-conditions.pdf" target="_blank">
                <Text mb={1} fontSize="xs" textAlign="center" mt={16}>
                  Terms and Conditions
                </Text>
              </Link>
            </Form>
          )}
        </Formik>
        <Text mt={8} fontSize="sm" textAlign="center">
          Don&apos;t have an account?{' '}
          <Link style={{ color: 'dodgerblue' }} to="/register">
            Register
          </Link>
        </Text>
      </Flex>
    </FormWrapper>
  )
}

export default Login
