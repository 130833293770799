import { Flex } from '@chakra-ui/react'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import React, { memo } from 'react'

const MapContainer: React.FC<{
  google: any
  lat: number | null
  lng: number | null
  markers?: { lat: number | null; lng: number | null }[] | null
  onMarkerDrop?: (lat: number, lng: number) => void
  dragMarkers?: boolean
}> = ({ google, lat, lng, markers, onMarkerDrop, dragMarkers }) => {
  return (
    <Flex position="relative" w="100%" h="100%">
      <Map
        google={google}
        initialCenter={{ lat: lat || -25.83466820438054, lng: lng || 28.298358430266173 }}
        center={{ lat: lat || -25.83466820438054, lng: lng || 28.298358430266173 }}
        style={{ width: '100%', height: '100%' }}
      >
        {markers?.length &&
          markers
            .filter((marker) => marker.lat && marker.lng)
            .map((marker) => (
              <Marker
                // @ts-ignore
                key={marker}
                draggable={dragMarkers}
                mapCenter={{
                  lat: marker.lat,
                  lng: marker.lng
                }}
                position={{
                  lat: marker.lat,
                  lng: marker.lng
                }}
                onDragend={(_event: any, _data: any, { latLng }: any) => {
                  onMarkerDrop && onMarkerDrop(latLng.lat(), latLng.lng())
                }}
              />
            ))}
      </Map>
    </Flex>
  )
}

export default GoogleApiWrapper((_props) => ({
  apiKey: 'AIzaSyBPLbOsd07e28ioIXwb3EK3BXIBJz8HeZY'
}))(memo(MapContainer))
