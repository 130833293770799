import React from 'react'
import strapiHelpers from '../../../../utils/strapiHelpers'
import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'
import { Box, Heading, Text } from '@chakra-ui/react'
import { useIsSubscribed } from 'hooks'

import { PopupButton } from '@typeform/embed-react'
import { SUBSCRIPTIONS_TYPEFORM_ID } from '../../../../constants'

const SubscriptionTab: React.FC = () => {
  const { subscriptionStatus, isSubscribed } = useIsSubscribed()
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <Box>
      <Heading size="lg">Your Subscription</Heading>
      <Text py={2} fontSize="sm" w="50%">
        {subscriptionStatus === 'trialing'
          ? 'You’re currently enjoying your 14-day free trial! For more information about your plan, and when your free trial ends, please click Manage Subscription below'
          : `Your subscription is currently ${subscriptionStatus}`}
        ,{' '}
        {isSubscribed
          ? 'thank you!'
          : 'please check your subscription info to continue using our services.'}
      </Text>
      <PrimaryButton
        my={8}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true)
          await strapiHelpers?.openBillingPortal()
          setIsLoading(false)
        }}
      >
        Manage Subscription
      </PrimaryButton>
      <Text fontSize="sm" marginTop={'4vh'}>
        Need support with your subscription? Get in touch with our Sales team.
      </Text>

      <PopupButton
        id={SUBSCRIPTIONS_TYPEFORM_ID}
        size={80}
        onReady={() => {
          console.log('form ready')
        }}
        enableSandbox
      >
        <SecondaryButton my={2} isLoading={isLoading}>
          Get in touch
        </SecondaryButton>
      </PopupButton>
    </Box>
  )
}

export default SubscriptionTab
