import {
  Box,
  Text,
  Link,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import FieldTypeAndDescriptionPair from 'features/task-builder/components/FieldTypeAndDescriptionPair'
import React from 'react'
import { VscQuestion } from 'react-icons/vsc'
import { dataFieldsDescription } from './data'

export const createTaskTitleHelperPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>Use this page to create new Task Templates for your Data Collectors.</Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const addFieldButtonHelperTooltip: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>Use this button to add questions to the Task Template.</Text>
  </Box>
)

const FieldTypeToPickHelperPopoverButton = (
  <Button leftIcon={<VscQuestion />} fontSize="x-small" mr={2} my={2} variant="ghost">
    Which option should I choose?
  </Button>
)

export const fieldTypeToPickHelperPopover: React.ReactNode = (
  <Popover>
    <PopoverTrigger>{FieldTypeToPickHelperPopoverButton}</PopoverTrigger>
    <PopoverContent w="50vw" overflowY="auto" maxH="35vh">
      <PopoverArrow />
      <PopoverCloseButton />

      <PopoverBody>
        <Box px={4} py={6}>
          <FieldTypeAndDescriptionPair fields={dataFieldsDescription} />
          <br />
          <br />
          <Text>
            For more, visit our{' '}
            <Text as="b">
              <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
                help and training
              </Link>
            </Text>{' '}
            section.
          </Text>
        </Box>
      </PopoverBody>
    </PopoverContent>
  </Popover>
)

export const saveAsDraftButtonHelperTooltip: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>Use this button to save a Task Template you are not finished building.</Text>
  </Box>
)

export const saveAndPublishButtonHelperTooltip: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>Use this button to save your completed Task Template.</Text>
    <br />
    <Text>
      <b>Tip</b>: Don&apos;t forget to add this Task Template to the relevant project so your Data
      Collectors can access it on the app.
    </Text>
  </Box>
)

export const requiredFieldHelperTooltip: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>
      This toggle allows you to set individual questions as required fields. Data Collectors will
      not be able to submit their tasks unless all required fields are completed.
    </Text>
    <br />
    <Text>
      Tip: Use this feature to ensure tasks are submitted with all critical information in helping
      you track impact and/or monitor project progress.
    </Text>
  </Box>
)
