import {
  Flex,
  Select,
  Spinner,
  FlexProps,
  Text,
  Heading,
  Image,
  Center,
  Skeleton,
  Link
} from '@chakra-ui/react'
import React, { ReactNode, useMemo } from 'react'
import { ArrowDown, ArrowUp } from 'react-feather'

import { Column, ColumnInstance, HeaderGroup, Row, useSortBy, useTable } from 'react-table'
import { images } from '../../../theme'

import Card from '../Card'

import EmptyListHandler from '../../utils/EmptyListHandler'

import SearchBar from '../../forms/SearchBar'
import { StyledTable, TableCell, TableHead, TableRow } from './styles'

import { AnimatePresence } from 'framer-motion'
import TableLoader from '../Loaders/TableLoader'

// use declaration merging to extend types, example file:
// https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
// caveat - these interfaces are global

declare module 'react-table' {
  export interface TableOptions<D extends object>
    extends UsePaginationOptions<D>,
      UseFiltersOptions<D> {}

  export interface TableInstance<D extends object = {}> extends UsePaginationInstanceProps<D> {}

  export interface TableState<D extends object = {}> extends UsePaginationState<D> {}

  export interface ColumnInstance<D extends object = {}> extends UseSortByColumnProps<D> {}
}

type TableProps<D extends object = {}> = {
  data: any
  pageSize?: number
  tableHeading: ReactNode
  columns: Column<D>[]
  searchValue?: string
  help?: ReactNode
  onRowClick?: (row: Row<D>) => void
  isLoading?: boolean
  tableActions?: () => ReactNode
  titleActions?: () => ReactNode
  tableToolbar?: () => ReactNode
  onSearch?: (text: string) => void
  onClickNext?: () => Promise<any>
  searchPlaceholder?: string
  renderRowSubComponent?: (row: Row<D>) => ReactNode
  setSelectedFilter?: React.Dispatch<React.SetStateAction<string>>
  selectedFilter?: string
  emptyListComponent?: ReactNode
  searchFilterOptions?: {
    label: string
    value: string
  }[]
}

const resize: FlexProps = {
  maxW: '4.5rem',
  justify: 'center',
  align: 'center',
  textAlign: 'center',
  border: '1px soild green'
}

const resizable = (column: ColumnInstance<any>) =>
  column.Header === ' ' || column.Header === 'Avatar'

const Table = <D extends {}>({
  columns,
  data,
  tableHeading,
  pageSize: initialPageSize,
  onRowClick,
  isLoading,
  tableActions,
  titleActions,
  tableToolbar,
  help,
  onSearch,
  searchValue,
  searchPlaceholder,
  setSelectedFilter,
  renderRowSubComponent,
  searchFilterOptions,
  emptyListComponent
}: TableProps<D>) => {
  const tableColumns = useMemo(() => columns, [columns])
  const tableData = useMemo(() => data, [data])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<D>(
    {
      columns: tableColumns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: initialPageSize }
    },
    useSortBy
  )

  return (
    <>
      <Flex mb={4} align="center" justify="space-between">
        <Flex justify="flex-start" align="center">
          <Heading color="gray.600" size="lg">
            {tableHeading}{' '}
          </Heading>
          {help ? (
            help
          ) : (
            <Link mx={2} fontWeight="semibold" color="gray.500" fontSize="xs" href="/auth/help">
              Help and Training
            </Link>
          )}
        </Flex>
        {titleActions?.()}
      </Flex>

      <Flex p={4} width="100%" justifyContent="space-between" alignItems="center">
        <Flex align="center" width="100%">
          <Center mr={2}>
            {isLoading ? (
              <Spinner size="xs" color="brand.300" m={2} />
            ) : (
              <Image minW={7} maxW={7} src={images.logoNoText} />
            )}
          </Center>
          {!!onSearch && (
            <Skeleton rounded="lg" isLoaded={!isLoading}>
              <SearchBar
                searchValue={searchValue}
                isLoading={isLoading}
                variant="filled"
                minW={72}
                placeholder={searchPlaceholder}
                onSearch={onSearch}
              />
            </Skeleton>
          )}

          {!!setSelectedFilter && (
            <Select
              maxW={32}
              mx={2}
              variant="filled"
              fontSize="x-small"
              placeholder="Select filter"
              name="employerFilter"
              onChange={({ target }) => {
                if (setSelectedFilter) {
                  setSelectedFilter(target.value)
                }
              }}
            >
              {searchFilterOptions
                ? searchFilterOptions.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))
                : tableColumns
                    .filter((column) => column.Header !== 'Avatar')
                    .map((item, index) => (
                      <option key={index} value={item.accessor as string}>
                        {item.Header}
                      </option>
                    ))}
            </Select>
          )}
          {tableActions && tableActions()}
        </Flex>
      </Flex>
      {tableToolbar && (
        <Flex w="100%" direction="column">
          {tableToolbar()}
        </Flex>
      )}
      {data?.length === 0 && !isLoading ? (
        emptyListComponent ? (
          emptyListComponent
        ) : (
          <EmptyListHandler bg="transparent" />
        )
      ) : (
        <Card
          flexDirection="column"
          border="none"
          bg="transparent"
          flex={1}
          maxWidth="100%"
          width="100%"
        >
          <StyledTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup: HeaderGroup<D>, idx) => (
                <Flex flex={1} flexDirection="row" {...headerGroup.getHeaderGroupProps()} key={idx}>
                  {headerGroup.headers.map((column: ColumnInstance<D>, idx) => (
                    <TableCell
                      py={3}
                      px={4}
                      fontWeight={600}
                      {...column.getHeaderProps()}
                      justifyContent="space-between"
                      {...column.getSortByToggleProps()}
                      {...(resizable(column) && { ...resize })}
                      key={idx}
                    >
                      <Text fontSize="xs" color="gray.500" width="100%">
                        {column.render('Header')}
                      </Text>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDown size={16} />
                        ) : (
                          <ArrowUp size={16} />
                        )
                      ) : (
                        ''
                      )}
                    </TableCell>
                  ))}
                </Flex>
              ))}
            </TableHead>
            <Flex flexDirection="column" {...getTableBodyProps()}>
              {isLoading ? (
                <TableLoader n={headerGroups?.[0].headers?.length - 1 || 7} />
              ) : (
                rows.map((row, key) => {
                  return (
                    <AnimatePresence key={key}>
                      {
                        // @ts-ignore
                        prepareRow(row) || (
                          <TableRow
                            layout
                            // style={{ position: isPresent ? 'relative' : 'absolute' }}
                            onClick={() => onRowClick && onRowClick(row)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell, idx) => {
                              return (
                                <TableCell
                                  justifyContent="flex-start"
                                  p={4}
                                  fontSize="xs"
                                  {...(resizable(cell.column) && { ...resize })}
                                  {...cell.getCellProps()}
                                  key={idx}
                                >
                                  {cell.render('Cell')}
                                </TableCell>
                              )
                            })}
                            {renderRowSubComponent && renderRowSubComponent(row)}
                          </TableRow>
                        )
                      }
                    </AnimatePresence>
                  )
                })
              )}
            </Flex>
          </StyledTable>
        </Card>
      )}
    </>
  )
}

export default Table

Table.defaultProps = {
  pageSize: 10
}
