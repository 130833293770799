import { Flex, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { memo } from 'react'
import { FloatingButtonWrapper, ModalWrap } from '../../../components'

import ConnectedFormGroup from '../../../components/forms/FormElements/ConnectedFormGroup'
import ConnectedSelect, { OptionType } from '../../../components/forms/FormElements/ConnectedSelect'
import { DATA_COLLECTOR, ERROR_TOAST } from '../../../constants'
import { UpdateCommunityMemberMutation, useGetCommunitiesQuery } from '../../../generated/graphql'
import { CommunityMember } from '../../../utils/types'
import { addCommunityMemberSchema } from '../../../utils/validationSchemas'

import ConnectedPhoneInput from '../../../components/forms/FormElements/ConnectedPhoneInput'
import Countries from 'world-countries'
import { find } from 'lodash'

import { VscAccount } from 'react-icons/vsc'
import ConnectedCheckbox from '../../../components/forms/FormElements/ConnectedCheckbox/index'

import { FetchResult } from 'apollo-boost'
import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'
import { formatError } from 'utils'
import { useIPLookup } from 'hooks'
import { IPField } from 'hooks/useIPLookup/types'

const countryOptions = Countries.map(({ name, idd, flag }) => ({
  name: name.common,
  code: idd,
  flag: flag
}))

const CommunityMemberModal: React.FC<{
  editableCommunityMember: CommunityMember
  selectCommunity?: boolean
  isEditMode: boolean
  isOpen: boolean
  isLoading: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: (
    communityMember: CommunityMember,
    isVerifier: boolean,
    addAnother: boolean
  ) => Promise<
    | FetchResult<UpdateCommunityMemberMutation, Record<string, any>, Record<string, any>>
    | FetchResult<any>
    | FetchResult<any>
    | undefined
  >
}> = ({
  isOpen,
  onClose,
  editableCommunityMember,
  isEditMode,
  onSubmit,
  isLoading,
  selectCommunity
}) => {
  const toast = useToast()
  const { country_name } = useIPLookup([IPField.country_name])
  const onError = (error: any) =>
    toast({ title: formatError(error) || 'There was an error.', ...ERROR_TOAST })

  const { data: storedCommunities } = useGetCommunitiesQuery({
    onError
  })

  const submitForm = async (
    { isVerifier, addAnother, ...values }: any,
    { resetForm, setSubmitting }: any
  ) => {
    setSubmitting(true)
    try {
      if (!values?.hasConsent) {
        toast({
          title: 'Consent from community member is required.',
          ...ERROR_TOAST,
          status: 'warning'
        })
        setSubmitting(false)
        return
      }

      const user = await onSubmit(values as CommunityMember, isVerifier, addAnother)

      if (addAnother && user?.data)
        resetForm({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          gender: '',
          hasConsent: false,
          isVerifier: true
        })
    } catch (error) {
      console.log(error)
    }
    setSubmitting(false)
  }

  const communityOptions = React.useMemo(
    () =>
      ((storedCommunities?.getBaoleaderCommunities || []).map((item) => {
        return { label: item?.attributes?.name, value: item?.id }
      }) as OptionType[]) || [],
    [storedCommunities]
  )

  return (
    <ModalWrap
      title={`Create ${
        editableCommunityMember.isVerifier ? 'Group ' + DATA_COLLECTOR : 'Group Member'
      }`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          ...editableCommunityMember
        }}
        validationSchema={addCommunityMemberSchema}
        onSubmit={submitForm}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, status }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <FloatingButtonWrapper
              status={status}
              isLoading={isSubmitting}
              button={
                <Flex flex={1} justify="flex-end" align="center">
                  <SecondaryButton isLoading={isLoading} mr={3} onClick={onClose}>
                    Cancel
                  </SecondaryButton>

                  <PrimaryButton flex={1} type="submit" isLoading={isSubmitting}>
                    {isEditMode ? 'Update' : 'Add'}
                  </PrimaryButton>
                </Flex>
              }
            >
              <Flex>
                <ConnectedFormGroup
                  name="firstName"
                  label="First name *"
                  mr={1}
                  w="50%"
                  leftIcon={VscAccount}
                  placeholder="Enter first name"
                />
                <ConnectedFormGroup
                  name="lastName"
                  leftIcon={VscAccount}
                  flex={1}
                  w="50%"
                  label="Last name *"
                  placeholder="Enter last name"
                />
              </Flex>
              <ConnectedSelect
                name="gender"
                label="Gender"
                placeholder="Select Gender"
                showIsValidValue
                options={
                  [
                    { label: 'Male', value: 'Male' },
                    { label: 'Female', value: 'Female' }
                  ] as OptionType[]
                }
              />

              <ConnectedPhoneInput
                label="Phone Number *"
                name="phoneNumber"
                mb={!selectCommunity ? 10 : undefined}
                setFieldValue={setFieldValue}
                country={find(countryOptions, ['name', country_name || 'Kenya'])}
                options={countryOptions}
              />

              {selectCommunity && (
                <ConnectedSelect
                  name="community"
                  label="Community"
                  placeholder="Select a Group"
                  options={communityOptions}
                />
              )}
              <ConnectedCheckbox
                name="hasConsent"
                mb={3}
                label="You have been given permission to upload these details."
              />

              <ConnectedCheckbox name="addAnother" label=" Add Another" />
            </FloatingButtonWrapper>
          </Form>
        )}
      </Formik>
    </ModalWrap>
  )
}

export default memo(CommunityMemberModal)
