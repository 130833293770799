import get from 'lodash/get'
import {
  Enum_Userspermissionsuser_Status,
  Maybe,
  TaskEntity,
  UploadFileEntity
} from 'generated/graphql'
import { STRAPI_USER_STORAGE_KEY } from '../constants'

import { images } from 'theme'
import { Enum_Task_Status } from 'generated/graphql'
import axios from 'axios'

/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = () => {
  const localUser = localStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export function formatError({ response }: any) {
  return get(response, 'data.error.details[0].messages[0].message', 'An unknown error has occured.')
}
export const getTaskStatuses = () => {
  const statuses: { [key: string]: string } = { ...Enum_Task_Status }
  return Object.keys(statuses)
    .sort()
    .map((key) => {
      return {
        label: statuses[key]?.split('_').join(' '),
        value: statuses[key]
      }
    })
}
export const statusHelper = (status: string, hex?: boolean) => {
  const statusColors: { [key: string]: string } = {
    ACTIVE: hex ? '#82b79b' : 'green',
    INACTIVE: hex ? '#b77575' : 'red',
    IN_PROGRESS: hex ? '#bee3f8' : 'blue',
    REPORTED: hex ? '#bee3f8' : 'blue',
    COMPLETED: hex ? '#82b79b' : 'green',
    URGENT: hex ? '#b77575' : 'red',
    CANCELLED: hex ? '#b77575' : 'red',
    REJECTED: hex ? '#b77575' : 'red',
    PENDING: hex ? '#fefbbf' : 'yellow',
    MORE_INFO_REQUESTED: hex ? '#9a88be' : 'purple',
    ARCHIVED: hex ? '#edf2f7' : 'red',
    LIVE: hex ? '#9a88be' : 'purple'
  }
  const icons: { [key: string]: string } = {
    ACTIVE: 'VscCircleFilled',
    INACTIVE: 'VscClose',
    IN_PROGRESS: 'VscIssueDraft',
    REPORTED: 'VscLoading',
    COMPLETED: 'VscCheck',
    URGENT: 'VscWarning',
    CANCELLED: 'VscClose',
    REJECTED: 'VscClose',
    PENDING: 'VscIssueDraft',
    MORE_INFO_REQUESTED: 'VscLoading',
    ARCHIVED: 'VscClose',
    LIVE: 'VscCircleFilled'
  }
  return {
    title: status.toUpperCase().replace('_', ' '),
    color: statusColors[status] || 'gray.500',
    icon: icons[status] || 'VscCircleFilled'
  }
}
export function* arrayRange(start: number, end: number, step: number) {
  while (start < end) {
    yield start
    start += step
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getImageByFileExtension(fileName: string) {
  const fileExtension = fileName.split('.').pop()
  switch (fileExtension) {
    case 'pdf':
      return images.pdf
    case 'doc':
    case 'docx':
      return images.word
    case 'xls':
    case 'xlsx':
      return images.excel
    case 'ppt':
    case 'pptx':
      return images.powerpoint
    case 'zip':
    case 'rar':
      return 'zip.svg'
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'image.svg'
    case 'mp3':
    case 'wav':
    case 'aac':
      return 'audio.svg'
    case 'mp4':
    case 'avi':
    case 'mkv':
      return 'video.svg'
    default:
      return images.logoNoText
  }
}

export function pluralHandler(string: string, count: number) {
  const y = string.endsWith('y')
  const finalString = y ? string.slice(0, -1) : string
  if (count > 1) {
    return y ? finalString + 'ies' : finalString + 's'
  }
  return string
}

export function parseDot(str: string) {
  return str?.split('&dot;')?.join('.')
}

export const swapArrayLocs = (arr: any[], from: number, to: number) => {
  // remove `from` item and store it
  const f = arr.splice(from, 1)[0]
  // insert stored item into position `to`
  arr.splice(to, 0, f)
}
export const getAppPath = (location: any) =>
  [location.pathname, location.search, location.hash].join('')

export const getImages = (task: Maybe<TaskEntity>) => {
  let hasImageField = false
  const evedence = task?.attributes?.evidences?.data?.[0]?.attributes

  const customFields = evedence?.custom_data_fields || []
  const fieldImages = []
  for (const field of customFields) {
    if (field?.__typename === 'ComponentCustomDataFieldsImageField') {
      const images = field?.images?.data
      if (images && images?.length > 0) {
        hasImageField = true
        fieldImages.push(...images)
      }
    }
  }
  return {
    hasImages: (evedence?.photos && evedence?.photos?.data?.length > 0) || hasImageField,
    images: (evedence?.photos?.data || []).concat(fieldImages)
  }
}

export const getStatuses = () => {
  const statuses: { [key: string]: string | number } = { ...Enum_Userspermissionsuser_Status }
  return Object.keys(statuses).map((key) => {
    return { label: statuses[key], value: statuses[key] }
  })
}

export const disableReactDevTools = () => {
  const noop = () => undefined
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null
    }
  }
}

export const pathNameExists = (pathname: string, pathnames: string[]) => {
  return pathnames.some((path) => pathname.includes(path))
}

/**
 * A utility function that wraps axios as `fetch`.
 * There's still a lot of work to be done here, but this is a start.
 * @param url the url to fetch
 * @param method the HTTP method to use
 * @param _options any request options, and headers (to be designed)
 */
export const fetch = async (url: string, method = 'GET', _options?: any): Promise<any> => {
  if (method === 'GET') {
    const response = await axios.get(url).then((response) => {
      return response.data
    })
    return response
  } else {
    throw new Error('Method not supported')
  }
}

export const tranformObjectToQueryString = (obj: any) => {
  const str = []
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
}

export const removeQueryParams = (url: string) => {
  return url.split('?')[0]
}

export const replaceQuotationMarks = (str: string) => {
  return str.replace(/[”“]+/g, "'")
}

export const getImageByFormat = (
  image: Maybe<UploadFileEntity> | undefined,
  format = 'thumbnail'
) => {
  return image?.attributes?.formats?.[format]?.url || image?.attributes?.url || images.logoNoText
}
