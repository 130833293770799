import { Flex, HStack, IconButton, Text } from '@chakra-ui/react'
import { PrimaryButton } from 'components/UI/Buttons'
import React from 'react'
import { VscAdd, VscArrowLeft } from 'react-icons/vsc'
import { STAGE } from '../../..'
import { createDataListButtonHelperPopover } from 'features/task-builder/list/help'

type ActionListProps = {
  stage: STAGE
  setStage: React.Dispatch<React.SetStateAction<STAGE>>
}

const ActionList: React.FC<ActionListProps> = ({ stage, setStage }) => {
  return (
    <HStack spacing={4}>
      {stage !== STAGE.list && (
        <HStack cursor="pointer" onClick={() => setStage(STAGE.list)} fontSize="xs">
          <IconButton aria-label="back" rounded="full" icon={<VscArrowLeft />} /> <Text>List</Text>
        </HStack>
      )}

      {stage === STAGE.list && (
        <Flex align="center">
          <PrimaryButton leftIcon={<VscAdd />} onClick={() => setStage(STAGE.create)}>
            Create List
          </PrimaryButton>
          {createDataListButtonHelperPopover}
        </Flex>
      )}
    </HStack>
  )
}

export default ActionList
