import { css } from '@emotion/react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import * as React from 'react'
import { Grid, GridProps } from 'react-flexbox-grid'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { theme } from '../../../../theme'

export type StyledContainerProps = GridProps &
  SpaceProps &
  ColorProps & {
    color?: string
    noPadding?: boolean
  }

const StyledContainer = styled(Grid, { shouldForwardProp: isPropValid })`
  width: 100%;
  ${space};
  ${color};
  ${({ noPadding }: StyledContainerProps) => {
    if (theme.gridGutter) {
      return css`
        padding-left: ${noPadding ? 0 : theme.gridGutter}rem !important;
        padding-right: ${noPadding ? 0 : theme.gridGutter}rem !important;
      `
    } else {
      return css`
        padding-left: 1rem;
        padding-right: 1rem;
      `
    }
  }};
`

const CustomContainer: React.FC<StyledContainerProps> = ({ children, ...rest }) => {
  return <StyledContainer {...rest}>{children}</StyledContainer>
}

export default CustomContainer
