import { Box, Flex, FlexProps, Image } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Variants } from 'framer-motion'
import * as React from 'react'
import { MotionFlex } from '../..'
import { images } from '../../../theme'
import Version from '../../utils/Version'

const PanelWrapper = styled(MotionFlex)`
  top: 0;
  left: 0;
  bottom: 0;
  width: 360px;
  display: flex;
  max-width: 100%;
  position: fixed;
  overflow-y: auto;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const variants: Variants = {
  hidden: {
    x: '-350px'
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 40
    }
  }
}

const SideSlider: React.FC<FlexProps> = ({ children, justify }) => {
  return (
    <PanelWrapper
      p={7}
      m={[4, 0]}
      bg="white"
      initial="hidden"
      animate="visible"
      rounded={['md', 0]}
      variants={variants}
    >
      <Flex width="100%" flex={1} flexDir="column" justify="space-between" align="center">
        <Flex
          align="center"
          flexDir="column"
          flex={1}
          minHeight="100%"
          width="100%"
          justify={justify}
        >
          <Image boxSize="100px" src={images.logoNoText} />
          <Box width="100%">{children}</Box>
          <Version />
        </Flex>
      </Flex>
    </PanelWrapper>
  )
}

export default SideSlider

SideSlider.defaultProps = {
  justify: 'space-around'
}
