import { Box, Flex, Heading, Text, Image, Stack, VStack } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'

import { useHistory } from 'react-router-dom'
import { StatusBadge } from '../../../components'
import { DATA_COLLECTOR } from '../../../constants'
import Card from '../../../components/UI/Card/index'
import { images } from 'theme'
import {
  useCountRidersQuery,
  useCountTasksQuery,
  useGetCommunityProjectsCountQuery
} from '../../../generated/graphql'
import { VscGroupByRefType } from 'react-icons/vsc'
import { SlidingCardContainer } from 'components/UX/SlidingCardContainer'
import { CommunityEntity, Maybe } from '../../../generated/graphql'

const CommunityCard: React.FC<{ community: Maybe<CommunityEntity> }> = ({ community }) => {
  const history = useHistory()

  const { data: dataCollectorCount } = useCountRidersQuery({
    variables: {
      where: {
        community: community?.id || ''
      }
    }
  })
  const { data: storedCountProjects } = useGetCommunityProjectsCountQuery({
    variables: {
      community: community?.id || ''
    }
  })

  const { data: tasksCount } = useCountTasksQuery({
    variables: {
      filters: {
        baorider: { community: { id: community?.id || '' } }
      }
    }
  })

  return (
    <SlidingCardContainer
      cursor="pointer"
      onClick={() => {
        history.push(`/auth/communities/${community?.id}`)
      }}
    >
      <div className="community-card-front">
        <Card height="100%" border="1px solid" p={6}>
          <Flex mb={4} justify="space-between">
            <Image
              src={
                community?.attributes?.featured_image?.data?.attributes?.formats?.thumbnail?.url
                  ? community.attributes.featured_image?.data.attributes?.formats?.thumbnail?.url
                  : images.logoNoText
              }
              objectFit="cover"
              align="community profile"
              boxSize="40px"
              rounded="2xl"
            />
            <Stack ml={4}>
              <Text fontSize="xx-small" color="gray.500">
                Created {moment(community?.attributes?.createdAt).fromNow()}
              </Text>
              {community?.attributes?.type && (
                <Flex align="center">
                  <Box
                    mr={2}
                    _after={{
                      content: '""',
                      position: 'absolute',
                      bottom: '-0.5rem',
                      left: 0,
                      borderTop: '1px solid',
                      borderColor: 'green.500',
                      width: '100%',
                      background: 'green'
                    }}
                    position="relative"
                  >
                    <StatusBadge bg="green.50" color="green.500" p={1}>
                      <VscGroupByRefType />
                    </StatusBadge>
                  </Box>
                  <Heading color="gray.400" fontSize="x-small">
                    {community?.attributes?.type}
                  </Heading>
                </Flex>
              )}
            </Stack>
          </Flex>
          <Heading as="h2" size="sm" my={2}>
            {community?.attributes?.name}
          </Heading>
          <Text fontSize="x-small" noOfLines={2} color="gray.500">
            {community?.attributes?.description}
          </Text>
        </Card>
      </div>
      <div className="community-card-back">
        <Card
          shadow="none"
          height="100%"
          border="1px solid"
          borderColor="gray.200"
          bg="white"
          py={6}
          px={8}
        >
          <Flex
            flex={1}
            fontSize={15}
            align="flex-end"
            justify="space-between"
            textAlign="center"
            px={3}
          >
            <VStack spacing={0}>
              <Text color="gray.500" fontSize="x-small" textAlign="center" fontWeight="medium">
                {DATA_COLLECTOR}s
              </Text>
              <Heading as="h5" size="xs">
                {dataCollectorCount?.countRiders || 0}
              </Heading>
            </VStack>
            <VStack spacing={0}>
              <Text color="gray.500" fontSize="x-small" textAlign="center" fontWeight="medium">
                Projects
              </Text>
              <Heading as="h5" size="xs">
                {storedCountProjects?.getCommunityProjectsCount || 0}
              </Heading>
            </VStack>
            <VStack spacing={0}>
              <Text color="gray.500" fontSize="x-small" textAlign="center" fontWeight="medium">
                Tasks Reported
              </Text>
              <Heading as="h5" size="xs">
                {tasksCount?.countTasks || 0}
              </Heading>
            </VStack>
          </Flex>
        </Card>
      </div>
    </SlidingCardContainer>
  )
}

export default CommunityCard
