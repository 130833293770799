import {
  SimpleGrid,
  VStack,
  Flex,
  HStack,
  Heading,
  Skeleton,
  Center,
  Image,
  Box,
  Text
} from '@chakra-ui/react'
import { Card, StatusBadge } from 'components'
import {
  GetSingleBaoleaderProjectQuery,
  useGetProjectGroupsQuery,
  useGetProjectUnsdgTagsQuery
} from 'generated/graphql'
import moment from 'moment'
import React from 'react'
import { FcCollaboration } from 'react-icons/fc'

import { useHistory, useParams } from 'react-router-dom'
import { images } from 'theme'

type ProjectDetailsProps = {
  projectLoading: boolean
  storedProject: GetSingleBaoleaderProjectQuery | undefined
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({ projectLoading, storedProject }) => {
  const thumbnail =
    storedProject?.getSingleBaoleaderProject?.attributes?.featured_image?.data?.attributes?.formats
      ?.thumbnail?.url

  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { data: projectTags } = useGetProjectUnsdgTagsQuery({
    variables: {
      project: id
    }
  })
  const { data: projectGroups } = useGetProjectGroupsQuery({
    variables: {
      projectId: id
    }
  })
  const unsdgTags = projectTags?.getProjectUnsdgTags?.filter((tag) => tag !== null) || []

  return (
    <SimpleGrid templateColumns={{ md: '2fr 1fr', sm: '1fr' }} spacing={10}>
      <VStack spacing={6}>
        <Card width="100%">
          <Flex direction="column" height="100%" width="100%" flex={1}>
            <Box width="100%" px={6}>
              <Flex w="100%" py={4} direction="row" align="center">
                <Flex w="100%" direction="row" align="center">
                  <Image
                    src={thumbnail ? thumbnail : images.logoNoText}
                    boxSize={10}
                    draggable="false"
                    rounded="2xl"
                    objectFit="cover"
                    mr={3}
                  />
                  <VStack alignItems="flex-start" spacing={1}>
                    <HStack spacing={4} w="100%">
                      <Heading as="h5" fontWeight="semibold" size="xs">
                        <Skeleton isLoaded={!projectLoading} minW={50}>
                          {storedProject?.getSingleBaoleaderProject?.attributes?.name}
                        </Skeleton>
                      </Heading>
                    </HStack>
                    <Skeleton isLoaded={!projectLoading}>
                      <Text fontSize="x-small" color="gray.500" width="100%">
                        {moment(
                          storedProject?.getSingleBaoleaderProject?.attributes?.createdAt
                        ).format('LLLL')}
                      </Text>
                    </Skeleton>
                  </VStack>
                </Flex>
                <Flex direction="row" align="center" ml={4} justify="flex-end">
                  <Skeleton isLoaded={!projectLoading}>
                    <StatusBadge
                      status={storedProject?.getSingleBaoleaderProject?.attributes?.status}
                      withIcon
                    />
                  </Skeleton>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          {storedProject?.getSingleBaoleaderProject?.attributes?.description && (
            <Box px={8} py={3}>
              <Skeleton isLoaded={!projectLoading} my={3} w="fit-content" minW={200}>
                <Text fontWeight="500" color="gray.600">
                  Description
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!projectLoading} minW={200}>
                <Text color="gray.500" fontSize="xs">
                  {storedProject?.getSingleBaoleaderProject?.attributes?.description}
                </Text>
              </Skeleton>
            </Box>
          )}
          <HStack px={8} py={4} spacing={6} justify="space-between">
            <Box>
              <Skeleton isLoaded={!projectLoading} my={3} w="fit-content" minW={200}>
                <Text fontSize="xs" color="gray.500">
                  Project Description
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!projectLoading} mb={3} w="fit-content" minW={200}>
                <Text fontSize="xs">
                  {storedProject?.getSingleBaoleaderProject?.attributes?.statement}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!projectLoading} my={3} w="fit-content" minW={200}>
                <Text fontSize="xs" color="gray.500">
                  Outcomes
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!projectLoading} mb={3} w="fit-content" minW={200}>
                <Text fontSize="xs">
                  {storedProject?.getSingleBaoleaderProject?.attributes?.outcomes}
                </Text>
              </Skeleton>
            </Box>
          </HStack>
        </Card>
      </VStack>
      <VStack spacing={8}>
        <Card p={8} width="100%">
          <Skeleton isLoaded={!projectLoading} my={3} w="fit-content" minW={200}>
            <Flex fontSize="sm" align="center">
              <Image src={images.unLogo} boxSize={7} />
              <Text ml={2} color="gray.500">
                Un SDGs
              </Text>
            </Flex>
          </Skeleton>
          <Skeleton isLoaded={!projectLoading} borderRadius="2xl">
            <Flex dir="row" mb={3}>
              {unsdgTags?.length > 0 ? (
                unsdgTags?.map((tag, index) => (
                  <StatusBadge
                    py={1}
                    mr={3}
                    key={index}
                    px={2}
                    fontWeight="semibold"
                    borderRadius="2xl"
                  >
                    {tag}
                  </StatusBadge>
                ))
              ) : (
                <Text fontSize="x-small" color="gray.600">
                  No UN SDGs available
                </Text>
              )}
            </Flex>
          </Skeleton>

          <Flex fontSize="sm" align="center" my={3}>
            <FcCollaboration />
            <Text ml={2} color="gray.500">
              Groups
            </Text>
          </Flex>
          <Skeleton isLoaded={!projectLoading} borderRadius="2xl">
            <Flex wrap="wrap" mb={3}>
              {projectGroups?.getProjectGroups && projectGroups?.getProjectGroups?.length > 0 ? (
                projectGroups?.getProjectGroups?.map((group) => (
                  <StatusBadge
                    py={1}
                    mr={6}
                    my={1}
                    key={group?.id}
                    px={2}
                    fontWeight="semibold"
                    borderRadius="2xl"
                    cursor="pointer"
                    onClick={() => {
                      history.push(`/auth/communities/${group?.id}`)
                    }}
                  >
                    {group?.attributes?.name}
                  </StatusBadge>
                ))
              ) : (
                <Text fontSize="x-small" color="gray.600" width="100%" mt={3} mb={3}>
                  No groups available
                </Text>
              )}
            </Flex>
          </Skeleton>
        </Card>
        <Center>
          <Skeleton rounded="full" isLoaded={!projectLoading}>
            <Image
              objectFit="cover"
              rounded="2xl"
              boxSize={32}
              draggable="false"
              src={thumbnail ? thumbnail : images.logoNoText}
              alt="featured image"
            />
          </Skeleton>
        </Center>
      </VStack>
    </SimpleGrid>
  )
}

export default ProjectDetails
