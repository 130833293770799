import React, { memo } from 'react'
import { Flex, Select, theme, Text } from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'

import { ChevronLeft, ChevronRight } from 'react-feather'
import styled from 'styled-components'
import { FlexProps } from 'styled-system'

const PaginationContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  ul * {
    transition: all 0.2s ease-in-out 0s;
  }
  ul {
    display: flex;
    .pagination-active {
      font-weight: 600;
      font-size: small;
      background-color: ${theme.colors.green[50]};
      border-bottom: 4px solid ${theme.colors.green[500]};
    }
    .pagination-active:hover {
      background-color: #f4f4f4;
    }
    .pagination-disabled {
      color: lightgray;
    }
    a {
      padding: 0 1.3rem;
      display: inline-flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
    li {
      flex: 1;
      font-size: x-small;
      font-weight: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
    }

    li:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    li:last-of-type {
      border-radius: 0 5px 5px 0;
    }
    li:hover {
      background-color: #f3f3f347;
    }
  }
`
export type PaginationProps = {
  setLimit?: React.Dispatch<React.SetStateAction<number>>
  setStart?: React.Dispatch<React.SetStateAction<number>>
  setPage?: React.Dispatch<React.SetStateAction<number>>
  limit?: number
  start?: number
  count?: number | null
  pageRangeDisplayed?: number
  paginationEntries?: number[]
} & FlexProps
const Pagination: React.FC<PaginationProps> = ({
  count,
  setStart,
  start,
  limit,
  setLimit,
  setPage,
  pageRangeDisplayed,
  paginationEntries,
  ...rest
}) => {
  return (
    <PaginationContainer mt={4} borderRadius="sm" border="none" {...rest}>
      <Flex align="center">
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (setLimit) setLimit(parseInt(e.target.value))
          }}
          defaultValue={limit}
          fontSize="xs"
          variant="filled"
          minW={20}
          width="auto"
        >
          {paginationEntries?.map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </Select>
        <Text ml={3} fontSize="x-small">
          <i>entries per page</i>
        </Text>
      </Flex>

      <ReactPaginate
        pageCount={count && limit ? Math.ceil(count / limit) : 1}
        initialPage={start}
        disabledClassName="pagination-disabled"
        onPageChange={(value) => {
          if (setPage) setPage(value.selected)

          if (setStart && limit) {
            setStart(parseInt((value.selected * limit).toString()))
          }
        }}
        activeClassName="pagination-active"
        previousLabel={<ChevronLeft size={14} />}
        nextLabel={<ChevronRight size={14} />}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={1}
      />
    </PaginationContainer>
  )
}
Pagination.defaultProps = {
  limit: 10,
  start: 0,
  paginationEntries: [5, 10, 20, 50, 100],
  pageRangeDisplayed: 10
}

export default memo(Pagination)
