import {
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  Box,
  IconButton,
  useDisclosure,
  VStack,
  MenuButton,
  MenuItem,
  MenuList,
  Menu
} from '@chakra-ui/react'
import { Card, StatusBadge, CustomTooltip } from 'components'

import moment from 'moment'
import React from 'react'

import { VscCheck, VscTrash } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'

import { getImageByFormat } from 'utils'
import { Maybe, Enum_Tasktype_Status, TaskTypeEntity } from '../../../../generated/graphql'
import { Skeleton, Center } from '@chakra-ui/react'
import { motion, usePresence } from 'framer-motion'
import { BiCategoryAlt, BiDotsHorizontalRounded } from 'react-icons/bi'
import { Maximize2 } from 'react-feather'

import { useAuthContext } from '../../../../context/AuthProvider/index'
import DeactivateModal from 'features/task-builder/components/DeactivateModal'
import { SuspendedTaskTypes } from 'features/task-builder/types'
import { find } from 'lodash'

const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1 }

type TaskTypeCardProps = {
  task: Maybe<TaskTypeEntity>
  onDelete: (id?: string, status?: Enum_Tasktype_Status) => void
  loading?: boolean
  onClick: () => void
  index?: number
  isFocused?: boolean
  suspendedTaskTypes: SuspendedTaskTypes[] | undefined
}

const TaskTypeCard = React.forwardRef<HTMLDivElement, TaskTypeCardProps>(
  ({ task, onDelete, loading, onClick, isFocused, suspendedTaskTypes }, ref) => {
    const history = useHistory()
    const { isOpen, onClose, onOpen } = useDisclosure()

    const draft = React.useMemo(
      () => find(suspendedTaskTypes, (tasktype) => tasktype?.seletedTaskType === task?.id),
      [suspendedTaskTypes, task]
    )
    const isDrafted = draft !== undefined
    const openFullScreen = () => {
      history.push(`/auth/task-types-list/detail/${task?.id}`, {
        ...(isDrafted && {
          fields: draft.fields,
          taskTypeMeta: draft.taskTypeMeta
        }),
        isDrafted
      })
    }
    const { user } = useAuthContext()
    const [isPresent, safeToRemove] = usePresence()
    const isActive = task?.attributes?.status === Enum_Tasktype_Status.Active
    const creatorName =
      task?.attributes?.creator?.data?.id == user?.id
        ? `${task?.attributes?.creator?.data?.attributes?.firstName} (Me)`
        : `${task?.attributes?.creator?.data?.attributes?.firstName} ${task?.attributes?.creator?.data?.attributes?.lastName}`
    const animations = {
      layout: true,
      initial: 'out',
      position: isPresent ? 'static' : 'absolute',
      style: {
        width: '100%'
      },
      animate: isPresent ? 'in' : 'out',
      whileTap: 'tapped',
      variants: {
        in: { scaleY: 1, opacity: 1 },
        out: { scaleY: 0, opacity: 0 },
        tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.4 } }
      },
      onAnimationComplete: () => !isPresent && safeToRemove(),
      transition
    }

    return (
      <>
        <DeactivateModal
          {...{ isOpen, onClose }}
          updatingTaskType={loading}
          taskType={task as TaskTypeEntity}
          handleDeleteTaskType={() => {
            onDelete(task?.id || '', task?.attributes?.status as Enum_Tasktype_Status)
            onClose()
          }}
        />

        <motion.div {...animations}>
          <Skeleton isLoaded={!loading} ref={ref} rounded="3xl">
            <Card
              cursor="pointer"
              rounded="lg"
              border="1px solid"
              borderColor={isFocused ? 'gray.200' : 'transparent'}
              bg={isFocused ? 'white' : 'transparent'}
              height="100%"
              px={4}
              py={1}
            >
              <HStack position="relative">
                <HStack alignItems="flex-start" flex={1} spacing={4} onClick={onClick}>
                  <Image
                    src={getImageByFormat(
                      task?.attributes?.category?.data?.attributes?.featureImage?.data,
                      'thumbnail'
                    )}
                    objectFit="cover"
                    draggable="false"
                    align="community profile"
                    boxSize={8}
                    rounded="xl"
                  />
                  <VStack w="100%" alignItems="flex-start" spacing={0}>
                    <Flex w="100%" align="center" justify="space-between">
                      <Box mr={1} justifyContent="flex-start" alignContent="flex-start">
                        <Heading
                          as="h2"
                          minW="fit-content"
                          color="gray.700"
                          fontWeight="bold"
                          my={1}
                          fontSize="sm"
                        >
                          {task?.attributes?.title}
                        </Heading>
                      </Box>
                    </Flex>
                    <HStack mr={2} width="100%" justifyContent="space-between" alignItems="center">
                      <Flex align="center">
                        <StatusBadge
                          p={1}
                          indicator
                          fontSize=".5rem"
                          colorScheme={isActive ? 'green' : 'red'}
                        >
                          {isActive ? 'On' : 'Off'}
                        </StatusBadge>

                        {task?.attributes?.ER_ID && (
                          <StatusBadge p={1} fontSize=".5rem" colorScheme="pink">
                            ER
                          </StatusBadge>
                        )}
                        {isDrafted && (
                          <StatusBadge indicator p={1} fontSize=".5rem" colorScheme="orange">
                            Drafted
                          </StatusBadge>
                        )}
                      </Flex>
                      <Text fontSize="xx-small" color="gray.600">
                        Created {moment(task?.attributes?.createdAt).fromNow()}
                      </Text>
                    </HStack>
                    <HStack w="100%" fontSize="xx-small" justifyContent="space-between">
                      <Center color="gray.600">
                        <BiCategoryAlt />
                        <Text fontWeight="bold" ml={1}>
                          {task?.attributes?.category?.data?.attributes?.title}
                        </Text>
                      </Center>
                      <Text color="gray.500">
                        Created by <b>{creatorName}</b>
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
                <VStack>
                  <CustomTooltip label={`Open "${task?.attributes?.title}" in full screen`}>
                    <IconButton
                      aria-label="full screen"
                      rounded="full"
                      bg={isFocused ? 'gray.100' : 'transparent'}
                      onClick={openFullScreen}
                      icon={<Maximize2 size={12} />}
                    />
                  </CustomTooltip>

                  <Menu placement="left-end">
                    <MenuButton
                      as={IconButton}
                      rounded="full"
                      bg={isFocused ? 'gray.100' : 'transparent'}
                      aria-label="Options"
                      icon={<BiDotsHorizontalRounded />}
                    />

                    <MenuList overflow="hidden" rounded="2xl" py={0} zIndex={4743} fontSize="xs">
                      <MenuItem onClick={openFullScreen} icon={<Maximize2 size={12} />}>
                        Open in full screen
                      </MenuItem>
                      <MenuItem
                        onClick={onOpen}
                        color={!isActive ? 'green.500' : 'red.500'}
                        icon={!isActive ? <VscCheck /> : <VscTrash />}
                      >
                        {isActive ? 'Deactivate' : 'Activate'}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </VStack>
              </HStack>
            </Card>
          </Skeleton>
        </motion.div>
      </>
    )
  }
)

export default TaskTypeCard
