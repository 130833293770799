import { useToast, useDisclosure, HStack, Box } from '@chakra-ui/react'
import { CustomTooltip, VerifierDrawer } from 'components'

import { DATA_COLLECTOR, ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'

import BaoriderModal from 'containers/OnBoarding/components/BaoriderModal'

import {
  useCreateBaoriderMutation,
  useUpdateBaoriderMutation,
  useCountRidersQuery,
  useGetBaoridersQuery,
  UsersPermissionsUserEntity,
  Maybe,
  UpdateCommunityMembersInput,
  useUpdateCommunityMemberMutation,
  useCreateCommunityMemberMutation
} from 'generated/graphql'

import { get, omit } from 'lodash'
import React from 'react'

import posthog from 'posthog-js'
import { VscAdd } from 'react-icons/vsc'

import CommunityMemberModal from 'containers/OnBoarding/components/CommunityMemberModal'
import VerifiersListModal from '../../components/VerifiersListModal'

import DeleteModal from '../../../../components/UX/AlertModal/index'
import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'
import DataCollectorsTable from '../../../../components/features/DataCollectorsTable/index'

import { useParams } from 'react-router-dom'
import { CommunityMember } from 'utils/types'
import {
  addDatacollectorToGroupTooltipContent,
  createDatacollectorToGroupTooltipContent,
  groupDataCollectorsExportTooltipContent
} from '../help'

type VerifiersProps = {
  callback?: () => Promise<void>
}

const Verifiers: React.FC<VerifiersProps> = ({ callback }) => {
  const toast = useToast()

  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const { id } = useParams<{ id: string }>()

  const [verifiersWhereProps, setVerifiersWhereProps] = React.useState<any>({ community: id })

  const [editableCommunityMember] = React.useState({} as CommunityMember)
  const [createDataCollector, setCreateDataCollector] = React.useState(false)
  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)

  const [editItemIndex, setEditItemIndex] = React.useState(-1)
  const [addAnotherOne, setAddAnotherOne] = React.useState(false)
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedFilter, setSelectedFilter] = React.useState('')
  const { data: count } = useCountRidersQuery({
    variables: {
      where:
        searchTerm && selectedFilter
          ? {
              [`${selectedFilter}`]: { contains: searchTerm },
              ...verifiersWhereProps
            }
          : { ...verifiersWhereProps }
    },
    onError
  })
  const { data, refetch, loading } = useGetBaoridersQuery({
    variables: {
      pagination: {
        limit,
        start
      },
      filters:
        searchTerm && selectedFilter
          ? {
              [`${selectedFilter}`]: { contains: searchTerm },
              ...verifiersWhereProps
            }
          : { ...verifiersWhereProps }
    },
    onError,
    fetchPolicy: 'cache-and-network'
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure()

  const [createBaorider, { loading: createLoading }] = useCreateBaoriderMutation({
    onCompleted: () => {
      toast({ title: 'Baorider created.', ...SUCCESS_TOAST })
      if (!addAnotherOne) {
        onCreateClose()
      }
    },
    onError: (err) => {
      toast({
        title: get(err, 'graphQLErrors[0].message', 'Unable to add baorider'),
        ...ERROR_TOAST
      })
    }
  })
  const onFilter = async (filterValues: { [key: string]: string | number }) => {
    try {
      setVerifiersWhereProps({ ...filterValues, community: id })
      await refetch()
    } catch (e) {
      console.error(e)
    }
  }

  const [updateVerifier] = useUpdateBaoriderMutation()

  const [selectedVerifier, setSelectedVerifier] = React.useState<UsersPermissionsUserEntity | null>(
    null
  )

  const { isOpen: isDrawerOpen, onClose: onDrawerClose, onOpen: onDrawerOpen } = useDisclosure()

  const addBaorider = async (baorider: UsersPermissionsUserEntity & { community: string }) => {
    try {
      const verifier = await createBaorider({
        variables: {
          input: {
            firstName: baorider?.attributes?.firstName || '',
            lastName: baorider?.attributes?.lastName || '',
            gender: baorider?.attributes?.gender,
            phoneNumber: baorider?.attributes?.phoneNumber || '',
            community: id,
            isVerifier: false
          },
          sendInvite: true
        }
      })

      await refetch()
      return verifier.data?.createBaorider.baorider as Maybe<UsersPermissionsUserEntity>
    } catch (error) {
      console.error(error)
    }
  }
  const onRemove = async () => {
    await updateVerifier({
      variables: {
        id: selectedVerifier?.id || '',
        baorider: {
          community: null
        }
      }
    })

    await refetch()

    onDrawerClose()
  }
  const {
    isOpen: isVerifiersModalOpen,
    onClose: onVerifiersModalClose,
    onOpen: onVerifiersModalOpen
  } = useDisclosure()
  const { isOpen: isRemoveOpen, onClose: onRemoveClose, onOpen: onRemoveOpen } = useDisclosure()
  const [updateMember, { loading: editLoading }] = useUpdateCommunityMemberMutation({
    onCompleted: () => {
      toast({ title: 'Community member updated.', ...SUCCESS_TOAST })
      if (!addAnotherOne) {
        onCreateClose()
      }
    },
    onError
  })
  const [createMember, { loading: createCommunityMemberLoading }] =
    useCreateCommunityMemberMutation({
      onCompleted: () => {
        toast({ title: 'Community member created.', ...SUCCESS_TOAST })
        if (!addAnotherOne) {
          onCreateClose()
        }
      },
      onError
    })

  const isLoading = createLoading || editLoading || createCommunityMemberLoading

  const addCommunityMember = async (member: CommunityMember, isVerifier: boolean) => {
    const data = omit(member, 'hasConsent')
    let user
    try {
      if (isVerifier) {
        user = await createBaorider({
          variables: {
            input: {
              ...data,
              community: id || '',
              isVerifier
            },
            sendInvite: true
          }
        })
      } else {
        user = await createMember({
          variables: {
            input: {
              ...data,
              community: id || ''
            }
          }
        })
      }

      return user
    } catch (error) {
      console.error(error)
    }
  }
  const onCreate = () => {
    posthog.capture('dashboard_data_collector_create_clicked', {
      from: 'communities'
    })
    setCreateDataCollector(true)
    onCreateOpen()
  }

  const updateCommunityMember = async (_index: number, communityMember: CommunityMember) => {
    const user = await updateMember({
      variables: {
        id: communityMember?.id,
        input: {
          firstName: communityMember?.firstName,
          lastName: communityMember?.lastName,
          phoneNumber: communityMember?.phoneNumber,
          gender: communityMember?.gender,
          community: id
        } as UpdateCommunityMembersInput
      }
    })

    return user
  }
  const onCommunityMemberModal = React.useCallback(
    async (member, isVerifier, addAnother) => {
      let user
      if (isEditMode) {
        user = await updateCommunityMember(editItemIndex, member)
        setEditItemIndex(-1)
        setIsEditMode(false)
      } else {
        user = await addCommunityMember(member, isVerifier)
      }
      setAddAnotherOne(addAnother)

      return user
    },
    [editItemIndex, isEditMode, updateCommunityMember]
  )

  const tooltipTrigger = (
    <HStack spacing={5}>
      <CustomTooltip label={addDatacollectorToGroupTooltipContent}>
        <Box>
          <SecondaryButton leftIcon={<VscAdd />} onClick={onVerifiersModalOpen}>
            Add {DATA_COLLECTOR}
          </SecondaryButton>
        </Box>
      </CustomTooltip>
      <CustomTooltip label={createDatacollectorToGroupTooltipContent}>
        <Box>
          <PrimaryButton leftIcon={<VscAdd />} mr={3} onClick={onCreate}>
            Create new {DATA_COLLECTOR}
          </PrimaryButton>
        </Box>
      </CustomTooltip>
    </HStack>
  )

  return (
    <Box py={4}>
      <DataCollectorsTable
        {...{ limit, start, count: count?.countRiders || 0, setLimit, setStart }}
        loading={loading}
        visibleFilters={['status', 'project']}
        setSearchTerm={setSearchTerm}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        searchTerm={searchTerm}
        titleActions={() => <>{tooltipTrigger}</>}
        title={DATA_COLLECTOR}
        onRemove={() => onRemoveOpen()}
        baoriders={(data?.getBaoriders || []) as UsersPermissionsUserEntity[]}
        onFilter={onFilter}
        onReset={async () => {
          await refetch()
        }}
        onCreate={onOpen}
        onClick={(baorider) => {
          onDrawerOpen()
          setSelectedVerifier(baorider)
        }}
        exportExcelTooltipContent={groupDataCollectorsExportTooltipContent}
      />

      <VerifiersListModal
        verifiers={data?.getBaoriders as Maybe<UsersPermissionsUserEntity>[]}
        isOpen={isVerifiersModalOpen}
        onClose={onVerifiersModalClose}
        callback={() => {
          onCreateOpen()
        }}
        communityId={id}
        onAdd={async (values) => {
          try {
            values?.forEach(async (value) => {
              await updateVerifier({
                variables: {
                  id: value || '',
                  baorider: {
                    community: id
                  }
                }
              })
            })

            await callback?.()
            await refetch()
            onVerifiersModalClose()
          } catch (error) {
            console.error(error)
          }
        }}
      />

      <BaoriderModal
        {...{ isOpen, onOpen, onClose }}
        isEditMode={false}
        isLoading={isLoading}
        onSubmit={async (baorider) => {
          return await addBaorider({
            ...baorider
          })
        }}
      />
      <VerifierDrawer
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        selectedVerifier={selectedVerifier}
        onRemove={() => onRemoveOpen()}
      />
      <DeleteModal
        onClose={onRemoveClose}
        isOpen={isRemoveOpen}
        confirmButtonText="Remove"
        title="Remove Data Collector"
        onConfirm={async () => {
          await callback?.()
          onRemove()
          onRemoveClose()
        }}
      >
        Are you sure you want to remove this data collector from the group?
      </DeleteModal>

      <CommunityMemberModal
        {...{
          isOpen: isCreateOpen,
          onOpen: onCreateOpen,
          onClose: onCreateClose,
          editableCommunityMember: {
            ...editableCommunityMember,
            isVerifier: createDataCollector
          },
          isEditMode
        }}
        isLoading={isLoading}
        onSubmit={onCommunityMemberModal}
      />
    </Box>
  )
}

export default Verifiers
