import { Flex, HStack, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { FloatingButtonWrapper, ModalWrap } from '../../../components'

import ConnectedFormGroup from '../../../components/forms/FormElements/ConnectedFormGroup'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import { Company, useRequestUpdateCompanyDetailsMutation, Maybe } from '../../../generated/graphql'
import { RequestCompanyDetailsChange } from '../../../utils/types'
import * as Yup from 'yup'
import { SecondaryButton, PrimaryButton } from '../../../components/UI/Buttons/index'

const FormValidation = Yup.object().shape({
  website: Yup.string().required('A website is required'),
  CompanyName: Yup.string().required('A Company Name is required')
})
const DetailsModal: React.FC<{
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  company?: Maybe<Company>
}> = ({ isOpen, onClose, company }) => {
  const toast = useToast()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const [requestUpdateCompanyDetailsMutation] = useRequestUpdateCompanyDetailsMutation({
    onCompleted: () => {
      toast({ title: 'Request Sent.', ...SUCCESS_TOAST })
      onClose()
    },
    onError
  })
  return (
    <ModalWrap title="Request to Change Details" isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          CompanyName: '',
          orgID: '',
          WildlifeAssociationNumber: '',
          pin_registration: '',
          website: '',
          currentCompanyName: ''
        }}
        validationSchema={FormValidation}
        onSubmit={async (values: RequestCompanyDetailsChange) => {
          try {
            await requestUpdateCompanyDetailsMutation({
              variables: {
                data: {
                  CompanyName: values?.CompanyName,
                  orgID: values?.orgID,
                  WildlifeAssociationNumber: values?.WildlifeAssociationNumber,
                  pin_registration: values?.pin_registration,
                  website: values?.website,
                  currentCompanyName: company?.name
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {({ status }) => (
          <Form>
            <FloatingButtonWrapper
              status={status}
              button={
                <HStack width="100%">
                  <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                  <PrimaryButton flex={1} type="submit">
                    Submit
                  </PrimaryButton>
                </HStack>
              }
            >
              <Flex flexDir="column" p={4}>
                <ConnectedFormGroup
                  name="CompanyName"
                  label="Company Name"
                  placeholder="Enter new company name"
                />
                <ConnectedFormGroup
                  name="WildlifeAssociationNumber"
                  label="Wildlife Association Number"
                  placeholder="Enter wildlife association number"
                />
                <ConnectedFormGroup
                  name="orgID"
                  label="Organization ID Number"
                  placeholder="Enter organization ID number"
                />
                <ConnectedFormGroup
                  name="pin_registration"
                  label="PIN Registration"
                  placeholder="Enter PIN registration"
                />
                <ConnectedFormGroup
                  name="website"
                  label="Company website link"
                  placeholder="Enter company website link"
                />
              </Flex>
            </FloatingButtonWrapper>
          </Form>
        )}
      </Formik>
    </ModalWrap>
  )
}

export default DetailsModal
