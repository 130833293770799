import { Flex, VStack, Image, Box } from '@chakra-ui/react'
import { useAnimation, Variants } from 'framer-motion'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ColorProps } from 'styled-system'
import { useAppContext } from '../../../context/AppProvider'
import { NavItem } from '../../../navigation'
import { images, theme } from '../../../theme'
import Header from '../Header'
import SideBarItem, { MenuUserProfile } from './SideBarItem'
import { MenuCont, Overlay, RenderWrapper } from './styles'
import { useHistory } from 'react-router-dom'
import SideBarButton from '../SideBar/SideBarButton'
import CustomTooltip from 'components/UX/CustomTooltip'

type SideBarProps = ColorProps & {
  accentColor: string
  borderColor?: string
  closeOnNavigate?: boolean
  color: string
  hoverColor: string
  navItems: NavItem[]
  secondaryNavItems?: NavItem[]
  renderUserInfo?: (history: any) => React.ReactNode
  tooltipBg?: string
  tooltipColor?: string
  children?: React.ReactNode
}

const SideBar: React.FC<SideBarProps> = ({
  accentColor,
  bg,
  borderColor,
  children,
  color,
  hoverColor,
  navItems,
  secondaryNavItems,
  tooltipBg,
  tooltipColor
}) => {
  const { drawerOpen, toggleDrawer } = useAppContext()

  const controls = useAnimation()
  const history = useHistory()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  React.useEffect(() => {
    if (drawerOpen) {
      controls.start('open')
    } else {
      controls.start('closed')
    }
  }, [isTabletOrMobile, drawerOpen, controls])

  const variants: Variants = {
    open: {
      x: 0,
      width: 200,
      transition: { staggerChildren: 0.2, delayChildren: 0.1, stiffness: 80 }
    },
    closed: {
      x: isTabletOrMobile ? -230 : 0,
      width: isTabletOrMobile ? 230 : 64,
      transition: {
        stiffness: 80,
        staggerDirection: -1,
        staggerChildren: 0.2,
        delay: isTabletOrMobile ? 0.25 : 0
      }
    }
  }

  return (
    <>
      <MenuCont
        bg={bg as any}
        flexDir="column"
        animate={controls}
        variants={variants}
        alignItems="flex-start"
        boxShadow="inset -1rem 0 1rem rgba(0,0,0,0.05)"
        justifyContent="flex-start"
        initial={{ width: drawerOpen ? 230 : 64 }}
      >
        <VStack spacing={8}>
          <Flex
            pl="20px"
            width="100%"
            height="64px"
            alignItems="center"
            borderBottomWidth={1}
            justifyContent="flex-start"
            borderColor={borderColor}
            p={0}
            bg="brand.400"
            transition="all 0.2s ease-in-out"
            rounded="sm"
            cursor="pointer"
            border="none"
          >
            <Flex m={0} p={0} flex={1} justifyContent="center">
              <Image
                style={{
                  filter: 'grayscale(1) brightness(0) invert(1)'
                }}
                onClick={() => history.push('/auth')}
                width={drawerOpen ? '65%' : '50%'}
                src={drawerOpen ? images.logo : images.logoNoText}
                alt="Baotree Icon"
              />
            </Flex>
          </Flex>
          <Flex justify="center" w="100%" px={drawerOpen ? 4 : 0} align="center">
            {drawerOpen && <MenuUserProfile />}
            <CustomTooltip label={drawerOpen ? 'Close Draw' : 'Open Draw'}>
              <Box>
                <SideBarButton color="black" open={drawerOpen} onClick={toggleDrawer} />
              </Box>
            </CustomTooltip>
          </Flex>
        </VStack>
        <Flex
          direction="column"
          overflow={drawerOpen ? 'hidden' : undefined}
          overflowY={drawerOpen ? 'auto' : undefined}
          width="100%"
          flex={1}
          justify="center"
        >
          {navItems.map((props) => (
            <SideBarItem
              color={color}
              key={props.title}
              hoverColor={hoverColor}
              accentColor={accentColor}
              tooltipColor={tooltipColor}
              tooltipBg={tooltipBg}
              {...props}
            />
          ))}
        </Flex>
        <VStack w="100%">
          {secondaryNavItems &&
            secondaryNavItems.map((props) => (
              <SideBarItem
                color={color}
                key={props.title}
                hoverColor={hoverColor}
                accentColor={accentColor}
                tooltipColor={tooltipColor}
                tooltipBg={tooltipBg}
                {...props}
              />
            ))}
        </VStack>
      </MenuCont>
      <RenderWrapper
        animate
        bg={`linear-gradient(to top, ${theme.colors.background[100]} 50%, ${theme.colors.background[200]} 50%)`}
        className="render-wrapper"
        pl={isTabletOrMobile ? 0 : drawerOpen ? '200px' : '64px'}
      >
        <Header />
        {children}
        {isTabletOrMobile && (
          <Overlay
            onClick={toggleDrawer}
            initial={{ opacity: 0 }}
            pointerEvents={drawerOpen ? 'auto' : 'none'}
            animate={drawerOpen ? { opacity: 1 } : { opacity: 0 }}
          />
        )}
      </RenderWrapper>
    </>
  )
}

export default SideBar

SideBar.defaultProps = {
  color: 'white',
  bg: 'gray.900',
  hoverColor: 'gray.800',
  borderColor: 'gray.700',
  accentColor: 'cyan.500'
}
