import { Box, Flex, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { FloatingButtonWrapper, Stat } from 'components'
import { Form, Formik } from 'formik'
import React from 'react'
import { VscLocation, VscPin } from 'react-icons/vsc'

import { Community, Enum_Community_Type } from 'generated/graphql'
import { PrimaryButton } from 'components/UI/Buttons'
import {
  ConnectedFormGroup,
  ConnectedTextArea,
  ConnectedImageUploader,
  ConnectedSelect
} from 'components/forms/FormElements'
import GoogleMap from 'lib/map/components/GoogleMap'
import { useGeolocation } from 'hooks'
import { addCommunitySchema } from 'utils/validationSchemas'

const StyledMap = styled.div`
  width: 100%;
  height: 100%;
  > div,
  > div > div {
    width: 100%;
    height: 100%;
  }
`
const CreateCommunityForm: React.FC<{
  actionBar: (dirty: boolean) => React.ReactNode
  submitForm: (values: Community) => void
  initialValues: Community
}> = ({ actionBar, submitForm, initialValues }) => {
  const { latitude, longitude, error: locationError, accuracy } = useGeolocation()
  const [selectedLocation, setSelectedLocation] = React.useState<{
    latitude: number | null
    longitude: number | null
  }>({
    latitude: null,
    longitude: null
  })
  React.useEffect(() => {
    if (initialValues?.location?.latitude && initialValues?.location?.longitude) {
      setSelectedLocation({
        latitude: initialValues?.location?.latitude,
        longitude: initialValues?.location?.longitude
      })
    }
  }, [initialValues])

  const onMarkerDrop = React.useCallback(
    (lat: number, lng: number) => {
      setSelectedLocation({
        latitude: lat,
        longitude: lng
      })
    },
    [setSelectedLocation]
  )

  const markers = React.useMemo(() => {
    return [
      ...(selectedLocation.latitude !== null && selectedLocation.longitude !== null
        ? [
            {
              lat: selectedLocation.latitude,
              lng: selectedLocation.longitude
            }
          ]
        : [])
    ]
  }, [selectedLocation])

  return (
    <Formik
      validationSchema={addCommunitySchema}
      initialValues={initialValues}
      onSubmit={(values: Community) => {
        submitForm({
          ...values,
          ...(selectedLocation?.latitude !== null && selectedLocation?.longitude !== null
            ? { location: selectedLocation }
            : {})
        })
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, dirty }) => (
        <Form style={{ width: '50%' }}>
          <FloatingButtonWrapper isLoading={isSubmitting} button={actionBar && actionBar(dirty)}>
            <Box>
              <Flex>
                <ConnectedFormGroup name="name" w="100%" mr={1} label="Group name *" />
                <ConnectedSelect
                  options={Object.values(Enum_Community_Type).map((type) => ({
                    value: type,
                    label: type
                  }))}
                  name="type"
                  width="100%"
                  label="Group type *"
                />
              </Flex>
              <ConnectedFormGroup name="chief_name" label="Group Point of Contact" />
              <ConnectedTextArea
                name="description"
                label="Description *"
                minHeight="150px"
                placeholder="Provide a description of the Group"
              />
              <Flex justify="flex-end" p={0} m={0}>
                <Text fontSize="xs">Maximum 300 characters</Text>
              </Flex>
            </Box>

            <Box>
              <Text fontWeight="semibold" mt={3} mb={1}>
                Location *
              </Text>
              <Box
                w="100%"
                rounded="2xl"
                overflow="hidden"
                minH="385px"
                h="385px"
                background="rgba(0,0,0,0.1)"
              >
                <StyledMap>
                  <GoogleMap
                    dragMarkers={true}
                    onMarkerDrop={onMarkerDrop}
                    lat={selectedLocation.latitude}
                    lng={selectedLocation.longitude}
                    markers={markers}
                  />
                </StyledMap>
              </Box>
              <PrimaryButton
                mt={4}
                leftIcon={<VscLocation />}
                onClick={() => {
                  if (locationError.trim() === '') {
                    setSelectedLocation({ longitude, latitude })
                  }
                }}
              >
                Use my current location
              </PrimaryButton>
              <br />
              <Flex justify="space-between" my={8} align="center" fontSize="xs">
                <Stat label="Latitude" icon={<VscLocation />} value={latitude || '0'} />
                <Stat label="Longitude" icon={<VscLocation />} value={longitude || '0'} />
                <Stat label="Accuracy" icon={<VscPin />} value={accuracy || '0'} />
              </Flex>
            </Box>

            <Flex width="100%" py={3} direction="column">
              <Text fontWeight="semibold" fontSize="xs" mb={2} mt={2}>
                Add a picture for this community
              </Text>
              <Box maxWidth="300px">
                <ConnectedImageUploader name="featured_image" />
              </Box>
            </Flex>
          </FloatingButtonWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default CreateCommunityForm
