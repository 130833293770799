import { Box, HStack, Text } from '@chakra-ui/react'
import { FloatingButtonWrapper } from 'components'
import { ConnectedFormGroup, ConnectedSelect } from 'components/forms/FormElements'
import { Form, Formik, FormikProps } from 'formik'
import { MeQuery, useUpdateMyUserMutation } from 'generated/graphql'
import React, { useEffect } from 'react'
import { formatError } from 'utils'
import * as Yup from 'yup'
import posthog from 'posthog-js'
import countries from 'world-countries'
import { useIPLookup } from 'hooks'
import { IPField } from 'hooks/useIPLookup/types'
import { find, sortBy } from 'lodash'

const ProfileInformationFormValidation = Yup.object().shape({
  companySize: Yup.string().required('Your company size is required'),
  username: Yup.string().required('Your username is required'),
  jobTitle: Yup.string().required('Your job title is required'),
  lookingToAchieve: Yup.string().required('What you are looking to achieve is required'),
  country: Yup.string().required('Your country is required')
})

type ProfileInformationValues = {
  companySize: string
  username: string
  jobTitle: string
  lookingToAchieve: string
  country: string
}

export type CompleteProfileProps = {
  meData: MeQuery | undefined
  refetchMeData: any
}

const countryOptions = sortBy(
  countries.map(({ name, idd, flag }) => ({
    name: name.common,
    code: idd,
    flag: flag
  })),
  'name'
)

const CompleteProfile: React.FC<{
  meData: MeQuery | undefined
  refetchMeData: any
}> = (props: CompleteProfileProps) => {
  const [profileUpdated, setProfileUpdated] = React.useState<boolean>(false)

  const { country_name } = useIPLookup([IPField.country_name])
  const [country, setCountry] = React.useState<string>('South Africa')
  React.useEffect(() => {
    if (country_name) {
      const name = find(countries, ['name', country_name])?.name.common
      setCountry(name ? name : 'South Africa')
    }
  }, [country_name])

  const {
    meData,
    refetchMeData
  }: { meData: MeQuery | undefined; refetchMeData: () => Promise<any> } = props
  const [updateMyUser] = useUpdateMyUserMutation({
    onCompleted: async () => {
      await refetchMeData()
    }
  })

  useEffect(() => {
    if (meData?.myUser?.attributes?.onboardingInformation?.profileCompleted) {
      setProfileUpdated(true)
    }
  }, [meData])

  return (
    <>
      <Box marginBottom={'2vh'}>
        {!profileUpdated && (
          <>
            <Text mb={2}>Please update your username and business information below.</Text>
            <Formik
              validationSchema={ProfileInformationFormValidation}
              initialValues={{
                companySize: '1-10',

                username: '',
                jobTitle: '',
                lookingToAchieve: '',
                country: country
              }}
              onSubmit={async (
                { companySize, username, jobTitle, lookingToAchieve, country },
                { setStatus, setSubmitting }
              ) => {
                setStatus(null)
                try {
                  setSubmitting(true)
                  // wait 5 seconds
                  await updateMyUser({
                    variables: {
                      input: {
                        username,
                        onboardingInformation: {
                          ...meData?.myUser?.attributes?.onboardingInformation,
                          profileCompleted: true,
                          profileInformation: {
                            companySize,
                            jobTitle,
                            lookingToAchieve,
                            completedAt: new Date(),
                            country
                          }
                        }
                      }
                    }
                  })
                  posthog.capture('profile_information_updated')
                  setSubmitting(false)
                  setProfileUpdated(true)
                } catch (error) {
                  // The only error that can happen here that isn't a network error is a validation error for the username
                  if (JSON.stringify(error).includes('Validation')) {
                    setStatus('Username is already taken')
                  } else {
                    setStatus(formatError(error))
                  }
                }
              }}
            >
              {({ isSubmitting, status }: FormikProps<ProfileInformationValues>) => (
                <Form style={{ width: '100%', marginTop: '2vh' }}>
                  <FloatingButtonWrapper
                    isLoading={isSubmitting}
                    status={status}
                    buttonTitle="Update Information"
                  >
                    <ConnectedSelect
                      name="companySize"
                      label="Company Size"
                      options={[
                        {
                          label: '1-10',
                          value: '1-10'
                        },
                        {
                          label: '11-50',
                          value: '11-50'
                        },
                        {
                          label: '51-200',
                          value: '51-200'
                        },
                        {
                          label: '201-500',
                          value: '201-500'
                        },
                        {
                          label: '500+',
                          value: '500+'
                        }
                      ]}
                    />
                    <HStack mb={2}>
                      <ConnectedFormGroup
                        name="username"
                        label="Username"
                        mb={0}
                        w="100%"
                        placeholder={meData?.myUser?.attributes?.username || 'Your username'}
                      />
                      <ConnectedFormGroup
                        name="jobTitle"
                        label="Job Title"
                        mb={0}
                        w="100%"
                        placeholder="CEO"
                      />
                    </HStack>
                    <ConnectedFormGroup
                      name="lookingToAchieve"
                      label="What are you looking to achieve with Baotree?"
                      placeholder="I am looking to achieve..."
                    />
                    <ConnectedSelect
                      name="country"
                      label="Country"
                      placeholder="South Africa"
                      options={countryOptions.map((country) => ({
                        label: country.name,
                        value: country.name
                      }))}
                    />
                  </FloatingButtonWrapper>
                </Form>
              )}
            </Formik>
          </>
        )}
        {profileUpdated && (
          <Text marginBottom={'2vh'}>
            Your profile information has been updated successfully! If you need to change it again,
            you can do so from your profile settings.
          </Text>
        )}
      </Box>
    </>
  )
}

export default CompleteProfile
