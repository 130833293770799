import { Box, Text, Link } from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'

export const tasksHelpPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6} zIndex={100}>
      <Text>
        This page allows you to manage your Company Profile, <b>create</b> and <b>manage</b> Admin
        Users and, if you are a self-service Customer, manage your Baotree <b>Subscription</b>.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <b>
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            Help and Training
          </Link>
        </b>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const settingsToolTip = (
  <Box px={4} py={6}>
    <Text>
      <b>This tab allows you to control your notification settings.</b>
    </Text>
    <br />
    <Text>Navigate along these tabs to manage your admin profile and add new admin users.</Text>
  </Box>
)

export const companyToolTip = (
  <Box px={4} py={6}>
    <Text>
      <b>This tab provides an overview of your company.</b>.
    </Text>
    <br />
    <Text>Navigate along these tabs to manage your admin profile and add new admin users.</Text>
  </Box>
)

export const teamToolTip = (
  <Box px={4} py={6}>
    <Text>
      <b>This tab allows you to manage your admin profile and add new admin users.</b>.
    </Text>
  </Box>
)

export const archieveUserTooltipContent = (
  <Box px={4} py={6}>
    <Text>Archiving the user will remove their access to the system.</Text>
  </Box>
)

export const unarchieveUserTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      Unarchiving the user will restore their access to the system and their previous permissions.
    </Text>
  </Box>
)
