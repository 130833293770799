import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  PopoverProps
} from '@chakra-ui/react'
import { HintElementProps } from 'components/utils/interfaces'
import React, { ReactNode } from 'react'
import { FaInfoCircle } from 'react-icons/fa'

interface CustomPopoverProps extends Partial<PopoverProps>, HintElementProps {
  children?: ReactNode
  customTrigger?: ReactNode
  label?: string
}
const CustomPopover: React.FC<CustomPopoverProps> = ({
  children,
  customTrigger,
  label = 'More Information',
  icon: Icon = FaInfoCircle,
  iconColor = 'rgba(0,0,0,0.5)',
  textColor = 'rgba(0,0,0,0.5)',
  fontSize = 'sm',
  spacing = 3,
  placement = 'bottom',
  ...popoverProps
}) => {
  return (
    <Popover {...popoverProps} placement={placement}>
      <PopoverTrigger>
        {customTrigger ? (
          customTrigger
        ) : (
          <Flex direction="row" align="center" mt={6}>
            <Box color={iconColor} as={Icon} mr={spacing} />
            <Text color={textColor} fontSize={fontSize}>
              {label}
            </Text>
          </Flex>
        )}
      </PopoverTrigger>
      <PopoverContent as="div" zIndex={40}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default CustomPopover
