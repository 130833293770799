import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  EvidenceCustomDataFieldsDynamicZoneInput: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any;
  TaskTypeDataFieldsDynamicZoneInput: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AddDataCollectorToProjectInput = {
  projectId: Scalars['ID'];
  dataCollectorId: Scalars['ID'];
};

export type Analytic = {
  __typename?: 'Analytic';
  default_dashboard?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type AnalyticEntity = {
  __typename?: 'AnalyticEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Analytic>;
};

export type AnalyticEntityResponse = {
  __typename?: 'AnalyticEntityResponse';
  data?: Maybe<AnalyticEntity>;
};

export type AnalyticInput = {
  default_dashboard?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type AverageMetirc = {
  __typename?: 'AverageMetirc';
  total: Scalars['Float'];
  wow: Scalars['Float'];
  dod: Scalars['Float'];
};

export type BaoleaderPayload = {
  __typename?: 'BaoleaderPayload';
  baoleader?: Maybe<UsersPermissionsUserEntity>;
};

export type BaoriderCommunityMetrics = {
  __typename?: 'BaoriderCommunityMetrics';
  community?: Maybe<Community>;
  completedTasks: Scalars['Int'];
  completedProjects: Scalars['Int'];
  activeMembers: Scalars['Int'];
};

export type BaoriderPayload = {
  __typename?: 'BaoriderPayload';
  baorider?: Maybe<UsersPermissionsUserEntity>;
};

export type BaoriderTaskMetrics = {
  __typename?: 'BaoriderTaskMetrics';
  currentTask?: Maybe<TaskEntity>;
  availableTasks: Scalars['Int'];
  completedTasks: Scalars['Int'];
  currentProject?: Maybe<ProjectSet>;
};

export type BaoverifierApp = {
  __typename?: 'BaoverifierApp';
  appVersion?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Baoverifierapp_Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type BaoverifierAppEntity = {
  __typename?: 'BaoverifierAppEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<BaoverifierApp>;
};

export type BaoverifierAppEntityResponse = {
  __typename?: 'BaoverifierAppEntityResponse';
  data?: Maybe<BaoverifierAppEntity>;
};

export type BaoverifierAppInput = {
  appVersion?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Baoverifierapp_Status>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type BooleanFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  or?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  not?: Maybe<BooleanFilterInput>;
  eq?: Maybe<Scalars['Boolean']>;
  eqi?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  nei?: Maybe<Scalars['Boolean']>;
  startsWith?: Maybe<Scalars['Boolean']>;
  endsWith?: Maybe<Scalars['Boolean']>;
  contains?: Maybe<Scalars['Boolean']>;
  notContains?: Maybe<Scalars['Boolean']>;
  containsi?: Maybe<Scalars['Boolean']>;
  notContainsi?: Maybe<Scalars['Boolean']>;
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  between?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
};

export type CardPayload = {
  __typename?: 'CardPayload';
  brand?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  featureImage?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  onlyEmployeesVerify?: Maybe<Scalars['Boolean']>;
  task_types?: Maybe<TaskTypeRelationResponseCollection>;
  ER_ID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type CategoryTask_TypesArgs = {
  filters?: Maybe<TaskTypeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Category>;
};

export type CategoryEntityResponse = {
  __typename?: 'CategoryEntityResponse';
  data?: Maybe<CategoryEntity>;
};

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection';
  data: Array<CategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryFiltersInput = {
  id?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  onlyEmployeesVerify?: Maybe<BooleanFilterInput>;
  task_types?: Maybe<TaskTypeFiltersInput>;
  ER_ID?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<CategoryFiltersInput>>>;
  or?: Maybe<Array<Maybe<CategoryFiltersInput>>>;
  not?: Maybe<CategoryFiltersInput>;
};

export type CategoryInput = {
  featureImage?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  onlyEmployeesVerify?: Maybe<Scalars['Boolean']>;
  task_types?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ER_ID?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type CategorySet = {
  __typename?: 'CategorySet';
  id: Scalars['String'];
  title: Scalars['String'];
  index: Scalars['Int'];
};

export type ChartData = {
  __typename?: 'ChartData';
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type CodeValid = {
  __typename?: 'CodeValid';
  valid?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
};

export type Community = {
  __typename?: 'Community';
  name?: Maybe<Scalars['String']>;
  community_members?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  description?: Maybe<Scalars['String']>;
  chief_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSON']>;
  featured_image?: Maybe<UploadFileEntityResponse>;
  baoleader?: Maybe<UsersPermissionsUserEntityResponse>;
  type?: Maybe<Enum_Community_Type>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type CommunityCommunity_MembersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommunityEntity = {
  __typename?: 'CommunityEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Community>;
};

export type CommunityEntityResponse = {
  __typename?: 'CommunityEntityResponse';
  data?: Maybe<CommunityEntity>;
};

export type CommunityEntityResponseCollection = {
  __typename?: 'CommunityEntityResponseCollection';
  data: Array<CommunityEntity>;
  meta: ResponseCollectionMeta;
};

export type CommunityFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  community_members?: Maybe<UsersPermissionsUserFiltersInput>;
  description?: Maybe<StringFilterInput>;
  chief_name?: Maybe<StringFilterInput>;
  location?: Maybe<JsonFilterInput>;
  baoleader?: Maybe<UsersPermissionsUserFiltersInput>;
  type?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<CommunityFiltersInput>>>;
  or?: Maybe<Array<Maybe<CommunityFiltersInput>>>;
  not?: Maybe<CommunityFiltersInput>;
};

export type CommunityInput = {
  name?: Maybe<Scalars['String']>;
  community_members?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  chief_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSON']>;
  featured_image?: Maybe<Scalars['ID']>;
  baoleader?: Maybe<Scalars['ID']>;
  type?: Maybe<Enum_Community_Type>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type CommunityMembersPayload = {
  __typename?: 'CommunityMembersPayload';
  member?: Maybe<UsersPermissionsUserEntity>;
};

export type Company = {
  __typename?: 'Company';
  name?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  wildlife_association_number?: Maybe<Scalars['String']>;
  pin_registration?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  affiliates?: Maybe<Scalars['JSON']>;
  team_members?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  subscription?: Maybe<SubscriptionEntityResponse>;
  email: Scalars['String'];
  data_lists?: Maybe<DataListRelationResponseCollection>;
  matabase_group_ID?: Maybe<Scalars['String']>;
  projects?: Maybe<ProjectRelationResponseCollection>;
  default_dashboard?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Company_Status>;
  paymentMethod?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type CompanyTeam_MembersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type CompanyData_ListsArgs = {
  filters?: Maybe<DataListFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type CompanyProjectsArgs = {
  filters?: Maybe<ProjectFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Company>;
};

export type CompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse';
  data?: Maybe<CompanyEntity>;
};

export type CompanyEntityResponseCollection = {
  __typename?: 'CompanyEntityResponseCollection';
  data: Array<CompanyEntity>;
  meta: ResponseCollectionMeta;
};

export type CompanyFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  id_number?: Maybe<StringFilterInput>;
  wildlife_association_number?: Maybe<StringFilterInput>;
  pin_registration?: Maybe<StringFilterInput>;
  website?: Maybe<StringFilterInput>;
  affiliates?: Maybe<JsonFilterInput>;
  team_members?: Maybe<UsersPermissionsUserFiltersInput>;
  customerId?: Maybe<StringFilterInput>;
  subscription?: Maybe<SubscriptionFiltersInput>;
  email?: Maybe<StringFilterInput>;
  data_lists?: Maybe<DataListFiltersInput>;
  matabase_group_ID?: Maybe<StringFilterInput>;
  projects?: Maybe<ProjectFiltersInput>;
  default_dashboard?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  paymentMethod?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<CompanyFiltersInput>>>;
  or?: Maybe<Array<Maybe<CompanyFiltersInput>>>;
  not?: Maybe<CompanyFiltersInput>;
};

export type CompanyInput = {
  name?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  wildlife_association_number?: Maybe<Scalars['String']>;
  pin_registration?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  affiliates?: Maybe<Scalars['JSON']>;
  team_members?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customerId?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  data_lists?: Maybe<Array<Maybe<Scalars['ID']>>>;
  matabase_group_ID?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  default_dashboard?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Company_Status>;
  paymentMethod?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyRelationResponseCollection = {
  __typename?: 'CompanyRelationResponseCollection';
  data: Array<CompanyEntity>;
};

export type ComponentComboFieldOption = {
  __typename?: 'ComponentComboFieldOption';
  id: Scalars['ID'];
  option?: Maybe<Scalars['String']>;
};

export type ComponentComboFieldOptionFiltersInput = {
  option?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ComponentComboFieldOptionFiltersInput>>>;
  or?: Maybe<Array<Maybe<ComponentComboFieldOptionFiltersInput>>>;
  not?: Maybe<ComponentComboFieldOptionFiltersInput>;
};

export type ComponentComboFieldOptionInput = {
  id?: Maybe<Scalars['ID']>;
  option?: Maybe<Scalars['String']>;
};

export type ComponentCustomDataFieldsCheckbox = {
  __typename?: 'ComponentCustomDataFieldsCheckbox';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  option?: Maybe<Array<Maybe<ComponentComboFieldOption>>>;
  isRequired?: Maybe<Scalars['Boolean']>;
};


export type ComponentCustomDataFieldsCheckboxOptionArgs = {
  filters?: Maybe<ComponentComboFieldOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentCustomDataFieldsDataList = {
  __typename?: 'ComponentCustomDataFieldsDataList';
  id: Scalars['ID'];
  listId: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  option?: Maybe<Array<Maybe<ComponentComboFieldOption>>>;
};


export type ComponentCustomDataFieldsDataListOptionArgs = {
  filters?: Maybe<ComponentComboFieldOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentCustomDataFieldsDateTimeField = {
  __typename?: 'ComponentCustomDataFieldsDateTimeField';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['DateTime']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsEmailField = {
  __typename?: 'ComponentCustomDataFieldsEmailField';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsImageField = {
  __typename?: 'ComponentCustomDataFieldsImageField';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  images?: Maybe<UploadFileRelationResponseCollection>;
  isRequired?: Maybe<Scalars['Boolean']>;
  imageUrls?: Maybe<Scalars['JSON']>;
  upload_state?: Maybe<Enum_Componentcustomdatafieldsimagefield_Upload_State>;
};


export type ComponentCustomDataFieldsImageFieldImagesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentCustomDataFieldsMetricsSpecific = {
  __typename?: 'ComponentCustomDataFieldsMetricsSpecific';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  metric?: Maybe<Scalars['String']>;
  number_value?: Maybe<Scalars['Float']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsNumberDropDown = {
  __typename?: 'ComponentCustomDataFieldsNumberDropDown';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Long']>;
  min?: Maybe<Scalars['Long']>;
  max?: Maybe<Scalars['Long']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsNumberField = {
  __typename?: 'ComponentCustomDataFieldsNumberField';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  number_field_value?: Maybe<Scalars['Float']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsNumberRange = {
  __typename?: 'ComponentCustomDataFieldsNumberRange';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Long']>;
  max?: Maybe<Scalars['Long']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsPercentage = {
  __typename?: 'ComponentCustomDataFieldsPercentage';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Long']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsPhoneNumberField = {
  __typename?: 'ComponentCustomDataFieldsPhoneNumberField';
  id: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsRadio = {
  __typename?: 'ComponentCustomDataFieldsRadio';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  option?: Maybe<Array<Maybe<ComponentComboFieldOption>>>;
  isRequired?: Maybe<Scalars['Boolean']>;
};


export type ComponentCustomDataFieldsRadioOptionArgs = {
  filters?: Maybe<ComponentComboFieldOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentCustomDataFieldsRating = {
  __typename?: 'ComponentCustomDataFieldsRating';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  number_of_stars?: Maybe<Scalars['Long']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentCustomDataFieldsSelect = {
  __typename?: 'ComponentCustomDataFieldsSelect';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  option?: Maybe<Array<Maybe<ComponentComboFieldOption>>>;
  isRequired?: Maybe<Scalars['Boolean']>;
};


export type ComponentCustomDataFieldsSelectOptionArgs = {
  filters?: Maybe<ComponentComboFieldOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentCustomDataFieldsText = {
  __typename?: 'ComponentCustomDataFieldsText';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  text_value?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type ComponentFaqFaq = {
  __typename?: 'ComponentFaqFaq';
  id: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  category?: Maybe<Enum_Componentfaqfaq_Category>;
  tags?: Maybe<Array<Maybe<ComponentComboFieldOption>>>;
};


export type ComponentFaqFaqTagsArgs = {
  filters?: Maybe<ComponentComboFieldOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentFaqFaqFiltersInput = {
  question?: Maybe<StringFilterInput>;
  answer?: Maybe<StringFilterInput>;
  category?: Maybe<StringFilterInput>;
  tags?: Maybe<ComponentComboFieldOptionFiltersInput>;
  and?: Maybe<Array<Maybe<ComponentFaqFaqFiltersInput>>>;
  or?: Maybe<Array<Maybe<ComponentFaqFaqFiltersInput>>>;
  not?: Maybe<ComponentFaqFaqFiltersInput>;
};

export type ComponentFaqFaqInput = {
  id?: Maybe<Scalars['ID']>;
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  category?: Maybe<Enum_Componentfaqfaq_Category>;
  tags?: Maybe<Array<Maybe<ComponentComboFieldOptionInput>>>;
};

export type ComponentPricePlansPlans = {
  __typename?: 'ComponentPricePlansPlans';
  id: Scalars['ID'];
  price?: Maybe<Scalars['Float']>;
  recurring?: Maybe<Enum_Componentpriceplansplans_Recurring>;
  priceId?: Maybe<Scalars['String']>;
};

export type ComponentProductFeatureProductFeature = {
  __typename?: 'ComponentProductFeatureProductFeature';
  id: Scalars['ID'];
  baoriders_allowed?: Maybe<Scalars['Int']>;
  cost_per_baorider?: Maybe<Scalars['Float']>;
  recurring?: Maybe<Enum_Componentproductfeatureproductfeature_Recurring>;
  price?: Maybe<Scalars['Long']>;
};

export type ComponentProductFeatureProductFeatureFiltersInput = {
  baoriders_allowed?: Maybe<IntFilterInput>;
  cost_per_baorider?: Maybe<FloatFilterInput>;
  recurring?: Maybe<StringFilterInput>;
  price?: Maybe<LongFilterInput>;
  and?: Maybe<Array<Maybe<ComponentProductFeatureProductFeatureFiltersInput>>>;
  or?: Maybe<Array<Maybe<ComponentProductFeatureProductFeatureFiltersInput>>>;
  not?: Maybe<ComponentProductFeatureProductFeatureFiltersInput>;
};

export type ComponentProductFeatureProductFeatureInput = {
  id?: Maybe<Scalars['ID']>;
  baoriders_allowed?: Maybe<Scalars['Int']>;
  cost_per_baorider?: Maybe<Scalars['Float']>;
  recurring?: Maybe<Enum_Componentproductfeatureproductfeature_Recurring>;
  price?: Maybe<Scalars['Long']>;
};

export type ComponentTrainingVideoTrainingVideo = {
  __typename?: 'ComponentTrainingVideoTrainingVideo';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<ComponentComboFieldOption>>>;
  category?: Maybe<Enum_Componenttrainingvideotrainingvideo_Category>;
};


export type ComponentTrainingVideoTrainingVideoTagsArgs = {
  filters?: Maybe<ComponentComboFieldOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentTrainingVideoTrainingVideoFiltersInput = {
  title?: Maybe<StringFilterInput>;
  url?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  tags?: Maybe<ComponentComboFieldOptionFiltersInput>;
  category?: Maybe<StringFilterInput>;
  and?: Maybe<Array<Maybe<ComponentTrainingVideoTrainingVideoFiltersInput>>>;
  or?: Maybe<Array<Maybe<ComponentTrainingVideoTrainingVideoFiltersInput>>>;
  not?: Maybe<ComponentTrainingVideoTrainingVideoFiltersInput>;
};

export type ComponentTrainingVideoTrainingVideoInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<ComponentComboFieldOptionInput>>>;
  category?: Maybe<Enum_Componenttrainingvideotrainingvideo_Category>;
};

export type CreateBaoleaderInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title: Scalars['String'];
  email: Scalars['String'];
  role: Scalars['String'];
};

export type CreateBaoriderInput = {
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  community: Scalars['ID'];
  projects?: Maybe<Array<Scalars['ID']>>;
  idNumber?: Maybe<Scalars['String']>;
  isVerifier?: Maybe<Scalars['Boolean']>;
};

export type CreateCommunityMembersInput = {
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  community: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
};

export type CreateNewSubscriptionInput = {
  priceId: Scalars['String'];
  plan: Scalars['String'];
  recurring: Scalars['String'];
  collectionMethod: Scalars['String'];
};

export type CustomDataLabel = {
  __typename?: 'CustomDataLabel';
  label?: Maybe<Scalars['String']>;
  type?: Maybe<DataLabelTypeEntityResponse>;
  options?: Maybe<ProjectDataLabelTypeOptionRelationResponseCollection>;
  task_type?: Maybe<TaskTypeEntityResponse>;
  baoleader?: Maybe<UsersPermissionsUserEntityResponse>;
  is_default?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type CustomDataLabelOptionsArgs = {
  filters?: Maybe<ProjectDataLabelTypeOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type CustomDataLabelEntity = {
  __typename?: 'CustomDataLabelEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<CustomDataLabel>;
};

export type CustomDataLabelEntityResponse = {
  __typename?: 'CustomDataLabelEntityResponse';
  data?: Maybe<CustomDataLabelEntity>;
};

export type CustomDataLabelEntityResponseCollection = {
  __typename?: 'CustomDataLabelEntityResponseCollection';
  data: Array<CustomDataLabelEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomDataLabelFiltersInput = {
  id?: Maybe<IdFilterInput>;
  label?: Maybe<StringFilterInput>;
  type?: Maybe<DataLabelTypeFiltersInput>;
  options?: Maybe<ProjectDataLabelTypeOptionFiltersInput>;
  task_type?: Maybe<TaskTypeFiltersInput>;
  baoleader?: Maybe<UsersPermissionsUserFiltersInput>;
  is_default?: Maybe<BooleanFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<CustomDataLabelFiltersInput>>>;
  or?: Maybe<Array<Maybe<CustomDataLabelFiltersInput>>>;
  not?: Maybe<CustomDataLabelFiltersInput>;
};

export type CustomDataLabelInput = {
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
  options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  task_type?: Maybe<Scalars['ID']>;
  baoleader?: Maybe<Scalars['ID']>;
  is_default?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomDataLabelRelationResponseCollection = {
  __typename?: 'CustomDataLabelRelationResponseCollection';
  data: Array<CustomDataLabelEntity>;
};

export type DataLabelType = {
  __typename?: 'DataLabelType';
  name?: Maybe<Scalars['String']>;
  has_options?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type DataLabelTypeEntity = {
  __typename?: 'DataLabelTypeEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<DataLabelType>;
};

export type DataLabelTypeEntityResponse = {
  __typename?: 'DataLabelTypeEntityResponse';
  data?: Maybe<DataLabelTypeEntity>;
};

export type DataLabelTypeEntityResponseCollection = {
  __typename?: 'DataLabelTypeEntityResponseCollection';
  data: Array<DataLabelTypeEntity>;
  meta: ResponseCollectionMeta;
};

export type DataLabelTypeFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  has_options?: Maybe<BooleanFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<DataLabelTypeFiltersInput>>>;
  or?: Maybe<Array<Maybe<DataLabelTypeFiltersInput>>>;
  not?: Maybe<DataLabelTypeFiltersInput>;
};

export type DataLabelTypeInput = {
  name?: Maybe<Scalars['String']>;
  has_options?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type DataList = {
  __typename?: 'DataList';
  name: Scalars['String'];
  data_list_options?: Maybe<DataListOptionRelationResponseCollection>;
  company?: Maybe<CompanyEntityResponse>;
  status?: Maybe<Enum_Datalist_Status>;
  updateKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type DataListData_List_OptionsArgs = {
  filters?: Maybe<DataListOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type DataListEntity = {
  __typename?: 'DataListEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<DataList>;
};

export type DataListEntityResponse = {
  __typename?: 'DataListEntityResponse';
  data?: Maybe<DataListEntity>;
};

export type DataListEntityResponseCollection = {
  __typename?: 'DataListEntityResponseCollection';
  data: Array<DataListEntity>;
  meta: ResponseCollectionMeta;
};

export type DataListFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  data_list_options?: Maybe<DataListOptionFiltersInput>;
  company?: Maybe<CompanyFiltersInput>;
  status?: Maybe<StringFilterInput>;
  updateKey?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<DataListFiltersInput>>>;
  or?: Maybe<Array<Maybe<DataListFiltersInput>>>;
  not?: Maybe<DataListFiltersInput>;
};

export type DataListInput = {
  name?: Maybe<Scalars['String']>;
  data_list_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  company?: Maybe<Scalars['ID']>;
  status?: Maybe<Enum_Datalist_Status>;
  updateKey?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type DataListOption = {
  __typename?: 'DataListOption';
  data_list?: Maybe<DataListEntityResponse>;
  value: Scalars['String'];
  status?: Maybe<Enum_Datalistoption_Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type DataListOptionEntity = {
  __typename?: 'DataListOptionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<DataListOption>;
};

export type DataListOptionEntityResponse = {
  __typename?: 'DataListOptionEntityResponse';
  data?: Maybe<DataListOptionEntity>;
};

export type DataListOptionEntityResponseCollection = {
  __typename?: 'DataListOptionEntityResponseCollection';
  data: Array<DataListOptionEntity>;
  meta: ResponseCollectionMeta;
};

export type DataListOptionFiltersInput = {
  id?: Maybe<IdFilterInput>;
  data_list?: Maybe<DataListFiltersInput>;
  value?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<DataListOptionFiltersInput>>>;
  or?: Maybe<Array<Maybe<DataListOptionFiltersInput>>>;
  not?: Maybe<DataListOptionFiltersInput>;
};

export type DataListOptionInput = {
  data_list?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Datalistoption_Status>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type DataListOptionRelationResponseCollection = {
  __typename?: 'DataListOptionRelationResponseCollection';
  data: Array<DataListOptionEntity>;
};

export type DataListOptionWithCount = {
  __typename?: 'DataListOptionWithCount';
  data?: Maybe<Array<Maybe<DataListOptionEntity>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DataListRelationResponseCollection = {
  __typename?: 'DataListRelationResponseCollection';
  data: Array<DataListEntity>;
};


export type DateFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Date']>>>;
  or?: Maybe<Array<Maybe<Scalars['Date']>>>;
  not?: Maybe<DateFilterInput>;
  eq?: Maybe<Scalars['Date']>;
  eqi?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  nei?: Maybe<Scalars['Date']>;
  startsWith?: Maybe<Scalars['Date']>;
  endsWith?: Maybe<Scalars['Date']>;
  contains?: Maybe<Scalars['Date']>;
  notContains?: Maybe<Scalars['Date']>;
  containsi?: Maybe<Scalars['Date']>;
  notContainsi?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Date']>>>;
  between?: Maybe<Array<Maybe<Scalars['Date']>>>;
};


export type DateTimeFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  or?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  not?: Maybe<DateTimeFilterInput>;
  eq?: Maybe<Scalars['DateTime']>;
  eqi?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  nei?: Maybe<Scalars['DateTime']>;
  startsWith?: Maybe<Scalars['DateTime']>;
  endsWith?: Maybe<Scalars['DateTime']>;
  contains?: Maybe<Scalars['DateTime']>;
  notContains?: Maybe<Scalars['DateTime']>;
  containsi?: Maybe<Scalars['DateTime']>;
  notContainsi?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  between?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export enum Enum_Baoverifierapp_Status {
  Active = 'active',
  Inactive = 'inactive'
}

export enum Enum_Community_Type {
  Community = 'Community',
  Team = 'Team',
  Volunteers = 'Volunteers'
}

export enum Enum_Company_Status {
  Active = 'active',
  Inactive = 'inactive'
}

export enum Enum_Componentcustomdatafieldsimagefield_Upload_State {
  InProgress = 'in_progress',
  Done = 'done'
}

export enum Enum_Componentfaqfaq_Category {
  Admin = 'Admin',
  DataCollector = 'Data_Collector',
  WelcomeToBaotree = 'Welcome_to_Baotree'
}

export enum Enum_Componentpriceplansplans_Recurring {
  Month = 'month',
  Year = 'year'
}

export enum Enum_Componentproductfeatureproductfeature_Recurring {
  Month = 'month',
  Year = 'year'
}

export enum Enum_Componenttrainingvideotrainingvideo_Category {
  Admin = 'Admin',
  DataCollector = 'Data_Collector',
  WelcomeToBaotree = 'Welcome_to_Baotree'
}

export enum Enum_Datalistoption_Status {
  Active = 'active',
  Inactive = 'inactive'
}

export enum Enum_Datalist_Status {
  Active = 'active',
  Inactive = 'inactive'
}

export enum Enum_Evidence_Upload_State {
  InProgress = 'in_progress',
  Done = 'done'
}

export enum Enum_Notification_Status {
  Success = 'SUCCESS',
  Warning = 'WARNING',
  Error = 'ERROR',
  Info = 'INFO'
}

export enum Enum_Project_Status {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Cancelled = 'CANCELLED',
  Archived = 'ARCHIVED'
}

export enum Enum_Request_Status {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export enum Enum_Scheduledcronjob_Jobtype {
  Login = 'LOGIN',
  DcWeeklyActivities = 'DC_WEEKLY_ACTIVITIES',
  DcInactivity = 'DC_INACTIVITY',
  DcLastTaskReported = 'DC_LAST_TASK_REPORTED'
}

export enum Enum_Scheduledcronjob_Status {
  Pending = 'PENDING',
  Done = 'DONE',
  Error = 'ERROR'
}

export enum Enum_Subscription_Collection_Method {
  ChargeAutomatically = 'charge_automatically',
  SendInvoice = 'send_invoice'
}

export enum Enum_Subscription_Recurring {
  Month = 'month',
  Year = 'year'
}

export enum Enum_Subscription_Status {
  Active = 'active',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid'
}

export enum Enum_Taskdefinition_Status {
  Active = 'active',
  Deactivated = 'deactivated'
}

export enum Enum_Tasktype_Status {
  Active = 'active',
  Deactivated = 'deactivated',
  Drafted = 'drafted'
}

export enum Enum_Task_Status {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Reported = 'REPORTED',
  Pending = 'PENDING',
  MoreInfoRequested = 'MORE_INFO_REQUESTED',
  Rejected = 'REJECTED'
}

export enum Enum_Task_Task_Origin {
  DataCollector = 'DATA_COLLECTOR',
  Leader = 'LEADER',
  CommunityMember = 'COMMUNITY_MEMBER',
  Baoverifier = 'BAOVERIFIER',
  Baoleader = 'BAOLEADER'
}

export enum Enum_Userspermissionsuser_Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  PreferNotToSay = 'Prefer_not_to_say'
}

export enum Enum_Userspermissionsuser_Onboarding {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

export enum Enum_Userspermissionsuser_Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  RequestPending = 'REQUEST_PENDING',
  Archived = 'ARCHIVED'
}

export enum Enum_Video_Category {
  General = 'general',
  Community = 'community',
  Project = 'project',
  Task = 'task',
  Baorider = 'baorider',
  Request = 'request'
}

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type Evidence = {
  __typename?: 'Evidence';
  notes?: Maybe<Scalars['String']>;
  photos?: Maybe<UploadFileRelationResponseCollection>;
  location?: Maybe<Scalars['String']>;
  baorider?: Maybe<UsersPermissionsUserEntityResponse>;
  cancellationReason?: Maybe<Scalars['String']>;
  requestedInfo?: Maybe<Scalars['String']>;
  data_field_values?: Maybe<TaskDataLabelValueRelationResponseCollection>;
  task?: Maybe<TaskEntityResponse>;
  custom_data_fields?: Maybe<Array<Maybe<EvidenceCustomDataFieldsDynamicZone>>>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  connectivity?: Maybe<Scalars['JSON']>;
  upload_state?: Maybe<Enum_Evidence_Upload_State>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type EvidencePhotosArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type EvidenceData_Field_ValuesArgs = {
  filters?: Maybe<TaskDataLabelValueFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type EvidenceCustomDataFieldsDynamicZone = ComponentCustomDataFieldsRadio | ComponentCustomDataFieldsText | ComponentCustomDataFieldsCheckbox | ComponentCustomDataFieldsSelect | ComponentCustomDataFieldsNumberRange | ComponentCustomDataFieldsPercentage | ComponentCustomDataFieldsMetricsSpecific | ComponentCustomDataFieldsNumberDropDown | ComponentCustomDataFieldsRating | ComponentCustomDataFieldsNumberField | ComponentCustomDataFieldsDateTimeField | ComponentCustomDataFieldsDataList | ComponentCustomDataFieldsEmailField | ComponentCustomDataFieldsPhoneNumberField | ComponentCustomDataFieldsImageField | Error;


export type EvidenceEntity = {
  __typename?: 'EvidenceEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Evidence>;
};

export type EvidenceEntityResponse = {
  __typename?: 'EvidenceEntityResponse';
  data?: Maybe<EvidenceEntity>;
};

export type EvidenceEntityResponseCollection = {
  __typename?: 'EvidenceEntityResponseCollection';
  data: Array<EvidenceEntity>;
  meta: ResponseCollectionMeta;
};

export type EvidenceFiltersInput = {
  id?: Maybe<IdFilterInput>;
  notes?: Maybe<StringFilterInput>;
  location?: Maybe<StringFilterInput>;
  baorider?: Maybe<UsersPermissionsUserFiltersInput>;
  cancellationReason?: Maybe<StringFilterInput>;
  requestedInfo?: Maybe<StringFilterInput>;
  data_field_values?: Maybe<TaskDataLabelValueFiltersInput>;
  task?: Maybe<TaskFiltersInput>;
  latitude?: Maybe<FloatFilterInput>;
  longitude?: Maybe<FloatFilterInput>;
  accuracy?: Maybe<FloatFilterInput>;
  connectivity?: Maybe<JsonFilterInput>;
  upload_state?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<EvidenceFiltersInput>>>;
  or?: Maybe<Array<Maybe<EvidenceFiltersInput>>>;
  not?: Maybe<EvidenceFiltersInput>;
};

export type EvidenceInput = {
  notes?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Maybe<Scalars['ID']>>>;
  location?: Maybe<Scalars['String']>;
  baorider?: Maybe<Scalars['ID']>;
  cancellationReason?: Maybe<Scalars['String']>;
  requestedInfo?: Maybe<Scalars['String']>;
  data_field_values?: Maybe<Array<Maybe<Scalars['ID']>>>;
  task?: Maybe<Scalars['ID']>;
  custom_data_fields?: Maybe<Array<Scalars['EvidenceCustomDataFieldsDynamicZoneInput']>>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  accuracy?: Maybe<Scalars['Float']>;
  connectivity?: Maybe<Scalars['JSON']>;
  upload_state?: Maybe<Enum_Evidence_Upload_State>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type EvidenceInputType = {
  input?: Maybe<EvidenceInput>;
  dataLabelValuesArr?: Maybe<Scalars['JSON']>;
  taskOfflineId?: Maybe<Scalars['String']>;
};

export type EvidenceRelationResponseCollection = {
  __typename?: 'EvidenceRelationResponseCollection';
  data: Array<EvidenceEntity>;
};

export type FileInfoInput = {
  name?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Float']>>>;
  or?: Maybe<Array<Maybe<Scalars['Float']>>>;
  not?: Maybe<FloatFilterInput>;
  eq?: Maybe<Scalars['Float']>;
  eqi?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  nei?: Maybe<Scalars['Float']>;
  startsWith?: Maybe<Scalars['Float']>;
  endsWith?: Maybe<Scalars['Float']>;
  contains?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
  containsi?: Maybe<Scalars['Float']>;
  notContainsi?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type GenericMorph = ComponentComboFieldOption | ComponentCustomDataFieldsCheckbox | ComponentCustomDataFieldsDataList | ComponentCustomDataFieldsDateTimeField | ComponentCustomDataFieldsEmailField | ComponentCustomDataFieldsImageField | ComponentCustomDataFieldsMetricsSpecific | ComponentCustomDataFieldsNumberDropDown | ComponentCustomDataFieldsNumberField | ComponentCustomDataFieldsNumberRange | ComponentCustomDataFieldsPercentage | ComponentCustomDataFieldsPhoneNumberField | ComponentCustomDataFieldsRadio | ComponentCustomDataFieldsRating | ComponentCustomDataFieldsSelect | ComponentCustomDataFieldsText | ComponentFaqFaq | ComponentPricePlansPlans | ComponentProductFeatureProductFeature | ComponentTrainingVideoTrainingVideo | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | I18NLocale | Analytic | BaoverifierApp | Category | Community | Company | CustomDataLabel | DataLabelType | DataList | DataListOption | Evidence | Notification | ProductPlan | Project | ProjectDataLabelTypeOption | Request | ScheduledCronEmailStatus | ScheduledCronJob | Subscription | Task | TaskDataLabelValue | TaskDefinition | TaskType | TermsAndCondition | TrainingVideo | UnSdg | Video;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<I18NLocale>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  code?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  or?: Maybe<Array<Maybe<I18NLocaleFiltersInput>>>;
  not?: Maybe<I18NLocaleFiltersInput>;
};

export type IdFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['ID']>>>;
  or?: Maybe<Array<Maybe<Scalars['ID']>>>;
  not?: Maybe<IdFilterInput>;
  eq?: Maybe<Scalars['ID']>;
  eqi?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  nei?: Maybe<Scalars['ID']>;
  startsWith?: Maybe<Scalars['ID']>;
  endsWith?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  containsi?: Maybe<Scalars['ID']>;
  notContainsi?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type IntFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Int']>>>;
  or?: Maybe<Array<Maybe<Scalars['Int']>>>;
  not?: Maybe<IntFilterInput>;
  eq?: Maybe<Scalars['Int']>;
  eqi?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  nei?: Maybe<Scalars['Int']>;
  startsWith?: Maybe<Scalars['Int']>;
  endsWith?: Maybe<Scalars['Int']>;
  contains?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
  containsi?: Maybe<Scalars['Int']>;
  notContainsi?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type InvoicePayload = {
  __typename?: 'InvoicePayload';
  id?: Maybe<Scalars['ID']>;
  account_country?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  account_tax_ids?: Maybe<Scalars['String']>;
  amount_due?: Maybe<Scalars['Float']>;
  amount_paid?: Maybe<Scalars['Float']>;
  amount_remaining?: Maybe<Scalars['Float']>;
  application_fee_amount?: Maybe<Scalars['Float']>;
  attempt_count?: Maybe<Scalars['Int']>;
  attempted?: Maybe<Scalars['Boolean']>;
  auto_advance?: Maybe<Scalars['Boolean']>;
  billing_reason?: Maybe<Scalars['String']>;
  collection_method?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  default_payment_method?: Maybe<Scalars['String']>;
  default_source?: Maybe<Scalars['String']>;
  default_tax_rates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  discounts?: Maybe<Array<Maybe<Scalars['Float']>>>;
  due_date?: Maybe<Scalars['String']>;
  ending_balance?: Maybe<Scalars['Float']>;
  hosted_invoice_url?: Maybe<Scalars['String']>;
  invoice_pdf?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  period_end?: Maybe<Scalars['String']>;
  period_start?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  lines?: Maybe<Scalars['JSON']>;
  number?: Maybe<Scalars['String']>;
};


export type JsonFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  or?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  not?: Maybe<JsonFilterInput>;
  eq?: Maybe<Scalars['JSON']>;
  eqi?: Maybe<Scalars['JSON']>;
  ne?: Maybe<Scalars['JSON']>;
  nei?: Maybe<Scalars['JSON']>;
  startsWith?: Maybe<Scalars['JSON']>;
  endsWith?: Maybe<Scalars['JSON']>;
  contains?: Maybe<Scalars['JSON']>;
  notContains?: Maybe<Scalars['JSON']>;
  containsi?: Maybe<Scalars['JSON']>;
  notContainsi?: Maybe<Scalars['JSON']>;
  gt?: Maybe<Scalars['JSON']>;
  gte?: Maybe<Scalars['JSON']>;
  lt?: Maybe<Scalars['JSON']>;
  lte?: Maybe<Scalars['JSON']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  between?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


export type LongFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['Long']>>>;
  or?: Maybe<Array<Maybe<Scalars['Long']>>>;
  not?: Maybe<LongFilterInput>;
  eq?: Maybe<Scalars['Long']>;
  eqi?: Maybe<Scalars['Long']>;
  ne?: Maybe<Scalars['Long']>;
  nei?: Maybe<Scalars['Long']>;
  startsWith?: Maybe<Scalars['Long']>;
  endsWith?: Maybe<Scalars['Long']>;
  contains?: Maybe<Scalars['Long']>;
  notContains?: Maybe<Scalars['Long']>;
  containsi?: Maybe<Scalars['Long']>;
  notContainsi?: Maybe<Scalars['Long']>;
  gt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Long']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Long']>>>;
  between?: Maybe<Array<Maybe<Scalars['Long']>>>;
};

export type Metric = {
  __typename?: 'Metric';
  total: Scalars['Float'];
  last30Days: Scalars['Float'];
  chartData?: Maybe<Array<Maybe<ChartData>>>;
};

export type MultipleBaoriderPayload = {
  __typename?: 'MultipleBaoriderPayload';
  baoriders?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
};

export type MultipleCommunityMembersPayload = {
  __typename?: 'MultipleCommunityMembersPayload';
  members?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBaoleaderCommunity?: Maybe<CommunityEntity>;
  updateBaoleaderCommunity?: Maybe<CommunityEntity>;
  removeCompanyMember?: Maybe<UsersPermissionsUserEntity>;
  unarchiveCompanyMember?: Maybe<UsersPermissionsUserEntity>;
  requestUpdateCompanyDetails?: Maybe<Company>;
  createBaoriderEvidence: EvidenceEntity;
  updateBaoriderEvidence: Evidence;
  updateBaoleaderEvidence: Evidence;
  createBaoleaderProject?: Maybe<ProjectEntity>;
  updateBaoleaderProject?: Maybe<ProjectEntity>;
  addDataCollectorToProject?: Maybe<ProjectEntity>;
  exportExcel?: Maybe<Scalars['JSON']>;
  acceptBaoriderTask: TaskEntity;
  createBaoleaderTask: TaskEntity;
  createBaoverifierTask: TaskEntity;
  createBaoverifierFullTask: TaskAndEvidenceType;
  updateBaoleaderTask: TaskEntity;
  deleteBaoleaderTask?: Maybe<TaskEntity>;
  updateBaoriderTask: TaskEntity;
  createDataLabelValue?: Maybe<TaskDataLabelValue>;
  createBaoleaderTaskDefinition: TaskDefinitionEntity;
  updateBaoleaderTaskDefinition: TaskDefinitionEntity;
  deleteBaoleaderTaskDefinition?: Maybe<TaskDefinitionEntity>;
  createBaoleaderTaskType: TaskTypeEntity;
  updateBaoleaderTaskType: TaskTypeEntity;
  deactivateTaskType: TaskTypeEntity;
  activateTaskType: TaskTypeEntity;
  verifyDatacollectOtp?: Maybe<CodeValid>;
  resendDataCollectorInvite?: Maybe<UsersPermissionsUserEntity>;
  changeMyPassword?: Maybe<UsersPermissionsUserEntity>;
  createBaoriders: MultipleBaoriderPayload;
  createBaoleader: BaoleaderPayload;
  createBaorider: BaoriderPayload;
  updateBaorider: BaoriderPayload;
  removeBaorider: BaoriderPayload;
  updateMyUser?: Maybe<UsersPermissionsUserEntity>;
  skipOnboardingStep?: Maybe<UsersPermissionsUserEntity>;
  completeOnboardingStep?: Maybe<UsersPermissionsUserEntity>;
  createCommunityMembers: Array<Maybe<CommunityMembersPayload>>;
  createCommunityMember: CommunityMembersPayload;
  updateCommunityMember: CommunityMembersPayload;
  removeCommunityMember: CommunityMembersPayload;
  suggestCommunityMember: CommunityMembersPayload;
  acceptCommunityMemberRequest: UsersPermissionsUserEntity;
  rejectCommunityMemberRequest: UsersPermissionsUserEntity;
  createNewSubscription?: Maybe<Subscription>;
  loginCustomerPortal?: Maybe<Scalars['String']>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateAnalytic?: Maybe<AnalyticEntityResponse>;
  deleteAnalytic?: Maybe<AnalyticEntityResponse>;
  updateBaoverifierApp?: Maybe<BaoverifierAppEntityResponse>;
  deleteBaoverifierApp?: Maybe<BaoverifierAppEntityResponse>;
  createCategory?: Maybe<CategoryEntityResponse>;
  updateCategory?: Maybe<CategoryEntityResponse>;
  deleteCategory?: Maybe<CategoryEntityResponse>;
  createCommunity?: Maybe<CommunityEntityResponse>;
  updateCommunity?: Maybe<CommunityEntityResponse>;
  deleteCommunity?: Maybe<CommunityEntityResponse>;
  createCompany?: Maybe<CompanyEntityResponse>;
  updateCompany?: Maybe<CompanyEntityResponse>;
  deleteCompany?: Maybe<CompanyEntityResponse>;
  createCustomDataLabel?: Maybe<CustomDataLabelEntityResponse>;
  updateCustomDataLabel?: Maybe<CustomDataLabelEntityResponse>;
  deleteCustomDataLabel?: Maybe<CustomDataLabelEntityResponse>;
  createDataLabelType?: Maybe<DataLabelTypeEntityResponse>;
  updateDataLabelType?: Maybe<DataLabelTypeEntityResponse>;
  deleteDataLabelType?: Maybe<DataLabelTypeEntityResponse>;
  createDataList?: Maybe<DataListEntityResponse>;
  updateDataList?: Maybe<DataListEntityResponse>;
  deleteDataList?: Maybe<DataListEntityResponse>;
  createDataListOption?: Maybe<DataListOptionEntityResponse>;
  updateDataListOption?: Maybe<DataListOptionEntityResponse>;
  deleteDataListOption?: Maybe<DataListOptionEntityResponse>;
  createEvidence?: Maybe<EvidenceEntityResponse>;
  updateEvidence?: Maybe<EvidenceEntityResponse>;
  deleteEvidence?: Maybe<EvidenceEntityResponse>;
  createNotification?: Maybe<NotificationEntityResponse>;
  updateNotification?: Maybe<NotificationEntityResponse>;
  deleteNotification?: Maybe<NotificationEntityResponse>;
  createProductPlan?: Maybe<ProductPlanEntityResponse>;
  updateProductPlan?: Maybe<ProductPlanEntityResponse>;
  deleteProductPlan?: Maybe<ProductPlanEntityResponse>;
  createProject?: Maybe<ProjectEntityResponse>;
  updateProject?: Maybe<ProjectEntityResponse>;
  deleteProject?: Maybe<ProjectEntityResponse>;
  createProjectDataLabelTypeOption?: Maybe<ProjectDataLabelTypeOptionEntityResponse>;
  updateProjectDataLabelTypeOption?: Maybe<ProjectDataLabelTypeOptionEntityResponse>;
  deleteProjectDataLabelTypeOption?: Maybe<ProjectDataLabelTypeOptionEntityResponse>;
  createRequest?: Maybe<RequestEntityResponse>;
  updateRequest?: Maybe<RequestEntityResponse>;
  deleteRequest?: Maybe<RequestEntityResponse>;
  createScheduledCronEmailStatus?: Maybe<ScheduledCronEmailStatusEntityResponse>;
  updateScheduledCronEmailStatus?: Maybe<ScheduledCronEmailStatusEntityResponse>;
  deleteScheduledCronEmailStatus?: Maybe<ScheduledCronEmailStatusEntityResponse>;
  createScheduledCronJob?: Maybe<ScheduledCronJobEntityResponse>;
  updateScheduledCronJob?: Maybe<ScheduledCronJobEntityResponse>;
  deleteScheduledCronJob?: Maybe<ScheduledCronJobEntityResponse>;
  createSubscription?: Maybe<SubscriptionEntityResponse>;
  updateSubscription?: Maybe<SubscriptionEntityResponse>;
  deleteSubscription?: Maybe<SubscriptionEntityResponse>;
  createTask?: Maybe<TaskEntityResponse>;
  updateTask?: Maybe<TaskEntityResponse>;
  deleteTask?: Maybe<TaskEntityResponse>;
  createTaskDataLabelValue?: Maybe<TaskDataLabelValueEntityResponse>;
  updateTaskDataLabelValue?: Maybe<TaskDataLabelValueEntityResponse>;
  deleteTaskDataLabelValue?: Maybe<TaskDataLabelValueEntityResponse>;
  createTaskDefinition?: Maybe<TaskDefinitionEntityResponse>;
  updateTaskDefinition?: Maybe<TaskDefinitionEntityResponse>;
  deleteTaskDefinition?: Maybe<TaskDefinitionEntityResponse>;
  createTaskType?: Maybe<TaskTypeEntityResponse>;
  updateTaskType?: Maybe<TaskTypeEntityResponse>;
  deleteTaskType?: Maybe<TaskTypeEntityResponse>;
  updateTermsAndCondition?: Maybe<TermsAndConditionEntityResponse>;
  deleteTermsAndCondition?: Maybe<TermsAndConditionEntityResponse>;
  updateTrainingVideo?: Maybe<TrainingVideoEntityResponse>;
  deleteTrainingVideo?: Maybe<TrainingVideoEntityResponse>;
  createUnSdg?: Maybe<UnSdgEntityResponse>;
  updateUnSdg?: Maybe<UnSdgEntityResponse>;
  deleteUnSdg?: Maybe<UnSdgEntityResponse>;
  createVideo?: Maybe<VideoEntityResponse>;
  updateVideo?: Maybe<VideoEntityResponse>;
  deleteVideo?: Maybe<VideoEntityResponse>;
  upload: UploadFileEntityResponse;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  updateFileInfo: UploadFileEntityResponse;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  login: UsersPermissionsLoginPayload;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
};


export type MutationCreateBaoleaderCommunityArgs = {
  input?: Maybe<CommunityInput>;
};


export type MutationUpdateBaoleaderCommunityArgs = {
  id: Scalars['ID'];
  input?: Maybe<CommunityInput>;
};


export type MutationRemoveCompanyMemberArgs = {
  memberId: Scalars['ID'];
};


export type MutationUnarchiveCompanyMemberArgs = {
  memberId: Scalars['ID'];
};


export type MutationRequestUpdateCompanyDetailsArgs = {
  data: RequestCompanyDetailsChangeInput;
};


export type MutationCreateBaoriderEvidenceArgs = {
  input?: Maybe<EvidenceInput>;
  dataLabelValuesArr?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateBaoriderEvidenceArgs = {
  id: Scalars['ID'];
  input?: Maybe<EvidenceInput>;
};


export type MutationUpdateBaoleaderEvidenceArgs = {
  id: Scalars['ID'];
  input?: Maybe<EvidenceInput>;
};


export type MutationCreateBaoleaderProjectArgs = {
  input?: Maybe<ProjectInput>;
};


export type MutationUpdateBaoleaderProjectArgs = {
  id: Scalars['ID'];
  input?: Maybe<ProjectInput>;
};


export type MutationAddDataCollectorToProjectArgs = {
  input?: Maybe<AddDataCollectorToProjectInput>;
};


export type MutationExportExcelArgs = {
  filterValues?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
};


export type MutationAcceptBaoriderTaskArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBaoleaderTaskArgs = {
  input?: Maybe<TaskInput>;
};


export type MutationCreateBaoverifierTaskArgs = {
  input?: Maybe<TaskInput>;
};


export type MutationCreateBaoverifierFullTaskArgs = {
  task?: Maybe<TaskInput>;
  evidence?: Maybe<EvidenceInputType>;
};


export type MutationUpdateBaoleaderTaskArgs = {
  id: Scalars['ID'];
  input: TaskInput;
};


export type MutationDeleteBaoleaderTaskArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateBaoriderTaskArgs = {
  id: Scalars['ID'];
  input?: Maybe<TaskInput>;
};


export type MutationCreateDataLabelValueArgs = {
  input?: Maybe<TaskDataLabelValueInput>;
};


export type MutationCreateBaoleaderTaskDefinitionArgs = {
  input?: Maybe<TaskDefinitionInput>;
};


export type MutationUpdateBaoleaderTaskDefinitionArgs = {
  id: Scalars['ID'];
  input?: Maybe<TaskDefinitionInput>;
};


export type MutationDeleteBaoleaderTaskDefinitionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBaoleaderTaskTypeArgs = {
  input: TaskTypeInput;
  dataFieldsArr?: Maybe<Scalars['JSON']>;
};


export type MutationUpdateBaoleaderTaskTypeArgs = {
  id: Scalars['ID'];
  input: TaskTypeInput;
  dataFieldsArr?: Maybe<Scalars['JSON']>;
};


export type MutationDeactivateTaskTypeArgs = {
  id: Scalars['ID'];
};


export type MutationActivateTaskTypeArgs = {
  id: Scalars['ID'];
};


export type MutationVerifyDatacollectOtpArgs = {
  code: Scalars['String'];
  otp: Scalars['String'];
};


export type MutationResendDataCollectorInviteArgs = {
  id: Scalars['ID'];
};


export type MutationChangeMyPasswordArgs = {
  password: Scalars['String'];
  passwordNew: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreateBaoridersArgs = {
  input: Array<Maybe<CreateBaoriderInput>>;
};


export type MutationCreateBaoleaderArgs = {
  input: CreateBaoleaderInput;
};


export type MutationCreateBaoriderArgs = {
  input: CreateBaoriderInput;
  sendInvite?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateBaoriderArgs = {
  id: Scalars['ID'];
  baorider?: Maybe<UpdateBaoriderInput>;
};


export type MutationRemoveBaoriderArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMyUserArgs = {
  input?: Maybe<UpdateMyUserInput>;
};


export type MutationSkipOnboardingStepArgs = {
  index: Scalars['String'];
  completeOnboarding?: Maybe<Scalars['Boolean']>;
};


export type MutationCompleteOnboardingStepArgs = {
  index: Scalars['String'];
  completeOnboarding?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateCommunityMembersArgs = {
  input?: Maybe<Array<Maybe<CreateCommunityMembersInput>>>;
};


export type MutationCreateCommunityMemberArgs = {
  input?: Maybe<CreateCommunityMembersInput>;
  sendInvite?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCommunityMemberArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateCommunityMembersInput>;
};


export type MutationRemoveCommunityMemberArgs = {
  id: Scalars['ID'];
};


export type MutationSuggestCommunityMemberArgs = {
  input?: Maybe<SuggestCommunityMembersInput>;
};


export type MutationAcceptCommunityMemberRequestArgs = {
  requestId: Scalars['ID'];
};


export type MutationRejectCommunityMemberRequestArgs = {
  requestId: Scalars['ID'];
};


export type MutationCreateNewSubscriptionArgs = {
  input?: Maybe<CreateNewSubscriptionInput>;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationUpdateUploadFileArgs = {
  id: Scalars['ID'];
  data: UploadFileInput;
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationUpdateUploadFolderArgs = {
  id: Scalars['ID'];
  data: UploadFolderInput;
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAnalyticArgs = {
  data: AnalyticInput;
};


export type MutationUpdateBaoverifierAppArgs = {
  data: BaoverifierAppInput;
};


export type MutationCreateCategoryArgs = {
  data: CategoryInput;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  data: CategoryInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCommunityArgs = {
  data: CommunityInput;
};


export type MutationUpdateCommunityArgs = {
  id: Scalars['ID'];
  data: CommunityInput;
};


export type MutationDeleteCommunityArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  data: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  data: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCustomDataLabelArgs = {
  data: CustomDataLabelInput;
};


export type MutationUpdateCustomDataLabelArgs = {
  id: Scalars['ID'];
  data: CustomDataLabelInput;
};


export type MutationDeleteCustomDataLabelArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDataLabelTypeArgs = {
  data: DataLabelTypeInput;
};


export type MutationUpdateDataLabelTypeArgs = {
  id: Scalars['ID'];
  data: DataLabelTypeInput;
};


export type MutationDeleteDataLabelTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDataListArgs = {
  data: DataListInput;
};


export type MutationUpdateDataListArgs = {
  id: Scalars['ID'];
  data: DataListInput;
};


export type MutationDeleteDataListArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDataListOptionArgs = {
  data: DataListOptionInput;
};


export type MutationUpdateDataListOptionArgs = {
  id: Scalars['ID'];
  data: DataListOptionInput;
};


export type MutationDeleteDataListOptionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEvidenceArgs = {
  data: EvidenceInput;
};


export type MutationUpdateEvidenceArgs = {
  id: Scalars['ID'];
  data: EvidenceInput;
};


export type MutationDeleteEvidenceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateNotificationArgs = {
  data: NotificationInput;
};


export type MutationUpdateNotificationArgs = {
  id: Scalars['ID'];
  data: NotificationInput;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateProductPlanArgs = {
  data: ProductPlanInput;
};


export type MutationUpdateProductPlanArgs = {
  id: Scalars['ID'];
  data: ProductPlanInput;
};


export type MutationDeleteProductPlanArgs = {
  id: Scalars['ID'];
};


export type MutationCreateProjectArgs = {
  data: ProjectInput;
};


export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  data: ProjectInput;
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};


export type MutationCreateProjectDataLabelTypeOptionArgs = {
  data: ProjectDataLabelTypeOptionInput;
};


export type MutationUpdateProjectDataLabelTypeOptionArgs = {
  id: Scalars['ID'];
  data: ProjectDataLabelTypeOptionInput;
};


export type MutationDeleteProjectDataLabelTypeOptionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateRequestArgs = {
  data: RequestInput;
};


export type MutationUpdateRequestArgs = {
  id: Scalars['ID'];
  data: RequestInput;
};


export type MutationDeleteRequestArgs = {
  id: Scalars['ID'];
};


export type MutationCreateScheduledCronEmailStatusArgs = {
  data: ScheduledCronEmailStatusInput;
};


export type MutationUpdateScheduledCronEmailStatusArgs = {
  id: Scalars['ID'];
  data: ScheduledCronEmailStatusInput;
};


export type MutationDeleteScheduledCronEmailStatusArgs = {
  id: Scalars['ID'];
};


export type MutationCreateScheduledCronJobArgs = {
  data: ScheduledCronJobInput;
};


export type MutationUpdateScheduledCronJobArgs = {
  id: Scalars['ID'];
  data: ScheduledCronJobInput;
};


export type MutationDeleteScheduledCronJobArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSubscriptionArgs = {
  data: SubscriptionInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['ID'];
  data: SubscriptionInput;
};


export type MutationDeleteSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTaskArgs = {
  data: TaskInput;
};


export type MutationUpdateTaskArgs = {
  id: Scalars['ID'];
  data: TaskInput;
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTaskDataLabelValueArgs = {
  data: TaskDataLabelValueInput;
};


export type MutationUpdateTaskDataLabelValueArgs = {
  id: Scalars['ID'];
  data: TaskDataLabelValueInput;
};


export type MutationDeleteTaskDataLabelValueArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTaskDefinitionArgs = {
  data: TaskDefinitionInput;
};


export type MutationUpdateTaskDefinitionArgs = {
  id: Scalars['ID'];
  data: TaskDefinitionInput;
};


export type MutationDeleteTaskDefinitionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTaskTypeArgs = {
  data: TaskTypeInput;
};


export type MutationUpdateTaskTypeArgs = {
  id: Scalars['ID'];
  data: TaskTypeInput;
};


export type MutationDeleteTaskTypeArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTermsAndConditionArgs = {
  data: TermsAndConditionInput;
};


export type MutationUpdateTrainingVideoArgs = {
  data: TrainingVideoInput;
};


export type MutationCreateUnSdgArgs = {
  data: UnSdgInput;
};


export type MutationUpdateUnSdgArgs = {
  id: Scalars['ID'];
  data: UnSdgInput;
};


export type MutationDeleteUnSdgArgs = {
  id: Scalars['ID'];
};


export type MutationCreateVideoArgs = {
  data: VideoInput;
};


export type MutationUpdateVideoArgs = {
  id: Scalars['ID'];
  data: VideoInput;
};


export type MutationDeleteVideoArgs = {
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  refId?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  info?: Maybe<FileInfoInput>;
  file: Scalars['Upload'];
};


export type MutationMultipleUploadArgs = {
  refId?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: Maybe<FileInfoInput>;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsRoleInput;
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationUpdateUsersPermissionsUserArgs = {
  id: Scalars['ID'];
  data: UsersPermissionsUserInput;
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  code: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Enum_Notification_Status>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
  action?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Notification>;
};

export type NotificationEntityResponse = {
  __typename?: 'NotificationEntityResponse';
  data?: Maybe<NotificationEntity>;
};

export type NotificationEntityResponseCollection = {
  __typename?: 'NotificationEntityResponseCollection';
  data: Array<NotificationEntity>;
  meta: ResponseCollectionMeta;
};

export type NotificationFiltersInput = {
  id?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
  message?: Maybe<StringFilterInput>;
  read?: Maybe<BooleanFilterInput>;
  status?: Maybe<StringFilterInput>;
  user?: Maybe<UsersPermissionsUserFiltersInput>;
  action?: Maybe<JsonFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<NotificationFiltersInput>>>;
  or?: Maybe<Array<Maybe<NotificationFiltersInput>>>;
  not?: Maybe<NotificationFiltersInput>;
};

export type NotificationInput = {
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Enum_Notification_Status>;
  user?: Maybe<Scalars['ID']>;
  action?: Maybe<Scalars['JSON']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRelationResponseCollection = {
  __typename?: 'NotificationRelationResponseCollection';
  data: Array<NotificationEntity>;
};

export type Pagination = {
  __typename?: 'Pagination';
  total: Scalars['Int'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pageCount: Scalars['Int'];
};

export type PaginationArg = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PopularTaskDefinitionEntityWithCount = {
  __typename?: 'PopularTaskDefinitionEntityWithCount';
  count: Scalars['Int'];
  taskDefinition: TaskDefinitionEntity;
};

export type Prices = {
  __typename?: 'Prices';
  id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Float']>;
  unit_amount_decimal?: Maybe<Scalars['Float']>;
  recurring?: Maybe<Recurring>;
};

export type ProductMetaData = {
  __typename?: 'ProductMetaData';
  baoriders_allowed?: Maybe<Scalars['Int']>;
  baoriders_cost?: Maybe<Scalars['Float']>;
};

export type ProductPlan = {
  __typename?: 'ProductPlan';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ProductFeature?: Maybe<ComponentProductFeatureProductFeature>;
  subscriptions?: Maybe<SubscriptionRelationResponseCollection>;
  isDefault?: Maybe<Scalars['Boolean']>;
  priceId?: Maybe<Scalars['String']>;
  isManual?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type ProductPlanSubscriptionsArgs = {
  filters?: Maybe<SubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductPlanEntity = {
  __typename?: 'ProductPlanEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<ProductPlan>;
};

export type ProductPlanEntityResponse = {
  __typename?: 'ProductPlanEntityResponse';
  data?: Maybe<ProductPlanEntity>;
};

export type ProductPlanEntityResponseCollection = {
  __typename?: 'ProductPlanEntityResponseCollection';
  data: Array<ProductPlanEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductPlanFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  productId?: Maybe<StringFilterInput>;
  ProductFeature?: Maybe<ComponentProductFeatureProductFeatureFiltersInput>;
  subscriptions?: Maybe<SubscriptionFiltersInput>;
  isDefault?: Maybe<BooleanFilterInput>;
  priceId?: Maybe<StringFilterInput>;
  isManual?: Maybe<BooleanFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<ProductPlanFiltersInput>>>;
  or?: Maybe<Array<Maybe<ProductPlanFiltersInput>>>;
  not?: Maybe<ProductPlanFiltersInput>;
};

export type ProductPlanInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  ProductFeature?: Maybe<ComponentProductFeatureProductFeatureInput>;
  subscriptions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isDefault?: Maybe<Scalars['Boolean']>;
  priceId?: Maybe<Scalars['String']>;
  isManual?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type Products = {
  __typename?: 'Products';
  id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  statement_descriptor?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<Maybe<Prices>>>;
  metadata?: Maybe<ProductMetaData>;
};

export type Project = {
  __typename?: 'Project';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Project_Status>;
  featured_image?: Maybe<UploadFileEntityResponse>;
  budget?: Maybe<Scalars['String']>;
  outcomes?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Scalars['JSON']>;
  breakdown?: Maybe<Scalars['JSON']>;
  reward?: Maybe<Scalars['String']>;
  baoleaders?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  task_definitions?: Maybe<TaskDefinitionRelationResponseCollection>;
  companies?: Maybe<CompanyRelationResponseCollection>;
  verifiers?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type ProjectBaoleadersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProjectTask_DefinitionsArgs = {
  filters?: Maybe<TaskDefinitionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type ProjectCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type ProjectVerifiersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProjectDataLabelTypeOption = {
  __typename?: 'ProjectDataLabelTypeOption';
  value: Scalars['String'];
  custom_data_field?: Maybe<CustomDataLabelEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectDataLabelTypeOptionEntity = {
  __typename?: 'ProjectDataLabelTypeOptionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<ProjectDataLabelTypeOption>;
};

export type ProjectDataLabelTypeOptionEntityResponse = {
  __typename?: 'ProjectDataLabelTypeOptionEntityResponse';
  data?: Maybe<ProjectDataLabelTypeOptionEntity>;
};

export type ProjectDataLabelTypeOptionEntityResponseCollection = {
  __typename?: 'ProjectDataLabelTypeOptionEntityResponseCollection';
  data: Array<ProjectDataLabelTypeOptionEntity>;
  meta: ResponseCollectionMeta;
};

export type ProjectDataLabelTypeOptionFiltersInput = {
  id?: Maybe<IdFilterInput>;
  value?: Maybe<StringFilterInput>;
  custom_data_field?: Maybe<CustomDataLabelFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<ProjectDataLabelTypeOptionFiltersInput>>>;
  or?: Maybe<Array<Maybe<ProjectDataLabelTypeOptionFiltersInput>>>;
  not?: Maybe<ProjectDataLabelTypeOptionFiltersInput>;
};

export type ProjectDataLabelTypeOptionInput = {
  value?: Maybe<Scalars['String']>;
  custom_data_field?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectDataLabelTypeOptionRelationResponseCollection = {
  __typename?: 'ProjectDataLabelTypeOptionRelationResponseCollection';
  data: Array<ProjectDataLabelTypeOptionEntity>;
};

export type ProjectEntity = {
  __typename?: 'ProjectEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Project>;
};

export type ProjectEntityResponse = {
  __typename?: 'ProjectEntityResponse';
  data?: Maybe<ProjectEntity>;
};

export type ProjectEntityResponseCollection = {
  __typename?: 'ProjectEntityResponseCollection';
  data: Array<ProjectEntity>;
  meta: ResponseCollectionMeta;
};

export type ProjectFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  budget?: Maybe<StringFilterInput>;
  outcomes?: Maybe<StringFilterInput>;
  statement?: Maybe<StringFilterInput>;
  collaborators?: Maybe<JsonFilterInput>;
  breakdown?: Maybe<JsonFilterInput>;
  reward?: Maybe<StringFilterInput>;
  baoleaders?: Maybe<UsersPermissionsUserFiltersInput>;
  task_definitions?: Maybe<TaskDefinitionFiltersInput>;
  companies?: Maybe<CompanyFiltersInput>;
  verifiers?: Maybe<UsersPermissionsUserFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<ProjectFiltersInput>>>;
  or?: Maybe<Array<Maybe<ProjectFiltersInput>>>;
  not?: Maybe<ProjectFiltersInput>;
};

export type ProjectInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Project_Status>;
  featured_image?: Maybe<Scalars['ID']>;
  budget?: Maybe<Scalars['String']>;
  outcomes?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Scalars['JSON']>;
  breakdown?: Maybe<Scalars['JSON']>;
  reward?: Maybe<Scalars['String']>;
  baoleaders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  task_definitions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  verifiers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectRelationResponseCollection = {
  __typename?: 'ProjectRelationResponseCollection';
  data: Array<ProjectEntity>;
};

export type ProjectSet = {
  __typename?: 'ProjectSet';
  project?: Maybe<ProjectEntity>;
  taskCompleted: Scalars['Int'];
  increaseIn30days: Scalars['Int'];
  completedPercentage: Scalars['Float'];
  chartData?: Maybe<Array<Maybe<ChartData>>>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  getCompanyCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  getTaskBuilderFilterCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  getTaskBuilderFilterCreators?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
  getTaskFilterCommunities?: Maybe<Array<Maybe<CommunityEntity>>>;
  getSingleBaoleaderCommunity?: Maybe<CommunityEntity>;
  getBaoleaderCommunity?: Maybe<CommunityEntity>;
  getBaoleaderCommunities?: Maybe<Array<Maybe<CommunityEntity>>>;
  getSingleBaoriderCommunity?: Maybe<BaoriderCommunityMetrics>;
  getBaoriderCommunities?: Maybe<Array<Maybe<CommunityEntity>>>;
  countCompanyCommunities?: Maybe<Scalars['Int']>;
  getCompanyInfo?: Maybe<Company>;
  countCompanyMembers?: Maybe<Scalars['Int']>;
  getAllProducts?: Maybe<Array<Maybe<Products>>>;
  getAllInvoices?: Maybe<Array<Maybe<InvoicePayload>>>;
  getSubscription?: Maybe<SubscriptionPayload>;
  getCustomerPaymentDetails?: Maybe<Array<Maybe<CardPayload>>>;
  getBaoriderEvidence?: Maybe<Evidence>;
  getTaskFilterProjects?: Maybe<Array<Maybe<ProjectEntity>>>;
  getBaoleaderProjectsCategories?: Maybe<Array<Maybe<CategorySet>>>;
  countCompanyProjects?: Maybe<Scalars['Int']>;
  getCommunityProjects?: Maybe<Array<Maybe<ProjectEntity>>>;
  getCommunityProjectsCount?: Maybe<Scalars['Int']>;
  getProjectDataCollectorsCount?: Maybe<Scalars['Int']>;
  getProjectUnsdgTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  getBaoleaderProject?: Maybe<ProjectEntity>;
  getProjectMetrics?: Maybe<TaskMetrics>;
  getSingleBaoleaderProject?: Maybe<ProjectEntity>;
  getBaoleaderProjects?: Maybe<Array<Maybe<ProjectEntity>>>;
  getBaoriderProjects?: Maybe<Array<Maybe<ProjectEntity>>>;
  getSingleBaoriderProject?: Maybe<ProjectEntity>;
  getBaoriderCommunityProjects?: Maybe<Array<Maybe<ProjectEntity>>>;
  getProjectGroups?: Maybe<Array<Maybe<CommunityEntity>>>;
  getRequests?: Maybe<Array<Maybe<Request>>>;
  getTasksDashboardMetrics?: Maybe<TaskMetrics>;
  getBaoriderDashboardMetrics?: Maybe<BaoriderTaskMetrics>;
  getBaoleaderTasksWithCount?: Maybe<TasksWithCount>;
  getBaoleaderTask?: Maybe<TaskEntity>;
  getBaoriderTasks?: Maybe<TasksWithCount>;
  getSingleBaoriderTask?: Maybe<TaskEntity>;
  countTasks?: Maybe<Scalars['Int']>;
  getBaoleaderTaskDefinitions?: Maybe<Array<Maybe<TaskDefinitionEntity>>>;
  getBaoleaderTaskDefinitionsCount?: Maybe<Scalars['Int']>;
  getBaoverifierTaskDefinitions?: Maybe<Array<Maybe<TaskDefinitionEntity>>>;
  getBaoverifierMostPopularTaskDefinitions?: Maybe<Array<Maybe<PopularTaskDefinitionEntityWithCount>>>;
  getBaoleaderTaskDefinition?: Maybe<TaskDefinitionEntity>;
  getCompanyTaskTypes?: Maybe<Array<Maybe<TaskTypeEntity>>>;
  getTaskType?: Maybe<TaskTypeEntity>;
  getWholeCompanyTaskTypes?: Maybe<Array<Maybe<TaskTypeEntity>>>;
  getCompanyTaskTypesCount?: Maybe<Scalars['Int']>;
  getTaskFilterTaskTypes?: Maybe<Array<Maybe<TaskTypeEntity>>>;
  getBaoriders?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
  getCompanyLeaders?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
  getCompanyLeadersCount?: Maybe<Scalars['Int']>;
  getTopPerformingUsers?: Maybe<TopPerforming>;
  myUser?: Maybe<UsersPermissionsUserEntity>;
  getTaskFilterDataCollectors?: Maybe<Array<Maybe<UsersPermissionsUserEntity>>>;
  getCommunityMembers?: Maybe<MultipleCommunityMembersPayload>;
  countCommunityMembers?: Maybe<Scalars['Int']>;
  getSingleCommunityMember?: Maybe<CommunityMembersPayload>;
  getSingleBaorider?: Maybe<BaoriderPayload>;
  getCommunityBaoriders?: Maybe<MultipleCommunityMembersPayload>;
  getCommunityVerifiers?: Maybe<MultipleCommunityMembersPayload>;
  countRiders?: Maybe<Scalars['Int']>;
  getDataLists?: Maybe<Array<Maybe<DataListEntity>>>;
  findDataListOptionWithCount?: Maybe<DataListOptionWithCount>;
  getTaskFilterUnsdgs?: Maybe<Array<Maybe<UnSdgEntity>>>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  analytic?: Maybe<AnalyticEntityResponse>;
  baoverifierApp?: Maybe<BaoverifierAppEntityResponse>;
  category?: Maybe<CategoryEntityResponse>;
  categories?: Maybe<CategoryEntityResponseCollection>;
  community?: Maybe<CommunityEntityResponse>;
  communities?: Maybe<CommunityEntityResponseCollection>;
  company?: Maybe<CompanyEntityResponse>;
  companies?: Maybe<CompanyEntityResponseCollection>;
  customDataLabel?: Maybe<CustomDataLabelEntityResponse>;
  customDataLabels?: Maybe<CustomDataLabelEntityResponseCollection>;
  dataLabelType?: Maybe<DataLabelTypeEntityResponse>;
  dataLabelTypes?: Maybe<DataLabelTypeEntityResponseCollection>;
  dataList?: Maybe<DataListEntityResponse>;
  dataLists?: Maybe<DataListEntityResponseCollection>;
  dataListOption?: Maybe<DataListOptionEntityResponse>;
  dataListOptions?: Maybe<DataListOptionEntityResponseCollection>;
  evidence?: Maybe<EvidenceEntityResponse>;
  evidences?: Maybe<EvidenceEntityResponseCollection>;
  notification?: Maybe<NotificationEntityResponse>;
  notifications?: Maybe<NotificationEntityResponseCollection>;
  productPlan?: Maybe<ProductPlanEntityResponse>;
  productPlans?: Maybe<ProductPlanEntityResponseCollection>;
  project?: Maybe<ProjectEntityResponse>;
  projects?: Maybe<ProjectEntityResponseCollection>;
  projectDataLabelTypeOption?: Maybe<ProjectDataLabelTypeOptionEntityResponse>;
  projectDataLabelTypeOptions?: Maybe<ProjectDataLabelTypeOptionEntityResponseCollection>;
  request?: Maybe<RequestEntityResponse>;
  requests?: Maybe<RequestEntityResponseCollection>;
  scheduledCronEmailStatus?: Maybe<ScheduledCronEmailStatusEntityResponse>;
  scheduledCronEmailStatuses?: Maybe<ScheduledCronEmailStatusEntityResponseCollection>;
  scheduledCronJob?: Maybe<ScheduledCronJobEntityResponse>;
  scheduledCronJobs?: Maybe<ScheduledCronJobEntityResponseCollection>;
  subscription?: Maybe<SubscriptionEntityResponse>;
  subscriptions?: Maybe<SubscriptionEntityResponseCollection>;
  task?: Maybe<TaskEntityResponse>;
  tasks?: Maybe<TaskEntityResponseCollection>;
  taskDataLabelValue?: Maybe<TaskDataLabelValueEntityResponse>;
  taskDataLabelValues?: Maybe<TaskDataLabelValueEntityResponseCollection>;
  taskDefinition?: Maybe<TaskDefinitionEntityResponse>;
  taskDefinitions?: Maybe<TaskDefinitionEntityResponseCollection>;
  taskType?: Maybe<TaskTypeEntityResponse>;
  taskTypes?: Maybe<TaskTypeEntityResponseCollection>;
  termsAndCondition?: Maybe<TermsAndConditionEntityResponse>;
  trainingVideo?: Maybe<TrainingVideoEntityResponse>;
  unSdg?: Maybe<UnSdgEntityResponse>;
  unSdgs?: Maybe<UnSdgEntityResponseCollection>;
  video?: Maybe<VideoEntityResponse>;
  videos?: Maybe<VideoEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
};


export type QueryGetCompanyCategoriesArgs = {
  where?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryGetTaskBuilderFilterCategoriesArgs = {
  where?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryGetTaskBuilderFilterCreatorsArgs = {
  where?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryGetTaskFilterCommunitiesArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetSingleBaoleaderCommunityArgs = {
  id: Scalars['ID'];
};


export type QueryGetBaoleaderCommunitiesArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetSingleBaoriderCommunityArgs = {
  id: Scalars['ID'];
};


export type QueryCountCompanyCommunitiesArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoriderEvidenceArgs = {
  id: Scalars['ID'];
};


export type QueryGetTaskFilterProjectsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryCountCompanyProjectsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetCommunityProjectsArgs = {
  community: Scalars['ID'];
};


export type QueryGetCommunityProjectsCountArgs = {
  community: Scalars['ID'];
};


export type QueryGetProjectDataCollectorsCountArgs = {
  project: Scalars['ID'];
};


export type QueryGetProjectUnsdgTagsArgs = {
  project: Scalars['ID'];
};


export type QueryGetProjectMetricsArgs = {
  id: Scalars['ID'];
};


export type QueryGetSingleBaoleaderProjectArgs = {
  id: Scalars['ID'];
};


export type QueryGetBaoleaderProjectsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoriderProjectsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetSingleBaoriderProjectArgs = {
  id: Scalars['ID'];
};


export type QueryGetBaoriderCommunityProjectsArgs = {
  community: Scalars['ID'];
};


export type QueryGetProjectGroupsArgs = {
  projectId: Scalars['ID'];
};


export type QueryGetRequestsArgs = {
  where?: Maybe<Scalars['JSON']>;
};


export type QueryGetBaoriderDashboardMetricsArgs = {
  where?: Maybe<Scalars['JSON']>;
};


export type QueryGetBaoleaderTasksWithCountArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoleaderTaskArgs = {
  id: Scalars['ID'];
};


export type QueryGetBaoriderTasksArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetSingleBaoriderTaskArgs = {
  id: Scalars['ID'];
};


export type QueryCountTasksArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoleaderTaskDefinitionsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoleaderTaskDefinitionsCountArgs = {
  filters?: Maybe<Scalars['JSON']>;
};


export type QueryGetBaoverifierTaskDefinitionsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoverifierMostPopularTaskDefinitionsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoleaderTaskDefinitionArgs = {
  id: Scalars['ID'];
};


export type QueryGetCompanyTaskTypesArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetTaskTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetWholeCompanyTaskTypesArgs = {
  sort?: Maybe<Scalars['JSON']>;
  filters?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
  pagination?: Maybe<PaginationArg>;
};


export type QueryGetCompanyTaskTypesCountArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetTaskFilterTaskTypesArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetBaoridersArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetCompanyLeadersArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetTopPerformingUsersArgs = {
  community?: Maybe<Scalars['ID']>;
  project?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTaskFilterDataCollectorsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetCommunityMembersArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryCountCommunityMembersArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryGetSingleCommunityMemberArgs = {
  where?: Maybe<Scalars['JSON']>;
};


export type QueryGetSingleBaoriderArgs = {
  id: Scalars['ID'];
};


export type QueryGetCommunityBaoridersArgs = {
  community: Scalars['ID'];
};


export type QueryGetCommunityVerifiersArgs = {
  community: Scalars['ID'];
};


export type QueryCountRidersArgs = {
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
};


export type QueryGetDataListsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryFindDataListOptionWithCountArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  publicationState?: Maybe<PublicationState>;
  pagination?: Maybe<PaginationArg>;
};


export type QueryGetTaskFilterUnsdgsArgs = {
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryUploadFileArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: Maybe<UsersPermissionsRoleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: Maybe<I18NLocaleFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAnalyticArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type QueryBaoverifierAppArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCategoriesArgs = {
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryCommunityArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCommunitiesArgs = {
  filters?: Maybe<CommunityFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCompaniesArgs = {
  filters?: Maybe<CompanyFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryCustomDataLabelArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCustomDataLabelsArgs = {
  filters?: Maybe<CustomDataLabelFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryDataLabelTypeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryDataLabelTypesArgs = {
  filters?: Maybe<DataLabelTypeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryDataListArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryDataListsArgs = {
  filters?: Maybe<DataListFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryDataListOptionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryDataListOptionsArgs = {
  filters?: Maybe<DataListOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryEvidenceArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEvidencesArgs = {
  filters?: Maybe<EvidenceFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryNotificationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryNotificationsArgs = {
  filters?: Maybe<NotificationFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryProductPlanArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProductPlansArgs = {
  filters?: Maybe<ProductPlanFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryProjectArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProjectsArgs = {
  filters?: Maybe<ProjectFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryProjectDataLabelTypeOptionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProjectDataLabelTypeOptionsArgs = {
  filters?: Maybe<ProjectDataLabelTypeOptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryRequestArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRequestsArgs = {
  filters?: Maybe<RequestFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryScheduledCronEmailStatusArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryScheduledCronEmailStatusesArgs = {
  filters?: Maybe<ScheduledCronEmailStatusFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryScheduledCronJobArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryScheduledCronJobsArgs = {
  filters?: Maybe<ScheduledCronJobFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySubscriptionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySubscriptionsArgs = {
  filters?: Maybe<SubscriptionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTaskArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTasksArgs = {
  filters?: Maybe<TaskFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryTaskDataLabelValueArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTaskDataLabelValuesArgs = {
  filters?: Maybe<TaskDataLabelValueFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryTaskDefinitionArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTaskDefinitionsArgs = {
  filters?: Maybe<TaskDefinitionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryTaskTypeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTaskTypesArgs = {
  filters?: Maybe<TaskTypeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryTermsAndConditionArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type QueryTrainingVideoArgs = {
  publicationState?: Maybe<PublicationState>;
};


export type QueryUnSdgArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUnSdgsArgs = {
  filters?: Maybe<UnSdgFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type QueryVideoArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryVideosArgs = {
  filters?: Maybe<VideoFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type Recurring = {
  __typename?: 'Recurring';
  interval?: Maybe<Scalars['String']>;
  interval_count?: Maybe<Scalars['Int']>;
};

export type Request = {
  __typename?: 'Request';
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Request_Status>;
  baoleader?: Maybe<UsersPermissionsUserEntityResponse>;
  community?: Maybe<CommunityEntityResponse>;
  community_member?: Maybe<UsersPermissionsUserEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type RequestCompanyDetailsChangeInput = {
  CompanyName?: Maybe<Scalars['String']>;
  orgID?: Maybe<Scalars['String']>;
  WildlifeAssociationNumber?: Maybe<Scalars['String']>;
  pin_registration?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  currentCompanyName?: Maybe<Scalars['String']>;
};

export type RequestEntity = {
  __typename?: 'RequestEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Request>;
};

export type RequestEntityResponse = {
  __typename?: 'RequestEntityResponse';
  data?: Maybe<RequestEntity>;
};

export type RequestEntityResponseCollection = {
  __typename?: 'RequestEntityResponseCollection';
  data: Array<RequestEntity>;
  meta: ResponseCollectionMeta;
};

export type RequestFiltersInput = {
  id?: Maybe<IdFilterInput>;
  reason?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  baoleader?: Maybe<UsersPermissionsUserFiltersInput>;
  community?: Maybe<CommunityFiltersInput>;
  community_member?: Maybe<UsersPermissionsUserFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<RequestFiltersInput>>>;
  or?: Maybe<Array<Maybe<RequestFiltersInput>>>;
  not?: Maybe<RequestFiltersInput>;
};

export type RequestInput = {
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Request_Status>;
  baoleader?: Maybe<Scalars['ID']>;
  community?: Maybe<Scalars['ID']>;
  community_member?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type RequestRelationResponseCollection = {
  __typename?: 'RequestRelationResponseCollection';
  data: Array<RequestEntity>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type ScheduledCronEmailStatus = {
  __typename?: 'ScheduledCronEmailStatus';
  user_id?: Maybe<UsersPermissionsUserEntityResponse>;
  job_id?: Maybe<ScheduledCronJobEntityResponse>;
  email_sent: Scalars['Boolean'];
  email_last_added: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ScheduledCronEmailStatusEntity = {
  __typename?: 'ScheduledCronEmailStatusEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<ScheduledCronEmailStatus>;
};

export type ScheduledCronEmailStatusEntityResponse = {
  __typename?: 'ScheduledCronEmailStatusEntityResponse';
  data?: Maybe<ScheduledCronEmailStatusEntity>;
};

export type ScheduledCronEmailStatusEntityResponseCollection = {
  __typename?: 'ScheduledCronEmailStatusEntityResponseCollection';
  data: Array<ScheduledCronEmailStatusEntity>;
  meta: ResponseCollectionMeta;
};

export type ScheduledCronEmailStatusFiltersInput = {
  id?: Maybe<IdFilterInput>;
  user_id?: Maybe<UsersPermissionsUserFiltersInput>;
  job_id?: Maybe<ScheduledCronJobFiltersInput>;
  email_sent?: Maybe<BooleanFilterInput>;
  email_last_added?: Maybe<DateTimeFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<ScheduledCronEmailStatusFiltersInput>>>;
  or?: Maybe<Array<Maybe<ScheduledCronEmailStatusFiltersInput>>>;
  not?: Maybe<ScheduledCronEmailStatusFiltersInput>;
};

export type ScheduledCronEmailStatusInput = {
  user_id?: Maybe<Scalars['ID']>;
  job_id?: Maybe<Scalars['ID']>;
  email_sent?: Maybe<Scalars['Boolean']>;
  email_last_added?: Maybe<Scalars['DateTime']>;
};

export type ScheduledCronJob = {
  __typename?: 'ScheduledCronJob';
  jobId: Scalars['String'];
  jobType: Enum_Scheduledcronjob_Jobtype;
  status: Enum_Scheduledcronjob_Status;
  started_at: Scalars['DateTime'];
  finished_at?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ScheduledCronJobEntity = {
  __typename?: 'ScheduledCronJobEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<ScheduledCronJob>;
};

export type ScheduledCronJobEntityResponse = {
  __typename?: 'ScheduledCronJobEntityResponse';
  data?: Maybe<ScheduledCronJobEntity>;
};

export type ScheduledCronJobEntityResponseCollection = {
  __typename?: 'ScheduledCronJobEntityResponseCollection';
  data: Array<ScheduledCronJobEntity>;
  meta: ResponseCollectionMeta;
};

export type ScheduledCronJobFiltersInput = {
  id?: Maybe<IdFilterInput>;
  jobId?: Maybe<StringFilterInput>;
  jobType?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  started_at?: Maybe<DateTimeFilterInput>;
  finished_at?: Maybe<DateTimeFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<ScheduledCronJobFiltersInput>>>;
  or?: Maybe<Array<Maybe<ScheduledCronJobFiltersInput>>>;
  not?: Maybe<ScheduledCronJobFiltersInput>;
};

export type ScheduledCronJobInput = {
  jobId?: Maybe<Scalars['String']>;
  jobType?: Maybe<Enum_Scheduledcronjob_Jobtype>;
  status?: Maybe<Enum_Scheduledcronjob_Status>;
  started_at?: Maybe<Scalars['DateTime']>;
  finished_at?: Maybe<Scalars['DateTime']>;
};

export type StringFilterInput = {
  and?: Maybe<Array<Maybe<Scalars['String']>>>;
  or?: Maybe<Array<Maybe<Scalars['String']>>>;
  not?: Maybe<StringFilterInput>;
  eq?: Maybe<Scalars['String']>;
  eqi?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  nei?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  containsi?: Maybe<Scalars['String']>;
  notContainsi?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  null?: Maybe<Scalars['Boolean']>;
  notNull?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  current_period_start?: Maybe<Scalars['DateTime']>;
  current_period_end?: Maybe<Scalars['DateTime']>;
  cancel_at?: Maybe<Scalars['DateTime']>;
  trial_start?: Maybe<Scalars['DateTime']>;
  trial_end?: Maybe<Scalars['DateTime']>;
  status: Enum_Subscription_Status;
  company?: Maybe<CompanyEntityResponse>;
  product_plan?: Maybe<ProductPlanEntityResponse>;
  recurring: Enum_Subscription_Recurring;
  priceId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Scalars['JSON']>;
  created?: Maybe<Scalars['DateTime']>;
  collection_method: Enum_Subscription_Collection_Method;
  monthly_extra?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Subscription>;
};

export type SubscriptionEntityResponse = {
  __typename?: 'SubscriptionEntityResponse';
  data?: Maybe<SubscriptionEntity>;
};

export type SubscriptionEntityResponseCollection = {
  __typename?: 'SubscriptionEntityResponseCollection';
  data: Array<SubscriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type SubscriptionFiltersInput = {
  id?: Maybe<IdFilterInput>;
  current_period_start?: Maybe<DateTimeFilterInput>;
  current_period_end?: Maybe<DateTimeFilterInput>;
  cancel_at?: Maybe<DateTimeFilterInput>;
  trial_start?: Maybe<DateTimeFilterInput>;
  trial_end?: Maybe<DateTimeFilterInput>;
  status?: Maybe<StringFilterInput>;
  company?: Maybe<CompanyFiltersInput>;
  product_plan?: Maybe<ProductPlanFiltersInput>;
  recurring?: Maybe<StringFilterInput>;
  priceId?: Maybe<StringFilterInput>;
  subscriptionId?: Maybe<StringFilterInput>;
  cancel_at_period_end?: Maybe<BooleanFilterInput>;
  items?: Maybe<JsonFilterInput>;
  created?: Maybe<DateTimeFilterInput>;
  collection_method?: Maybe<StringFilterInput>;
  monthly_extra?: Maybe<FloatFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<SubscriptionFiltersInput>>>;
  or?: Maybe<Array<Maybe<SubscriptionFiltersInput>>>;
  not?: Maybe<SubscriptionFiltersInput>;
};

export type SubscriptionInput = {
  current_period_start?: Maybe<Scalars['DateTime']>;
  current_period_end?: Maybe<Scalars['DateTime']>;
  cancel_at?: Maybe<Scalars['DateTime']>;
  trial_start?: Maybe<Scalars['DateTime']>;
  trial_end?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Enum_Subscription_Status>;
  company?: Maybe<Scalars['ID']>;
  product_plan?: Maybe<Scalars['ID']>;
  recurring?: Maybe<Enum_Subscription_Recurring>;
  priceId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Scalars['JSON']>;
  created?: Maybe<Scalars['DateTime']>;
  collection_method?: Maybe<Enum_Subscription_Collection_Method>;
  monthly_extra?: Maybe<Scalars['Float']>;
};

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload';
  id?: Maybe<Scalars['ID']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  cancel_at?: Maybe<Scalars['DateTime']>;
  collection_method?: Maybe<Scalars['String']>;
  recurring?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  current_period_end?: Maybe<Scalars['DateTime']>;
  current_period_start?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Scalars['ID']>;
  ended_at?: Maybe<Scalars['DateTime']>;
  latest_invoice?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  trial_end?: Maybe<Scalars['DateTime']>;
  trial_start?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Scalars['JSON']>;
  product_plan?: Maybe<ProductPlan>;
};

export type SubscriptionRelationResponseCollection = {
  __typename?: 'SubscriptionRelationResponseCollection';
  data: Array<SubscriptionEntity>;
};

export type SuggestCommunityMembersInput = {
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  community: Scalars['ID'];
  role: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  status?: Maybe<Enum_Task_Status>;
  community?: Maybe<CommunityEntityResponse>;
  baoleader?: Maybe<UsersPermissionsUserEntityResponse>;
  baorider?: Maybe<UsersPermissionsUserEntityResponse>;
  project?: Maybe<ProjectEntityResponse>;
  location?: Maybe<Scalars['JSON']>;
  onlyEmployeesVerify?: Maybe<Scalars['Boolean']>;
  accepted_at?: Maybe<Scalars['DateTime']>;
  task_definition?: Maybe<TaskDefinitionEntityResponse>;
  evidences?: Maybe<EvidenceRelationResponseCollection>;
  task_origin?: Maybe<Enum_Task_Task_Origin>;
  documents?: Maybe<UploadFileRelationResponseCollection>;
  date_reported?: Maybe<Scalars['DateTime']>;
  ER_ID?: Maybe<Scalars['String']>;
  task_offline_id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type TaskEvidencesArgs = {
  filters?: Maybe<EvidenceFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type TaskDocumentsArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TaskAndEvidenceType = {
  __typename?: 'TaskAndEvidenceType';
  task?: Maybe<TaskEntity>;
  evidence?: Maybe<EvidenceEntity>;
};

export type TaskDataLabelValue = {
  __typename?: 'TaskDataLabelValue';
  evidence?: Maybe<EvidenceEntityResponse>;
  data_field?: Maybe<CustomDataLabelEntityResponse>;
  baorider?: Maybe<UsersPermissionsUserEntityResponse>;
  value?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskDataLabelValueEntity = {
  __typename?: 'TaskDataLabelValueEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<TaskDataLabelValue>;
};

export type TaskDataLabelValueEntityResponse = {
  __typename?: 'TaskDataLabelValueEntityResponse';
  data?: Maybe<TaskDataLabelValueEntity>;
};

export type TaskDataLabelValueEntityResponseCollection = {
  __typename?: 'TaskDataLabelValueEntityResponseCollection';
  data: Array<TaskDataLabelValueEntity>;
  meta: ResponseCollectionMeta;
};

export type TaskDataLabelValueFiltersInput = {
  id?: Maybe<IdFilterInput>;
  evidence?: Maybe<EvidenceFiltersInput>;
  data_field?: Maybe<CustomDataLabelFiltersInput>;
  baorider?: Maybe<UsersPermissionsUserFiltersInput>;
  value?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<TaskDataLabelValueFiltersInput>>>;
  or?: Maybe<Array<Maybe<TaskDataLabelValueFiltersInput>>>;
  not?: Maybe<TaskDataLabelValueFiltersInput>;
};

export type TaskDataLabelValueInput = {
  evidence?: Maybe<Scalars['ID']>;
  data_field?: Maybe<Scalars['ID']>;
  baorider?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskDataLabelValueRelationResponseCollection = {
  __typename?: 'TaskDataLabelValueRelationResponseCollection';
  data: Array<TaskDataLabelValueEntity>;
};

export type TaskDefinition = {
  __typename?: 'TaskDefinition';
  category?: Maybe<CategoryEntityResponse>;
  task_type?: Maybe<TaskTypeEntityResponse>;
  project?: Maybe<ProjectEntityResponse>;
  baoleader?: Maybe<UsersPermissionsUserEntityResponse>;
  ecoCredits?: Maybe<Scalars['Float']>;
  status?: Maybe<Enum_Taskdefinition_Status>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskDefinitionEntity = {
  __typename?: 'TaskDefinitionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<TaskDefinition>;
};

export type TaskDefinitionEntityResponse = {
  __typename?: 'TaskDefinitionEntityResponse';
  data?: Maybe<TaskDefinitionEntity>;
};

export type TaskDefinitionEntityResponseCollection = {
  __typename?: 'TaskDefinitionEntityResponseCollection';
  data: Array<TaskDefinitionEntity>;
  meta: ResponseCollectionMeta;
};

export type TaskDefinitionFiltersInput = {
  id?: Maybe<IdFilterInput>;
  category?: Maybe<CategoryFiltersInput>;
  task_type?: Maybe<TaskTypeFiltersInput>;
  project?: Maybe<ProjectFiltersInput>;
  baoleader?: Maybe<UsersPermissionsUserFiltersInput>;
  ecoCredits?: Maybe<FloatFilterInput>;
  status?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<TaskDefinitionFiltersInput>>>;
  or?: Maybe<Array<Maybe<TaskDefinitionFiltersInput>>>;
  not?: Maybe<TaskDefinitionFiltersInput>;
};

export type TaskDefinitionInput = {
  category?: Maybe<Scalars['ID']>;
  task_type?: Maybe<Scalars['ID']>;
  project?: Maybe<Scalars['ID']>;
  baoleader?: Maybe<Scalars['ID']>;
  ecoCredits?: Maybe<Scalars['Float']>;
  status?: Maybe<Enum_Taskdefinition_Status>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskDefinitionRelationResponseCollection = {
  __typename?: 'TaskDefinitionRelationResponseCollection';
  data: Array<TaskDefinitionEntity>;
};

export type TaskEntity = {
  __typename?: 'TaskEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Task>;
};

export type TaskEntityResponse = {
  __typename?: 'TaskEntityResponse';
  data?: Maybe<TaskEntity>;
};

export type TaskEntityResponseCollection = {
  __typename?: 'TaskEntityResponseCollection';
  data: Array<TaskEntity>;
  meta: ResponseCollectionMeta;
};

export type TaskFiltersInput = {
  id?: Maybe<IdFilterInput>;
  status?: Maybe<StringFilterInput>;
  community?: Maybe<CommunityFiltersInput>;
  baoleader?: Maybe<UsersPermissionsUserFiltersInput>;
  baorider?: Maybe<UsersPermissionsUserFiltersInput>;
  project?: Maybe<ProjectFiltersInput>;
  location?: Maybe<JsonFilterInput>;
  onlyEmployeesVerify?: Maybe<BooleanFilterInput>;
  accepted_at?: Maybe<DateTimeFilterInput>;
  task_definition?: Maybe<TaskDefinitionFiltersInput>;
  evidences?: Maybe<EvidenceFiltersInput>;
  task_origin?: Maybe<StringFilterInput>;
  date_reported?: Maybe<DateTimeFilterInput>;
  ER_ID?: Maybe<StringFilterInput>;
  task_offline_id?: Maybe<StringFilterInput>;
  timezone?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<TaskFiltersInput>>>;
  or?: Maybe<Array<Maybe<TaskFiltersInput>>>;
  not?: Maybe<TaskFiltersInput>;
};

export type TaskInput = {
  status?: Maybe<Enum_Task_Status>;
  community?: Maybe<Scalars['ID']>;
  baoleader?: Maybe<Scalars['ID']>;
  baorider?: Maybe<Scalars['ID']>;
  project?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['JSON']>;
  onlyEmployeesVerify?: Maybe<Scalars['Boolean']>;
  accepted_at?: Maybe<Scalars['DateTime']>;
  task_definition?: Maybe<Scalars['ID']>;
  evidences?: Maybe<Array<Maybe<Scalars['ID']>>>;
  task_origin?: Maybe<Enum_Task_Task_Origin>;
  documents?: Maybe<Array<Maybe<Scalars['ID']>>>;
  date_reported?: Maybe<Scalars['DateTime']>;
  ER_ID?: Maybe<Scalars['String']>;
  task_offline_id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskMetrics = {
  __typename?: 'TaskMetrics';
  completedTasks?: Maybe<Metric>;
  reportedTask?: Maybe<Metric>;
  averageCompletionTime?: Maybe<AverageMetirc>;
  averageEvidenceTime?: Maybe<AverageMetirc>;
};

export type TaskType = {
  __typename?: 'TaskType';
  title?: Maybe<Scalars['String']>;
  photoEvidence?: Maybe<Scalars['String']>;
  documentedEvidence?: Maybe<Scalars['String']>;
  environmentalImpact?: Maybe<Scalars['String']>;
  socialImpact?: Maybe<Scalars['String']>;
  onlyEmployeesVerify?: Maybe<Scalars['Boolean']>;
  custom_data_fields?: Maybe<CustomDataLabelRelationResponseCollection>;
  dataFields?: Maybe<Array<Maybe<TaskTypeDataFieldsDynamicZone>>>;
  photoRequired?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<UsersPermissionsUserEntityResponse>;
  category?: Maybe<CategoryEntityResponse>;
  status?: Maybe<Enum_Tasktype_Status>;
  un_sdg?: Maybe<UnSdgEntityResponse>;
  ER_ID?: Maybe<Scalars['String']>;
  withComment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type TaskTypeCustom_Data_FieldsArgs = {
  filters?: Maybe<CustomDataLabelFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type TaskTypeDataFieldsDynamicZone = ComponentCustomDataFieldsCheckbox | ComponentCustomDataFieldsRadio | ComponentCustomDataFieldsSelect | ComponentCustomDataFieldsText | ComponentCustomDataFieldsNumberRange | ComponentCustomDataFieldsPercentage | ComponentCustomDataFieldsMetricsSpecific | ComponentCustomDataFieldsNumberDropDown | ComponentCustomDataFieldsRating | ComponentCustomDataFieldsNumberField | ComponentCustomDataFieldsDateTimeField | ComponentCustomDataFieldsDataList | ComponentCustomDataFieldsEmailField | ComponentCustomDataFieldsPhoneNumberField | ComponentCustomDataFieldsImageField | Error;


export type TaskTypeEntity = {
  __typename?: 'TaskTypeEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<TaskType>;
};

export type TaskTypeEntityResponse = {
  __typename?: 'TaskTypeEntityResponse';
  data?: Maybe<TaskTypeEntity>;
};

export type TaskTypeEntityResponseCollection = {
  __typename?: 'TaskTypeEntityResponseCollection';
  data: Array<TaskTypeEntity>;
  meta: ResponseCollectionMeta;
};

export type TaskTypeFiltersInput = {
  id?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
  photoEvidence?: Maybe<StringFilterInput>;
  documentedEvidence?: Maybe<StringFilterInput>;
  environmentalImpact?: Maybe<StringFilterInput>;
  socialImpact?: Maybe<StringFilterInput>;
  onlyEmployeesVerify?: Maybe<BooleanFilterInput>;
  custom_data_fields?: Maybe<CustomDataLabelFiltersInput>;
  photoRequired?: Maybe<BooleanFilterInput>;
  creator?: Maybe<UsersPermissionsUserFiltersInput>;
  category?: Maybe<CategoryFiltersInput>;
  status?: Maybe<StringFilterInput>;
  un_sdg?: Maybe<UnSdgFiltersInput>;
  ER_ID?: Maybe<StringFilterInput>;
  withComment?: Maybe<BooleanFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<TaskTypeFiltersInput>>>;
  or?: Maybe<Array<Maybe<TaskTypeFiltersInput>>>;
  not?: Maybe<TaskTypeFiltersInput>;
};

export type TaskTypeInput = {
  title?: Maybe<Scalars['String']>;
  photoEvidence?: Maybe<Scalars['String']>;
  documentedEvidence?: Maybe<Scalars['String']>;
  environmentalImpact?: Maybe<Scalars['String']>;
  socialImpact?: Maybe<Scalars['String']>;
  onlyEmployeesVerify?: Maybe<Scalars['Boolean']>;
  custom_data_fields?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dataFields?: Maybe<Array<Scalars['TaskTypeDataFieldsDynamicZoneInput']>>;
  photoRequired?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['ID']>;
  status?: Maybe<Enum_Tasktype_Status>;
  un_sdg?: Maybe<Scalars['ID']>;
  ER_ID?: Maybe<Scalars['String']>;
  withComment?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TaskTypeRelationResponseCollection = {
  __typename?: 'TaskTypeRelationResponseCollection';
  data: Array<TaskTypeEntity>;
};

export type TasksWithCount = {
  __typename?: 'TasksWithCount';
  data?: Maybe<Array<Maybe<TaskEntity>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TermsAndCondition = {
  __typename?: 'TermsAndCondition';
  terms_pdf: UploadFileEntityResponse;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TermsAndConditionEntity = {
  __typename?: 'TermsAndConditionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<TermsAndCondition>;
};

export type TermsAndConditionEntityResponse = {
  __typename?: 'TermsAndConditionEntityResponse';
  data?: Maybe<TermsAndConditionEntity>;
};

export type TermsAndConditionInput = {
  terms_pdf?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type TopPerforming = {
  __typename?: 'TopPerforming';
  baoriders?: Maybe<Array<Maybe<TopPerformingBaorider>>>;
  communityMembers?: Maybe<Array<Maybe<TopPerformingCommunityMember>>>;
  chartData?: Maybe<TopPerformingChartData>;
};

export type TopPerformingBaorider = {
  __typename?: 'TopPerformingBaorider';
  baorider?: Maybe<UsersPermissionsUserEntity>;
  tasksCompleted: Scalars['Int'];
};

export type TopPerformingChartData = {
  __typename?: 'TopPerformingChartData';
  baoriders?: Maybe<Array<Maybe<ChartData>>>;
  communityMembers?: Maybe<Array<Maybe<ChartData>>>;
};

export type TopPerformingCommunityMember = {
  __typename?: 'TopPerformingCommunityMember';
  communityMember?: Maybe<UsersPermissionsUserEntity>;
  tasksCompleted: Scalars['Int'];
};

export type TrainingVideo = {
  __typename?: 'TrainingVideo';
  training_videos?: Maybe<Array<Maybe<ComponentTrainingVideoTrainingVideo>>>;
  FAQs?: Maybe<Array<Maybe<ComponentFaqFaq>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type TrainingVideoTraining_VideosArgs = {
  filters?: Maybe<ComponentTrainingVideoTrainingVideoFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TrainingVideoFaQsArgs = {
  filters?: Maybe<ComponentFaqFaqFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrainingVideoEntity = {
  __typename?: 'TrainingVideoEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<TrainingVideo>;
};

export type TrainingVideoEntityResponse = {
  __typename?: 'TrainingVideoEntityResponse';
  data?: Maybe<TrainingVideoEntity>;
};

export type TrainingVideoInput = {
  training_videos?: Maybe<Array<Maybe<ComponentTrainingVideoTrainingVideoInput>>>;
  FAQs?: Maybe<Array<Maybe<ComponentFaqFaqInput>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type UnSdg = {
  __typename?: 'UnSdg';
  name?: Maybe<Scalars['String']>;
  task_types?: Maybe<TaskTypeRelationResponseCollection>;
  icon?: Maybe<UploadFileEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type UnSdgTask_TypesArgs = {
  filters?: Maybe<TaskTypeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type UnSdgEntity = {
  __typename?: 'UnSdgEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UnSdg>;
};

export type UnSdgEntityResponse = {
  __typename?: 'UnSdgEntityResponse';
  data?: Maybe<UnSdgEntity>;
};

export type UnSdgEntityResponseCollection = {
  __typename?: 'UnSdgEntityResponseCollection';
  data: Array<UnSdgEntity>;
  meta: ResponseCollectionMeta;
};

export type UnSdgFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  task_types?: Maybe<TaskTypeFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UnSdgFiltersInput>>>;
  or?: Maybe<Array<Maybe<UnSdgFiltersInput>>>;
  not?: Maybe<UnSdgFiltersInput>;
};

export type UnSdgInput = {
  name?: Maybe<Scalars['String']>;
  task_types?: Maybe<Array<Maybe<Scalars['ID']>>>;
  icon?: Maybe<Scalars['ID']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateBaoriderInput = {
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Scalars['ID']>>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  community?: Maybe<Scalars['ID']>;
  idNumber?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Enum_Userspermissionsuser_Status>;
};

export type UpdateCommunityMembersInput = {
  phoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  community?: Maybe<Scalars['ID']>;
  idNumber?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Enum_Userspermissionsuser_Status>;
};


export type UploadFile = {
  __typename?: 'UploadFile';
  name: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  ext?: Maybe<Scalars['String']>;
  mime: Scalars['String'];
  size: Scalars['Float'];
  url: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFile>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  alternativeText?: Maybe<StringFilterInput>;
  caption?: Maybe<StringFilterInput>;
  width?: Maybe<IntFilterInput>;
  height?: Maybe<IntFilterInput>;
  formats?: Maybe<JsonFilterInput>;
  hash?: Maybe<StringFilterInput>;
  ext?: Maybe<StringFilterInput>;
  mime?: Maybe<StringFilterInput>;
  size?: Maybe<FloatFilterInput>;
  url?: Maybe<StringFilterInput>;
  previewUrl?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  provider_metadata?: Maybe<JsonFilterInput>;
  folder?: Maybe<UploadFolderFiltersInput>;
  folderPath?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  or?: Maybe<Array<Maybe<UploadFileFiltersInput>>>;
  not?: Maybe<UploadFileFiltersInput>;
};

export type UploadFileInput = {
  name?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  formats?: Maybe<Scalars['JSON']>;
  hash?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_metadata?: Maybe<Scalars['JSON']>;
  folder?: Maybe<Scalars['ID']>;
  folderPath?: Maybe<Scalars['String']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  name: Scalars['String'];
  pathId: Scalars['Int'];
  parent?: Maybe<UploadFolderEntityResponse>;
  children?: Maybe<UploadFolderRelationResponseCollection>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  path: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: Maybe<UploadFolderFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: Maybe<UploadFileFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UploadFolder>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  pathId?: Maybe<IntFilterInput>;
  parent?: Maybe<UploadFolderFiltersInput>;
  children?: Maybe<UploadFolderFiltersInput>;
  files?: Maybe<UploadFileFiltersInput>;
  path?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  or?: Maybe<Array<Maybe<UploadFolderFiltersInput>>>;
  not?: Maybe<UploadFolderFiltersInput>;
};

export type UploadFolderInput = {
  name?: Maybe<Scalars['String']>;
  pathId?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['ID']>;
  children?: Maybe<Array<Maybe<Scalars['ID']>>>;
  files?: Maybe<Array<Maybe<Scalars['ID']>>>;
  path?: Maybe<Scalars['String']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  id: Scalars['ID'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsMeRole>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsPermission>;
};

export type UsersPermissionsPermissionFiltersInput = {
  id?: Maybe<IdFilterInput>;
  action?: Maybe<StringFilterInput>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  or?: Maybe<Array<Maybe<UsersPermissionsPermissionFiltersInput>>>;
  not?: Maybe<UsersPermissionsPermissionFiltersInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: Maybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsRole>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  id?: Maybe<IdFilterInput>;
  name?: Maybe<StringFilterInput>;
  description?: Maybe<StringFilterInput>;
  type?: Maybe<StringFilterInput>;
  permissions?: Maybe<UsersPermissionsPermissionFiltersInput>;
  users?: Maybe<UsersPermissionsUserFiltersInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  or?: Maybe<Array<Maybe<UsersPermissionsRoleFiltersInput>>>;
  not?: Maybe<UsersPermissionsRoleFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  username: Scalars['String'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<UploadFileEntityResponse>;
  notifications?: Maybe<NotificationRelationResponseCollection>;
  onboardingInformation?: Maybe<Scalars['JSON']>;
  onboarding?: Maybe<Enum_Userspermissionsuser_Onboarding>;
  baoleader?: Maybe<UsersPermissionsUserEntityResponse>;
  baoriders?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Enum_Userspermissionsuser_Gender>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  idNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Userspermissionsuser_Status>;
  invitedViaSMS?: Maybe<Scalars['Boolean']>;
  receiveSmsNotifications?: Maybe<Scalars['Boolean']>;
  requests?: Maybe<RequestRelationResponseCollection>;
  request?: Maybe<RequestEntityResponse>;
  community?: Maybe<CommunityEntityResponse>;
  customerId?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyEntityResponse>;
  isVerifier?: Maybe<Scalars['Boolean']>;
  pushToken?: Maybe<Scalars['String']>;
  projects?: Maybe<ProjectRelationResponseCollection>;
  location_permission?: Maybe<Scalars['Boolean']>;
  camera_permission?: Maybe<Scalars['Boolean']>;
  media_library_permission?: Maybe<Scalars['Boolean']>;
  metabaseUserID?: Maybe<Scalars['String']>;
  receiveWeeklyStatusReport?: Maybe<Scalars['Boolean']>;
  lastWeeklyStatusReportSentDate?: Maybe<Scalars['Date']>;
  receiveAllTimeWeeklyStatusReport?: Maybe<Scalars['Boolean']>;
  receiveLoginReminder?: Maybe<Scalars['Boolean']>;
  lastLoggedIn?: Maybe<Scalars['Date']>;
  lastLoginReminderSent?: Maybe<Scalars['Date']>;
  receiveProjectNotifications?: Maybe<Scalars['Boolean']>;
  lastTaskReportedDate?: Maybe<Scalars['Date']>;
  lastInactivityNotificationSentDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UsersPermissionsUserNotificationsArgs = {
  filters?: Maybe<NotificationFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type UsersPermissionsUserBaoridersArgs = {
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UsersPermissionsUserRequestsArgs = {
  filters?: Maybe<RequestFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};


export type UsersPermissionsUserProjectsArgs = {
  filters?: Maybe<ProjectFiltersInput>;
  pagination?: Maybe<PaginationArg>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicationState?: Maybe<PublicationState>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<UsersPermissionsUser>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  id?: Maybe<IdFilterInput>;
  username?: Maybe<StringFilterInput>;
  email?: Maybe<StringFilterInput>;
  provider?: Maybe<StringFilterInput>;
  password?: Maybe<StringFilterInput>;
  resetPasswordToken?: Maybe<StringFilterInput>;
  otp?: Maybe<StringFilterInput>;
  confirmed?: Maybe<BooleanFilterInput>;
  blocked?: Maybe<BooleanFilterInput>;
  confirmationToken?: Maybe<StringFilterInput>;
  role?: Maybe<UsersPermissionsRoleFiltersInput>;
  phoneNumber?: Maybe<StringFilterInput>;
  notifications?: Maybe<NotificationFiltersInput>;
  onboardingInformation?: Maybe<JsonFilterInput>;
  onboarding?: Maybe<StringFilterInput>;
  baoleader?: Maybe<UsersPermissionsUserFiltersInput>;
  baoriders?: Maybe<UsersPermissionsUserFiltersInput>;
  firstName?: Maybe<StringFilterInput>;
  lastName?: Maybe<StringFilterInput>;
  gender?: Maybe<StringFilterInput>;
  dateOfBirth?: Maybe<DateFilterInput>;
  idNumber?: Maybe<StringFilterInput>;
  status?: Maybe<StringFilterInput>;
  invitedViaSMS?: Maybe<BooleanFilterInput>;
  receiveSmsNotifications?: Maybe<BooleanFilterInput>;
  requests?: Maybe<RequestFiltersInput>;
  request?: Maybe<RequestFiltersInput>;
  community?: Maybe<CommunityFiltersInput>;
  customerId?: Maybe<StringFilterInput>;
  company?: Maybe<CompanyFiltersInput>;
  isVerifier?: Maybe<BooleanFilterInput>;
  pushToken?: Maybe<StringFilterInput>;
  projects?: Maybe<ProjectFiltersInput>;
  location_permission?: Maybe<BooleanFilterInput>;
  camera_permission?: Maybe<BooleanFilterInput>;
  media_library_permission?: Maybe<BooleanFilterInput>;
  metabaseUserID?: Maybe<StringFilterInput>;
  receiveWeeklyStatusReport?: Maybe<BooleanFilterInput>;
  lastWeeklyStatusReportSentDate?: Maybe<DateFilterInput>;
  receiveAllTimeWeeklyStatusReport?: Maybe<BooleanFilterInput>;
  receiveLoginReminder?: Maybe<BooleanFilterInput>;
  lastLoggedIn?: Maybe<DateFilterInput>;
  lastLoginReminderSent?: Maybe<DateFilterInput>;
  receiveProjectNotifications?: Maybe<BooleanFilterInput>;
  lastTaskReportedDate?: Maybe<DateFilterInput>;
  lastInactivityNotificationSentDate?: Maybe<DateFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  or?: Maybe<Array<Maybe<UsersPermissionsUserFiltersInput>>>;
  not?: Maybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsUserInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  otp?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  confirmationToken?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['ID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['ID']>;
  notifications?: Maybe<Array<Maybe<Scalars['ID']>>>;
  onboardingInformation?: Maybe<Scalars['JSON']>;
  onboarding?: Maybe<Enum_Userspermissionsuser_Onboarding>;
  baoleader?: Maybe<Scalars['ID']>;
  baoriders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Enum_Userspermissionsuser_Gender>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  idNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Userspermissionsuser_Status>;
  invitedViaSMS?: Maybe<Scalars['Boolean']>;
  receiveSmsNotifications?: Maybe<Scalars['Boolean']>;
  requests?: Maybe<Array<Maybe<Scalars['ID']>>>;
  request?: Maybe<Scalars['ID']>;
  community?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['ID']>;
  isVerifier?: Maybe<Scalars['Boolean']>;
  pushToken?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  location_permission?: Maybe<Scalars['Boolean']>;
  camera_permission?: Maybe<Scalars['Boolean']>;
  media_library_permission?: Maybe<Scalars['Boolean']>;
  metabaseUserID?: Maybe<Scalars['String']>;
  receiveWeeklyStatusReport?: Maybe<Scalars['Boolean']>;
  lastWeeklyStatusReportSentDate?: Maybe<Scalars['Date']>;
  receiveAllTimeWeeklyStatusReport?: Maybe<Scalars['Boolean']>;
  receiveLoginReminder?: Maybe<Scalars['Boolean']>;
  lastLoggedIn?: Maybe<Scalars['Date']>;
  lastLoginReminderSent?: Maybe<Scalars['Date']>;
  receiveProjectNotifications?: Maybe<Scalars['Boolean']>;
  lastTaskReportedDate?: Maybe<Scalars['Date']>;
  lastInactivityNotificationSentDate?: Maybe<Scalars['Date']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type Video = {
  __typename?: 'Video';
  title: Scalars['String'];
  videoLink: UploadFileEntityResponse;
  order?: Maybe<Scalars['Int']>;
  category?: Maybe<Enum_Video_Category>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type VideoEntity = {
  __typename?: 'VideoEntity';
  id?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Video>;
};

export type VideoEntityResponse = {
  __typename?: 'VideoEntityResponse';
  data?: Maybe<VideoEntity>;
};

export type VideoEntityResponseCollection = {
  __typename?: 'VideoEntityResponseCollection';
  data: Array<VideoEntity>;
  meta: ResponseCollectionMeta;
};

export type VideoFiltersInput = {
  id?: Maybe<IdFilterInput>;
  title?: Maybe<StringFilterInput>;
  order?: Maybe<IntFilterInput>;
  category?: Maybe<StringFilterInput>;
  createdAt?: Maybe<DateTimeFilterInput>;
  updatedAt?: Maybe<DateTimeFilterInput>;
  publishedAt?: Maybe<DateTimeFilterInput>;
  and?: Maybe<Array<Maybe<VideoFiltersInput>>>;
  or?: Maybe<Array<Maybe<VideoFiltersInput>>>;
  not?: Maybe<VideoFiltersInput>;
};

export type VideoInput = {
  title?: Maybe<Scalars['String']>;
  videoLink?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  category?: Maybe<Enum_Video_Category>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateMyUserInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['ID']>;
  pushToken?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  receiveWeeklyStatusReport?: Maybe<Scalars['Boolean']>;
  receiveAllTimeWeeklyStatusReport?: Maybe<Scalars['Boolean']>;
  receiveLoginReminder?: Maybe<Scalars['Boolean']>;
  receiveProjectNotifications?: Maybe<Scalars['Boolean']>;
  profilePicture?: Maybe<Scalars['ID']>;
  notifications?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tasks?: Maybe<Array<Maybe<Scalars['ID']>>>;
  community?: Maybe<Scalars['ID']>;
  requests?: Maybe<Array<Maybe<Scalars['ID']>>>;
  onboardingInformation?: Maybe<Scalars['JSON']>;
  onboarding?: Maybe<Enum_Userspermissionsuser_Onboarding>;
  receiveSmsNotifications?: Maybe<Scalars['Boolean']>;
  baoriders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  firstName?: Maybe<Scalars['String']>;
  location_permission?: Maybe<Scalars['Boolean']>;
  camera_permission?: Maybe<Scalars['Boolean']>;
  media_library_permission?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
};

export type UnsdgFragment = (
  { __typename?: 'UnSdg' }
  & Pick<UnSdg, 'name'>
  & { icon?: Maybe<(
    { __typename?: 'UploadFileEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UploadFileEntity' }
      & UploadFileFragment
    )> }
  )> }
);

export type Analytic_FragmentFragment = (
  { __typename?: 'Analytic' }
  & Pick<Analytic, 'default_dashboard'>
);

export type CategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'title' | 'description'>
);

export type CommunityFragment = (
  { __typename?: 'Community' }
  & Pick<Community, 'createdAt' | 'updatedAt' | 'name' | 'type' | 'description' | 'chief_name' | 'location'>
);

export type ComponentCustomDataFieldsCheckboxFragment = (
  { __typename?: 'ComponentCustomDataFieldsCheckbox' }
  & Pick<ComponentCustomDataFieldsCheckbox, 'id' | 'isRequired' | 'label'>
  & { option?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentComboFieldOption' }
    & Pick<ComponentComboFieldOption, 'id' | 'option'>
  )>>> }
);

export type ComponentCustomDataFieldsDataListFragment = (
  { __typename?: 'ComponentCustomDataFieldsDataList' }
  & Pick<ComponentCustomDataFieldsDataList, 'id' | 'label' | 'isRequired' | 'listId'>
  & { option?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentComboFieldOption' }
    & Pick<ComponentComboFieldOption, 'id' | 'option'>
  )>>> }
);

export type ComponentCustomDataFieldsDateTimeFieldFragment = (
  { __typename?: 'ComponentCustomDataFieldsDateTimeField' }
  & Pick<ComponentCustomDataFieldsDateTimeField, 'id' | 'label' | 'isRequired' | 'datetime'>
);

export type ComponentCustomDataFieldsEmailFieldFragment = (
  { __typename?: 'ComponentCustomDataFieldsEmailField' }
  & Pick<ComponentCustomDataFieldsEmailField, 'id' | 'label' | 'isRequired' | 'email'>
);

export type ComponentCustomDataFieldsImageFieldFragment = (
  { __typename?: 'ComponentCustomDataFieldsImageField' }
  & Pick<ComponentCustomDataFieldsImageField, 'id' | 'isRequired' | 'label' | 'upload_state'>
  & { images?: Maybe<(
    { __typename?: 'UploadFileRelationResponseCollection' }
    & { data: Array<(
      { __typename?: 'UploadFileEntity' }
      & UploadFileFragment
    )> }
  )> }
);

export type ComponentCustomDataFieldsMetricsSpecificFragment = (
  { __typename?: 'ComponentCustomDataFieldsMetricsSpecific' }
  & Pick<ComponentCustomDataFieldsMetricsSpecific, 'id' | 'label' | 'isRequired' | 'metric' | 'number_value'>
);

export type ComponentCustomDataFieldsNumberDropDownFragment = (
  { __typename?: 'ComponentCustomDataFieldsNumberDropDown' }
  & Pick<ComponentCustomDataFieldsNumberDropDown, 'id' | 'label' | 'value' | 'isRequired' | 'max' | 'min'>
);

export type ComponentCustomDataFieldsNumberFieldFragment = (
  { __typename?: 'ComponentCustomDataFieldsNumberField' }
  & Pick<ComponentCustomDataFieldsNumberField, 'id' | 'label' | 'isRequired' | 'number_field_value'>
);

export type ComponentCustomDataFieldsNumberRangeFragment = (
  { __typename?: 'ComponentCustomDataFieldsNumberRange' }
  & Pick<ComponentCustomDataFieldsNumberRange, 'id' | 'label' | 'min' | 'isRequired' | 'max'>
);

export type ComponentCustomDataFieldsPercentageFragment = (
  { __typename?: 'ComponentCustomDataFieldsPercentage' }
  & Pick<ComponentCustomDataFieldsPercentage, 'id' | 'label' | 'isRequired' | 'value'>
);

export type ComponentCustomDataFieldsPhoneNumberFieldFragment = (
  { __typename?: 'ComponentCustomDataFieldsPhoneNumberField' }
  & Pick<ComponentCustomDataFieldsPhoneNumberField, 'id' | 'label' | 'isRequired' | 'phoneNumber'>
);

export type ComponentCustomDataFieldsRadioFragment = (
  { __typename?: 'ComponentCustomDataFieldsRadio' }
  & Pick<ComponentCustomDataFieldsRadio, 'id' | 'label' | 'isRequired'>
  & { option?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentComboFieldOption' }
    & Pick<ComponentComboFieldOption, 'id' | 'option'>
  )>>> }
);

export type ComponentCustomDataFieldsRatingFragment = (
  { __typename?: 'ComponentCustomDataFieldsRating' }
  & Pick<ComponentCustomDataFieldsRating, 'id' | 'label' | 'rating' | 'isRequired' | 'number_of_stars'>
);

export type ComponentCustomDataFieldsSelectFragment = (
  { __typename?: 'ComponentCustomDataFieldsSelect' }
  & Pick<ComponentCustomDataFieldsSelect, 'id' | 'isRequired' | 'label'>
  & { option?: Maybe<Array<Maybe<(
    { __typename: 'ComponentComboFieldOption' }
    & Pick<ComponentComboFieldOption, 'id' | 'option'>
  )>>> }
);

export type ComponentCustomDataFieldsTextFragment = (
  { __typename?: 'ComponentCustomDataFieldsText' }
  & Pick<ComponentCustomDataFieldsText, 'id' | 'label' | 'isRequired' | 'text_value'>
);

export type DataListOptionFragment = (
  { __typename?: 'DataListOption' }
  & Pick<DataListOption, 'createdAt' | 'status' | 'updatedAt' | 'value' | 'publishedAt'>
);

export type DataListsFragment = (
  { __typename?: 'DataList' }
  & Pick<DataList, 'name' | 'createdAt' | 'updatedAt' | 'status' | 'publishedAt'>
);

export type EvidenceFragment = (
  { __typename?: 'Evidence' }
  & Pick<Evidence, 'notes' | 'location' | 'createdAt' | 'updatedAt' | 'upload_state' | 'cancellationReason' | 'requestedInfo'>
);

export type FaqFragment = (
  { __typename: 'ComponentFaqFaq' }
  & Pick<ComponentFaqFaq, 'id' | 'question' | 'answer' | 'category'>
  & { tags?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentComboFieldOption' }
    & Pick<ComponentComboFieldOption, 'id' | 'option'>
  )>>> }
);

export type MemberFragment = (
  { __typename?: 'UsersPermissionsUser' }
  & Pick<UsersPermissionsUser, 'firstName' | 'lastName' | 'status' | 'phoneNumber' | 'createdAt' | 'updatedAt' | 'gender' | 'idNumber' | 'blocked' | 'dateOfBirth' | 'location_permission' | 'camera_permission' | 'media_library_permission' | 'isVerifier' | 'email'>
  & { baoleader?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
    )> }
  )>, profilePicture?: Maybe<(
    { __typename?: 'UploadFileEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UploadFileEntity' }
      & UploadFileFragment
    )> }
  )>, role?: Maybe<(
    { __typename?: 'UsersPermissionsRoleEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'UsersPermissionsRoleEntity' }
      & Pick<UsersPermissionsRoleEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsRole' }
        & Pick<UsersPermissionsRole, 'name' | 'type'>
      )> }
    )> }
  )>, community?: Maybe<(
    { __typename?: 'CommunityEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'CommunityEntity' }
      & Pick<CommunityEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Community' }
        & { featured_image?: Maybe<(
          { __typename?: 'UploadFileEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UploadFileEntity' }
            & UploadFileFragment
          )> }
        )> }
        & CommunityFragment
      )> }
    )> }
  )> }
);

export type ProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'breakdown' | 'name' | 'createdAt' | 'updatedAt' | 'collaborators' | 'reward' | 'status' | 'statement' | 'description' | 'outcomes' | 'budget'>
);

export type TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'updatedAt' | 'createdAt' | 'date_reported' | 'status' | 'location' | 'onlyEmployeesVerify' | 'timezone'>
);

export type TaskTypeFragment = (
  { __typename?: 'TaskType' }
  & Pick<TaskType, 'title' | 'ER_ID' | 'photoEvidence' | 'documentedEvidence' | 'environmentalImpact' | 'socialImpact' | 'status' | 'createdAt' | 'updatedAt' | 'withComment' | 'photoRequired'>
);

export type Training_VideoFragment = (
  { __typename: 'ComponentTrainingVideoTrainingVideo' }
  & Pick<ComponentTrainingVideoTrainingVideo, 'id' | 'title' | 'url' | 'category' | 'description'>
  & { tags?: Maybe<Array<Maybe<(
    { __typename?: 'ComponentComboFieldOption' }
    & Pick<ComponentComboFieldOption, 'id' | 'option'>
  )>>> }
);

export type UploadFileFragment = (
  { __typename?: 'UploadFileEntity' }
  & Pick<UploadFileEntity, 'id'>
  & { attributes?: Maybe<(
    { __typename?: 'UploadFile' }
    & Pick<UploadFile, 'createdAt' | 'updatedAt' | 'name' | 'alternativeText' | 'caption' | 'size' | 'mime' | 'url' | 'provider' | 'width' | 'height' | 'formats' | 'hash' | 'ext'>
  )> }
);

export type AcceptCommunityMemberRequestMutationVariables = Exact<{
  requestId: Scalars['ID'];
}>;


export type AcceptCommunityMemberRequestMutation = (
  { __typename?: 'Mutation' }
  & { acceptCommunityMemberRequest: (
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'firstName' | 'lastName'>
    )> }
  ) }
);

export type ActivateTaskTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateTaskTypeMutation = (
  { __typename?: 'Mutation' }
  & { activateTaskType: (
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
  ) }
);

export type AddDataCollectorToProjectMutationVariables = Exact<{
  input: AddDataCollectorToProjectInput;
}>;


export type AddDataCollectorToProjectMutation = (
  { __typename?: 'Mutation' }
  & { addDataCollectorToProject?: Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
  )> }
);

export type CompleteOnboardingStepMutationVariables = Exact<{
  index: Scalars['String'];
  completeOnboarding?: Maybe<Scalars['Boolean']>;
}>;


export type CompleteOnboardingStepMutation = (
  { __typename?: 'Mutation' }
  & { completeOnboardingStep?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'username'>
    )> }
  )> }
);

export type CreateBaoleaderMutationVariables = Exact<{
  input: CreateBaoleaderInput;
}>;


export type CreateBaoleaderMutation = (
  { __typename?: 'Mutation' }
  & { createBaoleader: (
    { __typename?: 'BaoleaderPayload' }
    & { baoleader?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'username' | 'email'>
      )> }
    )> }
  ) }
);

export type CreateBaoriderMutationVariables = Exact<{
  input: CreateBaoriderInput;
  sendInvite?: Maybe<Scalars['Boolean']>;
}>;


export type CreateBaoriderMutation = (
  { __typename?: 'Mutation' }
  & { createBaorider: (
    { __typename?: 'BaoriderPayload' }
    & { baorider?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'username' | 'email'>
      )> }
    )> }
  ) }
);

export type CreateBaoridersMutationVariables = Exact<{
  input: Array<Maybe<CreateBaoriderInput>> | Maybe<CreateBaoriderInput>;
}>;


export type CreateBaoridersMutation = (
  { __typename?: 'Mutation' }
  & { createBaoriders: (
    { __typename?: 'MultipleBaoriderPayload' }
    & { baoriders?: Maybe<Array<Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'email' | 'username'>
      )> }
    )>>> }
  ) }
);

export type CreateCommunityMutationVariables = Exact<{
  input?: Maybe<CommunityInput>;
}>;


export type CreateCommunityMutation = (
  { __typename?: 'Mutation' }
  & { createBaoleaderCommunity?: Maybe<(
    { __typename?: 'CommunityEntity' }
    & Pick<CommunityEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'name' | 'chief_name' | 'description'>
    )> }
  )> }
);

export type CreateCommunityMemberMutationVariables = Exact<{
  input?: Maybe<CreateCommunityMembersInput>;
  sendInvite?: Maybe<Scalars['Boolean']>;
}>;


export type CreateCommunityMemberMutation = (
  { __typename?: 'Mutation' }
  & { createCommunityMember: (
    { __typename?: 'CommunityMembersPayload' }
    & { member?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'username'>
      )> }
    )> }
  ) }
);

export type CreateCommunityMembersMutationVariables = Exact<{
  input?: Maybe<Array<Maybe<CreateCommunityMembersInput>> | Maybe<CreateCommunityMembersInput>>;
}>;


export type CreateCommunityMembersMutation = (
  { __typename?: 'Mutation' }
  & { createCommunityMembers: Array<Maybe<(
    { __typename?: 'CommunityMembersPayload' }
    & { member?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'username'>
      )> }
    )> }
  )>> }
);

export type CreateDataListMutationVariables = Exact<{
  input: DataListInput;
}>;


export type CreateDataListMutation = (
  { __typename?: 'Mutation' }
  & { createDataList?: Maybe<(
    { __typename?: 'DataListEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'DataListEntity' }
      & Pick<DataListEntity, 'id'>
    )> }
  )> }
);

export type CreateDataListOptionMutationVariables = Exact<{
  input: DataListOptionInput;
}>;


export type CreateDataListOptionMutation = (
  { __typename?: 'Mutation' }
  & { createDataListOption?: Maybe<(
    { __typename?: 'DataListOptionEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'DataListOptionEntity' }
      & Pick<DataListOptionEntity, 'id'>
    )> }
  )> }
);

export type CreateProjectMutationVariables = Exact<{
  input?: Maybe<ProjectInput>;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createBaoleaderProject?: Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'name'>
    )> }
  )> }
);

export type CreateTaskMutationVariables = Exact<{
  input?: Maybe<TaskInput>;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createBaoleaderTask: (
    { __typename?: 'TaskEntity' }
    & Pick<TaskEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'createdAt'>
    )> }
  ) }
);

export type DeactivateTaskTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateTaskTypeMutation = (
  { __typename?: 'Mutation' }
  & { deactivateTaskType: (
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
  ) }
);

export type DeleteBaoriderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBaoriderMutation = (
  { __typename?: 'Mutation' }
  & { removeBaorider: (
    { __typename?: 'BaoriderPayload' }
    & { baorider?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'username'>
      )> }
    )> }
  ) }
);

export type DeleteCommunityMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommunityMemberMutation = (
  { __typename?: 'Mutation' }
  & { removeCommunityMember: (
    { __typename?: 'CommunityMembersPayload' }
    & { member?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'username'>
      )> }
    )> }
  ) }
);

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteBaoleaderTask?: Maybe<(
    { __typename?: 'TaskEntity' }
    & Pick<TaskEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Task' }
      & { baorider?: Maybe<(
        { __typename?: 'UsersPermissionsUserEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UsersPermissionsUserEntity' }
          & Pick<UsersPermissionsUserEntity, 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type DeleteTasktypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTasktypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskType?: Maybe<(
    { __typename?: 'TaskTypeEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'TaskTypeEntity' }
      & Pick<TaskTypeEntity, 'id'>
    )> }
  )> }
);

export type ExportExcelMutationVariables = Exact<{
  filterValues?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type ExportExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportExcel'>
);

export type RejectCommunityMemberRequestMutationVariables = Exact<{
  requestId: Scalars['ID'];
}>;


export type RejectCommunityMemberRequestMutation = (
  { __typename?: 'Mutation' }
  & { rejectCommunityMemberRequest: (
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'firstName' | 'lastName'>
    )> }
  ) }
);

export type RemoveCompanyMemberMutationVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type RemoveCompanyMemberMutation = (
  { __typename?: 'Mutation' }
  & { removeCompanyMember?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
  )> }
);

export type RequestUpdateCompanyDetailsMutationVariables = Exact<{
  data: RequestCompanyDetailsChangeInput;
}>;


export type RequestUpdateCompanyDetailsMutation = (
  { __typename?: 'Mutation' }
  & { requestUpdateCompanyDetails?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )> }
);

export type ResendDataCollectorInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResendDataCollectorInviteMutation = (
  { __typename?: 'Mutation' }
  & { resendDataCollectorInvite?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
  )> }
);

export type SkipOnboardingStepMutationVariables = Exact<{
  index: Scalars['String'];
  completeOnboarding?: Maybe<Scalars['Boolean']>;
}>;


export type SkipOnboardingStepMutation = (
  { __typename?: 'Mutation' }
  & { skipOnboardingStep?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'username'>
    )> }
  )> }
);

export type CreateTaskDefinitionMutationVariables = Exact<{
  input?: Maybe<TaskDefinitionInput>;
}>;


export type CreateTaskDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { createBaoleaderTaskDefinition: (
    { __typename?: 'TaskDefinitionEntity' }
    & Pick<TaskDefinitionEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskDefinition' }
      & { category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
        )> }
      )>, task_type?: Maybe<(
        { __typename?: 'TaskTypeEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'TaskTypeEntity' }
          & Pick<TaskTypeEntity, 'id'>
        )> }
      )> }
    )> }
  ) }
);

export type UpdateBaoleaderTaskDefinitionMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<TaskDefinitionInput>;
}>;


export type UpdateBaoleaderTaskDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { updateBaoleaderTaskDefinition: (
    { __typename?: 'TaskDefinitionEntity' }
    & Pick<TaskDefinitionEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskDefinition' }
      & { category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
        )> }
      )>, task_type?: Maybe<(
        { __typename?: 'TaskTypeEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'TaskTypeEntity' }
          & Pick<TaskTypeEntity, 'id'>
        )> }
      )> }
    )> }
  ) }
);

export type DeleteBaoleaderTaskDefinitionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBaoleaderTaskDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { deleteBaoleaderTaskDefinition?: Maybe<(
    { __typename?: 'TaskDefinitionEntity' }
    & Pick<TaskDefinitionEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskDefinition' }
      & { category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
        )> }
      )>, task_type?: Maybe<(
        { __typename?: 'TaskTypeEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'TaskTypeEntity' }
          & Pick<TaskTypeEntity, 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type CreateTaskTypeMutationVariables = Exact<{
  input: TaskTypeInput;
  dataFieldsArr?: Maybe<Scalars['JSON']>;
}>;


export type CreateTaskTypeMutation = (
  { __typename?: 'Mutation' }
  & { createBaoleaderTaskType: (
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskType' }
      & { category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
        )> }
      )> }
      & TaskTypeFragment
    )> }
  ) }
);

export type UnarchiveCompanyMemberMutationVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type UnarchiveCompanyMemberMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveCompanyMember?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
  )> }
);

export type UpdateBaoriderMutationVariables = Exact<{
  id: Scalars['ID'];
  baorider?: Maybe<UpdateBaoriderInput>;
}>;


export type UpdateBaoriderMutation = (
  { __typename?: 'Mutation' }
  & { updateBaorider: (
    { __typename?: 'BaoriderPayload' }
    & { baorider?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
    )> }
  ) }
);

export type UpdateCommunityMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<CommunityInput>;
}>;


export type UpdateCommunityMutation = (
  { __typename?: 'Mutation' }
  & { updateBaoleaderCommunity?: Maybe<(
    { __typename?: 'CommunityEntity' }
    & Pick<CommunityEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'name'>
    )> }
  )> }
);

export type UpdateCommunityMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<UpdateCommunityMembersInput>;
}>;


export type UpdateCommunityMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateCommunityMember: (
    { __typename?: 'CommunityMembersPayload' }
    & { member?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'username'>
      )> }
    )> }
  ) }
);

export type UpdateDataListMutationVariables = Exact<{
  input: DataListInput;
  id: Scalars['ID'];
}>;


export type UpdateDataListMutation = (
  { __typename?: 'Mutation' }
  & { updateDataList?: Maybe<(
    { __typename?: 'DataListEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'DataListEntity' }
      & Pick<DataListEntity, 'id'>
    )> }
  )> }
);

export type UpdateDataListOptionMutationVariables = Exact<{
  input: DataListOptionInput;
  id: Scalars['ID'];
}>;


export type UpdateDataListOptionMutation = (
  { __typename?: 'Mutation' }
  & { updateDataListOption?: Maybe<(
    { __typename?: 'DataListOptionEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'DataListOptionEntity' }
      & Pick<DataListOptionEntity, 'id'>
    )> }
  )> }
);

export type UpdateEvidenceMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<EvidenceInput>;
}>;


export type UpdateEvidenceMutation = (
  { __typename?: 'Mutation' }
  & { updateBaoleaderEvidence: (
    { __typename?: 'Evidence' }
    & Pick<Evidence, 'notes'>
  ) }
);

export type UpdateMyUserMutationVariables = Exact<{
  input?: Maybe<UpdateMyUserInput>;
}>;


export type UpdateMyUserMutation = (
  { __typename?: 'Mutation' }
  & { updateMyUser?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
  )> }
);

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: Maybe<ProjectInput>;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateBaoleaderProject?: Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'name'>
    )> }
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TaskInput;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateBaoleaderTask: (
    { __typename?: 'TaskEntity' }
    & Pick<TaskEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'createdAt'>
    )> }
  ) }
);

export type UpdateTaskTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: TaskTypeInput;
  dataFieldsArr?: Maybe<Scalars['JSON']>;
}>;


export type UpdateTaskTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateBaoleaderTaskType: (
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskType' }
      & Pick<TaskType, 'title'>
    )> }
  ) }
);

export type UpdateVideoMutationVariables = Exact<{
  input: VideoInput;
  id: Scalars['ID'];
}>;


export type UpdateVideoMutation = (
  { __typename?: 'Mutation' }
  & { updateVideo?: Maybe<(
    { __typename?: 'VideoEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'VideoEntity' }
      & Pick<VideoEntity, 'id'>
    )> }
  )> }
);

export type AnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsQuery = (
  { __typename?: 'Query' }
  & { analytic?: Maybe<(
    { __typename?: 'AnalyticEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'AnalyticEntity' }
      & Pick<AnalyticEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Analytic' }
        & Analytic_FragmentFragment
      )> }
    )> }
  )> }
);

export type BaoverifierAppQueryVariables = Exact<{ [key: string]: never; }>;


export type BaoverifierAppQuery = (
  { __typename?: 'Query' }
  & { baoverifierApp?: Maybe<(
    { __typename?: 'BaoverifierAppEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'BaoverifierAppEntity' }
      & Pick<BaoverifierAppEntity, 'id'>
    )> }
  )> }
);

export type CountCommunityMembersQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type CountCommunityMembersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countCommunityMembers'>
);

export type CountCompanyCommunitiesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type CountCompanyCommunitiesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countCompanyCommunities'>
);

export type CountCompanyMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type CountCompanyMembersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countCompanyMembers'>
);

export type CountCompanyProjectsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type CountCompanyProjectsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countCompanyProjects'>
);

export type CountRidersQueryVariables = Exact<{
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  where?: Maybe<Scalars['JSON']>;
}>;


export type CountRidersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countRiders'>
);

export type CountTaskTypesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type CountTaskTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyTaskTypesCount'>
);

export type CountTasksQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type CountTasksQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countTasks'>
);

export type FindDataListOptionWithCountQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type FindDataListOptionWithCountQuery = (
  { __typename?: 'Query' }
  & { findDataListOptionWithCount?: Maybe<(
    { __typename?: 'DataListOptionWithCount' }
    & Pick<DataListOptionWithCount, 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'DataListOptionEntity' }
      & Pick<DataListOptionEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'DataListOption' }
        & { data_list?: Maybe<(
          { __typename?: 'DataListEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'DataListEntity' }
            & Pick<DataListEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'DataList' }
              & DataListsFragment
            )> }
          )> }
        )> }
        & DataListOptionFragment
      )> }
    )>>> }
  )> }
);

export type GetAllInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllInvoicesQuery = (
  { __typename?: 'Query' }
  & { getAllInvoices?: Maybe<Array<Maybe<(
    { __typename?: 'InvoicePayload' }
    & Pick<InvoicePayload, 'id' | 'currency' | 'customer' | 'created' | 'ending_balance' | 'hosted_invoice_url' | 'invoice_pdf' | 'paid' | 'status' | 'subscription' | 'lines' | 'number'>
  )>>> }
);

export type GetAllProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductsQuery = (
  { __typename?: 'Query' }
  & { getAllProducts?: Maybe<Array<Maybe<(
    { __typename?: 'Products' }
    & Pick<Products, 'id' | 'name' | 'statement_descriptor'>
    & { metadata?: Maybe<(
      { __typename?: 'ProductMetaData' }
      & Pick<ProductMetaData, 'baoriders_allowed' | 'baoriders_cost'>
    )>, prices?: Maybe<Array<Maybe<(
      { __typename?: 'Prices' }
      & Pick<Prices, 'id' | 'nickname' | 'currency' | 'unit_amount' | 'unit_amount_decimal'>
      & { recurring?: Maybe<(
        { __typename?: 'Recurring' }
        & Pick<Recurring, 'interval' | 'interval_count'>
      )> }
    )>>> }
  )>>> }
);

export type GetBaoleaderProjectsCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBaoleaderProjectsCategoriesQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderProjectsCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategorySet' }
    & Pick<CategorySet, 'id' | 'title'>
  )>>> }
);

export type GetBaoleaderTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBaoleaderTaskQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderTask?: Maybe<(
    { __typename?: 'TaskEntity' }
    & Pick<TaskEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'createdAt' | 'updatedAt' | 'status' | 'date_reported' | 'location' | 'onlyEmployeesVerify' | 'task_origin'>
      & { documents?: Maybe<(
        { __typename?: 'UploadFileRelationResponseCollection' }
        & { data: Array<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )>, task_definition?: Maybe<(
        { __typename?: 'TaskDefinitionEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'TaskDefinitionEntity' }
          & Pick<TaskDefinitionEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'TaskDefinition' }
            & { category?: Maybe<(
              { __typename?: 'CategoryEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'CategoryEntity' }
                & Pick<CategoryEntity, 'id'>
                & { attributes?: Maybe<(
                  { __typename?: 'Category' }
                  & { featureImage?: Maybe<(
                    { __typename?: 'UploadFileEntityResponse' }
                    & { data?: Maybe<(
                      { __typename?: 'UploadFileEntity' }
                      & UploadFileFragment
                    )> }
                  )> }
                  & CategoryFragment
                )> }
              )> }
            )>, task_type?: Maybe<(
              { __typename?: 'TaskTypeEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'TaskTypeEntity' }
                & Pick<TaskTypeEntity, 'id'>
                & { attributes?: Maybe<(
                  { __typename?: 'TaskType' }
                  & TaskTypeFragment
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, community?: Maybe<(
        { __typename?: 'CommunityEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CommunityEntity' }
          & Pick<CommunityEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Community' }
            & { featured_image?: Maybe<(
              { __typename?: 'UploadFileEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'UploadFileEntity' }
                & UploadFileFragment
              )> }
            )> }
            & CommunityFragment
          )> }
        )> }
      )>, project?: Maybe<(
        { __typename?: 'ProjectEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'ProjectEntity' }
          & Pick<ProjectEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Project' }
            & { featured_image?: Maybe<(
              { __typename?: 'UploadFileEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'UploadFileEntity' }
                & UploadFileFragment
              )> }
            )> }
            & ProjectFragment
          )> }
        )> }
      )>, evidences?: Maybe<(
        { __typename?: 'EvidenceRelationResponseCollection' }
        & { data: Array<(
          { __typename?: 'EvidenceEntity' }
          & Pick<EvidenceEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Evidence' }
            & { custom_data_fields?: Maybe<Array<Maybe<(
              { __typename: 'ComponentCustomDataFieldsRadio' }
              & ComponentCustomDataFieldsRadioFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsText' }
              & ComponentCustomDataFieldsTextFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsCheckbox' }
              & ComponentCustomDataFieldsCheckboxFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsSelect' }
              & ComponentCustomDataFieldsSelectFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsNumberRange' }
              & ComponentCustomDataFieldsNumberRangeFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsPercentage' }
              & ComponentCustomDataFieldsPercentageFragment
              & ComponentCustomDataFieldsPercentageFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsMetricsSpecific' }
              & ComponentCustomDataFieldsMetricsSpecificFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsNumberDropDown' }
              & ComponentCustomDataFieldsNumberDropDownFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsRating' }
              & ComponentCustomDataFieldsRatingFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsNumberField' }
              & ComponentCustomDataFieldsNumberFieldFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsDateTimeField' }
              & ComponentCustomDataFieldsDateTimeFieldFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsDataList' }
              & ComponentCustomDataFieldsDataListFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsEmailField' }
              & ComponentCustomDataFieldsEmailFieldFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsPhoneNumberField' }
              & ComponentCustomDataFieldsPhoneNumberFieldFragment
            ) | (
              { __typename: 'ComponentCustomDataFieldsImageField' }
              & ComponentCustomDataFieldsImageFieldFragment
            ) | { __typename: 'Error' }>>>, photos?: Maybe<(
              { __typename?: 'UploadFileRelationResponseCollection' }
              & { data: Array<(
                { __typename?: 'UploadFileEntity' }
                & UploadFileFragment
              )> }
            )>, data_field_values?: Maybe<(
              { __typename?: 'TaskDataLabelValueRelationResponseCollection' }
              & { data: Array<(
                { __typename?: 'TaskDataLabelValueEntity' }
                & Pick<TaskDataLabelValueEntity, 'id'>
                & { attributes?: Maybe<(
                  { __typename?: 'TaskDataLabelValue' }
                  & Pick<TaskDataLabelValue, 'value'>
                  & { data_field?: Maybe<(
                    { __typename?: 'CustomDataLabelEntityResponse' }
                    & { data?: Maybe<(
                      { __typename?: 'CustomDataLabelEntity' }
                      & Pick<CustomDataLabelEntity, 'id'>
                      & { attributes?: Maybe<(
                        { __typename?: 'CustomDataLabel' }
                        & Pick<CustomDataLabel, 'label'>
                      )> }
                    )> }
                  )>, baorider?: Maybe<(
                    { __typename?: 'UsersPermissionsUserEntityResponse' }
                    & { data?: Maybe<(
                      { __typename?: 'UsersPermissionsUserEntity' }
                      & Pick<UsersPermissionsUserEntity, 'id'>
                    )> }
                  )> }
                )> }
              )> }
            )>, baorider?: Maybe<(
              { __typename?: 'UsersPermissionsUserEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'UsersPermissionsUserEntity' }
                & Pick<UsersPermissionsUserEntity, 'id'>
                & { attributes?: Maybe<(
                  { __typename?: 'UsersPermissionsUser' }
                  & MemberFragment
                )> }
              )> }
            )> }
            & EvidenceFragment
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetBaoleaderTaskDefinitionsCountQueryVariables = Exact<{
  filters?: Maybe<Scalars['JSON']>;
}>;


export type GetBaoleaderTaskDefinitionsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBaoleaderTaskDefinitionsCount'>
);

export type GetBaoridersQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetBaoridersQuery = (
  { __typename?: 'Query' }
  & { getBaoriders?: Maybe<Array<Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'idNumber' | 'phoneNumber' | 'firstName' | 'isVerifier' | 'lastName' | 'status' | 'createdAt' | 'updatedAt'>
      & { profilePicture?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )>, projects?: Maybe<(
        { __typename?: 'ProjectRelationResponseCollection' }
        & { data: Array<(
          { __typename?: 'ProjectEntity' }
          & Pick<ProjectEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Project' }
            & ProjectFragment
          )> }
        )> }
      )>, community?: Maybe<(
        { __typename?: 'CommunityEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CommunityEntity' }
          & Pick<CommunityEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Community' }
            & CommunityFragment
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type GetCategoriesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<CategoryFiltersInput>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<(
    { __typename?: 'CategoryEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'title' | 'description'>
        & { featureImage?: Maybe<(
          { __typename?: 'UploadFileEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UploadFileEntity' }
            & UploadFileFragment
          )> }
        )>, task_types?: Maybe<(
          { __typename?: 'TaskTypeRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'TaskTypeEntity' }
            & Pick<TaskTypeEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'TaskType' }
              & TaskTypeFragment
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetCommunityVerifiersQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type GetCommunityVerifiersQuery = (
  { __typename?: 'Query' }
  & { getCommunityVerifiers?: Maybe<(
    { __typename?: 'MultipleCommunityMembersPayload' }
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MemberFragment
      )> }
    )>>> }
  )> }
);

export type GetCommunitiesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetCommunitiesQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderCommunities?: Maybe<Array<Maybe<(
    { __typename?: 'CommunityEntity' }
    & Pick<CommunityEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Community' }
      & { featured_image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )> }
      & CommunityFragment
    )> }
  )>>> }
);

export type GetCommunityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommunityQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderCommunity?: Maybe<(
    { __typename?: 'CommunityEntity' }
    & Pick<CommunityEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'name' | 'chief_name' | 'description' | 'location' | 'updatedAt' | 'createdAt'>
      & { featured_image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )>, baoleader?: Maybe<(
        { __typename?: 'UsersPermissionsUserEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UsersPermissionsUserEntity' }
          & Pick<UsersPermissionsUserEntity, 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type GetCommunityBaoridersQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;


export type GetCommunityBaoridersQuery = (
  { __typename?: 'Query' }
  & { getCommunityBaoriders?: Maybe<(
    { __typename?: 'MultipleCommunityMembersPayload' }
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MemberFragment
      )> }
    )>>> }
  )> }
);

export type GetCommunityMembersQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetCommunityMembersQuery = (
  { __typename?: 'Query' }
  & { getCommunityMembers?: Maybe<(
    { __typename?: 'MultipleCommunityMembersPayload' }
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & MemberFragment
      )> }
    )>>> }
  )> }
);

export type GetCommunityProjectsQueryVariables = Exact<{
  community: Scalars['ID'];
}>;


export type GetCommunityProjectsQuery = (
  { __typename?: 'Query' }
  & { getCommunityProjects?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Project' }
      & { featured_image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )> }
      & ProjectFragment
    )> }
  )>>> }
);

export type GetCommunityProjectsCountQueryVariables = Exact<{
  community: Scalars['ID'];
}>;


export type GetCommunityProjectsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCommunityProjectsCount'>
);

export type GetCompanyCategoriesQueryVariables = Exact<{
  where?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type GetCompanyCategoriesQuery = (
  { __typename?: 'Query' }
  & { getCompanyCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Category' }
      & { featureImage?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )> }
      & CategoryFragment
    )> }
  )>>> }
);

export type GetCompanyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyInfoQuery = (
  { __typename?: 'Query' }
  & { getCompanyInfo?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name' | 'pin_registration' | 'website' | 'affiliates' | 'paymentMethod' | 'default_dashboard' | 'id_number' | 'wildlife_association_number' | 'createdAt' | 'updatedAt'>
    & { subscription?: Maybe<(
      { __typename?: 'SubscriptionEntityResponse' }
      & { data?: Maybe<(
        { __typename?: 'SubscriptionEntity' }
        & Pick<SubscriptionEntity, 'id'>
        & { attributes?: Maybe<(
          { __typename?: 'Subscription' }
          & Pick<Subscription, 'createdAt' | 'updatedAt' | 'current_period_start' | 'current_period_end' | 'cancel_at' | 'trial_start' | 'collection_method' | 'cancel_at_period_end' | 'recurring' | 'trial_end' | 'status'>
          & { product_plan?: Maybe<(
            { __typename?: 'ProductPlanEntityResponse' }
            & { data?: Maybe<(
              { __typename?: 'ProductPlanEntity' }
              & Pick<ProductPlanEntity, 'id'>
              & { attributes?: Maybe<(
                { __typename?: 'ProductPlan' }
                & Pick<ProductPlan, 'name' | 'isManual' | 'description'>
                & { ProductFeature?: Maybe<(
                  { __typename?: 'ComponentProductFeatureProductFeature' }
                  & Pick<ComponentProductFeatureProductFeature, 'id' | 'baoriders_allowed' | 'cost_per_baorider' | 'price' | 'recurring'>
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, team_members?: Maybe<(
      { __typename?: 'UsersPermissionsUserRelationResponseCollection' }
      & { data: Array<(
        { __typename?: 'UsersPermissionsUserEntity' }
        & Pick<UsersPermissionsUserEntity, 'id'>
        & { attributes?: Maybe<(
          { __typename?: 'UsersPermissionsUser' }
          & MemberFragment
        )> }
      )> }
    )> }
  )> }
);

export type GetCompanyLeadersQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetCompanyLeadersQuery = (
  { __typename?: 'Query' }
  & { getCompanyLeaders?: Maybe<Array<Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & MemberFragment
    )> }
  )>>> }
);

export type GetCompanyLeadersCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyLeadersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyLeadersCount'>
);

export type GetCompanyTaskTypesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetCompanyTaskTypesQuery = (
  { __typename?: 'Query' }
  & { getCompanyTaskTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskType' }
      & TaskTypeFragment
    )> }
  )>>> }
);

export type GetCustomerPaymentDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerPaymentDetailsQuery = (
  { __typename?: 'Query' }
  & { getCustomerPaymentDetails?: Maybe<Array<Maybe<(
    { __typename?: 'CardPayload' }
    & Pick<CardPayload, 'brand' | 'exp_month' | 'exp_year' | 'last4'>
  )>>> }
);

export type GetDataListsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetDataListsQuery = (
  { __typename?: 'Query' }
  & { getDataLists?: Maybe<Array<Maybe<(
    { __typename?: 'DataListEntity' }
    & Pick<DataListEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'DataList' }
      & DataListsFragment
    )> }
  )>>> }
);

export type GetProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderProject?: Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Project' }
      & { featured_image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )> }
      & ProjectFragment
    )> }
  )> }
);

export type GetProjectDataCollectorsCountQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetProjectDataCollectorsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProjectDataCollectorsCount'>
);

export type GetProjectGroupsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetProjectGroupsQuery = (
  { __typename?: 'Query' }
  & { getProjectGroups?: Maybe<Array<Maybe<(
    { __typename?: 'CommunityEntity' }
    & Pick<CommunityEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Community' }
      & Pick<Community, 'name'>
    )> }
  )>>> }
);

export type GetProjectMetricsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProjectMetricsQuery = (
  { __typename?: 'Query' }
  & { getProjectMetrics?: Maybe<(
    { __typename?: 'TaskMetrics' }
    & { completedTasks?: Maybe<(
      { __typename?: 'Metric' }
      & Pick<Metric, 'total' | 'last30Days'>
      & { chartData?: Maybe<Array<Maybe<(
        { __typename?: 'ChartData' }
        & Pick<ChartData, 'key' | 'value'>
      )>>> }
    )>, reportedTask?: Maybe<(
      { __typename?: 'Metric' }
      & Pick<Metric, 'total' | 'last30Days'>
      & { chartData?: Maybe<Array<Maybe<(
        { __typename?: 'ChartData' }
        & Pick<ChartData, 'key' | 'value'>
      )>>> }
    )>, averageCompletionTime?: Maybe<(
      { __typename?: 'AverageMetirc' }
      & Pick<AverageMetirc, 'total' | 'wow' | 'dod'>
    )>, averageEvidenceTime?: Maybe<(
      { __typename?: 'AverageMetirc' }
      & Pick<AverageMetirc, 'total' | 'wow' | 'dod'>
    )> }
  )> }
);

export type GetProjectTaskTypesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<TaskTypeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetProjectTaskTypesQuery = (
  { __typename?: 'Query' }
  & { taskTypes?: Maybe<(
    { __typename?: 'TaskTypeEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'TaskTypeEntity' }
      & Pick<TaskTypeEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'TaskType' }
        & Pick<TaskType, 'title'>
      )> }
    )> }
  )> }
);

export type GetProjectUnsdgTagsQueryVariables = Exact<{
  project: Scalars['ID'];
}>;


export type GetProjectUnsdgTagsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getProjectUnsdgTags'>
);

export type GetProjectsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetProjectsQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderProjects?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Project' }
      & { featured_image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )> }
      & ProjectFragment
    )> }
  )>>> }
);

export type GetSingleBaoriderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleBaoriderQuery = (
  { __typename?: 'Query' }
  & { getSingleBaorider?: Maybe<(
    { __typename?: 'BaoriderPayload' }
    & { baorider?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'idNumber' | 'phoneNumber' | 'email' | 'firstName' | 'lastName' | 'isVerifier' | 'status' | 'createdAt' | 'updatedAt' | 'gender' | 'dateOfBirth'>
        & { profilePicture?: Maybe<(
          { __typename?: 'UploadFileEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UploadFileEntity' }
            & UploadFileFragment
          )> }
        )>, community?: Maybe<(
          { __typename?: 'CommunityEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'CommunityEntity' }
            & Pick<CommunityEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Community' }
              & CommunityFragment
            )> }
          )> }
        )>, projects?: Maybe<(
          { __typename?: 'ProjectRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'ProjectEntity' }
            & Pick<ProjectEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Project' }
              & { featured_image?: Maybe<(
                { __typename?: 'UploadFileEntityResponse' }
                & { data?: Maybe<(
                  { __typename?: 'UploadFileEntity' }
                  & UploadFileFragment
                )> }
              )> }
              & ProjectFragment
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetSingleBaoleaderCommunityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleBaoleaderCommunityQuery = (
  { __typename?: 'Query' }
  & { getSingleBaoleaderCommunity?: Maybe<(
    { __typename?: 'CommunityEntity' }
    & Pick<CommunityEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Community' }
      & { featured_image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )> }
      & CommunityFragment
    )> }
  )> }
);

export type GetSingleCommunityMemberQueryVariables = Exact<{
  where?: Maybe<Scalars['JSON']>;
}>;


export type GetSingleCommunityMemberQuery = (
  { __typename?: 'Query' }
  & { getSingleCommunityMember?: Maybe<(
    { __typename?: 'CommunityMembersPayload' }
    & { member?: Maybe<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'idNumber' | 'phoneNumber' | 'firstName' | 'lastName' | 'email' | 'status' | 'createdAt' | 'updatedAt' | 'gender' | 'dateOfBirth'>
        & { profilePicture?: Maybe<(
          { __typename?: 'UploadFileEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UploadFileEntity' }
            & UploadFileFragment
          )> }
        )>, community?: Maybe<(
          { __typename?: 'CommunityEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'CommunityEntity' }
            & Pick<CommunityEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Community' }
              & { featured_image?: Maybe<(
                { __typename?: 'UploadFileEntityResponse' }
                & { data?: Maybe<(
                  { __typename?: 'UploadFileEntity' }
                  & UploadFileFragment
                )> }
              )> }
              & CommunityFragment
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetSingleBaoleaderProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSingleBaoleaderProjectQuery = (
  { __typename?: 'Query' }
  & { getSingleBaoleaderProject?: Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Project' }
      & { featured_image?: Maybe<(
        { __typename?: 'UploadFileEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UploadFileEntity' }
          & UploadFileFragment
        )> }
      )> }
      & ProjectFragment
    )> }
  )> }
);

export type GetSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionQuery = (
  { __typename?: 'Query' }
  & { getSubscription?: Maybe<(
    { __typename?: 'SubscriptionPayload' }
    & Pick<SubscriptionPayload, 'id' | 'recurring' | 'cancel_at_period_end' | 'cancel_at' | 'collection_method' | 'created' | 'current_period_end' | 'current_period_start' | 'customer' | 'ended_at' | 'latest_invoice' | 'status' | 'trial_end' | 'trial_start' | 'items'>
    & { product_plan?: Maybe<(
      { __typename?: 'ProductPlan' }
      & Pick<ProductPlan, 'name'>
    )> }
  )> }
);

export type GetTaskBuilderFilterCategoriesQueryVariables = Exact<{
  where?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type GetTaskBuilderFilterCategoriesQuery = (
  { __typename?: 'Query' }
  & { getTaskBuilderFilterCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'title'>
    )> }
  )>>> }
);

export type GetTaskBuilderFilterCreatorsQueryVariables = Exact<{
  where?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
}>;


export type GetTaskBuilderFilterCreatorsQuery = (
  { __typename?: 'Query' }
  & { getTaskBuilderFilterCreators?: Maybe<Array<Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'firstName' | 'lastName'>
    )> }
  )>>> }
);

export type GetTaskFilterCommunitiesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetTaskFilterCommunitiesQuery = (
  { __typename?: 'Query' }
  & { getTaskFilterCommunities?: Maybe<Array<Maybe<(
    { __typename?: 'CommunityEntity' }
    & Pick<CommunityEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Community' }
      & CommunityFragment
    )> }
  )>>> }
);

export type GetTaskFilterDataCollectorsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetTaskFilterDataCollectorsQuery = (
  { __typename?: 'Query' }
  & { getTaskFilterDataCollectors?: Maybe<Array<Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'idNumber' | 'phoneNumber' | 'firstName' | 'isVerifier' | 'lastName' | 'status' | 'createdAt' | 'updatedAt'>
    )> }
  )>>> }
);

export type GetTaskFilterProjectsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetTaskFilterProjectsQuery = (
  { __typename?: 'Query' }
  & { getTaskFilterProjects?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectEntity' }
    & Pick<ProjectEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'Project' }
      & ProjectFragment
    )> }
  )>>> }
);

export type GetTaskFilterTaskTypesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetTaskFilterTaskTypesQuery = (
  { __typename?: 'Query' }
  & { getTaskFilterTaskTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskType' }
      & TaskTypeFragment
    )> }
  )>>> }
);

export type GetTaskFilterUnsdgsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetTaskFilterUnsdgsQuery = (
  { __typename?: 'Query' }
  & { getTaskFilterUnsdgs?: Maybe<Array<Maybe<(
    { __typename?: 'UnSdgEntity' }
    & Pick<UnSdgEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UnSdg' }
      & UnsdgFragment
    )> }
  )>>> }
);

export type GetTaskTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTaskTypeQuery = (
  { __typename?: 'Query' }
  & { getTaskType?: Maybe<(
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskType' }
      & { un_sdg?: Maybe<(
        { __typename?: 'UnSdgEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UnSdgEntity' }
          & Pick<UnSdgEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'UnSdg' }
            & UnsdgFragment
          )> }
        )> }
      )>, category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Category' }
            & { featureImage?: Maybe<(
              { __typename?: 'UploadFileEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'UploadFileEntity' }
                & UploadFileFragment
              )> }
            )> }
            & CategoryFragment
          )> }
        )> }
      )>, creator?: Maybe<(
        { __typename?: 'UsersPermissionsUserEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UsersPermissionsUserEntity' }
          & Pick<UsersPermissionsUserEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'UsersPermissionsUser' }
            & Pick<UsersPermissionsUser, 'firstName' | 'lastName'>
          )> }
        )> }
      )>, dataFields?: Maybe<Array<Maybe<(
        { __typename: 'ComponentCustomDataFieldsCheckbox' }
        & ComponentCustomDataFieldsCheckboxFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsRadio' }
        & ComponentCustomDataFieldsRadioFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsSelect' }
        & ComponentCustomDataFieldsSelectFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsText' }
        & ComponentCustomDataFieldsTextFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsNumberRange' }
        & ComponentCustomDataFieldsNumberRangeFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsPercentage' }
        & ComponentCustomDataFieldsPercentageFragment
        & ComponentCustomDataFieldsPercentageFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsMetricsSpecific' }
        & ComponentCustomDataFieldsMetricsSpecificFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsNumberDropDown' }
        & ComponentCustomDataFieldsNumberDropDownFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsRating' }
        & ComponentCustomDataFieldsRatingFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsNumberField' }
        & ComponentCustomDataFieldsNumberFieldFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsDateTimeField' }
        & ComponentCustomDataFieldsDateTimeFieldFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsDataList' }
        & ComponentCustomDataFieldsDataListFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsEmailField' }
        & ComponentCustomDataFieldsEmailFieldFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsPhoneNumberField' }
        & ComponentCustomDataFieldsPhoneNumberFieldFragment
      ) | (
        { __typename: 'ComponentCustomDataFieldsImageField' }
        & ComponentCustomDataFieldsImageFieldFragment
      ) | { __typename: 'Error' }>>> }
      & TaskTypeFragment
    )> }
  )> }
);

export type GetTaskTypesQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<TaskTypeFiltersInput>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetTaskTypesQuery = (
  { __typename?: 'Query' }
  & { taskTypes?: Maybe<(
    { __typename?: 'TaskTypeEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'TaskTypeEntity' }
      & Pick<TaskTypeEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'TaskType' }
        & { category?: Maybe<(
          { __typename?: 'CategoryEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Category' }
              & { featureImage?: Maybe<(
                { __typename?: 'UploadFileEntityResponse' }
                & { data?: Maybe<(
                  { __typename?: 'UploadFileEntity' }
                  & UploadFileFragment
                )> }
              )> }
              & CategoryFragment
            )> }
          )> }
        )>, creator?: Maybe<(
          { __typename?: 'UsersPermissionsUserEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UsersPermissionsUserEntity' }
            & Pick<UsersPermissionsUserEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'UsersPermissionsUser' }
              & MemberFragment
            )> }
          )> }
        )> }
        & TaskTypeFragment
      )> }
    )> }
  )> }
);

export type GetTasksQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetTasksQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderTasksWithCount?: Maybe<(
    { __typename?: 'TasksWithCount' }
    & Pick<TasksWithCount, 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TaskEntity' }
      & Pick<TaskEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Task' }
        & { task_definition?: Maybe<(
          { __typename?: 'TaskDefinitionEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'TaskDefinitionEntity' }
            & Pick<TaskDefinitionEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'TaskDefinition' }
              & { task_type?: Maybe<(
                { __typename?: 'TaskTypeEntityResponse' }
                & { data?: Maybe<(
                  { __typename?: 'TaskTypeEntity' }
                  & Pick<TaskTypeEntity, 'id'>
                  & { attributes?: Maybe<(
                    { __typename?: 'TaskType' }
                    & Pick<TaskType, 'title'>
                  )> }
                )> }
              )>, category?: Maybe<(
                { __typename?: 'CategoryEntityResponse' }
                & { data?: Maybe<(
                  { __typename?: 'CategoryEntity' }
                  & Pick<CategoryEntity, 'id'>
                  & { attributes?: Maybe<(
                    { __typename?: 'Category' }
                    & { featureImage?: Maybe<(
                      { __typename?: 'UploadFileEntityResponse' }
                      & { data?: Maybe<(
                        { __typename?: 'UploadFileEntity' }
                        & UploadFileFragment
                      )> }
                    )> }
                  )> }
                )> }
              )> }
            )> }
          )> }
        )>, community?: Maybe<(
          { __typename?: 'CommunityEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'CommunityEntity' }
            & Pick<CommunityEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Community' }
              & Pick<Community, 'name'>
            )> }
          )> }
        )>, project?: Maybe<(
          { __typename?: 'ProjectEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'ProjectEntity' }
            & Pick<ProjectEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Project' }
              & Pick<Project, 'name'>
            )> }
          )> }
        )>, evidences?: Maybe<(
          { __typename?: 'EvidenceRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'EvidenceEntity' }
            & Pick<EvidenceEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'Evidence' }
              & { custom_data_fields?: Maybe<Array<Maybe<{ __typename: 'ComponentCustomDataFieldsRadio' } | { __typename: 'ComponentCustomDataFieldsText' } | { __typename: 'ComponentCustomDataFieldsCheckbox' } | { __typename: 'ComponentCustomDataFieldsSelect' } | { __typename: 'ComponentCustomDataFieldsNumberRange' } | { __typename: 'ComponentCustomDataFieldsPercentage' } | { __typename: 'ComponentCustomDataFieldsMetricsSpecific' } | { __typename: 'ComponentCustomDataFieldsNumberDropDown' } | { __typename: 'ComponentCustomDataFieldsRating' } | { __typename: 'ComponentCustomDataFieldsNumberField' } | { __typename: 'ComponentCustomDataFieldsDateTimeField' } | { __typename: 'ComponentCustomDataFieldsDataList' } | { __typename: 'ComponentCustomDataFieldsEmailField' } | { __typename: 'ComponentCustomDataFieldsPhoneNumberField' } | (
                { __typename: 'ComponentCustomDataFieldsImageField' }
                & ComponentCustomDataFieldsImageFieldFragment
              ) | { __typename: 'Error' }>>>, photos?: Maybe<(
                { __typename?: 'UploadFileRelationResponseCollection' }
                & { data: Array<(
                  { __typename?: 'UploadFileEntity' }
                  & UploadFileFragment
                )> }
              )>, baorider?: Maybe<(
                { __typename?: 'UsersPermissionsUserEntityResponse' }
                & { data?: Maybe<(
                  { __typename?: 'UsersPermissionsUserEntity' }
                  & Pick<UsersPermissionsUserEntity, 'id'>
                  & { attributes?: Maybe<(
                    { __typename?: 'UsersPermissionsUser' }
                    & Pick<UsersPermissionsUser, 'firstName' | 'lastName' | 'phoneNumber'>
                  )> }
                )> }
              )> }
            )> }
          )> }
        )> }
        & TaskFragment
      )> }
    )>>> }
  )> }
);

export type GetTasksDashboardMetricsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTasksDashboardMetricsQuery = (
  { __typename?: 'Query' }
  & { getTasksDashboardMetrics?: Maybe<(
    { __typename?: 'TaskMetrics' }
    & { completedTasks?: Maybe<(
      { __typename?: 'Metric' }
      & Pick<Metric, 'total' | 'last30Days'>
      & { chartData?: Maybe<Array<Maybe<(
        { __typename?: 'ChartData' }
        & Pick<ChartData, 'key' | 'value'>
      )>>> }
    )>, reportedTask?: Maybe<(
      { __typename?: 'Metric' }
      & Pick<Metric, 'total' | 'last30Days'>
      & { chartData?: Maybe<Array<Maybe<(
        { __typename?: 'ChartData' }
        & Pick<ChartData, 'key' | 'value'>
      )>>> }
    )>, averageCompletionTime?: Maybe<(
      { __typename?: 'AverageMetirc' }
      & Pick<AverageMetirc, 'total' | 'wow' | 'dod'>
    )>, averageEvidenceTime?: Maybe<(
      { __typename?: 'AverageMetirc' }
      & Pick<AverageMetirc, 'total' | 'wow' | 'dod'>
    )> }
  )> }
);

export type GetTopPerformingUsersQueryVariables = Exact<{
  community?: Maybe<Scalars['ID']>;
  project?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetTopPerformingUsersQuery = (
  { __typename?: 'Query' }
  & { getTopPerformingUsers?: Maybe<(
    { __typename?: 'TopPerforming' }
    & { baoriders?: Maybe<Array<Maybe<(
      { __typename?: 'TopPerformingBaorider' }
      & Pick<TopPerformingBaorider, 'tasksCompleted'>
      & { baorider?: Maybe<(
        { __typename?: 'UsersPermissionsUserEntity' }
        & Pick<UsersPermissionsUserEntity, 'id'>
        & { attributes?: Maybe<(
          { __typename?: 'UsersPermissionsUser' }
          & Pick<UsersPermissionsUser, 'firstName' | 'lastName'>
        )> }
      )> }
    )>>>, communityMembers?: Maybe<Array<Maybe<(
      { __typename?: 'TopPerformingCommunityMember' }
      & Pick<TopPerformingCommunityMember, 'tasksCompleted'>
      & { communityMember?: Maybe<(
        { __typename?: 'UsersPermissionsUserEntity' }
        & Pick<UsersPermissionsUserEntity, 'id'>
        & { attributes?: Maybe<(
          { __typename?: 'UsersPermissionsUser' }
          & Pick<UsersPermissionsUser, 'firstName' | 'lastName'>
        )> }
      )> }
    )>>>, chartData?: Maybe<(
      { __typename?: 'TopPerformingChartData' }
      & { communityMembers?: Maybe<Array<Maybe<(
        { __typename?: 'ChartData' }
        & Pick<ChartData, 'key' | 'value'>
      )>>>, baoriders?: Maybe<Array<Maybe<(
        { __typename?: 'ChartData' }
        & Pick<ChartData, 'key' | 'value'>
      )>>> }
    )> }
  )> }
);

export type GetUnsdGsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<UnSdgFiltersInput>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetUnsdGsQuery = (
  { __typename?: 'Query' }
  & { unSdgs?: Maybe<(
    { __typename?: 'UnSdgEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'UnSdgEntity' }
      & Pick<UnSdgEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UnSdg' }
        & Pick<UnSdg, 'name' | 'createdAt' | 'updatedAt' | 'publishedAt'>
        & { task_types?: Maybe<(
          { __typename?: 'TaskTypeRelationResponseCollection' }
          & { data: Array<(
            { __typename?: 'TaskTypeEntity' }
            & Pick<TaskTypeEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'TaskType' }
              & TaskTypeFragment
            )> }
          )> }
        )>, icon?: Maybe<(
          { __typename?: 'UploadFileEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UploadFileEntity' }
            & UploadFileFragment
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetVideosQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<VideoFiltersInput>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetVideosQuery = (
  { __typename?: 'Query' }
  & { videos?: Maybe<(
    { __typename?: 'VideoEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'VideoEntity' }
      & Pick<VideoEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'Video' }
        & Pick<Video, 'title' | 'order' | 'category'>
        & { videoLink: (
          { __typename?: 'UploadFileEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UploadFileEntity' }
            & UploadFileFragment
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type GetWholeCompanyTaskTypesQueryVariables = Exact<{
  sort?: Maybe<Scalars['JSON']>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetWholeCompanyTaskTypesQuery = (
  { __typename?: 'Query' }
  & { getWholeCompanyTaskTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TaskTypeEntity' }
    & Pick<TaskTypeEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskType' }
      & { category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Category' }
            & Pick<Category, 'title'>
            & { featureImage?: Maybe<(
              { __typename?: 'UploadFileEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'UploadFileEntity' }
                & UploadFileFragment
              )> }
            )> }
          )> }
        )> }
      )>, creator?: Maybe<(
        { __typename?: 'UsersPermissionsUserEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'UsersPermissionsUserEntity' }
          & Pick<UsersPermissionsUserEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'UsersPermissionsUser' }
            & Pick<UsersPermissionsUser, 'firstName' | 'lastName'>
          )> }
        )> }
      )> }
      & TaskTypeFragment
    )> }
  )>>> }
);

export type HelpQueryVariables = Exact<{ [key: string]: never; }>;


export type HelpQuery = (
  { __typename?: 'Query' }
  & { trainingVideo?: Maybe<(
    { __typename?: 'TrainingVideoEntityResponse' }
    & { data?: Maybe<(
      { __typename?: 'TrainingVideoEntity' }
      & Pick<TrainingVideoEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename: 'TrainingVideo' }
        & { training_videos?: Maybe<Array<Maybe<(
          { __typename?: 'ComponentTrainingVideoTrainingVideo' }
          & Training_VideoFragment
        )>>>, FAQs?: Maybe<Array<Maybe<(
          { __typename?: 'ComponentFaqFaq' }
          & FaqFragment
        )>>> }
      )> }
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { myUser?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntity' }
    & Pick<UsersPermissionsUserEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'UsersPermissionsUser' }
      & Pick<UsersPermissionsUser, 'username' | 'firstName' | 'lastName' | 'email' | 'confirmed' | 'blocked' | 'receiveAllTimeWeeklyStatusReport' | 'receiveLoginReminder' | 'receiveWeeklyStatusReport' | 'onboardingInformation'>
      & { company?: Maybe<(
        { __typename?: 'CompanyEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CompanyEntity' }
          & Pick<CompanyEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Company' }
            & Pick<Company, 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetBaoleaderTaskDefinitionsQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Scalars['JSON']>;
  pagination?: Maybe<PaginationArg>;
}>;


export type GetBaoleaderTaskDefinitionsQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderTaskDefinitions?: Maybe<Array<Maybe<(
    { __typename?: 'TaskDefinitionEntity' }
    & Pick<TaskDefinitionEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskDefinition' }
      & Pick<TaskDefinition, 'status'>
      & { category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Category' }
            & { featureImage?: Maybe<(
              { __typename?: 'UploadFileEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'UploadFileEntity' }
                & UploadFileFragment
              )> }
            )> }
            & CategoryFragment
          )> }
        )> }
      )>, task_type?: Maybe<(
        { __typename?: 'TaskTypeEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'TaskTypeEntity' }
          & Pick<TaskTypeEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'TaskType' }
            & TaskTypeFragment
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type GetBaoleaderTaskDefinitionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBaoleaderTaskDefinitionQuery = (
  { __typename?: 'Query' }
  & { getBaoleaderTaskDefinition?: Maybe<(
    { __typename?: 'TaskDefinitionEntity' }
    & Pick<TaskDefinitionEntity, 'id'>
    & { attributes?: Maybe<(
      { __typename?: 'TaskDefinition' }
      & Pick<TaskDefinition, 'status'>
      & { category?: Maybe<(
        { __typename?: 'CategoryEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'Category' }
            & { featureImage?: Maybe<(
              { __typename?: 'UploadFileEntityResponse' }
              & { data?: Maybe<(
                { __typename?: 'UploadFileEntity' }
                & UploadFileFragment
              )> }
            )> }
            & CategoryFragment
          )> }
        )> }
      )>, task_type?: Maybe<(
        { __typename?: 'TaskTypeEntityResponse' }
        & { data?: Maybe<(
          { __typename?: 'TaskTypeEntity' }
          & Pick<TaskTypeEntity, 'id'>
          & { attributes?: Maybe<(
            { __typename?: 'TaskType' }
            & Pick<TaskType, 'title' | 'photoEvidence' | 'documentedEvidence' | 'environmentalImpact' | 'socialImpact'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<UsersPermissionsUserFiltersInput>;
  pagination?: Maybe<PaginationArg>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { usersPermissionsUsers?: Maybe<(
    { __typename?: 'UsersPermissionsUserEntityResponseCollection' }
    & { data: Array<(
      { __typename?: 'UsersPermissionsUserEntity' }
      & Pick<UsersPermissionsUserEntity, 'id'>
      & { attributes?: Maybe<(
        { __typename?: 'UsersPermissionsUser' }
        & Pick<UsersPermissionsUser, 'createdAt' | 'updatedAt' | 'username' | 'email' | 'provider' | 'confirmed' | 'blocked'>
        & { role?: Maybe<(
          { __typename?: 'UsersPermissionsRoleEntityResponse' }
          & { data?: Maybe<(
            { __typename?: 'UsersPermissionsRoleEntity' }
            & Pick<UsersPermissionsRoleEntity, 'id'>
            & { attributes?: Maybe<(
              { __typename?: 'UsersPermissionsRole' }
              & Pick<UsersPermissionsRole, 'name' | 'description' | 'type'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export const UploadFileFragmentDoc = gql`
    fragment uploadFile on UploadFileEntity {
  id
  attributes {
    createdAt
    updatedAt
    name
    alternativeText
    caption
    size
    mime
    url
    provider
    width
    height
    formats
    hash
    ext
  }
}
    `;
export const UnsdgFragmentDoc = gql`
    fragment UNSDG on UnSdg {
  name
  icon {
    data {
      ...uploadFile
    }
  }
}
    ${UploadFileFragmentDoc}`;
export const Analytic_FragmentFragmentDoc = gql`
    fragment analytic_fragment on Analytic {
  default_dashboard
}
    `;
export const CategoryFragmentDoc = gql`
    fragment category on Category {
  title
  description
}
    `;
export const ComponentCustomDataFieldsCheckboxFragmentDoc = gql`
    fragment componentCustomDataFieldsCheckbox on ComponentCustomDataFieldsCheckbox {
  id
  isRequired
  label
  option {
    id
    option
  }
}
    `;
export const ComponentCustomDataFieldsDataListFragmentDoc = gql`
    fragment componentCustomDataFieldsDataList on ComponentCustomDataFieldsDataList {
  id
  label
  isRequired
  listId
  option {
    id
    option
  }
}
    `;
export const ComponentCustomDataFieldsDateTimeFieldFragmentDoc = gql`
    fragment componentCustomDataFieldsDateTimeField on ComponentCustomDataFieldsDateTimeField {
  id
  label
  isRequired
  datetime
}
    `;
export const ComponentCustomDataFieldsEmailFieldFragmentDoc = gql`
    fragment componentCustomDataFieldsEmailField on ComponentCustomDataFieldsEmailField {
  id
  label
  isRequired
  email
}
    `;
export const ComponentCustomDataFieldsImageFieldFragmentDoc = gql`
    fragment componentCustomDataFieldsImageField on ComponentCustomDataFieldsImageField {
  id
  isRequired
  label
  upload_state
  images {
    data {
      ...uploadFile
    }
  }
}
    ${UploadFileFragmentDoc}`;
export const ComponentCustomDataFieldsMetricsSpecificFragmentDoc = gql`
    fragment componentCustomDataFieldsMetricsSpecific on ComponentCustomDataFieldsMetricsSpecific {
  id
  label
  isRequired
  metric
  number_value
}
    `;
export const ComponentCustomDataFieldsNumberDropDownFragmentDoc = gql`
    fragment componentCustomDataFieldsNumberDropDown on ComponentCustomDataFieldsNumberDropDown {
  id
  label
  value
  isRequired
  max
  min
}
    `;
export const ComponentCustomDataFieldsNumberFieldFragmentDoc = gql`
    fragment componentCustomDataFieldsNumberField on ComponentCustomDataFieldsNumberField {
  id
  label
  isRequired
  number_field_value
}
    `;
export const ComponentCustomDataFieldsNumberRangeFragmentDoc = gql`
    fragment componentCustomDataFieldsNumberRange on ComponentCustomDataFieldsNumberRange {
  id
  label
  min
  isRequired
  max
}
    `;
export const ComponentCustomDataFieldsPercentageFragmentDoc = gql`
    fragment componentCustomDataFieldsPercentage on ComponentCustomDataFieldsPercentage {
  id
  label
  isRequired
  value
}
    `;
export const ComponentCustomDataFieldsPhoneNumberFieldFragmentDoc = gql`
    fragment componentCustomDataFieldsPhoneNumberField on ComponentCustomDataFieldsPhoneNumberField {
  id
  label
  isRequired
  phoneNumber
}
    `;
export const ComponentCustomDataFieldsRadioFragmentDoc = gql`
    fragment componentCustomDataFieldsRadio on ComponentCustomDataFieldsRadio {
  id
  label
  isRequired
  option {
    id
    option
  }
}
    `;
export const ComponentCustomDataFieldsRatingFragmentDoc = gql`
    fragment componentCustomDataFieldsRating on ComponentCustomDataFieldsRating {
  id
  label
  rating
  isRequired
  number_of_stars
}
    `;
export const ComponentCustomDataFieldsSelectFragmentDoc = gql`
    fragment componentCustomDataFieldsSelect on ComponentCustomDataFieldsSelect {
  id
  isRequired
  label
  option {
    id
    option
    __typename
  }
}
    `;
export const ComponentCustomDataFieldsTextFragmentDoc = gql`
    fragment componentCustomDataFieldsText on ComponentCustomDataFieldsText {
  id
  label
  isRequired
  text_value
}
    `;
export const DataListOptionFragmentDoc = gql`
    fragment dataListOption on DataListOption {
  createdAt
  status
  updatedAt
  value
  publishedAt
}
    `;
export const DataListsFragmentDoc = gql`
    fragment dataLists on DataList {
  name
  createdAt
  updatedAt
  status
  publishedAt
}
    `;
export const EvidenceFragmentDoc = gql`
    fragment evidence on Evidence {
  notes
  location
  createdAt
  updatedAt
  upload_state
  cancellationReason
  requestedInfo
  upload_state
}
    `;
export const FaqFragmentDoc = gql`
    fragment faq on ComponentFaqFaq {
  id
  question
  answer
  category
  tags {
    id
    option
  }
  __typename
}
    `;
export const CommunityFragmentDoc = gql`
    fragment community on Community {
  createdAt
  updatedAt
  name
  type
  description
  chief_name
  location
}
    `;
export const MemberFragmentDoc = gql`
    fragment member on UsersPermissionsUser {
  firstName
  lastName
  status
  phoneNumber
  createdAt
  updatedAt
  gender
  idNumber
  blocked
  dateOfBirth
  location_permission
  camera_permission
  media_library_permission
  baoleader {
    data {
      id
    }
  }
  isVerifier
  email
  profilePicture {
    data {
      ...uploadFile
    }
  }
  role {
    data {
      id
      attributes {
        name
        type
      }
    }
  }
  community {
    data {
      id
      attributes {
        ...community
        featured_image {
          data {
            ...uploadFile
          }
        }
      }
    }
  }
}
    ${UploadFileFragmentDoc}
${CommunityFragmentDoc}`;
export const ProjectFragmentDoc = gql`
    fragment project on Project {
  breakdown
  name
  createdAt
  updatedAt
  collaborators
  reward
  status
  statement
  description
  outcomes
  createdAt
  updatedAt
  budget
}
    `;
export const TaskFragmentDoc = gql`
    fragment task on Task {
  updatedAt
  createdAt
  date_reported
  status
  location
  onlyEmployeesVerify
  timezone
}
    `;
export const TaskTypeFragmentDoc = gql`
    fragment taskType on TaskType {
  title
  ER_ID
  photoEvidence
  documentedEvidence
  environmentalImpact
  socialImpact
  status
  createdAt
  updatedAt
  withComment
  photoRequired
}
    `;
export const Training_VideoFragmentDoc = gql`
    fragment training_video on ComponentTrainingVideoTrainingVideo {
  id
  title
  url
  category
  description
  tags {
    id
    option
  }
  __typename
}
    `;
export const AcceptCommunityMemberRequestDocument = gql`
    mutation acceptCommunityMemberRequest($requestId: ID!) {
  acceptCommunityMemberRequest(requestId: $requestId) {
    id
    attributes {
      firstName
      lastName
    }
  }
}
    `;
export type AcceptCommunityMemberRequestMutationFn = Apollo.MutationFunction<AcceptCommunityMemberRequestMutation, AcceptCommunityMemberRequestMutationVariables>;

/**
 * __useAcceptCommunityMemberRequestMutation__
 *
 * To run a mutation, you first call `useAcceptCommunityMemberRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCommunityMemberRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCommunityMemberRequestMutation, { data, loading, error }] = useAcceptCommunityMemberRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useAcceptCommunityMemberRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCommunityMemberRequestMutation, AcceptCommunityMemberRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCommunityMemberRequestMutation, AcceptCommunityMemberRequestMutationVariables>(AcceptCommunityMemberRequestDocument, options);
      }
export type AcceptCommunityMemberRequestMutationHookResult = ReturnType<typeof useAcceptCommunityMemberRequestMutation>;
export type AcceptCommunityMemberRequestMutationResult = Apollo.MutationResult<AcceptCommunityMemberRequestMutation>;
export type AcceptCommunityMemberRequestMutationOptions = Apollo.BaseMutationOptions<AcceptCommunityMemberRequestMutation, AcceptCommunityMemberRequestMutationVariables>;
export const ActivateTaskTypeDocument = gql`
    mutation activateTaskType($id: ID!) {
  activateTaskType(id: $id) {
    id
  }
}
    `;
export type ActivateTaskTypeMutationFn = Apollo.MutationFunction<ActivateTaskTypeMutation, ActivateTaskTypeMutationVariables>;

/**
 * __useActivateTaskTypeMutation__
 *
 * To run a mutation, you first call `useActivateTaskTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTaskTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTaskTypeMutation, { data, loading, error }] = useActivateTaskTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateTaskTypeMutation(baseOptions?: Apollo.MutationHookOptions<ActivateTaskTypeMutation, ActivateTaskTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateTaskTypeMutation, ActivateTaskTypeMutationVariables>(ActivateTaskTypeDocument, options);
      }
export type ActivateTaskTypeMutationHookResult = ReturnType<typeof useActivateTaskTypeMutation>;
export type ActivateTaskTypeMutationResult = Apollo.MutationResult<ActivateTaskTypeMutation>;
export type ActivateTaskTypeMutationOptions = Apollo.BaseMutationOptions<ActivateTaskTypeMutation, ActivateTaskTypeMutationVariables>;
export const AddDataCollectorToProjectDocument = gql`
    mutation addDataCollectorToProject($input: AddDataCollectorToProjectInput!) {
  addDataCollectorToProject(input: $input) {
    id
  }
}
    `;
export type AddDataCollectorToProjectMutationFn = Apollo.MutationFunction<AddDataCollectorToProjectMutation, AddDataCollectorToProjectMutationVariables>;

/**
 * __useAddDataCollectorToProjectMutation__
 *
 * To run a mutation, you first call `useAddDataCollectorToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDataCollectorToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDataCollectorToProjectMutation, { data, loading, error }] = useAddDataCollectorToProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDataCollectorToProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddDataCollectorToProjectMutation, AddDataCollectorToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDataCollectorToProjectMutation, AddDataCollectorToProjectMutationVariables>(AddDataCollectorToProjectDocument, options);
      }
export type AddDataCollectorToProjectMutationHookResult = ReturnType<typeof useAddDataCollectorToProjectMutation>;
export type AddDataCollectorToProjectMutationResult = Apollo.MutationResult<AddDataCollectorToProjectMutation>;
export type AddDataCollectorToProjectMutationOptions = Apollo.BaseMutationOptions<AddDataCollectorToProjectMutation, AddDataCollectorToProjectMutationVariables>;
export const CompleteOnboardingStepDocument = gql`
    mutation completeOnboardingStep($index: String!, $completeOnboarding: Boolean) {
  completeOnboardingStep(index: $index, completeOnboarding: $completeOnboarding) {
    id
    attributes {
      username
    }
  }
}
    `;
export type CompleteOnboardingStepMutationFn = Apollo.MutationFunction<CompleteOnboardingStepMutation, CompleteOnboardingStepMutationVariables>;

/**
 * __useCompleteOnboardingStepMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingStepMutation, { data, loading, error }] = useCompleteOnboardingStepMutation({
 *   variables: {
 *      index: // value for 'index'
 *      completeOnboarding: // value for 'completeOnboarding'
 *   },
 * });
 */
export function useCompleteOnboardingStepMutation(baseOptions?: Apollo.MutationHookOptions<CompleteOnboardingStepMutation, CompleteOnboardingStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteOnboardingStepMutation, CompleteOnboardingStepMutationVariables>(CompleteOnboardingStepDocument, options);
      }
export type CompleteOnboardingStepMutationHookResult = ReturnType<typeof useCompleteOnboardingStepMutation>;
export type CompleteOnboardingStepMutationResult = Apollo.MutationResult<CompleteOnboardingStepMutation>;
export type CompleteOnboardingStepMutationOptions = Apollo.BaseMutationOptions<CompleteOnboardingStepMutation, CompleteOnboardingStepMutationVariables>;
export const CreateBaoleaderDocument = gql`
    mutation createBaoleader($input: CreateBaoleaderInput!) {
  createBaoleader(input: $input) {
    baoleader {
      id
      attributes {
        username
        email
      }
    }
  }
}
    `;
export type CreateBaoleaderMutationFn = Apollo.MutationFunction<CreateBaoleaderMutation, CreateBaoleaderMutationVariables>;

/**
 * __useCreateBaoleaderMutation__
 *
 * To run a mutation, you first call `useCreateBaoleaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaoleaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaoleaderMutation, { data, loading, error }] = useCreateBaoleaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBaoleaderMutation(baseOptions?: Apollo.MutationHookOptions<CreateBaoleaderMutation, CreateBaoleaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBaoleaderMutation, CreateBaoleaderMutationVariables>(CreateBaoleaderDocument, options);
      }
export type CreateBaoleaderMutationHookResult = ReturnType<typeof useCreateBaoleaderMutation>;
export type CreateBaoleaderMutationResult = Apollo.MutationResult<CreateBaoleaderMutation>;
export type CreateBaoleaderMutationOptions = Apollo.BaseMutationOptions<CreateBaoleaderMutation, CreateBaoleaderMutationVariables>;
export const CreateBaoriderDocument = gql`
    mutation createBaorider($input: CreateBaoriderInput!, $sendInvite: Boolean) {
  createBaorider(input: $input, sendInvite: $sendInvite) {
    baorider {
      id
      attributes {
        username
        email
      }
    }
  }
}
    `;
export type CreateBaoriderMutationFn = Apollo.MutationFunction<CreateBaoriderMutation, CreateBaoriderMutationVariables>;

/**
 * __useCreateBaoriderMutation__
 *
 * To run a mutation, you first call `useCreateBaoriderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaoriderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaoriderMutation, { data, loading, error }] = useCreateBaoriderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendInvite: // value for 'sendInvite'
 *   },
 * });
 */
export function useCreateBaoriderMutation(baseOptions?: Apollo.MutationHookOptions<CreateBaoriderMutation, CreateBaoriderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBaoriderMutation, CreateBaoriderMutationVariables>(CreateBaoriderDocument, options);
      }
export type CreateBaoriderMutationHookResult = ReturnType<typeof useCreateBaoriderMutation>;
export type CreateBaoriderMutationResult = Apollo.MutationResult<CreateBaoriderMutation>;
export type CreateBaoriderMutationOptions = Apollo.BaseMutationOptions<CreateBaoriderMutation, CreateBaoriderMutationVariables>;
export const CreateBaoridersDocument = gql`
    mutation createBaoriders($input: [CreateBaoriderInput]!) {
  createBaoriders(input: $input) {
    baoriders {
      id
      attributes {
        email
        username
      }
    }
  }
}
    `;
export type CreateBaoridersMutationFn = Apollo.MutationFunction<CreateBaoridersMutation, CreateBaoridersMutationVariables>;

/**
 * __useCreateBaoridersMutation__
 *
 * To run a mutation, you first call `useCreateBaoridersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaoridersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaoridersMutation, { data, loading, error }] = useCreateBaoridersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBaoridersMutation(baseOptions?: Apollo.MutationHookOptions<CreateBaoridersMutation, CreateBaoridersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBaoridersMutation, CreateBaoridersMutationVariables>(CreateBaoridersDocument, options);
      }
export type CreateBaoridersMutationHookResult = ReturnType<typeof useCreateBaoridersMutation>;
export type CreateBaoridersMutationResult = Apollo.MutationResult<CreateBaoridersMutation>;
export type CreateBaoridersMutationOptions = Apollo.BaseMutationOptions<CreateBaoridersMutation, CreateBaoridersMutationVariables>;
export const CreateCommunityDocument = gql`
    mutation createCommunity($input: CommunityInput) {
  createBaoleaderCommunity(input: $input) {
    id
    attributes {
      name
      chief_name
      description
    }
  }
}
    `;
export type CreateCommunityMutationFn = Apollo.MutationFunction<CreateCommunityMutation, CreateCommunityMutationVariables>;

/**
 * __useCreateCommunityMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMutation, { data, loading, error }] = useCreateCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommunityMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityMutation, CreateCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityMutation, CreateCommunityMutationVariables>(CreateCommunityDocument, options);
      }
export type CreateCommunityMutationHookResult = ReturnType<typeof useCreateCommunityMutation>;
export type CreateCommunityMutationResult = Apollo.MutationResult<CreateCommunityMutation>;
export type CreateCommunityMutationOptions = Apollo.BaseMutationOptions<CreateCommunityMutation, CreateCommunityMutationVariables>;
export const CreateCommunityMemberDocument = gql`
    mutation createCommunityMember($input: CreateCommunityMembersInput, $sendInvite: Boolean) {
  createCommunityMember(input: $input, sendInvite: $sendInvite) {
    member {
      id
      attributes {
        username
      }
    }
  }
}
    `;
export type CreateCommunityMemberMutationFn = Apollo.MutationFunction<CreateCommunityMemberMutation, CreateCommunityMemberMutationVariables>;

/**
 * __useCreateCommunityMemberMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMemberMutation, { data, loading, error }] = useCreateCommunityMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sendInvite: // value for 'sendInvite'
 *   },
 * });
 */
export function useCreateCommunityMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityMemberMutation, CreateCommunityMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityMemberMutation, CreateCommunityMemberMutationVariables>(CreateCommunityMemberDocument, options);
      }
export type CreateCommunityMemberMutationHookResult = ReturnType<typeof useCreateCommunityMemberMutation>;
export type CreateCommunityMemberMutationResult = Apollo.MutationResult<CreateCommunityMemberMutation>;
export type CreateCommunityMemberMutationOptions = Apollo.BaseMutationOptions<CreateCommunityMemberMutation, CreateCommunityMemberMutationVariables>;
export const CreateCommunityMembersDocument = gql`
    mutation createCommunityMembers($input: [CreateCommunityMembersInput]) {
  createCommunityMembers(input: $input) {
    member {
      id
      attributes {
        username
      }
    }
  }
}
    `;
export type CreateCommunityMembersMutationFn = Apollo.MutationFunction<CreateCommunityMembersMutation, CreateCommunityMembersMutationVariables>;

/**
 * __useCreateCommunityMembersMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMembersMutation, { data, loading, error }] = useCreateCommunityMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommunityMembersMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityMembersMutation, CreateCommunityMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityMembersMutation, CreateCommunityMembersMutationVariables>(CreateCommunityMembersDocument, options);
      }
export type CreateCommunityMembersMutationHookResult = ReturnType<typeof useCreateCommunityMembersMutation>;
export type CreateCommunityMembersMutationResult = Apollo.MutationResult<CreateCommunityMembersMutation>;
export type CreateCommunityMembersMutationOptions = Apollo.BaseMutationOptions<CreateCommunityMembersMutation, CreateCommunityMembersMutationVariables>;
export const CreateDataListDocument = gql`
    mutation createDataList($input: DataListInput!) {
  createDataList(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateDataListMutationFn = Apollo.MutationFunction<CreateDataListMutation, CreateDataListMutationVariables>;

/**
 * __useCreateDataListMutation__
 *
 * To run a mutation, you first call `useCreateDataListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataListMutation, { data, loading, error }] = useCreateDataListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataListMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataListMutation, CreateDataListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataListMutation, CreateDataListMutationVariables>(CreateDataListDocument, options);
      }
export type CreateDataListMutationHookResult = ReturnType<typeof useCreateDataListMutation>;
export type CreateDataListMutationResult = Apollo.MutationResult<CreateDataListMutation>;
export type CreateDataListMutationOptions = Apollo.BaseMutationOptions<CreateDataListMutation, CreateDataListMutationVariables>;
export const CreateDataListOptionDocument = gql`
    mutation createDataListOption($input: DataListOptionInput!) {
  createDataListOption(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateDataListOptionMutationFn = Apollo.MutationFunction<CreateDataListOptionMutation, CreateDataListOptionMutationVariables>;

/**
 * __useCreateDataListOptionMutation__
 *
 * To run a mutation, you first call `useCreateDataListOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataListOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataListOptionMutation, { data, loading, error }] = useCreateDataListOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataListOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataListOptionMutation, CreateDataListOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataListOptionMutation, CreateDataListOptionMutationVariables>(CreateDataListOptionDocument, options);
      }
export type CreateDataListOptionMutationHookResult = ReturnType<typeof useCreateDataListOptionMutation>;
export type CreateDataListOptionMutationResult = Apollo.MutationResult<CreateDataListOptionMutation>;
export type CreateDataListOptionMutationOptions = Apollo.BaseMutationOptions<CreateDataListOptionMutation, CreateDataListOptionMutationVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($input: ProjectInput) {
  createBaoleaderProject(input: $input) {
    id
    attributes {
      name
    }
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($input: TaskInput) {
  createBaoleaderTask(input: $input) {
    id
    attributes {
      createdAt
    }
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const DeactivateTaskTypeDocument = gql`
    mutation deactivateTaskType($id: ID!) {
  deactivateTaskType(id: $id) {
    id
  }
}
    `;
export type DeactivateTaskTypeMutationFn = Apollo.MutationFunction<DeactivateTaskTypeMutation, DeactivateTaskTypeMutationVariables>;

/**
 * __useDeactivateTaskTypeMutation__
 *
 * To run a mutation, you first call `useDeactivateTaskTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTaskTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTaskTypeMutation, { data, loading, error }] = useDeactivateTaskTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateTaskTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateTaskTypeMutation, DeactivateTaskTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateTaskTypeMutation, DeactivateTaskTypeMutationVariables>(DeactivateTaskTypeDocument, options);
      }
export type DeactivateTaskTypeMutationHookResult = ReturnType<typeof useDeactivateTaskTypeMutation>;
export type DeactivateTaskTypeMutationResult = Apollo.MutationResult<DeactivateTaskTypeMutation>;
export type DeactivateTaskTypeMutationOptions = Apollo.BaseMutationOptions<DeactivateTaskTypeMutation, DeactivateTaskTypeMutationVariables>;
export const DeleteBaoriderDocument = gql`
    mutation deleteBaorider($id: ID!) {
  removeBaorider(id: $id) {
    baorider {
      id
      attributes {
        username
      }
    }
  }
}
    `;
export type DeleteBaoriderMutationFn = Apollo.MutationFunction<DeleteBaoriderMutation, DeleteBaoriderMutationVariables>;

/**
 * __useDeleteBaoriderMutation__
 *
 * To run a mutation, you first call `useDeleteBaoriderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBaoriderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBaoriderMutation, { data, loading, error }] = useDeleteBaoriderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBaoriderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBaoriderMutation, DeleteBaoriderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBaoriderMutation, DeleteBaoriderMutationVariables>(DeleteBaoriderDocument, options);
      }
export type DeleteBaoriderMutationHookResult = ReturnType<typeof useDeleteBaoriderMutation>;
export type DeleteBaoriderMutationResult = Apollo.MutationResult<DeleteBaoriderMutation>;
export type DeleteBaoriderMutationOptions = Apollo.BaseMutationOptions<DeleteBaoriderMutation, DeleteBaoriderMutationVariables>;
export const DeleteCommunityMemberDocument = gql`
    mutation deleteCommunityMember($id: ID!) {
  removeCommunityMember(id: $id) {
    member {
      id
      attributes {
        username
      }
    }
  }
}
    `;
export type DeleteCommunityMemberMutationFn = Apollo.MutationFunction<DeleteCommunityMemberMutation, DeleteCommunityMemberMutationVariables>;

/**
 * __useDeleteCommunityMemberMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityMemberMutation, { data, loading, error }] = useDeleteCommunityMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommunityMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityMemberMutation, DeleteCommunityMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityMemberMutation, DeleteCommunityMemberMutationVariables>(DeleteCommunityMemberDocument, options);
      }
export type DeleteCommunityMemberMutationHookResult = ReturnType<typeof useDeleteCommunityMemberMutation>;
export type DeleteCommunityMemberMutationResult = Apollo.MutationResult<DeleteCommunityMemberMutation>;
export type DeleteCommunityMemberMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityMemberMutation, DeleteCommunityMemberMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($id: ID!) {
  deleteBaoleaderTask(id: $id) {
    id
    attributes {
      baorider {
        data {
          id
        }
      }
    }
  }
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const DeleteTasktypeDocument = gql`
    mutation deleteTasktype($id: ID!) {
  deleteTaskType(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteTasktypeMutationFn = Apollo.MutationFunction<DeleteTasktypeMutation, DeleteTasktypeMutationVariables>;

/**
 * __useDeleteTasktypeMutation__
 *
 * To run a mutation, you first call `useDeleteTasktypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTasktypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTasktypeMutation, { data, loading, error }] = useDeleteTasktypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTasktypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTasktypeMutation, DeleteTasktypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTasktypeMutation, DeleteTasktypeMutationVariables>(DeleteTasktypeDocument, options);
      }
export type DeleteTasktypeMutationHookResult = ReturnType<typeof useDeleteTasktypeMutation>;
export type DeleteTasktypeMutationResult = Apollo.MutationResult<DeleteTasktypeMutation>;
export type DeleteTasktypeMutationOptions = Apollo.BaseMutationOptions<DeleteTasktypeMutation, DeleteTasktypeMutationVariables>;
export const ExportExcelDocument = gql`
    mutation exportExcel($filterValues: JSON, $pagination: PaginationArg) {
  exportExcel(filterValues: $filterValues, pagination: $pagination)
}
    `;
export type ExportExcelMutationFn = Apollo.MutationFunction<ExportExcelMutation, ExportExcelMutationVariables>;

/**
 * __useExportExcelMutation__
 *
 * To run a mutation, you first call `useExportExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportExcelMutation, { data, loading, error }] = useExportExcelMutation({
 *   variables: {
 *      filterValues: // value for 'filterValues'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExportExcelMutation(baseOptions?: Apollo.MutationHookOptions<ExportExcelMutation, ExportExcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportExcelMutation, ExportExcelMutationVariables>(ExportExcelDocument, options);
      }
export type ExportExcelMutationHookResult = ReturnType<typeof useExportExcelMutation>;
export type ExportExcelMutationResult = Apollo.MutationResult<ExportExcelMutation>;
export type ExportExcelMutationOptions = Apollo.BaseMutationOptions<ExportExcelMutation, ExportExcelMutationVariables>;
export const RejectCommunityMemberRequestDocument = gql`
    mutation rejectCommunityMemberRequest($requestId: ID!) {
  rejectCommunityMemberRequest(requestId: $requestId) {
    id
    attributes {
      firstName
      lastName
    }
  }
}
    `;
export type RejectCommunityMemberRequestMutationFn = Apollo.MutationFunction<RejectCommunityMemberRequestMutation, RejectCommunityMemberRequestMutationVariables>;

/**
 * __useRejectCommunityMemberRequestMutation__
 *
 * To run a mutation, you first call `useRejectCommunityMemberRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCommunityMemberRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCommunityMemberRequestMutation, { data, loading, error }] = useRejectCommunityMemberRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRejectCommunityMemberRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectCommunityMemberRequestMutation, RejectCommunityMemberRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectCommunityMemberRequestMutation, RejectCommunityMemberRequestMutationVariables>(RejectCommunityMemberRequestDocument, options);
      }
export type RejectCommunityMemberRequestMutationHookResult = ReturnType<typeof useRejectCommunityMemberRequestMutation>;
export type RejectCommunityMemberRequestMutationResult = Apollo.MutationResult<RejectCommunityMemberRequestMutation>;
export type RejectCommunityMemberRequestMutationOptions = Apollo.BaseMutationOptions<RejectCommunityMemberRequestMutation, RejectCommunityMemberRequestMutationVariables>;
export const RemoveCompanyMemberDocument = gql`
    mutation removeCompanyMember($memberId: ID!) {
  removeCompanyMember(memberId: $memberId) {
    id
  }
}
    `;
export type RemoveCompanyMemberMutationFn = Apollo.MutationFunction<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>;

/**
 * __useRemoveCompanyMemberMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMemberMutation, { data, loading, error }] = useRemoveCompanyMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRemoveCompanyMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>(RemoveCompanyMemberDocument, options);
      }
export type RemoveCompanyMemberMutationHookResult = ReturnType<typeof useRemoveCompanyMemberMutation>;
export type RemoveCompanyMemberMutationResult = Apollo.MutationResult<RemoveCompanyMemberMutation>;
export type RemoveCompanyMemberMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyMemberMutation, RemoveCompanyMemberMutationVariables>;
export const RequestUpdateCompanyDetailsDocument = gql`
    mutation requestUpdateCompanyDetails($data: RequestCompanyDetailsChangeInput!) {
  requestUpdateCompanyDetails(data: $data) {
    name
  }
}
    `;
export type RequestUpdateCompanyDetailsMutationFn = Apollo.MutationFunction<RequestUpdateCompanyDetailsMutation, RequestUpdateCompanyDetailsMutationVariables>;

/**
 * __useRequestUpdateCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useRequestUpdateCompanyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUpdateCompanyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUpdateCompanyDetailsMutation, { data, loading, error }] = useRequestUpdateCompanyDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestUpdateCompanyDetailsMutation(baseOptions?: Apollo.MutationHookOptions<RequestUpdateCompanyDetailsMutation, RequestUpdateCompanyDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestUpdateCompanyDetailsMutation, RequestUpdateCompanyDetailsMutationVariables>(RequestUpdateCompanyDetailsDocument, options);
      }
export type RequestUpdateCompanyDetailsMutationHookResult = ReturnType<typeof useRequestUpdateCompanyDetailsMutation>;
export type RequestUpdateCompanyDetailsMutationResult = Apollo.MutationResult<RequestUpdateCompanyDetailsMutation>;
export type RequestUpdateCompanyDetailsMutationOptions = Apollo.BaseMutationOptions<RequestUpdateCompanyDetailsMutation, RequestUpdateCompanyDetailsMutationVariables>;
export const ResendDataCollectorInviteDocument = gql`
    mutation ResendDataCollectorInvite($id: ID!) {
  resendDataCollectorInvite(id: $id) {
    id
  }
}
    `;
export type ResendDataCollectorInviteMutationFn = Apollo.MutationFunction<ResendDataCollectorInviteMutation, ResendDataCollectorInviteMutationVariables>;

/**
 * __useResendDataCollectorInviteMutation__
 *
 * To run a mutation, you first call `useResendDataCollectorInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendDataCollectorInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendDataCollectorInviteMutation, { data, loading, error }] = useResendDataCollectorInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendDataCollectorInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendDataCollectorInviteMutation, ResendDataCollectorInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendDataCollectorInviteMutation, ResendDataCollectorInviteMutationVariables>(ResendDataCollectorInviteDocument, options);
      }
export type ResendDataCollectorInviteMutationHookResult = ReturnType<typeof useResendDataCollectorInviteMutation>;
export type ResendDataCollectorInviteMutationResult = Apollo.MutationResult<ResendDataCollectorInviteMutation>;
export type ResendDataCollectorInviteMutationOptions = Apollo.BaseMutationOptions<ResendDataCollectorInviteMutation, ResendDataCollectorInviteMutationVariables>;
export const SkipOnboardingStepDocument = gql`
    mutation skipOnboardingStep($index: String!, $completeOnboarding: Boolean) {
  skipOnboardingStep(index: $index, completeOnboarding: $completeOnboarding) {
    id
    attributes {
      username
    }
  }
}
    `;
export type SkipOnboardingStepMutationFn = Apollo.MutationFunction<SkipOnboardingStepMutation, SkipOnboardingStepMutationVariables>;

/**
 * __useSkipOnboardingStepMutation__
 *
 * To run a mutation, you first call `useSkipOnboardingStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipOnboardingStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipOnboardingStepMutation, { data, loading, error }] = useSkipOnboardingStepMutation({
 *   variables: {
 *      index: // value for 'index'
 *      completeOnboarding: // value for 'completeOnboarding'
 *   },
 * });
 */
export function useSkipOnboardingStepMutation(baseOptions?: Apollo.MutationHookOptions<SkipOnboardingStepMutation, SkipOnboardingStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SkipOnboardingStepMutation, SkipOnboardingStepMutationVariables>(SkipOnboardingStepDocument, options);
      }
export type SkipOnboardingStepMutationHookResult = ReturnType<typeof useSkipOnboardingStepMutation>;
export type SkipOnboardingStepMutationResult = Apollo.MutationResult<SkipOnboardingStepMutation>;
export type SkipOnboardingStepMutationOptions = Apollo.BaseMutationOptions<SkipOnboardingStepMutation, SkipOnboardingStepMutationVariables>;
export const CreateTaskDefinitionDocument = gql`
    mutation createTaskDefinition($input: TaskDefinitionInput) {
  createBaoleaderTaskDefinition(input: $input) {
    id
    attributes {
      category {
        data {
          id
        }
      }
      task_type {
        data {
          id
        }
      }
    }
  }
}
    `;
export type CreateTaskDefinitionMutationFn = Apollo.MutationFunction<CreateTaskDefinitionMutation, CreateTaskDefinitionMutationVariables>;

/**
 * __useCreateTaskDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateTaskDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskDefinitionMutation, { data, loading, error }] = useCreateTaskDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskDefinitionMutation, CreateTaskDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskDefinitionMutation, CreateTaskDefinitionMutationVariables>(CreateTaskDefinitionDocument, options);
      }
export type CreateTaskDefinitionMutationHookResult = ReturnType<typeof useCreateTaskDefinitionMutation>;
export type CreateTaskDefinitionMutationResult = Apollo.MutationResult<CreateTaskDefinitionMutation>;
export type CreateTaskDefinitionMutationOptions = Apollo.BaseMutationOptions<CreateTaskDefinitionMutation, CreateTaskDefinitionMutationVariables>;
export const UpdateBaoleaderTaskDefinitionDocument = gql`
    mutation updateBaoleaderTaskDefinition($id: ID!, $input: TaskDefinitionInput) {
  updateBaoleaderTaskDefinition(id: $id, input: $input) {
    id
    attributes {
      category {
        data {
          id
        }
      }
      task_type {
        data {
          id
        }
      }
    }
  }
}
    `;
export type UpdateBaoleaderTaskDefinitionMutationFn = Apollo.MutationFunction<UpdateBaoleaderTaskDefinitionMutation, UpdateBaoleaderTaskDefinitionMutationVariables>;

/**
 * __useUpdateBaoleaderTaskDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateBaoleaderTaskDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaoleaderTaskDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaoleaderTaskDefinitionMutation, { data, loading, error }] = useUpdateBaoleaderTaskDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBaoleaderTaskDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBaoleaderTaskDefinitionMutation, UpdateBaoleaderTaskDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBaoleaderTaskDefinitionMutation, UpdateBaoleaderTaskDefinitionMutationVariables>(UpdateBaoleaderTaskDefinitionDocument, options);
      }
export type UpdateBaoleaderTaskDefinitionMutationHookResult = ReturnType<typeof useUpdateBaoleaderTaskDefinitionMutation>;
export type UpdateBaoleaderTaskDefinitionMutationResult = Apollo.MutationResult<UpdateBaoleaderTaskDefinitionMutation>;
export type UpdateBaoleaderTaskDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateBaoleaderTaskDefinitionMutation, UpdateBaoleaderTaskDefinitionMutationVariables>;
export const DeleteBaoleaderTaskDefinitionDocument = gql`
    mutation deleteBaoleaderTaskDefinition($id: ID!) {
  deleteBaoleaderTaskDefinition(id: $id) {
    id
    attributes {
      category {
        data {
          id
        }
      }
      task_type {
        data {
          id
        }
      }
    }
  }
}
    `;
export type DeleteBaoleaderTaskDefinitionMutationFn = Apollo.MutationFunction<DeleteBaoleaderTaskDefinitionMutation, DeleteBaoleaderTaskDefinitionMutationVariables>;

/**
 * __useDeleteBaoleaderTaskDefinitionMutation__
 *
 * To run a mutation, you first call `useDeleteBaoleaderTaskDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBaoleaderTaskDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBaoleaderTaskDefinitionMutation, { data, loading, error }] = useDeleteBaoleaderTaskDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBaoleaderTaskDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBaoleaderTaskDefinitionMutation, DeleteBaoleaderTaskDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBaoleaderTaskDefinitionMutation, DeleteBaoleaderTaskDefinitionMutationVariables>(DeleteBaoleaderTaskDefinitionDocument, options);
      }
export type DeleteBaoleaderTaskDefinitionMutationHookResult = ReturnType<typeof useDeleteBaoleaderTaskDefinitionMutation>;
export type DeleteBaoleaderTaskDefinitionMutationResult = Apollo.MutationResult<DeleteBaoleaderTaskDefinitionMutation>;
export type DeleteBaoleaderTaskDefinitionMutationOptions = Apollo.BaseMutationOptions<DeleteBaoleaderTaskDefinitionMutation, DeleteBaoleaderTaskDefinitionMutationVariables>;
export const CreateTaskTypeDocument = gql`
    mutation createTaskType($input: TaskTypeInput!, $dataFieldsArr: JSON) {
  createBaoleaderTaskType(input: $input, dataFieldsArr: $dataFieldsArr) {
    id
    attributes {
      ...taskType
      category {
        data {
          id
        }
      }
    }
  }
}
    ${TaskTypeFragmentDoc}`;
export type CreateTaskTypeMutationFn = Apollo.MutationFunction<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>;

/**
 * __useCreateTaskTypeMutation__
 *
 * To run a mutation, you first call `useCreateTaskTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskTypeMutation, { data, loading, error }] = useCreateTaskTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      dataFieldsArr: // value for 'dataFieldsArr'
 *   },
 * });
 */
export function useCreateTaskTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>(CreateTaskTypeDocument, options);
      }
export type CreateTaskTypeMutationHookResult = ReturnType<typeof useCreateTaskTypeMutation>;
export type CreateTaskTypeMutationResult = Apollo.MutationResult<CreateTaskTypeMutation>;
export type CreateTaskTypeMutationOptions = Apollo.BaseMutationOptions<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>;
export const UnarchiveCompanyMemberDocument = gql`
    mutation unarchiveCompanyMember($memberId: ID!) {
  unarchiveCompanyMember(memberId: $memberId) {
    id
  }
}
    `;
export type UnarchiveCompanyMemberMutationFn = Apollo.MutationFunction<UnarchiveCompanyMemberMutation, UnarchiveCompanyMemberMutationVariables>;

/**
 * __useUnarchiveCompanyMemberMutation__
 *
 * To run a mutation, you first call `useUnarchiveCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveCompanyMemberMutation, { data, loading, error }] = useUnarchiveCompanyMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useUnarchiveCompanyMemberMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveCompanyMemberMutation, UnarchiveCompanyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveCompanyMemberMutation, UnarchiveCompanyMemberMutationVariables>(UnarchiveCompanyMemberDocument, options);
      }
export type UnarchiveCompanyMemberMutationHookResult = ReturnType<typeof useUnarchiveCompanyMemberMutation>;
export type UnarchiveCompanyMemberMutationResult = Apollo.MutationResult<UnarchiveCompanyMemberMutation>;
export type UnarchiveCompanyMemberMutationOptions = Apollo.BaseMutationOptions<UnarchiveCompanyMemberMutation, UnarchiveCompanyMemberMutationVariables>;
export const UpdateBaoriderDocument = gql`
    mutation updateBaorider($id: ID!, $baorider: UpdateBaoriderInput) {
  updateBaorider(id: $id, baorider: $baorider) {
    baorider {
      id
    }
  }
}
    `;
export type UpdateBaoriderMutationFn = Apollo.MutationFunction<UpdateBaoriderMutation, UpdateBaoriderMutationVariables>;

/**
 * __useUpdateBaoriderMutation__
 *
 * To run a mutation, you first call `useUpdateBaoriderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaoriderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaoriderMutation, { data, loading, error }] = useUpdateBaoriderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      baorider: // value for 'baorider'
 *   },
 * });
 */
export function useUpdateBaoriderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBaoriderMutation, UpdateBaoriderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBaoriderMutation, UpdateBaoriderMutationVariables>(UpdateBaoriderDocument, options);
      }
export type UpdateBaoriderMutationHookResult = ReturnType<typeof useUpdateBaoriderMutation>;
export type UpdateBaoriderMutationResult = Apollo.MutationResult<UpdateBaoriderMutation>;
export type UpdateBaoriderMutationOptions = Apollo.BaseMutationOptions<UpdateBaoriderMutation, UpdateBaoriderMutationVariables>;
export const UpdateCommunityDocument = gql`
    mutation updateCommunity($id: ID!, $input: CommunityInput) {
  updateBaoleaderCommunity(id: $id, input: $input) {
    id
    attributes {
      name
    }
  }
}
    `;
export type UpdateCommunityMutationFn = Apollo.MutationFunction<UpdateCommunityMutation, UpdateCommunityMutationVariables>;

/**
 * __useUpdateCommunityMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMutation, { data, loading, error }] = useUpdateCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityMutation, UpdateCommunityMutationVariables>(UpdateCommunityDocument, options);
      }
export type UpdateCommunityMutationHookResult = ReturnType<typeof useUpdateCommunityMutation>;
export type UpdateCommunityMutationResult = Apollo.MutationResult<UpdateCommunityMutation>;
export type UpdateCommunityMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityMutation, UpdateCommunityMutationVariables>;
export const UpdateCommunityMemberDocument = gql`
    mutation updateCommunityMember($id: ID!, $input: UpdateCommunityMembersInput) {
  updateCommunityMember(id: $id, input: $input) {
    member {
      id
      attributes {
        username
      }
    }
  }
}
    `;
export type UpdateCommunityMemberMutationFn = Apollo.MutationFunction<UpdateCommunityMemberMutation, UpdateCommunityMemberMutationVariables>;

/**
 * __useUpdateCommunityMemberMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMemberMutation, { data, loading, error }] = useUpdateCommunityMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityMemberMutation, UpdateCommunityMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityMemberMutation, UpdateCommunityMemberMutationVariables>(UpdateCommunityMemberDocument, options);
      }
export type UpdateCommunityMemberMutationHookResult = ReturnType<typeof useUpdateCommunityMemberMutation>;
export type UpdateCommunityMemberMutationResult = Apollo.MutationResult<UpdateCommunityMemberMutation>;
export type UpdateCommunityMemberMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityMemberMutation, UpdateCommunityMemberMutationVariables>;
export const UpdateDataListDocument = gql`
    mutation updateDataList($input: DataListInput!, $id: ID!) {
  updateDataList(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateDataListMutationFn = Apollo.MutationFunction<UpdateDataListMutation, UpdateDataListMutationVariables>;

/**
 * __useUpdateDataListMutation__
 *
 * To run a mutation, you first call `useUpdateDataListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataListMutation, { data, loading, error }] = useUpdateDataListMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDataListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataListMutation, UpdateDataListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataListMutation, UpdateDataListMutationVariables>(UpdateDataListDocument, options);
      }
export type UpdateDataListMutationHookResult = ReturnType<typeof useUpdateDataListMutation>;
export type UpdateDataListMutationResult = Apollo.MutationResult<UpdateDataListMutation>;
export type UpdateDataListMutationOptions = Apollo.BaseMutationOptions<UpdateDataListMutation, UpdateDataListMutationVariables>;
export const UpdateDataListOptionDocument = gql`
    mutation updateDataListOption($input: DataListOptionInput!, $id: ID!) {
  updateDataListOption(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateDataListOptionMutationFn = Apollo.MutationFunction<UpdateDataListOptionMutation, UpdateDataListOptionMutationVariables>;

/**
 * __useUpdateDataListOptionMutation__
 *
 * To run a mutation, you first call `useUpdateDataListOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataListOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataListOptionMutation, { data, loading, error }] = useUpdateDataListOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDataListOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataListOptionMutation, UpdateDataListOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataListOptionMutation, UpdateDataListOptionMutationVariables>(UpdateDataListOptionDocument, options);
      }
export type UpdateDataListOptionMutationHookResult = ReturnType<typeof useUpdateDataListOptionMutation>;
export type UpdateDataListOptionMutationResult = Apollo.MutationResult<UpdateDataListOptionMutation>;
export type UpdateDataListOptionMutationOptions = Apollo.BaseMutationOptions<UpdateDataListOptionMutation, UpdateDataListOptionMutationVariables>;
export const UpdateEvidenceDocument = gql`
    mutation updateEvidence($id: ID!, $input: EvidenceInput) {
  updateBaoleaderEvidence(id: $id, input: $input) {
    notes
  }
}
    `;
export type UpdateEvidenceMutationFn = Apollo.MutationFunction<UpdateEvidenceMutation, UpdateEvidenceMutationVariables>;

/**
 * __useUpdateEvidenceMutation__
 *
 * To run a mutation, you first call `useUpdateEvidenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvidenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvidenceMutation, { data, loading, error }] = useUpdateEvidenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEvidenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEvidenceMutation, UpdateEvidenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEvidenceMutation, UpdateEvidenceMutationVariables>(UpdateEvidenceDocument, options);
      }
export type UpdateEvidenceMutationHookResult = ReturnType<typeof useUpdateEvidenceMutation>;
export type UpdateEvidenceMutationResult = Apollo.MutationResult<UpdateEvidenceMutation>;
export type UpdateEvidenceMutationOptions = Apollo.BaseMutationOptions<UpdateEvidenceMutation, UpdateEvidenceMutationVariables>;
export const UpdateMyUserDocument = gql`
    mutation updateMyUser($input: updateMyUserInput) {
  updateMyUser(input: $input) {
    id
  }
}
    `;
export type UpdateMyUserMutationFn = Apollo.MutationFunction<UpdateMyUserMutation, UpdateMyUserMutationVariables>;

/**
 * __useUpdateMyUserMutation__
 *
 * To run a mutation, you first call `useUpdateMyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyUserMutation, { data, loading, error }] = useUpdateMyUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyUserMutation, UpdateMyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyUserMutation, UpdateMyUserMutationVariables>(UpdateMyUserDocument, options);
      }
export type UpdateMyUserMutationHookResult = ReturnType<typeof useUpdateMyUserMutation>;
export type UpdateMyUserMutationResult = Apollo.MutationResult<UpdateMyUserMutation>;
export type UpdateMyUserMutationOptions = Apollo.BaseMutationOptions<UpdateMyUserMutation, UpdateMyUserMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($id: ID!, $input: ProjectInput) {
  updateBaoleaderProject(id: $id, input: $input) {
    id
    attributes {
      name
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: ID!, $input: TaskInput!) {
  updateBaoleaderTask(id: $id, input: $input) {
    id
    attributes {
      createdAt
    }
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const UpdateTaskTypeDocument = gql`
    mutation updateTaskType($id: ID!, $input: TaskTypeInput!, $dataFieldsArr: JSON) {
  updateBaoleaderTaskType(id: $id, input: $input, dataFieldsArr: $dataFieldsArr) {
    id
    attributes {
      title
    }
  }
}
    `;
export type UpdateTaskTypeMutationFn = Apollo.MutationFunction<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>;

/**
 * __useUpdateTaskTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTaskTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskTypeMutation, { data, loading, error }] = useUpdateTaskTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      dataFieldsArr: // value for 'dataFieldsArr'
 *   },
 * });
 */
export function useUpdateTaskTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>(UpdateTaskTypeDocument, options);
      }
export type UpdateTaskTypeMutationHookResult = ReturnType<typeof useUpdateTaskTypeMutation>;
export type UpdateTaskTypeMutationResult = Apollo.MutationResult<UpdateTaskTypeMutation>;
export type UpdateTaskTypeMutationOptions = Apollo.BaseMutationOptions<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>;
export const UpdateVideoDocument = gql`
    mutation updateVideo($input: VideoInput!, $id: ID!) {
  updateVideo(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateVideoMutationFn = Apollo.MutationFunction<UpdateVideoMutation, UpdateVideoMutationVariables>;

/**
 * __useUpdateVideoMutation__
 *
 * To run a mutation, you first call `useUpdateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoMutation, { data, loading, error }] = useUpdateVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoMutation, UpdateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(UpdateVideoDocument, options);
      }
export type UpdateVideoMutationHookResult = ReturnType<typeof useUpdateVideoMutation>;
export type UpdateVideoMutationResult = Apollo.MutationResult<UpdateVideoMutation>;
export type UpdateVideoMutationOptions = Apollo.BaseMutationOptions<UpdateVideoMutation, UpdateVideoMutationVariables>;
export const AnalyticsDocument = gql`
    query Analytics {
  analytic {
    data {
      id
      attributes {
        ...analytic_fragment
      }
    }
  }
}
    ${Analytic_FragmentFragmentDoc}`;

/**
 * __useAnalyticsQuery__
 *
 * To run a query within a React component, call `useAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, options);
      }
export function useAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, options);
        }
export type AnalyticsQueryHookResult = ReturnType<typeof useAnalyticsQuery>;
export type AnalyticsLazyQueryHookResult = ReturnType<typeof useAnalyticsLazyQuery>;
export type AnalyticsQueryResult = Apollo.QueryResult<AnalyticsQuery, AnalyticsQueryVariables>;
export const BaoverifierAppDocument = gql`
    query BaoverifierApp {
  baoverifierApp {
    data {
      id
    }
  }
}
    `;

/**
 * __useBaoverifierAppQuery__
 *
 * To run a query within a React component, call `useBaoverifierAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaoverifierAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaoverifierAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useBaoverifierAppQuery(baseOptions?: Apollo.QueryHookOptions<BaoverifierAppQuery, BaoverifierAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaoverifierAppQuery, BaoverifierAppQueryVariables>(BaoverifierAppDocument, options);
      }
export function useBaoverifierAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaoverifierAppQuery, BaoverifierAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaoverifierAppQuery, BaoverifierAppQueryVariables>(BaoverifierAppDocument, options);
        }
export type BaoverifierAppQueryHookResult = ReturnType<typeof useBaoverifierAppQuery>;
export type BaoverifierAppLazyQueryHookResult = ReturnType<typeof useBaoverifierAppLazyQuery>;
export type BaoverifierAppQueryResult = Apollo.QueryResult<BaoverifierAppQuery, BaoverifierAppQueryVariables>;
export const CountCommunityMembersDocument = gql`
    query countCommunityMembers($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  countCommunityMembers(sort: $sort, filters: $filters, pagination: $pagination)
}
    `;

/**
 * __useCountCommunityMembersQuery__
 *
 * To run a query within a React component, call `useCountCommunityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCommunityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCommunityMembersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCountCommunityMembersQuery(baseOptions?: Apollo.QueryHookOptions<CountCommunityMembersQuery, CountCommunityMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCommunityMembersQuery, CountCommunityMembersQueryVariables>(CountCommunityMembersDocument, options);
      }
export function useCountCommunityMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCommunityMembersQuery, CountCommunityMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCommunityMembersQuery, CountCommunityMembersQueryVariables>(CountCommunityMembersDocument, options);
        }
export type CountCommunityMembersQueryHookResult = ReturnType<typeof useCountCommunityMembersQuery>;
export type CountCommunityMembersLazyQueryHookResult = ReturnType<typeof useCountCommunityMembersLazyQuery>;
export type CountCommunityMembersQueryResult = Apollo.QueryResult<CountCommunityMembersQuery, CountCommunityMembersQueryVariables>;
export const CountCompanyCommunitiesDocument = gql`
    query countCompanyCommunities($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  countCompanyCommunities(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination)
}
    `;

/**
 * __useCountCompanyCommunitiesQuery__
 *
 * To run a query within a React component, call `useCountCompanyCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCompanyCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCompanyCommunitiesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCountCompanyCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<CountCompanyCommunitiesQuery, CountCompanyCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCompanyCommunitiesQuery, CountCompanyCommunitiesQueryVariables>(CountCompanyCommunitiesDocument, options);
      }
export function useCountCompanyCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCompanyCommunitiesQuery, CountCompanyCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCompanyCommunitiesQuery, CountCompanyCommunitiesQueryVariables>(CountCompanyCommunitiesDocument, options);
        }
export type CountCompanyCommunitiesQueryHookResult = ReturnType<typeof useCountCompanyCommunitiesQuery>;
export type CountCompanyCommunitiesLazyQueryHookResult = ReturnType<typeof useCountCompanyCommunitiesLazyQuery>;
export type CountCompanyCommunitiesQueryResult = Apollo.QueryResult<CountCompanyCommunitiesQuery, CountCompanyCommunitiesQueryVariables>;
export const CountCompanyMembersDocument = gql`
    query countCompanyMembers {
  countCompanyMembers
}
    `;

/**
 * __useCountCompanyMembersQuery__
 *
 * To run a query within a React component, call `useCountCompanyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCompanyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCompanyMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountCompanyMembersQuery(baseOptions?: Apollo.QueryHookOptions<CountCompanyMembersQuery, CountCompanyMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCompanyMembersQuery, CountCompanyMembersQueryVariables>(CountCompanyMembersDocument, options);
      }
export function useCountCompanyMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCompanyMembersQuery, CountCompanyMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCompanyMembersQuery, CountCompanyMembersQueryVariables>(CountCompanyMembersDocument, options);
        }
export type CountCompanyMembersQueryHookResult = ReturnType<typeof useCountCompanyMembersQuery>;
export type CountCompanyMembersLazyQueryHookResult = ReturnType<typeof useCountCompanyMembersLazyQuery>;
export type CountCompanyMembersQueryResult = Apollo.QueryResult<CountCompanyMembersQuery, CountCompanyMembersQueryVariables>;
export const CountCompanyProjectsDocument = gql`
    query countCompanyProjects($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  countCompanyProjects(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination)
}
    `;

/**
 * __useCountCompanyProjectsQuery__
 *
 * To run a query within a React component, call `useCountCompanyProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCompanyProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCompanyProjectsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCountCompanyProjectsQuery(baseOptions?: Apollo.QueryHookOptions<CountCompanyProjectsQuery, CountCompanyProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountCompanyProjectsQuery, CountCompanyProjectsQueryVariables>(CountCompanyProjectsDocument, options);
      }
export function useCountCompanyProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountCompanyProjectsQuery, CountCompanyProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountCompanyProjectsQuery, CountCompanyProjectsQueryVariables>(CountCompanyProjectsDocument, options);
        }
export type CountCompanyProjectsQueryHookResult = ReturnType<typeof useCountCompanyProjectsQuery>;
export type CountCompanyProjectsLazyQueryHookResult = ReturnType<typeof useCountCompanyProjectsLazyQuery>;
export type CountCompanyProjectsQueryResult = Apollo.QueryResult<CountCompanyProjectsQuery, CountCompanyProjectsQueryVariables>;
export const CountRidersDocument = gql`
    query countRiders($sort: String, $limit: Int, $start: Int, $where: JSON) {
  countRiders(sort: $sort, limit: $limit, start: $start, where: $where)
}
    `;

/**
 * __useCountRidersQuery__
 *
 * To run a query within a React component, call `useCountRidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountRidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountRidersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountRidersQuery(baseOptions?: Apollo.QueryHookOptions<CountRidersQuery, CountRidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountRidersQuery, CountRidersQueryVariables>(CountRidersDocument, options);
      }
export function useCountRidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountRidersQuery, CountRidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountRidersQuery, CountRidersQueryVariables>(CountRidersDocument, options);
        }
export type CountRidersQueryHookResult = ReturnType<typeof useCountRidersQuery>;
export type CountRidersLazyQueryHookResult = ReturnType<typeof useCountRidersLazyQuery>;
export type CountRidersQueryResult = Apollo.QueryResult<CountRidersQuery, CountRidersQueryVariables>;
export const CountTaskTypesDocument = gql`
    query countTaskTypes($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getCompanyTaskTypesCount(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination)
}
    `;

/**
 * __useCountTaskTypesQuery__
 *
 * To run a query within a React component, call `useCountTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountTaskTypesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCountTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<CountTaskTypesQuery, CountTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountTaskTypesQuery, CountTaskTypesQueryVariables>(CountTaskTypesDocument, options);
      }
export function useCountTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountTaskTypesQuery, CountTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountTaskTypesQuery, CountTaskTypesQueryVariables>(CountTaskTypesDocument, options);
        }
export type CountTaskTypesQueryHookResult = ReturnType<typeof useCountTaskTypesQuery>;
export type CountTaskTypesLazyQueryHookResult = ReturnType<typeof useCountTaskTypesLazyQuery>;
export type CountTaskTypesQueryResult = Apollo.QueryResult<CountTaskTypesQuery, CountTaskTypesQueryVariables>;
export const CountTasksDocument = gql`
    query countTasks($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  countTasks(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination)
}
    `;

/**
 * __useCountTasksQuery__
 *
 * To run a query within a React component, call `useCountTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountTasksQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCountTasksQuery(baseOptions?: Apollo.QueryHookOptions<CountTasksQuery, CountTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountTasksQuery, CountTasksQueryVariables>(CountTasksDocument, options);
      }
export function useCountTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountTasksQuery, CountTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountTasksQuery, CountTasksQueryVariables>(CountTasksDocument, options);
        }
export type CountTasksQueryHookResult = ReturnType<typeof useCountTasksQuery>;
export type CountTasksLazyQueryHookResult = ReturnType<typeof useCountTasksLazyQuery>;
export type CountTasksQueryResult = Apollo.QueryResult<CountTasksQuery, CountTasksQueryVariables>;
export const FindDataListOptionWithCountDocument = gql`
    query findDataListOptionWithCount($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  findDataListOptionWithCount(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    count
    data {
      id
      attributes {
        ...dataListOption
        data_list {
          data {
            id
            attributes {
              ...dataLists
            }
          }
        }
      }
    }
  }
}
    ${DataListOptionFragmentDoc}
${DataListsFragmentDoc}`;

/**
 * __useFindDataListOptionWithCountQuery__
 *
 * To run a query within a React component, call `useFindDataListOptionWithCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDataListOptionWithCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDataListOptionWithCountQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindDataListOptionWithCountQuery(baseOptions?: Apollo.QueryHookOptions<FindDataListOptionWithCountQuery, FindDataListOptionWithCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindDataListOptionWithCountQuery, FindDataListOptionWithCountQueryVariables>(FindDataListOptionWithCountDocument, options);
      }
export function useFindDataListOptionWithCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindDataListOptionWithCountQuery, FindDataListOptionWithCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindDataListOptionWithCountQuery, FindDataListOptionWithCountQueryVariables>(FindDataListOptionWithCountDocument, options);
        }
export type FindDataListOptionWithCountQueryHookResult = ReturnType<typeof useFindDataListOptionWithCountQuery>;
export type FindDataListOptionWithCountLazyQueryHookResult = ReturnType<typeof useFindDataListOptionWithCountLazyQuery>;
export type FindDataListOptionWithCountQueryResult = Apollo.QueryResult<FindDataListOptionWithCountQuery, FindDataListOptionWithCountQueryVariables>;
export const GetAllInvoicesDocument = gql`
    query getAllInvoices {
  getAllInvoices {
    id
    currency
    customer
    created
    ending_balance
    hosted_invoice_url
    invoice_pdf
    paid
    status
    subscription
    lines
    number
  }
}
    `;

/**
 * __useGetAllInvoicesQuery__
 *
 * To run a query within a React component, call `useGetAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(GetAllInvoicesDocument, options);
      }
export function useGetAllInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(GetAllInvoicesDocument, options);
        }
export type GetAllInvoicesQueryHookResult = ReturnType<typeof useGetAllInvoicesQuery>;
export type GetAllInvoicesLazyQueryHookResult = ReturnType<typeof useGetAllInvoicesLazyQuery>;
export type GetAllInvoicesQueryResult = Apollo.QueryResult<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>;
export const GetAllProductsDocument = gql`
    query getAllProducts {
  getAllProducts {
    id
    name
    statement_descriptor
    metadata {
      baoriders_allowed
      baoriders_cost
    }
    prices {
      id
      nickname
      currency
      recurring {
        interval
        interval_count
      }
      unit_amount
      unit_amount_decimal
    }
  }
}
    `;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
      }
export function useGetAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
        }
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = Apollo.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetBaoleaderProjectsCategoriesDocument = gql`
    query getBaoleaderProjectsCategories {
  getBaoleaderProjectsCategories {
    id
    title
  }
}
    `;

/**
 * __useGetBaoleaderProjectsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBaoleaderProjectsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaoleaderProjectsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaoleaderProjectsCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaoleaderProjectsCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetBaoleaderProjectsCategoriesQuery, GetBaoleaderProjectsCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaoleaderProjectsCategoriesQuery, GetBaoleaderProjectsCategoriesQueryVariables>(GetBaoleaderProjectsCategoriesDocument, options);
      }
export function useGetBaoleaderProjectsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaoleaderProjectsCategoriesQuery, GetBaoleaderProjectsCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaoleaderProjectsCategoriesQuery, GetBaoleaderProjectsCategoriesQueryVariables>(GetBaoleaderProjectsCategoriesDocument, options);
        }
export type GetBaoleaderProjectsCategoriesQueryHookResult = ReturnType<typeof useGetBaoleaderProjectsCategoriesQuery>;
export type GetBaoleaderProjectsCategoriesLazyQueryHookResult = ReturnType<typeof useGetBaoleaderProjectsCategoriesLazyQuery>;
export type GetBaoleaderProjectsCategoriesQueryResult = Apollo.QueryResult<GetBaoleaderProjectsCategoriesQuery, GetBaoleaderProjectsCategoriesQueryVariables>;
export const GetBaoleaderTaskDocument = gql`
    query getBaoleaderTask($id: ID!) {
  getBaoleaderTask(id: $id) {
    id
    attributes {
      createdAt
      updatedAt
      status
      date_reported
      documents {
        data {
          ...uploadFile
        }
      }
      location
      onlyEmployeesVerify
      task_origin
      task_definition {
        data {
          id
          attributes {
            category {
              data {
                id
                attributes {
                  ...category
                  featureImage {
                    data {
                      ...uploadFile
                    }
                  }
                }
              }
            }
            task_type {
              data {
                id
                attributes {
                  ...taskType
                }
              }
            }
          }
        }
      }
      community {
        data {
          id
          attributes {
            ...community
            featured_image {
              data {
                ...uploadFile
              }
            }
          }
        }
      }
      project {
        data {
          id
          attributes {
            ...project
            featured_image {
              data {
                ...uploadFile
              }
            }
          }
        }
      }
      evidences {
        data {
          id
          attributes {
            ...evidence
            custom_data_fields {
              ...componentCustomDataFieldsMetricsSpecific
              ...componentCustomDataFieldsNumberRange
              ...componentCustomDataFieldsNumberDropDown
              ...componentCustomDataFieldsPercentage
              ...componentCustomDataFieldsRadio
              ...componentCustomDataFieldsText
              ...componentCustomDataFieldsCheckbox
              ...componentCustomDataFieldsImageField
              ...componentCustomDataFieldsDateTimeField
              ...componentCustomDataFieldsSelect
              ...componentCustomDataFieldsNumberField
              ...componentCustomDataFieldsPercentage
              ...componentCustomDataFieldsRating
              ...componentCustomDataFieldsDataList
              ...componentCustomDataFieldsPhoneNumberField
              ...componentCustomDataFieldsEmailField
              __typename
            }
            photos {
              data {
                ...uploadFile
              }
            }
            data_field_values {
              data {
                id
                attributes {
                  value
                  data_field {
                    data {
                      id
                      attributes {
                        label
                      }
                    }
                  }
                  baorider {
                    data {
                      id
                    }
                  }
                }
              }
            }
            baorider {
              data {
                id
                attributes {
                  ...member
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${UploadFileFragmentDoc}
${CategoryFragmentDoc}
${TaskTypeFragmentDoc}
${CommunityFragmentDoc}
${ProjectFragmentDoc}
${EvidenceFragmentDoc}
${ComponentCustomDataFieldsMetricsSpecificFragmentDoc}
${ComponentCustomDataFieldsNumberRangeFragmentDoc}
${ComponentCustomDataFieldsNumberDropDownFragmentDoc}
${ComponentCustomDataFieldsPercentageFragmentDoc}
${ComponentCustomDataFieldsRadioFragmentDoc}
${ComponentCustomDataFieldsTextFragmentDoc}
${ComponentCustomDataFieldsCheckboxFragmentDoc}
${ComponentCustomDataFieldsImageFieldFragmentDoc}
${ComponentCustomDataFieldsDateTimeFieldFragmentDoc}
${ComponentCustomDataFieldsSelectFragmentDoc}
${ComponentCustomDataFieldsNumberFieldFragmentDoc}
${ComponentCustomDataFieldsRatingFragmentDoc}
${ComponentCustomDataFieldsDataListFragmentDoc}
${ComponentCustomDataFieldsPhoneNumberFieldFragmentDoc}
${ComponentCustomDataFieldsEmailFieldFragmentDoc}
${MemberFragmentDoc}`;

/**
 * __useGetBaoleaderTaskQuery__
 *
 * To run a query within a React component, call `useGetBaoleaderTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaoleaderTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaoleaderTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBaoleaderTaskQuery(baseOptions: Apollo.QueryHookOptions<GetBaoleaderTaskQuery, GetBaoleaderTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaoleaderTaskQuery, GetBaoleaderTaskQueryVariables>(GetBaoleaderTaskDocument, options);
      }
export function useGetBaoleaderTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaoleaderTaskQuery, GetBaoleaderTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaoleaderTaskQuery, GetBaoleaderTaskQueryVariables>(GetBaoleaderTaskDocument, options);
        }
export type GetBaoleaderTaskQueryHookResult = ReturnType<typeof useGetBaoleaderTaskQuery>;
export type GetBaoleaderTaskLazyQueryHookResult = ReturnType<typeof useGetBaoleaderTaskLazyQuery>;
export type GetBaoleaderTaskQueryResult = Apollo.QueryResult<GetBaoleaderTaskQuery, GetBaoleaderTaskQueryVariables>;
export const GetBaoleaderTaskDefinitionsCountDocument = gql`
    query getBaoleaderTaskDefinitionsCount($filters: JSON) {
  getBaoleaderTaskDefinitionsCount(filters: $filters)
}
    `;

/**
 * __useGetBaoleaderTaskDefinitionsCountQuery__
 *
 * To run a query within a React component, call `useGetBaoleaderTaskDefinitionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaoleaderTaskDefinitionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaoleaderTaskDefinitionsCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetBaoleaderTaskDefinitionsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetBaoleaderTaskDefinitionsCountQuery, GetBaoleaderTaskDefinitionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaoleaderTaskDefinitionsCountQuery, GetBaoleaderTaskDefinitionsCountQueryVariables>(GetBaoleaderTaskDefinitionsCountDocument, options);
      }
export function useGetBaoleaderTaskDefinitionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaoleaderTaskDefinitionsCountQuery, GetBaoleaderTaskDefinitionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaoleaderTaskDefinitionsCountQuery, GetBaoleaderTaskDefinitionsCountQueryVariables>(GetBaoleaderTaskDefinitionsCountDocument, options);
        }
export type GetBaoleaderTaskDefinitionsCountQueryHookResult = ReturnType<typeof useGetBaoleaderTaskDefinitionsCountQuery>;
export type GetBaoleaderTaskDefinitionsCountLazyQueryHookResult = ReturnType<typeof useGetBaoleaderTaskDefinitionsCountLazyQuery>;
export type GetBaoleaderTaskDefinitionsCountQueryResult = Apollo.QueryResult<GetBaoleaderTaskDefinitionsCountQuery, GetBaoleaderTaskDefinitionsCountQueryVariables>;
export const GetBaoridersDocument = gql`
    query getBaoriders($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getBaoriders(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      idNumber
      phoneNumber
      firstName
      isVerifier
      lastName
      profilePicture {
        data {
          ...uploadFile
        }
      }
      projects {
        data {
          id
          attributes {
            ...project
          }
        }
      }
      community {
        data {
          id
          attributes {
            ...community
          }
        }
      }
      status
      createdAt
      updatedAt
    }
  }
}
    ${UploadFileFragmentDoc}
${ProjectFragmentDoc}
${CommunityFragmentDoc}`;

/**
 * __useGetBaoridersQuery__
 *
 * To run a query within a React component, call `useGetBaoridersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaoridersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaoridersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetBaoridersQuery(baseOptions?: Apollo.QueryHookOptions<GetBaoridersQuery, GetBaoridersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaoridersQuery, GetBaoridersQueryVariables>(GetBaoridersDocument, options);
      }
export function useGetBaoridersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaoridersQuery, GetBaoridersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaoridersQuery, GetBaoridersQueryVariables>(GetBaoridersDocument, options);
        }
export type GetBaoridersQueryHookResult = ReturnType<typeof useGetBaoridersQuery>;
export type GetBaoridersLazyQueryHookResult = ReturnType<typeof useGetBaoridersLazyQuery>;
export type GetBaoridersQueryResult = Apollo.QueryResult<GetBaoridersQuery, GetBaoridersQueryVariables>;
export const GetCategoriesDocument = gql`
    query getCategories($sort: [String], $filters: CategoryFiltersInput, $pagination: PaginationArg) {
  categories(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    data {
      id
      attributes {
        title
        description
        featureImage {
          data {
            ...uploadFile
          }
        }
        task_types {
          data {
            id
            attributes {
              ...taskType
            }
          }
        }
      }
    }
  }
}
    ${UploadFileFragmentDoc}
${TaskTypeFragmentDoc}`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetCommunityVerifiersDocument = gql`
    query getCommunityVerifiers($communityId: ID!) {
  getCommunityVerifiers(community: $communityId) {
    members {
      id
      attributes {
        ...member
      }
    }
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetCommunityVerifiersQuery__
 *
 * To run a query within a React component, call `useGetCommunityVerifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityVerifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityVerifiersQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityVerifiersQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityVerifiersQuery, GetCommunityVerifiersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityVerifiersQuery, GetCommunityVerifiersQueryVariables>(GetCommunityVerifiersDocument, options);
      }
export function useGetCommunityVerifiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityVerifiersQuery, GetCommunityVerifiersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityVerifiersQuery, GetCommunityVerifiersQueryVariables>(GetCommunityVerifiersDocument, options);
        }
export type GetCommunityVerifiersQueryHookResult = ReturnType<typeof useGetCommunityVerifiersQuery>;
export type GetCommunityVerifiersLazyQueryHookResult = ReturnType<typeof useGetCommunityVerifiersLazyQuery>;
export type GetCommunityVerifiersQueryResult = Apollo.QueryResult<GetCommunityVerifiersQuery, GetCommunityVerifiersQueryVariables>;
export const GetCommunitiesDocument = gql`
    query getCommunities($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getBaoleaderCommunities(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...community
      featured_image {
        data {
          ...uploadFile
        }
      }
    }
  }
}
    ${CommunityFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunitiesQuery, GetCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunitiesQuery, GetCommunitiesQueryVariables>(GetCommunitiesDocument, options);
      }
export function useGetCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunitiesQuery, GetCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunitiesQuery, GetCommunitiesQueryVariables>(GetCommunitiesDocument, options);
        }
export type GetCommunitiesQueryHookResult = ReturnType<typeof useGetCommunitiesQuery>;
export type GetCommunitiesLazyQueryHookResult = ReturnType<typeof useGetCommunitiesLazyQuery>;
export type GetCommunitiesQueryResult = Apollo.QueryResult<GetCommunitiesQuery, GetCommunitiesQueryVariables>;
export const GetCommunityDocument = gql`
    query getCommunity {
  getBaoleaderCommunity {
    id
    attributes {
      name
      chief_name
      description
      location
      updatedAt
      createdAt
      featured_image {
        data {
          ...uploadFile
        }
      }
      baoleader {
        data {
          id
        }
      }
    }
  }
}
    ${UploadFileFragmentDoc}`;

/**
 * __useGetCommunityQuery__
 *
 * To run a query within a React component, call `useGetCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommunityQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunityQuery, GetCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityQuery, GetCommunityQueryVariables>(GetCommunityDocument, options);
      }
export function useGetCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityQuery, GetCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityQuery, GetCommunityQueryVariables>(GetCommunityDocument, options);
        }
export type GetCommunityQueryHookResult = ReturnType<typeof useGetCommunityQuery>;
export type GetCommunityLazyQueryHookResult = ReturnType<typeof useGetCommunityLazyQuery>;
export type GetCommunityQueryResult = Apollo.QueryResult<GetCommunityQuery, GetCommunityQueryVariables>;
export const GetCommunityBaoridersDocument = gql`
    query getCommunityBaoriders($communityId: ID!) {
  getCommunityBaoriders(community: $communityId) {
    members {
      id
      attributes {
        ...member
      }
    }
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetCommunityBaoridersQuery__
 *
 * To run a query within a React component, call `useGetCommunityBaoridersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityBaoridersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityBaoridersQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityBaoridersQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityBaoridersQuery, GetCommunityBaoridersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityBaoridersQuery, GetCommunityBaoridersQueryVariables>(GetCommunityBaoridersDocument, options);
      }
export function useGetCommunityBaoridersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityBaoridersQuery, GetCommunityBaoridersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityBaoridersQuery, GetCommunityBaoridersQueryVariables>(GetCommunityBaoridersDocument, options);
        }
export type GetCommunityBaoridersQueryHookResult = ReturnType<typeof useGetCommunityBaoridersQuery>;
export type GetCommunityBaoridersLazyQueryHookResult = ReturnType<typeof useGetCommunityBaoridersLazyQuery>;
export type GetCommunityBaoridersQueryResult = Apollo.QueryResult<GetCommunityBaoridersQuery, GetCommunityBaoridersQueryVariables>;
export const GetCommunityMembersDocument = gql`
    query getCommunityMembers($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getCommunityMembers(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    members {
      id
      attributes {
        ...member
      }
    }
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetCommunityMembersQuery__
 *
 * To run a query within a React component, call `useGetCommunityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityMembersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCommunityMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>(GetCommunityMembersDocument, options);
      }
export function useGetCommunityMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>(GetCommunityMembersDocument, options);
        }
export type GetCommunityMembersQueryHookResult = ReturnType<typeof useGetCommunityMembersQuery>;
export type GetCommunityMembersLazyQueryHookResult = ReturnType<typeof useGetCommunityMembersLazyQuery>;
export type GetCommunityMembersQueryResult = Apollo.QueryResult<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>;
export const GetCommunityProjectsDocument = gql`
    query getCommunityProjects($community: ID!) {
  getCommunityProjects(community: $community) {
    id
    attributes {
      ...project
      featured_image {
        data {
          ...uploadFile
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetCommunityProjectsQuery__
 *
 * To run a query within a React component, call `useGetCommunityProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityProjectsQuery({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useGetCommunityProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityProjectsQuery, GetCommunityProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityProjectsQuery, GetCommunityProjectsQueryVariables>(GetCommunityProjectsDocument, options);
      }
export function useGetCommunityProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityProjectsQuery, GetCommunityProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityProjectsQuery, GetCommunityProjectsQueryVariables>(GetCommunityProjectsDocument, options);
        }
export type GetCommunityProjectsQueryHookResult = ReturnType<typeof useGetCommunityProjectsQuery>;
export type GetCommunityProjectsLazyQueryHookResult = ReturnType<typeof useGetCommunityProjectsLazyQuery>;
export type GetCommunityProjectsQueryResult = Apollo.QueryResult<GetCommunityProjectsQuery, GetCommunityProjectsQueryVariables>;
export const GetCommunityProjectsCountDocument = gql`
    query getCommunityProjectsCount($community: ID!) {
  getCommunityProjectsCount(community: $community)
}
    `;

/**
 * __useGetCommunityProjectsCountQuery__
 *
 * To run a query within a React component, call `useGetCommunityProjectsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityProjectsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityProjectsCountQuery({
 *   variables: {
 *      community: // value for 'community'
 *   },
 * });
 */
export function useGetCommunityProjectsCountQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityProjectsCountQuery, GetCommunityProjectsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityProjectsCountQuery, GetCommunityProjectsCountQueryVariables>(GetCommunityProjectsCountDocument, options);
      }
export function useGetCommunityProjectsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityProjectsCountQuery, GetCommunityProjectsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityProjectsCountQuery, GetCommunityProjectsCountQueryVariables>(GetCommunityProjectsCountDocument, options);
        }
export type GetCommunityProjectsCountQueryHookResult = ReturnType<typeof useGetCommunityProjectsCountQuery>;
export type GetCommunityProjectsCountLazyQueryHookResult = ReturnType<typeof useGetCommunityProjectsCountLazyQuery>;
export type GetCommunityProjectsCountQueryResult = Apollo.QueryResult<GetCommunityProjectsCountQuery, GetCommunityProjectsCountQueryVariables>;
export const GetCompanyCategoriesDocument = gql`
    query GetCompanyCategories($where: JSON, $limit: Int, $start: Int, $sort: String) {
  getCompanyCategories(where: $where, limit: $limit, start: $start, sort: $sort) {
    id
    attributes {
      ...category
      featureImage {
        data {
          ...uploadFile
        }
      }
    }
  }
}
    ${CategoryFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetCompanyCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCompanyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCompanyCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyCategoriesQuery, GetCompanyCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyCategoriesQuery, GetCompanyCategoriesQueryVariables>(GetCompanyCategoriesDocument, options);
      }
export function useGetCompanyCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyCategoriesQuery, GetCompanyCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyCategoriesQuery, GetCompanyCategoriesQueryVariables>(GetCompanyCategoriesDocument, options);
        }
export type GetCompanyCategoriesQueryHookResult = ReturnType<typeof useGetCompanyCategoriesQuery>;
export type GetCompanyCategoriesLazyQueryHookResult = ReturnType<typeof useGetCompanyCategoriesLazyQuery>;
export type GetCompanyCategoriesQueryResult = Apollo.QueryResult<GetCompanyCategoriesQuery, GetCompanyCategoriesQueryVariables>;
export const GetCompanyInfoDocument = gql`
    query getCompanyInfo {
  getCompanyInfo {
    name
    pin_registration
    website
    affiliates
    paymentMethod
    default_dashboard
    subscription {
      data {
        id
        attributes {
          createdAt
          updatedAt
          current_period_start
          current_period_end
          cancel_at
          trial_start
          collection_method
          cancel_at_period_end
          recurring
          trial_end
          status
          product_plan {
            data {
              id
              attributes {
                name
                isManual
                description
                ProductFeature {
                  id
                  baoriders_allowed
                  cost_per_baorider
                  price
                  recurring
                }
              }
            }
          }
        }
      }
    }
    team_members {
      data {
        id
        attributes {
          ...member
        }
      }
    }
    id_number
    wildlife_association_number
    createdAt
    updatedAt
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetCompanyInfoQuery__
 *
 * To run a query within a React component, call `useGetCompanyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(GetCompanyInfoDocument, options);
      }
export function useGetCompanyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(GetCompanyInfoDocument, options);
        }
export type GetCompanyInfoQueryHookResult = ReturnType<typeof useGetCompanyInfoQuery>;
export type GetCompanyInfoLazyQueryHookResult = ReturnType<typeof useGetCompanyInfoLazyQuery>;
export type GetCompanyInfoQueryResult = Apollo.QueryResult<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>;
export const GetCompanyLeadersDocument = gql`
    query getCompanyLeaders($sort: [String], $filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg) {
  getCompanyLeaders(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...member
    }
  }
}
    ${MemberFragmentDoc}`;

/**
 * __useGetCompanyLeadersQuery__
 *
 * To run a query within a React component, call `useGetCompanyLeadersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLeadersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLeadersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCompanyLeadersQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyLeadersQuery, GetCompanyLeadersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyLeadersQuery, GetCompanyLeadersQueryVariables>(GetCompanyLeadersDocument, options);
      }
export function useGetCompanyLeadersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyLeadersQuery, GetCompanyLeadersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyLeadersQuery, GetCompanyLeadersQueryVariables>(GetCompanyLeadersDocument, options);
        }
export type GetCompanyLeadersQueryHookResult = ReturnType<typeof useGetCompanyLeadersQuery>;
export type GetCompanyLeadersLazyQueryHookResult = ReturnType<typeof useGetCompanyLeadersLazyQuery>;
export type GetCompanyLeadersQueryResult = Apollo.QueryResult<GetCompanyLeadersQuery, GetCompanyLeadersQueryVariables>;
export const GetCompanyLeadersCountDocument = gql`
    query getCompanyLeadersCount {
  getCompanyLeadersCount
}
    `;

/**
 * __useGetCompanyLeadersCountQuery__
 *
 * To run a query within a React component, call `useGetCompanyLeadersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLeadersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLeadersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyLeadersCountQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyLeadersCountQuery, GetCompanyLeadersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyLeadersCountQuery, GetCompanyLeadersCountQueryVariables>(GetCompanyLeadersCountDocument, options);
      }
export function useGetCompanyLeadersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyLeadersCountQuery, GetCompanyLeadersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyLeadersCountQuery, GetCompanyLeadersCountQueryVariables>(GetCompanyLeadersCountDocument, options);
        }
export type GetCompanyLeadersCountQueryHookResult = ReturnType<typeof useGetCompanyLeadersCountQuery>;
export type GetCompanyLeadersCountLazyQueryHookResult = ReturnType<typeof useGetCompanyLeadersCountLazyQuery>;
export type GetCompanyLeadersCountQueryResult = Apollo.QueryResult<GetCompanyLeadersCountQuery, GetCompanyLeadersCountQueryVariables>;
export const GetCompanyTaskTypesDocument = gql`
    query GetCompanyTaskTypes($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getCompanyTaskTypes(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...taskType
    }
  }
}
    ${TaskTypeFragmentDoc}`;

/**
 * __useGetCompanyTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetCompanyTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTaskTypesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCompanyTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyTaskTypesQuery, GetCompanyTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyTaskTypesQuery, GetCompanyTaskTypesQueryVariables>(GetCompanyTaskTypesDocument, options);
      }
export function useGetCompanyTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyTaskTypesQuery, GetCompanyTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyTaskTypesQuery, GetCompanyTaskTypesQueryVariables>(GetCompanyTaskTypesDocument, options);
        }
export type GetCompanyTaskTypesQueryHookResult = ReturnType<typeof useGetCompanyTaskTypesQuery>;
export type GetCompanyTaskTypesLazyQueryHookResult = ReturnType<typeof useGetCompanyTaskTypesLazyQuery>;
export type GetCompanyTaskTypesQueryResult = Apollo.QueryResult<GetCompanyTaskTypesQuery, GetCompanyTaskTypesQueryVariables>;
export const GetCustomerPaymentDetailsDocument = gql`
    query getCustomerPaymentDetails {
  getCustomerPaymentDetails {
    brand
    exp_month
    exp_year
    last4
  }
}
    `;

/**
 * __useGetCustomerPaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetCustomerPaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPaymentDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerPaymentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerPaymentDetailsQuery, GetCustomerPaymentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerPaymentDetailsQuery, GetCustomerPaymentDetailsQueryVariables>(GetCustomerPaymentDetailsDocument, options);
      }
export function useGetCustomerPaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerPaymentDetailsQuery, GetCustomerPaymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerPaymentDetailsQuery, GetCustomerPaymentDetailsQueryVariables>(GetCustomerPaymentDetailsDocument, options);
        }
export type GetCustomerPaymentDetailsQueryHookResult = ReturnType<typeof useGetCustomerPaymentDetailsQuery>;
export type GetCustomerPaymentDetailsLazyQueryHookResult = ReturnType<typeof useGetCustomerPaymentDetailsLazyQuery>;
export type GetCustomerPaymentDetailsQueryResult = Apollo.QueryResult<GetCustomerPaymentDetailsQuery, GetCustomerPaymentDetailsQueryVariables>;
export const GetDataListsDocument = gql`
    query getDataLists($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getDataLists(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...dataLists
    }
  }
}
    ${DataListsFragmentDoc}`;

/**
 * __useGetDataListsQuery__
 *
 * To run a query within a React component, call `useGetDataListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataListsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetDataListsQuery(baseOptions?: Apollo.QueryHookOptions<GetDataListsQuery, GetDataListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataListsQuery, GetDataListsQueryVariables>(GetDataListsDocument, options);
      }
export function useGetDataListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataListsQuery, GetDataListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataListsQuery, GetDataListsQueryVariables>(GetDataListsDocument, options);
        }
export type GetDataListsQueryHookResult = ReturnType<typeof useGetDataListsQuery>;
export type GetDataListsLazyQueryHookResult = ReturnType<typeof useGetDataListsLazyQuery>;
export type GetDataListsQueryResult = Apollo.QueryResult<GetDataListsQuery, GetDataListsQueryVariables>;
export const GetProjectDocument = gql`
    query getProject {
  getBaoleaderProject {
    id
    attributes {
      ...project
      featured_image {
        data {
          ...uploadFile
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectDataCollectorsCountDocument = gql`
    query getProjectDataCollectorsCount($projectId: ID!) {
  getProjectDataCollectorsCount(project: $projectId)
}
    `;

/**
 * __useGetProjectDataCollectorsCountQuery__
 *
 * To run a query within a React component, call `useGetProjectDataCollectorsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDataCollectorsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDataCollectorsCountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectDataCollectorsCountQuery(baseOptions: Apollo.QueryHookOptions<GetProjectDataCollectorsCountQuery, GetProjectDataCollectorsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectDataCollectorsCountQuery, GetProjectDataCollectorsCountQueryVariables>(GetProjectDataCollectorsCountDocument, options);
      }
export function useGetProjectDataCollectorsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectDataCollectorsCountQuery, GetProjectDataCollectorsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectDataCollectorsCountQuery, GetProjectDataCollectorsCountQueryVariables>(GetProjectDataCollectorsCountDocument, options);
        }
export type GetProjectDataCollectorsCountQueryHookResult = ReturnType<typeof useGetProjectDataCollectorsCountQuery>;
export type GetProjectDataCollectorsCountLazyQueryHookResult = ReturnType<typeof useGetProjectDataCollectorsCountLazyQuery>;
export type GetProjectDataCollectorsCountQueryResult = Apollo.QueryResult<GetProjectDataCollectorsCountQuery, GetProjectDataCollectorsCountQueryVariables>;
export const GetProjectGroupsDocument = gql`
    query getProjectGroups($projectId: ID!) {
  getProjectGroups(projectId: $projectId) {
    id
    attributes {
      name
    }
  }
}
    `;

/**
 * __useGetProjectGroupsQuery__
 *
 * To run a query within a React component, call `useGetProjectGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectGroupsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>(GetProjectGroupsDocument, options);
      }
export function useGetProjectGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>(GetProjectGroupsDocument, options);
        }
export type GetProjectGroupsQueryHookResult = ReturnType<typeof useGetProjectGroupsQuery>;
export type GetProjectGroupsLazyQueryHookResult = ReturnType<typeof useGetProjectGroupsLazyQuery>;
export type GetProjectGroupsQueryResult = Apollo.QueryResult<GetProjectGroupsQuery, GetProjectGroupsQueryVariables>;
export const GetProjectMetricsDocument = gql`
    query getProjectMetrics($id: ID!) {
  getProjectMetrics(id: $id) {
    completedTasks {
      total
      last30Days
      chartData {
        key
        value
      }
    }
    reportedTask {
      total
      last30Days
      chartData {
        key
        value
      }
    }
    averageCompletionTime {
      total
      wow
      dod
    }
    averageEvidenceTime {
      total
      wow
      dod
    }
  }
}
    `;

/**
 * __useGetProjectMetricsQuery__
 *
 * To run a query within a React component, call `useGetProjectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMetricsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectMetricsQuery, GetProjectMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectMetricsQuery, GetProjectMetricsQueryVariables>(GetProjectMetricsDocument, options);
      }
export function useGetProjectMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectMetricsQuery, GetProjectMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectMetricsQuery, GetProjectMetricsQueryVariables>(GetProjectMetricsDocument, options);
        }
export type GetProjectMetricsQueryHookResult = ReturnType<typeof useGetProjectMetricsQuery>;
export type GetProjectMetricsLazyQueryHookResult = ReturnType<typeof useGetProjectMetricsLazyQuery>;
export type GetProjectMetricsQueryResult = Apollo.QueryResult<GetProjectMetricsQuery, GetProjectMetricsQueryVariables>;
export const GetProjectTaskTypesDocument = gql`
    query getProjectTaskTypes($sort: [String], $filters: TaskTypeFiltersInput, $pagination: PaginationArg) {
  taskTypes(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    data {
      id
      attributes {
        title
      }
    }
  }
}
    `;

/**
 * __useGetProjectTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskTypesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetProjectTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectTaskTypesQuery, GetProjectTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectTaskTypesQuery, GetProjectTaskTypesQueryVariables>(GetProjectTaskTypesDocument, options);
      }
export function useGetProjectTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectTaskTypesQuery, GetProjectTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectTaskTypesQuery, GetProjectTaskTypesQueryVariables>(GetProjectTaskTypesDocument, options);
        }
export type GetProjectTaskTypesQueryHookResult = ReturnType<typeof useGetProjectTaskTypesQuery>;
export type GetProjectTaskTypesLazyQueryHookResult = ReturnType<typeof useGetProjectTaskTypesLazyQuery>;
export type GetProjectTaskTypesQueryResult = Apollo.QueryResult<GetProjectTaskTypesQuery, GetProjectTaskTypesQueryVariables>;
export const GetProjectUnsdgTagsDocument = gql`
    query getProjectUnsdgTags($project: ID!) {
  getProjectUnsdgTags(project: $project)
}
    `;

/**
 * __useGetProjectUnsdgTagsQuery__
 *
 * To run a query within a React component, call `useGetProjectUnsdgTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUnsdgTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUnsdgTagsQuery({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useGetProjectUnsdgTagsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectUnsdgTagsQuery, GetProjectUnsdgTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectUnsdgTagsQuery, GetProjectUnsdgTagsQueryVariables>(GetProjectUnsdgTagsDocument, options);
      }
export function useGetProjectUnsdgTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectUnsdgTagsQuery, GetProjectUnsdgTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectUnsdgTagsQuery, GetProjectUnsdgTagsQueryVariables>(GetProjectUnsdgTagsDocument, options);
        }
export type GetProjectUnsdgTagsQueryHookResult = ReturnType<typeof useGetProjectUnsdgTagsQuery>;
export type GetProjectUnsdgTagsLazyQueryHookResult = ReturnType<typeof useGetProjectUnsdgTagsLazyQuery>;
export type GetProjectUnsdgTagsQueryResult = Apollo.QueryResult<GetProjectUnsdgTagsQuery, GetProjectUnsdgTagsQueryVariables>;
export const GetProjectsDocument = gql`
    query getProjects($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getBaoleaderProjects(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...project
      featured_image {
        data {
          ...uploadFile
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetSingleBaoriderDocument = gql`
    query getSingleBaorider($id: ID!) {
  getSingleBaorider(id: $id) {
    baorider {
      id
      attributes {
        idNumber
        phoneNumber
        email
        profilePicture {
          data {
            ...uploadFile
          }
        }
        firstName
        lastName
        isVerifier
        community {
          data {
            id
            attributes {
              ...community
            }
          }
        }
        projects {
          data {
            id
            attributes {
              ...project
              featured_image {
                data {
                  ...uploadFile
                }
              }
            }
          }
        }
        status
        createdAt
        updatedAt
        gender
        dateOfBirth
      }
    }
  }
}
    ${UploadFileFragmentDoc}
${CommunityFragmentDoc}
${ProjectFragmentDoc}`;

/**
 * __useGetSingleBaoriderQuery__
 *
 * To run a query within a React component, call `useGetSingleBaoriderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBaoriderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBaoriderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleBaoriderQuery(baseOptions: Apollo.QueryHookOptions<GetSingleBaoriderQuery, GetSingleBaoriderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleBaoriderQuery, GetSingleBaoriderQueryVariables>(GetSingleBaoriderDocument, options);
      }
export function useGetSingleBaoriderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleBaoriderQuery, GetSingleBaoriderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleBaoriderQuery, GetSingleBaoriderQueryVariables>(GetSingleBaoriderDocument, options);
        }
export type GetSingleBaoriderQueryHookResult = ReturnType<typeof useGetSingleBaoriderQuery>;
export type GetSingleBaoriderLazyQueryHookResult = ReturnType<typeof useGetSingleBaoriderLazyQuery>;
export type GetSingleBaoriderQueryResult = Apollo.QueryResult<GetSingleBaoriderQuery, GetSingleBaoriderQueryVariables>;
export const GetSingleBaoleaderCommunityDocument = gql`
    query getSingleBaoleaderCommunity($id: ID!) {
  getSingleBaoleaderCommunity(id: $id) {
    id
    attributes {
      ...community
      featured_image {
        data {
          ...uploadFile
        }
      }
    }
  }
}
    ${CommunityFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetSingleBaoleaderCommunityQuery__
 *
 * To run a query within a React component, call `useGetSingleBaoleaderCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBaoleaderCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBaoleaderCommunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleBaoleaderCommunityQuery(baseOptions: Apollo.QueryHookOptions<GetSingleBaoleaderCommunityQuery, GetSingleBaoleaderCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleBaoleaderCommunityQuery, GetSingleBaoleaderCommunityQueryVariables>(GetSingleBaoleaderCommunityDocument, options);
      }
export function useGetSingleBaoleaderCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleBaoleaderCommunityQuery, GetSingleBaoleaderCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleBaoleaderCommunityQuery, GetSingleBaoleaderCommunityQueryVariables>(GetSingleBaoleaderCommunityDocument, options);
        }
export type GetSingleBaoleaderCommunityQueryHookResult = ReturnType<typeof useGetSingleBaoleaderCommunityQuery>;
export type GetSingleBaoleaderCommunityLazyQueryHookResult = ReturnType<typeof useGetSingleBaoleaderCommunityLazyQuery>;
export type GetSingleBaoleaderCommunityQueryResult = Apollo.QueryResult<GetSingleBaoleaderCommunityQuery, GetSingleBaoleaderCommunityQueryVariables>;
export const GetSingleCommunityMemberDocument = gql`
    query getSingleCommunityMember($where: JSON) {
  getSingleCommunityMember(where: $where) {
    member {
      id
      attributes {
        idNumber
        phoneNumber
        firstName
        lastName
        email
        profilePicture {
          data {
            ...uploadFile
          }
        }
        community {
          data {
            id
            attributes {
              ...community
              featured_image {
                data {
                  ...uploadFile
                }
              }
            }
          }
        }
        status
        createdAt
        updatedAt
        gender
        dateOfBirth
      }
    }
  }
}
    ${UploadFileFragmentDoc}
${CommunityFragmentDoc}`;

/**
 * __useGetSingleCommunityMemberQuery__
 *
 * To run a query within a React component, call `useGetSingleCommunityMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCommunityMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCommunityMemberQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSingleCommunityMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetSingleCommunityMemberQuery, GetSingleCommunityMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleCommunityMemberQuery, GetSingleCommunityMemberQueryVariables>(GetSingleCommunityMemberDocument, options);
      }
export function useGetSingleCommunityMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleCommunityMemberQuery, GetSingleCommunityMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleCommunityMemberQuery, GetSingleCommunityMemberQueryVariables>(GetSingleCommunityMemberDocument, options);
        }
export type GetSingleCommunityMemberQueryHookResult = ReturnType<typeof useGetSingleCommunityMemberQuery>;
export type GetSingleCommunityMemberLazyQueryHookResult = ReturnType<typeof useGetSingleCommunityMemberLazyQuery>;
export type GetSingleCommunityMemberQueryResult = Apollo.QueryResult<GetSingleCommunityMemberQuery, GetSingleCommunityMemberQueryVariables>;
export const GetSingleBaoleaderProjectDocument = gql`
    query getSingleBaoleaderProject($id: ID!) {
  getSingleBaoleaderProject(id: $id) {
    id
    attributes {
      ...project
      featured_image {
        data {
          ...uploadFile
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetSingleBaoleaderProjectQuery__
 *
 * To run a query within a React component, call `useGetSingleBaoleaderProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleBaoleaderProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleBaoleaderProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleBaoleaderProjectQuery(baseOptions: Apollo.QueryHookOptions<GetSingleBaoleaderProjectQuery, GetSingleBaoleaderProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleBaoleaderProjectQuery, GetSingleBaoleaderProjectQueryVariables>(GetSingleBaoleaderProjectDocument, options);
      }
export function useGetSingleBaoleaderProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleBaoleaderProjectQuery, GetSingleBaoleaderProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleBaoleaderProjectQuery, GetSingleBaoleaderProjectQueryVariables>(GetSingleBaoleaderProjectDocument, options);
        }
export type GetSingleBaoleaderProjectQueryHookResult = ReturnType<typeof useGetSingleBaoleaderProjectQuery>;
export type GetSingleBaoleaderProjectLazyQueryHookResult = ReturnType<typeof useGetSingleBaoleaderProjectLazyQuery>;
export type GetSingleBaoleaderProjectQueryResult = Apollo.QueryResult<GetSingleBaoleaderProjectQuery, GetSingleBaoleaderProjectQueryVariables>;
export const GetSubscriptionDocument = gql`
    query getSubscription {
  getSubscription {
    id
    product_plan {
      name
    }
    recurring
    cancel_at_period_end
    cancel_at
    collection_method
    created
    current_period_end
    current_period_start
    customer
    ended_at
    latest_invoice
    status
    trial_end
    trial_start
    items
  }
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
      }
export function useGetSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<GetSubscriptionQuery, GetSubscriptionQueryVariables>;
export const GetTaskBuilderFilterCategoriesDocument = gql`
    query GetTaskBuilderFilterCategories($where: JSON, $limit: Int, $start: Int, $sort: String) {
  getTaskBuilderFilterCategories(where: $where, limit: $limit, start: $start, sort: $sort) {
    id
    attributes {
      title
    }
  }
}
    `;

/**
 * __useGetTaskBuilderFilterCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTaskBuilderFilterCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskBuilderFilterCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskBuilderFilterCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTaskBuilderFilterCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskBuilderFilterCategoriesQuery, GetTaskBuilderFilterCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskBuilderFilterCategoriesQuery, GetTaskBuilderFilterCategoriesQueryVariables>(GetTaskBuilderFilterCategoriesDocument, options);
      }
export function useGetTaskBuilderFilterCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskBuilderFilterCategoriesQuery, GetTaskBuilderFilterCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskBuilderFilterCategoriesQuery, GetTaskBuilderFilterCategoriesQueryVariables>(GetTaskBuilderFilterCategoriesDocument, options);
        }
export type GetTaskBuilderFilterCategoriesQueryHookResult = ReturnType<typeof useGetTaskBuilderFilterCategoriesQuery>;
export type GetTaskBuilderFilterCategoriesLazyQueryHookResult = ReturnType<typeof useGetTaskBuilderFilterCategoriesLazyQuery>;
export type GetTaskBuilderFilterCategoriesQueryResult = Apollo.QueryResult<GetTaskBuilderFilterCategoriesQuery, GetTaskBuilderFilterCategoriesQueryVariables>;
export const GetTaskBuilderFilterCreatorsDocument = gql`
    query GetTaskBuilderFilterCreators($where: JSON, $limit: Int, $start: Int, $sort: String) {
  getTaskBuilderFilterCreators(where: $where, limit: $limit, start: $start, sort: $sort) {
    id
    attributes {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetTaskBuilderFilterCreatorsQuery__
 *
 * To run a query within a React component, call `useGetTaskBuilderFilterCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskBuilderFilterCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskBuilderFilterCreatorsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      start: // value for 'start'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTaskBuilderFilterCreatorsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskBuilderFilterCreatorsQuery, GetTaskBuilderFilterCreatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskBuilderFilterCreatorsQuery, GetTaskBuilderFilterCreatorsQueryVariables>(GetTaskBuilderFilterCreatorsDocument, options);
      }
export function useGetTaskBuilderFilterCreatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskBuilderFilterCreatorsQuery, GetTaskBuilderFilterCreatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskBuilderFilterCreatorsQuery, GetTaskBuilderFilterCreatorsQueryVariables>(GetTaskBuilderFilterCreatorsDocument, options);
        }
export type GetTaskBuilderFilterCreatorsQueryHookResult = ReturnType<typeof useGetTaskBuilderFilterCreatorsQuery>;
export type GetTaskBuilderFilterCreatorsLazyQueryHookResult = ReturnType<typeof useGetTaskBuilderFilterCreatorsLazyQuery>;
export type GetTaskBuilderFilterCreatorsQueryResult = Apollo.QueryResult<GetTaskBuilderFilterCreatorsQuery, GetTaskBuilderFilterCreatorsQueryVariables>;
export const GetTaskFilterCommunitiesDocument = gql`
    query getTaskFilterCommunities($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getTaskFilterCommunities(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...community
    }
  }
}
    ${CommunityFragmentDoc}`;

/**
 * __useGetTaskFilterCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetTaskFilterCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskFilterCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskFilterCommunitiesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTaskFilterCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskFilterCommunitiesQuery, GetTaskFilterCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskFilterCommunitiesQuery, GetTaskFilterCommunitiesQueryVariables>(GetTaskFilterCommunitiesDocument, options);
      }
export function useGetTaskFilterCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskFilterCommunitiesQuery, GetTaskFilterCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskFilterCommunitiesQuery, GetTaskFilterCommunitiesQueryVariables>(GetTaskFilterCommunitiesDocument, options);
        }
export type GetTaskFilterCommunitiesQueryHookResult = ReturnType<typeof useGetTaskFilterCommunitiesQuery>;
export type GetTaskFilterCommunitiesLazyQueryHookResult = ReturnType<typeof useGetTaskFilterCommunitiesLazyQuery>;
export type GetTaskFilterCommunitiesQueryResult = Apollo.QueryResult<GetTaskFilterCommunitiesQuery, GetTaskFilterCommunitiesQueryVariables>;
export const GetTaskFilterDataCollectorsDocument = gql`
    query getTaskFilterDataCollectors($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getTaskFilterDataCollectors(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      idNumber
      phoneNumber
      firstName
      isVerifier
      lastName
      status
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetTaskFilterDataCollectorsQuery__
 *
 * To run a query within a React component, call `useGetTaskFilterDataCollectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskFilterDataCollectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskFilterDataCollectorsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTaskFilterDataCollectorsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskFilterDataCollectorsQuery, GetTaskFilterDataCollectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskFilterDataCollectorsQuery, GetTaskFilterDataCollectorsQueryVariables>(GetTaskFilterDataCollectorsDocument, options);
      }
export function useGetTaskFilterDataCollectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskFilterDataCollectorsQuery, GetTaskFilterDataCollectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskFilterDataCollectorsQuery, GetTaskFilterDataCollectorsQueryVariables>(GetTaskFilterDataCollectorsDocument, options);
        }
export type GetTaskFilterDataCollectorsQueryHookResult = ReturnType<typeof useGetTaskFilterDataCollectorsQuery>;
export type GetTaskFilterDataCollectorsLazyQueryHookResult = ReturnType<typeof useGetTaskFilterDataCollectorsLazyQuery>;
export type GetTaskFilterDataCollectorsQueryResult = Apollo.QueryResult<GetTaskFilterDataCollectorsQuery, GetTaskFilterDataCollectorsQueryVariables>;
export const GetTaskFilterProjectsDocument = gql`
    query getTaskFilterProjects($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getTaskFilterProjects(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...project
    }
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useGetTaskFilterProjectsQuery__
 *
 * To run a query within a React component, call `useGetTaskFilterProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskFilterProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskFilterProjectsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTaskFilterProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskFilterProjectsQuery, GetTaskFilterProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskFilterProjectsQuery, GetTaskFilterProjectsQueryVariables>(GetTaskFilterProjectsDocument, options);
      }
export function useGetTaskFilterProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskFilterProjectsQuery, GetTaskFilterProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskFilterProjectsQuery, GetTaskFilterProjectsQueryVariables>(GetTaskFilterProjectsDocument, options);
        }
export type GetTaskFilterProjectsQueryHookResult = ReturnType<typeof useGetTaskFilterProjectsQuery>;
export type GetTaskFilterProjectsLazyQueryHookResult = ReturnType<typeof useGetTaskFilterProjectsLazyQuery>;
export type GetTaskFilterProjectsQueryResult = Apollo.QueryResult<GetTaskFilterProjectsQuery, GetTaskFilterProjectsQueryVariables>;
export const GetTaskFilterTaskTypesDocument = gql`
    query getTaskFilterTaskTypes($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getTaskFilterTaskTypes(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...taskType
    }
  }
}
    ${TaskTypeFragmentDoc}`;

/**
 * __useGetTaskFilterTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetTaskFilterTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskFilterTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskFilterTaskTypesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTaskFilterTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskFilterTaskTypesQuery, GetTaskFilterTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskFilterTaskTypesQuery, GetTaskFilterTaskTypesQueryVariables>(GetTaskFilterTaskTypesDocument, options);
      }
export function useGetTaskFilterTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskFilterTaskTypesQuery, GetTaskFilterTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskFilterTaskTypesQuery, GetTaskFilterTaskTypesQueryVariables>(GetTaskFilterTaskTypesDocument, options);
        }
export type GetTaskFilterTaskTypesQueryHookResult = ReturnType<typeof useGetTaskFilterTaskTypesQuery>;
export type GetTaskFilterTaskTypesLazyQueryHookResult = ReturnType<typeof useGetTaskFilterTaskTypesLazyQuery>;
export type GetTaskFilterTaskTypesQueryResult = Apollo.QueryResult<GetTaskFilterTaskTypesQuery, GetTaskFilterTaskTypesQueryVariables>;
export const GetTaskFilterUnsdgsDocument = gql`
    query getTaskFilterUnsdgs($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getTaskFilterUnsdgs(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...UNSDG
    }
  }
}
    ${UnsdgFragmentDoc}`;

/**
 * __useGetTaskFilterUnsdgsQuery__
 *
 * To run a query within a React component, call `useGetTaskFilterUnsdgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskFilterUnsdgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskFilterUnsdgsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTaskFilterUnsdgsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskFilterUnsdgsQuery, GetTaskFilterUnsdgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskFilterUnsdgsQuery, GetTaskFilterUnsdgsQueryVariables>(GetTaskFilterUnsdgsDocument, options);
      }
export function useGetTaskFilterUnsdgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskFilterUnsdgsQuery, GetTaskFilterUnsdgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskFilterUnsdgsQuery, GetTaskFilterUnsdgsQueryVariables>(GetTaskFilterUnsdgsDocument, options);
        }
export type GetTaskFilterUnsdgsQueryHookResult = ReturnType<typeof useGetTaskFilterUnsdgsQuery>;
export type GetTaskFilterUnsdgsLazyQueryHookResult = ReturnType<typeof useGetTaskFilterUnsdgsLazyQuery>;
export type GetTaskFilterUnsdgsQueryResult = Apollo.QueryResult<GetTaskFilterUnsdgsQuery, GetTaskFilterUnsdgsQueryVariables>;
export const GetTaskTypeDocument = gql`
    query getTaskType($id: ID!) {
  getTaskType(id: $id) {
    id
    attributes {
      ...taskType
      un_sdg {
        data {
          id
          attributes {
            ...UNSDG
          }
        }
      }
      category {
        data {
          id
          attributes {
            ...category
            featureImage {
              data {
                ...uploadFile
              }
            }
          }
        }
      }
      creator {
        data {
          id
          attributes {
            firstName
            lastName
          }
        }
      }
      dataFields {
        ...componentCustomDataFieldsMetricsSpecific
        ...componentCustomDataFieldsNumberRange
        ...componentCustomDataFieldsNumberDropDown
        ...componentCustomDataFieldsPercentage
        ...componentCustomDataFieldsRadio
        ...componentCustomDataFieldsText
        ...componentCustomDataFieldsCheckbox
        ...componentCustomDataFieldsDataList
        ...componentCustomDataFieldsDateTimeField
        ...componentCustomDataFieldsImageField
        ...componentCustomDataFieldsSelect
        ...componentCustomDataFieldsNumberField
        ...componentCustomDataFieldsPercentage
        ...componentCustomDataFieldsRating
        ...componentCustomDataFieldsPhoneNumberField
        ...componentCustomDataFieldsEmailField
        __typename
      }
    }
  }
}
    ${TaskTypeFragmentDoc}
${UnsdgFragmentDoc}
${CategoryFragmentDoc}
${UploadFileFragmentDoc}
${ComponentCustomDataFieldsMetricsSpecificFragmentDoc}
${ComponentCustomDataFieldsNumberRangeFragmentDoc}
${ComponentCustomDataFieldsNumberDropDownFragmentDoc}
${ComponentCustomDataFieldsPercentageFragmentDoc}
${ComponentCustomDataFieldsRadioFragmentDoc}
${ComponentCustomDataFieldsTextFragmentDoc}
${ComponentCustomDataFieldsCheckboxFragmentDoc}
${ComponentCustomDataFieldsDataListFragmentDoc}
${ComponentCustomDataFieldsDateTimeFieldFragmentDoc}
${ComponentCustomDataFieldsImageFieldFragmentDoc}
${ComponentCustomDataFieldsSelectFragmentDoc}
${ComponentCustomDataFieldsNumberFieldFragmentDoc}
${ComponentCustomDataFieldsRatingFragmentDoc}
${ComponentCustomDataFieldsPhoneNumberFieldFragmentDoc}
${ComponentCustomDataFieldsEmailFieldFragmentDoc}`;

/**
 * __useGetTaskTypeQuery__
 *
 * To run a query within a React component, call `useGetTaskTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskTypeQuery(baseOptions: Apollo.QueryHookOptions<GetTaskTypeQuery, GetTaskTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskTypeQuery, GetTaskTypeQueryVariables>(GetTaskTypeDocument, options);
      }
export function useGetTaskTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskTypeQuery, GetTaskTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskTypeQuery, GetTaskTypeQueryVariables>(GetTaskTypeDocument, options);
        }
export type GetTaskTypeQueryHookResult = ReturnType<typeof useGetTaskTypeQuery>;
export type GetTaskTypeLazyQueryHookResult = ReturnType<typeof useGetTaskTypeLazyQuery>;
export type GetTaskTypeQueryResult = Apollo.QueryResult<GetTaskTypeQuery, GetTaskTypeQueryVariables>;
export const GetTaskTypesDocument = gql`
    query getTaskTypes($sort: [String], $filters: TaskTypeFiltersInput, $pagination: PaginationArg) {
  taskTypes(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    data {
      id
      attributes {
        ...taskType
        category {
          data {
            id
            attributes {
              ...category
              featureImage {
                data {
                  ...uploadFile
                }
              }
            }
          }
        }
        creator {
          data {
            id
            attributes {
              ...member
            }
          }
        }
      }
    }
  }
}
    ${TaskTypeFragmentDoc}
${CategoryFragmentDoc}
${UploadFileFragmentDoc}
${MemberFragmentDoc}`;

/**
 * __useGetTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTypesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskTypesQuery, GetTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskTypesQuery, GetTaskTypesQueryVariables>(GetTaskTypesDocument, options);
      }
export function useGetTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskTypesQuery, GetTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskTypesQuery, GetTaskTypesQueryVariables>(GetTaskTypesDocument, options);
        }
export type GetTaskTypesQueryHookResult = ReturnType<typeof useGetTaskTypesQuery>;
export type GetTaskTypesLazyQueryHookResult = ReturnType<typeof useGetTaskTypesLazyQuery>;
export type GetTaskTypesQueryResult = Apollo.QueryResult<GetTaskTypesQuery, GetTaskTypesQueryVariables>;
export const GetTasksDocument = gql`
    query getTasks($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getBaoleaderTasksWithCount(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    count
    data {
      id
      attributes {
        ...task
        task_definition {
          data {
            id
            attributes {
              task_type {
                data {
                  id
                  attributes {
                    title
                  }
                }
              }
              category {
                data {
                  id
                  attributes {
                    featureImage {
                      data {
                        ...uploadFile
                      }
                    }
                  }
                }
              }
            }
          }
        }
        community {
          data {
            id
            attributes {
              name
            }
          }
        }
        project {
          data {
            id
            attributes {
              name
            }
          }
        }
        evidences {
          data {
            id
            attributes {
              custom_data_fields {
                ...componentCustomDataFieldsImageField
                __typename
              }
              photos {
                data {
                  ...uploadFile
                }
              }
              baorider {
                data {
                  id
                  attributes {
                    firstName
                    lastName
                    phoneNumber
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${TaskFragmentDoc}
${UploadFileFragmentDoc}
${ComponentCustomDataFieldsImageFieldFragmentDoc}`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export const GetTasksDashboardMetricsDocument = gql`
    query getTasksDashboardMetrics {
  getTasksDashboardMetrics {
    completedTasks {
      total
      last30Days
      chartData {
        key
        value
      }
    }
    reportedTask {
      total
      last30Days
      chartData {
        key
        value
      }
    }
    averageCompletionTime {
      total
      wow
      dod
    }
    averageEvidenceTime {
      total
      wow
      dod
    }
  }
}
    `;

/**
 * __useGetTasksDashboardMetricsQuery__
 *
 * To run a query within a React component, call `useGetTasksDashboardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksDashboardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksDashboardMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTasksDashboardMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksDashboardMetricsQuery, GetTasksDashboardMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksDashboardMetricsQuery, GetTasksDashboardMetricsQueryVariables>(GetTasksDashboardMetricsDocument, options);
      }
export function useGetTasksDashboardMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksDashboardMetricsQuery, GetTasksDashboardMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksDashboardMetricsQuery, GetTasksDashboardMetricsQueryVariables>(GetTasksDashboardMetricsDocument, options);
        }
export type GetTasksDashboardMetricsQueryHookResult = ReturnType<typeof useGetTasksDashboardMetricsQuery>;
export type GetTasksDashboardMetricsLazyQueryHookResult = ReturnType<typeof useGetTasksDashboardMetricsLazyQuery>;
export type GetTasksDashboardMetricsQueryResult = Apollo.QueryResult<GetTasksDashboardMetricsQuery, GetTasksDashboardMetricsQueryVariables>;
export const GetTopPerformingUsersDocument = gql`
    query getTopPerformingUsers($community: ID, $project: ID, $startDate: DateTime, $endDate: DateTime) {
  getTopPerformingUsers(community: $community, project: $project, startDate: $startDate, endDate: $endDate) {
    baoriders {
      baorider {
        id
        attributes {
          firstName
          lastName
        }
      }
      tasksCompleted
    }
    communityMembers {
      communityMember {
        id
        attributes {
          firstName
          lastName
        }
      }
      tasksCompleted
    }
    chartData {
      communityMembers {
        key
        value
      }
      baoriders {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetTopPerformingUsersQuery__
 *
 * To run a query within a React component, call `useGetTopPerformingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopPerformingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopPerformingUsersQuery({
 *   variables: {
 *      community: // value for 'community'
 *      project: // value for 'project'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetTopPerformingUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetTopPerformingUsersQuery, GetTopPerformingUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopPerformingUsersQuery, GetTopPerformingUsersQueryVariables>(GetTopPerformingUsersDocument, options);
      }
export function useGetTopPerformingUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopPerformingUsersQuery, GetTopPerformingUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopPerformingUsersQuery, GetTopPerformingUsersQueryVariables>(GetTopPerformingUsersDocument, options);
        }
export type GetTopPerformingUsersQueryHookResult = ReturnType<typeof useGetTopPerformingUsersQuery>;
export type GetTopPerformingUsersLazyQueryHookResult = ReturnType<typeof useGetTopPerformingUsersLazyQuery>;
export type GetTopPerformingUsersQueryResult = Apollo.QueryResult<GetTopPerformingUsersQuery, GetTopPerformingUsersQueryVariables>;
export const GetUnsdGsDocument = gql`
    query getUNSDGs($sort: [String], $filters: UnSdgFiltersInput, $pagination: PaginationArg) {
  unSdgs(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    data {
      id
      attributes {
        name
        task_types {
          data {
            id
            attributes {
              ...taskType
            }
          }
        }
        icon {
          data {
            ...uploadFile
          }
        }
        createdAt
        updatedAt
        publishedAt
      }
    }
  }
}
    ${TaskTypeFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetUnsdGsQuery__
 *
 * To run a query within a React component, call `useGetUnsdGsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnsdGsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnsdGsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUnsdGsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnsdGsQuery, GetUnsdGsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnsdGsQuery, GetUnsdGsQueryVariables>(GetUnsdGsDocument, options);
      }
export function useGetUnsdGsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnsdGsQuery, GetUnsdGsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnsdGsQuery, GetUnsdGsQueryVariables>(GetUnsdGsDocument, options);
        }
export type GetUnsdGsQueryHookResult = ReturnType<typeof useGetUnsdGsQuery>;
export type GetUnsdGsLazyQueryHookResult = ReturnType<typeof useGetUnsdGsLazyQuery>;
export type GetUnsdGsQueryResult = Apollo.QueryResult<GetUnsdGsQuery, GetUnsdGsQueryVariables>;
export const GetVideosDocument = gql`
    query getVideos($sort: [String], $filters: VideoFiltersInput, $pagination: PaginationArg) {
  videos(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    data {
      id
      attributes {
        title
        order
        category
        videoLink {
          data {
            ...uploadFile
          }
        }
      }
    }
  }
}
    ${UploadFileFragmentDoc}`;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetVideosQuery(baseOptions?: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
      }
export function useGetVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
        }
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<typeof useGetVideosLazyQuery>;
export type GetVideosQueryResult = Apollo.QueryResult<GetVideosQuery, GetVideosQueryVariables>;
export const GetWholeCompanyTaskTypesDocument = gql`
    query getWholeCompanyTaskTypes($sort: JSON, $filters: JSON, $pagination: PaginationArg) {
  getWholeCompanyTaskTypes(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      ...taskType
      category {
        data {
          id
          attributes {
            title
            featureImage {
              data {
                ...uploadFile
              }
            }
          }
        }
      }
      creator {
        data {
          id
          attributes {
            firstName
            lastName
          }
        }
      }
    }
  }
}
    ${TaskTypeFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetWholeCompanyTaskTypesQuery__
 *
 * To run a query within a React component, call `useGetWholeCompanyTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWholeCompanyTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWholeCompanyTaskTypesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetWholeCompanyTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetWholeCompanyTaskTypesQuery, GetWholeCompanyTaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWholeCompanyTaskTypesQuery, GetWholeCompanyTaskTypesQueryVariables>(GetWholeCompanyTaskTypesDocument, options);
      }
export function useGetWholeCompanyTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWholeCompanyTaskTypesQuery, GetWholeCompanyTaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWholeCompanyTaskTypesQuery, GetWholeCompanyTaskTypesQueryVariables>(GetWholeCompanyTaskTypesDocument, options);
        }
export type GetWholeCompanyTaskTypesQueryHookResult = ReturnType<typeof useGetWholeCompanyTaskTypesQuery>;
export type GetWholeCompanyTaskTypesLazyQueryHookResult = ReturnType<typeof useGetWholeCompanyTaskTypesLazyQuery>;
export type GetWholeCompanyTaskTypesQueryResult = Apollo.QueryResult<GetWholeCompanyTaskTypesQuery, GetWholeCompanyTaskTypesQueryVariables>;
export const HelpDocument = gql`
    query help {
  trainingVideo(publicationState: LIVE) {
    data {
      id
      attributes {
        training_videos {
          ...training_video
        }
        FAQs {
          ...faq
        }
        __typename
      }
    }
  }
}
    ${Training_VideoFragmentDoc}
${FaqFragmentDoc}`;

/**
 * __useHelpQuery__
 *
 * To run a query within a React component, call `useHelpQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpQuery(baseOptions?: Apollo.QueryHookOptions<HelpQuery, HelpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelpQuery, HelpQueryVariables>(HelpDocument, options);
      }
export function useHelpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelpQuery, HelpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelpQuery, HelpQueryVariables>(HelpDocument, options);
        }
export type HelpQueryHookResult = ReturnType<typeof useHelpQuery>;
export type HelpLazyQueryHookResult = ReturnType<typeof useHelpLazyQuery>;
export type HelpQueryResult = Apollo.QueryResult<HelpQuery, HelpQueryVariables>;
export const MeDocument = gql`
    query Me {
  myUser {
    id
    attributes {
      username
      firstName
      lastName
      email
      confirmed
      blocked
      receiveAllTimeWeeklyStatusReport
      receiveLoginReminder
      receiveWeeklyStatusReport
      onboardingInformation
      company {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetBaoleaderTaskDefinitionsDocument = gql`
    query getBaoleaderTaskDefinitions($sort: [String], $filters: JSON, $pagination: PaginationArg) {
  getBaoleaderTaskDefinitions(sort: $sort, filters: $filters, publicationState: LIVE, pagination: $pagination) {
    id
    attributes {
      category {
        data {
          id
          attributes {
            ...category
            featureImage {
              data {
                ...uploadFile
              }
            }
          }
        }
      }
      status
      task_type {
        data {
          id
          attributes {
            ...taskType
          }
        }
      }
    }
  }
}
    ${CategoryFragmentDoc}
${UploadFileFragmentDoc}
${TaskTypeFragmentDoc}`;

/**
 * __useGetBaoleaderTaskDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetBaoleaderTaskDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaoleaderTaskDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaoleaderTaskDefinitionsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetBaoleaderTaskDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<GetBaoleaderTaskDefinitionsQuery, GetBaoleaderTaskDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaoleaderTaskDefinitionsQuery, GetBaoleaderTaskDefinitionsQueryVariables>(GetBaoleaderTaskDefinitionsDocument, options);
      }
export function useGetBaoleaderTaskDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaoleaderTaskDefinitionsQuery, GetBaoleaderTaskDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaoleaderTaskDefinitionsQuery, GetBaoleaderTaskDefinitionsQueryVariables>(GetBaoleaderTaskDefinitionsDocument, options);
        }
export type GetBaoleaderTaskDefinitionsQueryHookResult = ReturnType<typeof useGetBaoleaderTaskDefinitionsQuery>;
export type GetBaoleaderTaskDefinitionsLazyQueryHookResult = ReturnType<typeof useGetBaoleaderTaskDefinitionsLazyQuery>;
export type GetBaoleaderTaskDefinitionsQueryResult = Apollo.QueryResult<GetBaoleaderTaskDefinitionsQuery, GetBaoleaderTaskDefinitionsQueryVariables>;
export const GetBaoleaderTaskDefinitionDocument = gql`
    query getBaoleaderTaskDefinition($id: ID!) {
  getBaoleaderTaskDefinition(id: $id) {
    id
    attributes {
      category {
        data {
          id
          attributes {
            ...category
            featureImage {
              data {
                ...uploadFile
              }
            }
          }
        }
      }
      status
      task_type {
        data {
          id
          attributes {
            title
            photoEvidence
            documentedEvidence
            environmentalImpact
            socialImpact
          }
        }
      }
    }
  }
}
    ${CategoryFragmentDoc}
${UploadFileFragmentDoc}`;

/**
 * __useGetBaoleaderTaskDefinitionQuery__
 *
 * To run a query within a React component, call `useGetBaoleaderTaskDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaoleaderTaskDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaoleaderTaskDefinitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBaoleaderTaskDefinitionQuery(baseOptions: Apollo.QueryHookOptions<GetBaoleaderTaskDefinitionQuery, GetBaoleaderTaskDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaoleaderTaskDefinitionQuery, GetBaoleaderTaskDefinitionQueryVariables>(GetBaoleaderTaskDefinitionDocument, options);
      }
export function useGetBaoleaderTaskDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaoleaderTaskDefinitionQuery, GetBaoleaderTaskDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaoleaderTaskDefinitionQuery, GetBaoleaderTaskDefinitionQueryVariables>(GetBaoleaderTaskDefinitionDocument, options);
        }
export type GetBaoleaderTaskDefinitionQueryHookResult = ReturnType<typeof useGetBaoleaderTaskDefinitionQuery>;
export type GetBaoleaderTaskDefinitionLazyQueryHookResult = ReturnType<typeof useGetBaoleaderTaskDefinitionLazyQuery>;
export type GetBaoleaderTaskDefinitionQueryResult = Apollo.QueryResult<GetBaoleaderTaskDefinitionQuery, GetBaoleaderTaskDefinitionQueryVariables>;
export const UsersDocument = gql`
    query Users($sort: [String], $filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg) {
  usersPermissionsUsers(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      id
      attributes {
        createdAt
        updatedAt
        username
        email
        provider
        confirmed
        blocked
        role {
          data {
            id
            attributes {
              name
              description
              type
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;