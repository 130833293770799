import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  SlideFade,
  Box,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Flex,
  Center
} from '@chakra-ui/react'

import React, { useState, useEffect } from 'react'
import ActionList from './components/List/components/ActionList'
import CreateDataList from './components/create'

import {
  DataListEntity,
  DataListOptionEntity,
  DataListEntity as DataListType,
  Enum_Datalist_Status,
  useFindDataListOptionWithCountQuery,
  useGetDataListsQuery
} from '../../../../generated/graphql'

import { useCallback } from 'react'
import './styles.css'
import {
  useUpdateDataListMutation,
  useUpdateDataListOptionMutation,
  Enum_Datalistoption_Status
} from '../../../../generated/graphql'
import { VscEmptyWindow, VscFolderActive } from 'react-icons/vsc'
import { Spinner } from '@chakra-ui/react'
import Lister from './components/List/components/Lister'
import { CustomTab } from 'components'

type DataListProps = {
  isOpen: boolean
  onClose: () => void
}
export enum STAGE {
  list,
  details,
  create,
  update
}

const DataList: React.FC<DataListProps> = ({ isOpen, onClose }) => {
  const [stage, setStage] = useState<STAGE>(STAGE.list)
  const {
    data: activeDataLists,
    refetch: refetchActiveDataLists,
    loading: activeDataListsLoading
  } = useGetDataListsQuery({
    variables: {
      filters: {
        status: { $eq: Enum_Datalist_Status.Active }
      }
    }
  })

  const {
    data: deactiveDataLists,
    refetch: refetchDeactiveDataLists,
    loading: deactiveDataListsLoading
  } = useGetDataListsQuery({
    variables: {
      filters: {
        status: { $eq: Enum_Datalist_Status.Inactive }
      }
    }
  })
  const [selectedItem, setSelectedItem] = useState<null | DataListType>(null)
  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)
  const [limitInactive, setLimitInactive] = React.useState(10)
  const [startInactive, setStartInactive] = React.useState(0)
  const {
    data: activeDataListOptionsData,
    refetch: activeRefetchDataListOptions,
    loading: activeDataListOptionsLoading
  } = useFindDataListOptionWithCountQuery({
    variables: {
      filters: {
        data_list: { id: selectedItem?.id },
        status: Enum_Datalistoption_Status.Active
      },
      pagination: {
        limit: limitInactive,
        start: startInactive
      }
    }
  })
  const countActive = activeDataListOptionsData?.findDataListOptionWithCount?.count
  const activeDataListOptions = activeDataListOptionsData?.findDataListOptionWithCount?.data

  const {
    data: inactiveDataListOptionsData,
    refetch: inactiveRefetchDataListOptions,
    loading: inactiveDataListOptionsLoading
  } = useFindDataListOptionWithCountQuery({
    variables: {
      filters: {
        data_list: { id: selectedItem?.id },
        status: Enum_Datalistoption_Status.Inactive
      },
      pagination: {
        limit: limitInactive,
        start: startInactive
      }
    }
  })

  const countInactive = inactiveDataListOptionsData?.findDataListOptionWithCount?.count
  const inactiveDataListOptions = inactiveDataListOptionsData?.findDataListOptionWithCount?.data

  const [updateDataLis] = useUpdateDataListMutation()
  const [updateDataLisOption] = useUpdateDataListOptionMutation()

  const deactivateOption = useCallback(
    async (id: string) => {
      try {
        await updateDataLisOption({
          variables: {
            id,
            input: {
              status: Enum_Datalistoption_Status.Inactive
            }
          }
        })
        await activeRefetchDataListOptions()
        await inactiveRefetchDataListOptions()
      } catch (error) {
        console.log(error)
      }
    },
    [activeRefetchDataListOptions, inactiveRefetchDataListOptions]
  )
  const activateOption = useCallback(
    async (id: string) => {
      try {
        await updateDataLisOption({
          variables: {
            id,
            input: {
              status: Enum_Datalistoption_Status.Active
            }
          }
        })
        await activeRefetchDataListOptions()
        await inactiveRefetchDataListOptions()
      } catch (error) {
        console.log(error)
      }
    },
    [activeRefetchDataListOptions, inactiveRefetchDataListOptions]
  )
  const deactivateList = useCallback(async (id: string) => {
    try {
      await updateDataLis({
        variables: {
          id,
          input: {
            status: Enum_Datalist_Status.Inactive
          }
        }
      })
      await refetchActiveDataLists()
      await refetchDeactiveDataLists()
    } catch (error) {
      console.log(error)
    }
  }, [])
  const activateList = useCallback(async (id: string) => {
    try {
      await updateDataLis({
        variables: {
          id,
          input: {
            status: Enum_Datalist_Status.Active
          }
        }
      })
      await refetchActiveDataLists()
      await refetchDeactiveDataLists()
    } catch (error) {
      console.log(error)
    }
  }, [])
  useEffect(() => {
    ;(async () => {
      await activeRefetchDataListOptions()
      await inactiveRefetchDataListOptions()
    })()
  }, [stage])

  const loading =
    activeDataListsLoading ||
    inactiveDataListOptionsLoading ||
    deactiveDataListsLoading ||
    activeDataListOptionsLoading

  const tabPanels = [
    {
      component: (
        <Lister
          data={activeDataLists?.getDataLists as DataListType[]}
          deactivateList={deactivateList}
          activateList={activateList}
          type="active"
          setSelectedItem={setSelectedItem}
          setStage={setStage}
          dataListOptions={activeDataListOptions as DataListOptionEntity[]}
          selectedItem={selectedItem}
          stage={stage}
          callBack={async () => {
            await activeRefetchDataListOptions({
              pagination: {
                limit,
                start
              }
            })
            await inactiveRefetchDataListOptions()
          }}
          deactivateListOption={deactivateOption}
          refetchDataListOptions={activeRefetchDataListOptions}
          activateListOption={activateOption}
          setLimit={setLimit}
          start={start}
          setStart={setStart}
          count={countActive || 0}
          limit={limit}
        />
      )
    },
    {
      component: (
        <Lister
          data={deactiveDataLists?.getDataLists as DataListEntity[]}
          deactivateList={deactivateList}
          activateList={activateList}
          type="inactive"
          setSelectedItem={setSelectedItem}
          setStage={setStage}
          dataListOptions={inactiveDataListOptions as DataListOptionEntity[]}
          selectedItem={selectedItem}
          stage={stage}
          callBack={async () => {
            await activeRefetchDataListOptions()
            await inactiveRefetchDataListOptions()
          }}
          refetchDataListOptions={inactiveRefetchDataListOptions}
          activateListOption={activateOption}
          deactivateListOption={deactivateOption}
          setLimit={setLimitInactive}
          start={startInactive}
          setStart={setStartInactive}
          count={countInactive || 0}
          limit={limitInactive}
        />
      )
    }
  ]
  const tabs = [
    {
      title: `Active ${
        stage === STAGE.details
          ? `Items (${countActive})`
          : `List (${activeDataLists?.getDataLists?.length})`
      }`,
      icon: <VscFolderActive />,
      loading: loading,
      bg: 'background.100'
    },
    {
      title: `Archived ${
        stage === STAGE.details
          ? `Items (${countInactive})`
          : `List (${deactiveDataLists?.getDataLists?.length})`
      }`,
      icon: <VscEmptyWindow />,
      loading: loading,
      bg: 'background.100'
    }
  ]
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <ActionList setStage={setStage} stage={stage} />
            {stage === STAGE.create && (
              <Box flex={0.9}>
                <SlideFade in={stage === STAGE.create} offsetY="-20px">
                  <CreateDataList
                    callBack={async () => {
                      await refetchActiveDataLists()
                      await refetchDeactiveDataLists()
                    }}
                  />
                </SlideFade>
              </Box>
            )}
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Tabs colorScheme="green">
            <TabList>
              {tabs?.map((tab, index) => (
                <CustomTab tab={tab} key={index} />
              ))}
            </TabList>

            {loading ? (
              <Center py={10}>
                <Spinner colorScheme="green" />
              </Center>
            ) : (
              <TabPanels>
                {tabPanels.map((tabPanel, index) => (
                  <TabPanel key={index} p={0}>
                    {tabPanel.component}
                  </TabPanel>
                ))}
              </TabPanels>
            )}
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default DataList
