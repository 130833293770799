import { FormControl, Input, Text, HStack, InputGroup, Box } from '@chakra-ui/react'
import * as React from 'react'
import { VscCheck } from 'react-icons/vsc'
import QuestionTypePicker from './QuestionTypePicker'
import { SupportedDataFieldKeysType } from '../types'
import { FloatingButtonWrapper, ModalWrap } from 'components'
import { PrimaryButton, SecondaryButton } from '../../../components/UI/Buttons/index'

const QuestionModal: React.FC<{
  isOpen: boolean
  isLoading: boolean
  onClose(): void
  onConfirm: (question: string, fieldType: SupportedDataFieldKeysType) => void
}> = ({ onConfirm, isOpen, onClose, isLoading }) => {
  const [question, setQuestion] = React.useState('')
  const [qnError, setQnError] = React.useState('')
  const [qnTypeError, setQnTypeError] = React.useState('')
  const [selectedFieldType, setSelectedFieldType] = React.useState<SupportedDataFieldKeysType | ''>(
    ''
  )

  const handleConfirm = () => {
    if (question === '') {
      setQnTypeError('')
      setQnError('Enter a question')
    } else if (!selectedFieldType) {
      setQnError('')
      setQnTypeError('select a question type below')
    } else {
      setQnError('')
      setQnTypeError('')

      onClose()
      onConfirm(question, selectedFieldType)
      setQuestion('')
      setSelectedFieldType('')
    }
  }

  return (
    <ModalWrap isOpen={isOpen} onClose={onClose} title="Add Question" scrollBehavior="outside">
      <Box mb={16}>
        <FloatingButtonWrapper
          button={
            <HStack w="100%">
              <SecondaryButton onClick={onClose} isLoading={isLoading}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                leftIcon={<VscCheck />}
                w="100%"
                onClick={handleConfirm}
                isLoading={isLoading}
                isDisabled={isLoading || !question || !selectedFieldType}
              >
                Add Question
              </PrimaryButton>
            </HStack>
          }
          status={qnError}
          buttonTitle="Login"
        >
          <FormControl>
            <InputGroup>
              <Input
                name="question"
                value={question}
                placeholder="Enter question ..."
                variant="filled"
                fontWeight="semibold"
                outlineColor="green.300"
                focusBorderColor="transparent"
                size="lg"
                fontSize="lg"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setQuestion(event?.target?.value)
                }
              />
            </InputGroup>
            <Text color="red.500" p={1} borderRadius={5} fontSize="xs" textAlign="left">
              {qnError}
            </Text>
          </FormControl>

          <QuestionTypePicker
            qnTypeError={qnTypeError}
            selectedFieldType={selectedFieldType}
            setSelectedFieldType={setSelectedFieldType}
          />
        </FloatingButtonWrapper>
      </Box>
    </ModalWrap>
  )
}

export default QuestionModal
