import { Container, Link } from '@chakra-ui/react'
import AlertIndicator from 'components/UX/AlertIndicator'
import { Enum_Subscription_Status, Maybe } from 'generated/graphql'
import React from 'react'

type SubscripitonIndicatorProps = {
  status: Enum_Subscription_Status | undefined | string
  cancelAtPeriodEnd?: Maybe<boolean> | undefined
}

const SubscripitonIndicator: React.FC<SubscripitonIndicatorProps> = ({
  status,
  cancelAtPeriodEnd
}) => {
  return (
    <Container position="relative" maxW="1600" mt={0} mb={6} px={14}>
      <AlertIndicator title="Important notice about your subscription plan" status="warning">
        {
          /* eslint-disable-next-line no-nested-ternary */
          status === Enum_Subscription_Status.Active
            ? 'You are currently subscribed to our services'
            : status === Enum_Subscription_Status.Trialing
            ? 'You are currently on a free trial'
            : status === Enum_Subscription_Status.PastDue
            ? 'Your subscription has expired. To continue using our services, please update your payment method'
            : status === Enum_Subscription_Status.Canceled
            ? 'Your subscription has been canceled'
            : status === Enum_Subscription_Status.Incomplete
            ? "You haven't selected a plan and set up a subscription yet. Please do so to continue using our services"
            : 'Your subscription status is unknown'
        }
        {cancelAtPeriodEnd && '(will be canceled at the end of the period)'}. Please{' '}
        <Link href="/auth/settings?tab=3" fontWeight="extrabold">
          click here to manage your subscription and plan.
        </Link>
      </AlertIndicator>
    </Container>
  )
}

export default SubscripitonIndicator
