import React from 'react'
import moment from 'moment'
import {
  Flex,
  VStack,
  Text,
  HStack,
  Skeleton,
  useToast,
  Heading,
  useDisclosure,
  Stack,
  Box
} from '@chakra-ui/react'
import {
  Card,
  CustomTooltip,
  EmptyListHandler,
  FloatingButtonWrapper,
  StatusBadge
} from 'components'
import { ConnectedFormGroup, ConnectedSelect } from 'components/forms/FormElements'
import { Formik, Form } from 'formik'
import {
  Enum_Userspermissionsuser_Status,
  ProjectEntity,
  UsersPermissionsUser,
  useGetCommunitiesQuery
} from 'generated/graphql'

import { CommunityMember } from 'utils/types'
import { addUserSchema } from 'utils/validationSchemas'
import { Maybe } from '../../../../generated/graphql'

import { OutlineButton, PrimaryButton } from 'components/UI/Buttons'

import { ERROR_TOAST } from '../../../../constants'
import { OptionType } from 'components/forms/FormElements/ConnectedSelect'
import ProjectCards from './ProjectCards'
import AddToProjectModal from './AddToProjectModal'
import {
  activateDataCollectorTooltipContent,
  deactivateDataCollectorTooltipContent
} from 'features/datacollectors/details/help'

type DataCollectorsDetailsFormProps = {
  storedBaorider:
    | (Maybe<Omit<CommunityMember, 'community'>> & { community: Maybe<string> })
    | undefined
  loading?: boolean
  submitForm: (values: CommunityMember) => void
  setShowDeactivateConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  setShowReactivateConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  removeProject: (projectId: Maybe<string>) => void
  addProject: (projectId: Maybe<string>) => void
  callBack?: () => void
}

const DataCollectorsDetailsForm: React.FC<DataCollectorsDetailsFormProps> = ({
  storedBaorider,
  submitForm,
  loading,
  setShowDeactivateConfirmation,
  setShowReactivateConfirmation,
  removeProject,
  addProject,
  callBack
}) => {
  const toast = useToast()

  const onError = () => {
    toast({ title: 'There was an error.', ...ERROR_TOAST })
  }

  const { onClose, isOpen, onOpen } = useDisclosure()
  const { data: storedCommunities } = useGetCommunitiesQuery({
    onError
  })

  const dataCollectorProjects = ({ ...storedBaorider } as Maybe<UsersPermissionsUser>)?.projects
    ?.data

  const dcProjectsIds = dataCollectorProjects?.map((project) => project?.id) || []

  return (
    <HStack alignItems="flex-start" spacing={32}>
      <VStack spacing={24} flex={1.4}>
        <Formik
          initialValues={storedBaorider as CommunityMember}
          validationSchema={addUserSchema}
          onSubmit={submitForm}
          enableReinitialize={true}
        >
          {({ handleSubmit, dirty, status }) => (
            <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <FloatingButtonWrapper
                status={status}
                button={
                  <HStack width="100%">
                    {storedBaorider?.status === Enum_Userspermissionsuser_Status.Active && (
                      <CustomTooltip label={deactivateDataCollectorTooltipContent}>
                        <Box>
                          <OutlineButton
                            variant="ghost"
                            colorScheme="red"
                            isLoading={loading}
                            onClick={() => setShowDeactivateConfirmation(true)}
                            flex={1}
                          >
                            Deactivate this user?
                          </OutlineButton>
                        </Box>
                      </CustomTooltip>
                    )}
                    {storedBaorider?.status !== Enum_Userspermissionsuser_Status.Active && (
                      <CustomTooltip label={activateDataCollectorTooltipContent}>
                        <Box>
                          <OutlineButton
                            variant="ghost"
                            colorScheme="green"
                            isLoading={loading}
                            onClick={() => setShowReactivateConfirmation(true)}
                            flex={1}
                          >
                            Reactivate this user?
                          </OutlineButton>
                        </Box>
                      </CustomTooltip>
                    )}
                    <PrimaryButton flex={1} isDisabled={!dirty} type="submit">
                      Update
                    </PrimaryButton>
                  </HStack>
                }
              >
                <Flex w="100%" mb={8} justify="space-between">
                  <VStack w="50%" alignItems="flex-start" spacing={0}>
                    <Heading
                      as="h3"
                      size="md"
                      color="gray.600"
                      fontWeight="semibold"
                      textAlign="left"
                    >
                      Basic Profile
                    </Heading>
                    <Skeleton isLoaded={!loading}>
                      <Text fontSize="xs" color="gray.500">
                        Joined {moment(storedBaorider?.createdAt).format('LL')}
                      </Text>
                    </Skeleton>
                  </VStack>
                  <StatusBadge status={storedBaorider?.status} withIcon />
                </Flex>
                <Flex mb={1}>
                  <Skeleton mr={1} w="100%" isLoaded={!loading}>
                    <ConnectedFormGroup
                      name="firstName"
                      label="First name *"
                      placeholder="Enter first name"
                    />
                  </Skeleton>
                  <Skeleton w="100%" isLoaded={!loading}>
                    <ConnectedFormGroup
                      name="lastName"
                      label="Last name *"
                      placeholder="Enter last name"
                    />
                  </Skeleton>
                </Flex>
                <Skeleton isLoaded={!loading}>
                  <ConnectedFormGroup
                    name="phoneNumber"
                    label="Phone Number *"
                    placeholder="Enter phone number"
                    showIsValidValue
                  />
                </Skeleton>
                <Heading
                  as="h3"
                  size="md"
                  mt={10}
                  mb={5}
                  color="gray.600"
                  fontWeight="semibold"
                  textAlign="left"
                >
                  Group
                </Heading>

                <Skeleton flex={1} mr={3} isLoaded={!loading}>
                  <ConnectedSelect
                    name="community"
                    label="Group"
                    width="100%"
                    placeholder="No Group"
                    options={
                      ((storedCommunities?.getBaoleaderCommunities || []).map((item) => {
                        return { label: item?.attributes?.name, value: item?.id }
                      }) as OptionType[]) || []
                    }
                  />
                </Skeleton>
              </FloatingButtonWrapper>
            </Form>
          )}
        </Formik>
      </VStack>

      <Stack align="center" flex={1} spacing={16}>
        <Card width="100%" p={5}>
          <HStack w="100%" spacing={6} mb={5}>
            <VStack alignItems="flex-start" flex={1} spacing={0}>
              <Skeleton isLoaded={!loading}>
                <Heading as="h3" size="md" color="gray.600" fontWeight="semibold" textAlign="left">
                  {storedBaorider?.firstName}&apos;s Projects
                </Heading>
              </Skeleton>
              <Skeleton isLoaded={!loading} flex={1}>
                <Text fontSize="xs" color="gray.500">
                  {dataCollectorProjects?.length} projects assigned
                </Text>
              </Skeleton>
            </VStack>
            <PrimaryButton onClick={onOpen} isLoading={loading} variant="outline" size="lg">
              Assign to a Project
            </PrimaryButton>
          </HStack>

          <ProjectCards
            projects={dataCollectorProjects as Maybe<ProjectEntity>[]}
            status="remove"
            columns={2}
            columnGap={2}
            loading={loading}
            emptyHandler={
              <EmptyListHandler
                title="No projects assigned."
                withImage={false}
                subTitle="Assign this user to a project to get started."
              />
            }
            onAction={(_index, project) => {
              removeProject(project?.id || '')
            }}
          />
        </Card>
      </Stack>

      {!loading && (
        <AddToProjectModal
          addProject={() => {
            addProject(storedBaorider?.id || '')
          }}
          callBack={callBack}
          storedBaorider={storedBaorider}
          isOpen={isOpen}
          onClose={onClose}
          dcProjectsIds={dcProjectsIds}
        />
      )}
    </HStack>
  )
}

export default DataCollectorsDetailsForm
