import { Checkbox, CheckboxProps, Flex, FormLabel, Icon } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { VscCheck } from 'react-icons/vsc'
import { Text } from '../../../../typography'
import { LabelProps } from '../styles'

export type ConnectedCheckboxProps = LabelProps &
  CheckboxProps & {
    label?: string
    name: string
  }

const ConnectedCheckbox: React.FC<ConnectedCheckboxProps> = ({ label, ...rest }) => {
  const [field, meta] = useField(rest.name)

  return (
    <Flex
      mb={rest.mb}
      ml={rest.ml}
      mr={rest.mr}
      mt={rest.mt}
      width="100%"
      direction="column"
      justify={rest.justifyContent}
    >
      <Flex align="center">
        <Checkbox
          {...field}
          id={field.name}
          {...rest}
          overflow="hidden"
          size="md"
          m={0}
          isChecked={field.value}
          border=".5px solid"
          borderColor={meta.touched && meta.error ? 'red.300' : 'green.300'}
          colorScheme="green"
          icon={<Icon as={VscCheck} transform="rotate(-45deg)" />}
          rounded="md"
          width="fit-content"
          transform="rotate(45deg)"
        />
        {label && (
          <FormLabel
            ml={2}
            mb={0}
            cursor="pointer"
            fontSize="xs"
            fontWeight="500"
            color={meta.touched && meta.error ? 'red.500' : 'gray.800'}
            htmlFor={field.name}
          >
            {label}
          </FormLabel>
        )}
      </Flex>
      {meta.touched && meta.error ? (
        <Text mt={1} color="red.500" fontSize="xs">
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  )
}

export default ConnectedCheckbox

ConnectedCheckbox.defaultProps = {
  alignItems: 'center',
  justifyContent: 'flex-start'
}
