import React from 'react'
import {
  useDisclosure,
  Heading,
  HStack,
  FormControl,
  FormLabel,
  Text,
  Switch,
  Box,
  Skeleton
} from '@chakra-ui/react'
import { AlertModal } from 'components'
import { useMeQuery, useUpdateMyUserMutation } from 'generated/graphql'

const WeeklyStatus: React.FC = () => {
  const { data: meData, refetch: refetchMeData, loading: loadingMyUser } = useMeQuery()
  const [updateMyUser, { loading: updatetingMyUser }] = useUpdateMyUserMutation({
    onCompleted: async () => {
      await refetchMeData()
    }
  })
  const {
    isOpen: isReceiveWeeklyStatusReportOpen,
    onOpen: onReceiveWeeklyStatusReportOpen,
    onClose: onReceiveWeeklyStatusReportClose
  } = useDisclosure()
  const me = meData?.myUser
  const loading = loadingMyUser || updatetingMyUser
  return (
    <Box>
      <AlertModal
        activatorStatus={me?.attributes?.receiveWeeklyStatusReport ? 'deactivate' : 'activate'}
        onConfirm={async () => {
          await updateMyUser({
            variables: {
              input: {
                receiveWeeklyStatusReport: !me?.attributes?.receiveWeeklyStatusReport
              }
            }
          })
          onReceiveWeeklyStatusReportClose()
        }}
        isOpen={isReceiveWeeklyStatusReportOpen}
        onClose={onReceiveWeeklyStatusReportClose}
        loading={updatetingMyUser}
        title="Receive Weekly Status Report"
        confirmButtonText={me?.attributes?.receiveWeeklyStatusReport ? 'Deactivate' : 'Activate'}
      >
        <Heading fontSize="sm">
          Are you sure you want to{' '}
          {me?.attributes?.receiveWeeklyStatusReport ? 'deactivate' : 'activate'} the weekly status
          report?
        </Heading>
        <Text fontSize="xs" mt={2}>
          {me?.attributes?.receiveWeeklyStatusReport
            ? 'You will no longer receive weekly status reports'
            : 'You will receive weekly status reports'}
        </Text>
      </AlertModal>
      <HStack>
        <FormControl display="flex" onChange={onReceiveWeeklyStatusReportOpen} alignItems="center">
          <Skeleton isLoaded={!loading}>
            <FormLabel cursor="pointer" htmlFor="weekly-status" mb="0">
              Receive Company Weekly Status Report
            </FormLabel>
          </Skeleton>

          <Skeleton isLoaded={!loading}>
            <Switch
              id="weekly-status"
              colorScheme="green"
              isChecked={me?.attributes?.receiveWeeklyStatusReport || false}
            />
          </Skeleton>
        </FormControl>
      </HStack>
      <Skeleton isLoaded={!loading}>
        <Text color="gray.600" fontSize="xs">
          {me?.attributes?.receiveWeeklyStatusReport
            ? 'You will receive weekly status reports'
            : 'You will no longer receive weekly status reports'}
        </Text>
      </Skeleton>
    </Box>
  )
}

export default WeeklyStatus
