import { Stack, Text } from '@chakra-ui/react'
import { AlertIndicator } from 'components'
import React from 'react'
import { Maybe, EvidenceEntity } from '../../../../generated/graphql'

type PermissionsCheckerProps = {
  evidence: Maybe<EvidenceEntity> | undefined
  loading: boolean
}

const PermissionsChecker: React.FC<PermissionsCheckerProps> = ({ evidence, loading }) => {
  return (
    <Stack w="100%" spacing={3}>
      {evidence?.attributes?.baorider?.data?.attributes?.camera_permission === false && (
        <AlertIndicator isLoading={loading} status="warning">
          <Text fontWeight="semibold" mr={1}>
            {evidence?.attributes?.baorider?.data?.attributes?.firstName}{' '}
            {evidence?.attributes?.baorider?.data?.attributes?.lastName}
          </Text>
          hasn&apos;t allow the camera permission.
        </AlertIndicator>
      )}
      {evidence?.attributes?.baorider?.data?.attributes?.location_permission === false && (
        <AlertIndicator isLoading={loading} status="warning">
          <Text fontWeight="semibold" mr={1}>
            {evidence?.attributes?.baorider?.data?.attributes?.firstName}{' '}
            {evidence?.attributes?.baorider?.data?.attributes?.lastName}
          </Text>
          hasn&apos;t allow the location permission
          {evidence?.attributes?.baorider?.data?.attributes?.location_permission === null
            ? ' yet'
            : ''}
          .
        </AlertIndicator>
      )}
      {evidence?.attributes?.baorider?.data?.attributes?.media_library_permission === false && (
        <AlertIndicator isLoading={loading} status="warning">
          <Text fontWeight="semibold" mr={1}>
            {evidence?.attributes?.baorider?.data?.attributes?.firstName}{' '}
            {evidence?.attributes?.baorider?.data?.attributes?.lastName}
          </Text>
          hasn&apos;t allow the media gallery permission.
        </AlertIndicator>
      )}
    </Stack>
  )
}

export default PermissionsChecker
