import { SimpleGrid } from '@chakra-ui/react'

import React from 'react'
import { images } from 'theme'

import { useHistory } from 'react-router-dom'
import { StatusParserProps } from './StatusParser'
import { FeaturedCard } from 'components'
import { useGetTaskTypeQuery } from 'generated/graphql'
import { getImageByFormat } from 'utils'

type EvidenceRelationshipsProps = Omit<StatusParserProps, 'evidence'>

const EvidenceRelationships: React.FC<EvidenceRelationshipsProps> = ({ data }) => {
  const history = useHistory()
  const community = data?.getBaoleaderTask?.attributes?.community?.data
  const project = data?.getBaoleaderTask?.attributes?.project?.data
  const { data: taskTypeEntity } = useGetTaskTypeQuery({
    variables: {
      id:
        data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.task_type?.data
          ?.id || ''
    }
  })

  const unSdg = taskTypeEntity?.getTaskType?.attributes?.un_sdg?.data

  return (
    <SimpleGrid width="100%" templateColumns={unSdg ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'} gap={4}>
      {project?.attributes && (
        <FeaturedCard
          title="Group"
          value={community?.attributes?.name}
          onClick={() => {
            history.push(`/auth/communities/${community?.id}`)
          }}
          image={getImageByFormat(community?.attributes?.featured_image?.data, 'thumbnail')}
        />
      )}
      {project?.attributes?.name && (
        <FeaturedCard
          title="Project"
          value={project?.attributes?.name}
          onClick={() => {
            history.push(`/auth/projects/${project?.id}`)
          }}
          image={getImageByFormat(project?.attributes?.featured_image?.data, 'thumbnail')}
        />
      )}
      {data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.task_type?.data && (
        <FeaturedCard
          title="Task"
          onClick={() => {
            history.push(
              `/auth/task-types-list/detail/${data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.task_type?.data?.id}`
            )
          }}
          value={
            data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.task_type?.data
              ?.attributes?.title || ''
          }
          image={getImageByFormat(
            data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.category?.data
              ?.attributes?.featureImage?.data,
            'thumbnail'
          )}
        />
      )}
      {unSdg && (
        <FeaturedCard
          title="UN SDG"
          value={unSdg?.attributes?.name || ''}
          image={
            unSdg?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url || images.unLogo
          }
        />
      )}
    </SimpleGrid>
  )
}

export default EvidenceRelationships
