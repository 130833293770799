import { Tooltip, TooltipProps } from '@chakra-ui/react'
import { HintElementProps } from 'components/utils/interfaces'
import React, { ReactNode } from 'react'

interface CustomTooltipProps
  extends TooltipProps,
    Omit<HintElementProps, 'fontSize' | 'textColor'> {
  label?: string | ReactNode
  customTrigger?: ReactNode
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ label, children, ...rest }) => {
  return (
    <Tooltip hasArrow label={label} bg="gray.100" p={2} borderRadius="lg" color="black" {...rest}>
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
