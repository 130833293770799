import React, { Suspense } from 'react'
import {
  FcParallelTasks,
  FcTodoList,
  FcSettings,
  FcPhoneAndroid,
  FcCollaboration,
  FcInspection
} from 'react-icons/fc'
import ReactGA from 'react-ga4'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader, SideBar } from '../components'
import PageNotFound from '../containers/PageNotFound'
import { useAuthContext } from '../context/AuthProvider'

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'

import { DATA_COLLECTOR, GA_MEASURING_ID } from '../constants'
import { ErrorBoundary } from 'layouts'
import { theme } from 'theme'
import { useSocketConnector } from 'hooks'

import { uniqBy } from 'lodash'

import { VscQuestion } from 'react-icons/vsc'

const ICON_COLOR = theme.colors.gray[500]
const ICON_SIZE = 16
interface RouteType extends RouteProps {
  component: any
}

export type NavItem = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
}

const NAV_ITEMS: NavItem[] = [
  {
    to: `/auth/tasks`,
    title: 'Task Feed',
    icon: <FcTodoList size={ICON_SIZE} color={ICON_COLOR} className="sidebar-menu-icon" />
  },
  {
    to: `/auth/task-types-list`,
    title: 'Task Builder',
    icon: <FcInspection size={ICON_SIZE} color={ICON_COLOR} className="sidebar-menu-icon" />
  },
  {
    to: `/auth/projects`,
    title: 'Projects',
    icon: <FcParallelTasks size={ICON_SIZE} color={ICON_COLOR} className="sidebar-menu-icon" />
  },
  {
    to: `/auth/communities`,
    title: 'Groups',
    icon: <FcCollaboration size={ICON_SIZE} color={ICON_COLOR} className="sidebar-menu-icon" />
  },
  {
    to: `/auth/baoriders`,
    title: DATA_COLLECTOR + 's',
    icon: <FcPhoneAndroid size={ICON_SIZE} className="sidebar-menu-icon" />
  },
  {
    to: `/auth/settings`,
    title: 'Settings',
    icon: <FcSettings size={ICON_SIZE} color={ICON_COLOR} className="sidebar-menu-icon" />
  }
]
const SECONDARY_NAV_ITEMS: NavItem[] = [
  {
    to: '/auth/help',
    title: 'Help',
    icon: <VscQuestion size={20} color={ICON_COLOR} className="sidebar-menu-icon" />
  }
]

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { isAuthenticating, isAuthenticated } = useAuthContext()

  if (isAuthenticating) {
    return <FillLoader />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Suspense fallback={<FillLoader color="black" />}>
            <Component {...rest} />
          </Suspense>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
  </Route>
)

const Navigation = () => {
  //initialize google analytics
  ReactGA.initialize(GA_MEASURING_ID)
  React.useEffect(() => {
    ReactGA.send('pageview')
  }, [])

  useSocketConnector()

  return (
    <ErrorBoundary>
      <Router>
        <Suspense fallback={<FillLoader />}>
          <Switch>
            {PUBLIC_ROUTES.map((route) => {
              return <PublicRoute key={route.path} {...route} />
            })}

            {/* Private routes without the admin wrapper */}
            {PRIVATE_ROUTES.filter((route) => route.hidePageWrap).map((route) => {
              return <PrivateRoute key={route.path} {...route} />
            })}

            {/* Normal auth routes with admin wrapper */}
            <Route
              path="/auth"
              render={() => {
                return (
                  <SideBar
                    bg="background.100"
                    color={ICON_COLOR}
                    navItems={uniqBy(NAV_ITEMS, 'to')}
                    hoverColor={theme.colors.background[200]}
                    accentColor={theme.colors.brand[400]}
                    secondaryNavItems={uniqBy(SECONDARY_NAV_ITEMS, 'to')}
                    tooltipBg={theme.colors.brand[400]}
                    tooltipColor={theme.colors.gray[100]}
                  >
                    {PRIVATE_ROUTES.filter((route) => !route.hidePageWrap).map((route) => (
                      <PrivateRoute key={route.path} {...route} />
                    ))}
                  </SideBar>
                )
              }}
            />
            <Route path="/terms-and-conditions.pdf" />
            <Route render={PageNotFound} />
          </Switch>
        </Suspense>
      </Router>
    </ErrorBoundary>
  )
}

export default Navigation
