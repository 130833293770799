import React from 'react'
import {
  useDisclosure,
  Heading,
  HStack,
  FormControl,
  FormLabel,
  Text,
  Switch,
  Box,
  Skeleton
} from '@chakra-ui/react'
import { AlertModal } from 'components'
import { useMeQuery, useUpdateMyUserMutation } from 'generated/graphql'

const LoginReminder: React.FC = () => {
  const { data: meData, refetch: refetchMeData, loading: loadingMyUser } = useMeQuery()
  const [updateMyUser, { loading: updatetingMyUser }] = useUpdateMyUserMutation({
    onCompleted: async () => {
      await refetchMeData()
    }
  })
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure()
  const me = meData?.myUser
  const loading = loadingMyUser || updatetingMyUser
  return (
    <Box>
      <AlertModal
        activatorStatus={me?.attributes?.receiveLoginReminder ? 'deactivate' : 'activate'}
        onConfirm={async () => {
          await updateMyUser({
            variables: {
              input: {
                receiveLoginReminder: !me?.attributes?.receiveLoginReminder
              }
            }
          })
          onClose()
        }}
        isOpen={isOpen}
        onClose={onClose}
        loading={updatetingMyUser}
        title="Receive Login Reminder"
        confirmButtonText={me?.attributes?.receiveLoginReminder ? 'Deactivate' : 'Activate'}
      >
        <Heading fontSize="sm">
          Are you sure you want to{' '}
          {me?.attributes?.receiveLoginReminder ? 'deactivate' : 'activate'} the login reminder?
        </Heading>
        <Text fontSize="xs" mt={2}>
          {me?.attributes?.receiveLoginReminder
            ? 'You will no longer Login Reminder'
            : 'You will Login Reminder'}
        </Text>
      </AlertModal>
      <HStack>
        <FormControl display="flex" onChange={onOpen} alignItems="center">
          <Skeleton isLoaded={!loading}>
            <FormLabel cursor="pointer" htmlFor="login-reminder" mb="0">
              Receive Login Reminder
            </FormLabel>
          </Skeleton>

          <Skeleton isLoaded={!loading}>
            <Switch
              id="login-reminder"
              colorScheme="green"
              isChecked={me?.attributes?.receiveLoginReminder || false}
            />
          </Skeleton>
        </FormControl>
      </HStack>
      <Skeleton isLoaded={!loading}>
        <Text color="gray.600" fontSize="xs">
          {me?.attributes?.receiveLoginReminder
            ? 'You will receive login reminders'
            : 'You will no longer receive login reminders'}
        </Text>
      </Skeleton>
    </Box>
  )
}

export default LoginReminder
