import { useToast, Text, Skeleton } from '@chakra-ui/react'
import { TasksTable } from 'components'

import * as React from 'react'

import { FcElectricalSensor, FcPhoneAndroid, FcTodoList } from 'react-icons/fc'
import { useHistory } from 'react-router-dom'
import { pluralHandler } from 'utils'

import { DATA_COLLECTOR, ERROR_TOAST } from '../../../constants'

import {
  TaskEntity,
  useCountRidersQuery,
  useCountTasksQuery,
  useGetTasksQuery
} from '../../../generated/graphql'
import { PageListContainer } from '../../../layouts'

const TasksView: React.FC<{ filters?: any }> = (props) => {
  const toast = useToast()
  const history = useHistory()
  const [limit, setLimit] = React.useState(() => (history.location.state as any)?.limit || 10)
  const [start, setStart] = React.useState(() => (history.location.state as any)?.currentPage || 0)
  const [page, setPage] = React.useState(0)
  const [filters, setFilters] = React.useState<any>(() => ({}))
  const [search, setSearch] = React.useState('')
  const pagination = { limit, start }
  const {
    data: tasks,
    refetch,
    loading
  } = useGetTasksQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: { ...filters, id: { $contains: search }, ...props?.filters },
      pagination
    },
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    }
  })

  const data = tasks?.getBaoleaderTasksWithCount?.data
  const taskCount = tasks?.getBaoleaderTasksWithCount?.count
  const { data: count, loading: countLoading } = useCountTasksQuery()

  const { data: countTaskCompleted, loading: countTaskCompletedLoading } = useCountTasksQuery({
    variables: {
      filters: {
        status: 'COMPLETED'
      }
    }
  })
  const { data: countTaskPending, loading: countTaskPendingLoading } = useCountTasksQuery({
    variables: {
      filters: {
        status: 'PENDING'
      }
    }
  })

  const { data: countVerifiers, loading: countVerifiersLoading } = useCountRidersQuery()

  const onFilter = async (where: { [key: string]: string | number | string[] }) => {
    try {
      setFilters(where)

      await refetch()
    } catch (e) {
      console.error(e)
    }
  }

  const onReset = async () => {
    await refetch({ filters: {} })
  }

  const stats = React.useMemo(
    () => [
      {
        title: 'Tasks Overview',
        icon: FcElectricalSensor,
        loading: countLoading,
        value: `${count?.countTasks || 0} tasks reported`
      },
      {
        title: 'Tasks Pending',
        icon: FcElectricalSensor,
        loading: countTaskPendingLoading,
        value: `${countTaskPending?.countTasks || 0} pending tasks`
      },
      {
        title: 'Tasks Completed',
        icon: FcElectricalSensor,
        loading: countTaskCompletedLoading,
        value: `${countTaskCompleted?.countTasks || 0} tasks completed`
      },
      {
        title: 'Number of ' + DATA_COLLECTOR + 's',
        icon: FcPhoneAndroid,
        loading: countVerifiersLoading,
        value: `${countVerifiers?.countRiders || 0} ${DATA_COLLECTOR}s`
      }
    ],
    [countLoading, countTaskCompletedLoading, countTaskPendingLoading, countVerifiersLoading]
  )
  const titleActions = () => (
    <Skeleton isLoaded={!loading}>
      <Text fontWeight="semibold" color="gray.800" fontSize="xs">
        {taskCount || 0} {pluralHandler('task', taskCount || 0)}
      </Text>
    </Skeleton>
  )
  const contents = (
    <TasksTable
      loading={loading}
      data={(data || []) as TaskEntity[]}
      onFilter={onFilter}
      titleActions={titleActions}
      onSearch={(search) => {
        setSearch(search)
      }}
      searchValue={search}
      currentPage={page}
      limit={limit}
      start={start}
      count={taskCount || 0}
      onReset={onReset}
      setLimit={setLimit}
      setPage={setPage}
      setStart={setStart}
    />
  )
  return props.filters ? (
    contents
  ) : (
    <PageListContainer
      title="Tasks"
      icon={<FcTodoList />}
      loading={loading}
      stats={stats}
      bg="white"
    >
      {contents}
    </PageListContainer>
  )
}

export default TasksView
