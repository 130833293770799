import { Flex, Container } from '@chakra-ui/react'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { BackButton } from '../../../components'

import { PageListContainer } from '../../../layouts'

import TaskTypes from '../details/components/TaskTypes'

const EditProject: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  return (
    <PageListContainer
      title="Project Tasks"
      pageActions={
        <Container my={8} maxW="1600" px={16}>
          <Flex>
            <BackButton
              title=" Back to the project"
              onClick={() => history.push(`/auth/projects/${id}`)}
            />
          </Flex>
        </Container>
      }
      mt={0}
    >
      <TaskTypes />
    </PageListContainer>
  )
}

export default EditProject
