import {
  useToast,
  useDisclosure,
  Flex,
  Heading,
  Image,
  Link as LinkChakra,
  Skeleton,
  HStack,
  SimpleGrid,
  VStack,
  Center,
  Box,
  Text,
  Spinner
} from '@chakra-ui/react'
import { Card, EmptyListHandler, AlertModal, CustomTooltip } from 'components'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'
import AddTaskModal from 'containers/OnBoarding/components/AddTaskModal'
import TaskCards from 'containers/OnBoarding/components/TaskCards'
import {
  useGetSingleBaoleaderProjectQuery,
  useCreateTaskDefinitionMutation,
  useUpdateBaoleaderTaskDefinitionMutation,
  TaskDefinitionInput,
  Enum_Taskdefinition_Status,
  Enum_Tasktype_Status,
  useGetBaoleaderTaskDefinitionsQuery
} from 'generated/graphql'

import React from 'react'

import { BiTask } from 'react-icons/bi'
import { MdAdd } from 'react-icons/md'
import { useParams, useHistory } from 'react-router-dom'
import { images } from 'theme'

import { TaskDefinitionEntity } from '../../../../generated/graphql'

import { FcDisclaimer } from 'react-icons/fc'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'

import { addTaskTypeToolTip, createTaskTypeToolTip, taskTypeTitleHelperPopover } from '../help'

type TaskTypesProps = {}

const TaskTypes: React.FC<TaskTypesProps> = () => {
  const { id } = useParams<{ id: string }>()
  const toast = useToast()
  const onError = (error: any) => toast({ title: `${error}`, ...ERROR_TOAST })
  const {
    data: storedTasks,
    refetch,
    loading
  } = useGetBaoleaderTaskDefinitionsQuery({
    variables: {
      filters: {
        project: { id }
      }
    },
    onError,
    fetchPolicy: 'cache-first'
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isActivateModalOpen,
    onOpen: onActivateModalOpen,
    onClose: onActivateModalClose
  } = useDisclosure()
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [editItemIndex, setEditItemIndex] = React.useState(-1)
  const [editableTask, setEditableTask] = React.useState({} as TaskDefinitionEntity)
  const { data: storedProject } = useGetSingleBaoleaderProjectQuery({
    variables: { id },
    onError
  })

  const [createTask, { loading: createLoading }] = useCreateTaskDefinitionMutation({
    onCompleted: async () => {
      await refetch()
      toast({ title: 'Task created.', ...SUCCESS_TOAST })
    },
    onError
  })

  const [editTask, { loading: editLoading }] = useUpdateBaoleaderTaskDefinitionMutation({
    onCompleted: async () => {
      await refetch()
      toast({ title: 'Task update.', ...SUCCESS_TOAST })
    },
    onError
  })

  const history = useHistory()
  const isLoading = createLoading || editLoading
  const [tasks, setTasks] = React.useState<TaskDefinitionEntity[]>([])

  React.useEffect(() => {
    storedTasks &&
      setTasks((storedTasks?.getBaoleaderTaskDefinitions || []) as TaskDefinitionEntity[])
  }, [storedTasks])

  const addTask = async (task: TaskDefinitionInput) => {
    await createTask({
      variables: {
        input: {
          ...(task as TaskDefinitionInput),
          project: storedProject?.getSingleBaoleaderProject?.id
        }
      }
    })
  }

  const updateTask = async (_index: number, id: string, task: TaskDefinitionInput) => {
    await editTask({
      variables: {
        id: id,
        input: task as TaskDefinitionInput
      }
    })
  }
  const thumbnail =
    storedProject?.getSingleBaoleaderProject?.attributes?.featured_image?.data?.attributes?.formats
      ?.thumbnail?.url
  const workingTask = React.useRef<TaskDefinitionEntity>()
  const removeTask = React.useCallback(
    async (index: number, isActive: boolean) => {
      if (
        storedTasks?.getBaoleaderTaskDefinitions &&
        storedTasks?.getBaoleaderTaskDefinitions[index]
      ) {
        const task = storedTasks?.getBaoleaderTaskDefinitions?.[index] as TaskDefinitionEntity
        workingTask.current = task

        if (
          isActive &&
          task?.attributes?.task_type?.data?.attributes?.status === Enum_Tasktype_Status.Deactivated
        ) {
          onActivateModalOpen()
        } else {
          const newTaskDefinition: TaskDefinitionInput = {
            category: task?.attributes?.category?.data?.id,
            task_type: task?.attributes?.task_type?.data?.id,
            status: isActive
              ? Enum_Taskdefinition_Status.Active
              : Enum_Taskdefinition_Status.Deactivated
          }

          await editTask({
            variables: {
              id: task?.id || '',
              input: newTaskDefinition
            }
          })
        }
      }
    },
    [storedTasks?.getBaoleaderTaskDefinitions]
  )

  const onActivateTask = async () => {
    onActivateModalClose()
    history.push(
      '/auth/task-types-list/detail/' + workingTask.current?.attributes?.task_type?.data?.id,
      {
        project: storedProject
      }
    )
  }

  return (
    <>
      <AlertModal
        {...{
          isOpen: isActivateModalOpen,
          onClose: onActivateModalClose
        }}
        icon={<FcDisclaimer size={38} />}
        title="Unable to Turn Task On"
        confirmButtonText="Go to Task Builder"
        confirmButtonColor="yellow"
        activatorStatus="activate"
        onConfirm={onActivateTask}
      >
        <Text fontWeight="500">
          The associated Task in the Task Builder is turned off, kindly check and it turn on there.
        </Text>
      </AlertModal>
      <Box bg="background.100" width="100%">
        <Flex mb={4} width="100%" fontSize="sm" align="center" justify="space-between">
          <Flex align="center">
            <Heading color="gray.600" size="lg">
              <Skeleton isLoaded={!loading} maxW="fit-content">
                Tasks
              </Skeleton>
            </Heading>
            {taskTypeTitleHelperPopover}
          </Flex>
          <HStack spacing={4}>
            <CustomTooltip label={createTaskTypeToolTip}>
              <Skeleton my={6} isLoaded={!loading}>
                <SecondaryButton
                  onClick={() =>
                    history.push('/auth/task-types-list/create-task', {
                      project: storedProject
                    })
                  }
                  leftIcon={<BiTask />}
                  minW={200}
                  isLoading={isLoading}
                >
                  Create Task
                </SecondaryButton>
              </Skeleton>
            </CustomTooltip>

            <CustomTooltip label={addTaskTypeToolTip}>
              <Skeleton my={6} isLoaded={!loading}>
                <PrimaryButton
                  onClick={onOpen}
                  leftIcon={<MdAdd />}
                  minW={200}
                  isLoading={isLoading}
                >
                  Add Task
                </PrimaryButton>
              </Skeleton>
            </CustomTooltip>
          </HStack>
        </Flex>

        <SimpleGrid templateColumns={{ md: '2fr 1.5fr', sm: '1fr' }} spacing={10}>
          <VStack spacing={6}>
            <Card width="100%" px={4}>
              {loading ? (
                <Center height="40vh">
                  <Spinner color="green.300" />
                </Center>
              ) : tasks && tasks?.length > 0 ? (
                <TaskCards
                  tasks={tasks}
                  onAction={async (action, index, task, isActive) => {
                    if (action === 'edit') {
                      onOpen()
                      setEditItemIndex(index)
                      setIsEditMode(true)
                      setEditableTask(task)
                    }
                    if (action === 'delete') {
                      await removeTask(index, isActive || false)
                    }
                  }}
                />
              ) : (
                <EmptyListHandler
                  title={`No task available for ${storedProject?.getSingleBaoleaderProject?.attributes?.name}`}
                />
              )}
            </Card>
            <Flex w="100%" fontSize="x-small" justify="center" align="center" my={3}>
              <LinkChakra
                target="_blank"
                href="mailto:customers@baotree.ai?subject=Want to suggest a task"
              >
                Want to suggest a task?
              </LinkChakra>
            </Flex>
          </VStack>
          <VStack spacing={6}>
            <Center w="100%">
              <Image
                objectFit="cover"
                rounded="2xl"
                boxSize={32}
                src={thumbnail ? thumbnail : images.logoNoText}
                alt="featured image"
              />
            </Center>
          </VStack>
        </SimpleGrid>
      </Box>

      <AddTaskModal
        {...{ isOpen, onOpen, onClose, editableTask, isEditMode, setIsEditMode }}
        isLoading={isLoading}
        projectImage={
          storedProject?.getSingleBaoleaderProject?.attributes?.featured_image?.data?.attributes
            ?.url
        }
        onSubmit={async (task, addAnother) => {
          if (isEditMode) {
            const { category, task_type } = task
            await updateTask(editItemIndex, editableTask?.id || '', {
              ...{ category, task_type }
            })

            setEditItemIndex(-1)
            setIsEditMode(false)
          } else {
            addTask(task)
          }
          if (!addAnother) {
            onClose()
          }
        }}
      />
    </>
  )
}

export default TaskTypes
