import axios, { AxiosResponse } from 'axios'
import { fetchJwt } from '.'
import * as XLSX from 'xlsx'
import { Maybe, UploadFile, UsersPermissionsUserEntity } from '../generated/graphql'
import { omit } from 'lodash'

const BASE = process.env.REACT_APP_API_HOST || ''

export type StrapiLoginPayload = {
  jwt: string
  user: UsersPermissionsUserEntity
}

const downloadCsv = async (filterValues: any) => {
  const where = {
    ...omit(filterValues, 'task_definition', 'date_range', 'baorider'),
    ...(filterValues.date_range && {
      created_at_gte: (filterValues.date_range as any)[0] || undefined,
      created_at_lte: (filterValues.date_range as any)[1] || undefined
    }),
    ...(filterValues.task_definition && {
      'task_definition.category': (filterValues.task_definition as any).category || undefined,
      'task_definition.task_type': (filterValues.task_definition as any).task_type || undefined
    }),
    project: filterValues?.project || undefined,
    baorider: filterValues.baorider || undefined
  }
  try {
    const result = await axios.post(
      BASE + '/export-tasks',
      {
        data: JSON.stringify(where)
      },
      {
        headers: {
          Authorization: `Bearer ${fetchJwt()}`
        }
      }
    )

    const blob = new Blob([result.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `tasks(${new Date().toLocaleDateString()}).csv`
    link.click()
  } catch (error) {
    return Promise.reject(error)
  }
}

const downloadExcel = async (result: any) => {
  return new Promise((resolve, reject) => {
    try {
      if (result) {
        const data = result || []

        const wb = XLSX.utils.book_new()
        Object.keys(data).forEach((key) => {
          // control for field order, irrespective of the fields, lat, long, accuracy should always be last
          let columns = Object.keys(
            data[key].reduce((acc: any, obj: any) => Object.assign(acc, obj), {})
          )
          const columnsToRemove = ['latitude', 'longitude', 'accuracy']

          columns = columns.filter((column) => !columnsToRemove.includes(column))

          // Append the removed columns to the end of the array
          columns = columns.concat(columnsToRemove)

          const ws = XLSX.utils.json_to_sheet(data[key], {
            header: columns
          })
          XLSX.utils.book_append_sheet(wb, ws, `${key.split('::')[1]}`)
        })
        XLSX.writeFile(wb, `tasks(${new Date().toLocaleDateString()}).xlsx`)
      }
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}

const downloadBaoriderCsv = async (
  baoriders: Maybe<UsersPermissionsUserEntity>[],
  filename: string
) => {
  try {
    const result = await axios.post(
      BASE + '/export-baoriders',
      { baoriders },
      {
        headers: {
          Authorization: `Bearer ${fetchJwt()}`
        }
      }
    )
    const blob = new Blob([result.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename + '.csv'
    link.click()
  } catch (error) {
    return Promise.reject(error)
  }
}

const downloadCommunityMemberCsv = async (communityID: string) => {
  try {
    const result = await axios.post(
      BASE + '/export-communitymembers',
      {
        communityID
      },
      {
        headers: {
          Authorization: `Bearer ${fetchJwt()}`
        }
      }
    )
    const blob = new Blob([result.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'Commnuity Members.csv'
    link.click()
  } catch (error) {
    return Promise.reject(error)
  }
}

const forgotPassword = async (email: string): Promise<AxiosResponse<any>> => {
  try {
    return await axios.post(BASE + '/api/auth/forgot-password', {
      email
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const resetPassword = async (code: string, password: string, passwordConfirmation: string) => {
  try {
    return await axios.post(BASE + '/api/auth/reset-password', {
      code,
      password,
      passwordConfirmation
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const login = async (
  identifier: string,
  password: string
): Promise<AxiosResponse<StrapiLoginPayload>> => {
  try {
    return await axios.post(BASE + '/api/auth/local', {
      identifier,
      password,
      type: 'baoleader'
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const register = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  companyName: string
): Promise<AxiosResponse<StrapiLoginPayload>> => {
  try {
    return await axios.post(BASE + '/api/auth/local/register', {
      firstName,
      lastName,
      email,
      password,
      companyName
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const upload = async (
  file: File,
  onUploadProgress?: (progressEvent: ProgressEvent, file: File) => void
): Promise<AxiosResponse<UploadFile[]>> => {
  const formData = new FormData()
  formData.append('files', file)
  try {
    return await axios.post(BASE + '/api/upload', formData, {
      onUploadProgress: (event) => onUploadProgress && onUploadProgress(event, file),
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${await fetchJwt()}`
      }
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const createCheckoutSession = async (priceId: string): Promise<AxiosResponse<any>> => {
  try {
    return await axios.post(
      `${BASE}/companies/create-checkout-session`,
      { priceId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await fetchJwt()}`
        }
      }
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

const createPaymentIntent = async (): Promise<AxiosResponse<any>> => {
  try {
    return axios.post(
      `${BASE}/companies/create-payment-intent`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await fetchJwt()}`
        }
      }
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

const sendVerificationEmail = async (userId: number): Promise<AxiosResponse<any>> => {
  try {
    return await axios.post(BASE + '/api/auth/send-email-confirmation', {
      userId
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const confirmEmail = async (u: number, pin: string): Promise<AxiosResponse<any>> => {
  try {
    return await axios.get(BASE + `/api/auth/email-confirmation?u=${u}&pin=${pin}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

const crushObj = (obj: any): any =>
  Object.keys(obj).reduce(
    (acc, cur) =>
      typeof obj[cur] === 'object'
        ? { ...acc, ...crushObj(obj[cur]) }
        : { ...acc, [cur]: obj[cur] },
    {}
  )

const openBillingPortal = async () => {
  const res = await axios.post(
    `${BASE}/api/stripe-billing`,
    {},
    {
      headers: {
        Authorization: `Bearer ${await fetchJwt()}`
      }
    }
  )
  window.location.href = res?.data?.url
}

const handleCreateCheckoutSession = async () => {
  const res = await axios.post(
    `${BASE}/api/stripe-billing/create-checkout-session`,
    {},
    {
      headers: {
        Authorization: `Bearer ${await fetchJwt()}`
      }
    }
  )
  window.location.href = res?.data?.url
}

export default {
  openBillingPortal,
  forgotPassword,
  resetPassword,
  login,
  register,
  upload,
  createCheckoutSession,
  createPaymentIntent,
  downloadCsv,
  downloadExcel,
  downloadBaoriderCsv,
  downloadCommunityMemberCsv,
  sendVerificationEmail,
  confirmEmail,
  crushObj,
  handleCreateCheckoutSession
}
