import { Image, Spinner, Text } from '@chakra-ui/react'
import { FormWrapper } from 'components'
import * as React from 'react'
import { images } from 'theme'
import { H5 } from 'typography'
import strapiHelpers from 'utils/strapiHelpers'

const ConfirmEmail: React.FC = () => {
  const [hasPin, setHasPin] = React.useState(false)

  const [hasUserId, setHasUserId] = React.useState(false)
  const [emailVerified, setEmailVerified] = React.useState(false)

  const [errors, setErrors] = React.useState(false)
  React.useEffect(() => {
    // get the token from the url
    const urlParams = new URLSearchParams(window.location.search)
    const pin = urlParams.get('pin')
    const userId = urlParams.get('u')

    const verifyEmail = async (pin: string, userId: string) => {
      await strapiHelpers
        .confirmEmail(parseInt(userId), pin)
        .then(() => {
          setEmailVerified(true)
        })
        .catch(() => {
          setErrors(true)
        })
    }

    if (userId && pin) {
      setHasUserId(true)
      setHasPin(true)
      verifyEmail(pin, userId)
    } else {
      setErrors(true)
    }
    // send the pin to the backend to confirm the email
  }, [])

  return (
    <FormWrapper title="Confirm Email">
      <Image src={images.logo} width="150px" />
      <H5 fontWeight="bold" my={4}>
        Email Verification
      </H5>
      {hasPin && hasUserId && !emailVerified && !errors && (
        <>
          <Text>We are verifying your email address. Please wait...</Text>
          <Spinner />
        </>
      )}
      {errors && (
        <Text textAlign="center">
          There was an error verifying your email address. Please try to resend the email from your
          dashboard.
        </Text>
      )}
      {emailVerified && (
        <Text textAlign="center">
          Your email address has been verified. You can now return to the dashboard and continue
          with onboarding!
        </Text>
      )}
    </FormWrapper>
  )
}

export default ConfirmEmail
