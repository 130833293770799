import { Flex, Image, useToast } from '@chakra-ui/react'
import { FormWrapper, FloatingButtonWrapper } from 'components'
import { ConnectedPasswordGroup } from 'components/forms/FormElements'
import { SUCCESS_TOAST } from '../../../constants'
import { Form, Formik, FormikProps } from 'formik'

import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { images } from 'theme'
import { H3 } from 'typography'
import strapiHelpers from 'utils/strapiHelpers'
import * as Yup from 'yup'
import { formatError } from 'utils'

const ResetPasswordFormValidation = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('A password is required'),
  confirmPassword: Yup.string()
    .required('Confirm your password')
    .oneOf([Yup.ref('password')], 'Password does not match')
})

type ResetPasswordProps = {}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const toast = useToast()
  const location = useLocation()
  const history = useHistory()

  const INITIAL_VALUES = {
    password: '',
    confirmPassword: '',
    code: new URLSearchParams(location.search).get('code') as string
  }

  return (
    <FormWrapper image={images.baotreeHomeBg3} withBack title="Reset Password">
      <Flex width="100%" direction="column" justify="center" align="center">
        <Image src={images.logo} height="50px" mb={3} />
        <H3 textAlign="center" mb={4}>
          Create Password
        </H3>
      </Flex>
      <Formik
        validationSchema={ResetPasswordFormValidation}
        initialValues={INITIAL_VALUES}
        onSubmit={async ({ code, password, confirmPassword }, { setSubmitting, setStatus }) => {
          setStatus(null)
          try {
            setSubmitting(true)
            await strapiHelpers.resetPassword(code, password, confirmPassword)
            setSubmitting(false)
            toast({ title: 'Your password was updated.', ...SUCCESS_TOAST })
            history.push('/')
          } catch (error) {
            setStatus(formatError(error))
          }
        }}
      >
        {({ isSubmitting, status }: FormikProps<typeof INITIAL_VALUES>) => (
          <Form autoComplete="off" style={{ width: '100%' }}>
            <FloatingButtonWrapper status={status} buttonTitle="Confirm" isLoading={isSubmitting}>
              <ConnectedPasswordGroup name="password" label="Password" placeholder="Password" />
              <ConnectedPasswordGroup
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm Password"
              />
            </FloatingButtonWrapper>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  )
}

export default ResetPassword
