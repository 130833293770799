import { Stack, Text, TextProps, HStack, Box } from '@chakra-ui/react'
import { CustomTooltip } from 'components'
import moment from 'moment'
import React from 'react'

import { MdWifiTethering, MdWifiTetheringOff } from 'react-icons/md'

type DateAndTimeProps = {
  dateAndTime: any
  isOnline?: boolean
} & TextProps

const DateAndTime: React.FC<DateAndTimeProps> = ({ dateAndTime, isOnline, ...rest }) => {
  return (
    <HStack alignItems="flex-start">
      {(isOnline === true || isOnline === false) && (
        <CustomTooltip label={`Task Reported ${!isOnline ? 'Offline' : 'Online'}`}>
          <Box p={1}>
            {isOnline === true && <MdWifiTethering />}
            {isOnline === false && <MdWifiTetheringOff />}
          </Box>
        </CustomTooltip>
      )}
      <Stack spacing={0} fontSize="x-small" color="gray.500">
        <Text fontWeight={500} {...rest}>
          {moment(dateAndTime).format('DD MMM YYYY')}
        </Text>

        <Text color="gray.400" {...rest}>
          {moment(dateAndTime).format('HH:mm')}
        </Text>
      </Stack>
    </HStack>
  )
}
DateAndTime.defaultProps = {}

export default DateAndTime
