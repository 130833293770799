import { Flex, VStack, HStack, Heading, SimpleGrid, Box, Text, Image } from '@chakra-ui/react'
import { Card, StatusBadge } from 'components'
import { Enum_Task_Status, GetBaoleaderTaskQuery } from 'generated/graphql'
import moment from 'moment'
import React from 'react'

import { images } from 'theme'
import EvidenceTime from './EvidenceTime'

const checkTime = (date: any) => {
  const currentTime = moment()
  const timeStore = moment(date)
  const hourDiff = currentTime.diff(timeStore, 'h')
  const dayDiff = currentTime.diff(timeStore, 'd')
  const monthDiff = currentTime.diff(timeStore, 'm')
  const yearDiff = currentTime.diff(timeStore, 'y')
  if (hourDiff < 25) {
    if (hourDiff === 1) {
      return `${hourDiff} hour ago`
    } else {
      return `${hourDiff} hours ago`
    }
  } else if (dayDiff < 32) {
    if (dayDiff === 1) {
      return `${dayDiff} day ago`
    } else {
      return `${dayDiff} days ago`
    }
  } else if (monthDiff < 12) {
    if (monthDiff === 1) {
      return `${monthDiff} month ago`
    } else {
      return `${monthDiff} months ago`
    }
  } else {
    if (yearDiff === 1) {
      return `${yearDiff} year ago`
    } else {
      return `${yearDiff} years ago`
    }
  }
}

type EvidenceDetailsProps = {
  data: GetBaoleaderTaskQuery | undefined
}

const EvidenceDetails: React.FC<EvidenceDetailsProps> = ({ data }) => {
  const isCompleted = data?.getBaoleaderTask?.attributes?.status === Enum_Task_Status.Completed
  const evidenceDates = []

  if (data?.getBaoleaderTask?.attributes?.date_reported)
    evidenceDates.push(data?.getBaoleaderTask?.attributes?.date_reported)
  if (data?.getBaoleaderTask?.attributes?.createdAt)
    evidenceDates.push(data?.getBaoleaderTask?.attributes.createdAt)
  if (
    data?.getBaoleaderTask?.attributes?.updatedAt &&
    !isCompleted &&
    data?.getBaoleaderTask?.attributes?.status !== Enum_Task_Status.Pending
  )
    evidenceDates.push(data?.getBaoleaderTask?.attributes?.updatedAt)
  if (isCompleted) evidenceDates.push(data?.getBaoleaderTask?.attributes?.updatedAt)
  return (
    <Card width="100%" border="none">
      <Flex direction="column" height="100%" width="100%" flex={1}>
        <Box width="100%" px={6}>
          <Flex w="100%" py={4} direction="row" align="center">
            <Flex w="100%" direction="row" align="center">
              <Image
                src={
                  data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.category
                    ?.data?.attributes?.featureImage?.data?.attributes?.formats?.thumbnail?.url
                    ? `${data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.category?.data?.attributes?.featureImage?.data?.attributes?.formats?.thumbnail?.url}`
                    : images.logoNoText
                }
                boxSize={10}
                draggable="false"
                rounded="2xl"
                objectFit="cover"
                mr={3}
              />
              <VStack alignItems="flex-start" spacing={0}>
                <HStack spacing={4} w="100%">
                  <Heading as="h5" fontWeight="500" size="xs">
                    {
                      data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes
                        ?.task_type?.data?.attributes?.title
                    }
                  </Heading>
                  <StatusBadge py={1}>
                    {checkTime(data?.getBaoleaderTask?.attributes?.createdAt)}
                  </StatusBadge>
                </HStack>

                <Text fontSize="x-small" color="gray.500" width="100%">
                  {
                    data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.category
                      ?.data?.attributes?.title
                  }{' '}
                  -{' '}
                  {
                    data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.task_type
                      ?.data?.attributes?.title
                  }
                </Text>
              </VStack>
            </Flex>
            <Flex direction="row" align="center" justify="flex-end">
              <StatusBadge status={data?.getBaoleaderTask?.attributes?.status} withIcon />
            </Flex>
          </Flex>
          <SimpleGrid pb={4} templateColumns={`repeat(${evidenceDates?.length}, 1fr)`}>
            {data?.getBaoleaderTask?.attributes?.date_reported && (
              <EvidenceTime
                title="Reported Offline At"
                dateAndTime={data?.getBaoleaderTask?.attributes?.date_reported}
                isOnline={!!!data?.getBaoleaderTask?.attributes?.date_reported}
              />
            )}
            {data?.getBaoleaderTask?.attributes?.createdAt && (
              <EvidenceTime
                title="Date Submitted"
                dateAndTime={data?.getBaoleaderTask?.attributes?.createdAt}
              />
            )}

            {data?.getBaoleaderTask?.attributes?.updatedAt &&
              !isCompleted &&
              data?.getBaoleaderTask?.attributes?.status !== Enum_Task_Status.Pending && (
                <EvidenceTime
                  title="Date Reviewed"
                  dateAndTime={data?.getBaoleaderTask?.attributes?.updatedAt}
                  textAlign={evidenceDates?.length === 2 ? 'right' : 'left'}
                />
              )}
            {isCompleted && (
              <EvidenceTime
                title="Completed At"
                dateAndTime={data?.getBaoleaderTask?.attributes?.updatedAt}
              />
            )}
          </SimpleGrid>
        </Box>
      </Flex>
    </Card>
  )
}

export default EvidenceDetails
