import React, { ReactNode } from 'react'
import {
  TaskTypeEntity,
  useActivateTaskTypeMutation,
  useDeactivateTaskTypeMutation
} from '../../../generated/graphql'
import { PageListContainer } from 'layouts'
import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  useToast,
  Skeleton,
  Center,
  Spinner
} from '@chakra-ui/react'
import { CustomTooltip, EmptyListHandler, ListLoader } from 'components'
import { pluralHandler } from 'utils'

import TaskTypeCard from './components/TaskTypeCard'
import { MdAdd, MdList } from 'react-icons/md'
import { Link, useHistory } from 'react-router-dom'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../../constants'
import ListActions from './components/ListActions'
import { SearchFilters, Sorts, SuspendedTaskTypes } from '../types'
import { AnimatePresence } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import {
  useCountTaskTypesQuery,
  useGetWholeCompanyTaskTypesQuery
} from '../../../generated/graphql'
import { images } from 'theme'
import { VStack, useDisclosure } from '@chakra-ui/react'
import TaskTypeDetails from '../datails/index'
import { Enum_Tasktype_Status } from '../../../generated/graphql'
import { useBrowserStorage } from 'hooks'

import ListOptions from './components/ListOptions'
import { find } from 'lodash'
import SmallSubNav from './components/SmallSubNav'
import MoreContentsLoader from './components/MoreContentsLoader'
import DataList from '../components/DataList'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'
import { FcElectricalSensor, FcInspection } from 'react-icons/fc'
import { SUSPENDED_TASK_TYPES } from '../constants'
import posthog from 'posthog-js'
import {
  createNewTaskButtonHelperTooltip,
  dataListButtonHelperTooltip,
  tasksBuilderHelpPopover
} from './help'

const filterHandler = (searchTerm: string) => {
  return {
    title: { $contains: searchTerm }
  }
}
const sortHandler = (filter: SearchFilters, sort: Sorts) => {
  switch (filter) {
    case 'task_type':
      return { title: sort }
    case 'category':
      return { category: { title: sort } }
    case 'createdAt':
      return { createdAt: sort }
    case 'updatedAt':
      return { updatedAt: sort }
    default:
      return undefined
  }
}

const LIMIT_PER_PAGE = 10

type TaskTypesListProps = {}

const TaskTypesList: React.FC<TaskTypesListProps> = ({}) => {
  const [searchTerm, setSearchTerm] = React.useState('')

  const history = useHistory()
  const [sort, setSort] = React.useState<Sorts>('desc')
  const [sortBy, setSortBy] = React.useState<SearchFilters | undefined>('createdAt')
  const [limit, setLimit] = React.useState<number>(LIMIT_PER_PAGE)
  const [statusFilter, setStatusFilter] = React.useState<Enum_Tasktype_Status>()
  const [ref, inView] = useInView({ threshold: 1, triggerOnce: true })
  const [activeCategory, setActiveCategory] = React.useState<undefined | string>()
  const [activeCreator, setActiveCreator] = React.useState<undefined | string>()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { data, loading, refetch } = useGetWholeCompanyTaskTypesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: {
        ...filterHandler(searchTerm),
        category: { id: activeCategory },
        creator: { id: activeCreator },
        ...(statusFilter && { status: statusFilter })
      },
      pagination: { limit },
      sort: sort && sortBy && sortHandler(sortBy, sort)
    }
  })

  const [suspendedTaskTypes, setSuspendedTaskTypes, _removeSuspendedTaskTypes] = useBrowserStorage<
    SuspendedTaskTypes[]
  >(SUSPENDED_TASK_TYPES, 'local')

  const [detailsContainerSubNavOptions, setDetailsContainerSubNavOptions] =
    React.useState<() => JSX.Element>()

  const [detailsContainerSubNavRightElement, setDetailsContainerSubRightElement] =
    React.useState<ReactNode>()

  const [detailsElementRef, detailsElementRefInView] = useInView({ threshold: 0 })

  const activeCard = React.useRef<HTMLDivElement>(null)
  const detailsTopRef = React.useRef<HTMLDivElement>(null)
  const [selectedTaskType, setSelectedTaskType] = React.useState<string | undefined>(
    () => (history.location.state as any)?.seletedTaskType
  )
  const { data: count, loading: countLoading, refetch: refetchCount } = useCountTaskTypesQuery()
  const {
    data: countActive,
    loading: countActiveLoading,
    refetch: refetchCountActive
  } = useCountTaskTypesQuery({
    variables: {
      filters: {
        status: Enum_Tasktype_Status.Active
      }
    }
  })
  const {
    data: countDeactived,
    loading: countDeactivedLoading,
    refetch: refetchCountDeactived
  } = useCountTaskTypesQuery({
    variables: {
      filters: {
        status: Enum_Tasktype_Status.Deactivated
      }
    }
  })
  const [scrollable, setScrollable] = React.useState(false)

  const toast = useToast()

  const [activateTaskType] = useActivateTaskTypeMutation({
    onCompleted: async () => {
      await refetch()
      await refetchCount()
      await refetchCountActive()
      await refetchCountDeactived()
      toast({ title: 'Task updated', ...SUCCESS_TOAST })
      posthog.capture('dashboard_task_type_activated')
    },
    onError: (err) => {
      toast({
        title: `Unable to update task -> ${err.message}`,
        ...ERROR_TOAST
      })
    }
  })
  const [deactivateTaskType] = useDeactivateTaskTypeMutation({
    onCompleted: async () => {
      await refetch()
      await refetchCount()
      await refetchCountActive()
      await refetchCountDeactived()
      toast({ title: 'Task updated', ...SUCCESS_TOAST })
      posthog.capture('dashboard_task_type_deactivated')
    },
    onError: (err) => {
      toast({
        title: `Unable to update task -> ${err.message}`,
        ...ERROR_TOAST
      })
    }
  })
  React.useEffect(() => {
    const locationState = history.location.state as any
    if (locationState) {
      history.location.state = undefined
    }
    detailsTopRef?.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [selectedTaskType])
  React.useEffect(() => {
    activeCard?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
  }, [selectedTaskType, scrollable])

  React.useEffect(() => {
    if (data?.getWholeCompanyTaskTypes?.length) setScrollable(true)
  }, [data?.getWholeCompanyTaskTypes])

  const loadMore = React.useCallback(() => {
    if (count?.getCompanyTaskTypesCount && count?.getCompanyTaskTypesCount > limit) {
      setLimit((prev) => prev + LIMIT_PER_PAGE)
    }
  }, [inView, count?.getCompanyTaskTypesCount, limit])
  React.useEffect(() => {
    if (inView) loadMore()
  }, [inView])

  const handleDeleteTaskType = async (id?: string, status?: Enum_Tasktype_Status) => {
    try {
      if (id) {
        if (status === Enum_Tasktype_Status.Active) {
          await deactivateTaskType({
            variables: {
              id
            }
          })
        } else {
          await activateTaskType({
            variables: {
              id
            }
          })
        }
      }
    } catch (error) {
      console.error({ error })
    }
  }
  const stats = React.useMemo(
    () => [
      {
        title: 'Tasks Overview',
        icon: FcElectricalSensor,
        loading: countLoading,
        value: `${count?.getCompanyTaskTypesCount || 0} ${pluralHandler(
          'Task',
          count?.getCompanyTaskTypesCount || 0
        )}`
      },
      {
        title: 'Active Tasks Overview',
        icon: FcElectricalSensor,
        loading: countActiveLoading,
        value: `${countActive?.getCompanyTaskTypesCount || 0} Active ${pluralHandler(
          'Task',
          countActive?.getCompanyTaskTypesCount || 0
        )}`
      },
      {
        title: 'Deactived Tasks Overview',
        icon: FcElectricalSensor,
        loading: countDeactivedLoading,
        value: `${countDeactived?.getCompanyTaskTypesCount || 0} Deactived ${pluralHandler(
          'Task',
          countDeactived?.getCompanyTaskTypesCount || 0
        )}`
      }
    ],
    [countLoading, countActiveLoading, countDeactivedLoading]
  )

  const options = () => (
    <HStack>
      <CustomTooltip label={dataListButtonHelperTooltip}>
        <Box>
          <SecondaryButton onClick={onOpen} leftIcon={<MdList />}>
            Data Lists
          </SecondaryButton>
        </Box>
      </CustomTooltip>
      <CustomTooltip label={createNewTaskButtonHelperTooltip}>
        <Link to="/auth/task-types-list/create-task">
          <PrimaryButton leftIcon={<MdAdd />}>Create a new Task</PrimaryButton>
        </Link>
      </CustomTooltip>
    </HStack>
  )
  return (
    <PageListContainer
      title="Task Builder"
      pb={0}
      icon={<FcInspection />}
      loading={loading}
      stats={stats}
    >
      <DataList {...{ isOpen, onClose }} />

      <Flex align="flex-end" mb={2} justify="space-between" w="100%">
        <HStack mr={8} spacing={4}>
          <Flex justify="flex-start" align="center">
            <Heading minW="fit-content" lineHeight={8} as="h3" size="lg" color="gray.600">
              Task Builder
            </Heading>
            {tasksBuilderHelpPopover}
          </Flex>

          <ListOptions
            setSearchTerm={setSearchTerm}
            loading={loading}
            sortBy={sortBy}
            setSort={setSort}
            setSortBy={setSortBy}
            sort={sort}
          />
          <ListActions
            setStatusFilter={setStatusFilter}
            setActiveCategory={setActiveCategory}
            setActiveCreator={setActiveCreator}
          />
        </HStack>
        {options()}
      </Flex>

      <Flex flex={1} h="calc(100vh - 320px)">
        <VStack spacing={0} position="relative">
          <VStack pr={4} overflowY="auto" pb={24} w="380px" overflowX="hidden">
            {searchTerm && (
              <Skeleton maxW="fit-content" isLoaded={!loading} my={4}>
                <Text fontSize="xs">
                  {data?.getWholeCompanyTaskTypes?.length}{' '}
                  {pluralHandler('Result', data?.getWholeCompanyTaskTypes?.length ?? 0)} for{' '}
                  <b>&quot;{searchTerm}&quot;</b>
                </Text>
              </Skeleton>
            )}

            {!data?.getWholeCompanyTaskTypes ||
              (data?.getWholeCompanyTaskTypes && data?.getWholeCompanyTaskTypes?.length <= 0 && (
                <EmptyListHandler
                  title="No Task"
                  bg="transparent"
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                  h="100%"
                  image={images.baotreeHomeBg2}
                  subTitle=""
                />
              ))}
            {loading && LIMIT_PER_PAGE === limit ? <ListLoader /> : <></>}
            <AnimatePresence>
              {data?.getWholeCompanyTaskTypes?.map((task, idx) => {
                return (
                  <TaskTypeCard
                    key={task?.id}
                    suspendedTaskTypes={suspendedTaskTypes}
                    index={idx}
                    ref={task?.id === selectedTaskType ? activeCard : undefined}
                    isFocused={task?.id === selectedTaskType}
                    {...(data?.getWholeCompanyTaskTypes &&
                      idx === data?.getWholeCompanyTaskTypes?.length - 1 && { ref })}
                    loading={loading}
                    onClick={() => setSelectedTaskType(task?.id || '')}
                    onDelete={handleDeleteTaskType}
                    task={task as TaskTypeEntity}
                  />
                )
              })}
            </AnimatePresence>
            {loading && (
              <Center py={5}>
                <Spinner emptyColor="gray.200" color="green.500" size="md" />
              </Center>
            )}
          </VStack>
          {!inView &&
            count?.getCompanyTaskTypesCount &&
            count?.getCompanyTaskTypesCount > limit && <MoreContentsLoader loadMore={loadMore} />}
        </VStack>
        <VStack w="100%" spacing={0}>
          {selectedTaskType && data?.getWholeCompanyTaskTypes && !detailsElementRefInView && (
            <SmallSubNav
              detailsContainerSubNavOptions={detailsContainerSubNavOptions}
              detailsContainerSubNavRightElement={detailsContainerSubNavRightElement}
            />
          )}

          <Box overflowY="auto" ref={detailsTopRef} overflowX="hidden" w="100%" flex={1}>
            {selectedTaskType ? (
              <TaskTypeDetails
                refetch={refetch}
                inline
                ref={detailsElementRef}
                suspendedTaskTypes={suspendedTaskTypes}
                setSuspendedTaskTypes={setSuspendedTaskTypes}
                isDraft={
                  find(
                    suspendedTaskTypes,
                    (tasktype) => tasktype?.seletedTaskType === selectedTaskType
                  ) !== undefined
                }
                getOptions={(options, rightElement) => {
                  setDetailsContainerSubNavOptions(options)
                  setDetailsContainerSubRightElement(rightElement)
                }}
                taskTypeID={selectedTaskType}
              />
            ) : (
              <EmptyListHandler
                title="No Task Selected"
                bg="transparent"
                flex={1}
                alignItems="center"
                justifyContent="center"
                h="100%"
                image={images.baotreeHomeBg2}
                subTitle="No Task Selected, select a task from the left menu to get started"
              />
            )}
          </Box>
        </VStack>
      </Flex>
    </PageListContainer>
  )
}

export default TaskTypesList
