import { Container, HStack, Icon, Box, BoxProps, useToast } from '@chakra-ui/react'
import { TableIcon, FloatingCard } from 'components'
import GreetingsBanner from 'components/layouts/GreetingsBanner'
import { ERROR_TOAST } from '../constants'
import { useCountCompanyProjectsQuery, useMeQuery } from 'generated/graphql'
import React from 'react'

import PageWrap from './PageWrap'
import { useHistory } from 'react-router-dom'

type PageListContainerProps = {
  title: string
  loading?: boolean
  stats?: {
    title: string
    icon: any
    loading?: boolean
    value: string
  }[]
  icon?: React.ReactNode
  showNoProjectBanner?: boolean
  pageActions?: React.ReactNode
  statsComponents?: React.ReactNode
} & BoxProps

const PageListContainer: React.FC<PageListContainerProps> = ({
  children,
  title,
  loading,
  stats,
  icon,
  pageActions,
  statsComponents,
  showNoProjectBanner,
  ...rest
}) => {
  const toast = useToast()

  const { data: projects, refetch } = useCountCompanyProjectsQuery({
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    }
  })
  const numberOfProjects = projects?.countCompanyProjects || 0
  const history = useHistory()

  const { data: meData } = useMeQuery()

  // Redirect user to verify email page if they haven't confirmed their email
  React.useEffect(() => {
    if (meData?.myUser) {
      if (
        !meData?.myUser?.attributes?.confirmed &&
        history.location.pathname !== '/auth/onboarding'
      ) {
        console.log('redirecting')
        history.push('/auth/onboarding')
      }
    }
  }, [meData])

  React.useEffect(() => {
    ;(async () => await refetch())()
  }, [history.location.pathname])
  return (
    <PageWrap direction="column" title={title} align="center">
      {pageActions}
      <Box pb={20} bg="background.100" rounded="md" mt={20} flex={1} w="100%" {...rest}>
        <Container pt={24} position="relative" maxW="1600" px={16}>
          {icon && <TableIcon loading={loading} icon={icon} />}
          <HStack
            spacing={5}
            position="absolute"
            top={0}
            transform="translateY(-60%)"
            mr={14}
            right={0}
          >
            {statsComponents}
            {stats?.map((stat, index) => (
              <FloatingCard
                icon={<Icon as={stat?.icon} fontSize={16} />}
                key={stat.title + index}
                loading={stat?.loading}
                title={stat.title}
                value={stat.value}
              />
            ))}
          </HStack>
          {!loading &&
          numberOfProjects === 0 &&
          meData?.myUser?.attributes?.confirmed &&
          showNoProjectBanner ? (
            <GreetingsBanner
              border="none"
              text="Welcome to Baotree! To get started, create your first Project, Group and Task - Then invite your Team of Data Collectors! The dashboard will guide you through set up of your first Project, and the left hand menu bar has everything else you need! The Help and Training section is full of useful walkthroughs, tips and tricks. If you have any other questions, get in touch with our team through the Contact Us form in Help and Training."
            />
          ) : (
            children
          )}
        </Container>
      </Box>
    </PageWrap>
  )
}
PageListContainer.defaultProps = {
  showNoProjectBanner: true
}
export default PageListContainer
