import { Flex, Avatar, Heading, VStack, Text, HStack, Button } from '@chakra-ui/react'

import { AlertModal, Card, CustomTooltip, StatusBadge } from 'components'
import { useAuthContext } from 'context/AuthProvider'
import React from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { FcApproval } from 'react-icons/fc'
import { SlidingCardContainer } from 'components/UX/SlidingCardContainer'
import {
  Exact,
  Maybe,
  useRemoveCompanyMemberMutation,
  UsersPermissionsUserFiltersInput,
  useUnarchiveCompanyMemberMutation
} from '../../../generated/graphql'
import {
  COMMUNITY_MEMBER_ROLE,
  DATA_COLLECTOR,
  LEADER_ROLE,
  VERIFIER_ROLE
} from '../../../constants/index'
import { VscArrowRight } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import { UsersPermissionsUserEntity } from '../../../generated/graphql'
import moment from 'moment'
import { archieveUserTooltipContent, unarchieveUserTooltipContent } from '../help'

type UserCardProps = {
  user: Maybe<UsersPermissionsUserEntity>
  refetch: (
    variables?:
      | Partial<
          Exact<{
            sort?: Maybe<Maybe<string> | Maybe<string>[]> | undefined
            filters?: Maybe<UsersPermissionsUserFiltersInput> | undefined
            pagination?: Maybe<any> | undefined
          }>
        >
      | undefined
  ) => Promise<any>
}

const UserCard: React.FC<UserCardProps> = ({ user, refetch }) => {
  const { user: me } = useAuthContext()
  const [archiveCompanyMember, { loading: archiving }] = useRemoveCompanyMemberMutation({
    onCompleted: async () => await refetch()
  })
  const [unarchiveCompanyMember, { loading: unarchiving }] = useUnarchiveCompanyMemberMutation({
    onCompleted: async () => await refetch()
  })

  const history = useHistory()
  const onViewUser = () => {
    history.push(
      user?.attributes?.role?.data?.attributes?.type === COMMUNITY_MEMBER_ROLE
        ? user?.attributes?.community?.data?.id
          ? `/auth/community-member/${user?.attributes?.community?.data?.id}/${user?.id}`
          : '/auth/settings'
        : user?.attributes?.role?.data?.attributes?.type === VERIFIER_ROLE
        ? `/auth/baoriders/${user?.id}`
        : '/auth/settings'
    )
  }
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: isRemoveOpen, onClose: onRemoveClose, onOpen: onRemoveOpen } = useDisclosure()

  return (
    <>
      <AlertModal
        activatorStatus="deactivate"
        onConfirm={async () => {
          await archiveCompanyMember({ variables: { memberId: user?.id || '' } })
          onClose()
        }}
        loading={archiving}
        isOpen={isOpen}
        onClose={onClose}
        confirmButtonText="Archive"
        title="Archive Admin"
      >
        <Heading fontSize="sm">
          Are you sure you want to archive this admin from the platform?
        </Heading>
        <Text fontSize="xs" mt={2}>
          By removing an admin from the platform, they will no longer be able to access the admin
          dashobard
        </Text>
      </AlertModal>
      <AlertModal
        activatorStatus="activate"
        confirmButtonText="Unarchive"
        loading={unarchiving}
        onConfirm={async () => {
          await unarchiveCompanyMember({ variables: { memberId: user?.id || '' } })
          onRemoveClose()
        }}
        isOpen={isRemoveOpen}
        onClose={onRemoveClose}
        title="Unarchive Admin"
      >
        <Heading fontSize="sm">Are you sure you want to unarchive this admin?</Heading>
        <Text fontSize="xs" mt={2}>
          By unarchiving an admin, they will be able to access the admin dashobard
        </Text>
      </AlertModal>

      <SlidingCardContainer>
        <div className="community-card-front">
          <Card
            onClick={onViewUser}
            cursor="pointer"
            border="1px solid"
            borderColor={user?.attributes?.blocked ? 'red.200' : 'gray.200'}
            height="100%"
            p={6}
          >
            <Flex mb={3} justify="space-between">
              <Avatar
                size="sm"
                src={user?.attributes?.profilePicture?.data?.attributes?.formats?.thumbnail?.url}
                name={`${user?.attributes?.firstName} ${user?.attributes?.lastName}`}
              />
              <VStack>
                <Text fontSize="x-small" color="gray.400">
                  Added {moment(user?.attributes?.createdAt).fromNow()}
                </Text>

                <StatusBadge
                  py={1}
                  key={user?.attributes?.community?.data?.id}
                  px={2}
                  maxW="fit-content"
                  fontWeight="normal"
                  colorScheme="green"
                >
                  {user?.attributes?.community?.data?.attributes?.name}
                </StatusBadge>

                {user?.attributes?.role && (
                  <Flex fontSize="x-small" align="center">
                    <FcApproval />
                    <Text color="gray.600" ml={1}>
                      {user?.attributes?.role?.data?.attributes?.type === VERIFIER_ROLE
                        ? DATA_COLLECTOR
                        : user?.attributes?.role?.data?.attributes?.type === COMMUNITY_MEMBER_ROLE
                        ? 'Community Member'
                        : 'Leader'}
                    </Text>
                  </Flex>
                )}
              </VStack>
            </Flex>
            <Flex direction="column">
              <VStack spacing={1} alignItems="flex-start">
                <HStack align="center">
                  <Heading fontSize="sm" fontWeight={500} mr={2}>
                    {user?.attributes?.firstName} {user?.attributes?.lastName}
                  </Heading>
                  {user?.attributes?.role?.data?.attributes?.name === 'Baoleader' && (
                    <StatusBadge p={1} fontWeight="normal" fontSize="x-small">
                      {user?.attributes?.baoleader ? 'ADMIN' : 'SUPER ADMIN'}
                    </StatusBadge>
                  )}
                  {user?.attributes?.blocked && (
                    <StatusBadge
                      p={1}
                      fontWeight="normal"
                      indicator
                      colorScheme="red"
                      fontSize="x-small"
                    >
                      Archived
                    </StatusBadge>
                  )}
                </HStack>
                {user?.attributes?.role?.data?.attributes?.type === LEADER_ROLE && (
                  <Text fontSize="x-small" color="gray.500">
                    {user?.attributes?.email}
                  </Text>
                )}
                <Text fontSize="x-small" color="gray.500">
                  {user?.attributes?.phoneNumber}
                </Text>
              </VStack>
            </Flex>
          </Card>
        </div>
        <div className="community-card-back">
          <Card shadow="none" height="100%" bg="white" py={4} px={8}>
            <Flex
              flex={1}
              fontSize={15}
              align="flex-end"
              justify="space-between"
              textAlign="center"
              px={3}
            >
              <HStack spacing={2} w="100%">
                {user?.id?.toString() !== me?.id?.toString() ? (
                  user?.attributes?.blocked ? (
                    <CustomTooltip label={archieveUserTooltipContent}>
                      <Button
                        variant="ghost"
                        fontSize="xs"
                        colorScheme="green"
                        isLoading={unarchiving}
                        flex={1}
                        onClick={onRemoveOpen}
                      >
                        Unarchive
                      </Button>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip label={unarchieveUserTooltipContent}>
                      <Button
                        variant="ghost"
                        fontSize="xs"
                        colorScheme="red"
                        isLoading={archiving}
                        flex={1}
                        leftIcon={<AiOutlineDelete />}
                        onClick={onOpen}
                      >
                        Archive
                      </Button>
                    </CustomTooltip>
                  )
                ) : (
                  //   TODO:: Add a button to change role
                  <Text fontSize="xs" w="100%">
                    Nothing to see here for now...
                  </Text>
                )}
                {!(user?.attributes?.role?.data?.attributes?.type === LEADER_ROLE) && (
                  <Button
                    flex={1.5}
                    onClick={onViewUser}
                    rightIcon={<VscArrowRight />}
                    colorScheme="green"
                    variant="ghost"
                    fontSize="xs"
                  >
                    View Project
                  </Button>
                )}
              </HStack>
            </Flex>
          </Card>
        </div>
      </SlidingCardContainer>
    </>
  )
}

export default UserCard
