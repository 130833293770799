export const dataFieldsDescription = [
  {
    fieldType: 'Checkbox',
    description:
      'Allows data collectors to select from pre-determined options. They are able to select more than one option with this data type.'
  },
  {
    fieldType: 'Radio',
    description:
      "This is a 'radio' button which offers only two options (e.g. YES or NO, ON or OFF, etc). Data collectors can only select one option with this data type."
  },
  {
    fieldType: 'Select',
    description:
      'Allows data collectors to select from pre-determined options but unlike the checkbox, they are only able to select one option.'
  },
  {
    fieldType: 'Metric Specific',
    description:
      'This allows data collectors to enter information according to the metrics you are trying to monitor. You will ask a question according to the metric info you are after and specify the metric type, they will then add in the units (e.g. XXX kilograms, XXX pounds)'
  },
  {
    fieldType: 'Text',
    description:
      'Free form text that allows data collectors to type unique inputs. Useful for descriptions that cannot be selected from a list.'
  },
  {
    fieldType: 'Number Range',
    description:
      'Allows data collectors to gather information that is not finite and may occur within a range. You will set a question and a range and they will be responsible for providing information that falls within this range (e.g. How frequently are checks done on a vegetable garden each month? Answer: 2 - 4 times).'
  },
  {
    fieldType: 'Percentage',
    description:
      'Allows data collectors to provide information on the percentage of a task completed or percentage of a resource depleted/restored.'
  },
  {
    fieldType: 'Number',
    description:
      'Allows data collectors to type a number into a data field - this is free form and not from a drop down.'
  },
  {
    fieldType: 'Rating',
    description: 'Allows data collectors to provide a star-rating out of 5.'
  },
  {
    fieldType: 'Date and Time',
    description:
      'Allows data collectors to specify a date and time relevant to the data being collected (e.g. Date and time an incident occurred).'
  },
  {
    fieldType: 'Data List',
    description:
      'Allows data collectors to select from an organisational data list. This can contain hundreds of options and is great if you have specific information you are trying to collect for. (E.g. Tree species, animal types, settlement names)'
  },
  {
    fieldType: 'Phone Number',
    description: 'Data collectors can add in their/guest phone numbers.'
  },
  {
    fieldType: 'Email Address',
    description: 'Data collectors can add in their/guest email addresses.'
  },
  {
    fieldType: 'Photo Field',
    description:
      'Allows you to specify the photos that data collectors must gather and ensures you get the verification images required to approve tasks/monitor projects.'
  }
]
