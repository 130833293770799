import { Center, Icon, Flex, FormControl, FormLabel, Text, Spinner } from '@chakra-ui/react'
import React from 'react'

type FieldContainerProps = {
  leftIcon: React.ElementType
  rightIcon?: React.ElementType
  isFocused: boolean
  showError: string | false | undefined
  handleClickRightIcon?: () => void
  inputRef: React.RefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>
  label?: string
  loading?: boolean
  name: string
}

const FieldContainer: React.FC<FieldContainerProps> = ({
  children,
  leftIcon,
  isFocused,
  showError,
  inputRef,
  setIsFocused,
  label,
  loading,
  name,
  rightIcon,
  handleClickRightIcon
}) => {
  return (
    <Center
      py={3}
      bg="gray.100"
      border="2px solid"
      borderColor={isFocused ? 'brand.300' : showError ? 'red.500' : 'transparent'}
      rounded="xl"
      onClick={() => {
        setIsFocused(true)
        inputRef?.current?.focus?.()
      }}
    >
      {loading ? (
        <Spinner color="green.300" m={4} size="xs" />
      ) : (
        leftIcon && (
          <Icon
            as={leftIcon}
            boxSize={4}
            m={4}
            color={isFocused ? 'brand.300' : showError ? 'red.500' : 'gray.400'}
          />
        )
      )}
      <Flex flexDirection="column" width="100%">
        <FormControl>
          {label && (
            <FormLabel m={0} htmlFor={name}>
              <Text
                color={isFocused ? 'gray.500' : showError ? 'red.500' : 'gray.400'}
                fontSize="xs"
              >
                {label}
              </Text>
            </FormLabel>
          )}
          {children}
        </FormControl>
      </Flex>
      {rightIcon && (
        <Icon
          boxSize={5}
          m={4}
          color={isFocused ? 'brand.300' : showError ? 'red.500' : 'gray.400'}
          onClick={handleClickRightIcon}
          as={rightIcon}
        />
      )}
    </Center>
  )
}

export default FieldContainer
