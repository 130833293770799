import * as Yup from 'yup'

export const addUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Please enter a valid first name')
    .required('Please enter a valid first name'),
  lastName: Yup.string()
    .min(3, 'Please enter a valid last name')
    .required('Please enter a valid last name'),
  phoneNumber: Yup.string()
    .test(
      'Phone Number',
      'Please enter a valid a phone number with international code',
      (value: string) => {
        if (value && value.startsWith('+')) {
          const match = value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im)
          return match !== null && match.length > 0
        } else {
          return false
        }
      }
    )
    .required('Please enter a valid a phone number')
})
export const addBaoleaderSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Please enter a valid first name')
    .required('Please enter a valid first name'),
  lastName: Yup.string()
    .min(3, 'Please enter a valid last name')
    .required('Please enter a valid last name'),
  email: Yup.string().email().required('Please enter a valid email'),
  title: Yup.string().required('Please enter a valid title'),
  hasConsent: Yup.boolean().equals([true], 'This is required'),
  addAnother: Yup.boolean()
})

export const addCommunityMemberSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Please enter a valid first name')
    .required('Please enter a valid first name'),
  lastName: Yup.string()
    .min(3, 'Please enter a valid last name')
    .required('Please enter a valid last name'),
  phoneNumber: Yup.string()
    .test(
      'Phone Number',
      'Please enter a valid a phone number with international code',
      (value: string) => {
        if (value) {
          const match = value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im)
          return match !== null && match.length > 0
        } else {
          return false
        }
      }
    )
    .required('Please enter a valid a phone number'),
  gender: Yup.string().equals(['Male', 'Female'], 'Select a gender'),
  isVerifier: Yup.boolean(),
  hasConsent: Yup.boolean().equals([true], 'This is required')
})

export const addProjectSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(3, 'Please enter a valid project name')
      .required('Please enter a valid project name'),
    statement: Yup.string()
      .min(2, 'Please enter a valid project description')
      .max(300, 'Maximum 300 characters')
      .required('Please enter a valid project description'),
    outcomes: Yup.string()
      .min(2, 'Please enter a valid outcome for this project')
      .max(300, 'Maximum 300 characters')
      .required('Please enter a valid outcome for this project'),
    reward: Yup.string()
      .min(2, 'Please enter a valid description of the reward for this project')
      .max(300, 'Maximum 300 characters')
  })

export const addCommunitySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Please enter a valid name for your group')
    .required('Please enter a valid name for your group'),
  type: Yup.string().required('Please select a type for your group'),
  description: Yup.string()
    .min(2, 'Please enter a valid description of your group')
    .max(300, 'Maximum 300 characters')
})

export const expenseSchema = Yup.object().shape({
  expense: Yup.string()
    .min(2, 'Please enter a valid description of your expense')
    .required('Please enter a valid description of your expense'),
  amount: Yup.number().positive().required('Please enter a valid amount')
})

export const addTaskSchema = Yup.object().shape({
  category: Yup.string().required('Please select a category'),
  task_type: Yup.string().required('Please select a task'),
  addAnother: Yup.boolean(),
  status: Yup.string().required('Please select a status')
})

export const createTaskSchema = Yup.object().shape({
  task_definition: Yup.string().required('Please select a task_definition'),
  community: Yup.string().required('Please select a community'),
  baorider: Yup.string(),
  project: Yup.string().required('Please select a project')
})
