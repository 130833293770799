import React from 'react'
import {
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Image,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Text,
  SimpleGridProps,
  Skeleton,
  Switch,
  VStack,
  Box
} from '@chakra-ui/react'

import { VscListFilter } from 'react-icons/vsc'
import { TiSortAlphabetically } from 'react-icons/ti'

import { useGetCategoriesQuery } from 'generated/graphql'
import { omit, find, sortBy } from 'lodash'

import { images } from 'theme'
import EditedIndicator from './EditedIndicator'
import { isDifferent } from '../utils/utils'
import { useGetUnsdGsQuery, TaskTypeEntity } from '../../../generated/graphql'

import { OptionType } from 'types'
import { getImageByFormat } from 'utils'

type TaskTypeMetadataProps = {
  setTaskTypeMetadata: React.Dispatch<React.SetStateAction<OptionType>>
  taskTypeMetadata: OptionType
  loading?: boolean
  taskType?: TaskTypeEntity
  differences?: OptionType[]
  errors: OptionType | undefined
  setErrors: React.Dispatch<React.SetStateAction<OptionType | undefined>>
} & SimpleGridProps

const TaskTypeMetadata: React.FC<TaskTypeMetadataProps> = ({
  setTaskTypeMetadata,
  taskTypeMetadata,
  taskType,
  errors,
  differences,
  loading,
  setErrors,
  ...rest
}) => {
  const { data } = useGetCategoriesQuery({
    variables: {
      sort: ['title:asc'],
      pagination: {
        start: 0,
        limit: 100
      }
    }
  })
  const { data: sdgs } = useGetUnsdGsQuery({
    variables: {
      sort: ['name:asc'],
      pagination: { start: 0, limit: 100 }
    }
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setTaskTypeMetadata((prev) => ({ ...prev, [event.target.name]: event.target.value || '' }))

    if (event.target.value === '' && event.target.required) {
      setErrors((prev) => ({ ...prev, [event.target.name]: `${event.target.name} is required` }))
    } else if (event.target.name === 'title' && event?.target?.value?.length > 30) {
      setErrors((prev) => ({
        ...prev,
        [event.target.name]: `${event.target.name} must have atmost 30 characters`
      }))
    } else {
      setErrors((prev) => omit(prev, [event.target.name]))
    }
  }
  const foundCategory = React.useMemo(
    () =>
      find(data?.categories?.data || [], (category) => category?.id === taskTypeMetadata?.category),
    [data, taskTypeMetadata]
  )

  const foundSDG = React.useMemo(
    () => find(sdgs?.unSdgs?.data || [], (sdg) => sdg?.id === taskTypeMetadata?.un_sdg),
    [sdgs, taskTypeMetadata]
  )
  return (
    <SimpleGrid
      gap={10}
      mb={10}
      mt={6}
      alignItems="center"
      templateColumns="repeat(2,1fr)"
      {...rest}
    >
      <FormControl isInvalid={!!errors?.title}>
        {isDifferent(differences ?? [], 'title') && (
          <EditedIndicator
            prev={taskType?.attributes?.title || ''}
            loading={loading}
            current={taskTypeMetadata?.title as string}
          />
        )}
        <Skeleton isLoaded={!loading}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <TiSortAlphabetically />
            </InputLeftElement>
            <Input
              placeholder="Title"
              variant="flushed"
              name="title"
              required
              value={taskTypeMetadata?.title as string}
              fontSize="xs"
              onChange={(e) => {
                handleChange(e)
              }}
              focusBorderColor="green.200"
            />
          </InputGroup>
        </Skeleton>
        <Skeleton isLoaded={!loading} maxW="fit-content">
          {!errors?.title ? (
            <FormHelperText fontSize="x-small">Enter the task name.</FormHelperText>
          ) : (
            <FormErrorMessage fontSize="x-small">{errors?.title}.</FormErrorMessage>
          )}
        </Skeleton>
      </FormControl>

      <Center>
        <FormControl isInvalid={!!errors?.category}>
          {isDifferent(differences ?? [], 'category') && (
            <EditedIndicator
              prev={taskType?.attributes?.category?.data?.attributes?.title || ''}
              loading={loading}
              current={foundCategory?.attributes?.title || ''}
            />
          )}
          <Skeleton isLoaded={!loading}>
            <VStack alignItems="flex-start" spacing={0}>
              <Text fontSize="x-small">Category</Text>
              <Center w="100%" flex={1}>
                <VscListFilter />

                {taskTypeMetadata?.category && (
                  <Image
                    boxSize={8}
                    mx={2}
                    objectFit="cover"
                    rounded="full"
                    src={getImageByFormat(
                      foundCategory?.attributes?.featureImage?.data,
                      'thumbnail'
                    )}
                  />
                )}

                <Select
                  ml={4}
                  variant="flushed"
                  fontSize="xs"
                  required
                  name="category"
                  value={(taskTypeMetadata?.category || '') as string}
                  onChange={handleChange}
                  placeholder="None"
                  focusBorderColor="green.200"
                >
                  {data?.categories?.data?.map((category) => (
                    <option key={category?.id} value={category?.id || ''}>
                      {category?.attributes?.title}
                    </option>
                  ))}
                </Select>
              </Center>
            </VStack>
          </Skeleton>
          <Skeleton isLoaded={!loading} maxW="fit-content">
            {!errors?.category ? (
              <FormHelperText fontSize="x-small">Select a category for the task.</FormHelperText>
            ) : (
              <FormErrorMessage fontSize="x-small">{errors?.category}.</FormErrorMessage>
            )}
          </Skeleton>
        </FormControl>
      </Center>
      <Center>
        <FormControl isInvalid={!!errors?.un_sdg}>
          {isDifferent(differences ?? [], 'un_sdg') && (
            <EditedIndicator
              prev={taskType?.attributes?.un_sdg?.data?.attributes?.name || ''}
              loading={loading}
              current={foundSDG?.attributes?.name || ''}
            />
          )}
          <Skeleton isLoaded={!loading}>
            <VStack alignItems="flex-start" spacing={0}>
              <Text fontSize="x-small">UN SDG</Text>
              <Center w="100%" flex={1}>
                <Image
                  boxSize={8}
                  mx={2}
                  objectFit="cover"
                  rounded="full"
                  src={
                    foundSDG?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url
                      ? foundSDG?.attributes?.icon?.data?.attributes?.formats?.thumbnail?.url
                      : images.unLogo
                  }
                />

                <Select
                  ml={4}
                  variant="flushed"
                  fontSize="xs"
                  name="un_sdg"
                  value={(taskTypeMetadata?.un_sdg || '') as string}
                  onChange={handleChange}
                  placeholder="None"
                  focusBorderColor="green.200"
                >
                  {sortBy(sdgs?.unSdgs?.data, (unsdg) => {
                    return parseInt(unsdg?.attributes?.name?.slice(0, 3) || '')
                  })?.map((sdg) => (
                    <option key={sdg?.id} value={sdg?.id || ''}>
                      {sdg?.attributes?.name}
                    </option>
                  ))}
                </Select>
              </Center>
            </VStack>
          </Skeleton>
          <Skeleton isLoaded={!loading} maxW="fit-content">
            {!errors?.un_sdg ? (
              <FormHelperText fontSize="x-small">
                Select a UN Sustainable Development Goals for the task.
              </FormHelperText>
            ) : (
              <FormErrorMessage fontSize="x-small">{errors?.un_sdg}.</FormErrorMessage>
            )}
          </Skeleton>
        </FormControl>
      </Center>
      <VStack spacing={4} alignItems="flex-start">
        <Box>
          {isDifferent(differences ?? [], 'withComment') && (
            <EditedIndicator
              prev={`${taskType?.attributes?.withComment}`}
              loading={loading}
              current={`${taskTypeMetadata?.withComment}`}
            />
          )}
          <FormControl
            onChange={(event: any) => {
              setTaskTypeMetadata((prev) => ({
                ...prev,
                [event.target.name]: event.target.checked
              }))
            }}
            display="flex"
            alignItems="center"
          >
            <Skeleton isLoaded={!loading} mb={0} mr={2}>
              <FormLabel fontSize="x-small" cursor="pointer" m={0} htmlFor="withComment">
                With Comment Box
              </FormLabel>
            </Skeleton>
            <Skeleton alignItems="center" display="flex" isLoaded={!loading} rounded="full">
              <Switch
                id="withComment"
                isChecked={Boolean(taskTypeMetadata?.withComment)}
                defaultChecked={Boolean(taskTypeMetadata?.withComment)}
                colorScheme="green"
                name="withComment"
              />
            </Skeleton>
          </FormControl>
        </Box>
      </VStack>
    </SimpleGrid>
  )
}

export default TaskTypeMetadata
