import { Container, Flex } from '@chakra-ui/react'
import { MotionFlex } from 'components'
import React from 'react'

type SmallSubNavProps = {
  detailsContainerSubNavRightElement: React.ReactNode
  detailsContainerSubNavOptions: React.ReactNode
}

const SmallSubNav: React.FC<SmallSubNavProps> = ({
  detailsContainerSubNavOptions,
  detailsContainerSubNavRightElement
}) => {
  return (
    <MotionFlex
      initial={{
        opacity: 0,
        y: '-100%'
      }}
      borderBottom="1px solid"
      borderColor="gray.200"
      animate={{ opacity: 1, y: 0 }}
      zIndex={128}
      w="100%"
      bg="background.100"
    >
      <Container position="relative" maxW="1600" px={6}>
        <Flex justify="space-between" py={1} align="center">
          <Flex align="center" flex={1}>
            {detailsContainerSubNavRightElement}
          </Flex>
          <Flex align="center" flex={1} justify="flex-end" width="100%">
            {detailsContainerSubNavOptions && detailsContainerSubNavOptions}
          </Flex>
        </Flex>
      </Container>
    </MotionFlex>
  )
}

export default SmallSubNav
