import { Collapse, Flex, Box } from '@chakra-ui/react'
import { ConnectedSelect } from 'components/forms/FormElements'
import GridGenerator from 'components/GridGenerator'
import { DATA_COLLECTOR, taskFilters } from '../../../../constants'

import { Form, useFormikContext } from 'formik'
import React from 'react'
import { Activity } from 'react-feather'
import { FaProjectDiagram, FaTasks, FaUser, FaUsers } from 'react-icons/fa'
import { TaskFiltersType } from '../../../../types/index'
import {
  GetTaskFilterProjectsQuery,
  GetTaskFilterTaskTypesQuery,
  GetTaskFilterDataCollectorsQuery,
  GetTaskFilterCommunitiesQuery,
  Enum_Task_Status,
  GetTaskFilterUnsdgsQuery
} from '../../../../generated/graphql'
import { getStatusLabel } from 'features/datacollectors/utils'

const getStatuses = () => {
  const statuses: string[] = [
    Enum_Task_Status.Rejected,
    Enum_Task_Status.Completed,
    Enum_Task_Status.Pending
  ]
  return statuses.sort().map((status) => {
    return {
      label: getStatusLabel(status)?.split('_').join(' '),
      value: status
    }
  })
}
type TableToolbarProps = {
  showFilters: boolean
  projectsLoading: boolean
  verifiersLoading: boolean
  communitiesLoading: boolean
  taskTypesLoading: boolean
  unsdgsLoading: boolean
  storedUnsdgs: GetTaskFilterUnsdgsQuery | undefined
  leaderProjects: GetTaskFilterProjectsQuery | undefined
  storedTaskTypes: GetTaskFilterTaskTypesQuery | undefined
  storedCommunities: GetTaskFilterCommunitiesQuery | undefined
  verifiers: GetTaskFilterDataCollectorsQuery | undefined
  visibleFilters?: TaskFiltersType[]
}

const TableToolbar: React.FC<TableToolbarProps> = ({
  showFilters,
  projectsLoading,
  taskTypesLoading,
  verifiersLoading,
  communitiesLoading,
  leaderProjects,
  visibleFilters,
  storedTaskTypes,
  storedCommunities,
  verifiers,
  unsdgsLoading,
  storedUnsdgs
}) => {
  const { setFieldValue, submitForm } = useFormikContext()

  return (
    <Collapse in={showFilters} key={Math.random()} animateOpacity>
      <Flex w="100%" direction="column" p={3}>
        <Box w="100%" my={3}>
          <Form style={{ width: '100%' }}>
            <GridGenerator cols={3}>
              {visibleFilters?.includes?.('Project') && (
                <ConnectedSelect
                  label="Project"
                  placeholder="All"
                  leftIcon={FaProjectDiagram}
                  isDisabled={projectsLoading}
                  loading={projectsLoading}
                  name="project"
                  options={
                    leaderProjects?.getTaskFilterProjects?.map((project) => {
                      return {
                        label: project?.attributes?.name || '',
                        value: project?.id || ''
                      }
                    }) || []
                  }
                  onChange={async () => {
                    setFieldValue('task_definition.task_type', '')
                    setFieldValue('baorider', '')
                    setFieldValue('community', '')
                    await submitForm()
                  }}
                />
              )}

              {visibleFilters?.includes?.('Task') && (
                <ConnectedSelect
                  label="Task"
                  placeholder="All"
                  leftIcon={FaTasks}
                  isDisabled={taskTypesLoading}
                  loading={taskTypesLoading}
                  name="task_definition.task_type"
                  options={
                    storedTaskTypes?.getTaskFilterTaskTypes?.map((item) => {
                      return {
                        label: item?.attributes?.title || '',
                        value: item?.id || ''
                      }
                    }) || []
                  }
                  onChange={async () => {
                    setFieldValue('baorider', '')
                    setFieldValue('community', '')
                    setFieldValue('task_definition.un_sdg', '')
                    await submitForm()
                  }}
                />
              )}
              {visibleFilters?.includes?.('Data Collector') && (
                <ConnectedSelect
                  label={DATA_COLLECTOR}
                  placeholder="All"
                  isDisabled={verifiersLoading}
                  loading={verifiersLoading}
                  leftIcon={FaUser}
                  name="baorider"
                  options={
                    verifiers?.getTaskFilterDataCollectors?.map((verifier) => {
                      return {
                        label:
                          `${verifier?.attributes?.firstName} ${verifier?.attributes?.lastName}` ||
                          '',
                        value: verifier?.id || ''
                      }
                    }) || []
                  }
                  onChange={async () => {
                    setFieldValue('community', '')
                    await submitForm()
                  }}
                />
              )}

              {visibleFilters?.includes?.('Status') && (
                <ConnectedSelect
                  label="Status"
                  leftIcon={Activity}
                  placeholder="All"
                  name="status"
                  options={getStatuses() || []}
                  onChange={async () => await submitForm()}
                />
              )}
              {visibleFilters?.includes?.('Community') && (
                <ConnectedSelect
                  label="Group"
                  leftIcon={FaUsers}
                  isDisabled={communitiesLoading}
                  loading={communitiesLoading}
                  placeholder="All"
                  name="community"
                  options={
                    storedCommunities?.getTaskFilterCommunities?.map((item) => {
                      return {
                        label: item?.attributes?.name || '',
                        value: item?.id || ''
                      }
                    }) || []
                  }
                  onChange={async () => await submitForm()}
                />
              )}
              {visibleFilters?.includes?.('UNSDG') && (
                <ConnectedSelect
                  label="UNSDG"
                  leftIcon={FaUsers}
                  isDisabled={unsdgsLoading}
                  loading={unsdgsLoading}
                  placeholder="All"
                  name="task_definition.un_sdg"
                  options={
                    storedUnsdgs?.getTaskFilterUnsdgs?.map((item) => {
                      return {
                        label: item?.attributes?.name || '',
                        value: item?.id || ''
                      }
                    }) || []
                  }
                  onChange={async () => await submitForm()}
                />
              )}
            </GridGenerator>
          </Form>
        </Box>
      </Flex>
    </Collapse>
  )
}
TableToolbar.defaultProps = {
  visibleFilters: taskFilters
}

export default TableToolbar
