import { SlideFade } from '@chakra-ui/react'

import React from 'react'
import { STAGE } from '../../..'
import ListDetails, { ListDetailsProps } from '../../details/ListDetails'
import Lists, { ListsProps } from '..'

type ListerProps = {} & ListDetailsProps & ListsProps

const Lister: React.FC<ListerProps> = ({
  stage,
  selectedItem,
  deactivateList,
  activateList,
  setSelectedItem,
  setStage,
  callBack,
  dataListOptions,
  type,
  data,
  refetchDataListOptions,
  activateListOption,
  deactivateListOption,
  setLimit,
  start,
  setStart,
  count,
  limit
}) => {
  return (
    <>
      {(stage === STAGE.list || stage === STAGE.create) && (
        <SlideFade in={stage === STAGE.list || stage === STAGE.create} offsetY="20px">
          <Lists
            data={data}
            deactivateList={deactivateList}
            activateList={activateList}
            type={type}
            setSelectedItem={setSelectedItem}
            setStage={setStage}
          />
        </SlideFade>
      )}
      {stage === STAGE.details && (
        <SlideFade in={stage === STAGE.details} offsetY="20px">
          <ListDetails
            dataListOptions={dataListOptions}
            selectedItem={selectedItem}
            stage={stage}
            type="inactive"
            callBack={callBack}
            refetchDataListOptions={refetchDataListOptions}
            activateListOption={activateListOption}
            deactivateListOption={deactivateListOption}
            setLimit={setLimit}
            start={start}
            setStart={setStart}
            count={count || 0}
            limit={limit || 10}
          />
        </SlideFade>
      )}
    </>
  )
}

export default Lister
