import React, { ReactNode } from 'react'
import { Container, Flex, Image, IconButton } from '@chakra-ui/react'
import { useAppContext } from '../../../context/AppProvider/index'
import MotionFlex from '../../utils/MotionFlex/index'
import { images } from 'theme'
import { VscArrowLeft } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'

type SubNavProps = {
  leftElement?: ReactNode
  rightElement?: ReactNode
  top?: number
  logo?: boolean
  image?: any
  back?: boolean
  onGoBack?: () => void
}

const SubNav: React.FC<SubNavProps> = ({
  leftElement,
  top,
  rightElement,
  image,
  onGoBack,
  back,
  logo
}) => {
  const [inView, setInView] = React.useState(false)
  const { drawerOpen } = useAppContext()

  React.useLayoutEffect(() => {
    const onScroll: (this: Window, ev: Event) => any = () => {
      const state = document.body.getBoundingClientRect()
      if (state.top < (top ?? -240)) {
        setInView(true)
      } else {
        setInView(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])
  const history = useHistory()

  return inView ? (
    <MotionFlex
      initial={{
        y: '-100%',
        opacity: 0
      }}
      animate={{ opacity: 1, y: 0 }}
      position="fixed"
      width={`calc(100% - ${drawerOpen ? '200px' : '64px'})`}
      zIndex={1289}
      bg="background.100"
      shadow="sm"
      left={drawerOpen ? 200 : '64px'}
      top="64px"
    >
      <Container position="relative" maxW="1600" px={16}>
        {back && (
          <IconButton
            position="absolute"
            icon={<VscArrowLeft />}
            rounded="full"
            onClick={!onGoBack ? () => history.goBack() : onGoBack}
            transform={`translate(0,-50%)`}
            left={1}
            color="green.300"
            top="50%"
            aria-label="Back"
          />
        )}
        <Flex justify="space-between" py={3} align="center">
          <Flex align="center" flex={1}>
            {logo && (
              <Image
                boxSize={9}
                objectFit="cover"
                rounded="full"
                mr={3}
                src={image ? image : images.logoNoText}
              />
            )}
            {rightElement}
          </Flex>
          <Flex align="center" flex={1} justify="flex-end" width="100%">
            {leftElement}
          </Flex>
        </Flex>
      </Container>
    </MotionFlex>
  ) : (
    <></>
  )
}

SubNav.defaultProps = {
  top: -240,
  back: true,
  logo: true
}

export default SubNav
