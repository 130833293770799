import { Box, Flex, IconButton, Image, HStack, Switch, Text, Icon } from '@chakra-ui/react'
import React from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { Enum_Taskdefinition_Status, TaskDefinitionEntity } from '../../../generated/graphql'
import { useHistory } from 'react-router-dom'
import { VscArrowRight } from 'react-icons/vsc'
import { getImageByFormat } from 'utils'

const TaskCards: React.FC<{
  tasks: Array<TaskDefinitionEntity>
  onAction: (action: string, index: number, task: TaskDefinitionEntity, isActve?: boolean) => void
}> = ({ tasks, onAction }) => {
  return (
    <Box w="100%" my={3}>
      {tasks?.map((task, index) => {
        const isActive =
          task?.attributes?.status === Enum_Taskdefinition_Status.Active ? true : false
        return (
          <TaskCard
            task={task}
            key={task.id}
            onAction={onAction}
            index={index}
            isActive={isActive}
          />
        )
      })}
    </Box>
  )
}

const TaskCard: React.FC<{
  task: TaskDefinitionEntity
  index: number
  isActive: boolean
  onAction: (action: string, index: number, task: TaskDefinitionEntity, isActve?: boolean) => void
}> = ({ task, onAction, index, isActive }) => {
  const history = useHistory()
  const colWidth = 100 / 2

  return (
    <Flex
      px={6}
      py={3}
      my={3}
      width="100%"
      key={task.id}
      direction="row"
      rounded="xl"
      border="1px solid"
      borderColor="gray.50"
      bg="gray.100"
    >
      <Flex
        w={`${colWidth}%`}
        fontSize="xs"
        flex={1}
        direction="row"
        onClick={() =>
          history.push(`/auth/task-types-list/detail/${task?.attributes?.task_type?.data?.id}`)
        }
        cursor="pointer"
        fontWeight={400}
        justify="flex-start"
        align="center"
      >
        <Box mr={3}>
          <Image
            src={getImageByFormat(task?.attributes?.category?.data?.attributes?.featureImage?.data)}
            boxSize={8}
            rounded="2xl"
            objectFit="cover"
            mr={3}
          />
        </Box>
        <HStack>
          <Text color="gray.600">{task?.attributes?.category?.data?.attributes?.title || ''}</Text>
          <Icon as={VscArrowRight} color="green.300" />
          <Text fontWeight="semibold">
            {task?.attributes?.task_type?.data?.attributes?.title || ''}
          </Text>
        </HStack>
      </Flex>

      <HStack spacing={6}>
        <IconButton
          fontSize="sm"
          aria-label="Edit Task"
          onClick={() => onAction('edit', index, task)}
          icon={<FaPencilAlt fontSize={10} />}
        />
        <HStack>
          <Switch
            colorScheme="green"
            defaultChecked={isActive}
            isChecked={isActive}
            onChange={(event) => {
              onAction('delete', index, task, event.target.checked)
            }}
          />
          {task?.attributes?.status && (
            <Text fontSize="x-small" color={isActive ? 'green.400' : 'red.300'}>
              {task?.attributes?.status === Enum_Taskdefinition_Status.Active ? 'On' : 'Off'}
            </Text>
          )}
        </HStack>
      </HStack>
    </Flex>
  )
}

export default TaskCards
