import { Flex, FlexProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import LoadingLogo from '../LoadingLogo'

const Wrapper = styled(Flex)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
`

const Loader: React.FC<FlexProps> = (props) => {
  return (
    <Wrapper {...props} zIndex={84848}>
      <LoadingLogo />
    </Wrapper>
  )
}

Loader.defaultProps = {
  position: 'fixed',
  bg: 'rgba(255,255,255,0.7)'
}

export default Loader
