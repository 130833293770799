import { VStack, Text, Image, Center } from '@chakra-ui/react'
import { Card } from 'components'
import React from 'react'
import { VscLink } from 'react-icons/vsc'
import { Maybe } from '../../../generated/graphql'

type FeaturedCardProps = {
  title: string
  onClick?: () => void
  value: Maybe<string> | Maybe<string>[] | undefined
  image: string
}
const FeaturedCard: React.FC<FeaturedCardProps> = ({ title, value, image, onClick }) => {
  return (
    <Card
      p={5}
      textAlign="center"
      border="none"
      cursor={onClick ? 'pointer' : undefined}
      position="relative"
      onClick={onClick}
    >
      {onClick && (
        <Center
          top={0}
          transform="translate(-50%,-50%)"
          left="50%"
          rounded="2xl"
          p={1}
          bg="green.400"
          color="white"
          position="absolute"
        >
          <VscLink />
        </Center>
      )}
      <VStack justify="center" h="100%" spacing={2}>
        <Text fontWeight={500} fontSize="x-small">
          {title}
        </Text>
        <Image
          alt="community featured image"
          rounded="xl"
          objectFit="cover"
          boxSize="2rem"
          draggable={false}
          src={image}
        />

        {typeof value === 'string' ? (
          <Text fontSize="xs" color="gray.500" ml={2}>
            {value}
          </Text>
        ) : (
          value?.map((value, idx) => (
            <Text fontSize="xs" key={idx} color="gray.500" ml={2}>
              {value}
            </Text>
          )) || 'N/A'
        )}
      </VStack>
    </Card>
  )
}

export default FeaturedCard
