import { useToast, useDisclosure, Box } from '@chakra-ui/react'
import { CustomTooltip, VerifierDrawer } from 'components'

import { DATA_COLLECTOR, ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'

import BaoriderModal from 'containers/OnBoarding/components/BaoriderModal'

import {
  useCreateBaoriderMutation,
  useUpdateBaoriderMutation,
  useGetCommunityQuery,
  useUpdateProjectMutation,
  useCountRidersQuery,
  useGetBaoridersQuery,
  ProjectEntity,
  useGetSingleBaoriderLazyQuery,
  UsersPermissionsUserEntity,
  Maybe,
  useAddDataCollectorToProjectMutation
} from 'generated/graphql'

import { get, uniq } from 'lodash'
import React from 'react'

import { VscAdd } from 'react-icons/vsc'

import VerifiersListModal from '../../components/VerifiersListModal'

import DeleteModal from '../../../../components/UX/AlertModal/index'
import { PrimaryButton } from 'components/UI/Buttons'
import DataCollectorsTable from '../../../../components/features/DataCollectorsTable/index'
import { getSocket } from 'lib'
import {
  REMOVE_DATA_COLLECTOR_TO_PROJECT,
  ADD_DATA_COLLECTOR_TO_PROJECT
} from 'lib/websockets/constants'

import {
  addDatacollectorTooltip,
  dataCollectorsExportExcelTooltip,
  dataCollectorsHelpPopover
} from '../help'

type VerifiersProps = {
  storedProject: Maybe<ProjectEntity>
  callback?: () => Promise<void>
}

const Verifiers: React.FC<VerifiersProps> = ({ storedProject, callback }) => {
  const toast = useToast()
  const socket = getSocket()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const { data: count } = useCountRidersQuery({
    variables: {
      where: {
        projects: [storedProject?.id]
      }
    },
    onError
  })
  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)

  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedFilter, setSelectedFilter] = React.useState('')
  const { data, refetch, loading } = useGetBaoridersQuery({
    variables: {
      pagination: {
        limit,
        start
      },
      filters: {
        projects: [storedProject?.id],
        ...(searchTerm &&
          selectedFilter && {
            [`${selectedFilter}`]: { $contains: searchTerm }
          })
      }
    },
    onError
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [updateProject] = useUpdateProjectMutation()

  const [createBaorider, { loading: createLoading }] = useCreateBaoriderMutation({
    onCompleted: () => {
      toast({ title: 'Baorider created.', ...SUCCESS_TOAST })
    },
    onError: (err) => {
      toast({
        title: get(err, 'graphQLErrors[0].message', 'Unable to add baorider'),
        ...ERROR_TOAST
      })
    }
  })

  const { data: community } = useGetCommunityQuery()
  const [getVerifier] = useGetSingleBaoriderLazyQuery()
  const onFilter = async () => {
    try {
      await refetch()
    } catch (e) {
      console.error(e)
    }
  }

  const isLoading = createLoading

  const [updateVerifier] = useUpdateBaoriderMutation()

  const [selectedVerifier, setSelectedVerifier] = React.useState<UsersPermissionsUserEntity | null>(
    null
  )
  const [addDataCollectorToProject] = useAddDataCollectorToProjectMutation()
  const { isOpen: isDrawerOpen, onClose: onDrawerClose, onOpen: onDrawerOpen } = useDisclosure()

  const addBaorider = async (baorider: UsersPermissionsUserEntity & { community: string }) => {
    try {
      const verifier = await createBaorider({
        variables: {
          input: {
            firstName: baorider?.attributes?.firstName || '',
            lastName: baorider?.attributes?.lastName || '',
            gender: baorider?.attributes?.gender,
            phoneNumber: baorider?.attributes?.phoneNumber || '',
            community: community?.getBaoleaderCommunity?.id || '',
            isVerifier: false,
            projects: [storedProject?.id] as string[]
          },
          sendInvite: true
        }
      })
      await updateProject({
        variables: {
          id: storedProject?.id || '',
          input: {
            verifiers: [
              ...(storedProject?.attributes?.verifiers?.data?.map(
                (verifier) => verifier?.id
              ) as string[]),
              verifier?.data?.createBaorider.baorider?.id || ''
            ]
          }
        }
      })
      await refetch()
      return verifier.data?.createBaorider.baorider as Maybe<UsersPermissionsUserEntity>
    } catch (error) {
      console.error(error)
    }
  }
  const onRemove = async () => {
    await updateVerifier({
      variables: {
        id: selectedVerifier?.id || '',
        baorider: {
          projects: uniq(
            selectedVerifier?.attributes?.projects?.data
              ?.filter((project) => project?.id !== storedProject?.id)
              .map((project) => project?.id || '')
          )
        }
      }
    })
    await updateProject({
      variables: {
        id: storedProject?.id || '',
        input: {
          verifiers: storedProject?.attributes?.verifiers?.data
            ?.filter((verifier) => verifier?.id !== selectedVerifier?.id)
            .map((verifier) => verifier?.id || '')
        }
      }
    })
    await refetch()

    socket.emit(REMOVE_DATA_COLLECTOR_TO_PROJECT, {
      projectId: storedProject?.id,
      userId: selectedVerifier?.id
    })

    onDrawerClose()
  }
  const {
    isOpen: isVerifiersModalOpen,
    onClose: onVerifiersModalClose,
    onOpen: onVerifiersModalOpen
  } = useDisclosure()
  const { isOpen: isRemoveOpen, onClose: onRemoveClose, onOpen: onRemoveOpen } = useDisclosure()

  return (
    <Box py={4}>
      <DataCollectorsTable
        {...{ limit, start, count: count?.countRiders || 0, setLimit, setStart }}
        loading={loading}
        visibleFilters={['group', 'status']}
        dataCollectorsHelpPopover={dataCollectorsHelpPopover}
        setSearchTerm={setSearchTerm}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        searchTerm={searchTerm}
        titleActions={() => (
          <CustomTooltip label={addDatacollectorTooltip}>
            <Box>
              <PrimaryButton onClick={onVerifiersModalOpen} ml={4} leftIcon={<VscAdd />}>
                Add {DATA_COLLECTOR}
              </PrimaryButton>
            </Box>
          </CustomTooltip>
        )}
        title={DATA_COLLECTOR}
        onRemove={() => onRemoveOpen()}
        baoriders={(data?.getBaoriders || []) as UsersPermissionsUserEntity[]}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onExport={async () => {}}
        onFilter={onFilter}
        onReset={async () => {
          await refetch()
        }}
        onCreate={onOpen}
        onClick={(baorider) => {
          onDrawerOpen()
          setSelectedVerifier(baorider)
        }}
        exportExcelTooltipContent={dataCollectorsExportExcelTooltip}
      />

      <VerifiersListModal
        verifiers={data?.getBaoriders as Maybe<UsersPermissionsUserEntity>[]}
        isOpen={isVerifiersModalOpen}
        onClose={onVerifiersModalClose}
        project={storedProject}
        onAdd={async (values) => {
          try {
            values?.forEach(async (value) => {
              await getVerifier({
                variables: {
                  id: value || ''
                }
              })
              const project = await addDataCollectorToProject({
                variables: {
                  input: { projectId: storedProject?.id || '', dataCollectorId: value || '' }
                }
              })
              socket.emit(ADD_DATA_COLLECTOR_TO_PROJECT, {
                projectId: project.data?.addDataCollectorToProject?.id,
                userId: value
              })
              console.log('project', project)
            })

            await callback?.()
            await refetch()
            onVerifiersModalClose()
          } catch (error) {
            console.error(error)
          }
        }}
      />

      <BaoriderModal
        {...{ isOpen, onOpen, onClose }}
        isEditMode={false}
        isLoading={isLoading}
        image={storedProject?.attributes?.featured_image?.data?.attributes?.formats?.medium?.url}
        onSubmit={async (baorider) => {
          return await addBaorider({
            ...baorider
          })
        }}
      />
      <VerifierDrawer
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        image={storedProject?.attributes?.featured_image?.data?.attributes?.formats?.medium?.url}
        selectedVerifier={selectedVerifier}
        onRemove={() => onRemoveOpen()}
      />
      <DeleteModal
        onClose={onRemoveClose}
        isOpen={isRemoveOpen}
        confirmButtonText="Remove"
        title="Remove Data Collector"
        onConfirm={async () => {
          await callback?.()
          onRemove()
          onRemoveClose()
        }}
      >
        Are you sure you want to remove this data collector from the project?
      </DeleteModal>
    </Box>
  )
}

export default Verifiers
