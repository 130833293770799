import { Box, Center, Spinner, useToast, Text, Heading } from '@chakra-ui/react'
import { DATA_COLLECTOR, ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'
import {
  Maybe,
  ProjectEntity,
  useCountCompanyProjectsLazyQuery,
  useGetProjectsLazyQuery,
  useUpdateBaoriderMutation
} from 'generated/graphql'
import React, { useState } from 'react'
import { AlertModal, EmptyListHandler, ModalWrap, Pagination, SearchBar } from 'components'
import ProjectCards from './ProjectCards'
import { useHistory, useParams } from 'react-router-dom'
import { PrimaryButton } from 'components/UI/Buttons'

type AddToProjectModalProps = {
  isOpen: boolean
  onClose: () => void
  dcProjectsIds: (Maybe<string> | undefined)[]
  addProject: (projectId: string) => void
  storedBaorider: any
  callBack?: () => void
}
const AddToProjectModal: React.FC<AddToProjectModalProps> = ({
  isOpen,
  onClose,
  dcProjectsIds,
  addProject,
  storedBaorider,
  callBack
}) => {
  const toast = useToast()
  const history = useHistory()

  const onError = (e: any) => {
    console.log(e?.message)
    toast({ title: 'There was an error.', ...ERROR_TOAST })
  }
  const { id } = useParams<{ id: string }>()
  const [projectSearchTerm, setProjectSearchTerm] = React.useState('')
  const [limit, setLimit] = React.useState(5)
  const [start, setStart] = React.useState(0)
  const [currentProject, setCurrentProject] = useState<string | null>(null)
  const [showAddFromProject, setShowAddFromProjectCon] = useState(false)
  const [
    getCompanyProjects,
    { data: companyProjects, loading: projectsLoading, refetch: refetchProjects }
  ] = useGetProjectsLazyQuery({
    onError,
    variables: {
      filters: {
        name: { $contains: projectSearchTerm },
        id: { $notIn: dcProjectsIds }
      },
      pagination: {
        limit,
        start
      }
    }
  })
  const [getProjectsCount, { data: projectsCount, refetch: refetchProjectsCount }] =
    useCountCompanyProjectsLazyQuery({
      variables: {
        filters: {
          name: { $contains: projectSearchTerm },
          id: { $notIn: dcProjectsIds }
        }
      },
      onError: () => {
        toast({ title: 'There was an error.', ...ERROR_TOAST })
      }
    })
  const [updateBaorider] = useUpdateBaoriderMutation({
    onCompleted: () => {
      toast({ title: DATA_COLLECTOR + ' updated.', ...SUCCESS_TOAST })
    },
    onError
  })

  React.useEffect(() => {
    getCompanyProjects()
    getProjectsCount()
  }, [])

  const addUserToProject = async () => {
    await updateBaorider({
      variables: {
        id,
        baorider: {
          projects: [
            ...(storedBaorider?.projects?.data?.map((item: any) => item?.id) || []),
            currentProject
          ] as string[]
        }
      }
    })
    await refetchProjects()
    await refetchProjectsCount()
    callBack && callBack()
    onClose()
  }
  const otherProjects = companyProjects?.getBaoleaderProjects
  return (
    <ModalWrap isOpen={isOpen} onClose={onClose} title="Assign to Project">
      <Box w="100%" mb={2}>
        <SearchBar
          isLoading={projectsLoading}
          onSearch={(value) => {
            setProjectSearchTerm(value)
          }}
          placeholder="Search for projects ..."
        />
      </Box>

      {projectsLoading || projectsLoading ? (
        <Center py={8} w="100%">
          <Spinner color="green.300" />
        </Center>
      ) : otherProjects?.length ? (
        <ProjectCards
          projects={otherProjects as Maybe<ProjectEntity>[]}
          onAction={(_index, project) => {
            addProject(project?.id || '')
            setCurrentProject(project?.id || '')
            setShowAddFromProjectCon(true)
          }}
          status="add"
        />
      ) : (
        <EmptyListHandler
          title="No Projects"
          subTitle={`${storedBaorider?.firstName} is already added to all projects.`}
          action={
            <PrimaryButton
              mt={8}
              onClick={() => {
                history.push('/auth/create-project')
              }}
              w="100%"
            >
              Create Project
            </PrimaryButton>
          }
        />
      )}

      <Pagination
        setLimit={setLimit}
        start={start}
        setStart={setStart}
        pageRangeDisplayed={2}
        count={projectsCount?.countCompanyProjects || 0}
        limit={limit}
      />
      <AlertModal
        activatorStatus="activate"
        onConfirm={() => {
          setShowAddFromProjectCon(false)
          addUserToProject()
        }}
        isOpen={showAddFromProject}
        confirmButtonText="Add"
        onClose={() => setShowAddFromProjectCon(false)}
        title={`Add ${DATA_COLLECTOR} to project`}
      >
        <Heading fontSize="sm">
          Are you sure you want to add this {DATA_COLLECTOR} to the project?
        </Heading>
        <Text fontSize="xs" mt={2}>
          By adding a {DATA_COLLECTOR} to the project, they will be able to access or capture
          evidence for the project.
        </Text>
      </AlertModal>
    </ModalWrap>
  )
}

export default AddToProjectModal
