import { Box, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { ReactNode } from 'react'

import { FloatingButtonWrapper } from 'components'
import {
  ConnectedFormGroup,
  ConnectedTextArea,
  ConnectedImageUploader
} from 'components/forms/FormElements'

import { ProjectInput } from 'generated/graphql'
import { addProjectSchema } from 'utils/validationSchemas'

const CreateProjectForm: React.FC<{
  actionBar: ReactNode
  submitForm: (values: ProjectInput) => void
  initialValues: ProjectInput
}> = ({ actionBar, submitForm, initialValues }) => {
  const { ...restOfValues } = initialValues

  const onKeyDown = (keyEvent: React.KeyboardEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  return (
    <Formik
      initialValues={restOfValues}
      enableReinitialize={true}
      validationSchema={addProjectSchema()}
      onSubmit={({ featured_image, collaborators, ...rest }: any) => {
        submitForm({
          ...rest,
          featured_image: featured_image ? featured_image.id : null,
          collaborators: collaborators.split(',')
        } as ProjectInput)
      }}
    >
      {({ handleSubmit, isSubmitting, status }) => (
        <Form onSubmit={handleSubmit} style={{ width: '50%' }} onKeyDown={onKeyDown}>
          <FloatingButtonWrapper
            status={status}
            isLoading={isSubmitting}
            button={actionBar && actionBar}
          >
            <ConnectedFormGroup
              name="name"
              label="Project name *"
              placeholder="Enter the project’s name"
            />
            <ConnectedTextArea
              name="statement"
              label="Project description*"
              placeholder="Provide a description of the project"
            />
            <ConnectedTextArea
              name="outcomes"
              label="Outcomes of this project *"
              placeholder="Enter a description for the impact the project will have"
            />

            <Text fontWeight="semibold" fontSize="xs" mb={2} mt={6}>
              Add a picture for this project
            </Text>

            <Box maxWidth="300px">
              <ConnectedImageUploader name="featured_image" />
            </Box>
          </FloatingButtonWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default CreateProjectForm
