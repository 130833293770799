import React from 'react'
import { Flex, Heading, Skeleton, useToast, Box, HStack, Icon } from '@chakra-ui/react'

import { useParams, useHistory } from 'react-router-dom'
import { BackButton, FloatingCard } from '../../../components'
import { DATA_COLLECTOR, ERROR_TOAST } from '../../../constants'
import {
  Enum_Task_Status,
  useCountTasksQuery,
  useGetBaoleaderTaskDefinitionsCountQuery,
  useGetProjectDataCollectorsCountQuery,
  useGetSingleBaoleaderProjectQuery
} from '../../../generated/graphql'
import { CustomTabContainer, PageWrap } from '../../../layouts'
import images from '../../../theme/images'

import { VscArrowRight } from 'react-icons/vsc'
import { CheckCircle } from 'react-feather'
import { Container } from '@chakra-ui/react'

import { pluralHandler } from 'utils'
import Verifiers from './components/Verifiers'
import { ProjectEntity } from '../../../generated/graphql'
import SubNav from '../../../components/layouts/SubNav/index'
import { ApolloError } from 'apollo-boost'
import TaskTypes from './components/TaskTypes'

import ProjectDetails from './components/ProjectDetails'

import { FcAbout, FcInspection, FcPhoneAndroid, FcTodoList } from 'react-icons/fc'
import ProjectTasks from './components/ProjectTasks'
import { dataCollectorsToolTip, detailsToolTip, tasksReportedToolTip, tasksToolTip } from './help'

const Details = () => {
  const { id } = useParams<{ id: string }>()
  const toast = useToast()
  const history = useHistory()
  const onError = (error: ApolloError) =>
    toast({ title: `There was an error. ${error}`, ...ERROR_TOAST })
  const {
    data: storedProject,
    refetch,
    loading: projectLoading
  } = useGetSingleBaoleaderProjectQuery({
    variables: { id },
    onError
  })

  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)

  const { data: dataCollectorsCountEntity, loading: dataCollectorsCountLoading } =
    useGetProjectDataCollectorsCountQuery({
      variables: {
        projectId: id
      }
    })
  const dataCollectorsCount = dataCollectorsCountEntity?.getProjectDataCollectorsCount || 0

  const { data: taskDefinitionsCount, loading: taskDefinitionsCountLoading } =
    useGetBaoleaderTaskDefinitionsCountQuery({
      variables: {
        filters: {
          project: { id }
        }
      }
    })
  const { data: countTasks, loading: countTasksLoading } = useCountTasksQuery({
    variables: {
      filters: {
        project: { id }
      }
    }
  })

  const { data: countTaskCompleted, loading: countTaskCompletedLoading } = useCountTasksQuery({
    variables: {
      filters: {
        project: { id },
        status: Enum_Task_Status.Completed
      }
    }
  })

  const refetchAll = React.useCallback(async () => {
    await refetch()
  }, [refetch])

  const stats = React.useMemo(
    () => [
      {
        title: 'Tasks',
        icon: FcInspection,
        loading: taskDefinitionsCountLoading,
        value: `${taskDefinitionsCount?.getBaoleaderTaskDefinitionsCount || 0} ${pluralHandler(
          'task',
          taskDefinitionsCount?.getBaoleaderTaskDefinitionsCount || 0
        )}`
      },
      {
        title: 'Tasks completed',
        icon: CheckCircle,
        loading: countTaskCompletedLoading,
        value: `${countTaskCompleted?.countTasks || 0} ${pluralHandler(
          'task',
          countTaskCompleted?.countTasks || 0
        )} completed`
      },
      {
        title: 'Tasks Reported',
        icon: FcTodoList,
        loading: countTasksLoading,
        value: `${countTasks?.countTasks || 0} ${pluralHandler(
          'task',
          countTasks?.countTasks || 0
        )} Reported`
      },
      {
        title: DATA_COLLECTOR + ' Overview',
        icon: FcPhoneAndroid,
        loading: dataCollectorsCountLoading,
        value: `${dataCollectorsCount} ${pluralHandler(DATA_COLLECTOR, dataCollectorsCount)}`
      }
    ],
    [
      countTasksLoading,
      countTaskCompletedLoading,
      dataCollectorsCountLoading,
      taskDefinitionsCountLoading
    ]
  )

  const tabs = React.useMemo(
    () => [
      {
        title: 'Details',
        icon: <FcAbout />,
        loading: projectLoading,
        bg: 'background.100',
        tooltip: { label: detailsToolTip }
      },
      {
        title: 'Tasks',
        icon: <FcInspection />,
        loading: projectLoading,
        bg: 'background.100',
        tooltip: { label: tasksToolTip }
      },

      {
        title: 'Tasks Reported',
        icon: <FcTodoList />,
        loading: projectLoading,
        bg: 'background.50',
        tooltip: { label: tasksReportedToolTip }
      },
      {
        title: `${DATA_COLLECTOR}s`,
        icon: <FcPhoneAndroid />,
        loading: projectLoading,
        bg: 'background.100',
        tooltip: { label: dataCollectorsToolTip }
      }
    ],
    [projectLoading]
  )

  const tabPanels = React.useMemo(
    () => [
      {
        component: <ProjectDetails storedProject={storedProject} projectLoading={projectLoading} />
      },
      {
        component: <TaskTypes />
      },
      {
        component: <ProjectTasks />
      },
      {
        component: (
          <Verifiers
            callback={refetchAll}
            storedProject={storedProject?.getSingleBaoleaderProject as ProjectEntity}
          />
        )
      }
    ],
    [limit, start, projectLoading, refetch, refetchAll]
  )
  const [tabIndex, setTabIndex] = React.useState(0)
  const bg = tabs.map((tab) => tab.bg)[tabIndex]
  if (!projectLoading && !storedProject?.getSingleBaoleaderProject?.attributes)
    history.push('/auth/projects')
  return (
    <PageWrap direction="column" title="Project Details" align="center" p={0} m={0}>
      <SubNav
        image={
          storedProject?.getSingleBaoleaderProject?.attributes?.featured_image?.data?.attributes
            ?.formats?.thumbnail?.url
            ? `${storedProject?.getSingleBaoleaderProject?.attributes?.featured_image?.data?.attributes?.formats?.thumbnail?.url}`
            : images.logoNoText
        }
        rightElement={
          <Heading as="h3" flex={1} fontSize="lg" color="gray.600">
            <Skeleton isLoaded={!projectLoading} maxW="fit-content" minW={200}>
              {storedProject?.getSingleBaoleaderProject?.attributes?.name}
            </Skeleton>
          </Heading>
        }
      />
      <Container my={8} maxW="1600" px={16}>
        <Flex>
          <BackButton
            title=" Back to the list of projects"
            onClick={() => history.push('/auth/projects')}
          />
        </Flex>
      </Container>
      <Box bg={bg} minH="90vh" borderRadius="md" pb={24} width="100%">
        <Container minH="80vh" maxW="1600" px={16} pt={10} position="relative">
          <HStack
            spacing={5}
            position="absolute"
            top={0}
            transform="translateY(-60%)"
            mr={14}
            right={0}
          >
            {stats?.map((stat, index) => (
              <FloatingCard
                icon={<Icon as={stat.icon} fontSize={14} />}
                key={stat.title + index}
                title={stat.title}
                loading={stat.loading}
                value={stat.value}
              />
            ))}
          </HStack>
          <Flex py={10} width="100%" fontSize="sm" align="center" justify="space-between">
            <Skeleton isLoaded={!projectLoading} maxW="fit-content" minW={200}>
              <HStack spacing={4}>
                <Heading as="h3" size="xl" color="gray.400">
                  Project
                </Heading>

                <VscArrowRight size={16} color="gray" />

                <Heading as="h3" size="xl" flex={1} color="gray.600">
                  {storedProject?.getSingleBaoleaderProject?.attributes?.name}
                </Heading>
              </HStack>
            </Skeleton>
          </Flex>
          <CustomTabContainer
            onChange={(index) => {
              setLimit(10)
              setStart(0)
              setTabIndex(index)
            }}
            tabs={tabs}
            isLazy
            tabPanels={tabPanels}
          />
        </Container>
      </Box>
    </PageWrap>
  )
}

export default Details
