import { FlexProps } from '@chakra-ui/react'
import { FloatingCard } from 'components'
import { Enum_Task_Task_Origin } from 'generated/graphql'
import Maybe from 'graphql/tsutils/Maybe'
import React from 'react'
import { UsersPermissionsUserEntity } from '../../../generated/graphql'
import { DATA_COLLECTOR } from '../../../constants'
import { FcPhoneAndroid } from 'react-icons/fc'

type UserViewProps = {
  user: UsersPermissionsUserEntity
  task_origin?: Maybe<Enum_Task_Task_Origin> | undefined
  onClick?: () => void
} & FlexProps

const UserView: React.FC<UserViewProps> = ({ user, task_origin, onClick, ...rest }) => {
  return (
    <FloatingCard
      icon={<FcPhoneAndroid size={12} />}
      onClick={onClick}
      isLink
      title={(task_origin || DATA_COLLECTOR).split('_').join(' ')}
      value={`${user?.attributes?.firstName} ${user?.attributes?.lastName}`}
      {...rest}
    />
  )
}

export default UserView
