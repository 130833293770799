import { IToast } from '@chakra-ui/react'

import { TaskFiltersType } from '../types'
import { UploadFile } from '../generated/graphql'

export const APP_NAME = 'Baotree'

export const STRAPI_USER_STORAGE_KEY = 'strapi-user'
export const CONTACT_SUPPORT_FORM_ID = 'SyAdUMZ6'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

export const API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:1337'
export const API_HOST_WITHOUT_PROTOCOL = API_HOST.replace(/(^\w+:|^)\/\//, '')
export const BAOTREE_IO = 'https://www.baotree.io'
export const BAOTREE_IO_CONTACT = 'https://www.baotree.io/contact'
export const SUBSCRIPTIONS_TYPEFORM_ID = 'PR429DGF'

export const GA_MEASURING_ID = process.env.REACT_APP_GA_MEASURING_ID || 'XXXX'
export const STAGE = process.env.REACT_APP_STAGE

export const CURRENCY_SYMBOL = '$'

export const VERIFIER_ROLE = 'baorider'
export const LEADER_ROLE = 'baoleader'
export const COMMUNITY_MEMBER_ROLE = 'communitymember'
export const DATA_COLLECTOR = 'Data Collector'
export const LEADER = 'Admin'

export const IP_API_URL = 'https://ipapi.co/json/'

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const DEFAULT_DASHBOARD_PATH = process.env.REACT_APP_DEFAULT_DASHBOARD
export const SUCCESS_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  status: 'success',
  position: 'top-right'
}
export const WARNING_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  status: 'warning',
  position: 'top-right'
}
export const INFO_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  status: 'info',
  position: 'top-right'
}

export const ERROR_TOAST: IToast = {
  duration: 6000,
  status: 'error',
  isClosable: true,
  position: 'top-right'
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const EMPTY_FILE: UploadFile = {
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}
export const taskFilters: TaskFiltersType[] = [
  'Project',
  'Task',
  'Data Collector',
  'Status',
  'Community',
  'UNSDG'
]

export const PROJECT_TAGS = [
  'Food Security',
  'End poverty',
  'Sustainable Agriculture',
  'Health',
  'Promote Wellbeing',
  'Quality Education',
  'Gender Equality',
  'Empower Women',
  'Clean Water',
  'Sanitation',
  'Affordable Energy',
  'Clean Energy',
  'Decent Work',
  'Productive Employment',
  'Inclusive Industry',
  'Foster Innovation',
  'Resilient Infrastructure',
  'Reduced Inequality',
  'Sustainable Cities',
  'Resilient Settlements',
  'Responsible Consumption',
  'Sustainable Production',
  'Climate Change',
  'Life Below Water',
  'Life on Land',
  'Promote Peace',
  'Effective Justice',
  'Strong Institutions',
  'Partnerships for Change'
]
