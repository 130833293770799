import { Container, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import * as React from 'react'

import { RouteComponentProps, withRouter } from 'react-router'

import { color, ColorProps, space, SpaceProps } from 'styled-system'

import { useAppContext } from '../../../context/AppProvider'

import ProfileButton from '../../UI/Buttons/ProfileButton'

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
  }

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  }

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: 64px;
  z-index: 1290;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  left: ${(props) => (props.open ? '230px' : '64px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const { drawerOpen } = useAppContext()

  return (
    <HeaderCont pl={drawerOpen ? '136px' : 0} {...rest} bg={'background.200'}>
      <Container maxW="1600" px={16}>
        <Flex>
          <Flex flex={1} justify="flex-end" direction="row" height="100%">
            <Flex flexDirection="row" justifyContent="center" alignItems="center" height="100%">
              <ProfileButton />
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </HeaderCont>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'white'
}
