import { Button, Flex, FormLabel, Text } from '@chakra-ui/react'
import * as React from 'react'
import { SupportedDataFieldType, SupportedDataFieldKeysType } from '../types'
import { SupportedDataFields } from '../constants'
import { fieldTypeToPickHelperPopover } from '../create/help'

const QuestionTypePicker: React.FC<{
  qnTypeError: string
  selectedFieldType: SupportedDataFieldKeysType | ''
  setSelectedFieldType: (key: SupportedDataFieldKeysType) => void
}> = ({ selectedFieldType, setSelectedFieldType, qnTypeError }) => {
  const keys = Object.keys as <T>(o: T) => Extract<keyof T, string | number>[]

  return (
    <Flex mt={4} flexDirection="column">
      <FormLabel fontSize="xs">Choose Question Type</FormLabel>
      <Text color="red.500" p={1} borderRadius={5} fontSize="xs" textAlign="left">
        {qnTypeError}
      </Text>
      <Flex flexWrap="wrap">
        {keys(SupportedDataFields).map((key: SupportedDataFieldKeysType) => {
          const Field: SupportedDataFieldType = SupportedDataFields[key]

          return (
            <Button
              key={Field['display_title']}
              {...(selectedFieldType === key ? { colorScheme: 'green' } : {})}
              leftIcon={Field?.icon}
              fontSize="xs"
              mr={4}
              my={2}
              onClick={() => {
                setSelectedFieldType(key)
              }}
            >
              {SupportedDataFields[key]?.display_title}
            </Button>
          )
        })}
        {fieldTypeToPickHelperPopover}
      </Flex>
    </Flex>
  )
}

export default QuestionTypePicker
