import { TasksTable } from 'components'
import { ERROR_TOAST, taskFilters } from '../../../../constants'

import { TaskEntity, useGetTasksQuery } from 'generated/graphql'
import React from 'react'
import { Box, useToast } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { ApolloError } from 'apollo-boost'

type TasksReportedProps = {}

const TasksReported: React.FC<TasksReportedProps> = () => {
  const { id } = useParams<{ id: string }>()
  const toast = useToast()

  const onError = (error: ApolloError) =>
    toast({ title: `There was an error. ${error}`, ...ERROR_TOAST })
  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)
  const [page, setPage] = React.useState(0)

  const [filters, setFilters] = React.useState<any>(() => ({}))
  const [search, setSearch] = React.useState('')
  const {
    loading,
    refetch: refetchTasks,
    data: tasksEntity
  } = useGetTasksQuery({
    variables: {
      pagination: {
        limit,
        start
      },
      filters: {
        ...filters,
        baorider: { community: { id } },
        id: { $contains: search }
      }
    },
    onError
  })

  const tasks = (tasksEntity?.getBaoleaderTasksWithCount?.data || []) as TaskEntity[]
  const taskCount = tasksEntity?.getBaoleaderTasksWithCount?.count || 0

  const onReset = async () => {
    await refetchTasks({
      filters: { baorider: { community: { id } }, id: { $contains: search } },
      pagination: { limit, start }
    })
  }
  const onFilter = async (where: { [key: string]: string | number | string[] }) => {
    setFilters(where)
    try {
      await refetchTasks({
        pagination: { limit, start },
        filters: { ...where, baorider: { community: { id } }, id: { $contains: search } }
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box py={4}>
      <TasksTable
        loading={loading}
        data={tasks}
        onFilter={onFilter}
        currentPage={page}
        limit={limit}
        setPage={setPage}
        visibleFilters={taskFilters?.filter((filter) => filter !== 'Community')}
        setStart={setStart}
        onSearch={(search) => {
          setSearch(search)
        }}
        searchValue={search}
        count={taskCount || 0}
        setLimit={setLimit}
        start={start}
        onReset={onReset}
      />
    </Box>
  )
}

export default TasksReported
