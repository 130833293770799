import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Container,
  Flex
} from '@chakra-ui/react'

import * as React from 'react'
import { X, Upload } from 'react-feather'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  EmptyListHandler,
  FillLoader,
  SlideShowModal,
  SubNav
} from '../../../components'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import {
  Enum_Task_Status,
  EvidenceEntity,
  Maybe,
  TaskDefinitionEntity,
  UploadFileEntity,
  useGetBaoleaderTaskQuery,
  useUpdateEvidenceMutation,
  useUpdateTaskMutation
} from '../../../generated/graphql'
import { PageListContainer } from '../../../layouts'
import ReactGA from 'react-ga4'
import { VscArrowRight, VscCheck } from 'react-icons/vsc'
import { getImageByFormat } from 'utils'
import UploadFilesModal from '../components/UploadFilesModal'

import {
  ActionBar,
  DataFields,
  EvidenceDetails,
  EvidenceDocuments,
  EvidenceRelationships,
  EvidenceStat,
  Location,
  PermissionsChecker,
  PhotoEvidence,
  StatusParser
} from './components'
import AlertModal from '../../../components/UX/AlertModal/index'
import { FcApproval } from 'react-icons/fc'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'
import { RejectModal } from '../components/TaskModals'
import { replaceQuotationMarks } from 'utils'
import posthog from 'posthog-js'

const SingleTask: React.FC<{}> = () => {
  const toast = useToast()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const { id } = useParams<{ id: string }>()

  const {
    isOpen: showConfirmation,
    onOpen: onShowConfirmation,
    onClose: onCloseConfirmation
  } = useDisclosure()
  const { isOpen: showReject, onOpen: onShowReject, onClose: onCloseReject } = useDisclosure()

  const { isOpen: showLightbox, onOpen: onShowLightbox, onClose: onCloseLightbox } = useDisclosure()
  const {
    isOpen: uploadFileIsOpen,
    onClose: onUploadFileClose,
    onOpen: onUploadFileOpen
  } = useDisclosure()

  const {
    data,
    refetch,
    loading: taskLoading
  } = useGetBaoleaderTaskQuery({
    variables: { id },
    onError,
    fetchPolicy: 'cache-and-network'
  })
  const [currentImageShowing, setCurrentImageShowing] = React.useState<UploadFileEntity[]>([])
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0)

  const [updateEvidence] = useUpdateEvidenceMutation({
    onError,
    onCompleted: async () => {
      await refetch()
    }
  })
  const [updateTask, { loading: updatingTask }] = useUpdateTaskMutation({
    onCompleted: async () => {
      toast({
        description:
          'You have approved this task successfully. The status has been changed to approved.',
        ...SUCCESS_TOAST
      })
    },
    onError
  })
  const evidence = data?.getBaoleaderTask?.attributes?.evidences
    ?.data?.[0] as Maybe<EvidenceEntity> | null
  const location = evidence?.attributes?.location
    ? JSON.parse(replaceQuotationMarks(evidence?.attributes?.location))
    : null
  const options = () => (
    <HStack spacing={2} w="100%" justifyContent="end" align="end" flexDir="row">
      <SecondaryButton
        fontSize="x-small"
        leftIcon={<Upload size={14} />}
        onClick={() => onUploadFileOpen()}
      >
        Upload Files
      </SecondaryButton>
      {data?.getBaoleaderTask?.attributes?.status === Enum_Task_Status.Pending ? (
        <>
          <PrimaryButton
            colorScheme="red"
            fontSize="x-small"
            leftIcon={<X size={14} />}
            onClick={() => onShowReject()}
          >
            REJECT
          </PrimaryButton>
          <PrimaryButton
            colorScheme="green"
            fontSize="x-small"
            leftIcon={<VscCheck />}
            onClick={() => onShowConfirmation()}
          >
            APPROVE
          </PrimaryButton>
        </>
      ) : null}
    </HStack>
  )

  const updateTaskAction = async (status: Enum_Task_Status) => {
    await updateTask({
      variables: {
        id: data?.getBaoleaderTask?.id || '',
        input: { status }
      }
    })
    await refetch()
  }
  const history = useHistory()

  const handleBack = () => {
    history.push('/auth/tasks', {
      filter: (history.location.state as any)?.filter,
      currentPage: (history.location.state as any)?.currentPage,
      limit: (history.location.state as any)?.limit,
      start: (history.location.state as any)?.start
    })
  }

  if (taskLoading) return <FillLoader />
  if (
    !taskLoading &&
    data?.getBaoleaderTask?.attributes?.evidences &&
    data?.getBaoleaderTask?.attributes?.evidences?.data?.length < 1
  )
    return <EmptyListHandler title="Still no evidence for this task" subTitle="Try again later" />

  return (
    <PageListContainer
      mt={0}
      title={`Task #${data?.getBaoleaderTask?.id}`}
      pageActions={
        <>
          <SubNav
            leftElement={options()}
            onGoBack={handleBack}
            rightElement={
              <HStack spacing={4}>
                <Heading as="h3" size="md" color="gray.400">
                  Task
                </Heading>

                <VscArrowRight size={16} color="gray" />

                <Heading as="h3" size="md" flex={1} color="gray.600">
                  {data?.getBaoleaderTask?.id}
                </Heading>
              </HStack>
            }
            image={getImageByFormat(
              data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.category?.data
                ?.attributes?.featureImage?.data,
              'thumbnail'
            )}
          />
          <Container my={8} maxW="1600" px={16}>
            <Flex>
              <BackButton title=" Back to the list of tasks" onClick={handleBack} />
            </Flex>
          </Container>
        </>
      }
      statsComponents={<EvidenceStat data={data} evidence={evidence} />}
      loading={taskLoading}
    >
      <SimpleGrid templateColumns={{ md: '2fr 1fr', sm: '1fr' }} spacing={10}>
        <VStack spacing={6}>
          <ActionBar options={options()} loading={taskLoading} data={data} />
          <EvidenceDetails data={data} />
          <EvidenceRelationships data={data} />
          <DataFields
            data={data}
            evidence={evidence}
            setCurrentImageIndex={setCurrentImageIndex}
            setCurrentImageShowing={setCurrentImageShowing}
            onShowLightbox={onShowLightbox}
          />
          <PhotoEvidence
            data={data}
            evidence={evidence}
            setCurrentImageIndex={setCurrentImageIndex}
            setCurrentImageShowing={setCurrentImageShowing}
            onShowLightbox={onShowLightbox}
          />
          <Location location={location} />
        </VStack>
        <VStack spacing={6} pt={24}>
          <PermissionsChecker evidence={evidence} loading={taskLoading} />
          <StatusParser data={data} evidence={evidence} />
          <EvidenceDocuments data={data} />
          <Box />
        </VStack>

        <RejectModal
          isLoading={updatingTask}
          {...{ isOpen: showReject, onClose: onCloseReject }}
          onConfirm={async (reason) => {
            await updateTaskAction(Enum_Task_Status.Rejected)
            await updateEvidence({
              variables: {
                id: evidence?.id || '',
                input: {
                  cancellationReason: reason
                }
              }
            })
            toast({
              title: 'You have rejected this task successfully.',
              ...SUCCESS_TOAST
            })
            ReactGA.event({
              category: 'Reject Task',
              action: 'Reject Task'
            })
            posthog.capture('Dashboard_Reject_Task', {
              taskId: data?.getBaoleaderTask?.id
            })
            onCloseReject()
          }}
        />
        <AlertModal
          {...{
            isOpen: showConfirmation,
            onClose: onCloseConfirmation,
            updatingTask,
            title: 'Task approved!'
          }}
          icon={<FcApproval size={38} />}
          activatorStatus="activate"
          confirmButtonText="Approve"
          onConfirm={async () => {
            await updateTaskAction(Enum_Task_Status.Completed)

            ReactGA.event({
              category: 'Approve Task',
              action: 'Approve Task'
            })
            posthog.capture('Dashboard_Approve_Task', {
              taskId: data?.getBaoleaderTask?.id
            })
            onCloseConfirmation()
          }}
        >
          <Text>Are you sure you want to approve this task?</Text>
        </AlertModal>
      </SimpleGrid>

      <SlideShowModal
        photos={currentImageShowing}
        task={data?.getBaoleaderTask as TaskDefinitionEntity}
        isOpen={showLightbox}
        index={currentImageIndex}
        onClose={onCloseLightbox}
        loading={updatingTask}
      />
      <UploadFilesModal
        isOpen={uploadFileIsOpen}
        onClose={onUploadFileClose}
        taskId={id}
        refetch={refetch}
        title="Upload files"
      />
    </PageListContainer>
  )
}

export default SingleTask
