import { BAOTREE_IO, BAOTREE_IO_CONTACT } from '../../../../constants'

export const PUBLIC_ROUTES = [
  {
    title: 'Baotree.io',
    path: BAOTREE_IO
  },
  {
    title: 'Contact Us',
    path: BAOTREE_IO_CONTACT
  }
]
export const PRIVATE_ROUTES = []
