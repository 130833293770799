import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  VStack,
  Avatar,
  Heading,
  Text,
  Center,
  Box,
  Image,
  IconButton,
  HStack,
  Flex,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import React from 'react'
import { VscTrash, VscAccount, VscChevronRight, VscCheck, VscClose } from 'react-icons/vsc'

import { useHistory } from 'react-router-dom'

import { StatusBadge } from 'components'

import { getImageByFormat, pluralHandler } from 'utils'
import styled from '@emotion/styled'
import { UsersPermissionsUserEntity } from '../../../generated/graphql'

const ListPair = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
`
const Pair: React.FC<{
  left: React.ReactNode
  right: React.ReactNode
}> = ({ left, right }) => {
  return (
    <ListPair>
      <Box color="gray.600" fontSize="xs">
        {left}
      </Box>
      <Box fontSize="xs" fontWeight="semibold">
        {right}
      </Box>
    </ListPair>
  )
}

type VerifierDrawerProps = {
  selectedVerifier: UsersPermissionsUserEntity | null
  onRemove?: () => void
  image?: any
  onAccept?: () => void
  onReject?: () => void
  reason?: string
  onDrawerClose: () => void
  isDrawerOpen: boolean
}

const VerifierDrawer: React.FC<VerifierDrawerProps> = ({
  selectedVerifier,
  onRemove,
  onDrawerClose,
  reason,
  onAccept,
  onReject,
  isDrawerOpen
}) => {
  const history = useHistory()
  const isActive = selectedVerifier?.attributes?.status === 'ACTIVE'
  return (
    <Drawer placement="right" size="sm" onClose={onDrawerClose} isOpen={isDrawerOpen}>
      <DrawerOverlay />
      <DrawerContent background="gray.100">
        <DrawerBody p={0}>
          <VStack spacing={6} mb={3} bg="white" py={14}>
            <Avatar
              src={selectedVerifier?.attributes?.profilePicture?.data?.attributes?.url}
              size="xl"
              name={`${selectedVerifier?.attributes?.firstName} ${selectedVerifier?.attributes?.lastName}`}
            />
            <Center flexDirection="column">
              <Heading mb={1} fontSize="lg" as="h2">
                {selectedVerifier?.attributes?.firstName} {selectedVerifier?.attributes?.lastName}
              </Heading>
              <Text fontSize="xs">{selectedVerifier?.attributes?.email}</Text>
              <Text fontSize="xs" color="gray.500">
                {selectedVerifier?.attributes?.phoneNumber}
              </Text>
            </Center>
            <HStack
              w="100%"
              position="relative"
              spacing={8}
              mt={10}
              justifyContent="center"
              alignItems="center"
            >
              {onAccept && !isActive && (
                <IconButton
                  aria-label="Accept"
                  position="relative"
                  colorScheme="green"
                  onClick={onAccept}
                  size="lg"
                  _after={{
                    content: '"Accept"',
                    position: 'absolute',
                    top: '130%',
                    left: 0,
                    fontSize: 'x-small',
                    width: '100%'
                  }}
                  variant="ghost"
                  rounded="full"
                  icon={<VscCheck />}
                />
              )}
              {onAccept && !isActive && (
                <IconButton
                  aria-label="Reject"
                  colorScheme="red"
                  position="relative"
                  onClick={onReject}
                  size="lg"
                  _after={{
                    content: '"Reject"',
                    position: 'absolute',
                    top: '130%',
                    left: 0,
                    fontSize: 'x-small',
                    width: '100%'
                  }}
                  variant="ghost"
                  rounded="full"
                  icon={<VscClose />}
                />
              )}
              {onRemove && (
                <IconButton
                  colorScheme="red"
                  aria-label="Remove"
                  size="lg"
                  onClick={onRemove}
                  position="relative"
                  variant="ghost"
                  _after={{
                    content: '"Remove"',
                    position: 'absolute',
                    top: '130%',
                    left: 0,
                    fontSize: 'x-small',
                    width: '100%'
                  }}
                  rounded="full"
                  icon={<VscTrash />}
                />
              )}

              {selectedVerifier?.attributes?.role?.data?.attributes?.type === 'baorider' && (
                <IconButton
                  aria-label="profile"
                  position="relative"
                  onClick={() => history.push(`/auth/baoriders/${selectedVerifier?.id}`)}
                  size="lg"
                  _after={{
                    content: '"Profile"',
                    position: 'absolute',
                    top: '130%',
                    left: 0,
                    fontSize: 'xs',
                    width: '100%'
                  }}
                  variant="ghost"
                  rounded="full"
                  icon={<VscAccount />}
                />
              )}
            </HStack>
          </VStack>
          <Flex p={6} direction="column" bg="white">
            <Heading as="h3" mb={6} fontSize="md">
              Details
            </Heading>
            <VStack spacing={4}>
              <Pair
                left="Status"
                right={<StatusBadge status={selectedVerifier?.attributes?.status} />}
              />
              <Pair left="Phone Number" right={selectedVerifier?.attributes?.phoneNumber} />

              {reason && (
                <Box w="100%">
                  <Text color="gray.600" fontSize="xs">
                    Reason
                  </Text>
                  <Alert status="info" variant="left-accent">
                    <AlertIcon />
                    <Text fontSize="sm">{reason}</Text>
                  </Alert>
                </Box>
              )}
            </VStack>
          </Flex>
          {selectedVerifier?.attributes?.projects &&
            selectedVerifier?.attributes?.projects?.data?.length > 0 && (
              <Flex p={6} direction="column" bg="white">
                <Text fontSize="xs" mb={6}>
                  {pluralHandler(
                    'Project',
                    selectedVerifier?.attributes?.projects?.data?.length || 0
                  )}
                </Text>
                {selectedVerifier?.attributes?.projects?.data?.map((project) => (
                  <Flex
                    key={project?.id}
                    mb={4}
                    align="center"
                    justify="space-between"
                    cursor="pointer"
                    onClick={() => history.push(`/auth/projects/${project?.id}`)}
                  >
                    <Flex align="center">
                      <Image
                        src={getImageByFormat(project?.attributes?.featured_image?.data)}
                        objectFit="cover"
                        mr={5}
                        align="community profile"
                        boxSize={10}
                        rounded="2xl"
                      />
                      <Flex direction="column" justifyContent="center">
                        <Heading as="h3" mb={1} size="xs">
                          {project?.attributes?.name}
                        </Heading>

                        <Text fontSize="x-small" flex={1} color="gray.500" noOfLines={3}>
                          {project?.attributes?.description}
                        </Text>
                      </Flex>
                    </Flex>
                    <Box w={6}>
                      <VscChevronRight size={24} color="lightgray" />
                    </Box>
                  </Flex>
                ))}
              </Flex>
            )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default VerifierDrawer
