import { Box, Flex, VStack, Text, Avatar, Image, HStack, Textarea } from '@chakra-ui/react'
import * as React from 'react'
import { images } from 'theme'
import { SupportedDataFieldType } from '../../types'
import InputRenderer from './InputRenderer'
import { MotionFlex } from 'components'
import { FaTasks, FaWifi, FaSignal, FaBatteryHalf } from 'react-icons/fa'
import moment from 'moment'
import { VscChevronLeft } from 'react-icons/vsc'
import { OptionType } from 'types'

type MobileViewRendererProps = {
  fields: Array<SupportedDataFieldType>
  taskTypeMetadate: OptionType
}

const MobileViewRenderer: React.FC<MobileViewRendererProps> = ({ fields, taskTypeMetadate }) => {
  return (
    <MotionFlex
      style={{
        bottom: 20,
        position: 'fixed'
      }}
      marginTop={5}
      variants={{
        hidden: {
          opacity: 0
        },
        visible: {
          opacity: 1,
          transition: {
            type: 'spring',
            damping: 20
          }
        }
      }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        marginTop="3px"
        backgroundImage={images.androidPhoneFrame}
        backgroundRepeat="no-repeat"
        zIndex={10}
        bgPosition="center top"
        flexDirection="column"
        height="570px"
        width="402px"
        alignContent="center"
        alignItems="center"
        overflowY="hidden"
      >
        <Flex mt={6} direction="column" width="67%" scrollBehavior="smooth" height="515px">
          <MobileStatusBar taskTypeMetadate={taskTypeMetadate} />
          <Flex
            overflowY={'auto'}
            flex={1}
            mt="60px"
            px={4}
            direction="column"
            pt={3}
            pb={6}
            w="100%"
            mb="46px"
          >
            {fields &&
              fields?.length > 0 &&
              fields?.map((field, fieldIndex) => <InputRenderer key={fieldIndex} field={field} />)}
            {taskTypeMetadate?.withComment && (
              <Textarea
                size="xs"
                resize="none"
                variant="filled"
                rounded="lg"
                placeholder="Comment ..."
              />
            )}
          </Flex>
          <MobileMenu />
        </Flex>
      </Flex>
    </MotionFlex>
  )
}
const MobileStatusBar: React.FC<{ taskTypeMetadate: OptionType }> = ({ taskTypeMetadate }) => {
  return (
    <Box width="270px" borderRadius="6px 6px 0 0" zIndex={9} bg="white" position="fixed" px={4}>
      <Flex fontSize="x-small" justify="space-between">
        <Text>{moment().format('hh:mm A')}</Text>
        <HStack>
          <FaWifi />
          <FaSignal />
          <Text>47%</Text>
          <FaBatteryHalf />
        </HStack>
      </Flex>
      <Flex py={1} align="center">
        <VscChevronLeft size={20} />
        <VStack alignItems="flex-start" ml={2} spacing={0}>
          <Text fontSize="xs" fontWeight="semibold">
            Reporting
          </Text>
          <Text fontSize="x-small">on @{taskTypeMetadate?.title ?? 'title'}</Text>
        </VStack>
      </Flex>
    </Box>
  )
}

const MobileMenu = () => {
  return (
    <Flex
      justify="space-between"
      position="fixed"
      borderRadius={'0px 0px 7px 7px'}
      bottom="70px"
      width="270px"
      zIndex={9}
      py={1}
      bg="white"
      color="green.700"
      px={2}
    >
      <Pair title="Tasks">
        <FaTasks />
      </Pair>
      <Pair title="Report">
        <Box width="50px" position="relative" height="21px">
          <Image
            src={images.icon}
            position="absolute"
            left="50%"
            width="48px"
            height="48px"
            transform="translate(-50%,-50%)"
            rounded="full"
            objectFit="contain"
          />
        </Box>
      </Pair>

      <Pair>
        <Avatar size="sm" name="Dorny Muba" />
      </Pair>
    </Flex>
  )
}

const Pair: React.FC<{ title?: string; children?: React.ReactNode }> = ({ children, title }) => {
  return (
    <VStack flex={1} cursor="pointer" spacing={1} textAlign="center">
      {children}
      {title && <Text fontSize="xx-small">{title}</Text>}
    </VStack>
  )
}
export default MobileViewRenderer
