import { Avatar, Box, Center, Flex, Text } from '@chakra-ui/react'
import { CustomTooltip, EmptyListHandler, Pagination, StatusBadge, Table } from 'components'

import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'

import { Formik } from 'formik'
import { UsersPermissionsUserEntity } from 'generated/graphql'
import moment from 'moment'
import React from 'react'

import { MdAdd } from 'react-icons/md'
import { VscTrash } from 'react-icons/vsc'
import { Cell, Column, Row } from 'react-table'
import TableActions, { VisibleFiltersType } from './components/TableActions'
import { Maybe } from '../../../generated/graphql'

const DataCollectorsTable: React.FC<{
  onReset?: () => void
  onFilter?: (filterValues: { [key: string]: string | number }) => void
  onExport?: () => void
  onCreate: () => void
  onRemove?: () => void
  setLimit: React.Dispatch<React.SetStateAction<number>>
  limit: number
  start: number
  setStart: React.Dispatch<React.SetStateAction<number>>
  count: number
  titleActions?: () => React.ReactNode
  setSelectedFilter?: React.Dispatch<React.SetStateAction<string>>
  selectedFilter?: string
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
  searchTerm?: string
  title: string
  onClick: (baorider: UsersPermissionsUserEntity) => void
  baoriders: Maybe<UsersPermissionsUserEntity>[]
  loading: boolean
  visibleFilters?: VisibleFiltersType[]
  dataCollectorsHelpPopover?: React.ReactNode
  addDatacollectorTooltip?: React.ReactNode
  exportExcelTooltipContent?: React.ReactNode
}> = ({
  baoriders,
  loading,
  onReset,
  onFilter,
  onExport,
  onCreate,
  onClick,
  titleActions,
  setSearchTerm,
  searchTerm,
  setSelectedFilter,
  selectedFilter,
  title,
  onRemove,
  count,
  limit,
  setLimit,
  start,
  setStart,
  visibleFilters,
  dataCollectorsHelpPopover,
  addDatacollectorTooltip,
  exportExcelTooltipContent
}) => {
  const [showFilters, setShowFilters] = React.useState(false)
  const [filters, setFilters] = React.useState(0)

  // TODO: split out filter function into re-useable if it doesnt change
  const onFilterAction = (values: { [key: string]: string | number }) => {
    const filterValues: { [key: string]: string | number } = {}
    for (const key in values) {
      if (values[key] !== '' && values[key] !== null) {
        filterValues[key] = values[key]
      }
    }

    setFilters(Object.keys(filterValues).length)
    return filterValues
  }

  const columns = [
    {
      Header: 'Avatar',
      Cell: ({
        row: {
          original: { attributes }
        }
      }: Cell<UsersPermissionsUserEntity>) => {
        return (
          <Avatar
            name={`${attributes?.firstName} ${attributes?.lastName}`}
            size="sm"
            src={attributes?.profilePicture?.data?.attributes?.formats?.thumbnail?.url}
          />
        )
      }
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      Cell: ({ row: { original } }: Cell<UsersPermissionsUserEntity>) => {
        return (
          <Text fontWeight="medium" color="gray.500">
            {original.attributes?.firstName}
          </Text>
        )
      }
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      Cell: ({ row: { original } }: Cell<UsersPermissionsUserEntity>) => {
        return (
          <Text fontWeight="medium" color="gray.500">
            {original.attributes?.lastName}
          </Text>
        )
      }
    },
    {
      Header: 'Number',
      accessor: 'phoneNumber',
      Cell: ({ row: { original } }: Cell<UsersPermissionsUserEntity>) => {
        return <Text>{original.attributes?.phoneNumber}</Text>
      }
    },
    {
      Header: 'Group',
      accessor: 'community',
      Cell: ({ row: { original } }: Cell<UsersPermissionsUserEntity>) => {
        return (
          <Text color="gray.600">
            {original.attributes?.community?.data?.attributes?.name || 'No Group'}
          </Text>
        )
      }
    },
    {
      Header: 'Number of Projects',
      accessor: 'projects',
      Cell: ({ row: { original } }: Cell<UsersPermissionsUserEntity>) => {
        return (
          <CustomTooltip
            label={original?.attributes?.projects?.data?.map((project) => (
              <Text key={project?.id} p={1}>
                {project?.attributes?.name}
              </Text>
            ))}
          >
            <Text fontSize="xs" color="gray.600">
              <b>{original?.attributes?.projects?.data?.length}</b> project(s)
            </Text>
          </CustomTooltip>
        )
      }
    },
    {
      Header: 'Date Added',
      accessor: 'createdAt',
      Cell: ({ row: { original } }: Cell<UsersPermissionsUserEntity>) => {
        return <Text color="gray.500">{moment(original.attributes?.createdAt).format('LL')}</Text>
      }
    },
    {
      Header: 'Status',
      Cell: ({ row: { original } }: Cell<UsersPermissionsUserEntity>) => {
        return <StatusBadge status={original.attributes?.status} withIcon />
      }
    },
    ...(onRemove
      ? [
          {
            Header: ' ',
            Cell: () => {
              return (
                <Center width="100%" color="red.500">
                  <VscTrash size={18} onClick={onRemove} />
                </Center>
              )
            }
          }
        ]
      : [])
  ] as Column<UsersPermissionsUserEntity>[]

  const help = dataCollectorsHelpPopover

  return (
    <>
      <Formik
        initialValues={{ name: '', status: '' }}
        enableReinitialize={true}
        onSubmit={(values) => {
          const appliedFilters = onFilterAction(values)
          const filterValues = Object.keys(appliedFilters).map((key) => {
            switch (key) {
              case 'group':
                return {
                  community: appliedFilters[key]
                }
              case 'project':
                return {
                  projects: [appliedFilters[key]]
                }
              default:
                return {
                  [key]: appliedFilters[key]
                }
            }
          })

          const filterValuesObject = Object.assign({}, ...filterValues)

          onFilter?.(filterValuesObject)
        }}
      >
        {({ submitForm, resetForm }) => (
          <Flex direction="column">
            <Table
              isLoading={loading}
              columns={columns}
              searchFilterOptions={[
                { label: 'First Name', value: 'firstName' },
                { label: 'Last Name', value: 'lastName' },
                { label: 'Phone Number', value: 'phoneNumber' }
              ]}
              titleActions={
                titleActions
                  ? titleActions
                  : () => (
                      <CustomTooltip label={addDatacollectorTooltip}>
                        <Box>
                          <PrimaryButton leftIcon={<MdAdd />} mr={3} onClick={onCreate}>
                            Create new {title}
                          </PrimaryButton>
                        </Box>
                      </CustomTooltip>
                    )
              }
              data={baoriders}
              tableHeading={`${title}s Management`}
              emptyListComponent={
                <EmptyListHandler
                  title={`No ${title}s`}
                  subTitle={`No ${title}s found, please create a new ${title} to get started.`}
                  action={
                    <SecondaryButton mt={10} leftIcon={<MdAdd />} onClick={onCreate}>
                      Create new {title}
                    </SecondaryButton>
                  }
                />
              }
              help={help}
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
              tableActions={() => [
                <TableActions
                  key={title + Math.random()}
                  visibleFilters={visibleFilters}
                  {...{
                    onReset,
                    onExport,
                    submitForm,
                    resetForm,
                    showFilters,
                    numberOfFilters: filters,
                    setShowFilters,
                    baoriders,
                    exportExcelTooltipContent
                  }}
                />
              ]}
              searchPlaceholder="Search ..."
              searchValue={searchTerm}
              onSearch={(inputValue) => {
                if (setSearchTerm) setSearchTerm(inputValue)
              }}
              onRowClick={(row: Row<UsersPermissionsUserEntity>) => {
                onClick(row.original)
              }}
              pageSize={10}
            />
            <Pagination
              setLimit={setLimit}
              start={start}
              setStart={setStart}
              count={count}
              limit={limit}
            />
          </Flex>
        )}
      </Formik>
    </>
  )
}

export default DataCollectorsTable
