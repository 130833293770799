import { Input, InputGroup, InputProps, Box } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { Eye, EyeOff } from 'react-feather'
import { VscLock } from 'react-icons/vsc'
import { Text } from '../../../../typography'
import { FieldContainer } from '../components'
import { LabelProps } from '../styles'

export type ConnectedPasswordGroupProps = LabelProps &
  InputProps & {
    label?: string
    name: string
    hint?: string
    leftIcon?: React.ElementType
  }

const ConnectedPasswordGroup: React.FC<ConnectedPasswordGroupProps> = ({
  label,
  type,
  leftIcon,
  hint,
  ...rest
}) => {
  const [{ onBlur: onFieldBlur, ...field }, meta] = useField(rest.name)
  const [isFocused, setIsFocused] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const inputRef = React.useRef<HTMLInputElement>(null)
  const showError = meta.touched && meta.error
  return (
    <Box mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FieldContainer
        leftIcon={leftIcon ?? VscLock}
        isFocused={isFocused}
        showError={showError}
        inputRef={inputRef}
        setIsFocused={setIsFocused}
        label={label}
        name={field.name}
        rightIcon={show ? EyeOff : Eye}
        handleClickRightIcon={handleClick}
      >
        <InputGroup size="md">
          <Input
            focusBorderColor="green.200"
            fontSize="sm"
            ref={inputRef}
            onFocus={() => {
              setIsFocused(true)
            }}
            p={0}
            variant="unstyled"
            type={show ? 'text' : type}
            {...field}
            {...rest}
            id={field.name}
            onBlur={(e) => {
              setIsFocused(false)
              onFieldBlur(e)
            }}
          />
        </InputGroup>
      </FieldContainer>
      {!showError && hint ? (
        <Text color="text.300" textAlign="right" fontSize="0.8rem">
          {hint}
        </Text>
      ) : null}
      {showError ? (
        <Text color="red.500" fontSize="xs" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Box>
  )
}

export default ConnectedPasswordGroup

ConnectedPasswordGroup.defaultProps = {
  mb: 2,
  type: 'password'
}
