import { Fade, HStack, Text } from '@chakra-ui/react'

import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { PUBLIC_ROUTES } from '../routes'

type LaptopProps = {}

const Laptop: React.FC<LaptopProps> = ({}) => {
  const router = useRouteMatch()

  return (
    <Fade in={true}>
      <HStack
        spacing={6}
        fontWeight="semibold"
        ml={2}
        textTransform="uppercase"
        letterSpacing={0.1}
        fontSize={9}
      >
        {PUBLIC_ROUTES.map(({ title, path }) => {
          const active = router.path === path

          return (
            <a href={path} key={path} rel="noreferrer" target="_blank">
              <Text
                position="relative"
                _before={{
                  content: '""',
                  borderBottom: '1px solid',
                  borderColor: 'green.200',
                  position: 'absolute',
                  top: '130%',
                  left: 0,
                  transition: 'width .3s ease-in-out 0s',
                  width: 0,
                  ...(active && {
                    width: '100%'
                  })
                }}
                _hover={{
                  color: 'blue.900',
                  cursor: 'pointer',
                  _before: {
                    width: '100%'
                  }
                }}
                color={active ? 'blue.800' : undefined}
              >
                {title}
              </Text>
            </a>
          )
        })}
      </HStack>
    </Fade>
  )
}

export default Laptop
