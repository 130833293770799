import { HStack, SkeletonCircle, Skeleton } from '@chakra-ui/react'
import React from 'react'

const ListLoader: React.FC = () => {
  const arr = Array.from(Array(10).keys())
  return (
    <>
      {arr.map((item) => (
        <HStack my={2} w="100%" pl={4} alignItems="flex-start" spacing={3} key={item}>
          <SkeletonCircle p={4} size="10" />
          <Skeleton noOfLines={10} width="100%" height="20" />
        </HStack>
      ))}
    </>
  )
}

export default ListLoader
