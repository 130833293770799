import { Box, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { MdCheckCircle, MdTextFields } from 'react-icons/md'

import { Text } from '../../../../typography'
import { FieldContainer } from '../components'

import { LabelProps } from '../styles'

export type ConnectedFormGroupProps = LabelProps &
  InputProps & {
    label?: string
    showIsValidValue?: boolean
    name: string
    hint?: string
    leftIcon?: React.ElementType
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  label,
  showIsValidValue,
  hint,
  leftIcon,
  ...rest
}) => {
  const [{ onBlur: onFieldBlur, ...field }, meta] = useField(rest.name)
  const [isFocused, setIsFocused] = React.useState(false)
  const showError = meta.touched && meta.error
  const inputRef = React.useRef<HTMLInputElement>(null)
  return (
    <Box mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb} w={rest.w}>
      <FieldContainer
        leftIcon={leftIcon ?? MdTextFields}
        isFocused={isFocused}
        showError={showError}
        inputRef={inputRef}
        setIsFocused={setIsFocused}
        label={label}
        name={field.name}
      >
        <InputGroup>
          {showIsValidValue && meta.touched && !meta.error && !rest.isInvalid && (
            <InputLeftElement>
              <Box as={MdCheckCircle} color="green.500" zIndex={9} />
            </InputLeftElement>
          )}
          <Input
            focusBorderColor="green.200"
            fontSize="sm"
            ref={inputRef}
            onFocus={() => {
              setIsFocused(true)
            }}
            variant="unstyled"
            {...field}
            value={field.value ?? ''}
            id={field.name}
            {...rest}
            onBlur={(e) => {
              setIsFocused(false)
              onFieldBlur(e)
            }}
          />
        </InputGroup>
      </FieldContainer>
      {!showError && hint ? (
        <Text color="gray.500" textAlign="right" fontSize="0.8rem">
          {hint}
        </Text>
      ) : null}
      {showError ? (
        <Text color="red.500" fontSize="xs" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Box>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'text'
}
