import { Box, Text, Link } from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'

export const dataCollectorsTitleHelperPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        The <Text as="b">Data Collectors</Text> page is where you can <Text as="b">view</Text> and{' '}
        <Text as="b">manage</Text> all your <Text as="b">Data Collectors</Text>. Your{' '}
        <Text as="b">Data Collectors</Text> are the people who will be collecting data for your
        projects.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const exportExcelTooltipContent = (
  <Box px={4} py={6}>
    <Text>Use this button to export the details of your company&apos;s Data Collectors.</Text>
  </Box>
)

export const addDataCollectorTooltipContent = (
  <Box px={4} py={6}>
    <Text>Use this button to add a new Data Collector to the system.</Text>
  </Box>
)
