import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  NumberInputProps
} from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { Text } from '../../../../typography'

type ConnectedNumberInputProps = NumberInputProps & {
  label?: string
  name: string
  unit?: string
  showIsValidValue?: boolean
}

const ConnectedNumberInput: React.FC<ConnectedNumberInputProps> = ({
  label,
  precision,
  unit,
  showIsValidValue,
  ...rest
}) => {
  const [field, meta] = useField(rest.name)
  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FormControl>
        {label && (
          <FormLabel fontSize="xs" htmlFor={field.name}>
            {label}
          </FormLabel>
        )}
        <InputGroup width="100%">
          {!!unit && <InputLeftAddon fontSize="sm">{unit}</InputLeftAddon>}

          <NumberInput {...rest} precision={precision} step={0.01} width="100%">
            {showIsValidValue && meta.touched && !meta.error && !rest.isInvalid && (
              <InputLeftElement top="50%" transform="translateY(-50%)" px={3}>
                <Box as={MdCheckCircle} color="green.500" zIndex={9} />
              </InputLeftElement>
            )}
            <NumberInputField
              {...field}
              id={field.name}
              value={field.value ?? ''}
              roundedLeft={!!unit ? 0 : 4}
              placeholder={rest.placeholder}
              paddingLeft={
                showIsValidValue && meta.touched && !meta.error && !rest.isInvalid ? 8 : 3
              }
            />
          </NumberInput>
        </InputGroup>
        {meta.touched && meta.error ? (
          <Text color="red.500" fontSize="xs" textAlign="right">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedNumberInput

ConnectedNumberInput.defaultProps = {
  mb: 2,
  fontWeight: 'lighter',
  precision: 0
}
