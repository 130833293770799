import { Box, HStack, Skeleton, Text } from '@chakra-ui/react'
import React from 'react'
import { VscArrowRight } from 'react-icons/vsc'

type EditedIndicatorProps = {
  prev: string | undefined
  current: string | undefined
  loading?: boolean
}

const EditedIndicator: React.FC<EditedIndicatorProps> = ({ prev, current, loading }) => {
  return (
    <Skeleton my={1} maxW="fit-content" isLoaded={!loading}>
      <HStack color="gray.500" fontSize="x-small">
        <Box rounded="full" boxSize={1.5} bg="orange.300" />
        <Text fontWeight="semibold">{prev}</Text>
        <VscArrowRight />
        <Text fontWeight="semibold" color="orange.500">
          {current}
        </Text>
      </HStack>
    </Skeleton>
  )
}

EditedIndicator.defaultProps = {
  loading: false
}

export default EditedIndicator
