import { SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { CommunityEntity, Maybe } from '../../../generated/graphql'
import CommunityCard from './CommunityCard'

type CommunitiesGridProps = {
  communities: Maybe<CommunityEntity>[] | undefined
}

const CommunitiesGrid: React.FC<CommunitiesGridProps> = ({ communities }) => {
  return (
    <SimpleGrid
      columnGap={6}
      rowGap={20}
      templateColumns={{
        lg: 'repeat(4, 1fr)',
        md: 'repeat(3, 1fr)',
        sm: 'repeat(2, 1fr)',
        base: 'repeat(1, 1fr)'
      }}
    >
      {communities?.map((community) => (
        <CommunityCard key={community?.id} community={{ ...community } as CommunityEntity} />
      ))}
    </SimpleGrid>
  )
}

export default CommunitiesGrid
