/* eslint-disable react/no-unescaped-entities */
import { Flex, Heading, Box, Text, useDisclosure, HStack, List } from '@chakra-ui/react'
import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'
import { useAuthContext } from 'context/AuthProvider'
import { PageListContainer } from 'layouts'
import React, { useEffect } from 'react'
import {
  CompleteBillingDetails,
  CompleteProfile,
  StepListItemProps,
  VerifyEmail
} from '../components'
import { useMeQuery } from 'generated/graphql'
import { ModalWrap } from 'components'
import { VscArrowRight } from 'react-icons/vsc'
import posthog from 'posthog-js'

// move to constants
export enum OnboardingSteps {
  VerifyEmail = 'verify-email',
  CompleteProfile = 'complete-profile',
  CompleteBillingDetails = 'complete-billing-details',
  CreateProject = 'create-project'
}

const OnboardingLandingPage: React.FC<{}> = () => {
  const { user } = useAuthContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentStep, setCurrentStep] = React.useState<OnboardingSteps>(OnboardingSteps.VerifyEmail)

  const [nextStep, setNextStep] = React.useState<OnboardingSteps>(OnboardingSteps.CompleteProfile)

  const { data: meData, refetch: refetchMeData } = useMeQuery()

  useEffect(() => {
    if (
      meData?.myUser?.attributes?.onboardingInformation?.emailVerified &&
      !meData?.myUser?.attributes?.onboardingInformation?.profileCompleted
    ) {
      setNextStep(OnboardingSteps.CompleteProfile)
      setCurrentStep(OnboardingSteps.CompleteProfile)
    }
    if (
      meData?.myUser?.attributes?.onboardingInformation?.profileCompleted &&
      meData?.myUser?.attributes?.onboardingInformation?.emailVerified
    ) {
      setNextStep(OnboardingSteps.CompleteBillingDetails)
      setCurrentStep(OnboardingSteps.CompleteBillingDetails)
    }
  }, [meData])

  const canClickNext = () => {
    if (meData?.myUser?.attributes) {
      if (currentStep === OnboardingSteps?.VerifyEmail) {
        return !meData?.myUser?.attributes?.onboardingInformation?.emailVerified
      }
      if (currentStep === OnboardingSteps?.CompleteProfile) {
        return !meData?.myUser?.attributes?.onboardingInformation?.profileCompleted
      }
      if (currentStep === OnboardingSteps?.CompleteBillingDetails) {
        return !meData?.myUser?.attributes?.onboardingInformation?.billingInformationCompleted
      }
    }
  }
  return (
    <PageListContainer title="Verify Email">
      <Flex py={8} justify="space-between" align="center">
        <Heading color="gray.600">Hey {user?.attributes?.firstName} 👋,</Heading>
      </Flex>
      {meData?.myUser?.attributes?.confirmed ? (
        <Text>You've already completed your onboarding.</Text>
      ) : (
        <>
          <Text>
            Before you can get started with Baotree, we need to finish setting up your account in
            three easy steps.
          </Text>
          <List spacing={5} my={10}>
            <StepListItemProps
              title="Step 1: First we need to verify email address"
              isCurent={currentStep === OnboardingSteps.VerifyEmail}
              completed={meData?.myUser?.attributes?.onboardingInformation?.emailVerified}
            />
            <StepListItemProps
              title="Step 2: You can then update your personal and business information"
              isCurent={currentStep === OnboardingSteps?.CompleteProfile}
              completed={meData?.myUser?.attributes?.onboardingInformation?.profileCompleted}
            />
            <StepListItemProps
              title="Step 3: Select your Baotree subscription and start your free trial"
              isCurent={currentStep === OnboardingSteps?.CompleteBillingDetails}
              completed={
                meData?.myUser?.attributes?.onboardingInformation?.billingInformationCompleted
              }
            />
          </List>

          <PrimaryButton
            alignSelf="center"
            width="40%"
            marginTop="2vh"
            bottom={0}
            transform="translateY(50%)"
            onClick={onOpen}
          >
            {currentStep === OnboardingSteps.VerifyEmail ? 'Get Started' : 'Continue'}
          </PrimaryButton>

          <ModalWrap
            isOpen={isOpen}
            title={
              currentStep === OnboardingSteps.VerifyEmail
                ? 'Verify your email address'
                : currentStep === OnboardingSteps.CompleteProfile
                ? 'Update your personal and business information'
                : 'Start your Baotree Free Trial!'
            }
            onClose={onClose}
            rightElement={
              currentStep === OnboardingSteps.CompleteBillingDetails ? () => null : null
            }
          >
            <Box p={4}>
              {currentStep === OnboardingSteps.VerifyEmail && (
                <VerifyEmail refetchMeData={refetchMeData} meData={meData} />
              )}
              {currentStep === OnboardingSteps.CompleteProfile && (
                <CompleteProfile refetchMeData={refetchMeData} meData={meData} />
              )}
              {currentStep === OnboardingSteps.CompleteBillingDetails && <CompleteBillingDetails />}

              <HStack spacing={4} mt={16}>
                <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                {/* This state will eventually be "onboardingState.nextStep", driven by the backend updates */}
                {!canClickNext() && (
                  <PrimaryButton
                    variant="ghost"
                    rightIcon={<VscArrowRight />}
                    disabled={canClickNext()}
                    onClick={() => {
                      posthog.capture('onboarding_step_completed', {
                        step: currentStep
                      })
                      setCurrentStep(nextStep)
                    }}
                  >
                    Next
                  </PrimaryButton>
                )}
              </HStack>
            </Box>
          </ModalWrap>
        </>
      )}
    </PageListContainer>
  )
}

export default OnboardingLandingPage
