// we should use treeshaking for reduced build size maybe
import * as React from 'react'
import _, { omit } from 'lodash'
import {
  Button,
  Container,
  Flex,
  VStack,
  Heading,
  useDisclosure,
  HStack,
  Box
} from '@chakra-ui/react'
import { PageWrap } from '../../../layouts'
import { useToast } from '@chakra-ui/react'

import { SupportedDataFieldType, SupportedDataFieldKeysType, SuspendedTaskTypes } from '../types'

import {
  Enum_Datalist_Status,
  Enum_Tasktype_Status,
  GetSingleBaoleaderProjectQuery,
  useCreateTaskDefinitionMutation,
  useCreateTaskTypeMutation,
  useGetDataListsQuery,
  useGetTaskTypeQuery,
  DataListEntity,
  Enum_Taskdefinition_Status
} from 'generated/graphql'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../../constants'

import { BackButton, CustomTooltip, EmptyListHandler, H, SubNav, TableIcon } from 'components'
import { images } from 'theme'
import { VscAdd, VscSave } from 'react-icons/vsc'

import { useHistory, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { useBrowserStorage } from 'hooks'
import DynamicInputField from '../components/DynamicInputField'
import MobileViewRenderer from '../components/Mobile/MobileViewRenderer'
import OptionsDrawer from '../components/OptionsDrawer'
import QuestionModal from '../components/QuestionModal'
import TaskTypeMetadata from '../components/TaskTypeMetadata'
import { BiTask } from 'react-icons/bi'
import { cloneDeep } from 'lodash'
import ReactGA from 'react-ga4'
import DataList from '../components/DataList'
import { OutlineButton, PrimaryButton } from 'components/UI/Buttons'
import { OptionType } from 'types'
import { SupportedDataFields, SUSPENDED_TASK_TYPES } from '../constants'
import { useTaskTypes } from '../data'
import {
  addFieldButtonHelperTooltip,
  createTaskTitleHelperPopover,
  saveAndPublishButtonHelperTooltip,
  saveAsDraftButtonHelperTooltip
} from './help'

const TaskBuilder: React.FC = () => {
  const toast = useToast()
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const [copiedTaskTypeId, setCopiedTaskTypeId] = React.useState<string | null>(
    queryParams.get('taskType')
  )
  const [inputFields, setInputFields] = React.useState<Array<SupportedDataFieldType>>([])
  // @ts-ignore
  const { data } = useGetTaskTypeQuery({
    variables: {
      id: copiedTaskTypeId ? copiedTaskTypeId : ''
    },
    skip: _.isEmpty(copiedTaskTypeId),
    onError: (error) => {
      toast({ title: `There was an error ${error.message}.`, ...ERROR_TOAST })
      history.goBack()
    }
  })

  // if we have a param in the queries, log it and use it to fetch the taskType as a duplicate and load that into the form
  React.useEffect(() => {
    setCopiedTaskTypeId(queryParams.get('taskType'))
  }, [location.search])
  const taskTypeData = useTaskTypes(data)
  // use this to create a new form
  const populateFormContent = React.useCallback(() => {
    if (data?.getTaskType) {
      setInputFields(taskTypeData)
    }
  }, [data])

  React.useEffect(() => {
    populateFormContent()
  }, [data])

  const project = (history.location.state as any)?.project as GetSingleBaoleaderProjectQuery
  const {
    isOpen: isDataListOpen,
    onOpen: onDataListOpen,
    onClose: onDataListClose
  } = useDisclosure()
  const [openQuestionModal, setOpenQuestionModal] = React.useState(false)
  const { isOpen: isDrawerOpen, onClose: onDrawerClose, onOpen: _onDrawerOpen } = useDisclosure()
  const [createTask] = useCreateTaskDefinitionMutation({
    onCompleted: async () => {
      toast({ title: 'Task created.', ...SUCCESS_TOAST })
      history.push(`/auth/project/${project?.getSingleBaoleaderProject?.id}`, {
        refetch: true
      })
    },
    onError: (err) => {
      toast({
        title: `Unable to create task -> ${err.message}`,
        ...ERROR_TOAST
      })
    }
  })
  const [createTaskType, { loading: creatingTask }] = useCreateTaskTypeMutation({
    onCompleted: async ({ createBaoleaderTaskType }) => {
      toast({ title: 'Task created', ...SUCCESS_TOAST })
      if (project) {
        await createTask({
          variables: {
            input: {
              task_type: createBaoleaderTaskType?.id,
              category: createBaoleaderTaskType?.attributes?.category?.data?.id,
              project: project.getSingleBaoleaderProject?.id,
              status:
                createBaoleaderTaskType.attributes?.status === Enum_Tasktype_Status.Active
                  ? Enum_Taskdefinition_Status.Active
                  : Enum_Taskdefinition_Status.Deactivated
            }
          }
        })
        return
      }
      ReactGA.event({
        category: 'Task Builder',
        action: 'Create a Task'
      })
      history.push(`/auth/task-types-list/detail/${createBaoleaderTaskType?.id}`)
    },
    onError: (err) => {
      toast({
        title: `Unable to create task -> ${err.message}`,
        ...ERROR_TOAST
      })
    }
  })
  const { data: activeDataLists, refetch: refetchDataLists } = useGetDataListsQuery({
    variables: {
      filters: {
        status: Enum_Datalist_Status.Active
      },
      sort: 'name:asc'
    }
  })
  const [taskTypeMetadate, setTaskTypeMetadata] = React.useState<OptionType>({} as OptionType)

  React.useEffect(() => {
    if (data?.getTaskType) {
      setTaskTypeMetadata({
        title: `${data?.getTaskType?.attributes?.title} Duplicate` || '',
        category: data?.getTaskType?.attributes?.category?.data?.id || '',
        photoRequired: data?.getTaskType?.attributes?.photoRequired || false,
        withComment: data?.getTaskType?.attributes?.withComment || false,
        un_sdg: data?.getTaskType?.attributes?.un_sdg?.data?.id || ''
      })
    }
  }, [data?.getTaskType])
  const [suspendedTaskTypes, setSuspendedTaskTypes, _removeSuspendedTaskTypes] = useBrowserStorage<
    SuspendedTaskTypes[]
  >(SUSPENDED_TASK_TYPES, 'local')

  const [errors, setErrors] = React.useState<OptionType>()

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
    propertyIndex: number
  ) => {
    const values = cloneDeep(inputFields)
    values[index].properties[propertyIndex] = { [event.target.name]: event.target.value }
    if (event.target.value === '' && event.target.required) {
      setErrors((prev) => ({
        ...prev,
        [event.target.name]: `${event.target.name} is required`
      }))
    } else {
      setErrors((prev) => omit(prev, [event.target.name]))
    }
    setInputFields(values)
  }

  const isFormValid = React.useMemo(() => {
    if (taskTypeMetadate) {
      if (errors?.metric) {
        return false
      }
      const { category, title } = taskTypeMetadate
      return !!(category && title)
    }
    return false
  }, [taskTypeMetadate, errors])

  const handleSubmit = async () => {
    if (!isFormValid) return
    try {
      const dataFieldsArr = inputFields.map(({ properties, __component }) => ({
        properties,
        __component
      }))

      await createTaskType({
        variables: {
          input: {
            ...taskTypeMetadate,
            un_sdg: (taskTypeMetadate.un_sdg as string) || null,
            status: Enum_Tasktype_Status.Active
          },
          dataFieldsArr
        }
      })
    } catch (error) {
      console.error({ error })
    }
  }

  const handleAddField = (question: string, fieldKey: SupportedDataFieldKeysType) => {
    const newField = { ...SupportedDataFields[fieldKey] }
    const properties = newField.properties.map((property) => {
      if (Object.keys(property)[0] === 'label') {
        return { label: question }
      }
      if (Object.keys(property)[0] === 'metric') {
        setErrors((prev) => ({ ...prev, metric: 'metric is required' }))
      }
      return property
    })
    setInputFields([...inputFields, { ...newField, properties }])
  }

  const handleAddOption = (fieldIndex: number, propertyIndex: number) => {
    const values = [...inputFields]
    const itemToChange = _.cloneDeep(values[fieldIndex])
    const properties = [...itemToChange['properties']]
    const propertyOptions = [
      ...(properties[propertyIndex]['option'] as OptionType[]),
      {
        option: '',
        __component: 'combo-field.option'
      }
    ]
    properties[propertyIndex]['option'] = propertyOptions

    values[fieldIndex] = itemToChange
    setInputFields(values)
  }

  const handleOptionLabelChange = (
    event: any,
    fieldIndex: number,
    propertyIndex: number,
    optionIndex: number
  ) => {
    const values = [...inputFields]
    // @ts-ignore
    values[fieldIndex]['properties'][propertyIndex]['option'][optionIndex] = {
      option: event.target.value
    }
    setInputFields(values)
  }

  const handleDeleteOption = (fieldIndex: number, propertyIndex: number, optionIndex: number) => {
    const values = [...inputFields]
    // @ts-ignore
    values[fieldIndex]['properties'][propertyIndex]['option'].splice(optionIndex, 1)
    setInputFields(values)
  }

  const onDataListModalClose = React.useCallback(async () => {
    await refetchDataLists()
    onDataListClose()
  }, [refetchDataLists, onDataListClose])

  const handleRemoveField = (index: number) => {
    const values = [...inputFields]
    values.splice(index, 1)
    setInputFields(values)
  }
  const saveAsDraft = async () => {
    if (!isFormValid) return
    try {
      const dataFieldsArr = inputFields.map(({ properties, __component }) => ({
        properties,
        __component
      }))

      const { data } = await createTaskType({
        variables: {
          input: {
            ...taskTypeMetadate,
            un_sdg: (taskTypeMetadate.un_sdg as string) || null,
            status: Enum_Tasktype_Status.Deactivated
          },
          dataFieldsArr
        }
      })
      const draft = {
        fields: JSON.stringify(inputFields),
        taskTypeMeta: taskTypeMetadate,
        seletedTaskType: data?.createBaoleaderTaskType?.id || ''
      }
      setSuspendedTaskTypes([...(suspendedTaskTypes as SuspendedTaskTypes[]), draft])
    } catch (error) {
      console.error({ error })
    }
  }
  const options = () => {
    return (
      <HStack>
        {inputFields?.length > 0 && isFormValid && (
          <>
            <CustomTooltip label={saveAsDraftButtonHelperTooltip}>
              <Box>
                <OutlineButton
                  onClick={saveAsDraft}
                  isDisabled={!isFormValid}
                  isLoading={creatingTask}
                >
                  Save as Draft
                </OutlineButton>
              </Box>
            </CustomTooltip>
            <CustomTooltip label={saveAndPublishButtonHelperTooltip}>
              <Box>
                <PrimaryButton
                  onClick={handleSubmit}
                  isDisabled={!isFormValid}
                  leftIcon={<VscSave />}
                  isLoading={creatingTask}
                >
                  Save
                </PrimaryButton>
              </Box>
            </CustomTooltip>
          </>
        )}
      </HStack>
    )
  }

  return (
    <>
      <SubNav
        leftElement={options()}
        rightElement={
          <Heading fontSize="md" as="h3" color="gray.600">
            <H noH withBar />
            Create Task {project && `for ${project?.getSingleBaoleaderProject?.attributes?.name}`}
          </Heading>
        }
        image={images.logoNoText}
      />
      <DataList {...{ isOpen: isDataListOpen, onClose: onDataListModalClose }} />

      <PageWrap title="Tasks Builder">
        <Container my={8} maxW="1600" px={16}>
          <Flex>
            <BackButton
              title={`Back to ${
                project ? project?.getSingleBaoleaderProject?.attributes?.name : 'the list of tasks'
              }`}
              onClick={() =>
                history.push(
                  project
                    ? `/auth/project/${project?.getSingleBaoleaderProject?.id}`
                    : '/auth/task-types-list'
                )
              }
            />
          </Flex>
        </Container>
        <Flex
          pb={24}
          bg="background.100"
          borderTop="1px solid"
          borderColor="gray.200"
          flex={1}
          direction="column"
          w="100%"
        >
          <Container maxW="1600" px={16} pt={16} position="relative">
            <TableIcon position="right" icon={<BiTask />} />
            <Flex justify="space-between" mr="350px" align="center">
              <Flex align="center">
                <Heading as="h2" size="xl" color="gray.600">
                  Create Task{' '}
                  {project && `for ${project?.getSingleBaoleaderProject?.attributes?.name}`}
                </Heading>
                {createTaskTitleHelperPopover}
              </Flex>
              {options()}
            </Flex>

            <TaskTypeMetadata
              setTaskTypeMetadata={setTaskTypeMetadata}
              errors={errors}
              setErrors={setErrors}
              mr="350px"
              taskTypeMetadata={taskTypeMetadate}
            />

            <Heading size="md" as="h3">
              Data Fields
            </Heading>
            <Flex>
              <Flex flex={1} alignItems="center" flexDirection="column">
                {inputFields?.length === 0 && <EmptyListHandler />}
                <AnimatePresence>
                  {inputFields.map((inputField, inputIndex) => (
                    <DynamicInputField
                      key={`field-${inputIndex}`}
                      errors={errors}
                      inputField={inputField}
                      activeDataLists={activeDataLists?.getDataLists as DataListEntity[]}
                      collectionLength={inputFields?.length}
                      inputIndex={inputIndex}
                      onDataListOpen={onDataListOpen}
                      setInputFields={setInputFields}
                      handleRemoveField={handleRemoveField}
                      handleDeleteOption={handleDeleteOption}
                      handleOptionLabelChange={handleOptionLabelChange}
                      handleAddOption={handleAddOption}
                      handleInputChange={handleInputChange}
                    />
                  ))}
                </AnimatePresence>

                <VStack py={8} spacing={16} width="100%">
                  <CustomTooltip label={addFieldButtonHelperTooltip}>
                    <Button
                      colorScheme="green"
                      variant="ghost"
                      onClick={() => setOpenQuestionModal(true)}
                      fontSize="sm"
                      leftIcon={<VscAdd />}
                    >
                      Add Field
                    </Button>
                  </CustomTooltip>
                </VStack>
              </Flex>

              <Flex w={350}>
                <MobileViewRenderer taskTypeMetadate={taskTypeMetadate} fields={inputFields} />
              </Flex>
            </Flex>
          </Container>
        </Flex>
        <QuestionModal
          isOpen={openQuestionModal}
          isLoading={false}
          onClose={() => setOpenQuestionModal(false)}
          onConfirm={(question, fieldType) => handleAddField(question, fieldType)}
        />
        <OptionsDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} />
      </PageWrap>
    </>
  )
}

export default TaskBuilder
