import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Text,
  Thead,
  Tr,
  useRadioGroup,
  useToast,
  Input,
  CircularProgress,
  CircularProgressLabel,
  Flex
} from '@chakra-ui/react'

import React from 'react'

import {
  DataListEntity,
  Enum_Datalistoption_Status,
  useCreateDataListOptionMutation
} from '../../../../../../../generated/graphql'

import { read, utils } from 'xlsx'
import { VscCloudDownload, VscCloudUpload } from 'react-icons/vsc'
import { STAGE } from '../../..'
import { RadioCard } from 'components'

import { SUCCESS_TOAST } from '../../../../../../../constants'
import { pluralHandler } from '../../../../../../../utils/index'
import { PrimaryButton } from 'components/UI/Buttons'

type CSVImporterProps = {
  selectedItem: DataListEntity | null
  callBack?: () => void
  stage: STAGE
}

const CSVImporter: React.FC<CSVImporterProps> = ({ selectedItem, callBack, stage }) => {
  const toast = useToast()
  const duplicates = React.useRef(0)
  const success = React.useRef(0)
  const [createDataListOption] = useCreateDataListOptionMutation({
    onCompleted: async () => {
      success.current += 1
    },
    onError: (e) => {
      console.log('------>>', { e })
      duplicates.current += 1
    }
  })
  const [data, setData] = React.useState<any[]>([])

  const [selectedRow, setSelectedRow] = React.useState<{ key: string; index: number }>({
    key: '',
    index: -1
  })
  const handleImport = ($event: { target: { files: any } }) => {
    const files = $event.target.files
    if (files.length) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        const wb = read(event?.target?.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])

          setData(rows)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }
  const [progress, setProgress] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const uploadData = async () => {
    try {
      setLoading(true)

      for (const row of data) {
        await createDataListOption({
          variables: {
            input: {
              value: `${row?.[selectedRow?.key]}` || '',
              data_list: selectedItem?.id || '',
              status: Enum_Datalistoption_Status.Active
            }
          }
        })
        setProgress((prev) => prev + 1)
      }
      setProgress(0)

      callBack?.()

      toast({
        ...SUCCESS_TOAST,
        title: `${success.current} Data list created and ${duplicates.current} duplicates`
      })

      success.current = 0
      duplicates.current = 0
    } catch (error) {
      console.log({ error })
    }
    setLoading(false)
  }
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    onChange: console.log
  })
  const pourcentage = Math.floor((progress * 100) / data?.length || 0)

  const group = getRootProps()
  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <>
      <PrimaryButton
        leftIcon={<VscCloudDownload />}
        onClick={() => {
          inputRef.current?.click()
        }}
        my={4}
      >
        Import CSV or Excel
      </PrimaryButton>
      <Input
        type="file"
        name="file"
        hidden
        ref={inputRef}
        className="custom-file-input"
        id="inputGroupFile"
        required
        onChange={handleImport}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      {(stage === STAGE.details || stage === STAGE.update) && data.length ? (
        <Box w="100%">
          {data.length ? (
            <Text mt={4} color="grey.400" fontSize="xs">
              {data?.length} {pluralHandler('item', data?.length)}.
            </Text>
          ) : null}
          {data.length && !selectedRow?.key ? (
            <Text mt={4} color="yellow.500" fontSize="xs">
              Select a column to continue.
            </Text>
          ) : null}
          {loading && (
            <Flex py={4} align="flex-end">
              <CircularProgress value={pourcentage} size={16} color="green.400">
                <CircularProgressLabel fontSize="xs">{pourcentage}%</CircularProgressLabel>
              </CircularProgress>
              <Text fontSize="x-small" color="gray.500">
                {progress} / {data.length}
              </Text>
            </Flex>
          )}

          <TableContainer my={4}>
            <Table size="sm">
              <Thead>
                <Tr {...group}>
                  {Object.keys(data?.[0] || {})?.map((value, index) => {
                    const radio = getRadioProps({ value })
                    return (
                      <Th
                        borderColor={selectedRow?.index === index ? 'green.400' : 'transparent'}
                        bg={selectedRow?.index === index ? 'green.200' : 'transparent'}
                        fontSize="x-small"
                        p={0}
                        key={index}
                      >
                        <RadioCard
                          {...radio}
                          onChange={(e) => {
                            setSelectedRow({ key: value, index })
                            radio?.onChange?.(e)
                          }}
                        >
                          {value}
                        </RadioCard>
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {data?.slice(0, 6)?.map((item, index) => (
                  <Tr key={index}>
                    {Object.values(item).map((val: any, index) => (
                      <Td
                        borderColor={selectedRow?.index === index ? 'green.300' : 'transparent'}
                        bg={selectedRow?.index === index ? 'green.100' : 'transparent'}
                        fontSize="xs"
                        key={index}
                      >
                        <Text noOfLines={1}>{val}</Text>
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          {data.length && selectedRow?.key ? (
            <PrimaryButton
              w="100%"
              mb={8}
              isDisabled={loading}
              isLoading={loading}
              onClick={uploadData}
              leftIcon={<VscCloudUpload />}
            >
              Import
            </PrimaryButton>
          ) : null}
        </Box>
      ) : null}
    </>
  )
}

export default CSVImporter
