import { Alert, AlertDescription, AlertIcon, AlertProps, Skeleton } from '@chakra-ui/react'
import React from 'react'

type AlertIndicatorProps = AlertProps & {
  isLoading?: boolean
}

const AlertIndicator: React.FC<AlertIndicatorProps> = ({
  isLoading,
  status,
  children,
  ...rest
}) => {
  switch (status) {
    case 'success':
      return (
        <Skeleton isLoaded={!isLoading}>
          <Alert status="success" {...rest}>
            <AlertIcon />
            <AlertDescription>{children}</AlertDescription>
          </Alert>
        </Skeleton>
      )
    case 'error':
      return (
        <Skeleton isLoaded={!isLoading}>
          <Alert status="error" {...rest}>
            <AlertIcon />
            <AlertDescription>{children}</AlertDescription>
          </Alert>
        </Skeleton>
      )
    case 'info':
      return (
        <Skeleton isLoaded={!isLoading}>
          <Alert status="info" {...rest}>
            <AlertIcon />
            <AlertDescription>{children}</AlertDescription>
          </Alert>
        </Skeleton>
      )
    case 'warning':
      return (
        <Skeleton isLoaded={!isLoading}>
          <Alert status="warning" {...rest}>
            <AlertIcon />
            <AlertDescription>{children}</AlertDescription>
          </Alert>
        </Skeleton>
      )
    default:
      return <></>
  }
}

AlertIndicator.defaultProps = {
  fontSize: 'sm'
}

export default AlertIndicator
