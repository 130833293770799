import { FlexProps } from '@chakra-ui/react'
import { MotionProps, Variants } from 'framer-motion'
import React from 'react'
import MotionFlex from '../../utils/MotionFlex'

export type CardProps = FlexProps & MotionProps

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ children, ...rest }, ref) => {
  const variants: Variants = {
    show: {
      y: 0,
      opacity: 1
    },
    hide: {
      y: 50,
      opacity: 0
    }
  }

  return (
    <MotionFlex animate="show" ref={ref} initial="hide" variants={variants} {...rest}>
      {children}
    </MotionFlex>
  )
})

Card.defaultProps = {
  bg: 'white',
  width: 'auto',
  rounded: 'lg',
  border: 'none',
  borderColor: 'gray.200',
  onClick: () => false,
  flexDirection: 'column'
}

export default Card
