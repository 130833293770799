import { Image, Text, Avatar, useToast, AvatarGroup, VStack } from '@chakra-ui/react'

import { Form, Formik, FormikProps, FormikState } from 'formik'
import moment from 'moment'
import * as React from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { Cell, Row } from 'react-table'

import Table from '../../UI/Table'
import {
  TaskEntity,
  useGetTaskFilterCommunitiesLazyQuery,
  useGetTaskFilterDataCollectorsLazyQuery,
  useGetTaskFilterProjectsLazyQuery,
  useGetTaskFilterTaskTypesLazyQuery,
  useGetTaskFilterUnsdgsLazyQuery
} from '../../../generated/graphql'
//@ts-ignore
import { ExcelDateState } from '../../../types'

import { DATA_COLLECTOR, ERROR_TOAST, taskFilters } from '../../../constants'

import { DateAndTime, EmptyListHandler, H, Pagination, StatusBadge } from '../..'
import { Center } from '@chakra-ui/react'
import { ApolloError } from 'apollo-boost'

import { omit } from 'lodash'

import { CustomTooltip } from 'components'

import { getImageByFormat, getImages } from 'utils'
import TableToolbar from './components/TableToolbar'
import TableActions from './components/TableActions'
import { TaskFiltersType } from '../../../types/index'

import { tasksHelpPopoverContent } from './help'

type InitialValues = {
  id: string
  name: string
  baorider: string
  community: string
  project: string
  task_definition: string
  date_range: string[]
  status: string
}

const TasksTable: React.FC<{
  data: TaskEntity[]
  visibleFilters?: TaskFiltersType[]
  limit?: number
  start?: number
  currentPage?: number
  loading: boolean
  count: number
  onSearch?: (searchValue: string) => void
  searchValue?: string
  onFilter: (filterValues: { [key: string]: number | string | string[] }) => void
  onReset: () => void
  tasksHelpPopover?: React.ReactNode
  setLimit: React.Dispatch<React.SetStateAction<number>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setStart: React.Dispatch<React.SetStateAction<number>>
  titleActions?: (() => React.ReactNode) | undefined
}> = ({
  onFilter,
  onReset,
  data,
  loading,
  limit,
  currentPage,
  start,
  setLimit,
  setPage,
  onSearch,
  searchValue,
  setStart,
  visibleFilters,
  titleActions,
  tasksHelpPopover,
  count
}) => {
  const history = useHistory()
  const location = useLocation()

  const historyState = (history.location.state as any)?.filter as InitialValues

  const [showFilters, setShowFilters] = React.useState(() => (historyState ? true : false))
  const [filtersCount, setFiltersCount] = React.useState(0)

  const [filters, setFilters] = React.useState<any>({})

  const toast = useToast()
  const onError = (error: ApolloError) =>
    toast({
      title: `There was an error: ${error.message}`,
      ...ERROR_TOAST
    })

  const [getProjects, { data: leaderProjects, loading: projectsLoading }] =
    useGetTaskFilterProjectsLazyQuery({
      variables: {
        filters: {
          ...omit(filters, 'project')
        }
      },
      onError,
      fetchPolicy: 'cache-first'
    })

  const [getTaskTypes, { data: storedTaskTypes, loading: taskTypesLoading }] =
    useGetTaskFilterTaskTypesLazyQuery({
      fetchPolicy: 'cache-first',
      variables: {
        filters: {
          ...omit(filters, '$or')
        },
        sort: 'title:asc'
      }
    })

  const [getVerifiers, { data: verifiers, loading: verifiersLoading }] =
    useGetTaskFilterDataCollectorsLazyQuery({
      variables: { filters: { ...omit(filters, 'baorider') } },
      onError,
      fetchPolicy: 'cache-first'
    })

  const [getCommunities, { data: storedCommunities, loading: communitiesLoading }] =
    useGetTaskFilterCommunitiesLazyQuery({
      fetchPolicy: 'cache-first',
      variables: {
        filters: {
          ...omit(filters, 'community')
        }
      }
    })

  const [getUnsdgs, { data: storedUnsdgs, loading: unsdgsLoading }] =
    useGetTaskFilterUnsdgsLazyQuery({
      fetchPolicy: 'cache-first',
      variables: {
        filters: {
          ...omit(filters, '$or')
        },
        sort: 'name:asc'
      }
    })

  React.useEffect(() => {
    if (showFilters === true) {
      getProjects()
      getTaskTypes()
      getVerifiers()
      getCommunities()
      getUnsdgs()
    }
  }, [showFilters])

  const onToggleShowFilters = () => setShowFilters((prev) => !prev)

  const filter = React.useCallback(
    async (values: { [key: string]: string | string[] }) => {
      const filterValues: { [key: string]: string | string[] } = {}
      for (const key in values) {
        if (values[key] !== '' && values[key] !== null) {
          filterValues[key] = values[key]
        }
      }

      setFiltersCount(() => {
        if (filterValues?.date_range?.length > 0) return Object.keys(filterValues).length
        return Object.keys(omit(filterValues, 'date_range')).length
      })

      const where = {
        ...omit(filterValues, 'task_definition', 'date_range', 'baorider'),
        ...(filterValues?.date_range && {
          $or: [
            {
              $and: [
                { createdAt: { $gte: (filterValues?.date_range as any)[0] || undefined } },
                { createdAt: { $lte: (filterValues?.date_range as any)[1] || undefined } }
              ]
            },
            {
              $and: [
                { date_reported: { $gte: (filterValues?.date_range as any)[0] || undefined } },
                { date_reported: { $lte: (filterValues?.date_range as any)[1] || undefined } }
              ]
            }
          ]
        }),
        ...(filterValues?.task_definition && {
          $or: [
            {
              task_definition: {
                task_type: { id: (filterValues.task_definition as any)?.task_type || undefined }
              }
            },
            {
              task_definition: {
                task_type: {
                  un_sdg: {
                    id: (filterValues.task_definition as any)?.un_sdg || undefined
                  }
                }
              }
            }
          ]
        }),
        project: filterValues?.project || undefined,
        baorider: filterValues?.baorider || undefined,
        id: { $contains: searchValue || undefined }
      }
      console.log(where)
      onFilter(where as any)
      setFilters(where)
    },
    [onFilter, searchValue]
  )
  React.useEffect(() => {
    filter(filters)
  }, [searchValue])
  const resetFormRef =
    React.useRef<(nextState?: Partial<FormikState<InitialValues>> | undefined) => void>()
  const reset = async () => {
    onReset()
    setFiltersCount(0)
    onFilter({})
    filter({})
    resetFormRef.current?.({
      values: {
        id: '',
        name: '',
        baorider: '',
        community: '',
        project: '',
        task_definition: '',
        date_range: [],
        status: ''
      }
    })
    history.replace({ pathname: location.pathname, state: {} })
  }

  const help = tasksHelpPopover || tasksHelpPopoverContent

  const columns = [
    {
      Header: ' ',
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        return (
          <Image
            src={getImageByFormat(
              original?.attributes?.task_definition?.data?.attributes?.category?.data?.attributes
                ?.featureImage?.data,
              'thumbnail'
            )}
            boxSize={8}
            rounded="lg"
            objectFit="cover"
            mr={3}
          />
        )
      }
    },
    {
      Header: 'Date Reported',
      accessor: (original: TaskEntity) => {
        return moment(original?.attributes?.createdAt).toDate()
      },
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        const dateReported = original.attributes?.date_reported

        return (
          <DateAndTime
            dateAndTime={dateReported ? dateReported : original?.attributes?.createdAt}
            isOnline={!!!dateReported}
          />
        )
      }
    },
    {
      Header: 'Task Number',
      accessor: 'id',
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        return (
          <Center fontSize="xs" color="gray.500" fontWeight={500}>
            <H />
            {original.id}
          </Center>
        )
      }
    },
    {
      Header: 'Task',
      accessor: 'attributes.task_definition.data.attributes.task_type.data.attributes.title'
    },
    {
      Header: DATA_COLLECTOR,
      accessor: 'baorider',
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        const baorider =
          original?.attributes?.evidences?.data?.[0]?.attributes?.baorider?.data?.attributes
        return (
          <Center>
            <Avatar
              mr={2}
              name={`${baorider?.firstName} ${baorider?.lastName}`}
              size="xs"
              src={baorider?.profilePicture?.data?.attributes?.formats?.thumbnail?.url}
            />
            <VStack spacing={0} alignItems="flex-start">
              <Text fontWeight={500} fontSize="xs" color="gray.700">
                {baorider ? `${baorider?.firstName} ${baorider?.lastName}` : 'No ' + DATA_COLLECTOR}
              </Text>
              {baorider?.phoneNumber && (
                <Text color="gray.500" fontSize="x-small">
                  {baorider?.phoneNumber}
                </Text>
              )}
            </VStack>
          </Center>
        )
      }
    },
    {
      Header: 'Photos',
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        const { hasImages, images } = getImages(original)
        return (
          <>
            {hasImages ? (
              <AvatarGroup size="sm" max={2}>
                {images?.map((photo) => (
                  <Avatar
                    key={photo?.id}
                    _hover={{
                      transform: 'scale(1.5)',
                      zIndex: 23,
                      border: 'none'
                    }}
                    src={getImageByFormat(photo, 'thumbnail')}
                    name={photo?.attributes?.name}
                  />
                ))}
              </AvatarGroup>
            ) : (
              <Text fontSize="x-small" color="gray.500">
                No photos
              </Text>
            )}
          </>
        )
      }
    },
    {
      Header: 'Project',
      accessor: 'attributes.project.data.attributes.name',
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        return (
          <CustomTooltip label={original?.attributes?.project?.data?.attributes?.name || ''}>
            <Text fontSize="x-small" color="gray.500" isTruncated>
              {original?.attributes?.project?.data?.attributes?.name}
            </Text>
          </CustomTooltip>
        )
      }
    },
    {
      Header: 'Group',
      accessor: 'attributes.community.data.attributes.name',
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        return (
          <CustomTooltip label={original?.attributes?.community?.data?.attributes?.name || ''}>
            <Text fontSize="x-small" color="gray.500" isTruncated>
              {original?.attributes?.community?.data?.attributes?.name}
            </Text>
          </CustomTooltip>
        )
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row: { original } }: Cell<TaskEntity>) => {
        return <StatusBadge status={original.attributes?.status} withIcon />
      }
    }
  ]
  React.useEffect(() => {
    filter(historyState)
  }, [historyState])
  const pagination = { currentPage, limit, start }
  return (
    <>
      <Formik
        initialValues={{
          id: historyState?.id || '',
          name: historyState?.name || '',
          community: historyState?.community || '',
          project: historyState?.project || '',
          baorider: historyState?.baorider || '',
          task_definition: historyState?.task_definition || '',
          date_range: historyState?.date_range || [],
          status: historyState?.status || ''
        }}
        onSubmit={filter}
      >
        {({ values, resetForm }: FormikProps<InitialValues>) => {
          resetFormRef.current = resetForm
          const { project, task_definition, baorider, community, status, date_range } = values
          const hasValues = !!(
            project ||
            task_definition ||
            baorider ||
            community ||
            status ||
            date_range?.length
          )

          return (
            <Form>
              <Table
                isLoading={loading}
                columns={columns as any}
                data={data?.map((task) => {
                  return {
                    ...task,
                    baorider: `${task?.attributes?.baorider?.data?.attributes?.firstName || ''} ${
                      task?.attributes?.baorider?.data?.attributes?.lastName || ''
                    }`,
                    community: task?.attributes?.community?.data?.attributes?.name
                  }
                })}
                tableHeading="Task Feed"
                emptyListComponent={
                  <EmptyListHandler
                    title="No tasks found"
                    subTitle="There are no tasks available at the moment. Please check back later."
                  />
                }
                titleActions={titleActions}
                help={help}
                tableActions={() => [
                  <TableActions
                    key={Math.random()}
                    {...{
                      onSearch,
                      searchValue,
                      count,
                      filtersCount,
                      reset,
                      filters,
                      onToggleShowFilters,
                      showFilters,
                      filtersTitle: 'Task Filters'
                    }}
                  />
                ]}
                tableToolbar={() => [
                  <TableToolbar
                    key={Math.random()}
                    {...{
                      showFilters,
                      projectsLoading,
                      taskTypesLoading,
                      leaderProjects,
                      communitiesLoading,
                      unsdgsLoading,
                      verifiersLoading,
                      visibleFilters,
                      storedTaskTypes,
                      storedCommunities,
                      storedUnsdgs,
                      verifiers
                    }}
                  />
                ]}
                onRowClick={(row: Row<TaskEntity>) => {
                  history.push(
                    `/auth/tasks/${row.original.id}`,
                    hasValues
                      ? {
                          filter: values,
                          ...pagination
                        }
                      : pagination
                  )
                }}
                pageSize={10}
                onClickNext={async () => null}
              />

              <Pagination
                setLimit={setLimit}
                start={start}
                setPage={setPage}
                setStart={setStart}
                count={count}
                limit={limit}
              />
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

TasksTable.defaultProps = {
  visibleFilters: taskFilters
}

export default TasksTable
