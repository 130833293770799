import { Image, ImageProps } from '@chakra-ui/react'
import React from 'react'
import { images } from 'theme'
import { MotionFlex } from 'components'

type BaotreeLogoProps = ImageProps

const BaotreeLogo: React.FC<BaotreeLogoProps> = ({ ...rest }) => {
  return (
    <MotionFlex initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Image src={images.logo} {...rest} />
    </MotionFlex>
  )
}
BaotreeLogo.defaultProps = {
  w: 150,
  position: 'fixed',
  bottom: 10,
  right: 10
}

export default BaotreeLogo
