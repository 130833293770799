import { Skeleton, Text, VStack } from '@chakra-ui/react'
import { Maybe } from 'generated/graphql'
import React from 'react'

type TitleValuePairProps = {
  title: string
  value?: number | Maybe<string>
  loading?: boolean
}

const TitleValuePair: React.FC<TitleValuePairProps> = ({ title, value, loading }) => {
  return (
    <VStack alignItems="flex-start" spacing={1}>
      <Skeleton isLoaded={!loading}>
        <Text fontSize="x-small" textTransform="uppercase" color="gray.400" fontWeight="semibold">
          {title}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!loading}>
        <Text fontSize="xs" fontWeight="bold">
          {value}
        </Text>
      </Skeleton>
    </VStack>
  )
}

export default TitleValuePair
