import { Box, Container, Flex, Heading, HStack, Icon, Skeleton, useToast } from '@chakra-ui/react'

import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FloatingCard, SubNav } from '../../../components'

import { DATA_COLLECTOR, ERROR_TOAST } from '../../../constants'
import {
  useCountRidersQuery,
  useCountTasksQuery,
  useGetCommunityProjectsCountQuery,
  useGetCommunityProjectsQuery,
  useGetSingleBaoleaderCommunityQuery
} from '../../../generated/graphql'
import { CustomTabContainer, PageWrap } from '../../../layouts'
import images from '../../../theme/images'

import BackButton from '../../../components/UI/Buttons/BackButton/index'

import { pluralHandler } from 'utils'
import CommunityDetails from './components/CommunityDetails'

import { FaUsers } from 'react-icons/fa'
import { PrimaryButton } from 'components/UI/Buttons'

import { Projects } from 'features'
import { FcAbout, FcParallelTasks, FcPhoneAndroid, FcTodoList } from 'react-icons/fc'

import TasksReported from './components/TasksReported'

import { VscArrowRight } from 'react-icons/vsc'
import Verifiers from './components/Verifiers'
import {
  groupDataCollectorsTooltipContent,
  groupDetailsTooltipContent,
  groupProjectsTooltipContent,
  groupTasksReportedTooltipContent
} from './help'

const Community = () => {
  const { id } = useParams<{ id: string }>()
  const toast = useToast()

  const history = useHistory()
  const onError = (error: any) => {
    toast({ title: error.message || 'There was an error.', ...ERROR_TOAST })
  }
  const { data: storedCommunity, loading: communityLoading } = useGetSingleBaoleaderCommunityQuery({
    variables: { id },
    onError
  })

  const { data: storedCountProjects, loading: storedCountProjectsLoading } =
    useGetCommunityProjectsCountQuery({
      variables: {
        community: id
      }
    })

  const { data: commnunityProjects, loading: projectsLoading } = useGetCommunityProjectsQuery({
    variables: {
      community: id
    },
    onError,
    fetchPolicy: 'cache-first'
  })

  const { data: tasksCount, loading: tasksCountLoading } = useCountTasksQuery({
    variables: {
      filters: {
        baorider: { community: { id } }
      }
    },
    onError
  })

  const { data: dataCollectorCount, loading: dataCollectorCountLoading } = useCountRidersQuery({
    variables: {
      where: {
        community: id
      }
    }
  })

  const infos = React.useMemo(
    () => [
      {
        title: 'Group Point of Contact',
        value: storedCommunity?.getSingleBaoleaderCommunity?.attributes?.chief_name,
        loading: communityLoading
      },
      {
        title: `Group ${DATA_COLLECTOR}s`,
        value: dataCollectorCount?.countRiders || 0,
        loading: dataCollectorCountLoading
      }
    ],
    [communityLoading, dataCollectorCountLoading]
  )
  const stats = React.useMemo(
    () => [
      {
        title: 'Number Projects',
        icon: FcParallelTasks,
        value: `${storedCountProjects?.getCommunityProjectsCount || 0} ${pluralHandler(
          'Project',
          storedCountProjects?.getCommunityProjectsCount || 0
        )}`,
        loading: storedCountProjectsLoading
      },

      {
        title: `Number of ${DATA_COLLECTOR}s`,
        icon: FcPhoneAndroid,
        value: `${dataCollectorCount?.countRiders || 0} ${pluralHandler(
          DATA_COLLECTOR,
          dataCollectorCount?.countRiders || 0
        )}`,
        loading: dataCollectorCountLoading
      },
      {
        title: 'Number of Tasks Reported',
        icon: FcTodoList,
        value: `${tasksCount?.countTasks || 0} ${pluralHandler(
          'Task',
          tasksCount?.countTasks || 0
        )}`,
        loading: tasksCountLoading
      }
    ],
    [storedCountProjectsLoading, tasksCountLoading, dataCollectorCountLoading]
  )
  const tabs = React.useMemo(
    () => [
      {
        title: 'Details',
        icon: <FcAbout />,
        loading: communityLoading,
        bg: 'background.100',
        tooltip: { label: groupDetailsTooltipContent }
      },
      {
        title: 'Projects',
        icon: <FcParallelTasks />,
        loading: projectsLoading,
        bg: 'background.100',
        tooltip: { label: groupProjectsTooltipContent }
      },
      {
        title: 'Members',
        icon: <FaUsers />,
        loading: communityLoading,
        bg: 'background.50',
        tooltip: { label: groupDataCollectorsTooltipContent }
      },
      {
        title: 'Tasks Reported',
        icon: <FcTodoList />,
        loading: communityLoading,
        bg: 'background.100',
        tooltip: { label: groupTasksReportedTooltipContent }
      }
    ],
    [communityLoading, projectsLoading]
  )
  const tabPanels = React.useMemo(
    () => [
      {
        component: (
          <CommunityDetails
            communityLoading={communityLoading}
            infos={infos}
            storedCommunity={storedCommunity}
          />
        )
      },
      {
        component: (
          <Box py={5}>
            <Projects
              containerData={commnunityProjects?.getCommunityProjects || []}
              canCreate={false}
              canPaginate={false}
              canSearch={false}
              containerCount={storedCountProjects?.getCommunityProjectsCount || 0}
            />
          </Box>
        )
      },
      {
        component: <Verifiers />
      },
      {
        component: <TasksReported />
      }
    ],
    [communityLoading, projectsLoading, storedCountProjectsLoading]
  )
  const options = () => (
    <Link to={`/auth/edit-community/${storedCommunity?.getSingleBaoleaderCommunity?.id}`}>
      <Skeleton isLoaded={!communityLoading}>
        <PrimaryButton px={6}>Edit</PrimaryButton>
      </Skeleton>
    </Link>
  )
  if (!communityLoading && !storedCommunity?.getSingleBaoleaderCommunity?.attributes)
    history.push('/auth/communities')
  return (
    <>
      <SubNav
        leftElement={options()}
        image={
          storedCommunity?.getSingleBaoleaderCommunity?.attributes?.featured_image?.data?.attributes
            ?.formats?.thumbnail?.url
            ? storedCommunity?.getSingleBaoleaderCommunity?.attributes?.featured_image?.data
                ?.attributes?.formats?.thumbnail?.url
            : images.logoNoText
        }
        rightElement={
          <Skeleton width="100%" isLoaded={!communityLoading}>
            <Heading fontSize="sm" as="h3" flex={1} color="gray.600">
              <Skeleton isLoaded={!communityLoading} maxW="fit-content" minW={200}>
                {storedCommunity?.getSingleBaoleaderCommunity?.attributes?.name}
              </Skeleton>
            </Heading>
          </Skeleton>
        }
      />

      <PageWrap direction="column" title="Group Details" align="center" p={0} m={0}>
        <Container my={8} maxW="1600" px={16}>
          <Flex>
            <BackButton title=" Back to the list of Groups" />
          </Flex>
        </Container>
        <Box bg="background.100" borderRadius="md" minH="90vh" pb={24} width="100%">
          <Container maxW="1600" px={16} pt={10} position="relative">
            <HStack
              spacing={5}
              position="absolute"
              top={0}
              transform="translateY(-60%)"
              mr={14}
              right={0}
            >
              {stats?.map((stat, index) => (
                <FloatingCard
                  icon={<Icon as={stat.icon} fontSize={14} />}
                  key={stat.title + index}
                  title={stat.title}
                  value={stat.value}
                  loading={stat.loading}
                />
              ))}
            </HStack>
            <Flex py={10} width="100%" fontSize="sm" align="center" justify="space-between">
              <Skeleton isLoaded={!communityLoading} maxW="fit-content" minW={200}>
                <HStack spacing={4}>
                  <Heading as="h3" size="xl" color="gray.400">
                    Group
                  </Heading>

                  <VscArrowRight size={16} color="gray" />

                  <Heading as="h3" size="xl" flex={1} color="gray.600">
                    {storedCommunity?.getSingleBaoleaderCommunity?.attributes?.name}
                  </Heading>
                </HStack>
              </Skeleton>

              <Flex ml={8} justify="flex-end" align="center">
                {options()}
              </Flex>
            </Flex>
            <CustomTabContainer tabs={tabs} tabPanels={tabPanels} isLazy />
          </Container>
        </Box>
      </PageWrap>
    </>
  )
}

export default Community
