import { Box, InputGroup, Select, SelectProps } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { MdList } from 'react-icons/md'
import { Text } from '../../../../typography'
import { FieldContainer } from '../components'
import { LabelProps } from '../styles'
export type OptionType = {
  label: string
  value: any
}

export type ConnectedSelectProps = LabelProps &
  SelectProps & {
    label?: string
    showIsValidValue?: boolean
    name: string
    options: OptionType[]
    hint?: string
    leftIcon?: React.ElementType
    loading?: boolean
  }

const ConnectedSelect: React.FC<ConnectedSelectProps> = ({
  label,
  options,
  showIsValidValue,
  hint,
  leftIcon,
  loading,
  ...rest
}) => {
  const [{ onBlur: onFieldBlur, ...field }, meta] = useField(rest.name)
  const [isFocused, setIsFocused] = React.useState(false)
  const showError = meta.touched && meta.error
  const inputRef = React.useRef<HTMLSelectElement>(null)
  return (
    <Box mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb} width={rest.width}>
      <FieldContainer
        leftIcon={leftIcon ?? MdList}
        isFocused={isFocused}
        showError={showError}
        inputRef={inputRef}
        loading={loading}
        setIsFocused={setIsFocused}
        label={label}
        name={field.name}
      >
        <InputGroup>
          <Select
            focusBorderColor="accent.500"
            {...field}
            id={field.name}
            variant="unstyled"
            ref={inputRef}
            fontSize="xs"
            onFocus={() => {
              setIsFocused(true)
            }}
            {...rest}
            value={field.value ? field.value : ''}
            onChange={(e: any) => {
              field.onChange(e)
              rest.onChange && rest.onChange(e)
            }}
            onBlur={(e) => {
              setIsFocused(false)
              onFieldBlur(e)
            }}
          >
            {options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </InputGroup>
      </FieldContainer>
      {!showError && hint ? (
        <Text color="text.300" textAlign="right" fontSize="0.8rem">
          {hint}
        </Text>
      ) : null}
      {showError ? (
        <Text color="red.500" fontSize="xs" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Box>
  )
}

export default ConnectedSelect

ConnectedSelect.defaultProps = {
  mb: 2,
  options: []
}
