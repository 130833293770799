import { Flex, HStack, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { find } from 'lodash'
import { VscAdd } from 'react-icons/vsc'
import { PenTool } from 'react-feather'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'
import { Maybe, useGetCommunitiesQuery, UsersPermissionsUserEntity } from 'generated/graphql'
import { ERROR_TOAST, DATA_COLLECTOR } from '../../../../constants'
import { ModalWrap, FloatingButtonWrapper, AlertIndicator } from 'components'
import {
  ConnectedFormGroup,
  ConnectedSelect,
  ConnectedCheckbox
} from 'components/forms/FormElements'
import ConnectedPhoneInput from 'components/forms/FormElements/ConnectedPhoneInput'
import { OptionType } from 'components/forms/FormElements/ConnectedSelect'
import CardFooter from 'components/UI/Card/CardFooter'
import { addUserSchema } from 'utils/validationSchemas'
import { formatError } from 'utils'
import { IPField } from 'hooks/useIPLookup/types'
import { useIPLookup } from 'hooks'
import posthog from 'posthog-js'
import { countries } from 'lib'

const DataCollectorsModal: React.FC<{
  additionalCost: number
  teamMembersRemaining: number
  editableBaorider: Maybe<UsersPermissionsUserEntity>
  isEditMode: boolean
  image?: any
  isOpen: boolean
  isLoading: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: (
    baorider: Maybe<UsersPermissionsUserEntity> & { community: string },
    addAnother: boolean
  ) => Promise<Maybe<string> | undefined>
}> = ({
  additionalCost,
  teamMembersRemaining,
  isOpen,
  onClose,
  editableBaorider,
  isEditMode,
  image,
  onSubmit,
  isLoading
}) => {
  const toast = useToast()

  const location = useLocation<{ refetchCommunities?: boolean }>()

  const [resetNumber, setResetNumber] = React.useState(false)

  const onError = () => {
    toast({ title: 'There was an error.', ...ERROR_TOAST })
  }
  const { data: storedCommunities, refetch } = useGetCommunitiesQuery({
    onError
  })
  React.useEffect(() => {
    if (location?.state?.refetchCommunities) {
      refetch()
    }
  }, [location])

  const { country_name } = useIPLookup([IPField.country_name])

  const submitForm = async (
    { hasConsent, addAnother, ...values }: any,
    { setStatus, resetForm }: any
  ) => {
    posthog.capture('dashboard_data_collector_created')
    try {
      if (!hasConsent) {
        toast({ title: 'Consent from Baorider is required.', ...ERROR_TOAST })
        return
      }
      const user = await onSubmit(values, addAnother)
      if (user) {
        resetForm({
          values: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            community: null,
            hasConsent: false
          }
        })
        setResetNumber(!resetNumber)
        if (!addAnother) {
          onClose()
        }
      }
    } catch (error) {
      setStatus(formatError(error))
    }
  }

  return (
    <ModalWrap title={'Add ' + DATA_COLLECTOR} image={image} isOpen={isOpen} onClose={onClose}>
      {/* TODO - need to figure out a way to conditionally render in this component */}
      {teamMembersRemaining === 1 && (
        <AlertIndicator status="info">
          You can add {teamMembersRemaining} more {DATA_COLLECTOR.toLowerCase()}s on your current
          plan. Additional data collectors will cost £{additionalCost} each, per month.
        </AlertIndicator>
      )}
      {teamMembersRemaining < 0 && (
        <AlertIndicator status="info">
          Adding a new data collector will add an additional cost of £{additionalCost} to your
          current plan per month.
        </AlertIndicator>
      )}
      <Formik
        enableReinitialize={true}
        validationSchema={addUserSchema}
        initialValues={{
          ...editableBaorider,
          hasConsent: false,
          addAnother: false
        }}
        onSubmit={submitForm}
      >
        {({ handleSubmit, setFieldValue, isSubmitting, status }) => (
          <Form onSubmit={handleSubmit}>
            <FloatingButtonWrapper
              isLoading={isSubmitting}
              status={status}
              button={
                <Flex flex={1} justify="flex-end" align="center">
                  <SecondaryButton mr={2} onClick={onClose}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    width="100%"
                    leftIcon={isEditMode ? <PenTool size={16} /> : <VscAdd />}
                    isLoading={isLoading || isSubmitting}
                  >
                    {isEditMode ? 'Update' : 'Add'}
                  </PrimaryButton>
                </Flex>
              }
            >
              <Flex flexDir="column" p={4}>
                <HStack mb={2} spacing={2}>
                  <ConnectedFormGroup
                    name="firstName"
                    mb={0}
                    label="First name *"
                    w="100%"
                    placeholder="Enter first name"
                  />
                  <ConnectedFormGroup
                    w="100%"
                    mb={0}
                    name="lastName"
                    label="Last name *"
                    placeholder="Enter last name"
                  />
                </HStack>
                <ConnectedSelect
                  name="gender"
                  label="Gender"
                  placeholder="Select Gender"
                  showIsValidValue
                  options={
                    [
                      { label: 'Male', value: 'Male' },
                      { label: 'Female', value: 'Female' },
                      { label: 'Prefer not to say', value: 'Prefer not to say' }
                    ] as OptionType[]
                  }
                />
                <ConnectedPhoneInput
                  label="Phone Number *"
                  setFieldValue={setFieldValue}
                  name="phoneNumber"
                  country={find(countries, ['name', country_name || 'Kenya'])}
                  options={countries}
                  resetNumber={resetNumber}
                />
                <Flex align="center">
                  <ConnectedSelect
                    name="community"
                    label="Group"
                    width="100%"
                    placeholder="Select a Group"
                    options={
                      ((storedCommunities?.getBaoleaderCommunities || []).map((item) => {
                        return { label: item?.attributes?.name, value: item?.id }
                      }) as OptionType[]) || []
                    }
                  />
                  <Link
                    to={{
                      pathname: '/auth/create-community',
                      state: {
                        from: '/auth/baoriders'
                      }
                    }}
                  >
                    <SecondaryButton ml={5} px={5} mt={3} leftIcon={<VscAdd />} colorScheme="green">
                      Create Group
                    </SecondaryButton>
                  </Link>
                </Flex>
              </Flex>
              <CardFooter>
                <ConnectedCheckbox
                  name="hasConsent"
                  mb={3}
                  label="You have been given permission to upload these details."
                />

                <ConnectedCheckbox name="addAnother" label="Add Another" />
              </CardFooter>
            </FloatingButtonWrapper>
          </Form>
        )}
      </Formik>
    </ModalWrap>
  )
}

export default DataCollectorsModal
