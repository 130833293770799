import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'
import { Form, Formik } from 'formik'
import * as React from 'react'
import { Send } from 'react-feather'
import { FcDisclaimer } from 'react-icons/fc'
import { VscCheck } from 'react-icons/vsc'
import { ModalWrap } from '../../../components'
import { ConnectedTextArea } from '../../../components/forms/FormElements'

const ConfirmationModal: React.FC<{
  isOpen: boolean
  isLoading: boolean
  onClose(): void
  onConfirm: () => void
}> = ({ onConfirm, isOpen, onClose, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Approve Task</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="sm">Are you sure you want to approve this task?</ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            fontSize="sm"
            colorScheme="gray"
            onClick={onClose}
            isLoading={isLoading}
          >
            Cancel
          </Button>
          <Button
            colorScheme="green"
            leftIcon={<VscCheck />}
            fontSize="sm"
            ml={3}
            onClick={() => onConfirm()}
            isLoading={isLoading}
          >
            Approve
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const RejectModal: React.FC<{
  isOpen: boolean
  isLoading: boolean
  onClose(): void
  onConfirm: (reason: string) => Promise<void>
}> = ({ onConfirm, isOpen, onClose, isLoading }) => {
  const [showConfirm, setShowConfirm] = React.useState(false)
  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} size="xs" motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <Formik
          enableReinitialize={true}
          initialValues={{
            reason: ''
          }}
          onSubmit={async (values) => {
            if (showConfirm) {
              await onConfirm(values.reason)
              onClose()
              setShowConfirm(false)
            } else {
              setShowConfirm(true)
            }
          }}
        >
          {({ resetForm, values }) => (
            <Form style={{ width: '100%' }}>
              {!showConfirm && (
                <ModalBody fontSize="sm">
                  <VStack mt={8} spacing={6} alignItems="flex-start">
                    <FcDisclaimer size={38} />
                    <Box color="gray.500" fontSize="xs">
                      <Heading size="sm" mb={2} fontWeight={500} color="gray.800" as="h2">
                        Reject Task
                      </Heading>
                      Are you sure you want to reject this task?
                    </Box>
                  </VStack>
                </ModalBody>
              )}
              {showConfirm && (
                <ModalBody fontSize="sm">
                  <VStack mt={8} mb={5} spacing={6} alignItems="flex-start">
                    <FcDisclaimer size={38} />
                    <Box color="gray.500" fontSize="xs">
                      <Heading size="sm" mb={2} fontWeight={500} color="gray.800" as="h2">
                        Reject Task
                      </Heading>
                      Please provide a reason for rejecting this task
                    </Box>
                  </VStack>
                  <ConnectedTextArea
                    label="Reason"
                    name="reason"
                    value={values.reason}
                    placeholder="Enter a Reason..."
                  />
                </ModalBody>
              )}
              <ModalFooter>
                <SecondaryButton
                  variant="ghost"
                  w="70%"
                  onClick={() => {
                    onClose()
                    resetForm()
                    setShowConfirm(false)
                  }}
                  isLoading={isLoading}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  ml={3}
                  w="100%"
                  colorScheme="red"
                  leftIcon={<VscCheck />}
                  isLoading={isLoading}
                  type="submit"
                >
                  {showConfirm ? 'Reject' : 'Confirm'}
                </PrimaryButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

const RequestInfoModal: React.FC<{
  isOpen: boolean
  isLoading: boolean
  onClose(): void
  onConfirm: (comments: string) => void
}> = ({ onConfirm, isOpen, onClose, isLoading }) => {
  return (
    <ModalWrap title="Request more information" isOpen={isOpen} onClose={onClose}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          comments: ''
        }}
        onSubmit={async (values) => {
          await onConfirm(values.comments)
        }}
      >
        {({ resetForm }) => (
          <Form style={{ width: '100%' }}>
            <ModalBody>
              <Text fontSize="sm" mb={6}>
                Are you sure you want to request for more information on this task?
              </Text>
              <ConnectedTextArea label="Add comments" name="comments" />
            </ModalBody>

            <ModalFooter>
              <Button
                variant="outline"
                colorScheme="green"
                fontSize="sm"
                onClick={() => {
                  onClose()
                  resetForm()
                }}
                isLoading={isLoading}
              >
                Cancel
              </Button>
              <Button
                ml={3}
                colorScheme="green"
                leftIcon={<Send size={16} />}
                fontSize="sm"
                type="submit"
                isLoading={isLoading}
              >
                Send
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalWrap>
  )
}

export { ConfirmationModal, RequestInfoModal, RejectModal }
