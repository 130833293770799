import { Box, Flex, Heading, HStack, useToast } from '@chakra-ui/react'
import React from 'react'
import { MdAdd } from 'react-icons/md'
import { Link, useHistory } from 'react-router-dom'
import { pluralHandler } from 'utils'
import { CardListLoader, CustomTooltip, Pagination, SearchBar } from '../../../components'

import { ERROR_TOAST } from '../../../constants'

import {
  CommunityEntity,
  Maybe,
  useCountCompanyCommunitiesQuery,
  useGetCommunitiesQuery
} from '../../../generated/graphql'
import { PageListContainer } from '../../../layouts'

import { EmptyListHandler } from 'components'
import { CommunitiesGrid } from '../components'
import { PrimaryButton } from 'components/UI/Buttons'
import { FcCollaboration } from 'react-icons/fc'
import { createGroupTooltipContent, groupTitleHelperPopover } from './help'

const Communities: React.FC = () => {
  const toast = useToast()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })

  const [search, setSearch] = React.useState('')
  const history = useHistory()
  const [limit, setLimit] = React.useState(() => (history.location.state as any)?.limit || 12)
  const [start, setStart] = React.useState(() => (history.location.state as any)?.currentPage || 0)

  const pagination = { limit, start }

  const { data: communitiesCount, loading: loadingCount } = useCountCompanyCommunitiesQuery({
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    },
    variables: { filters: { name: { $contains: search } } }
  })
  const { data, loading } = useGetCommunitiesQuery({
    onError,
    fetchPolicy: 'cache-and-network',
    variables: { filters: { name: { $contains: search } }, pagination }
  })

  const count = communitiesCount?.countCompanyCommunities || 0

  const stats = React.useMemo(
    () => [
      {
        title: 'Groups Overview',
        icon: FcCollaboration,
        value: `${count} ${pluralHandler('Group', count)}`
      }
    ],
    [loadingCount, count]
  )

  return (
    <PageListContainer title="Groups" icon={<FcCollaboration />} loading={loading} stats={stats}>
      <Flex align="center" justify="space-between" w="100%" mb={10}>
        <Flex align="center">
          <Heading size="xl" color="gray.600">
            Groups
          </Heading>
          {groupTitleHelperPopover}
        </Flex>
        <HStack spacing={6}>
          <SearchBar
            placeholder="Search Groups"
            value={search}
            onSearch={(value) => setSearch(value)}
          />
          <CustomTooltip label={createGroupTooltipContent}>
            <Link to="/auth/create-community">
              <PrimaryButton leftIcon={<MdAdd />}>Connect a group</PrimaryButton>
            </Link>
          </CustomTooltip>
        </HStack>
      </Flex>

      {loading ? (
        <CardListLoader />
      ) : !data?.getBaoleaderCommunities?.length ? (
        <EmptyListHandler title="No Groups" />
      ) : (
        <CommunitiesGrid communities={data?.getBaoleaderCommunities as Maybe<CommunityEntity>[]} />
      )}

      <Box py={16}>
        <Pagination
          setLimit={setLimit}
          start={start}
          setStart={setStart}
          count={count}
          limit={limit}
          paginationEntries={[8, 12, 24, 48, 96]}
        />
      </Box>
    </PageListContainer>
  )
}

export default Communities
