import { Button, Checkbox, Flex, Heading, useDisclosure, VStack, Text } from '@chakra-ui/react'
import { Exact, Maybe, PaginationArg } from 'generated/graphql'
import React from 'react'
import { DataListEntity, DataListOptionEntity } from '../../../../../../generated/graphql'
import { AlertModal, EmptyListHandler, Pagination } from 'components'

import CSVImporter from './components/CSVImporter'
import { STAGE } from '../..'
import ListOptionItem from './components/ListOptionItem'

import { VscAdd, VscTrash } from 'react-icons/vsc'
import { pluralHandler } from 'utils'

export type ListDetailsProps = {
  selectedItem: DataListEntity | null
  stage: STAGE
  type: 'inactive' | 'active'
  callBack?: () => void
  deactivateListOption: (id: string) => Promise<void>
  dataListOptions: DataListOptionEntity[]
  activateListOption: (id: string) => Promise<void>
  setLimit: React.Dispatch<React.SetStateAction<number>>
  start: number
  setStart: React.Dispatch<React.SetStateAction<number>>
  count: number
  limit: number
  refetchDataListOptions: (
    variables?:
      | Partial<
          Exact<{
            sort?: Maybe<Maybe<string> | Maybe<string>[]> | undefined
            filters?: any
            pagination?: Maybe<PaginationArg> | undefined
          }>
        >
      | undefined
  ) => Promise<any>
}

const ListDetails = React.forwardRef<HTMLDivElement, ListDetailsProps>(
  (
    {
      selectedItem,
      dataListOptions,
      callBack,
      stage,
      type,
      deactivateListOption,
      activateListOption,
      setLimit,
      count,
      limit,
      setStart,
      start,
      refetchDataListOptions
    },
    ref
  ) => {
    const [selectedItems, setSelectedItems] = React.useState<string[]>([])
    const isIndeterminate =
      selectedItems?.length > 0 && selectedItems.length < dataListOptions?.length
    const isChecked = selectedItems?.length > 0

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isActiveOpen, onOpen: onActiveOpen, onClose: onActiveClose } = useDisclosure()

    React.useEffect(() => {
      if (count) {
        if (start > count && count > 0 && start > 0) {
          setStart(() => (count && count >= 10 ? count - 10 : 0))
        }
      }
      ;(async () => {
        await refetchDataListOptions({ pagination: { limit, start } })
      })()
    }, [limit, start, count])

    if (!dataListOptions || dataListOptions?.length === 0)
      return (
        <>
          <CSVImporter stage={stage} callBack={callBack} selectedItem={selectedItem} />
          <EmptyListHandler />
        </>
      )
    return (
      <>
        <AlertModal
          onConfirm={() => {
            selectedItems.forEach((item) => deactivateListOption(item))
            onClose()
            setSelectedItems([])
          }}
          isOpen={isOpen}
          title="Archive List"
          confirmButtonText="Archive"
          activatorStatus="deactivate"
          onClose={onClose}
        >{`Deactivate ${selectedItems?.length} ${pluralHandler(
          'Item',
          selectedItems?.length
        )}`}</AlertModal>
        <AlertModal
          onConfirm={() => {
            selectedItems.forEach((item) => activateListOption(item))
            onActiveClose()
            setSelectedItems([])
          }}
          title="Activate"
          activatorStatus="activate"
          isOpen={isActiveOpen}
          onClose={onActiveClose}
        >{`Activate ${selectedItems?.length} ${pluralHandler(
          'Item',
          selectedItems?.length
        )}`}</AlertModal>
        <VStack flex={1} w="100%" h="100%" alignItems="flex-start" justifyContent="flex-start">
          <CSVImporter stage={stage} callBack={callBack} selectedItem={selectedItem} />
          <Heading as="h2"> {selectedItem?.attributes?.name}</Heading>

          <Flex align="center" w="100%" justify="space-between">
            <Checkbox
              size="lg"
              isChecked={isChecked}
              mt={3}
              colorScheme="green"
              isIndeterminate={isIndeterminate}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedItems(dataListOptions?.map((item) => item?.id || ''))
                }
                if (!e.target.checked) {
                  setSelectedItems([])
                }
              }}
            >
              <Text color="gray.700" fontSize="xs">
                {isIndeterminate || isChecked ? 'Deselect all' : 'Select all'}
              </Text>
            </Checkbox>
            {selectedItems?.length > 0 &&
              (type === 'inactive' ? (
                <Button
                  leftIcon={<VscAdd />}
                  fontSize="xs"
                  colorScheme="green"
                  variant="ghost"
                  onClick={onActiveOpen}
                  size="xs"
                >
                  Activate ({selectedItems?.length})
                </Button>
              ) : (
                <Button
                  leftIcon={<VscTrash />}
                  fontSize="xs"
                  colorScheme="red"
                  variant="ghost"
                  onClick={onOpen}
                  size="xs"
                >
                  Deactivate ({selectedItems?.length})
                </Button>
              ))}
          </Flex>
          <VStack py={4} spacing={1} w="100%">
            {dataListOptions?.length > 0 ? (
              dataListOptions?.map((data, index) => {
                const isActive = selectedItems.includes(data?.id || '')
                const onChange = () => {
                  if (selectedItems.includes(data?.id || '')) {
                    setSelectedItems(selectedItems.filter((id) => id !== data?.id))
                  } else {
                    setSelectedItems([...selectedItems, data?.id || ''])
                  }
                }
                return (
                  <ListOptionItem
                    data={data}
                    {...(dataListOptions?.length === index + 1 && { ref })}
                    isActive={isActive}
                    onChange={onChange}
                    key={data?.id}
                    deactivateListOption={deactivateListOption}
                    activateListOption={activateListOption}
                  />
                )
              })
            ) : (
              <EmptyListHandler />
            )}
          </VStack>
        </VStack>
        <Pagination
          setLimit={setLimit}
          start={start}
          setStart={setStart}
          count={count}
          limit={limit}
          pageRangeDisplayed={5}
        />
      </>
    )
  }
)

export default ListDetails
