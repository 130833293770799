import { Button, Flex, Icon, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FloatingButtonWrapper, ModalWrap } from '../../../components'
import CardFooter from '../../../components/UI/Card/CardFooter'
import {
  ConnectedCheckbox,
  ConnectedFormGroup,
  ConnectedSelect
} from '../../../components/forms/FormElements'
import { DATA_COLLECTOR, ERROR_TOAST } from '../../../constants'
import {
  Maybe,
  useGetCommunitiesQuery,
  UsersPermissionsUserEntity
} from '../../../generated/graphql'
import { addUserSchema } from '../../../utils/validationSchemas'
import ConnectedPhoneInput from '../../../components/forms/FormElements/ConnectedPhoneInput'
import Countries from 'world-countries'
import { OptionType } from '../../../components/forms/FormElements/ConnectedSelect'
import { find } from 'lodash'
import { VscAdd } from 'react-icons/vsc'
import { PenTool } from 'react-feather'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'

const countryOptions = Countries.map(({ name, idd, flag }) => ({
  name: name.common,
  code: idd,
  flag: flag
}))
const BaoriderModal: React.FC<{
  editableBaorider?: Maybe<UsersPermissionsUserEntity>
  selectCommunity?: boolean
  isEditMode: boolean
  image?: any
  isOpen: boolean
  isLoading: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: (
    baorider: UsersPermissionsUserEntity & { community: string }
  ) => Promise<Maybe<UsersPermissionsUserEntity> | void> | undefined
}> = ({
  isOpen,
  onClose,
  editableBaorider,
  isEditMode,
  image,
  onSubmit,
  isLoading,
  selectCommunity
}) => {
  const toast = useToast()

  const location = useLocation<{ refetchCommunities?: boolean }>()

  const onError = () => {
    toast({ title: 'There was an error.', ...ERROR_TOAST })
  }
  const { data: storedCommunities, refetch } = useGetCommunitiesQuery({
    onError
  })
  React.useEffect(() => {
    if (location?.state?.refetchCommunities) {
      refetch()
    }
    // eslint-disable-next-line
  }, [location])
  const submitForm = async ({ hasConsent, addAnother, ...values }: any, { resetForm }: any) => {
    if (!hasConsent) {
      toast({ title: 'Consent from Baorider is required.', ...ERROR_TOAST })
      return
    }
    const user = await onSubmit(values)
    if (user) {
      resetForm({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        community: '',
        hasConsent: false
      })
      if (!addAnother) {
        onClose()
      }
    }
  }

  return (
    <ModalWrap title={'Add ' + DATA_COLLECTOR} image={image} isOpen={isOpen} onClose={onClose}>
      <Formik
        enableReinitialize={true}
        validationSchema={addUserSchema}
        initialValues={{
          ...editableBaorider,
          hasConsent: false,
          addAnother: false
        }}
        onSubmit={submitForm}
      >
        {({ handleSubmit, setFieldValue, isSubmitting, status }) => (
          <Form onSubmit={handleSubmit}>
            <FloatingButtonWrapper
              isLoading={isSubmitting}
              status={status}
              button={
                <Flex flex={1} justify="flex-end" align="center">
                  <SecondaryButton mr={2} onClick={onClose}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    width="100%"
                    leftIcon={isEditMode ? <PenTool size={16} /> : <VscAdd />}
                    isLoading={isLoading || isSubmitting}
                  >
                    {isEditMode ? 'Update' : 'Add'}
                  </PrimaryButton>
                </Flex>
              }
            >
              <Flex flexDir="column" p={4}>
                <ConnectedFormGroup
                  name="firstName"
                  label="First name *"
                  placeholder="Enter first name"
                />
                <ConnectedFormGroup
                  name="lastName"
                  label="Last name *"
                  placeholder="Enter last name"
                />
                <ConnectedSelect
                  name="gender"
                  label="Gender"
                  placeholder="Select Gender"
                  showIsValidValue
                  options={
                    [
                      { label: 'Male', value: 'Male' },
                      { label: 'Female', value: 'Female' }
                    ] as OptionType[]
                  }
                />
                <ConnectedPhoneInput
                  label="Phone Number *"
                  setFieldValue={setFieldValue}
                  name="phoneNumber"
                  country={find(countryOptions, ['name', 'Kenya'])}
                  options={countryOptions}
                />
                {selectCommunity && (
                  <Flex align="center">
                    <ConnectedSelect
                      name="community"
                      label="Community"
                      placeholder="Select a Community"
                      options={
                        ((storedCommunities?.getBaoleaderCommunities || []).map((item) => {
                          return { label: item?.attributes?.name, value: item?.id }
                        }) as OptionType[]) || []
                      }
                    />
                    <Link
                      to={{
                        pathname: '/auth/create-community',
                        state: {
                          from: '/auth/baoriders'
                        }
                      }}
                    >
                      <Button ml={5} px={5} mt={3} fontSize="sm" colorScheme="green">
                        <Icon name="add" />
                      </Button>
                    </Link>
                  </Flex>
                )}
              </Flex>
              <CardFooter>
                <ConnectedCheckbox
                  name="hasConsent"
                  mb={3}
                  label="You have been given permission to upload these details."
                />

                <ConnectedCheckbox name="addAnother" label="Add Another" />
              </CardFooter>
            </FloatingButtonWrapper>
          </Form>
        )}
      </Formik>
    </ModalWrap>
  )
}

export default BaoriderModal
