import { Heading, Box, Text, Flex, Icon, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { Card, PhotoGrid } from 'components'
import { EvidenceEntity, GetBaoleaderTaskQuery, UploadFileEntity } from 'generated/graphql'
import Maybe from 'graphql/tsutils/Maybe'
import React from 'react'
import { VscArrowRight } from 'react-icons/vsc'

export type PhotoEvidenceProps = {
  evidence: Maybe<EvidenceEntity> | undefined
  setCurrentImageShowing: React.Dispatch<React.SetStateAction<UploadFileEntity[]>>
  setCurrentImageIndex: React.Dispatch<React.SetStateAction<number>>
  onShowLightbox: () => void
  data: GetBaoleaderTaskQuery | undefined
}

const PhotoEvidence: React.FC<PhotoEvidenceProps> = ({
  evidence,
  setCurrentImageShowing,
  setCurrentImageIndex,
  onShowLightbox,
  data
}) => {
  const [objectFit, setObjectFit] = React.useState<'cover' | 'contain'>('contain')
  return evidence?.attributes?.photos && evidence?.attributes?.photos?.data?.length > 0 ? (
    <Card width="100%" p={6} border="none">
      <Flex justify="space-between" align="center">
        <Heading as="h4" size="xs" color="gray.600">
          <Flex align="center">
            Photo Evidence <Icon as={VscArrowRight} mx={2} color="green.300" />
            {evidence?.attributes?.photos?.data?.length}
          </Flex>
        </Heading>
        <Box>
          <FormControl cursor="pointer" display="flex" alignItems="center">
            <FormLabel fontSize="x-small" htmlFor="image-fit" mb="0">
              Image fit {objectFit}
            </FormLabel>
            <Switch
              onChange={(e) => {
                setObjectFit(e.target.checked ? 'cover' : 'contain')
              }}
              colorScheme="green"
              id="image-fit"
            />
          </FormControl>
        </Box>
      </Flex>
      <Box py={3}>
        <Text fontSize="sm" color="gray.500">
          {
            data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.task_type?.data
              ?.attributes?.photoEvidence
          }
        </Text>
        <Box my={6}>
          <PhotoGrid
            onClick={(_img, index) => {
              setCurrentImageShowing(
                data?.getBaoleaderTask?.attributes?.evidences?.data?.[0].attributes?.photos
                  ?.data as UploadFileEntity[]
              )
              setCurrentImageIndex(index)
              objectFit
              onShowLightbox()
            }}
            objectFit={objectFit}
            evidence={evidence as EvidenceEntity}
          />
        </Box>
      </Box>
    </Card>
  ) : null
}

export default PhotoEvidence
