import {
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  InputProps,
  InputLeftElement,
  Icon
} from '@chakra-ui/react'
import React from 'react'
import { VscArrowRight, VscSearch } from 'react-icons/vsc'
import { useDebounce } from '../../../hooks'

type SearchBarProps = InputProps & {
  onSearch: (text: string) => void
  isLoading?: boolean
  searchValue?: string
  withIcon?: boolean
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  isLoading,
  searchValue,
  withIcon,
  variant,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>(() => searchValue || '')

  const debouncedSearchTerm = useDebounce(searchTerm, 600)

  React.useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, onSearch])

  return (
    <InputGroup variant={variant} w="100%">
      <InputLeftElement color="green.500">
        <VscSearch />
      </InputLeftElement>
      <Input
        {...rest}
        variant="filled"
        fontWeight="semibold"
        focusBorderColor="green.200"
        value={searchTerm}
        autoFocus={Boolean(searchTerm)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
      />
      <InputRightElement>
        {isLoading ? (
          <Spinner size="xs" color="green.400" />
        ) : (
          withIcon && <Icon as={VscArrowRight} color="green.400" />
        )}
      </InputRightElement>
    </InputGroup>
  )
}

export default SearchBar

SearchBar.defaultProps = {
  width: '100%',
  minW: '160px',
  type: 'text',
  placeholder: 'Search...',
  withIcon: true
}
