import { Flex, FlexProps } from '@chakra-ui/react'
import { SubscripitonIndicator } from 'components'
import { useAuthContext } from 'context/AuthProvider'
import { useIsSubscribed } from 'hooks'
import * as React from 'react'
import { Helmet } from 'react-helmet'

type PageWrapProps = FlexProps & {
  title: string
  inline?: boolean
}

const PageWrap = React.forwardRef<HTMLDivElement, PageWrapProps>(
  ({ children, title, inline, ...rest }, ref) => {
    const {
      isSubscribed,
      loading,
      subscriptionStatus,
      cancelAtPeriodEnd,
      isManual,
      billingInformationCompleted
    } = useIsSubscribed()
    const { isAuthenticated } = useAuthContext()

    const showBanner =
      billingInformationCompleted &&
      (!isSubscribed || cancelAtPeriodEnd) &&
      isAuthenticated &&
      !inline &&
      !loading &&
      !isManual
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Flex {...rest} ref={ref}>
          {showBanner ? (
            <SubscripitonIndicator
              status={subscriptionStatus}
              cancelAtPeriodEnd={cancelAtPeriodEnd}
            />
          ) : (
            <></>
          )}
          {children}
        </Flex>
      </>
    )
  }
)

PageWrap.defaultProps = {
  flex: 1,
  bg: 'background.200',
  pt: 'calc(64px + 1rem)',
  height: '100%',
  flexDir: 'column',
  minHeight: '100vh',
  align: 'flex-start',
  justify: 'flex-start',
  inline: false
}

export default PageWrap
