import { Flex, ButtonProps, Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'
import MotionFlex from 'components/utils/MotionFlex'
import { PrimaryButton } from 'components/UI/Buttons'

import React from 'react'

type FloatingButtonWrapperProps = {
  buttonTitle?: string
  button?: React.ReactNode
  status?: any
} & ButtonProps

const FloatingButtonWrapper: React.FC<FloatingButtonWrapperProps> = ({
  children,
  buttonTitle,
  button,
  status,
  ...rest
}) => {
  return (
    <Flex
      direction="column"
      position="relative"
      p={5}
      pb={12}
      borderRadius="lg"
      backgroundColor="white"
    >
      {children}
      {status && (
        <MotionFlex
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          justify="center"
          my={4}
          width="100%"
        >
          <Alert borderRadius="lg" status="error">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="sm">
              Error:
            </AlertTitle>
            <AlertDescription fontSize="xs">{status}</AlertDescription>
          </Alert>
        </MotionFlex>
      )}
      {button ? (
        <Flex
          mt={4}
          alignSelf="center"
          width="70%"
          position="absolute"
          bottom={0}
          transform="translateY(50%)"
        >
          {button}
        </Flex>
      ) : buttonTitle ? (
        <PrimaryButton
          mt={4}
          alignSelf="center"
          width="70%"
          position="absolute"
          bottom={0}
          transform="translateY(50%)"
          type="submit"
          {...rest}
        >
          {buttonTitle}
        </PrimaryButton>
      ) : null}
    </Flex>
  )
}
FloatingButtonWrapper.defaultProps = {}

export default FloatingButtonWrapper
