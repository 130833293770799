import React from 'react'
import SettingsWrapper from '../SettingsWrapper'
import { SimpleGrid } from '@chakra-ui/react'
import WeeklyStatus from './WeeklyStatus'
import LoginReminder from './LoginReminder'
// import AllTimeWeeklyStatus from './AllTimeWeeklyStatusReport'

const SettingsTab: React.FC = () => {
  return (
    <SettingsWrapper heading="Settings" leftHeader={<></>}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={10}
        w="100%"
        alignItems="center"
        justifyContent="center"
      >
        <WeeklyStatus />
        <LoginReminder />
        {/* <AllTimeWeeklyStatus /> */}
      </SimpleGrid>
    </SettingsWrapper>
  )
}

export default SettingsTab
