import { VStack, Heading, Checkbox, Text, Flex, Button, useDisclosure } from '@chakra-ui/react'
import { AlertModal, EmptyListHandler } from 'components'

import { DataListEntity } from 'generated/graphql'
import React from 'react'
import { VscAdd, VscTrash } from 'react-icons/vsc'
import { pluralHandler } from 'utils'
import { STAGE } from '../..'
import ListItem from './components/Item'

export type ListsProps = {
  data: DataListEntity[]
  type: 'inactive' | 'active'
  deactivateList: (id: string) => Promise<void>
  activateList: (id: string) => Promise<void>
  setSelectedItem: React.Dispatch<React.SetStateAction<null | DataListEntity>>
  setStage: React.Dispatch<React.SetStateAction<STAGE>>
}

const Lists: React.FC<ListsProps> = ({
  data,
  setStage,
  type,
  setSelectedItem,
  deactivateList,
  activateList
}) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>([])
  const isIndeterminate = selectedItems?.length > 0 && selectedItems.length < data?.length
  const isChecked = selectedItems?.length > 0

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isActiveOpen, onOpen: onActiveOpen, onClose: onActiveClose } = useDisclosure()
  if (!data || data?.length === 0) return <EmptyListHandler />
  return (
    <>
      <AlertModal
        onConfirm={() => {
          selectedItems.forEach((item) => deactivateList(item))
          onClose()
          setSelectedItems([])
        }}
        isOpen={isOpen}
        title="Archive List"
        confirmButtonText="Archive"
        activatorStatus="deactivate"
        onClose={onClose}
      >{`Deactivate ${selectedItems?.length} ${pluralHandler(
        'Item',
        selectedItems?.length
      )}`}</AlertModal>
      <AlertModal
        onConfirm={() => {
          selectedItems.forEach((item) => activateList(item))
          onActiveClose()
          setSelectedItems([])
        }}
        title="Activate List"
        activatorStatus="activate"
        isOpen={isActiveOpen}
        onClose={onActiveClose}
      >{`Activate ${selectedItems?.length} ${pluralHandler(
        'Item',
        selectedItems?.length
      )}`}</AlertModal>
      <VStack
        flex={1}
        w="100%"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        my={3}
      >
        <Heading py={3} size="xs">
          Data Lists
        </Heading>
        <Flex align="center" w="100%" justify="space-between">
          <Checkbox
            size="lg"
            isChecked={isChecked}
            my={3}
            colorScheme="green"
            isIndeterminate={isIndeterminate}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedItems(data?.map((item) => item?.id || ''))
              }
              if (!e.target.checked) {
                setSelectedItems([])
              }
            }}
          >
            <Text color="gray.700" fontSize="x-small">
              {isIndeterminate || isChecked ? 'Deselect all' : 'Select all'}
            </Text>
          </Checkbox>
          {selectedItems?.length > 0 &&
            (type === 'inactive' ? (
              <Button
                leftIcon={<VscAdd />}
                fontSize="xs"
                colorScheme="green"
                variant="ghost"
                onClick={onActiveOpen}
                size="xs"
              >
                Activate ({selectedItems?.length})
              </Button>
            ) : (
              <Button
                leftIcon={<VscTrash />}
                fontSize="xs"
                colorScheme="red"
                variant="ghost"
                onClick={onOpen}
                size="xs"
              >
                Archive ({selectedItems?.length})
              </Button>
            ))}
        </Flex>
        {data?.map((el) => {
          const isActive = selectedItems.includes(el?.id || '')
          const onChange = () => {
            if (selectedItems.includes(el?.id || '')) {
              setSelectedItems(selectedItems.filter((id) => id !== el?.id))
            } else {
              setSelectedItems([...selectedItems, el?.id || ''])
            }
          }
          return (
            <ListItem
              key={el?.id}
              el={el}
              isActive={isActive}
              onChange={onChange}
              deactivateList={deactivateList}
              activateList={activateList}
              onClick={() => {
                setStage(STAGE.details)
                setSelectedItem(el)
              }}
            />
          )
        })}
      </VStack>
    </>
  )
}

export default Lists
