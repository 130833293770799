import { ConnectedFormGroup } from 'components/forms/FormElements'
import { Form, Formik } from 'formik'
import React from 'react'
import { HStack, useToast } from '@chakra-ui/react'
import { VscAdd, VscListFlat } from 'react-icons/vsc'
import {
  Enum_Datalist_Status,
  useCreateDataListMutation
} from '../../../../../../generated/graphql'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../../../../constants'
import { formatError } from 'utils'
import { PrimaryButton } from 'components/UI/Buttons'

type CreateDataListProps = {
  callBack?: () => void
}

const CreateDataList: React.FC<CreateDataListProps> = ({ callBack }) => {
  const toast = useToast()
  const [createDataList] = useCreateDataListMutation({
    onCompleted: async () => {
      callBack?.()
      toast({ ...SUCCESS_TOAST, title: 'Data list created' })
    },
    onError: (error) => {
      toast({ ...ERROR_TOAST, title: "Couldn't create data list -> " + error.message })
    }
  })

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={async ({ name }, { setSubmitting, setStatus, resetForm }) => {
        try {
          setSubmitting(true)
          await createDataList({
            variables: {
              input: {
                name: name || '',
                status: Enum_Datalist_Status.Active
              }
            }
          })
          setSubmitting(false)
          resetForm()
        } catch (error) {
          setStatus(formatError(error))
          console.log(error)
        }
      }}
    >
      <Form>
        <HStack>
          <ConnectedFormGroup name="name" mb={0} leftIcon={VscListFlat} label="List name" />
          <PrimaryButton leftIcon={<VscAdd />} type="submit">
            Create
          </PrimaryButton>
        </HStack>
      </Form>
    </Formik>
  )
}

export default CreateDataList
