import React, { useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/thumbs/thumbs.min.css'

// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs } from 'swiper/core'
import {
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  ModalOverlay,
  useMediaQuery,
  Box,
  Heading,
  Skeleton,
  VStack,
  FormControl,
  FormLabel,
  Switch,
  theme
} from '@chakra-ui/react'
import { TaskDefinitionEntity, UploadFileEntity } from '../../../generated/graphql'
import styled from '@emotion/styled'

import moment from 'moment'
import { H } from 'components'

const Container = styled(Center)`
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .slideShowModal {
    width: 100%;
    position: absolute;
    height: 100%;
    .SwiperSlide {
      height: 100%;
      b {
        color: ${theme.colors.green[300]};
      }
    }
  }
  .slideShowModal img {
    max-height: 100vh;
  }

  @media (max-width: 40em) {
  }
`

// install Swiper modules
SwiperCore.use([Navigation, Thumbs])

type SlideShowModalProps = {
  photos?: UploadFileEntity[] | undefined
  task: TaskDefinitionEntity
  loading: boolean
  isOpen: boolean
  index?: number
  onClose: () => void
}

const SlideShowModal: React.FC<SlideShowModalProps> = ({
  photos,
  index,
  loading,
  isOpen,
  onClose,
  task
}) => {
  const [thumbsSwiper] = useState<SwiperCore | null | undefined>(null)
  const [isMobileOrTablet] = useMediaQuery('(max-width: 40em)')
  const [objectFit, setObjectFit] = useState<'cover' | 'contain'>('contain')

  return (
    <Modal
      motionPreset="scale"
      isCentered
      onClose={onClose}
      size="full"
      scrollBehavior="inside"
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent zIndex={47547384784837384743878} bg="transparent" borderRadius="none">
        <ModalCloseButton
          rounded="full"
          mt={isMobileOrTablet ? 8 : 0}
          size="lg"
          color="gray.100"
          zIndex={4748373789}
        />
        <ModalBody position="relative" bg="rgba(0,0,0,0.1)" p={0}>
          <Container position="absolute" height="100%" width="100%">
            <Swiper
              slidesPerView={1}
              autoHeight={true}
              spaceBetween={10}
              navigation={true}
              initialSlide={index}
              thumbs={{ swiper: thumbsSwiper }}
              className="slideShowModal"
            >
              {photos?.map((photo) => (
                <SwiperSlide className="SwiperSlide" key={photo?.id}>
                  <Center w="100%" height="100%">
                    <Skeleton
                      display="flex"
                      flex={2}
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      isLoaded={!loading}
                    >
                      <Image
                        flex={1}
                        objectFit={objectFit}
                        alt="baotree evidence, evidence image"
                        boxSize="100%"
                        fallbackSrc={photo?.attributes?.formats?.thumbnail?.url}
                        src={photo?.attributes?.url}
                      />
                    </Skeleton>
                  </Center>
                  <Box
                    maxW={280}
                    p={8}
                    position="absolute"
                    top={0}
                    right={0}
                    borderRadius="1rem 0 0 1rem"
                    w="16rem"
                    color="gray.100"
                    bg="rgba(0,0,0,0.2)"
                    h="100%"
                    height="100%"
                    opacity={0.1}
                    transition="opacity .4s ease-in-out 0s"
                    _hover={{
                      opacity: 1
                    }}
                  >
                    <Heading mb={6} as="h2">
                      Details
                    </Heading>

                    <VStack
                      spacing={3}
                      fontSize="xs"
                      width="100%"
                      flexWrap="wrap"
                      alignItems="flex-start"
                    >
                      <Text>
                        <b>Task:</b> <H />
                        {task.id}
                      </Text>
                      <Text>
                        <b>Uploaded at:</b> {moment(photo?.attributes?.createdAt).format('LLL')}
                      </Text>
                      <Text>
                        <b>Updated at:</b> {moment(photo?.attributes?.updatedAt).format('LLL')}
                      </Text>
                      <Text>
                        <b>Mime:</b> {photo?.attributes?.mime}
                      </Text>
                      <Box>
                        <FormControl
                          onChange={() => {
                            setObjectFit(objectFit === 'cover' ? 'contain' : 'cover')
                          }}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                        >
                          <FormLabel fontSize="x-small" htmlFor="image-fit" mb="0">
                            Image fit {objectFit}
                          </FormLabel>
                          <Switch colorScheme="green" id="image-fit" />
                        </FormControl>
                      </Box>
                    </VStack>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
SlideShowModal.defaultProps = {
  index: 0
}
export default SlideShowModal
