import { Box, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { PrimaryButton } from 'components/UI/Buttons'

import { useIsSubscribed } from 'hooks'
import strapiHelpers from 'utils/strapiHelpers'
import React from 'react'
import ContactFrom from './ContactFrom'

export type CompleteBillingDetailsProps = {}

const CompleteBillingDetails: React.FC<CompleteBillingDetailsProps> = () => {
  const { isSubscribed } = useIsSubscribed()
  const [isLoading, setIsLoading] = React.useState(false)

  const [showWidget, setShowWidget] = React.useState(false)
  return (
    <>
      <Box>
        {!isSubscribed && (
          <>
            {!showWidget && (
              <Box mb={20}>
                <Text fontWeight="sm">
                  Thank you for setting up your account! You can try out Baotree for free, for 14
                  days.
                  <br />
                  We just need a few more details to get started - Click the button below to start
                  your free trial.
                </Text>
                <Box
                  w={'full'}
                  bg={useColorModeValue('white', 'gray.900')}
                  boxShadow="md"
                  rounded="lg"
                  p={6}
                  textAlign={'center'}
                  mt="2vh"
                >
                  <Heading fontSize={'2xl'} fontFamily={'body'}>
                    Lite Plan
                  </Heading>
                  <Text textAlign={'center'} px={3} my={4}>
                    The ultimate starter pack
                  </Text>

                  <Stack align={'center'} justify={'center'} direction={'column'}>
                    <Text fontWeight={600} fontSize={'xl'}>
                      £50 /month
                    </Text>
                    <Text fontSize={'sm'} color={'gray.500'}>
                      Up to 10 users included, £9 per additional user per month
                    </Text>
                  </Stack>

                  <PrimaryButton
                    mt={8}
                    w="full"
                    isLoading={isLoading}
                    onClick={async () => {
                      setIsLoading(true)
                      await strapiHelpers?.handleCreateCheckoutSession()
                      setIsLoading(false)
                    }}
                  >
                    Start your 14-day free trial
                  </PrimaryButton>
                </Box>
              </Box>
            )}
            <ContactFrom {...{ showWidget, setShowWidget }} />
          </>
        )}
        {isSubscribed && <Text fontSize="lg">You are already subscribed to Baotree!</Text>}
      </Box>
    </>
  )
}

export default CompleteBillingDetails
