import { Flex, Heading, Alert, AlertIcon, Text } from '@chakra-ui/react'
import { Card, H } from 'components'
import { GetBaoleaderTaskQuery, Enum_Task_Status } from 'generated/graphql'
import React from 'react'
import { Maybe, EvidenceEntity } from '../../../../generated/graphql'

export type StatusParserProps = {
  data: GetBaoleaderTaskQuery | undefined
  evidence: Maybe<EvidenceEntity> | undefined
}

const StatusParser: React.FC<StatusParserProps> = ({ data, evidence }) => {
  return (
    <>
      {data?.getBaoleaderTask?.attributes?.status === Enum_Task_Status.MoreInfoRequested && (
        <Card p={6} width="100%">
          <Flex direction="column">
            <Heading mb={4} size="md">
              <H withBar />
              Information requested details
            </Heading>

            <Text color="gray.600" fontSize="sm">
              {evidence?.attributes?.requestedInfo}
            </Text>
          </Flex>
        </Card>
      )}
      {data?.getBaoleaderTask?.attributes?.status === Enum_Task_Status.Rejected && (
        <Card p={6} width="100%">
          <Flex direction="column">
            <Flex direction="column">
              <Heading color="gray.600" size="sm">
                Reason for rejection:
              </Heading>
              <Alert my={4} variant="left-accent" fontSize="xs" status="error">
                <AlertIcon />
                {evidence?.attributes?.cancellationReason}
              </Alert>
            </Flex>
          </Flex>
        </Card>
      )}
    </>
  )
}

export default StatusParser
