import {
  VStack,
  Heading,
  ListItem,
  ListIcon,
  Flex,
  Center,
  List,
  Box,
  Text,
  Image
} from '@chakra-ui/react'
import { Card } from 'components'

import React from 'react'

import { MdCheckCircle } from 'react-icons/md'

import { images } from 'theme'
import { parseDot } from 'utils'
import DataFieldGenerator from './DataFieldGenerator'
import { PhotoEvidenceProps } from './PhotoEvidence'

type DataFieldsProps = PhotoEvidenceProps

const DataFields: React.FC<DataFieldsProps> = ({
  evidence,
  setCurrentImageIndex,
  setCurrentImageShowing,
  onShowLightbox,
  data
}) => {
  return evidence?.attributes?.custom_data_fields &&
    evidence?.attributes?.custom_data_fields?.length > 0 ? (
    <VStack width="100%" alignItems="flex-start" gap={6}>
      <Card width="100%" p={6} border="none">
        {evidence?.attributes?.data_field_values &&
          evidence?.attributes?.data_field_values?.data?.length > 0 && (
            <>
              <Heading size="xs" color="gray.600">
                Information Captured
              </Heading>
              <List my={2} spacing={0}>
                {evidence?.attributes?.data_field_values?.data?.map((field) => (
                  <Box py={3} key={field?.id}>
                    <Text color="gray.500" fontSize="xs">
                      {parseDot(
                        field?.attributes?.data_field?.data?.attributes?.label || 'no label'
                      )}
                    </Text>
                    <ListItem fontSize="xs">
                      <ListIcon as={MdCheckCircle} color="green.500" />

                      {field?.attributes?.value}
                    </ListItem>
                  </Box>
                ))}
              </List>
            </>
          )}
        {evidence?.attributes?.custom_data_fields &&
          evidence?.attributes?.custom_data_fields?.length > 0 && (
            <>
              <Heading size="xs" color="gray.600">
                Information Captured
              </Heading>
              <DataFieldGenerator
                {...{
                  evidence,
                  setCurrentImageIndex,
                  setCurrentImageShowing,
                  onShowLightbox
                }}
              />
            </>
          )}
      </Card>
      {evidence?.attributes?.notes && (
        <Card width="100%" border="none" p={6}>
          <Heading as="h6" color="gray.600" mb={4} size="xs">
            Comments
          </Heading>

          <Flex>
            <Text flex={1} fontSize="xs">
              {evidence?.attributes?.notes}
            </Text>
            <Center flex={0.5} my={8}>
              <Image
                objectFit="contain"
                rounded="full"
                w="50%"
                src={
                  data?.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.category
                    ?.data?.attributes?.featureImage?.data?.attributes?.formats?.small?.url
                    ? data.getBaoleaderTask?.attributes?.task_definition?.data?.attributes?.category
                        ?.data?.attributes?.featureImage?.data?.attributes?.formats?.small?.url
                    : images.logoNoText
                }
              />
            </Center>
          </Flex>
        </Card>
      )}
    </VStack>
  ) : (
    <></>
  )
}

export default DataFields
