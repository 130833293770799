import React from 'react'
import { ModalWrap } from 'components'

import { DATA_COLLECTOR } from '../../../../constants'
import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { OutlineButton, PrimaryButton } from 'components/UI/Buttons'
import { useHistory } from 'react-router-dom'

import { FcIdea } from 'react-icons/fc'

type DCOptionsModalProps = {
  isOpen: boolean
  onClose: () => void
  callBack?: () => void
}
const DCOptionsModal: React.FC<DCOptionsModalProps> = ({ isOpen, onClose, callBack }) => {
  const history = useHistory()
  return (
    <ModalWrap title="" rightElement={() => null} isOpen={isOpen} onClose={onClose} size="2xl">
      <HStack px={6} alignItems="flex-start" spacing={8}>
        <Box>
          <FcIdea size={100} />
        </Box>
        <VStack spacing={10} alignItems="flex-start">
          <VStack alignItems="flex-start">
            <Heading size="lg">User Successfully Added</Heading>
            <Text>
              You have successfully added this user to your Baotree dashboard. Don&apos;t forget to
              assign them to their projects so they can enter data on these tasks in their apps.
            </Text>
          </VStack>
          <HStack spacing={4} justifyContent="flex-end" w="100%">
            <OutlineButton onClick={callBack}>Add Another {DATA_COLLECTOR}</OutlineButton>
            <PrimaryButton onClick={() => history.push('/auth/projects')}>
              Go to Projects
            </PrimaryButton>
          </HStack>
        </VStack>
      </HStack>
    </ModalWrap>
  )
}

export default DCOptionsModal
