import { ConnectedFileUploader } from 'components/forms/FormElements'
import React from 'react'
import ModalWrap from '../../../components/utils/ModalWrap'
import { Form, Formik } from 'formik'
import {
  useUpdateTaskMutation,
  useGetBaoleaderTaskQuery,
  Exact,
  GetBaoleaderTaskQuery,
  UploadFileEntity
} from '../../../generated/graphql'
import { useToast } from '@chakra-ui/react'
import { ERROR_TOAST } from '../../../constants'
import { ApolloQueryResult } from '@apollo/client'
import { VscCheck } from 'react-icons/vsc'
import { PrimaryButton } from 'components/UI/Buttons'

type UploadFilesModalProps = {
  isOpen: boolean
  onClose: () => void
  title?: string
  taskId: string
  refetch?: (
    variables?:
      | Partial<
          Exact<{
            id: string
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetBaoleaderTaskQuery>>
}

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
  isOpen,
  onClose,
  title,
  taskId,
  refetch
}) => {
  const { data: task } = useGetBaoleaderTaskQuery({
    variables: {
      id: taskId
    }
  })
  const toast = useToast()
  const [updateTask] = useUpdateTaskMutation({
    onCompleted: async () => {
      await refetch?.()
      onClose()
    },
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    }
  })

  return (
    <ModalWrap title={title || ''} onClose={onClose} isOpen={isOpen}>
      <Formik
        initialValues={{
          files: [] as UploadFileEntity[]
        }}
        onSubmit={async ({ files }, { setSubmitting }) => {
          setSubmitting(true)
          try {
            await updateTask({
              variables: {
                id: taskId,
                input: {
                  documents: [
                    ...(task?.getBaoleaderTask?.attributes?.documents?.data?.map(
                      (file) => file?.id
                    ) as string[]),
                    ...(files.map((file) => file?.id) as string[])
                  ]
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, values: { files } }) => (
          <Form>
            <ConnectedFileUploader name="files" isMulti placeholder="Upload Files" />
            {files.length > 0 && (
              <PrimaryButton
                type="submit"
                leftIcon={<VscCheck />}
                w="100%"
                isLoading={isSubmitting}
              >
                Submit
              </PrimaryButton>
            )}
          </Form>
        )}
      </Formik>
    </ModalWrap>
  )
}

UploadFilesModal.defaultProps = {
  title: 'Upload Files'
}

export default UploadFilesModal
