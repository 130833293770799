import { Box, useToast } from '@chakra-ui/react'
import { ApolloError } from 'apollo-boost'
import { TasksTable } from 'components'
import { ERROR_TOAST, taskFilters } from '../../../../constants'
import { TaskEntity, useGetTasksQuery } from 'generated/graphql'
import React from 'react'
import { useParams } from 'react-router-dom'
import { tasksHelpPopover } from '../help'

const ProjectTasks: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const toast = useToast()

  const onError = (error: ApolloError) =>
    toast({ title: `There was an error. ${error}`, ...ERROR_TOAST })
  const [limit, setLimit] = React.useState(10)
  const [start, setStart] = React.useState(0)
  const [page, setPage] = React.useState(0)

  const [filters, setFilters] = React.useState<any>(() => ({}))
  const [search, setSearch] = React.useState('')
  const {
    loading,
    refetch: refetchTasks,
    data: tasksEntity
  } = useGetTasksQuery({
    variables: {
      pagination: {
        limit,
        start
      },
      filters: {
        ...filters,
        project: { id },
        id: { $contains: search }
      }
    },
    onError,
    fetchPolicy: 'cache-first'
  })

  const tasks = (tasksEntity?.getBaoleaderTasksWithCount?.data || []) as TaskEntity[]
  const taskCount = tasksEntity?.getBaoleaderTasksWithCount?.count || 0

  React.useEffect(() => {
    if (taskCount) {
      if (start > taskCount && taskCount > 0 && start > 0) {
        setStart(() => (taskCount && taskCount >= 10 ? taskCount - 10 : 0))
      }
    }
    ;(async () => {
      await refetchTasks({
        filters: { ...filters, project: { id }, id: { $contains: search } },
        pagination: { limit, start }
      })
    })()
  }, [limit, start, filters, taskCount])

  const onFilter = React.useCallback(
    async (where: { [key: string]: string | number | string[] }) => {
      setFilters(where)
      try {
        await refetchTasks({
          pagination: { limit, start },
          filters: { ...where, project: { id }, id: { $contains: search } }
        })
      } catch (e) {
        console.log(e)
      }
    },
    [limit, start, search]
  )

  const onReset = React.useCallback(async () => {
    await refetchTasks({
      filters: { project: { id }, id: { $contains: search } },
      pagination: { limit, start }
    })
  }, [limit, start, search])

  const onSearch = React.useCallback((search) => {
    setSearch(search)
  }, [])

  const visibleFilters = React.useMemo(() => {
    return taskFilters?.filter((filter) => filter !== 'Project')
  }, [])

  return (
    <Box py={4}>
      <TasksTable
        loading={loading}
        data={tasks}
        onFilter={onFilter}
        currentPage={page}
        tasksHelpPopover={tasksHelpPopover}
        limit={limit}
        setPage={setPage}
        visibleFilters={visibleFilters}
        setStart={setStart}
        onSearch={onSearch}
        searchValue={search}
        count={taskCount || 0}
        setLimit={setLimit}
        start={start}
        onReset={onReset}
      />
    </Box>
  )
}

export default ProjectTasks
