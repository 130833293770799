import { Flex, Text, useToast, HStack, Switch, FormControl, FormLabel } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { VscAdd } from 'react-icons/vsc'
import { FloatingButtonWrapper, ModalWrap } from '../../../components'
import ConnectedSelect, { OptionType } from '../../../components/forms/FormElements/ConnectedSelect'
import GridGenerator from '../../../components/GridGenerator/index'
import { ERROR_TOAST } from '../../../constants'
import {
  TaskDefinitionInput,
  useGetCategoriesQuery,
  useGetProjectTaskTypesQuery
} from '../../../generated/graphql'
import { addTaskSchema } from '../../../utils/validationSchemas'
import {
  Enum_Tasktype_Status,
  Enum_Taskdefinition_Status,
  TaskDefinitionEntity
} from '../../../generated/graphql'

import { ConnectedCheckbox } from 'components/forms/FormElements'
import TaskSearcher from './TaskSearcher'

import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'

const AddTaskModal: React.FC<{
  editableTask: TaskDefinitionEntity
  isEditMode: boolean
  isOpen: boolean
  isLoading: boolean
  projectImage?: any
  onOpen: () => void
  onClose: () => void
  onSubmit: (task: TaskDefinitionInput, addAnother: boolean) => Promise<void>
  setIsEditMode?: (isEditMode: boolean) => void
}> = ({ isOpen, onClose, editableTask, isEditMode, onSubmit, isLoading, projectImage }) => {
  const toast = useToast()
  const [task, setTask] = React.useState(() => editableTask || ({} as TaskDefinitionEntity))

  const onError = (error: any) =>
    toast({ title: `There was an error. -> ${error}`, ...ERROR_TOAST })
  const { data: storedCategories, loading: categoriesLoading } = useGetCategoriesQuery({
    onError,
    variables: {
      sort: ['title:asc'],
      pagination: {
        limit: 30
      }
    }
  })

  const [selectedCategory, setSelectedCategory] = React.useState('')

  const { data: storedTaskTypes, loading: storedTaskTypesLoading } = useGetProjectTaskTypesQuery({
    onError,
    variables: {
      filters: {
        status: { eq: Enum_Tasktype_Status.Active },
        category: { id: { eq: selectedCategory } }
      }
    }
  })

  React.useEffect(() => {
    if (editableTask?.attributes?.category?.data?.id) {
      setSelectedCategory(editableTask?.attributes?.category?.data?.id)
    }
  }, [editableTask])
  const close = () => {
    setTask({} as TaskDefinitionEntity)
    setSelectedCategory('')
    onClose()
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={addTaskSchema}
      initialValues={{
        category: editableTask ? editableTask?.attributes?.category?.data?.id : '',
        task_type: editableTask ? editableTask?.attributes?.task_type?.data?.id : '',
        status: Enum_Taskdefinition_Status.Deactivated,
        ...task,
        addAnother: false
      }}
      onSubmit={async ({ addAnother, ...values }, { resetForm }) => {
        await onSubmit(values, addAnother)
        resetForm()

        setTask({} as TaskDefinitionEntity)
      }}
    >
      {({ handleSubmit, setFieldValue, values, resetForm, isSubmitting, status }) => {
        const isActive = values.status === Enum_Taskdefinition_Status.Active

        return (
          <ModalWrap
            title={isEditMode ? 'Edit Task' : 'Add a Task'}
            isOpen={isOpen}
            image={projectImage}
            rightElement={
              <TaskSearcher
                setSelectedCategory={setSelectedCategory}
                setFieldValue={setFieldValue}
                values={values}
              />
            }
            onClose={() => {
              close()
              resetForm()
            }}
          >
            <Form onSubmit={handleSubmit}>
              <FloatingButtonWrapper
                isLoading={isSubmitting}
                status={status}
                button={
                  <Flex width="100%">
                    <SecondaryButton
                      mr={3}
                      isLoading={isLoading}
                      onClick={() => {
                        close()
                        resetForm()
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      isLoading={isLoading}
                      leftIcon={<VscAdd />}
                      width="100%"
                      type="submit"
                    >
                      {isEditMode ? 'Update' : 'Add'}
                    </PrimaryButton>
                  </Flex>
                }
              >
                <Text fontSize="xs">Please complete the fields below to add a Task</Text>

                <Flex py={6}>
                  <GridGenerator noPadding={true} cols={2}>
                    <ConnectedSelect
                      name="category"
                      label="Category *"
                      fontSize="xs"
                      placeholder="Select a Category"
                      showIsValidValue
                      loading={categoriesLoading}
                      onChange={async ({ target: { value } }) => {
                        setSelectedCategory(value)
                      }}
                      options={
                        (storedCategories?.categories?.data?.map((item) => {
                          return { label: item?.attributes?.title, value: item?.id }
                        }) as OptionType[]) || []
                      }
                    />

                    <ConnectedSelect
                      name="task_type"
                      label="Task *"
                      fontSize="xs"
                      placeholder="Select a Task"
                      showIsValidValue
                      loading={storedTaskTypesLoading}
                      options={
                        storedTaskTypes?.taskTypes?.data?.map((item) => {
                          return { label: item?.attributes?.title, value: item?.id }
                        }) as OptionType[]
                      }
                    />
                    <HStack>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="status" fontSize="xs" mb="0">
                          Status
                        </FormLabel>
                        <Switch
                          colorScheme="green"
                          id="status"
                          value={values.status}
                          isChecked={isActive}
                          defaultChecked={isActive}
                          onChange={(event) => {
                            setFieldValue(
                              'status',
                              event.target.checked
                                ? Enum_Tasktype_Status.Active
                                : Enum_Tasktype_Status.Deactivated
                            )
                          }}
                        />
                        <Text mx={2} fontSize="x-small" color={isActive ? 'green.400' : 'red.300'}>
                          {isActive ? 'On' : 'Off'}
                        </Text>
                      </FormControl>
                    </HStack>
                  </GridGenerator>
                </Flex>

                <ConnectedCheckbox name="addAnother" label="Add Another" />
              </FloatingButtonWrapper>
            </Form>
          </ModalWrap>
        )
      }}
    </Formik>
  )
}

export default AddTaskModal
