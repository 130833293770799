import { Flex, HStack, Heading, Skeleton } from '@chakra-ui/react'
import { GetBaoleaderTaskQuery } from 'generated/graphql'
import React from 'react'
import { VscArrowRight } from 'react-icons/vsc'

type ActionBarProps = {
  data: GetBaoleaderTaskQuery | undefined
  options?: React.ReactNode
  loading?: boolean
}

const ActionBar: React.FC<ActionBarProps> = ({ data, options, loading }) => {
  return (
    <Flex py={6} width="100%" fontSize="sm" align="center" justify="space-between">
      <Skeleton isLoaded={!loading} maxW="fit-content" minW={300}>
        <HStack spacing={4}>
          <Heading as="h3" size="xl" color="gray.400">
            Task
          </Heading>

          <VscArrowRight size={16} color="gray" />

          <Heading as="h3" size="lg" flex={1} color="gray.600">
            {data?.getBaoleaderTask?.id}
          </Heading>
        </HStack>
      </Skeleton>

      {options}
    </Flex>
  )
}

export default ActionBar
