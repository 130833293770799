import { Center, Spinner, Image } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { images } from 'theme'

type LoadingLogoProps = {}
const BlinkingImage = styled(Image)`
  animation: blink 1s ease-in-out 0s alternate infinite;
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
`
const LoadingLogo: React.FC<LoadingLogoProps> = ({}) => {
  return (
    <Center position="relative">
      <BlinkingImage w={130} src={images.logo} />
      <Spinner
        thickness="1px"
        speed="0.65s"
        emptyColor="gray.200"
        position="absolute"
        color="green.700"
        left={1.5}
        size="lg"
      />
    </Center>
  )
}

export default LoadingLogo
