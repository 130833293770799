import { FlexProps } from '@chakra-ui/react'
import { MotionFlex } from 'components'
import { MotionProps } from 'framer-motion'
import React from 'react'

type SliderAnimatorProps = FlexProps & MotionProps

const SliderAnimator: React.FC<SliderAnimatorProps> = ({ children, variants, ...rest }) => {
  return (
    <MotionFlex
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          x: '-350px',
          opacity: 0
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            type: 'spring',
            damping: 40
          }
        },
        ...variants
      }}
      {...rest}
    >
      {children}
    </MotionFlex>
  )
}

export default SliderAnimator
