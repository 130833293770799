import { GridItem, Center, Image, useMediaQuery, Grid } from '@chakra-ui/react'
import React from 'react'
import { EvidenceEntity, UploadFileEntity } from '../../../generated/graphql'
import { ImageProps } from '@chakra-ui/react'
import { getImageByFormat } from 'utils'

type PhotoGridProps = {
  evidence: EvidenceEntity
  onClick: (photo: UploadFileEntity, index: number) => void
} & Pick<ImageProps, 'objectFit'>

const PhotoGrid: React.FC<PhotoGridProps> = ({ evidence, onClick, ...rest }) => {
  const [isTabletOrMobile] = useMediaQuery('(max-width: 40em)')
  const morePics =
    evidence?.attributes?.photos?.data && evidence?.attributes?.photos?.data?.length > 0
      ? evidence?.attributes?.photos?.data?.length - 4
      : 0
  return (
    <Grid
      flex="1"
      overflow="hidden"
      templateRows="repeat(3, 1fr)"
      templateColumns={
        evidence?.attributes?.photos?.data && evidence?.attributes?.photos?.data?.length === 1
          ? '1fr'
          : 'repeat(4, 1fr)'
      }
      minW={{ base: 200, md: 400 }}
      minH={350}
      gap={2}
    >
      {evidence?.attributes?.photos?.data?.slice(0, isTabletOrMobile ? 1 : 4)?.map((photo, idx) => (
        <GridItem
          key={photo?.id}
          cursor="pointer"
          onClick={() => onClick(photo, idx)}
          position="relative"
          rowSpan={idx === 0 ? 3 : idx === 1 ? 2 : 1}
          colSpan={idx === 0 ? (isTabletOrMobile ? 4 : 2) : idx === 1 ? 2 : 1}
        >
          <Image
            src={
              evidence?.attributes?.photos?.data?.length === 1
                ? getImageByFormat(photo, 'medium') ||
                  getImageByFormat(photo, 'larger') ||
                  getImageByFormat(photo, 'small') ||
                  getImageByFormat(photo)
                : getImageByFormat(photo)
            }
            fallbackSrc={photo?.attributes?.formats?.thumbnail?.url}
            alt="baotree evidence, evidence image"
            draggable={false}
            position="absolute"
            borderRadius="3xl"
            top={0}
            h="100%"
            w="100%"
            flex="1"
            {...rest}
          />
          {idx === 3 && morePics > 0 && (
            <Center
              position="absolute"
              top={0}
              left={0}
              fontWeight="extrabold"
              h="100%"
              w="100%"
              fontSize="2xl"
              color="white"
              borderRadius="3xl"
              bg="#00000067"
            >
              +{morePics}
            </Center>
          )}
        </GridItem>
      ))}
    </Grid>
  )
}

export default PhotoGrid
