import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

type HProps = TextProps & { withBar?: boolean; noH?: boolean }

const H: React.FC<HProps> = ({ withBar, noH, ...rest }) => {
  return (
    <Text
      color="gray.400"
      position="relative"
      _before={{
        ...(withBar && {
          content: '""',
          position: 'absolute',
          bottom: '105%',
          left: '100%',
          borderRadius: 'md',
          height: '5px',
          minW: '400%',
          bg: 'green.300'
        })
      }}
      display="inline-flex"
      {...rest}
    >
      {!noH ? '#' : '- '}
    </Text>
  )
}
H.defaultProps = {
  withBar: false,
  noH: false
}
export default H
