import { HStack, Center, Select, Text, VStack } from '@chakra-ui/react'

import {
  Enum_Tasktype_Status,
  useGetTaskBuilderFilterCategoriesQuery,
  useGetTaskBuilderFilterCreatorsQuery
} from 'generated/graphql'
import React from 'react'

import { useAuthContext } from '../../../../context/AuthProvider/index'

type ListActionsProps = {
  setActiveCategory: (value: React.SetStateAction<string | undefined>) => void
  setActiveCreator: (value: React.SetStateAction<string | undefined>) => void
  setStatusFilter: React.Dispatch<React.SetStateAction<Enum_Tasktype_Status | undefined>>
}

const ListActions: React.FC<ListActionsProps> = ({
  setActiveCategory,
  setActiveCreator,
  setStatusFilter
}) => {
  const { data: storedDmpCategories } = useGetTaskBuilderFilterCategoriesQuery({
    variables: { sort: 'title:asc' }
  })
  const { data: storedDmpCreators } = useGetTaskBuilderFilterCreatorsQuery({
    variables: { sort: 'firstName:asc' }
  })
  const { user } = useAuthContext()
  const creators = storedDmpCreators?.getTaskBuilderFilterCreators
  const categories = storedDmpCategories?.getTaskBuilderFilterCategories
  return (
    <HStack alignItems="flex-end" spacing={4}>
      <OptionSelect
        label="Filter by Category"
        options={[
          { value: '', label: 'All' },
          ...(categories?.map((category) => ({
            value: category?.id || '',
            label: category?.attributes?.title || ''
          })) || [])
        ]}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setActiveCategory(e?.target?.value || undefined)
        }}
      />
      <OptionSelect
        label="Filter by Creator"
        options={[
          { value: '', label: 'All' },
          ...(creators?.map((creator) => ({
            value: creator?.id || '',
            label:
              creator?.id == user?.id
                ? `${creator?.attributes?.firstName} (Me)`
                : `${creator?.attributes?.firstName} ${creator?.attributes?.lastName}`
          })) || [])
        ]}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setActiveCreator(e?.target?.value || undefined)
        }}
      />

      <OptionSelect
        label="Filter by status"
        options={[
          { value: '', label: 'All' },
          { value: Enum_Tasktype_Status.Active, label: 'Active' },
          { value: Enum_Tasktype_Status.Deactivated, label: 'Deactivated' }
        ]}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setStatusFilter((e.target.value as Enum_Tasktype_Status) || undefined)
        }
      />
    </HStack>
  )
}

const OptionSelect: React.FC<{
  options: { value: string; label: string }[]
  label: string
  icon?: React.ReactNode
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined
}> = ({ options, onChange, label, icon }) => (
  <VStack alignItems="flex-start" spacing={0}>
    <Text color="gray.500" fontWeight="bold" fontSize="x-small">
      {label}
    </Text>
    <Center flex={1}>
      {icon}
      <Select
        variant="filled"
        maxW={100}
        ml={icon ? 2 : 0}
        focusBorderColor="green.200"
        fontSize="x-small"
        onChange={onChange}
      >
        {options.map(({ value, label }, index) => (
          <option key={index + value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Center>
  </VStack>
)

export default ListActions
