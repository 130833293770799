import { Box, Container, Flex, Heading, useToast, Image } from '@chakra-ui/react'
import { BackButton, TableIcon } from 'components'
import React from 'react'
import { FaUsers } from 'react-icons/fa'
import { VscAdd } from 'react-icons/vsc'
import { useHistory, useLocation } from 'react-router-dom'

import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import {
  CommunityInput,
  useCreateCommunityMutation,
  Community,
  Enum_Community_Type
} from '../../../generated/graphql'
import { PageWrap } from '../../../layouts'

import { images } from 'theme'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'
import { CreateCommunityForm } from '../components'
import posthog from 'posthog-js'
import { createGroupButtonHelperPopover, createGroupHelperPopover } from './help'

const CreateCommunity: React.FC = () => {
  const toast = useToast()
  const history = useHistory()
  const location = useLocation<{ from: string }>()
  const [createCommunity, { loading: createLoading }] = useCreateCommunityMutation({
    onCompleted: () => {
      toast({ title: 'Group created.', ...SUCCESS_TOAST })
      posthog.capture('dashboard_community_created')
      if (location?.state?.from) {
        history.push({
          pathname: location?.state?.from,
          state: {
            refetchCommunities: true
          }
        })
      } else {
        history.push('/auth/communities')
      }
    },
    onError: () => {
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    }
  })
  const submitForm = async (values: Community) => {
    await createCommunity({
      variables: {
        input: {
          ...values,
          featured_image: (values?.featured_image as any)?.id || null
        } as CommunityInput
      }
    })
  }

  return (
    <PageWrap direction="column" title="Create Group" align="center" p={0} m={0}>
      <Container my={8} maxW="1600" px={16}>
        <Flex>
          <BackButton title=" Back to the list of Groups" />
        </Flex>
      </Container>
      <Box bg="background.100" pb={24} width="100%">
        <Container maxW="1600" px={16} pt={10} position="relative">
          <TableIcon position="right" icon={<FaUsers />} />
          <Flex direction="column" flex={1} width="100%">
            <Flex direction="column" flex={1}>
              <Flex align="center">
                <Heading color="gray.600">Create Group</Heading>
                {createGroupHelperPopover}
              </Flex>

              <Flex mt={10}>
                <CreateCommunityForm
                  actionBar={(dirty) => (
                    <Flex direction="row" justify="flex-end" align="center" w="100%">
                      <SecondaryButton
                        onClick={() => {
                          if (location?.state?.from) {
                            history.push(location?.state?.from)
                          } else {
                            history.push('/auth/communities')
                          }
                        }}
                        isLoading={createLoading}
                        mr={3}
                      >
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        leftIcon={<VscAdd />}
                        width="100%"
                        type="submit"
                        isDisabled={!dirty}
                        isLoading={createLoading}
                      >
                        Create
                      </PrimaryButton>
                      {createGroupButtonHelperPopover}
                    </Flex>
                  )}
                  submitForm={submitForm}
                  initialValues={{
                    name: '',
                    chief_name: '',
                    type: Enum_Community_Type.Community,
                    description: '',
                    featured_image: null
                  }}
                />
                <Flex justify="center" flex={1}>
                  <Image
                    borderRadius="full"
                    objectFit="contain"
                    draggable="false"
                    src={images.logo}
                    boxSize="40%"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </PageWrap>
  )
}

export default CreateCommunity
