import { SimpleGrid } from '@chakra-ui/react'
import { Maybe, ProjectEntity } from 'generated/graphql'
import React from 'react'
import ProjectCard from '../ProjectCard'

type ProjectsGridProps = {
  projets: Maybe<ProjectEntity>[]
}

const ProjectsGrid: React.FC<ProjectsGridProps> = ({ projets }) => {
  return (
    <SimpleGrid
      columnGap={6}
      rowGap={20}
      templateColumns={{
        lg: 'repeat(4, 1fr)',
        md: 'repeat(3, 1fr)',
        sm: 'repeat(2, 1fr)',
        base: 'repeat(1, 1fr)'
      }}
    >
      {projets?.map((projectSet, index) => (
        <ProjectCard key={index} projectSet={projectSet as ProjectEntity} />
      ))}
    </SimpleGrid>
  )
}

export default ProjectsGrid
