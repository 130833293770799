import { Widget } from '@typeform/embed-react'
import { PrimaryButton } from 'components/UI/Buttons'
import { SUBSCRIPTIONS_TYPEFORM_ID } from '../../../constants'
import React from 'react'

import { Text, Box } from '@chakra-ui/react'
import { VscArrowLeft } from 'react-icons/vsc'
type ContactFromProps = {
  showWidget: boolean
  setShowWidget: React.Dispatch<React.SetStateAction<boolean>>
}
const ContactFrom: React.FC<ContactFromProps> = ({ showWidget, setShowWidget }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <Box h="100%" w={showWidget ? '100%' : '50%'} alignSelf="flex-start">
      {!showWidget && (
        <Text fontSize="sm">
          Need support with your subscription? Get in touch with our Sales team.
        </Text>
      )}
      <PrimaryButton
        minW={200}
        leftIcon={showWidget ? <VscArrowLeft /> : <></>}
        onClick={() => {
          setShowWidget(!showWidget)
          setIsLoading(!showWidget)
        }}
        isLoading={isLoading}
        my={2}
      >
        {showWidget ? 'Back' : 'Get in touch'}
      </PrimaryButton>
      {showWidget && (
        <Widget
          id={SUBSCRIPTIONS_TYPEFORM_ID}
          style={{ width: '100%', height: '55vh' }}
          onReady={() => {
            setIsLoading(false)
          }}
          enableSandbox
        />
      )}
    </Box>
  )
}

export default ContactFrom
