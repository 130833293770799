import React from 'react'
import { useResendDataCollectorInviteMutation } from 'generated/graphql'
import { Flex, HStack, Skeleton, Spinner, Text } from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'
import { dataCollectorsProfileHelperPopover } from '../help'

type ResendInviteProps = {
  loading: boolean
  storedBaoriderId: string
}

const ResendInvite: React.FC<ResendInviteProps> = ({ loading, storedBaoriderId }) => {
  const [status, setStatus] = React.useState<'sent' | 'not sent' | ''>('')
  const [bufferTimeCount, setBufferTimeCount] = React.useState<number>(0)

  React.useEffect(() => {
    if (bufferTimeCount > 0) {
      const interval = setInterval(() => {
        setBufferTimeCount(bufferTimeCount - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [bufferTimeCount])

  const [resendDataCollectorInvite, { loading: resendingDataCollectorInvite }] =
    useResendDataCollectorInviteMutation({
      onCompleted: () => {
        setStatus('sent')
        setBufferTimeCount(60)
      },
      onError: () => setStatus('not sent')
    })
  const handleResendInvite = async () => {
    await resendDataCollectorInvite({
      variables: {
        id: storedBaoriderId
      }
    })
  }

  const doneCountdown = bufferTimeCount === 0

  return (
    <Flex align="center">
      <Skeleton isLoaded={!loading}>
        <Text fontSize="xs" color="gray.500">
          Didn&apos;t recieve the invite?{' '}
          {resendingDataCollectorInvite ? (
            <Spinner size="xs" mx={2} />
          ) : doneCountdown ? (
            <Text
              as="span"
              color="brand.400"
              cursor="pointer"
              fontWeight="semibold"
              _hover={{
                textDecoration: 'underline'
              }}
              onClick={handleResendInvite}
            >
              Resend invite
            </Text>
          ) : (
            <Text as="span" fontStyle="italic" mx={2} color="gray.700">
              Resend invite in {bufferTimeCount} seconds
            </Text>
          )}
        </Text>
        {status === 'sent' && !doneCountdown ? (
          <HStack fontSize="xs" fontWeight="semibold" spacing={1} color="green.400">
            <MdCheckCircle size={16} />
            <Text>Invite sent</Text>
          </HStack>
        ) : null}
        {status === 'not sent' && !doneCountdown ? (
          <Text fontSize="xs" color="red.400">
            Invite not sent
          </Text>
        ) : null}
      </Skeleton>
      {dataCollectorsProfileHelperPopover}
    </Flex>
  )
}

export default ResendInvite
