import { VStack, Center, Spinner, HStack, Text, Box, Image, useToast } from '@chakra-ui/react'
import { Pagination, SearchBar } from 'components'
import React from 'react'

import { images } from 'theme'
import {
  Enum_Tasktype_Status,
  useCountTaskTypesQuery,
  useGetWholeCompanyTaskTypesQuery
} from '../../../generated/graphql'
import { ERROR_TOAST } from '../../../constants'

type TaskSearcherProps = {
  values: any
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const TaskSearcher: React.FC<TaskSearcherProps> = ({
  setSelectedCategory,
  setFieldValue,
  values
}) => {
  const toast = useToast()
  const onError = (error: any) =>
    toast({ title: `There was an error. -> ${error}`, ...ERROR_TOAST })

  const [searchTerm, setSearchTerm] = React.useState<string | undefined>(undefined)
  const [limit, setLimit] = React.useState(8)
  const [start, setStart] = React.useState(0)
  const { data: taskTypes, loading } = useGetWholeCompanyTaskTypesQuery({
    onError,
    variables: {
      filters: { title: { $contains: searchTerm }, status: Enum_Tasktype_Status.Active },
      pagination: {
        limit,
        start
      }
    }
  })
  const { data: taskTypesCount } = useCountTaskTypesQuery({
    onError,
    variables: {
      filters: { title: { $contains: searchTerm } }
    }
  })
  const count = taskTypesCount?.getCompanyTaskTypesCount || 0

  return (
    <VStack h="100%" alignItems="flex-start" flex={1.1} spacing={0} pb={8}>
      <SearchBar
        minW="160px"
        placeholder="Search for a task type"
        isLoading={loading}
        onSearch={(value) => {
          setSearchTerm(value)
        }}
      />
      <Box w="100%">
        {loading ? (
          <Center py={4}>
            <Spinner color="green.200" size="sm" />
          </Center>
        ) : (
          taskTypes?.getWholeCompanyTaskTypes?.map((taskType) => {
            const isActive = values?.task_type === taskType?.id
            return (
              <HStack
                spacing={3}
                _hover={{ bg: 'gray.50' }}
                cursor="pointer"
                transition="all .4s ease-in-out 0s"
                p={2}
                border="1px solid"
                borderColor={isActive ? 'green.200' : 'transparent'}
                bg={isActive ? 'green.50' : 'transparent'}
                onClick={() => {
                  setSelectedCategory(taskType?.attributes?.category?.data?.id || '')
                  setFieldValue('category', taskType?.attributes?.category?.data?.id)
                  setFieldValue('task_type', taskType?.id)
                }}
                key={taskType?.id}
              >
                <Image
                  src={
                    taskType?.attributes?.category?.data?.attributes?.featureImage?.data?.attributes
                      ?.formats?.thumbnail?.url || images.logoNoText
                  }
                  boxSize={8}
                  objectFit="cover"
                  rounded="full"
                />
                <VStack spacing={0} alignItems="flex-start">
                  <Text fontSize="xs" fontWeight="semibold">
                    {taskType?.attributes?.title}
                  </Text>
                  <Text fontSize="x-small">
                    {taskType?.attributes?.category?.data?.attributes?.title}
                  </Text>
                </VStack>
              </HStack>
            )
          })
        )}
      </Box>
      <Pagination
        setLimit={setLimit}
        start={start}
        setStart={setStart}
        count={count}
        limit={limit}
        pageRangeDisplayed={2}
        paginationEntries={[4, 8, 16]}
      />
    </VStack>
  )
}

export default TaskSearcher
