import { useToast, HStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { VscAdd } from 'react-icons/vsc'
import { useLocation } from 'react-router-dom'
import { AlertIndicator, ModalWrap } from '../../../components'
import { ConnectedFormGroup } from '../../../components/forms/FormElements'
import { ERROR_TOAST, LEADER } from '../../../constants'
import { useGetCommunitiesQuery, UsersPermissionsUser } from '../../../generated/graphql'
import { addBaoleaderSchema } from '../../../utils/validationSchemas'
import { FloatingButtonWrapper } from 'components'
import { ConnectedCheckbox } from 'components/forms/FormElements'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'

const AddUserModal: React.FC<{
  isOpen: boolean
  loading?: boolean
  additionalCost: number
  teamMembersRemaining: number
  onOpen: () => void
  onClose: () => void
  onSubmit: (
    baorider: UsersPermissionsUser & { community: string; title: string },
    addAnother: boolean
  ) => Promise<any>
}> = ({ isOpen, onClose, onSubmit, loading, additionalCost, teamMembersRemaining }) => {
  const toast = useToast()
  const location = useLocation<{ refetchCommunities?: boolean }>()

  const onError = () => {
    toast({ title: 'There was an error.', ...ERROR_TOAST })
  }
  const { refetch } = useGetCommunitiesQuery({
    onError
  })
  React.useEffect(() => {
    if (location?.state?.refetchCommunities) {
      refetch()
    }
  }, [location, refetch])
  const submitForm = async ({ addAnother, hasConsent, ...values }: any, { resetForm }: any) => {
    if (!hasConsent) {
      toast({ title: 'Consent from Baorider is required.', ...ERROR_TOAST })
      return
    }
    try {
      const user = await onSubmit(values, addAnother)

      if (user?.data) {
        resetForm({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          community: '',
          hasConsent: false
        })
        if (!addAnother) {
          onClose()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ModalWrap title="Add User" isOpen={isOpen} onClose={onClose}>
      {teamMembersRemaining === 1 && (
        <AlertIndicator status="info">
          You can add {teamMembersRemaining} more {LEADER.toLowerCase()}s to your account on your
          current subscription plan. Additional users after that will cost £{additionalCost} per
          user, per month.
        </AlertIndicator>
      )}
      {teamMembersRemaining < 0 && (
        <AlertIndicator status="info">
          Adding a new data collector will add an additional cost of £{additionalCost} to your
          current plan per month.
        </AlertIndicator>
      )}
      <Formik
        enableReinitialize={true}
        validationSchema={addBaoleaderSchema}
        initialValues={{
          role: 'ADMIN',
          hasConsent: false,
          addAnother: false
        }}
        onSubmit={submitForm}
      >
        {({ handleSubmit, status }) => (
          <Form onSubmit={handleSubmit}>
            <FloatingButtonWrapper
              status={status}
              button={
                <HStack width="100%">
                  <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    width="100%"
                    isLoading={loading}
                    leftIcon={<VscAdd />}
                  >
                    Add
                  </PrimaryButton>
                </HStack>
              }
            >
              <HStack mb={2} spacing={2}>
                <ConnectedFormGroup
                  name="firstName"
                  label="First name *"
                  placeholder="Enter first name"
                  w="100%"
                  mb={0}
                />
                <ConnectedFormGroup
                  name="lastName"
                  label="Last name *"
                  placeholder="Enter last name"
                  w="100%"
                  mb={0}
                />
              </HStack>
              <ConnectedFormGroup name="title" label="Title *" placeholder="Enter title" />
              <ConnectedFormGroup name="email" label="E-mail *" placeholder="Enter email" />
              <ConnectedFormGroup
                name="role"
                label="Role *"
                fontSize="sm"
                placeholder="Enter email"
                isDisabled
              />
              <ConnectedCheckbox
                name="hasConsent"
                mb={2}
                label="You have been given permission to upload these details."
              />
              <ConnectedCheckbox name="addAnother" label="Add Another" />
            </FloatingButtonWrapper>
          </Form>
        )}
      </Formik>
    </ModalWrap>
  )
}

export default AddUserModal
