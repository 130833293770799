import { DATA_COLLECTOR } from '../constants'
import { lazy } from 'react'
import { RouteProps } from 'react-router'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
  hidePageWrap?: boolean
}

const Login = lazy(() => import('../containers/Login'))
const ConfirmEmail = lazy(() => import('../containers/ConfirmEmail'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const Tasks = lazy(() => import('../containers/Tasks'))
const CreateTaskType = lazy(() => import('../containers/CreateTaskType'))
const SingleTask = lazy(() => import('../containers/TaskDetails'))
const TaskBuilder = lazy(() => import('../containers/TaskBuilder'))
const TaskTypeDetails = lazy(() => import('../containers/TaskTypeDetails'))
const Projects = lazy(() => import('../containers/Projects'))
const CreateProject = lazy(() => import('../containers/CreateProject'))
const EditProject = lazy(() => import('../containers/EditProject'))
const ProjectDetails = lazy(() => import('../containers/ProjectDetails'))
const Communities = lazy(() => import('../containers/Communities'))
const CommunityDetails = lazy(() => import('../containers/CommunityDetails'))
const CommunityCreate = lazy(() => import('../containers/CreateCommunity'))
const CommunityEdit = lazy(() => import('../containers/CommunityEdit'))
const CommunityMemberProfile = lazy(() => import('../containers/CommunityMemberProfile'))
const Settings = lazy(() => import('../containers/Settings'))
const Help = lazy(() => import('../containers/Help'))

const BaoriderProfile = lazy(() => import('../containers/DataCollectorsDetails'))

const Baoriders = lazy(() => import('../containers/DataCollectors'))

// Onboarding Routes
const Register = lazy(() => import('../containers/Register'))
const OnboardingLandingPage = lazy(() => import('../containers/OnboardingLandingPage'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/auth/help',
    breadcrumb: 'Help',
    component: Help,
    title: 'Help'
  },
  {
    exact: true,
    path: '/auth/',
    breadcrumb: 'Tasks',
    component: Tasks,
    title: 'Tasks'
  },
  {
    exact: true,
    path: '/auth/tasks',
    breadcrumb: 'Tasks',
    component: Tasks,
    title: 'Tasks'
  },
  {
    exact: true,
    path: '/auth/task-types-list/create-task',
    breadcrumb: 'Tasks',
    component: CreateTaskType,
    title: 'Tasks'
  },
  {
    exact: true,
    path: '/auth/task-types-list/detail/:id',
    breadcrumb: 'Task',
    component: TaskTypeDetails,
    title: 'Task'
  },
  {
    exact: true,
    path: '/auth/task-types-list',
    breadcrumb: 'task-builder',
    component: TaskBuilder,
    title: 'Tasks Types'
  },
  {
    exact: true,
    path: '/auth/tasks/:id',
    breadcrumb: 'Task-Details',
    component: SingleTask,
    title: 'Task'
  },
  {
    exact: true,
    path: '/auth/create-project',
    breadcrumb: 'New Project',
    component: CreateProject,
    title: 'Create Project'
  },
  {
    exact: true,
    path: '/auth/copy-project/:id',
    breadcrumb: 'Copy Project',
    component: CreateProject,
    title: 'Copy Project'
  },
  {
    exact: true,
    path: '/auth/project/:id',
    breadcrumb: 'Edit Project',
    component: EditProject,
    title: 'Edit Project'
  },
  {
    exact: true,
    path: '/auth/projects/:id',
    breadcrumb: 'Project Details',
    component: ProjectDetails,
    title: 'Project Details'
  },
  {
    exact: true,
    path: '/auth/projects',
    breadcrumb: 'Projects',
    component: Projects,
    title: 'Projects'
  },
  {
    exact: true,
    path: '/auth/communities',
    breadcrumb: 'Communities',
    component: Communities,
    title: 'Communities'
  },
  {
    exact: true,
    path: '/auth/create-community',
    breadcrumb: 'Create Group',
    component: CommunityCreate,
    title: 'Create Group'
  },
  {
    exact: true,
    path: '/auth/communities/:id',
    breadcrumb: 'Community Details',
    component: CommunityDetails,
    title: 'Community Details'
  },
  {
    exact: true,
    path: '/auth/edit-community/:id',
    breadcrumb: 'Edit Community',
    component: CommunityEdit,
    title: 'Edit Community'
  },
  {
    exact: true,
    path: '/auth/community-member/:communityId/:id',
    breadcrumb: 'Community Member Details',
    component: CommunityMemberProfile,
    title: 'Community Member'
  },
  {
    exact: true,
    path: '/auth/baoriders/:id',
    breadcrumb: DATA_COLLECTOR + ' Details',
    component: BaoriderProfile,
    title: DATA_COLLECTOR
  },
  {
    exact: true,
    path: '/auth/baoriders',
    breadcrumb: DATA_COLLECTOR + 's',
    component: Baoriders,
    title: DATA_COLLECTOR + 's'
  },
  {
    exact: true,
    path: '/auth/settings',
    breadcrumb: 'Settings',
    component: Settings,
    title: 'Settings'
  },
  {
    exact: true,
    path: '/auth/onboarding',
    breadcrumb: 'Onboarding',
    component: OnboardingLandingPage,
    title: 'Onboarding'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'Login',
    path: '/login',
    component: Login
  },
  {
    exact: true,
    title: 'Confirm Email',
    path: '/confirm-email',
    component: ConfirmEmail
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    exact: true,
    title: 'Register',
    path: '/register',
    component: Register
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
