import React from 'react'
import { VscListFilter, VscStarFull } from 'react-icons/vsc'
import { BsPercent } from 'react-icons/bs'
import { TiSortAlphabetically } from 'react-icons/ti'
import { SupportedDataFieldsType } from '../types'
import { FaRuler } from 'react-icons/fa'

import {
  FcCheckmark,
  FcFeedback,
  FcList,
  FcNumericalSorting12,
  FcNumericalSorting21,
  FcOk,
  FcOvertime,
  FcPhone,
  FcPicture
} from 'react-icons/fc'

export const Fields: React.FC = () => <></>

export const SUSPENDED_TASK_TYPES = 'suspended_task_types'
const ICON_SIZE = 15

export const SupportedDataFields: SupportedDataFieldsType = {
  CheckBox: {
    properties: [{ label: '' }, { option: [] }, { isRequired: false }],
    icon: <FcCheckmark size={ICON_SIZE} />,
    __component: 'custom-data-fields.checkbox',
    display_title: 'Checkbox'
  },
  Radio: {
    properties: [{ label: '' }, { option: [] }, { isRequired: false }],
    icon: <FcOk size={ICON_SIZE} />,
    __component: 'custom-data-fields.radio',
    display_title: 'Radio'
  },
  Select: {
    properties: [{ label: '' }, { option: [] }, { isRequired: false }],
    icon: <VscListFilter size={ICON_SIZE} />,
    __component: 'custom-data-fields.select',
    display_title: 'Select'
  },

  MetricSpecific: {
    properties: [{ label: '' }, { metric: '' }, { isRequired: false }],
    icon: <FaRuler size={ICON_SIZE} />,
    __component: 'custom-data-fields.metrics-specific',
    display_title: 'Metric Specific'
  },
  Text: {
    properties: [{ label: '' }, { isRequired: false }],
    icon: <TiSortAlphabetically size={ICON_SIZE} />,
    __component: 'custom-data-fields.text',
    display_title: 'Text'
  },

  NumberRange: {
    properties: [{ label: '' }, { min: 0 }, { max: 10 }, { isRequired: false }],
    icon: <FcNumericalSorting21 size={ICON_SIZE} />,
    __component: 'custom-data-fields.number-range',
    display_title: 'Number Range'
  },
  Percentage: {
    properties: [{ label: '' }, { isRequired: false }],
    icon: <BsPercent size={ICON_SIZE} />,
    __component: 'custom-data-fields.percentage',
    display_title: 'Percentage'
  },
  Number: {
    properties: [{ label: '' }, { isRequired: false }],
    icon: <FcNumericalSorting12 size={ICON_SIZE} />,
    __component: 'custom-data-fields.number-field',
    display_title: 'Number'
  },
  Rating: {
    properties: [{ label: '' }, { number_of_stars: 5 }, { isRequired: false }],
    icon: <VscStarFull size={ICON_SIZE} />,
    __component: 'custom-data-fields.rating',
    display_title: 'Rating'
  },
  DateTime: {
    properties: [{ label: '' }, { isRequired: false }],
    icon: <FcOvertime size={ICON_SIZE} />,
    __component: 'custom-data-fields.date-time-field',
    display_title: 'DateTime'
  },
  DataList: {
    properties: [{ label: '' }, { listId: '' }, { isRequired: false }],
    icon: <FcList size={ICON_SIZE} />,
    __component: 'custom-data-fields.data-list',
    display_title: 'Data List'
  },
  PhoneNumberField: {
    properties: [{ label: '' }, { isRequired: false }],
    icon: <FcPhone size={ICON_SIZE} />,
    __component: 'custom-data-fields.phone-number-field',
    display_title: 'Phone Number'
  },
  EmailAddress: {
    properties: [{ label: '' }, { isRequired: false }],
    icon: <FcFeedback size={ICON_SIZE} />,
    __component: 'custom-data-fields.email-field',
    display_title: 'Email Address'
  },
  ImageField: {
    properties: [{ label: '' }, { isRequired: false }],
    icon: <FcPicture size={ICON_SIZE} />,
    __component: 'custom-data-fields.image-field',
    display_title: 'Photo Field'
  }
}
