import { HStack, Center, Text, Tooltip, Icon } from '@chakra-ui/react'
import { ConnectedRangeDatePicker } from 'components/forms/FormElements'
import SearchBar from 'components/forms/SearchBar'
import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons'
import { useFormikContext } from 'formik'
import { useExportExcelMutation } from 'generated/graphql'
import { Moment } from 'moment'
import posthog from 'posthog-js'
import React, { memo } from 'react'

import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import { FiSliders } from 'react-icons/fi'
import { VscCloudDownload } from 'react-icons/vsc'
import strapiHelpers from 'utils/strapiHelpers'
import { capitalizeEachWord } from 'features/datacollectors/utils'
import { excelTooltipContent } from '../help'

type TableActionsProps = {
  onSearch?: (searchValue: string) => void
  searchValue?: string
  filtersCount: number
  filters: any
  reset: () => Promise<void>
  onToggleShowFilters: () => void
  showFilters: boolean
  filtersTitle?: string
}

const TableActions: React.FC<TableActionsProps> = ({
  onSearch,
  searchValue,
  filtersCount,
  reset,
  filters,
  onToggleShowFilters,
  showFilters,
  filtersTitle
}) => {
  const [exportingExcel, setExportingExcel] = React.useState(false)
  const [exportExcel] = useExportExcelMutation({
    onError: (error) => {
      console.log('error', error)
    }
  })
  const progress = React.useRef(0)
  const exportExcelHandler = React.useCallback(async () => {
    setExportingExcel(true)

    const chunk = await exportExcel({
      variables: {
        filterValues: filters
      }
    })

    const downloaded = await strapiHelpers.downloadExcel(chunk?.data?.exportExcel)

    if (downloaded) {
      setExportingExcel(false)
      progress.current = 0
    }
    posthog.capture('dashboard_export_excel')
  }, [exportExcel, filters, setExportingExcel])

  const { setFieldValue, submitForm, isSubmitting, resetForm } = useFormikContext()

  const tooltipTrigger = (
    <HStack>
      <PrimaryButton
        mr={3}
        leftIcon={<VscCloudDownload />}
        isLoading={isSubmitting || exportingExcel}
        onClick={exportExcelHandler}
      >
        Export Excel
      </PrimaryButton>
    </HStack>
  )

  return (
    <HStack
      key={Math.floor(Math.random() * 1000)}
      width="100%"
      spacing={3}
      justifyContent="flex-end"
      fontSize="sm"
    >
      <HStack align="center" spacing={3} flex={1}>
        {onSearch && (
          <SearchBar
            onSearch={onSearch}
            placeholder="Search Task By ID ..."
            searchValue={searchValue}
          />
        )}
        <ConnectedRangeDatePicker
          placeholder="Please select date"
          name="date_range"
          mb={0}
          onChange={({
            target: { value }
          }: React.ChangeEvent<HTMLInputElement & { value: Moment[] }>) => {
            const dates: Moment[] = value

            if (dates.length === 2) {
              setFieldValue('date_range', [
                value[0].startOf('day').toISOString(),
                value[1].endOf('day').toISOString()
              ])

              submitForm()
            }
          }}
        />
      </HStack>
      <Tooltip
        bg={'white'}
        textColor={'blackAlpha.900'}
        label={excelTooltipContent}
        hasArrow
        placement="top"
      >
        {tooltipTrigger}
      </Tooltip>
      <SecondaryButton
        mr={3}
        isLoading={isSubmitting}
        onClick={() => {
          reset()
          resetForm()
        }}
      >
        Reset
      </SecondaryButton>
      <SecondaryButton leftIcon={<FiSliders />} mr={2} onClick={onToggleShowFilters}>
        {filtersCount}
      </SecondaryButton>
      <Center
        cursor="pointer"
        onClick={onToggleShowFilters}
        mr={3}
        fontSize="md"
        fontWeight="semibold"
      >
        <Text fontSize="xs" mr={2}>
          {capitalizeEachWord(filtersTitle) || 'Expand'}{' '}
        </Text>{' '}
        <Icon as={showFilters ? FaChevronUp : FaChevronDown} fontSize="xs" />
      </Center>
    </HStack>
  )
}

export default memo(TableActions)
