import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Text,
  useToast
} from '@chakra-ui/react'

import React, { useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { BackButton, AlertModal, FloatingCard } from '../../../components'

import { DATA_COLLECTOR, ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import {
  Enum_Userspermissionsuser_Status,
  useCountTasksQuery,
  useGetSingleBaoriderQuery,
  useUpdateBaoriderMutation
} from '../../../generated/graphql'
import { CustomTabContainer, PageWrap } from '../../../layouts'
import { CommunityMember } from '../../../utils/types'

import { VStack } from '@chakra-ui/react'

import { pluralHandler } from 'utils'

import DataCollectorsDetailsForm from '../components/DataCollectorsDetailsForm/index'
import { FcAbout, FcElectricalSensor, FcTodoList } from 'react-icons/fc'
import ResendInvite from './components/ResendInvite'
import Tasks from './components/Tasks'

const DataCollectorsDetails = () => {
  const { id } = useParams<{ id: string }>()
  const toast = useToast()

  const history = useHistory()
  const onError = (response: any) => {
    const errorMessage = response.message
    toast({ title: errorMessage, ...ERROR_TOAST })
  }
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] = useState(false)
  const [showReactivateConfirmation, setShowReactivateConfirmation] = useState(false)
  const [showRemoveFromProject, setShowRemoveFromProjectCon] = useState(false)
  const [currentProject, setCurrentProject] = useState<string | null>(null)

  const {
    data: storedBaorider,
    refetch: refetchBaorider,
    loading
  } = useGetSingleBaoriderQuery({
    variables: { id },
    onError
  })
  const { data: tasksCount } = useCountTasksQuery({
    variables: {
      filters: {
        baorider: { id }
      }
    }
  })

  const [updateBaorider] = useUpdateBaoriderMutation({
    onCompleted: () => {
      toast({ title: DATA_COLLECTOR + ' updated.', ...SUCCESS_TOAST })
    },
    onError
  })

  const submitForm = async (values: CommunityMember) => {
    await updateBaorider({
      variables: {
        id,
        baorider: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          phoneNumber: values?.phoneNumber,
          gender: values?.gender,
          idNumber: values?.idNumber,
          community: values?.community || (null as any)
        }
      }
    })
    await refetchBaorider()
  }
  const deactivateUser = async () => {
    await updateBaorider({
      variables: {
        id,
        baorider: {
          status: Enum_Userspermissionsuser_Status.Archived,
          blocked: true
        }
      }
    })
    await refetchBaorider({
      id
    })
  }

  const reactivateUser = async () => {
    await updateBaorider({
      variables: {
        id,
        baorider: {
          status: Enum_Userspermissionsuser_Status.Active,
          blocked: false
        }
      }
    })
    await refetchBaorider({
      id
    })
  }

  const removeUserFromProject = async (projectId: string) => {
    await updateBaorider({
      variables: {
        id,
        baorider: {
          projects: ((storedBaorider?.getSingleBaorider?.baorider?.attributes?.projects?.data || [])
            .filter((item) => item?.id !== projectId)
            .map((item) => item?.id) || []) as string[]
        }
      }
    })

    await refetchBaorider()
  }

  const fullName = `${storedBaorider?.getSingleBaorider?.baorider?.attributes?.firstName} ${storedBaorider?.getSingleBaorider?.baorider?.attributes?.lastName}`

  const tabs = React.useMemo(
    () => [
      {
        title: 'Details',
        icon: <FcAbout />,
        loading,
        bg: 'background.100'
      },
      {
        title: 'Tasks',
        icon: <FcTodoList />,
        loading,
        bg: 'background.50'
      }
    ],
    [loading]
  )
  const tabPanels = React.useMemo(
    () => [
      {
        component: (
          <DataCollectorsDetailsForm
            storedBaorider={{
              ...(storedBaorider?.getSingleBaorider?.baorider?.attributes as CommunityMember),
              community:
                storedBaorider?.getSingleBaorider?.baorider?.attributes?.community?.data?.id || ''
            }}
            removeProject={(projectId) => {
              setShowRemoveFromProjectCon(true)
              setCurrentProject(projectId)
            }}
            addProject={(projectId) => {
              setCurrentProject(projectId)
            }}
            callBack={async () => {
              await refetchBaorider()
            }}
            loading={loading}
            submitForm={submitForm}
            setShowDeactivateConfirmation={setShowDeactivateConfirmation}
            setShowReactivateConfirmation={setShowReactivateConfirmation}
          />
        )
      },
      {
        component: <Tasks />
      }
    ],
    [
      loading,
      setShowDeactivateConfirmation,
      setShowReactivateConfirmation,
      setShowRemoveFromProjectCon,
      submitForm
    ]
  )

  const [tabIndex, setTabIndex] = React.useState(0)
  const bg = tabs.map((tab) => tab.bg)[tabIndex]
  if (!loading && !storedBaorider?.getSingleBaorider?.baorider?.attributes)
    history.push('/auth/baoriders')
  return (
    <PageWrap direction="column" title="Create Project" align="center" p={0} m={0}>
      <Container my={8} maxW="1600" px={16}>
        <Flex>
          <BackButton title={` Back to the list of ${DATA_COLLECTOR}s`} />
        </Flex>
      </Container>
      <Box bg={bg} pb={24} w="100%">
        <Container maxW="1600" px={16} pt={10} position="relative">
          <HStack
            spacing={5}
            position="absolute"
            top={0}
            transform="translateY(-60%)"
            mr={14}
            right={0}
          >
            <FloatingCard
              icon={<FcElectricalSensor fontSize={16} />}
              title="Tasks Reported"
              loading={loading}
              value={`${tasksCount?.countTasks || 0} ${pluralHandler(
                'task',
                tasksCount?.countTasks || 0
              )} reported`}
            />
          </HStack>
          <Flex direction="column" my={6} w="100%">
            <Flex align="center">
              <Skeleton isLoaded={!loading} borderRadius="full">
                <Avatar
                  size="md"
                  src={
                    storedBaorider?.getSingleBaorider?.baorider?.attributes?.profilePicture?.data
                      ?.attributes?.formats?.small?.url
                  }
                  name={fullName}
                />
              </Skeleton>
              <VStack align="flex-start" spacing={0} ml={4}>
                <Skeleton isLoaded={!loading}>
                  <Heading
                    as="h3"
                    size="lg"
                    color="gray.600"
                    fontWeight="semibold"
                    textAlign="left"
                  >
                    {fullName}
                  </Heading>
                </Skeleton>
                <ResendInvite
                  storedBaoriderId={storedBaorider?.getSingleBaorider?.baorider?.id || ''}
                  loading={loading}
                />
              </VStack>
            </Flex>
          </Flex>
          <Flex direction="column" w="100%" px={3}>
            <CustomTabContainer
              tabs={tabs}
              onChange={(index) => {
                setTabIndex(index)
              }}
              isLazy
              tabPanels={tabPanels}
            />
          </Flex>
        </Container>
      </Box>

      <AlertModal
        activatorStatus="deactivate"
        onConfirm={async () => {
          await deactivateUser()
          setShowDeactivateConfirmation(false)
        }}
        isOpen={showDeactivateConfirmation}
        onClose={() => setShowDeactivateConfirmation(false)}
        title="Deactivate User"
      >
        <Heading fontSize="sm">
          Are you sure you want to deactivate this user from the platform?
        </Heading>
        <Text fontSize="xs" mt={2}>
          By removing a {DATA_COLLECTOR} from the platform, they will no longer be able to access or
          capture evidence for the group
        </Text>
      </AlertModal>
      <AlertModal
        activatorStatus="activate"
        onConfirm={async () => {
          await reactivateUser()
          setShowReactivateConfirmation(false)
        }}
        isOpen={showReactivateConfirmation}
        onClose={() => setShowReactivateConfirmation(false)}
        title="Reactivate User"
      >
        <Heading fontSize="sm">Are you sure you want to reactivate this user ?</Heading>
        <Text fontSize="xs" mt={2}>
          By reactivating a {DATA_COLLECTOR}, they will be able to access and capture evidence for
          the group
        </Text>
      </AlertModal>
      <AlertModal
        activatorStatus="deactivate"
        confirmButtonText="Remove"
        onConfirm={() => {
          setShowRemoveFromProjectCon(false)
          removeUserFromProject(currentProject || '')
        }}
        isOpen={showRemoveFromProject}
        onClose={() => setShowRemoveFromProjectCon(false)}
        title={`Remove ${DATA_COLLECTOR} from project`}
      >
        <Heading fontSize="sm">
          Are you sure you want to remove this {DATA_COLLECTOR} from the project?
        </Heading>
        <Text fontSize="xs" mt={2}>
          By removing a {DATA_COLLECTOR} from the project, they will no longer be able to access or
          capture evidence for the project.
        </Text>
      </AlertModal>
    </PageWrap>
  )
}

export default DataCollectorsDetails
