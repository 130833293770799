import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { Check } from 'react-feather'

type StepProps = {
  steps: ReactNode[]
  active: number
}

const Steps: React.FC<StepProps> = ({ steps, active }) => {
  const itemwidth = 100 / (steps?.length * 2) - 1
  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      align="center"
      justify="center"
      py={6}
      bg="white"
      mb={3}
    >
      {steps?.map((step, index) => (
        <>
          <Flex flexDirection="row" width={`${itemwidth}%`}>
            <Flex flexDirection="row" w="100%" justify="center" align="center">
              <Text
                border="1px solid"
                borderRadius="35px"
                width="35px"
                height="35px"
                minWidth="35px"
                minHeight="35px"
                p={1}
                textAlign="center"
                borderColor={
                  active === index ? 'brand.500' : active > index ? 'brand.500' : 'rgba(0,0,0,0.3)'
                }
                color={
                  active === index ? 'white' : active > index ? 'brand.500' : 'rgba(0,0,0,0.3)'
                }
                bg={active === index ? 'brand.500' : 'white'}
              >
                {active > index ? <Check /> : index + 1}
              </Text>
              <Box ml={3} color={active === index ? '#000' : 'rgba(0,0,0,0.3)'}>
                {step}
              </Box>
            </Flex>
          </Flex>
          {index < steps.length - 1 && (
            <Flex flexDirection="row" w={`${itemwidth}%`} justify="center" alignItems="center">
              <Divider w="100%" />
            </Flex>
          )}
        </>
      ))}
    </Flex>
  )
}

export default Steps
