import {
  MenuButton,
  Center,
  Avatar,
  AvatarBadge,
  MenuList,
  MenuGroup,
  MenuItem,
  Flex,
  Text,
  Heading,
  Menu
} from '@chakra-ui/react'
import { getSocket } from 'lib'

import React from 'react'
import { VscSignOut } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../../../context/AuthProvider/index'
import { useMeQuery } from '../../../../generated/graphql'
import { useAppContext } from '../../../../context/AppProvider/index'
import { LEAVE } from 'lib/websockets/constants'
// import { FcQuestions } from 'react-icons/fc'

type ProfileButtonProps = {
  buttonTrigger?: React.ReactNode
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ buttonTrigger }) => {
  const history = useHistory()
  const { data } = useMeQuery()
  const { logout } = useAuthContext()
  const { isLive } = useAppContext()
  const user = data?.myUser
  const handleLogout = () => {
    const socket = getSocket()
    logout && logout()
    socket.emit(LEAVE, { userId: user?.id })
    socket.disconnect()
    history.push('/')
  }

  return (
    <Menu placement="bottom-end">
      <MenuButton p={1} mx={1} borderRadius="50%">
        {buttonTrigger ? (
          buttonTrigger
        ) : (
          <Center _focus={{ boxShadow: 'outline' }}>
            <Flex direction="column" align="flex-end" mx={3}>
              <Heading as="h6" isTruncated fontSize="xs">
                {user?.attributes?.username}
              </Heading>
              <Text isTruncated fontSize="x-small" color="gray.500">
                {user?.attributes?.company?.data?.attributes?.name}
              </Text>
            </Flex>
            <Avatar size="sm" name={`${user?.attributes?.firstName} ${user?.attributes?.lastName}`}>
              <AvatarBadge boxSize=".8rem" bg={isLive ? 'green.500' : 'red.300'} />
            </Avatar>
          </Center>
        )}
      </MenuButton>

      <MenuList textAlign="left" zIndex={474839} overflow="hidden" rounded="2xl" p={0}>
        <>
          <MenuGroup zIndex={484849}>
            <MenuItem onClick={() => history.push('/auth/settings')}>
              <Center py={2}>
                <Avatar
                  size="sm"
                  name={`${user?.attributes?.firstName} ${user?.attributes?.lastName}`}
                  mr={2}
                >
                  <AvatarBadge boxSize="1.25em" bg="green.500" />
                </Avatar>
                <Flex direction="column">
                  <Heading as="h4" fontSize="md">
                    {user?.attributes?.firstName} {user?.attributes?.lastName}
                  </Heading>
                  <Text color="gray.500" fontSize="x-small">
                    {user?.attributes?.email}
                  </Text>
                </Flex>
              </Center>
            </MenuItem>
          </MenuGroup>

          {/* <MenuItem fontSize="xs" icon={<FcQuestions />} onClick={() => history.push('/auth/help')}>
            Help
          </MenuItem> */}
          <MenuItem fontSize="xs" color="red.600" icon={<VscSignOut />} onClick={handleLogout}>
            Logout
          </MenuItem>
        </>
      </MenuList>
    </Menu>
  )
}

export default ProfileButton
