import { Flex, Heading, Box, FlexProps, Center, Skeleton } from '@chakra-ui/react'
import StatusBadge from 'components/UI/StatusBadge'

import React from 'react'
import { VscLink } from 'react-icons/vsc'

type FloatingCardProps = FlexProps & {
  title: string
  icon: React.ReactNode
  value: string
  isLink?: boolean
  loading?: boolean
  onClick?: () => void
}

const FloatingCard: React.FC<FloatingCardProps> = ({
  title,
  icon,
  value,
  onClick,
  isLink,
  loading,
  ...rest
}) => {
  return (
    <Skeleton width="fit-content" rounded="2xl" minW="5rem" isLoaded={!loading}>
      <Flex
        shadow="lg"
        direction="column"
        justify="space-between"
        rounded="2xl"
        bg="white"
        zIndex={134}
        p={6}
        height="9.2rem"
        position="relative"
        onClick={onClick}
        cursor={isLink ? 'pointer' : undefined}
        pointerEvents={isLink ? 'auto' : 'none'}
        {...rest}
      >
        {isLink && (
          <Center
            top={0}
            transform="translate(-50%,-50%)"
            left="50%"
            rounded="full"
            p={1}
            bg="green.400"
            color="white"
            position="absolute"
          >
            <VscLink />
          </Center>
        )}
        <Flex align="center">
          <Box
            mr={3}
            _after={{
              content: '""',
              position: 'absolute',
              bottom: '-0.5rem',
              left: 0,
              borderTop: '1px solid',
              borderColor: 'green.500',
              width: '100%',
              background: 'green'
            }}
            position="relative"
          >
            <StatusBadge bg="gray.100" color="green.500" p={1}>
              {icon}
            </StatusBadge>
          </Box>
          <Heading color="gray.400" isTruncated fontSize="x-small">
            {title}
          </Heading>
        </Flex>
        <Flex direction="column">
          <Skeleton width="fit-content" minW="5rem" isLoaded={!loading}>
            <Heading
              as="h2"
              color="gray.600"
              textTransform="capitalize"
              fontWeight="semibold"
              py={1}
              fontSize="xs"
            >
              {value}
            </Heading>
          </Skeleton>
        </Flex>
      </Flex>
    </Skeleton>
  )
}

export default FloatingCard
