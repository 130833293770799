import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { CSSReset, ChakraProvider } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import 'react-dates/initialize'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import client from './apollo'
import App from './App'
import { APP_NAME } from './constants'
import { AppProvider, AuthProvider, VideoModalProvider } from './context'
import * as serviceWorker from './serviceWorker'
import { theme } from './theme'
import { disableReactDevTools } from './utils/index'
import posthog from 'posthog-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '')
posthog.init(
  process.env.NODE_ENV === 'production'
    ? 'phc_FJTHAtI4Nc2AAKfrfGp2le7PGuxTQ7fDeKVxWeJutXx'
    : 'phc_buOFrinwCnvsUE8yMNFqYmij6a7h0sEeVuU9UuWelBV',
  {
    api_host: 'https://app.posthog.com'
  }
)

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <AuthProvider>
            <Helmet titleTemplate={`${APP_NAME} - %s`} />
            <AppProvider>
              <VideoModalProvider>
                <CSSReset />
                <Global
                  styles={css`
                    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;1,300&display=swap');
                    @import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
                    * {
                      font-family: ${theme.fonts.body};
                    }
                    *:focus {
                      box-shadow: none !important;
                    }
                    ::placeholder {
                      /* Chrome, Firefox, Opera, Safari 10.1+ */
                      font-size: small;
                      opacity: 1; /* Firefox */
                    }
                    button {
                      cursor: pointer;
                    }

                    :-ms-input-placeholder {
                      /* Internet Explorer 10-11 */
                      font-size: small;
                    }

                    ::-ms-input-placeholder {
                      /* Microsoft Edge */
                      font-size: small;
                    }
                  `}
                />
                <App />
              </VideoModalProvider>
            </AppProvider>
          </AuthProvider>
        </Elements>
      </ChakraProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
