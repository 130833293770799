export default {
  bg: require('../assets/images/MasaiFamily.jpg')?.default,
  bg2: require('../assets/images/MasaiSunset.jpg')?.default,
  dashboard: require('../assets/images/undraw_dashboard_nklg.png')?.default,
  campaign: require('../assets/images/undraw_circles_y7s2.png')?.default,
  logo: require('../assets/images/baotree-logo.png')?.default,
  project: require('../assets/images/undraw_project_completed_w0oq.png')?.default,
  404: require('../assets/images/404.svg')?.default,
  noData: require('../assets/images/no-data.png')?.default,
  notFound: require('../assets/images/not_found.png')?.default,
  landingPage1: require('../assets/images/undraw_happy_announcement_ac67.png')?.default,
  landingPage3: require('../assets/images/undraw_active_options_8je6.png')?.default,
  landingPage2: require('../assets/images/undraw_message_sent_1030.png')?.default,
  scooterIcon: require('../assets/images/scooter.svg')?.default,
  shopIcon: require('../assets/images/shop.svg')?.default,
  userIcon: require('../assets/images/user.svg')?.default,
  speakerIcon: require('../assets/images/speaker.svg')?.default,
  addFolderIcon: require('../assets/images/add-folder.svg')?.default,
  doneIllustration: require('../assets/images/undraw_done_a34v.png')?.default,
  empty: require('../assets/images/empty.webp')?.default,
  category1: require('../assets/images/Category 5@2x.svg')?.default,
  category2: require('../assets/images/Category 6@2x.svg')?.default,
  category3: require('../assets/images/Category 8@2x.svg')?.default,
  noImage: require('../assets/images/noImage.png')?.default,
  icon: require('../assets/images/icon.png')?.default,
  logoNoText: require('../assets/images/logo-no-text.png')?.default,
  baotreeHomeBg: require('../assets/images/BAOTREE-HOMEPAGE-V01-1536x1229.png')?.default,
  baotreeHomeBg2: require('../assets/images/Group-24-1536x1229.png')?.default,
  baotreeHomeBg3: require('../assets/images/BAO-TREE_APP-monitoring-and-evaluation-1536x1117.png')
    ?.default,
  baotreeHomeBg4: require('../assets/images/BAO-TREE_APP-collaboration-1536x1117.png')?.default,
  baotreeHomeBg5:
    require('../assets/images/BAOTREE_APP-all-v02_BAO-TREE_APP-monitoring-and-evaluation-1536x1117.png')
      ?.default,
  baotreeHomeBg6: require('../assets/images/BAOTREE_APP-all-v02_BAO-TREE_APP-task-1536x1117.png')
    ?.default,
  baotreeHomeBg7: require('../assets/images/BAOTREE_ESG-P02-1536x1117.png')?.default,
  baotreeHomeBg8: require('../assets/images/BAOTREE-HOMEPAGE-V01-1536x1229.png')?.default,
  csv: require('../assets/images/icon_file-CSV_plano-512.webp')?.default,
  word: require('../assets/images/word-logo-8.png')?.default,
  excel: require('../assets/images/microsof-excel-logo.png')?.default,
  pdf: require('../assets/images/pdf-icon-png-pdf-zum-download-2.png')?.default,
  powerpoint: require('../assets/images/microsoft-powerpoint-logo.png')?.default,
  noVideo: require('../assets/images/no video.gif')?.default,
  error: require('../assets/images/error.gif')?.default,
  samsungFrame: require('../assets/images/samsung_frame.png')?.default,
  androidPhoneFrame: require('../assets/images/androidphone_bg.jpg')?.default,
  unLogo: require('../assets/images/un_logo.png')?.default,
  verifyEmail: require('../assets/images/verify-email.webp')?.default
}
