import { useAuthContext } from 'context/AuthProvider'
import { CONNECT, JOINED } from 'lib/websockets/constants'
import React from 'react'
import { useAppContext } from '../context/AppProvider'
import { connectSocket } from 'lib'

const useSocketConnector = () => {
  const { isAuthenticated, user } = useAuthContext()
  const { setIsLive, setConnected } = useAppContext()

  React.useEffect(() => {
    if (isAuthenticated) {
      const socket = connectSocket({ userId: user?.id })
      socket.on(CONNECT, () => {
        console.log('socket connected')
      })
      socket.on(JOINED, async () => {
        console.log('socket joined')
        setConnected(socket.connected)
        setIsLive(socket.connected)
      })
      socket.on('disconnect', () => {
        setConnected(socket.connected)
        setIsLive(socket.connected)
      })
      socket.on('reconnect', () => {
        console.log('socket reconnected')
        socket.connect()
      })
      socket.on('reconnect_attempt', () => {
        console.log('socket reconnect attempt')
      })
      socket.on('connect_error', (err) => {
        console.log('socket connection error', err)
        socket.connect()
      })
      socket.on('connect_timeout', () => {
        console.log('socket connection timeout')
        socket.connect()
      })
      return () => {
        socket.disconnect()
      }
    }
  }, [isAuthenticated])
}

export default useSocketConnector
