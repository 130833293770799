import { Box, Text, Link } from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'

export const detailsToolTip: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>
      <b>Navigate</b> along the tabs to <b>manage</b> all aspects of this <b>project</b>.
    </Text>
  </Box>
)

export const tasksToolTip = (
  <Box px={4} py={6}>
    <Text>
      This indicates all the <b>Task Templates</b> added to this <b>project</b>.<b>Navigate</b>{' '}
      along the tabs to <b>manage</b> all aspects of this project.
    </Text>
  </Box>
)

export const tasksReportedToolTip = (
  <Box px={4} py={6}>
    <Text>
      This contains all <b>Tasks submitted</b> by your <b>Data Collectors</b> for this project.
    </Text>
  </Box>
)

export const dataCollectorsToolTip = (
  <Box px={4} py={6}>
    <Text>
      This contains all <b>Data Collectors</b> associated with this project. You can <b>add</b> and{' '}
      <b>remove</b> Data Collectors from the project in this tab.
    </Text>
  </Box>
)

export const tasksHelpPopover = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>This contains all Tasks submitted by your Data Collectors for this project.</Text>
    </Box>
  </CustomPopover>
)

export const taskTypeTitleHelperPopover = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        Use the toggles next to each <b>Task Template</b> to turn them on/off. Turning them off will
        remove them from the app but all data collected on them already will remain in the system.
        You can turn them on/off at any time.
      </Text>
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const createTaskTypeToolTip = (
  <Box px={4} py={6}>
    <Text>Use this button to create a new Task Template associated with this project.</Text>
  </Box>
)
export const addTaskTypeToolTip = (
  <Box px={4} py={6}>
    <Text>
      Use this button to add an existing Task Template from the Task Builder to this project.
    </Text>
  </Box>
)

export const dataCollectorsHelpPopover = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        This contains the Data Collectors associated with this project. Once a Data Collector has
        been added to the system, they must be added to the relevant projects to see their tasks.
        This can be done in this tab.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)
export const addDatacollectorTooltip = (
  <Box px={4} py={6}>
    <Text>Add Data Collectors to the project using this button.</Text>
  </Box>
)

export const dataCollectorsExportExcelTooltip = (
  <Box px={4} py={6}>
    <Text>Use this button to export the details of this project&rsquo;s data collectors.</Text>
  </Box>
)
