import { Box, Text, Link } from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'
export const tasksBuilderHelpPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        The <b>Task Builder</b> is where you <b>create, edit</b> and <b>manage</b> the{' '}
        <b>Task Templates</b> that will go into your projects and appear in your data
        collectors&apos; Baotree app.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const createNewTaskButtonHelperTooltip: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>Use this button to create a new task.</Text>
  </Box>
)

// datalist
export const dataListButtonHelperTooltip: React.ReactNode = (
  <Box px={4} py={6}>
    <Text>Use this button to access and manage all your data lists.</Text>
  </Box>
)

export const createDataListButtonHelperPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box fontSize="md" px={4} py={6}>
      <Text>
        Use this page to manage all your data lists. Click on a list name below to edit an existing
        list or select the &ldquo;create list&ldquo; button to create a new list.
      </Text>
      <br />
      <Text>
        <b>Tip</b>: Use data lists when several task templates will require the same list of options
        for a particular question (e.g. Areas you are working in, crops you are planting, animal
        IDs). Data lists help make it quicker and easier for you to manage, as you can simply update
        the master list and all tasks will be updated.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)
