import React from 'react'
import {
  Flex,
  IconButton,
  Box,
  SimpleGrid,
  SimpleGridProps,
  Skeleton,
  Text,
  Image
} from '@chakra-ui/react'
import { Maybe, ProjectEntity } from 'generated/graphql'
import { VscLink, VscTrash } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'
import { getImageByFormat } from 'utils'

type ProjectCardsProps = {
  projects: Maybe<ProjectEntity>[]
  status?: 'add' | 'remove'
  loading?: boolean
  onAction: (index: number, project: Maybe<ProjectEntity>) => void
  emptyHandler?: React.ReactNode
} & SimpleGridProps

const ProjectCards: React.FC<ProjectCardsProps> = ({
  projects,
  onAction,
  status,
  loading,
  emptyHandler,
  ...rest
}) => {
  const colWidth = 100 / 2
  const history = useHistory()
  const isAdd = status === 'add'
  const arrayWithLoading = Array.from({ length: 4 }, (_, i) => i)
  return (
    <>
      {loading ? (
        arrayWithLoading.map((i) => <Skeleton h={16} key={i} />)
      ) : projects.length ? (
        <SimpleGrid spacing={1} width="100%" {...rest}>
          {projects?.map((projectItem, index) => {
            const onActionHandler = () => onAction(index, projectItem)
            const redirect = () => history.push('/auth/projects/' + projectItem?.id)
            return (
              <Flex
                p={2}
                my={1}
                key={projectItem?.id}
                width="100%"
                rounded="2xl"
                bg="gray.100"
                transition="all 0.3s"
                _hover={{
                  bg: 'white'
                }}
              >
                <Flex
                  fontSize="xs"
                  flex={1}
                  w={`${colWidth}%`}
                  fontWeight={500}
                  cursor="pointer"
                  onClick={isAdd ? onActionHandler : redirect}
                  justify="flex-start"
                  align="center"
                >
                  <Box mr={3}>
                    <Image
                      src={getImageByFormat(projectItem?.attributes?.featured_image?.data)}
                      alt={projectItem?.attributes?.name || ''}
                      boxSize={8}
                      rounded="md"
                      objectFit="cover"
                    />
                  </Box>
                  {projectItem?.attributes?.name || ''}
                </Flex>

                <IconButton
                  aria-label={isAdd ? 'Add' : 'Remove'}
                  colorScheme={isAdd ? 'green' : 'red'}
                  size="lg"
                  variant="ghost"
                  icon={isAdd ? <VscLink /> : <VscTrash />}
                  onClick={isAdd ? redirect : onActionHandler}
                />
              </Flex>
            )
          })}
        </SimpleGrid>
      ) : (
        emptyHandler || (
          <Text fontSize="sm" color="gray.600" py={6}>
            No projects assigned.
          </Text>
        )
      )}
    </>
  )
}

export default ProjectCards
