import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react'
import * as React from 'react'
import { Skeleton } from '@chakra-ui/react'

interface Props {
  label: string
  icon?: React.ReactNode
  loading?: boolean
  value: string | number
}
const Stat = (props: Props) => {
  const { label, value, icon, loading, ...boxProps } = props
  return (
    <Box
      bg="bg-surface"
      borderRadius="lg"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      {...boxProps}
    >
      <Stack textAlign="center">
        <Skeleton isLoaded={!loading}>
          <Flex align="center">
            {icon}
            <Text ml={1} fontSize="xs" color="grey.600">
              {label}
            </Text>
          </Flex>
        </Skeleton>
        <Skeleton isLoaded={!loading}>
          <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>{value}</Heading>
        </Skeleton>
      </Stack>
    </Box>
  )
}
export default Stat
