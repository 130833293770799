import { DATE_FORMAT } from '../../../constants'
import moment from 'moment'
import { Maybe, UsersPermissionsUserEntity } from '../../../generated/graphql'
export const generateExcelDateSet = (baoriders: Maybe<UsersPermissionsUserEntity>[]) => {
  return [
    {
      columns: [
        { title: 'First Name', style: { font: { sz: '18', bold: true } }, width: { wpx: 125 } },
        { title: 'Last Name', style: { font: { sz: '18', bold: true } }, width: { wch: 30 } },
        { title: 'Number', style: { font: { sz: '18', bold: true } }, width: { wch: 30 } },
        { title: 'Date Added', style: { font: { sz: '18', bold: true } }, width: { wch: 30 } },
        { title: 'Status', style: { font: { sz: '18', bold: true } }, width: { wch: 30 } }
      ],
      data: baoriders.map((rider) => [
        {
          value: rider?.attributes?.firstName ?? '-',
          style: { font: { sz: '14' } }
        },

        {
          value: rider?.attributes?.lastName ?? '-',
          style: { font: { sz: '14' } }
        },
        {
          value: rider?.attributes?.phoneNumber ?? '-',
          style: { font: { sz: '14' } }
        },
        {
          value: rider?.attributes?.createdAt
            ? moment(rider?.attributes?.createdAt).format(DATE_FORMAT)
            : '-',
          style: { font: { sz: '14' } }
        },
        {
          value: rider?.attributes?.status ?? '-',
          style: { font: { sz: '14' } }
        }
      ])
    }
  ]
}

export const capitalizeEachWord = (str: string | undefined): string =>
  str ? str.replace(/\b\w/g, (char) => char.toUpperCase()) : ''

export const getStatusLabel = (status: string) => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'SUMBITTED'
    case 'completed':
      return 'APPROVED'
    case 'rejected':
      return 'REJECTED'
    default:
      return status
  }
}
