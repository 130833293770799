import Countries from 'world-countries'

export const countries = Countries.map(({ name, idd, flag }) =>
  name.common === 'United States'
    ? {
        name: 'United States',
        code: {
          root: idd.root,
          suffix: []
        },
        flag: flag
      }
    : {
        name: name.common,
        code: idd,
        flag: flag
      }
)
