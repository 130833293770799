import { Container, Flex, Heading, useToast, Box, Image } from '@chakra-ui/react'
import { BackButton, TableIcon } from 'components'
import React from 'react'
import { PenTool } from 'react-feather'
import { FaUsers } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import {
  CommunityInput,
  useGetSingleBaoleaderCommunityQuery,
  useUpdateCommunityMutation
} from '../../../generated/graphql'
import { PageWrap } from '../../../layouts'

import { Community } from '../../../generated/graphql'
import { images } from 'theme'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'
import { CreateCommunityForm } from '../components'

const EditCommunity: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetSingleBaoleaderCommunityQuery({ variables: { id } })
  const toast = useToast()
  const history = useHistory()
  const [updateCommunity, { loading }] = useUpdateCommunityMutation({
    onCompleted: () => {
      toast({ title: 'Group updated.', ...SUCCESS_TOAST })
    },
    onError: (error) => {
      console.log({ error })
      toast({ title: 'There was an error.', ...ERROR_TOAST })
    }
  })
  const community = data?.getSingleBaoleaderCommunity
  const submitForm = async (values: Community) => {
    try {
      await updateCommunity({
        variables: {
          id: id || '',
          input: {
            ...values,
            featured_image: (values?.featured_image as any)?.id
          } as CommunityInput
        }
      })
    } catch (error) {
      console.log({ error })
    }
  }
  return (
    <PageWrap direction="column" title="Edit Community" align="center" p={0} m={0}>
      <Container my={8} maxW="1600" px={16}>
        <Flex>
          <BackButton />
        </Flex>
      </Container>
      <Box bg="background.100" borderRadius="md" pb={24} width="100%">
        <Container maxW="1600" px={16} pt={10} position="relative">
          <TableIcon loading={loading} position="right" icon={<FaUsers />} />
          <Flex direction="column" flex={1}>
            <Flex align="center" mb={6}>
              <Heading color="gray.600">Edit Group</Heading>
            </Flex>

            <Flex>
              <CreateCommunityForm
                actionBar={(dirty: boolean) => (
                  <Flex direction="row" justify="flex-end" align="center" w="100%">
                    <SecondaryButton
                      onClick={() => {
                        history.push(`/auth/communities/${id}`)
                      }}
                      mr={3}
                      isLoading={loading}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      width="100%"
                      isDisabled={!dirty}
                      leftIcon={<PenTool size={14} />}
                      type="submit"
                      isLoading={loading}
                    >
                      Update
                    </PrimaryButton>
                  </Flex>
                )}
                submitForm={submitForm}
                initialValues={
                  {
                    chief_name: community?.attributes?.chief_name,
                    name: community?.attributes?.name,
                    type: community?.attributes?.type || '',
                    description: community?.attributes?.description,
                    featured_image: community?.attributes?.featured_image?.data?.attributes,
                    location: community?.attributes?.location
                  } as any
                }
              />
              <Flex justify="center" flex={1}>
                <Image
                  borderRadius="full"
                  objectFit="contain"
                  draggable="false"
                  src={images.logo}
                  boxSize="40%"
                />
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </PageWrap>
  )
}

export default EditCommunity
