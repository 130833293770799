import { Box, Button, Flex, Heading, HStack, Image, Skeleton, Stack, Text } from '@chakra-ui/react'
import React from 'react'

import { useHistory } from 'react-router-dom'
import { Card, StatusBadge } from '../..'
import { ProjectEntity, useGetProjectUnsdgTagsQuery } from '../../../generated/graphql'

import { SlidingCardContainer } from 'components/UX/SlidingCardContainer'
import moment from 'moment'
import { VscArrowRight, VscCopy } from 'react-icons/vsc'

import { getImageByFormat } from 'utils'

const ProjectCard: React.FC<{ projectSet: ProjectEntity }> = ({ projectSet }) => {
  const history = useHistory()
  const { data: projectTags, loading } = useGetProjectUnsdgTagsQuery({
    variables: {
      project: projectSet?.id || ''
    }
  })
  const unsdgTags = projectTags?.getProjectUnsdgTags?.filter((tag) => tag !== null) || []

  return (
    <SlidingCardContainer>
      <div className="community-card-front">
        <Card
          cursor="pointer"
          height="100%"
          width="100%"
          border="1px solid"
          onClick={() => {
            history.push(`/auth/projects/${projectSet?.id}`)
          }}
          p={6}
        >
          <Flex mb={4} justify="space-between">
            <Image
              src={getImageByFormat(projectSet?.attributes?.featured_image?.data)}
              objectFit="cover"
              align="community profile"
              boxSize="40px"
              rounded="2xl"
            />
            <Stack spacing={6} ml={4}>
              <Box>
                <Text mb={2} fontSize="xx-small" color="gray.500">
                  Created {moment(projectSet?.attributes?.createdAt).fromNow()}
                </Text>
              </Box>
            </Stack>
          </Flex>
          <Flex align="center">
            <Heading as="h2" flex={1} size="sm">
              {projectSet?.attributes?.name}
            </Heading>
          </Flex>
          {!projectSet?.attributes?.description ? (
            <HStack wrap="wrap" my={2} spacing={3} width="100%">
              {unsdgTags?.length ? (
                unsdgTags?.map((unsdg, index) => (
                  <Skeleton isLoaded={!loading} key={unsdg || index}>
                    <StatusBadge borderRadius="xl" fontWeight="normal">
                      {unsdg}
                    </StatusBadge>
                  </Skeleton>
                ))
              ) : (
                <Skeleton isLoaded={!loading}>
                  <Text color="gray.600" fontSize="x-small">
                    No unsdg
                  </Text>
                </Skeleton>
              )}
            </HStack>
          ) : (
            <Text fontSize="x-small" noOfLines={2} my={2} color="gray.500">
              {projectSet?.attributes?.description}
            </Text>
          )}
        </Card>
      </div>
      <div className="community-card-back">
        <Card
          shadow="none"
          border="1px solid"
          borderColor="gray.200"
          height="100%"
          bg="white"
          py={6}
          px={8}
        >
          <Flex flex={1} fontSize={15} align="flex-end" justify="space-between" textAlign="center">
            <HStack w="100%">
              {/* TODO:: Uncomment when rescoped */}
              {/* <ProjectPDFLink project={projectSet}>
                <Text fontSize="x-small">Export as PDF</Text>
              </ProjectPDFLink> */}

              <Button
                onClick={() => {
                  history.push(`/auth/copy-project/${projectSet?.id}`)
                }}
                leftIcon={<VscCopy />}
                variant="outline"
                rounded="2xl"
                fontSize="x-small"
              >
                Copy
              </Button>

              <Button
                onClick={() => {
                  history.push(`/auth/projects/${projectSet?.id}`)
                }}
                rightIcon={<VscArrowRight />}
                colorScheme="green"
                variant="ghost"
                rounded="2xl"
                flex={1}
                fontSize="x-small"
              >
                View Project
              </Button>
            </HStack>
          </Flex>
        </Card>
      </div>
    </SlidingCardContainer>
  )
}

export default ProjectCard
