import React from 'react'
import {
  Enum_Datalist_Status,
  Enum_Tasktype_Status,
  Exact,
  Maybe,
  DataListEntity as DataListType,
  useGetDataListsQuery,
  useGetTaskTypeQuery,
  useUpdateTaskTypeMutation,
  useActivateTaskTypeMutation,
  useDeactivateTaskTypeMutation,
  GetSingleBaoleaderProjectQuery
} from '../../../generated/graphql'
import { useParams, useHistory } from 'react-router-dom'
import {
  Button,
  Container,
  Flex,
  Heading,
  useToast,
  VStack,
  Text,
  Image,
  useDisclosure,
  Center,
  Link,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Spinner,
  Box
} from '@chakra-ui/react'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'
import { SubNav, BackButton, StatusBadge, CustomTooltip, TableIcon } from 'components'
import { PageWrap } from 'layouts'
import { VscSave, VscAdd, VscRefresh, VscTrash, VscCheck, VscEye, VscCopy } from 'react-icons/vsc'
import { images } from 'theme'

import { cloneDeep, isEqual, find, findIndex, omit, reduce } from 'lodash'

import { SupportedDataFieldType, SupportedDataFieldKeysType, SuspendedTaskTypes } from '../types'
import { HStack, Skeleton } from '@chakra-ui/react'

import { AnimatePresence, motion, usePresence } from 'framer-motion'
import { Maximize2 } from 'react-feather'
import { FaPenAlt } from 'react-icons/fa'
import { useBrowserStorage } from 'hooks'
import moment from 'moment'
import DeactivateModal from '../components/DeactivateModal'
import DynamicInputField from '../components/DynamicInputField'
import MobileViewRenderer from '../components/Mobile/MobileViewRenderer'
import OptionsDrawer from '../components/OptionsDrawer'
import QuestionModal from '../components/QuestionModal'
import TaskTypeMetadata from '../components/TaskTypeMetadata'
import { useAuthContext } from '../../../context/AuthProvider/index'
import { pluralHandler, replaceQuotationMarks } from 'utils'
import { useTaskTypes } from '../data'
import styled from '@emotion/styled'
import DataList from '../components/DataList'
import { TaskTypeEntity } from '../../../generated/graphql'
import { OptionType } from 'types'
import { FcInspection } from 'react-icons/fc'
import { SupportedDataFields, SUSPENDED_TASK_TYPES } from '../constants'

const Btn = styled(motion(Button))`
  font-size: 0.7rem;
`

const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1 }

type TaskTypeDetailsProps = {
  taskTypeID?: string | undefined
  refetch?: (
    variables?:
      | Partial<
          Exact<{
            sort?: Maybe<string> | undefined
            where?: any
            limit?: Maybe<number> | undefined
            start?: Maybe<number> | undefined
          }>
        >
      | undefined
  ) => Promise<any> | undefined
  isDraft?: boolean
  inline?: boolean
  suspendedTaskTypes?: SuspendedTaskTypes[] | undefined
  setSuspendedTaskTypes?: React.Dispatch<React.SetStateAction<SuspendedTaskTypes[] | undefined>>

  getOptions?: (options: () => JSX.Element, rightElement?: React.ReactNode) => void
}

const TaskTypeDetails = React.forwardRef<HTMLDivElement, TaskTypeDetailsProps>(
  (
    { taskTypeID, refetch, getOptions, isDraft, suspendedTaskTypes, setSuspendedTaskTypes, inline },
    ref
  ) => {
    const { id } = useParams<{ id: string }>()
    const toast = useToast()
    const history = useHistory()

    const project = (history.location.state as any)?.project as GetSingleBaoleaderProjectQuery
    const projectRef = React.useRef(project)

    const { user } = useAuthContext()
    // the current Task and all its data from the API
    const {
      data,
      loading: taskLoading,
      refetch: refetchTaskType
    } = useGetTaskTypeQuery({
      variables: {
        id: taskTypeID ? taskTypeID : id
      },
      onError: (error) => {
        toast({ title: `There was an error ${error.message}.`, ...ERROR_TOAST })
        history.goBack()
      },
      fetchPolicy: 'cache-first'
    })

    React.useEffect(() => {
      window.scrollY = 0
    }, [])
    // DTO style design pattern mapping to better utilize the `taskType` properties from the `data` and present them to the user
    const taskTypeData = useTaskTypes(data)
    const [
      suspendedTaskTypesDetails,
      setSuspendedTaskTypesDetails,
      _removeSuspendedTaskTypesDetails
    ] = useBrowserStorage<SuspendedTaskTypes[]>(SUSPENDED_TASK_TYPES, 'local')

    const taskTypeMetadata = React.useMemo(
      () => ({
        title: data?.getTaskType?.attributes?.title || '',
        category: data?.getTaskType?.attributes?.category?.data?.id || '',
        photoRequired: data?.getTaskType?.attributes?.photoRequired,
        withComment: data?.getTaskType?.attributes?.withComment,
        un_sdg: data?.getTaskType?.attributes?.un_sdg?.data?.id || ''
      }),
      [data]
    )

    const { isOpen, onClose, onOpen } = useDisclosure()
    // this is the TaskType instance data unadulterated after the query from the API
    const [taskTypeMetadate, setTaskTypeMetadata] = React.useState<any>()
    const [inputFields, setInputFields] = React.useState<Array<SupportedDataFieldType>>([])
    const oldDataStructure = React.useRef<Array<SupportedDataFieldType>>([])
    const oldTaskTypeMetadata = React.useRef<any>()
    const {
      isOpen: isDataListOpen,
      onOpen: onDataListOpen,
      onClose: onDataListClose
    } = useDisclosure()
    // use this to create a new form
    const pupulateFormContent = React.useCallback(() => {
      if (data?.getTaskType) {
        setTaskTypeMetadata(taskTypeMetadata)
        setInputFields(taskTypeData)
      }
    }, [data])
    const cleanDraft = React.useCallback(() => {
      if (suspendedTaskTypes || suspendedTaskTypesDetails) {
        const found = findIndex(
          taskTypeID ? suspendedTaskTypes : suspendedTaskTypesDetails,
          (task) => {
            const ID = taskTypeID ? taskTypeID : id
            return task?.seletedTaskType === ID
          }
        )

        if (found > -1) {
          const temp = taskTypeID
            ? suspendedTaskTypes?.filter((tasktype) => tasktype.seletedTaskType !== taskTypeID)
            : suspendedTaskTypesDetails?.filter((tasktype) => tasktype.seletedTaskType !== id)

          if (taskTypeID) setSuspendedTaskTypes?.(temp)
          else setSuspendedTaskTypesDetails(temp)
        }
      }
      history.location.state = undefined
    }, [suspendedTaskTypes, suspendedTaskTypesDetails, taskTypeID, id])
    const [updateTaskType, { loading: updatingTaskType }] = useUpdateTaskTypeMutation({
      onCompleted: async () => {
        await refetchTaskType()

        cleanDraft()
        toast({ title: 'Task updated', ...SUCCESS_TOAST })
      },
      onError: (err) => {
        toast({
          title: `Unable to update task -> ${err.message}`,
          ...ERROR_TOAST
        })
      }
    })
    const { data: activeDataLists, refetch: refetchDataLists } = useGetDataListsQuery({
      variables: {
        filters: {
          status: Enum_Datalist_Status.Active
        },
        sort: 'name:asc'
      }
    })
    const [activateTaskType] = useActivateTaskTypeMutation({
      onCompleted: async () => {
        toast({ title: 'Task updated', ...SUCCESS_TOAST })
      },
      onError: (err) => {
        toast({
          title: `Unable to update task -> ${err.message}`,
          ...ERROR_TOAST
        })
      }
    })
    const [deactivateTaskType] = useDeactivateTaskTypeMutation({
      onCompleted: async () => {
        toast({ title: 'Task updated', ...SUCCESS_TOAST })
      },
      onError: (err) => {
        toast({
          title: `Unable to update task -> ${err.message}`,
          ...ERROR_TOAST
        })
      }
    })

    const onDuplicateTask = () => {
      history.push(`/auth/task-types-list/create-task?taskType=${taskTypeID}`)
      console.log('duplicate go to the create task page')
    }

    const handleDeleteTaskType = async () => {
      try {
        if (data?.getTaskType?.attributes?.status === Enum_Tasktype_Status.Active) {
          await deactivateTaskType({
            variables: {
              id: taskTypeID ? taskTypeID : id
            }
          })
        } else {
          await activateTaskType({
            variables: {
              id: taskTypeID ? taskTypeID : id
            }
          })
        }

        onClose()
        await refetchTaskType()
        if (refetch) await refetch()
      } catch (error) {
        console.error({ error })
      }
    }

    const isDrafted = React.useMemo(() => {
      if (taskTypeID)
        return (
          (history.location.state as any)?.isDrafted ||
          find(suspendedTaskTypes, (tasktype) => tasktype?.seletedTaskType === taskTypeID) !==
            undefined
        )
      else
        return (
          (history.location.state as any)?.isDrafted ||
          find(suspendedTaskTypesDetails, (tasktype) => tasktype?.seletedTaskType === id) !==
            undefined
        )
    }, [history.location.state, suspendedTaskTypes, suspendedTaskTypesDetails, taskTypeID])

    const [differences, setDifferences] = React.useState(() => [])
    const [fieldsDifferences, setFieldsDifferences] = React.useState(() => [])

    const isDirty = React.useMemo(
      () => differences?.length > 0 || fieldsDifferences?.length > 0,
      [
        inputFields,
        taskTypeMetadate,
        differences?.length,
        fieldsDifferences?.length,
        history.location
      ]
    )

    React.useEffect(() => {
      setDifferences(
        reduce(
          taskTypeMetadate,
          (result, value, key) => {
            return isEqual(value, oldTaskTypeMetadata.current[key])
              ? result
              : result.concat({ [key]: value } as any)
          },
          []
        )
      )
      setFieldsDifferences(
        reduce(
          inputFields,
          (result, value, key) => {
            return isEqual(omit(value, 'icon'), omit(oldDataStructure.current[key], 'icon'))
              ? result
              : result.concat({ [key]: value } as any)
          },
          []
        )
      )
    }, [inputFields, inputFields.length, taskTypeMetadate, history.location, isDirty])

    const [drafting, setDrafting] = React.useState(false)

    React.useEffect(() => {
      const fields = (history.location.state as any)?.fields
        ? (
            JSON.parse(
              replaceQuotationMarks((history.location.state as any)?.fields)
            ) as SupportedDataFieldType[]
          ).map((f) => ({
            ...omit(f, 'icon')
          }))
        : undefined
      const meta = (history.location.state as any)?.taskTypeMeta

      if (isDrafted || isDraft) {
        const found = find(
          taskTypeID ? suspendedTaskTypes : suspendedTaskTypesDetails,
          (tasktype) => tasktype.seletedTaskType === data?.getTaskType?.id
        )

        if (found) {
          const foundMeta = found.taskTypeMeta

          const foundFields = found.fields
            ? (
                JSON.parse(replaceQuotationMarks(found.fields) || '') as SupportedDataFieldType[]
              ).map((f) => ({
                ...omit(f, 'icon')
              }))
            : undefined
          const newlyFoundFileds = foundFields?.map((field) => ({
            ...field,
            icon: <FaPenAlt size={11} />
          })) as SupportedDataFieldType[]
          const areEquals =
            isEqual(foundMeta, taskTypeMetadate) &&
            isEqual(
              newlyFoundFileds?.map((field) => omit(field, 'icon')),
              inputFields?.map((field) => omit(field, 'icon'))
            )

          if (foundMeta && foundFields && !areEquals) {
            setTaskTypeMetadata(foundMeta)
            setInputFields(newlyFoundFileds)
          }
          history.location.state = undefined
        }
      } else if (fields && meta) {
        if (data?.getTaskType) {
          setTaskTypeMetadata(meta)
          setInputFields(
            fields.map((field) => ({
              ...field,
              icon: <FaPenAlt size={11} />
            }))
          )
        }
      } else pupulateFormContent()
    }, [data, isDraft, isDrafted, taskTypeID, suspendedTaskTypes, suspendedTaskTypesDetails]) // <-- here

    React.useEffect(() => {
      oldDataStructure.current = cloneDeep(taskTypeData)
    }, [taskTypeData])

    React.useEffect(() => {
      oldTaskTypeMetadata.current = cloneDeep(taskTypeMetadata)
    }, [taskTypeMetadata])

    const [openQuestionModal, setOpenQuestionModal] = React.useState(false)
    const { isOpen: isDrawerOpen, onClose: onDrawerClose, onOpen: _onDrawerOpen } = useDisclosure()

    const [errors, setErrors] = React.useState<OptionType>()

    const handleInputChange = React.useCallback(
      (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        index: number,
        propertyIndex: number
      ) => {
        const values = cloneDeep(inputFields)
        values[index].properties[propertyIndex] = {
          [event.target.name]: event.target.value
        }
        setInputFields(values)
        if (event.target.value === '' && event.target.required) {
          setErrors((prev) => ({
            ...prev,
            [event.target.name]: `${event.target.name} is required`
          }))
        } else {
          setErrors((prev) => omit(prev, [event.target.name]))
        }
      },
      [inputFields]
    )
    const resetForm = React.useCallback(() => {
      if (data?.getTaskType) {
        setTaskTypeMetadata(taskTypeMetadata)

        setInputFields(cloneDeep(oldDataStructure.current))
      }
      cleanDraft()
    }, [data, cleanDraft])

    const isFormValid = React.useMemo(() => {
      if (taskTypeMetadate) {
        if (errors?.metric) {
          return false
        }
        const { category, title } = taskTypeMetadate
        return (
          !!(category && title) &&
          Object.keys(errors || {})?.length === 0 &&
          taskTypeMetadate?.title?.length <= 30
        )
      }
      return false
    }, [taskTypeMetadate, errors])

    React.useEffect(() => {
      setErrors({})
    }, [taskTypeID, id])

    const handleSubmit = React.useCallback(async () => {
      if (!isFormValid) return
      try {
        const dataFieldsArr = inputFields?.map(({ properties, __component }) => ({
          properties,
          __component
        }))

        await updateTaskType({
          variables: {
            input: { ...taskTypeMetadate, un_sdg: taskTypeMetadate.un_sdg || null },
            dataFieldsArr,
            id: taskTypeID ? taskTypeID || '' : id || ''
          }
        })
      } catch (error) {
        console.error({ error })
      }
    }, [inputFields, updateTaskType, taskTypeMetadate, taskTypeID, id, isFormValid, errors])

    const handleAddField = (question: string, fieldKey: SupportedDataFieldKeysType) => {
      const newField = { ...SupportedDataFields[fieldKey] }
      const properties = newField?.properties.map((property) => {
        if (Object.keys(property)[0] === 'label') {
          return { label: question }
        }
        if (Object.keys(property)[0] === 'metric') {
          setErrors((prev) => ({ ...prev, metric: 'metric is required' }))
        }
        return property
      })
      setInputFields([...inputFields, { ...newField, properties }])
    }

    const {
      isOpen: isPreviewOpen,
      onClose: onPreviewClose,
      onOpen: onPreviewOpen
    } = useDisclosure()

    const handleAddOption = React.useCallback(
      (fieldIndex: number, propertyIndex: number) => {
        const values = [...inputFields]
        const itemToChange = cloneDeep(values[fieldIndex])
        const properties = [...itemToChange['properties']]
        const propertyOptions = [
          ...(properties[propertyIndex]['option'] as OptionType[]),
          {
            option: '',
            __component: 'combo-field.option'
          }
        ]
        properties[propertyIndex]['option'] = propertyOptions

        values[fieldIndex] = itemToChange
        setInputFields(values)
      },
      [inputFields]
    )

    const handleOptionLabelChange = React.useCallback(
      (event: any, fieldIndex: number, propertyIndex: number, optionIndex: number) => {
        const values = [...inputFields]
        // @ts-ignore
        values[fieldIndex]['properties'][propertyIndex]['option'][optionIndex] = {
          option: event.target.value
        }
        setInputFields(values)
      },
      [inputFields]
    )

    const handleDeleteOption = React.useCallback(
      (fieldIndex: number, propertyIndex: number, optionIndex: number) => {
        const values = [...inputFields]
        // @ts-ignore
        values[fieldIndex]['properties'][propertyIndex]['option'].splice(optionIndex, 1)
        setInputFields(values)
      },
      [inputFields]
    )
    const onDataListModalClose = React.useCallback(async () => {
      await refetchDataLists()
      onDataListClose()
    }, [refetchDataLists, onDataListClose])

    const isActive = data?.getTaskType?.attributes?.status === Enum_Tasktype_Status.Active
    const handleRemoveField = React.useCallback(
      (index: number) => {
        const values = [...inputFields]
        values.splice(index, 1)
        setInputFields(values)
      },
      [inputFields, setInputFields]
    )
    React.useEffect(() => {
      if (isDirty) {
        if (taskTypeMetadate && inputFields?.length > 0) {
          setDrafting(true)
          const handler = setTimeout(() => {
            const newData = {
              fields: JSON.stringify(inputFields),
              taskTypeMeta: taskTypeMetadate,
              seletedTaskType: taskTypeID ? taskTypeID : id
            }

            if (suspendedTaskTypes || suspendedTaskTypesDetails) {
              const found = findIndex(
                taskTypeID ? suspendedTaskTypes : suspendedTaskTypesDetails,
                (task) => {
                  const ID = taskTypeID ? taskTypeID : id
                  return task?.seletedTaskType === ID
                }
              )

              if (found > -1) {
                const temp = [
                  ...((taskTypeID
                    ? suspendedTaskTypes
                    : suspendedTaskTypesDetails) as SuspendedTaskTypes[])
                ]
                temp[found] = newData
                if (taskTypeID) setSuspendedTaskTypes?.(temp)
                else setSuspendedTaskTypesDetails(temp)
              } else {
                if (taskTypeID)
                  setSuspendedTaskTypes?.([
                    ...(suspendedTaskTypes as SuspendedTaskTypes[]),
                    newData
                  ])
                else
                  setSuspendedTaskTypesDetails([
                    ...(suspendedTaskTypesDetails as SuspendedTaskTypes[]),
                    newData
                  ])
              }
            } else {
              if (taskTypeID) setSuspendedTaskTypes?.([newData])
              else setSuspendedTaskTypesDetails([newData])
            }
            setDrafting(false)
          }, 1000)

          return () => {
            clearTimeout(handler)
          }
        }
      }
      if (!isDirty && taskTypeMetadate && inputFields?.length > 0) {
        const handler = setTimeout(() => {
          cleanDraft()
          setDrafting(false)
        }, 1000)
        return () => {
          clearTimeout(handler)
        }
      }
    }, [
      isDirty,
      taskTypeID,
      taskTypeMetadate,
      inputFields,
      differences?.length,
      fieldsDifferences?.length
    ])

    const onBack = () => {
      history.push('/auth/task-types-list', {
        seletedTaskType: data?.getTaskType?.id,
        ...(isDirty && {
          fields: JSON.stringify(inputFields),
          taskTypeMeta: taskTypeMetadate
        })
      })
    }
    const maximise = React.useCallback(() => {
      history.push(`/auth/task-types-list/detail/${taskTypeID}`, {
        ...(isDirty && { fields: JSON.stringify(inputFields), taskTypeMeta: taskTypeMetadate })
      })
    }, [inputFields, taskTypeID, taskTypeMetadate, isDrafted, isDraft])

    const loading = taskLoading || updatingTaskType || !data?.getTaskType

    const [isPresent, safeToRemove] = usePresence()

    const animations = {
      layout: true,
      initial: 'out',
      position: isPresent ? 'static' : 'absolute',
      style: {
        width: '100%'
      },
      animate: isPresent ? 'in' : 'out',

      variants: {
        in: { opacity: 1, y: 0 },
        out: { opacity: 0, y: '5%', zIndex: 4 }
      },
      onAnimationComplete: () => !isPresent && safeToRemove(),
      transition
    }

    const draftStatus = (style: 'md' | 'sm' = 'md') => {
      const changes = differences?.length + fieldsDifferences?.length
      return (
        <>
          {(isDraft || isDrafted) && (
            <HStack>
              <Skeleton
                {...(style === 'md' ? { my: 2 } : {})}
                maxW="fit-content"
                isLoaded={!loading}
              >
                <StatusBadge
                  {...(style === 'md' ? { indicator: true } : { p: 2 })}
                  colorScheme="orange"
                >
                  {drafting ? 'Drafting' : 'Drafted'} {drafting && <Spinner size="xs" ml={2} />}
                </StatusBadge>
              </Skeleton>
              <Skeleton isLoaded={!loading}>
                <HStack>
                  <Box rounded="full" boxSize={1.5} bg="orange.300" />
                  <Text noOfLines={1} color="gray.500" fontSize="x-small">
                    {changes} {pluralHandler('change', changes)}
                  </Text>
                </HStack>
              </Skeleton>
            </HStack>
          )}
        </>
      )
    }
    const options = React.useCallback(() => {
      return (
        <Skeleton isLoaded={!loading} ml={2}>
          <HStack justifyContent="flex-end" flex={1}>
            {data?.getTaskType && !loading && (
              <AnimatePresence>
                <Btn
                  onClick={onDuplicateTask}
                  key={0}
                  flex={1}
                  minW={24}
                  maxW={32}
                  px={4}
                  variant="outline"
                  leftIcon={<VscCopy />}
                  isLoading={updatingTaskType}
                  {...animations}
                >
                  {'Duplicate'}
                </Btn>
                <Btn
                  onClick={onOpen}
                  colorScheme={!isActive ? 'green' : 'red'}
                  key={1}
                  flex={1}
                  minW={24}
                  maxW={32}
                  px={4}
                  variant="outline"
                  leftIcon={isActive ? <VscTrash /> : <VscCheck />}
                  isLoading={updatingTaskType}
                  {...animations}
                >
                  {isActive ? 'Turn off' : 'Turn on'}
                </Btn>
                {isDirty && (
                  <Btn
                    onClick={resetForm}
                    variant="outline"
                    flex={1}
                    minW={24}
                    maxW={32}
                    key={2}
                    leftIcon={<VscRefresh />}
                    isLoading={updatingTaskType}
                    {...animations}
                  >
                    Reset
                  </Btn>
                )}

                {taskTypeID && (
                  <Btn
                    onClick={onPreviewOpen}
                    key={3}
                    flex={1}
                    minW={24}
                    maxW={32}
                    variant="outline"
                    leftIcon={<VscEye />}
                    isLoading={updatingTaskType}
                    {...animations}
                  >
                    Preview
                  </Btn>
                )}
                {isDirty && inputFields?.length > 0 && (
                  <Btn
                    onClick={handleSubmit}
                    colorScheme="green"
                    w="100%"
                    flex={2}
                    key={4}
                    isDisabled={!isDirty || !isFormValid}
                    leftIcon={<VscSave />}
                    isLoading={updatingTaskType}
                    {...animations}
                  >
                    Save
                  </Btn>
                )}
              </AnimatePresence>
            )}
          </HStack>
        </Skeleton>
      )
    }, [
      onOpen,
      updatingTaskType,
      pupulateFormContent,
      isActive,
      isDirty,
      loading,
      inputFields,
      handleSubmit
    ])
    React.useEffect(() => {
      if (getOptions)
        getOptions(
          options,
          <Center>
            {data?.getTaskType && !loading && (
              <>
                <Image
                  boxSize={6}
                  mr={2}
                  objectFit="cover"
                  rounded="full"
                  src={
                    data?.getTaskType?.attributes?.category?.data?.attributes?.featureImage?.data
                      ?.attributes?.formats?.thumbnail?.url
                      ? data?.getTaskType?.attributes?.category?.data?.attributes?.featureImage
                          ?.data?.attributes?.formats?.thumbnail?.url
                      : images.logoNoText
                  }
                />
                <Heading
                  fontSize="md"
                  mr={1}
                  w="fit-content"
                  {...(taskTypeID && {
                    cursor: 'pointer',
                    transition: 'all .1s ease-in-out 0s',
                    onClick: maximise,
                    _hover: {
                      textDecoration: 'underline',
                      color: 'gray.500'
                    }
                  })}
                  as="h3"
                  color="gray.600"
                >
                  <Center>
                    <Text noOfLines={2} mr={2}>
                      {data?.getTaskType?.attributes?.title}
                    </Text>
                    {taskTypeID && <Maximize2 size={12} />}
                  </Center>
                </Heading>
                <StatusBadge mx={2} p={2} indicator colorScheme={isActive ? 'green' : 'red'}>
                  {isActive ? 'On' : 'Off'}
                </StatusBadge>

                {draftStatus('sm')}
              </>
            )}
          </Center>
        )
    }, [options, drafting])

    return (
      <>
        <DeactivateModal
          {...{ isOpen, onClose }}
          updatingTaskType={updatingTaskType}
          taskType={data?.getTaskType as TaskTypeEntity}
          handleDeleteTaskType={handleDeleteTaskType}
        />

        <SubNav
          leftElement={options()}
          onGoBack={onBack}
          rightElement={
            <HStack>
              <Heading fontSize="md" as="h3" color="gray.600">
                Editing{' '}
                <Text as="span" color="green.800">
                  {data?.getTaskType?.attributes?.title}
                </Text>
              </Heading>
              <StatusBadge mx={2} indicator colorScheme={isActive ? 'green' : 'red'}>
                {isActive ? 'On' : 'Off'}
              </StatusBadge>
              {draftStatus()}
            </HStack>
          }
          image={
            data?.getTaskType?.attributes?.category?.data?.attributes?.featureImage?.data
              ?.attributes?.formats?.thumbnail?.url
              ? `${data?.getTaskType?.attributes?.category?.data?.attributes?.featureImage?.data?.attributes?.formats?.thumbnail?.url}`
              : images.logoNoText
          }
        />
        <DataList {...{ isOpen: isDataListOpen, onClose: onDataListModalClose }} />
        <PageWrap
          pt={taskTypeID ? 0 : undefined}
          inline={inline}
          title={taskTypeID ? 'Tasks' : `Editing ${data?.getTaskType?.attributes?.title}`}
        >
          {!taskTypeID && (
            <Container my={8} maxW="1600" px={16}>
              <Flex>
                <BackButton
                  title={`Back to ${
                    projectRef.current
                      ? projectRef.current?.getSingleBaoleaderProject?.attributes?.name
                      : 'the list of tasks'
                  }`}
                  onClick={() =>
                    projectRef.current
                      ? history.push(
                          `/auth/project/${projectRef.current?.getSingleBaoleaderProject?.id}`
                        )
                      : onBack()
                  }
                />
              </Flex>
            </Container>
          )}

          <Flex
            pb={20}
            bg="background.100"
            borderRadius={!taskTypeID ? '1.5rem 1.5rem 0 0' : 0}
            borderColor="gray.200"
            flex={1}
            direction="column"
            w="100%"
          >
            <Container
              maxW="1600"
              px={taskTypeID ? 6 : 14}
              pt={taskTypeID ? 6 : 16}
              position="relative"
            >
              {!taskTypeID && (
                <TableIcon position="right" loading={updatingTaskType} icon={<FcInspection />} />
              )}

              <Flex
                justify="space-between"
                ref={ref}
                mb={2}
                mr={!taskTypeID ? '350px' : '0'}
                align="center"
              >
                <Center width="fit-content">
                  <Skeleton isLoaded={!loading} mr={2}>
                    <Heading
                      {...(taskTypeID && {
                        cursor: 'pointer',
                        transition: 'all .1s ease-in-out 0s',
                        size: 'lg',
                        onClick: maximise,
                        _hover: {
                          textDecoration: 'underline',
                          color: 'gray.600'
                        }
                      })}
                      width="fit-content"
                      as="h2"
                    >
                      <Center>
                        <Text noOfLines={2} mr={3}>
                          {!taskTypeID && 'Editing'}{' '}
                          <Text as="span" color="green.800">
                            {data?.getTaskType?.attributes?.title}
                          </Text>
                        </Text>
                        {taskTypeID && (
                          <CustomTooltip
                            label={`Open "${data?.getTaskType?.attributes?.title}" in full screen`}
                          >
                            <Maximize2 size={20} />
                          </CustomTooltip>
                        )}
                      </Center>
                    </Heading>
                  </Skeleton>
                  <Skeleton isLoaded={!loading}>
                    <StatusBadge mx={2} indicator colorScheme={isActive ? 'green' : 'red'}>
                      {isActive ? 'On' : 'Off'}
                    </StatusBadge>
                  </Skeleton>
                </Center>
                {options()}
              </Flex>

              <Flex
                align="center"
                fontSize="x-small"
                mr={!taskTypeID ? '350px' : '0'}
                justify="space-between"
              >
                <Skeleton isLoaded={!loading}>
                  <Text color="gray.500">
                    Created by{' '}
                    <Link onClick={() => history.push('/auth/settings')}>
                      <b>
                        {data?.getTaskType?.attributes?.creator?.data?.id == user?.id
                          ? `${data?.getTaskType?.attributes?.creator?.data?.attributes?.firstName} (Me)`
                          : `${data?.getTaskType?.attributes?.creator?.data?.attributes?.firstName} ${data?.getTaskType?.attributes?.creator?.data?.attributes?.lastName}`}
                      </b>
                    </Link>
                    , {moment(data?.getTaskType?.attributes?.createdAt).fromNow()}
                  </Text>
                </Skeleton>
                <Skeleton isLoaded={!loading}>
                  <Text color="gray.500">
                    Updated {moment(data?.getTaskType?.attributes?.updatedAt).fromNow()}
                  </Text>
                </Skeleton>
              </Flex>
              <Flex align="center">{draftStatus()}</Flex>

              <TaskTypeMetadata
                mr={!taskTypeID ? '350px' : '0'}
                setErrors={setErrors}
                loading={loading}
                differences={differences}
                taskType={data?.getTaskType as TaskTypeEntity}
                errors={errors}
                setTaskTypeMetadata={setTaskTypeMetadata}
                taskTypeMetadata={taskTypeMetadate}
              />

              <Skeleton isLoaded={!loading} maxW="fit-content">
                <Heading size="md" as="h3">
                  Data Fields
                </Heading>
              </Skeleton>
              <Flex>
                <Flex flex={1} alignItems="center" flexDirection="column">
                  {inputFields?.length === 0 && (
                    <VStack>
                      <Image w={450} draggable="false" src={images.baotreeHomeBg4} />
                      <Heading fontSize="xl">This form is currently empty</Heading>
                      <Text fontSize="sm">
                        You can add questions, notes, prompts, or other fields by clicking the
                        &apos;+&apos; sign below.
                      </Text>
                    </VStack>
                  )}
                  {loading ? (
                    <Center w="100%" h="20vh">
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="green.500"
                        size="lg"
                      />
                    </Center>
                  ) : (
                    <AnimatePresence>
                      {inputFields?.map((inputField, inputIndex) => (
                        <DynamicInputField
                          key={`field-${inputIndex}-${inputField.display_title}`}
                          errors={errors}
                          inputField={inputField}
                          inputIndex={inputIndex}
                          loading={loading}
                          onDataListOpen={onDataListOpen}
                          activeDataLists={activeDataLists?.getDataLists as DataListType[]}
                          differences={fieldsDifferences}
                          original={oldDataStructure.current}
                          setInputFields={setInputFields}
                          collectionLength={inputFields?.length}
                          handleRemoveField={handleRemoveField}
                          handleDeleteOption={handleDeleteOption}
                          handleOptionLabelChange={handleOptionLabelChange}
                          handleAddOption={handleAddOption}
                          handleInputChange={handleInputChange}
                        />
                      ))}
                    </AnimatePresence>
                  )}
                  <VStack py={8} spacing={16} width="100%">
                    <Button
                      colorScheme="green"
                      variant="ghost"
                      onClick={() => setOpenQuestionModal(true)}
                      fontSize="xs"
                      leftIcon={<VscAdd />}
                    >
                      Add Field
                    </Button>
                  </VStack>
                </Flex>
                {!taskTypeID && (
                  <Flex w={350}>
                    <MobileViewRenderer taskTypeMetadate={taskTypeMetadate} fields={inputFields} />
                  </Flex>
                )}
              </Flex>
            </Container>
          </Flex>
          {taskTypeID && (
            <Drawer onClose={onPreviewClose} isOpen={isPreviewOpen} size="sm">
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Preview for {data?.getTaskType?.attributes?.title}</DrawerHeader>
                <DrawerBody>
                  <Flex w={350}>
                    <MobileViewRenderer taskTypeMetadate={taskTypeMetadate} fields={inputFields} />
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          )}
          <QuestionModal
            isOpen={openQuestionModal}
            isLoading={false}
            onClose={() => setOpenQuestionModal(false)}
            onConfirm={(question, fieldType) => handleAddField(question, fieldType)}
          />
          <OptionsDrawer isOpen={isDrawerOpen} onClose={onDrawerClose} />
        </PageWrap>
      </>
    )
  }
)

export default TaskTypeDetails
