import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const SlidingCardContainer = styled(Box)`
  position: relative;
  width: 100%;
  .community-card-front,
  .community-card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out 0s;
    border-radius: 1rem;
    box-shadow: 0px 2rem 1rem -1rem rgba(0, 0, 0, 0.01);
  }
  .community-card-front {
    position: relative;
    z-index: 3;
  }

  .community-card-back {
  }
  &:hover {
    .community-card-back {
      transform: translateY(30%) scale(1.1);
    }

    .community-card-front {
      transform: translateY(-15%);
    }
  }
`
