import { GetTaskTypeQuery } from 'generated/graphql'
import { omit } from 'lodash'
import React from 'react'
import { SupportedDataFields } from './constants'

import { SupportedDataFieldType } from './types'
export const useTaskTypes = (data: GetTaskTypeQuery | undefined) =>
  React.useMemo(
    () =>
      data?.getTaskType?.attributes?.dataFields?.map((field) => {
        switch (field?.__typename) {
          case 'ComponentCustomDataFieldsRadio':
            return {
              ...omit(SupportedDataFields['Radio'], 'properties'),
              properties: [
                { label: field?.label },
                { option: field?.option?.map((opt) => ({ option: opt?.option })) },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType

          case 'ComponentCustomDataFieldsCheckbox':
            return {
              ...omit(SupportedDataFields['CheckBox'], 'properties'),
              properties: [
                { label: field?.label },
                { option: field?.option?.map((opt) => ({ option: opt?.option })) },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType

          case 'ComponentCustomDataFieldsText':
            return {
              ...omit(SupportedDataFields['Text'], 'properties'),
              properties: [{ label: field?.label }, { isRequired: field?.isRequired }]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsNumberField':
            return {
              ...omit(SupportedDataFields['Number'], 'properties'),
              properties: [{ label: field?.label }, { isRequired: field?.isRequired }]
            } as SupportedDataFieldType

          case 'ComponentCustomDataFieldsSelect':
            return {
              ...omit(SupportedDataFields['Select'], 'properties'),
              properties: [
                { label: field?.label },
                { option: field?.option?.map((opt) => ({ option: opt?.option })) },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsPercentage':
            return {
              ...omit(SupportedDataFields['Percentage'], 'properties'),
              properties: [{ label: field?.label }, { isRequired: field?.isRequired }]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsNumberDropDown':
            return {
              ...omit(SupportedDataFields['NumberDropDown'], 'properties'),
              properties: [
                { label: field?.label },
                { min: field?.min },
                { max: field?.max },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsMetricsSpecific':
            return {
              ...omit(SupportedDataFields['MetricSpecific'], 'properties'),
              properties: [
                { label: field?.label },
                { metric: field?.metric },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsRating':
            return {
              ...omit(SupportedDataFields['Rating'], 'properties'),
              properties: [
                { label: field?.label },
                { number_of_stars: field?.number_of_stars },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsNumberRange':
            return {
              ...omit(SupportedDataFields['NumberRange'], 'properties'),
              properties: [
                { label: field?.label },
                { min: field?.min },
                { max: field?.max },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsDateTimeField':
            return {
              ...omit(SupportedDataFields['DateTime'], 'properties'),
              properties: [{ label: field?.label }, { isRequired: field?.isRequired }]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsDataList':
            return {
              ...omit(SupportedDataFields['DataList'], 'properties'),
              properties: [
                { label: field?.label },
                { listId: field?.listId },
                { isRequired: field?.isRequired }
              ]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsEmailField':
            return {
              ...omit(SupportedDataFields['EmailAddress'], 'properties'),
              properties: [{ label: field?.label }, { isRequired: field?.isRequired }]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsPhoneNumberField':
            return {
              ...omit(SupportedDataFields['PhoneNumberField'], 'properties'),
              properties: [{ label: field?.label }, { isRequired: field?.isRequired }]
            } as SupportedDataFieldType
          case 'ComponentCustomDataFieldsImageField':
            return {
              ...omit(SupportedDataFields['ImageField'], 'properties'),
              properties: [{ label: field?.label }, { isRequired: field?.isRequired }]
            } as SupportedDataFieldType

          default:
            return {
              properties: [{ label: 'Unknown field type' }, { isRequired: false }],
              icon: '' as any,
              __component: 'custom-data-fields.text',
              display_title: 'Unknown'
            } as SupportedDataFieldType
        }
      }) || [],
    [data]
  )
