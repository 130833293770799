import { Skeleton, SkeletonCircle, HStack } from '@chakra-ui/react'
import React from 'react'

const TableLoader: React.FC<{ n?: number }> = ({ n }) => {
  const arr = Array.from(Array(10).keys())
  const innerArr = Array.from(Array(n).keys())
  return (
    <>
      {arr.map((item) => (
        <HStack my={3} px={3} spacing={8} key={item}>
          <SkeletonCircle p={4} size="10" />
          <HStack spacing={4} w="100%">
            {innerArr.map((item) => (
              <Skeleton key={item} height="10px" w="100%" />
            ))}
          </HStack>
        </HStack>
      ))}
    </>
  )
}
TableLoader.defaultProps = {
  n: 7
}
export default TableLoader
