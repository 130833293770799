import { HStack, Center, Button, Select } from '@chakra-ui/react'
import { CustomTooltip, SearchBar } from 'components'
import { Sorts, SearchFilters } from 'features/task-builder/types'
import React from 'react'
import { VscArrowUp, VscArrowDown } from 'react-icons/vsc'

const sortByOptions = [
  {
    label: 'By category',
    value: 'category'
  },
  {
    label: 'By task',
    value: 'task_type'
  },
  {
    label: 'By date created',
    value: 'createdAt'
  },
  {
    label: 'By date updated',
    value: 'updated_at'
  }
]

type ListOptionsProps = {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  sort: Sorts
  loading?: boolean
  sortBy: SearchFilters | undefined
  setSortBy: React.Dispatch<React.SetStateAction<SearchFilters | undefined>>
  setSort: React.Dispatch<React.SetStateAction<Sorts>>
}
const ListOptions: React.FC<ListOptionsProps> = ({
  sort,
  setSearchTerm,
  loading,
  setSort,
  sortBy,
  setSortBy
}) => {
  return (
    <HStack spacing={1} justifyContent="space-between" pr={4} py={1}>
      <Center>
        <SearchBar
          minW="160px"
          maxW="200px"
          withIcon={false}
          isLoading={loading}
          onSearch={(value) => {
            setSearchTerm(value)
          }}
        />
      </Center>
      <HStack>
        <CustomTooltip label={`Sort by ${sortBy?.split('_').join(' ')}`}>
          <Button
            fontSize="x-small"
            px={3}
            rounded="2xl"
            variant="outline"
            onClick={() => {
              setSort((prev) =>
                prev === 'asc'
                  ? 'desc'
                  : prev === 'desc'
                  ? undefined
                  : prev === undefined
                  ? 'asc'
                  : undefined
              )
            }}
            isLoading={loading}
            rightIcon={
              sort === 'asc' ? <VscArrowUp /> : sort === 'desc' ? <VscArrowDown /> : undefined
            }
          >
            Sort
          </Button>
        </CustomTooltip>
        <Select
          flex={1}
          variant="filled"
          size="md"
          fontSize="x-small"
          focusBorderColor="green.200"
          defaultValue="createdAt"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSortBy(e.target.value as SearchFilters)
          }
        >
          {sortByOptions?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </HStack>
    </HStack>
  )
}

export default ListOptions
