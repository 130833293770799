import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalProps,
  Heading,
  Box
} from '@chakra-ui/react'
import React from 'react'
import { capitalize } from 'lodash'

import { VStack } from '@chakra-ui/react'
import { FcCheckmark, FcFullTrash } from 'react-icons/fc'
import { SecondaryButton, PrimaryButton } from 'components/UI/Buttons'

export type AlertModalProps = {
  isOpen: boolean
  activatorStatus?: 'activate' | 'deactivate'
  onClose: () => void
  loading?: boolean
  onConfirm: (id?: string) => Promise<void> | void
  confirmButtonText?: string
  confirmButtonColor?: string
  title?: string
  icon?: React.ReactNode
} & ModalProps

const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
  activatorStatus,
  title,
  confirmButtonText,
  confirmButtonColor,
  children,
  icon,
  ...rest
}) => {
  const isActive = activatorStatus === 'activate'
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      size="xs"
      motionPreset="slideInBottom"
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody fontSize="sm">
          <VStack mt={8} spacing={6} alignItems="flex-start">
            {icon ? icon : isActive ? <FcCheckmark size={38} /> : <FcFullTrash size={38} />}
            <Box color="gray.500" fontSize="xs">
              <Heading size="sm" mb={2} fontWeight={500} color="gray.800" as="h2">
                {title}
              </Heading>
              {children}
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <SecondaryButton flex={1} mr={3} onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            flex={1}
            isLoading={loading}
            onClick={() => onConfirm()}
            colorScheme={
              confirmButtonColor
                ? confirmButtonColor
                : activatorStatus === 'activate'
                ? 'green'
                : 'red'
            }
          >
            {confirmButtonText
              ? confirmButtonText
              : activatorStatus
              ? capitalize(activatorStatus)
              : 'Delete'}
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

AlertModal.defaultProps = {
  title: 'Delete'
}

export default AlertModal
