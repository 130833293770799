import * as React from 'react'

type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: () => void
  isLive: boolean
  setIsLive: React.Dispatch<React.SetStateAction<boolean>>
  connected: boolean
  setConnected: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,
  isLive: false,
  setIsLive: () => null,
  connected: false,
  setConnected: () => null
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const [connected, setConnected] = React.useState<boolean>(false)
  const [isLive, setIsLive] = React.useState<boolean>(false)
  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <AppContext.Provider
      value={{ drawerOpen, toggleDrawer, isLive, setIsLive, connected, setConnected }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
