import React from 'react'
import { Heading, HStack, Text, VStack } from '@chakra-ui/react'
type FieldTypeAndDescriptionPairProps = {
  fields?: {
    fieldType: string
    description: string
  }[]
}
const FieldTypeAndDescriptionPair: React.FC<FieldTypeAndDescriptionPairProps> = ({ fields }) => {
  return (
    <VStack w="100%">
      <HStack w="100%" spacing={7}>
        <Heading size="sm" minW="10rem">
          Field Type
        </Heading>
        <Heading size="sm" minW="10rem">
          Description
        </Heading>
      </HStack>
      {fields?.map((field, index) => (
        <HStack key={index} w="100%" spacing={7}>
          <Heading
            fontSize="xs"
            minW="10rem"
            bg="green.100"
            rounded="md"
            textAlign="center"
            px={3}
            py={5}
          >
            {field.fieldType}
          </Heading>
          <Text bg="gray.100" p={4} rounded="md" w="100%" fontSize="sm">
            {field.description}
          </Text>
        </HStack>
      ))}
    </VStack>
  )
}

FieldTypeAndDescriptionPair.defaultProps = {
  fields: []
}

export default FieldTypeAndDescriptionPair
