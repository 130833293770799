import {
  useToast,
  VStack,
  Flex,
  Avatar,
  Checkbox,
  Text,
  Box,
  Heading,
  HStack,
  Container,
  Image,
  Spinner,
  Center
} from '@chakra-ui/react'
import { EmptyListHandler, ModalWrap, FloatingButtonWrapper, Card } from 'components'
import { DATA_COLLECTOR, ERROR_TOAST } from '../../../constants'
import {
  CommunityEntity,
  Enum_Userspermissionsuser_Status,
  useGetBaoridersLazyQuery,
  useGetCommunitiesLazyQuery
} from 'generated/graphql'
import React, { useEffect, useMemo, useState } from 'react'
import { VscAdd } from 'react-icons/vsc'
import { UsersPermissionsUserEntity, ProjectEntity, Maybe } from '../../../generated/graphql'
import { PrimaryButton } from 'components/UI/Buttons'
import { images } from 'theme'
import { useHistory } from 'react-router-dom'

const VerifiersListModal: React.FC<{
  onAdd: (values: string[]) => void
  verifiers: Maybe<UsersPermissionsUserEntity>[]
  project: Maybe<ProjectEntity>
  onClose: () => void
  isOpen: boolean
}> = ({ onAdd, onClose, isOpen, project }) => {
  const toast = useToast()
  const onError = () => toast({ title: 'There was an error.', ...ERROR_TOAST })
  const whereProps = {
    status: Enum_Userspermissionsuser_Status.Active
  }
  const history = useHistory()
  const [getVerifiers, { data, loading: loadingVerifiers, refetch }] = useGetBaoridersLazyQuery({
    variables: {
      filters: whereProps
    },
    onError,
    fetchPolicy: 'cache-first'
  })

  const [getCommunities, { data: storedGroups, loading: groupsLoading }] =
    useGetCommunitiesLazyQuery({
      onError
    })

  useEffect(() => {
    if (isOpen) {
      ;(async () => {
        await getVerifiers({ variables: { filters: whereProps } })
        await getCommunities({ variables: { filters: whereProps } })
      })()
    }
  }, [isOpen])

  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const verifiersData = useMemo(() => {
    return (
      (data?.getBaoriders?.filter((verifier) => {
        return !verifier?.attributes?.projects?.data?.some(
          (verifierProject) => verifierProject?.id === project?.id
        )
      }) as UsersPermissionsUserEntity[]) || []
    )
  }, [data, project])

  const isIndeterminate = selectedUsers?.length > 0 && selectedUsers.length < verifiersData.length
  const isChecked = selectedUsers?.length > 0

  // setup the group data for multiselect Data Collectors based on group selection

  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const groupData = useMemo(
    () => (storedGroups?.getBaoleaderCommunities || []) as CommunityEntity[],
    [storedGroups]
  )
  const isGroupIndeterminate =
    selectedGroups?.length > 0 && selectedGroups.length < groupData.length
  const isGroupChecked = selectedGroups?.length > 0

  useEffect(() => {
    const selectedGroupSet = new Set(selectedGroups)
    const newSelectedUsers = new Set<string>()

    verifiersData.forEach((verifier) => {
      const groupId = verifier?.attributes?.community?.data?.id || ''
      if (selectedGroupSet.has(groupId)) {
        newSelectedUsers.add(verifier?.id || '')
      }
    })

    const newSelectedUsersArray = Array.from(newSelectedUsers)
    if (
      newSelectedUsersArray.length !== selectedUsers.length ||
      !newSelectedUsersArray.every((val, index) => val === selectedUsers[index])
    ) {
      setSelectedUsers(newSelectedUsersArray)
    }
  }, [selectedGroups, verifiersData])

  const handleGroupSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedGroups(groupData?.map((group) => group?.id || ''))
    } else {
      setSelectedGroups([])
      setSelectedUsers([])
    }
  }

  const handleGroupCheckboxChange = (groupId: string) => {
    setSelectedGroups((prev) => {
      const isSelected = prev.includes(groupId)
      if (isSelected) {
        // Deselect the group
        return prev.filter((id) => id !== groupId)
      } else {
        // Select the group
        return [...prev, groupId]
      }
    })
  }

  const handleUserCheckboxChange = (userId: string) => {
    setSelectedUsers((prev) => {
      const isSelected = prev.includes(userId)
      if (isSelected) {
        return prev.filter((id) => id !== userId)
      } else {
        return [...prev, userId]
      }
    })
  }

  const groupSelectTitle = 'Add Data Collectors By Group'
  const groupSelectEl = (
    <Box flex={1}>
      {groupsLoading || loadingVerifiers ? (
        <Center p={6}>
          <Spinner color="green.300" />
        </Center>
      ) : groupData.length === 0 ? (
        <EmptyListHandler title="No Groups" subTitle="" />
      ) : !(verifiersData?.length === 0) ? (
        <Card
          overflow="auto"
          bg="transparent"
          border="none"
          rounded="xl"
          justifyContent="flex-start"
        >
          <Container maxW="container.lg">
            <Heading as="h5" mb={8} size="md">
              {groupSelectTitle}
            </Heading>

            <HStack spacing={8}>
              <Box pb={8} flex={1.3}>
                <Checkbox
                  size="md"
                  isChecked={isGroupChecked}
                  colorScheme="green"
                  isIndeterminate={isGroupIndeterminate}
                  onChange={handleGroupSelectChange}
                >
                  <Text color="gray.600" fontSize="x-small">
                    {isGroupIndeterminate || isGroupChecked ? 'Disable all' : 'Select all'}
                  </Text>
                </Checkbox>
                <VStack flex={1} overflowY="auto" py={2} maxH="48vh">
                  {groupData?.map((group) => {
                    const isActive = selectedGroups.includes(group?.id || '')
                    return (
                      <Checkbox
                        size="md"
                        width="100%"
                        display="flex"
                        px={4}
                        py={2}
                        key={group?.id}
                        isChecked={isActive}
                        onChange={() => handleGroupCheckboxChange(group?.id || '')}
                        colorScheme="green"
                        rounded="xl"
                        bg={isActive ? 'green.50' : 'transparent'}
                        cursor="pointer"
                      >
                        <Flex align="center" px={4}>
                          <VStack flex={1} alignItems="flex-start" spacing={0}>
                            <Text
                              fontWeight={500}
                              color="gray.600"
                              fontSize="sm"
                              textAlign="center"
                            >
                              {group?.attributes?.name}
                            </Text>
                          </VStack>
                        </Flex>
                      </Checkbox>
                    )
                  })}
                </VStack>
              </Box>
            </HStack>
          </Container>
        </Card>
      ) : (
        <Center>
          <Image src={images.logo} alt="Empty" width="50%" objectFit="contain" />
        </Center>
      )}
    </Box>
  )

  return (
    <ModalWrap
      onClose={onClose}
      title={`${DATA_COLLECTOR}s`}
      image={project?.attributes?.featured_image?.data?.attributes?.url}
      rightElement={() => null}
      isOpen={isOpen}
    >
      <HStack alignItems="flex-start" spacing={10}>
        {loadingVerifiers ? (
          <Center p={6}>
            <Spinner color="green.300" />
          </Center>
        ) : verifiersData?.length === 0 ? (
          <EmptyListHandler
            title="Nothing to see here"
            subTitle="All current data collectors have been added to this Project"
            action={
              <PrimaryButton
                mt={8}
                onClick={() => history.push('/auth/baoriders', { isCreateModalOpen: true })}
              >
                Create New Data Collector
              </PrimaryButton>
            }
          />
        ) : (
          <Box flex={1.5}>
            <FloatingButtonWrapper
              isLoading={loadingVerifiers}
              button={
                selectedUsers.length > 0 && (
                  <PrimaryButton
                    leftIcon={<VscAdd />}
                    width="100%"
                    onClick={async () => {
                      await onAdd(selectedUsers)
                      setSelectedUsers([])
                      await refetch()
                    }}
                  >
                    Add {DATA_COLLECTOR}
                    {selectedUsers.length > 0 && `s (${selectedUsers.length})`}
                  </PrimaryButton>
                )
              }
            >
              <Box>
                <Checkbox
                  size="md"
                  isChecked={isChecked}
                  colorScheme="green"
                  isIndeterminate={isIndeterminate}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUsers(verifiersData?.map((verifier) => verifier?.id || ''))
                    } else {
                      setSelectedUsers([])
                    }
                  }}
                >
                  <Text color="gray.600" fontSize="x-small">
                    {isIndeterminate || isChecked ? 'Disable all' : 'Select all'}
                  </Text>
                </Checkbox>
                <VStack overflowY="auto" py={2} maxH="48vh">
                  {verifiersData?.map((verifier) => {
                    const isActive = selectedUsers.includes(verifier?.id || '')
                    return (
                      <Checkbox
                        size="md"
                        width="100%"
                        display="flex"
                        px={4}
                        py={2}
                        key={verifier?.id}
                        isChecked={isActive}
                        onChange={() => handleUserCheckboxChange(verifier?.id || '')}
                        colorScheme="green"
                        rounded="xl"
                        bg={isActive ? 'green.50' : 'transparent'}
                        cursor="pointer"
                      >
                        <Flex align="center" px={4}>
                          <Avatar
                            src={
                              verifier?.attributes?.profilePicture?.data?.attributes?.formats
                                ?.thumbnail?.url
                            }
                            my={4}
                            mr={4}
                            size="sm"
                            name={`${verifier?.attributes?.firstName} ${verifier?.attributes?.lastName}`}
                          />
                          <VStack flex={1} alignItems="flex-start" spacing={0}>
                            <Text
                              fontWeight={500}
                              color="gray.600"
                              fontSize="sm"
                              textAlign="center"
                            >
                              {verifier?.attributes?.firstName} {verifier?.attributes?.lastName}
                            </Text>
                            <Text fontSize="x-small" color="gray.500" textAlign="center">
                              {verifier?.attributes?.phoneNumber}
                            </Text>
                          </VStack>
                        </Flex>
                      </Checkbox>
                    )
                  })}
                </VStack>
              </Box>
            </FloatingButtonWrapper>
          </Box>
        )}
        {groupSelectEl}
      </HStack>
    </ModalWrap>
  )
}

export default VerifiersListModal
