import {
  FormControl,
  FormLabel,
  Input,
  Radio,
  Checkbox,
  Select,
  Stack,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  InputRightAddon,
  InputGroup,
  Box,
  theme,
  InputLeftAddon,
  InputRightElement
} from '@chakra-ui/react'
import * as React from 'react'
import { SupportedDataFieldType, DataFieldPropertyType } from '../../types'
import _ from 'lodash'
import Rating from 'react-rating'
import { VscStarFull, VscStarEmpty, VscCalendar, VscArrowDown } from 'react-icons/vsc'

import { FaCamera, FaClock } from 'react-icons/fa'
import { OptionType } from 'types'
import { HStack } from '@chakra-ui/react'
import { RiGalleryFill } from 'react-icons/ri'
import { BoxButton } from 'components/UI/Buttons'

const handleRequired = (field: SupportedDataFieldType) => {
  return (
    field?.properties?.[field?.properties?.length - 1].isRequired && (
      <span style={{ color: 'red' }}>*</span>
    )
  )
}
const InputRenderer: React.FC<{
  field: SupportedDataFieldType
}> = ({ field }) => {
  const findPropertyValue = (properties: DataFieldPropertyType[], key: string) => {
    let value = null
    properties.forEach((property: any) => {
      if (Object.keys(property)[0] === key) {
        value = property[key]
      }
    })
    return value
  }

  switch (field.__component) {
    case 'custom-data-fields.checkbox':
      const checkOptions = field?.properties[field?.properties?.length - 2]['option']
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          {(checkOptions as OptionType[])?.length > 0 && (
            <Stack spacing={3} direction="column">
              {(checkOptions as OptionType[]).map(({ option }, index) => (
                <Checkbox
                  key={`option-${index}`}
                  size="sm"
                  id={field.properties[0]?.label as string}
                >
                  <Text fontSize="xs">{option}</Text>
                </Checkbox>
              ))}
            </Stack>
          )}
        </FormControl>
      )
    case 'custom-data-fields.radio':
      const radioOptions = field?.properties[field?.properties?.length - 2]['option']
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          {(radioOptions as OptionType[])?.length > 0 && (
            <Stack spacing={3} direction="column">
              {(radioOptions as OptionType[]).map(({ option }, index) => (
                <Radio
                  size="sm"
                  key={`option-${index}`}
                  value={`${index}`}
                  id={field.properties[0]?.label as string}
                >
                  <Text fontSize="xs">{option}</Text>
                </Radio>
              ))}
            </Stack>
          )}
        </FormControl>
      )
    case 'custom-data-fields.select':
      const selectOptions = field?.properties[field?.properties?.length - 2]['option']
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <Select
            placeholder={''}
            size="sm"
            focusBorderColor="accent.500"
            variant="filled"
            fontSize="x-small"
          >
            {(selectOptions as OptionType[])?.length > 0 &&
              (selectOptions as OptionType[]).map(({ option }, index) => (
                <option key={index} value={option as string}>
                  {option}
                </option>
              ))}
          </Select>
        </FormControl>
      )
    case 'custom-data-fields.metrics-specific':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <InputGroup size="sm">
            <Input
              placeholder={field.properties[0]?.label as string}
              variant="filled"
              name={field.display_title}
            />
            <InputRightAddon fontSize="x-small">{field.properties[1]?.metric}</InputRightAddon>
          </InputGroup>
        </FormControl>
      )
    case 'custom-data-fields.text':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <Input variant="filled" name={field.display_title} />
        </FormControl>
      )
    case 'custom-data-fields.email-field':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <Input type="email" variant="filled" name={field.display_title} />
        </FormControl>
      )
    case 'custom-data-fields.phone-number-field':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <Input type="number" variant="filled" name={field.display_title} />
        </FormControl>
      )
    case 'custom-data-fields.rating':
      return (
        <Box>
          <Text fontWeight="semibold" mb={2} fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </Text>
          <Rating
            initialRating={0}
            stop={(field?.properties?.[1]?.number_of_stars as number) ?? 5}
            fullSymbol={<VscStarFull color={theme.colors.yellow[400]} size={24} />}
            emptySymbol={<VscStarEmpty size={23} />}
          />
        </Box>
      )
    case 'custom-data-fields.number-field':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <Input
            name={field.display_title}
            placeholder={field.properties[0]?.label as string}
            type="number"
            variant="filled"
          />
        </FormControl>
      )
    case 'custom-data-fields.number-drop-down':
      const min = findPropertyValue(field.properties, 'min') || 0
      const max = findPropertyValue(field.properties, 'max') || 100
      const options = _.range(min, max)
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <Select focusBorderColor="accent.500" size="xs" variant="filled" fontSize="xs">
            {options?.length > 0 &&
              options?.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
          </Select>
        </FormControl>
      )
    case 'custom-data-fields.number-range':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <RangeSlider aria-label={['min', 'max']} colorScheme="pink" defaultValue={[10, 30]}>
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
        </FormControl>
      )
    case 'custom-data-fields.percentage':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <InputGroup>
            <Input
              name={field.display_title}
              placeholder={field.properties[0]?.label as string}
              type="number"
              variant="filled"
              max={100}
              min={0}
            />

            <InputRightAddon>%</InputRightAddon>
          </InputGroup>
        </FormControl>
      )
    case 'custom-data-fields.data-list':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <InputGroup>
            <Input name={field.display_title} variant="filled" type="text" max={100} min={0} />

            <InputRightElement>
              <VscArrowDown color="blue.100" />
            </InputRightElement>
          </InputGroup>
        </FormControl>
      )
    case 'custom-data-fields.date-time-field':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>

          <InputGroup>
            <InputLeftAddon>
              <VscCalendar />
            </InputLeftAddon>
            <Input
              name={field.display_title}
              placeholder={field.properties[0]?.label as string}
              type="number"
              variant="filled"
              max={100}
              min={0}
            />
            <InputRightAddon>
              <FaClock />
            </InputRightAddon>
          </InputGroup>
        </FormControl>
      )
    case 'custom-data-fields.image-field':
      return (
        <FormControl mb={3}>
          <FormLabel fontSize="x-small">
            {field.properties[0]?.label} {handleRequired(field)}
          </FormLabel>
          <HStack>
            <BoxButton title="Open Camera" icon={FaCamera} />
            <BoxButton title="Open Gallery" icon={RiGalleryFill} />
          </HStack>
        </FormControl>
      )
    default:
      return <></>
  }
}

export default InputRenderer
