import { HStack, Checkbox, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { AlertModal } from 'components'
import React from 'react'
import { VscAdd, VscClose } from 'react-icons/vsc'

import {
  Enum_Datalistoption_Status,
  DataListOptionEntity
} from '../../../../../../../generated/graphql'

type ListOptionItemProps = {
  data: DataListOptionEntity
  isActive: boolean
  onChange: () => void
  deactivateListOption: (id: string) => Promise<void>
  activateListOption: (id: string) => Promise<void>
}

const ListOptionItem = React.forwardRef<HTMLDivElement, ListOptionItemProps>(
  ({ data, deactivateListOption, isActive, onChange, activateListOption }, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isActiveOpen, onOpen: onActiveOpen, onClose: onActiveClose } = useDisclosure()

    return (
      <HStack
        rounded="sm"
        cursor="pointer"
        w="100%"
        ref={ref}
        bg={isActive ? 'green.50' : 'background.100'}
        px={4}
        _before={{
          content: '""',
          boxSize: 1.5,
          display: 'inline-flex',
          mr: 2,
          borderRadius: 'full',
          bg:
            data?.attributes?.status === Enum_Datalistoption_Status.Active ? `green.300` : `red.200`
        }}
        py={2}
        key={data?.id}
      >
        <AlertModal
          onConfirm={() => deactivateListOption(data?.id || '')}
          isOpen={isOpen}
          title="Archive List"
          confirmButtonText="Archive"
          onClose={onClose}
        >{`Deactivate ${data?.attributes?.value}`}</AlertModal>
        <AlertModal
          onConfirm={() => activateListOption(data?.id || '')}
          isOpen={isActiveOpen}
          title="Activate"
          onClose={onActiveClose}
        >{`Activate ${data?.attributes?.value}`}</AlertModal>
        <Checkbox
          w="100%"
          size="lg"
          display="flex"
          isChecked={isActive}
          onChange={onChange}
          colorScheme="green"
          borderRadius="md"
          cursor="pointer"
        >
          <Text fontSize="xs" fontWeight="semibold">
            {data?.attributes?.value}
          </Text>
        </Checkbox>
        {data?.attributes?.status === Enum_Datalistoption_Status.Active ? (
          <IconButton
            aria-label="Delete"
            colorScheme="red"
            size="xs"
            onClick={onOpen}
            icon={<VscClose />}
          />
        ) : (
          <IconButton aria-label="Add" onClick={onActiveOpen} icon={<VscAdd />} />
        )}
      </HStack>
    )
  }
)

export default ListOptionItem
