import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import MotionFlex from '../../utils/MotionFlex'

export const SpacerLine = styled(MotionFlex)`
  position: absolute;
  right: 0;
  height: 2px;
`

export const Square = styled(Flex)`
  width: 40px;
  height: 40px;
  position: relative;
  align-items: center;
  justify-content: center;
`

export const OuterCircle = styled(Flex)`
  width: 38px;
  height: 38px;
  border-radius: 19px;
  align-items: center;
  justify-content: center;
`

export const InnerCircle = styled(Flex)`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  align-items: center;
  justify-content: center;
`
