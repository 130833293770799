import { Box, Text, BoxProps } from '@chakra-ui/react'
import { DateAndTime } from 'components'
import React from 'react'

type EvidenceTimeProps = {
  dateAndTime: any
  title: string
  isOnline?: boolean
} & BoxProps

const EvidenceTime: React.FC<EvidenceTimeProps> = ({ dateAndTime, title, isOnline, ...rest }) => {
  return (
    <Box my={3} fontWeight="semibold" {...rest}>
      <Text fontSize="x-small" textTransform="uppercase" mb={2}>
        {title}
      </Text>

      <DateAndTime dateAndTime={dateAndTime} isOnline={isOnline} />
    </Box>
  )
}

export default EvidenceTime
