/* eslint-disable @typescript-eslint/no-use-before-define */
import { isEqual } from 'lodash'
import * as React from 'react'
import { STRAPI_USER_STORAGE_KEY } from '../../constants'
import { Maybe, UsersPermissionsUser, UsersPermissionsUserEntity } from '../../generated/graphql'
import { useBrowserStorage } from '../../hooks'
import strapiHelpers, { StrapiLoginPayload } from '../../utils/strapiHelpers'
import posthog from 'posthog-js'

type AuthProviderProps = {
  logout: () => void
  isAuthenticating: boolean
  isAuthenticated: boolean
  user: UsersPermissionsUserEntity
  register: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    companyName: string
  ) => Promise<void>
  login: (identifier: string, password: string, rememberMe: boolean) => Promise<void>
  setUser: React.Dispatch<React.SetStateAction<UsersPermissionsUserEntity | undefined>>
  analyticsAuthInfo: {
    getAuthUrl: (url: string | undefined) => Promise<string>
    baseUrl: Maybe<string> | undefined
  }
}

const AuthContext = React.createContext<Partial<AuthProviderProps>>({})

export const useAuthContext = () => React.useContext(AuthContext)

type UserStorage = StrapiLoginPayload | null

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [localUser, setLocalUser, removeLocalUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'local'
  )

  const [sessionUser, setSessionUser, removeSessionUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'session'
  )

  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)

  const [user, setUser] = React.useState(
    sessionUser
      ? ({
          id: sessionUser?.user.id,
          attributes: sessionUser?.user as UsersPermissionsUser
        } as UsersPermissionsUserEntity)
      : localUser
      ? ({
          id: localUser?.user.id,
          attributes: localUser?.user as UsersPermissionsUser
        } as UsersPermissionsUserEntity)
      : undefined
  )

  const persistUser = (data: StrapiLoginPayload, rememberMe?: boolean) => {
    rememberMe ? setLocalUser(data) : setSessionUser(data)
    setUser({ id: data?.user.id, attributes: data.user as UsersPermissionsUser })
  }

  const logout = async () => {
    removeLocalUser()
    removeSessionUser()
    setIsAuthenticated(false)
    setUser(undefined)
    posthog.reset()
  }
  React.useEffect(() => {
    if (user?.attributes) {
      /**
       * This is where we'd add more user properties to the identify call
       * if we need to track more user properties in PostHog
       * However, historical events will not be updated with these new properties
       */

      posthog.identify(user?.attributes.email as string, {
        ...user.attributes
      })
      setIsAuthenticated(true)
      if (localUser?.user && !isEqual(localUser?.user, user)) {
        setLocalUser({ jwt: localUser.jwt, user })
      }
      if (sessionUser?.user && !isEqual(sessionUser?.user, user)) {
        setSessionUser({
          jwt: sessionUser.jwt,
          user: user?.attributes as UsersPermissionsUserEntity
        })
      }
    }
    setIsAuthenticating(false)
    // eslint-disable-next-line
  }, [user])

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const { data } = await strapiHelpers.login(email, password)
      persistUser(data, rememberMe)
    } catch (error) {
      throw error
    }
  }

  const register = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    companyName: string
  ) => {
    try {
      const { data } = await strapiHelpers.register(
        firstName,
        lastName,
        email,
        password,
        companyName
      )
      persistUser(data)
    } catch (error) {
      throw error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        logout,
        isAuthenticated,
        isAuthenticating,
        user,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
