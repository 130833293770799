import { Flex, Box } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import styled from 'styled-components'
import { InvoicePayload } from '../../../../../generated/graphql'
import { Text } from '../../../../../typography'

const StyledDownloadLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export type InvoiceRowItemProps = InvoicePayload

const InvoiceRowItem: React.FC<InvoiceRowItemProps> = ({
  ending_balance,
  lines,
  created,
  invoice_pdf,
  currency,
  number
}) => {
  return (
    <Flex
      py={3}
      borderBottom="1px solid rgba(0, 0, 0, 0.2)"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text>{`#${number}`}</Text>
      <Text>
        {ending_balance !== null && ending_balance !== undefined
          ? ending_balance.toLocaleString('en-US', {
              currency: currency || 'usd',
              style: 'currency'
            })
          : ''}
      </Text>
      <Text>
        {lines
          ? `${
              lines.data[0].price.recurring === 'year'
                ? 'Yearly Subscription'
                : 'Monthly Subsription'
            }`
          : ''}
      </Text>
      <Text>{moment(created).format('DD/MM/YYYY')}</Text>
      {invoice_pdf && (
        <StyledDownloadLink href={invoice_pdf} download>
          <Box as={AiOutlineDownload} p={0} m={0} size={30} _hover={{ cursor: 'pointer' }} />
        </StyledDownloadLink>
      )}
    </Flex>
  )
}

export default InvoiceRowItem
