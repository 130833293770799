import { HStack, ListIcon, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { VscArrowRight } from 'react-icons/vsc'

const ICON_SIZE = 34

type StepListItemProps = {
  completed?: boolean
  isCurent?: boolean
  title: string
}

const StepListItem: React.FC<StepListItemProps> = ({ completed, title, isCurent }) => {
  return (
    <ListItem
      position="relative"
      _after={{
        ...(completed
          ? {
              content: '""',
              position: 'absolute',
              border: '1px solid',
              borderColor: 'green.200',
              height: 5,
              top: '100%',
              left: 4
            }
          : isCurent
          ? {}
          : {
              content: '""',
              position: 'absolute',
              border: '1px solid',
              borderColor: 'gray.200',
              height: 5,
              bottom: '100%',
              left: 4
            })
      }}
    >
      {completed ? (
        <HStack>
          <ListIcon
            as={MdCheckCircle}
            fontSize={ICON_SIZE}
            color="green.400"
            border="2px solid"
            borderColor="green.200"
            rounded="full"
            p={1}
          />
          <Text as="s">{title}</Text>
        </HStack>
      ) : (
        <HStack>
          <ListIcon
            as={VscArrowRight}
            fontSize={ICON_SIZE}
            color={isCurent ? 'green.400' : 'gray.400'}
            border="2px solid"
            borderColor={isCurent ? 'green.400' : 'gray.400'}
            rounded="full"
            p={2}
          />
          <Text fontWeight={isCurent ? 'bold' : 'normal'}>{title}</Text>
        </HStack>
      )}
    </ListItem>
  )
}

StepListItem.defaultProps = {
  completed: false,
  isCurent: false
}

export default StepListItem
