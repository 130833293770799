import { Center, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { ArrowLeft } from 'react-feather'
import { useHistory } from 'react-router-dom'

type BackButtonProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  title?: string
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, title }) => {
  const history = useHistory()
  return (
    <Center cursor="pointer" onClick={onClick ? onClick : () => history.goBack()}>
      <Icon mr={4} color="green.400" fontSize={18} as={ArrowLeft} />
      <Text fontWeight="semibold" color="gray.600">
        {title}
      </Text>
    </Center>
  )
}
BackButton.defaultProps = {
  title: 'Go Back'
}
export default BackButton
