import { VStack, Text, Center, Spinner, Heading, Badge, SimpleGrid } from '@chakra-ui/react'
import { EmptyListHandler } from 'components'
import { ComponentTrainingVideoTrainingVideo } from 'generated/graphql'
import React from 'react'

type TrainingVideosProps = {
  videos: ComponentTrainingVideoTrainingVideo[]
  loading: boolean
  isResult?: boolean
}

const TrainingVideos: React.FC<TrainingVideosProps> = ({ videos, loading, isResult }) => {
  const groupedVideos = videos.reduce((acc: any, video) => {
    const key = video.category || ''
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(video)
    return acc
  }, {})

  if (loading) {
    return (
      <Center>
        <Spinner colorScheme="green" />
      </Center>
    )
  }
  return videos?.length ? (
    <VStack flex={1} spacing={8} alignItems="flex-start">
      {Object.keys(groupedVideos).map((category) => (
        <>
          <Heading mb={4} size="md" mt={8}>
            {category.replace(/_/g, ' ')}
          </Heading>

          <SimpleGrid
            columns={{ base: 1, md: isResult ? 1 : 2 }}
            spacing={8}
            w="100%"
            gap={16}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {groupedVideos[category].map((video: any) => (
              <VStack
                key={video?.id}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                bg="background.50"
                borderRadius="md"
              >
                <iframe
                  width="100%"
                  height="345"
                  src={video?.url || ''}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <VStack spacing={4} w="100%" alignItems="flex-start" p={6}>
                  <VStack alignItems="flex-start">
                    <Heading fontSize="md">{video?.title}</Heading>
                    <Text fontSize="sm">{video?.description}</Text>
                  </VStack>
                  <Badge colorScheme="green" fontSize="xx-small">
                    {video?.category}
                  </Badge>
                </VStack>
              </VStack>
            ))}
          </SimpleGrid>
        </>
      ))}
    </VStack>
  ) : (
    <EmptyListHandler
      title="No Training Videos"
      subTitle="There are no training videos available at this time."
    />
  )
}

export default TrainingVideos
