import { Flex, Heading, Text, Box } from '@chakra-ui/react'
import React from 'react'

type SettingsWrapperProps = {
  heading: string
  subHeading?: string
  leftHeader?: React.ReactNode
}

const SettingsWrapper: React.FC<SettingsWrapperProps> = ({
  heading,
  leftHeader,
  subHeading,
  children
}) => {
  return (
    <Flex flexDirection="column">
      <Flex mb={6} justify="space-between" align="center">
        <Heading size="lg">{heading}</Heading>
        <Box>{leftHeader}</Box>
      </Flex>
      <Text my={2} fontSize="sm">
        {subHeading}
      </Text>
      {children}
    </Flex>
  )
}

export default SettingsWrapper
