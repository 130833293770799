export const ADD_DATA_COLLECTOR_TO_PROJECT = 'add-data-collector-to-project'
export const REMOVE_DATA_COLLECTOR_TO_PROJECT = 'remove-data-collector-to-project'
export const JOIN = 'join'
export const JOINED = 'joined'
export const TASK_TYPE_UPDATED = 'task-type-updated'
export const TASK_CREATED = 'task-created'
export const TASK_UPDATED = 'task-updated'
export const EVIDENCE_UPDATED = 'evidence-updated'
export const EVIDENCE_CREATED = 'evidence-created'
export const CONNECT = 'connect'
export const LEAVE = 'leave'
