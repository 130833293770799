import { Container, Center, Image, FlexProps, VStack, Box, IconButton } from '@chakra-ui/react'
import { BaotreeLogo, Nav } from 'components'
import SliderAnimator from 'components/UX/SliderAnimator'
import { PageWrap } from 'layouts'
import React from 'react'
import { VscArrowLeft } from 'react-icons/vsc'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { images } from 'theme'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/thumbs/thumbs.min.css'
import 'swiper/components/pagination/pagination.min.css'

// import Swiper core and required modules
import SwiperCore, { Thumbs, EffectFade, Pagination, Autoplay } from 'swiper/core'
import { MotionProps } from 'framer-motion'

SwiperCore.use([Pagination, Thumbs, EffectFade, Autoplay])

const slideImages = [
  {
    text: '',
    image: images.baotreeHomeBg
  },
  {
    text: '',
    image: images.baotreeHomeBg2
  },
  {
    text: '',
    image: images.baotreeHomeBg3
  },
  {
    text: '',
    image: images.baotreeHomeBg4
  },
  {
    text: '',
    image: images.baotreeHomeBg5
  },
  {
    text: '',
    image: images.baotreeHomeBg6
  },
  {
    text: '',
    image: images.baotreeHomeBg7
  },
  {
    text: '',
    image: images.baotreeHomeBg8
  }
]

type FormWrapperProps = {
  title: string
  image?: any
  withBack?: boolean
  slideShowStyle?: FlexProps & MotionProps
} & FlexProps

const FormWrapper: React.FC<FormWrapperProps> = ({
  children,
  title,
  image,
  withBack,
  slideShowStyle,
  ...rest
}) => {
  const [thumbsSwiper] = React.useState<SwiperCore | null | undefined>(null)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  return (
    <PageWrap
      align="center"
      bg="gray.50"
      pt={0}
      title={title}
      justify="center"
      display="flex"
      {...rest}
    >
      <BaotreeLogo />
      <Nav />
      <Container maxW="container.lg">
        <Center w="100%" flex={1} flexDirection={isTabletOrMobile ? 'column' : 'row'}>
          <SliderAnimator flex={1}>
            <VStack width="100%">
              {withBack && (
                <Box mb={16}>
                  <Link to="/login">
                    <IconButton
                      size="lg"
                      colorScheme="green"
                      borderRadius="full"
                      aria-label="Search database"
                      icon={<VscArrowLeft />}
                    />
                  </Link>
                </Box>
              )}
              {children}
            </VStack>
          </SliderAnimator>

          <SliderAnimator
            flex={1.5}
            maxW="40rem"
            variants={{
              hidden: {
                x: '350px'
              }
            }}
            {...slideShowStyle}
          >
            <Box w="90%" mx="auto">
              <Swiper
                slidesPerView={1}
                autoHeight
                spaceBetween={10}
                effect="fade"
                autoplay={{
                  delay: 5000,
                  waitForTransition: true,
                  disableOnInteraction: false
                }}
                pagination={{
                  clickable: true,
                  dynamicBullets: true
                }}
                fadeEffect={{ crossFade: true }}
                thumbs={{ swiper: thumbsSwiper }}
                className="cardSwiper"
              >
                {slideImages.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <Image
                      src={slide.image}
                      objectFit="contain"
                      width="100%"
                      height="50vh"
                      alt="slide"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </SliderAnimator>
        </Center>
      </Container>
    </PageWrap>
  )
}
FormWrapper.defaultProps = {
  image: images.baotreeHomeBg
}
export default FormWrapper
