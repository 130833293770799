import { Center, Box } from '@chakra-ui/react'
import { CustomTooltip } from 'components'
import React from 'react'

import { VscChevronDown } from 'react-icons/vsc'

type MoreContentsLoaderProps = {
  loadMore: () => void
}

const MoreContentsLoader: React.FC<MoreContentsLoaderProps> = ({ loadMore }) => {
  return (
    <Center
      bg={`linear-gradient(to top, white, transparent)`}
      w="100%"
      zIndex={23}
      position="absolute"
      bottom={0}
      left={0}
    >
      <CustomTooltip label="Load more">
        <Box
          rounded="full"
          onClick={loadMore}
          cursor="pointer"
          transform="translateY(-20%)"
          bg="white"
          shadow="lg"
          p={3}
        >
          <VscChevronDown />
        </Box>
      </CustomTooltip>
    </Center>
  )
}

export default MoreContentsLoader
