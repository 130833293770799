import {
  Flex,
  ListItem,
  ListIcon,
  Center,
  theme,
  HStack,
  Text,
  Image,
  Box,
  List,
  SimpleGrid
} from '@chakra-ui/react'
import { StatusBadge, DateAndTime } from 'components'
import { EvidenceEntity, UploadFileEntity } from 'generated/graphql'
import Maybe from 'graphql/tsutils/Maybe'
import React from 'react'

import { FaEnvelope } from 'react-icons/fa'
import { MdPhone, MdCheckCircle } from 'react-icons/md'
import {
  VscMenu,
  VscCircleFilled,
  VscArrowRight,
  VscArrowLeft,
  VscStarFull,
  VscStarEmpty
} from 'react-icons/vsc'
import Rating from 'react-rating'
import { getImageByFormat, parseDot } from 'utils'

export type DataFieldGeneratorProps = {
  evidence: Maybe<EvidenceEntity> | undefined
  setCurrentImageShowing: React.Dispatch<React.SetStateAction<UploadFileEntity[]>>
  setCurrentImageIndex: React.Dispatch<React.SetStateAction<number>>
  onShowLightbox: () => void
}

const DataFieldGenerator: React.FC<DataFieldGeneratorProps> = ({
  evidence,
  setCurrentImageShowing,
  setCurrentImageIndex,
  onShowLightbox
}) => {
  return (
    <SimpleGrid mt={6} fontSize="xs" columns={2} spacing={10} gap={2}>
      {evidence?.attributes?.custom_data_fields?.map((field, idx) =>
        field?.__typename === 'ComponentCustomDataFieldsText' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <Flex p={2} align="center">
              <VscMenu />
              <Text ml={2}>{field?.text_value}</Text>
            </Flex>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsPhoneNumberField' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <Flex p={2} align="center">
              <MdPhone />
              <Text ml={2}>{field?.phoneNumber}</Text>
            </Flex>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsEmailField' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <Flex p={2} align="center">
              <FaEnvelope />
              <Text ml={2}>{field?.email}</Text>
            </Flex>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsRadio' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <List>
              {field.option?.map((opt, idx) => (
                <ListItem key={idx} p={2} mx={2}>
                  <ListIcon as={VscCircleFilled} />
                  {opt?.option}
                </ListItem>
              ))}
            </List>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsCheckbox' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field.label || 'no label')}
            </Text>

            <List>
              {field?.option?.map((opt, idx) => (
                <ListItem key={idx} p={2} mx={2}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  {opt?.option}
                </ListItem>
              ))}
            </List>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsSelect' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>
            <List>
              {field?.option?.map((opt, idx) => (
                <ListItem key={idx} p={2} mx={2}>
                  <ListIcon as={VscArrowRight} color="green.500" />
                  {opt?.option}
                </ListItem>
              ))}
            </List>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsMetricsSpecific' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <Flex align="center">
              <Text>
                {field?.number_value} {field.metric}
              </Text>
            </Flex>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsNumberDropDown' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <Text>{field?.value}</Text>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsNumberField' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <Text>{field?.number_field_value}</Text>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsNumberRange' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <StatusBadge colorScheme="gray" w="fit-content" key={idx} p={2} mx={2}>
              <Center>
                <Text mx={2}>{field?.min}</Text>
                <VscArrowLeft />
                <VscArrowRight />
                <Text mx={2}>{field?.max}</Text>
              </Center>
            </StatusBadge>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsPercentage' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>
            <Text>{field?.value} %</Text>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsRating' ? (
          <Box key={idx}>
            <Text color="gray.500" mb={2} key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>
            <Rating
              initialRating={field.rating || 0}
              stop={field.number_of_stars || 5}
              fullSymbol={<VscStarFull color={theme.colors.yellow[400]} size={22} />}
              emptySymbol={<VscStarEmpty size={22} />}
              quiet
              readonly
            />
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsDateTimeField' ? (
          <Box key={idx}>
            <Text color="gray.500" mb={2} key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>
            <DateAndTime dateAndTime={field?.datetime} />
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsDataList' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field.label || 'no label')}
            </Text>

            <List>
              {field?.option?.map((opt, idx) => (
                <ListItem key={idx} p={2} mx={2}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  {opt?.option}
                </ListItem>
              ))}
            </List>
          </Box>
        ) : field?.__typename === 'ComponentCustomDataFieldsImageField' ? (
          <Box key={idx}>
            <Text color="gray.500" key={idx} fontSize="xs">
              {parseDot(field?.label || 'no label')}
            </Text>

            <HStack my={4}>
              {field.images?.data.length ? (
                field.images?.data.map((img, idx) => (
                  <Image
                    key={idx}
                    cursor="pointer"
                    objectFit="cover"
                    onClick={() => {
                      setCurrentImageShowing(field.images?.data as UploadFileEntity[])
                      setCurrentImageIndex(idx)
                      onShowLightbox()
                    }}
                    rounded="2xl"
                    boxSize={10}
                    src={getImageByFormat(img)}
                  />
                ))
              ) : (
                <Text>No Image</Text>
              )}
            </HStack>
          </Box>
        ) : null
      )}
    </SimpleGrid>
  )
}

export default DataFieldGenerator
