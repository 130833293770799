import React from 'react'
import { IoMdHelpCircle } from 'react-icons/io'
import { IconButton, Square } from '@chakra-ui/react'

const HelpPopoverTrigger: React.ReactNode = (
  <Square mx={3} size="1.5rem">
    <IconButton
      aria-label="help"
      as={IoMdHelpCircle}
      cursor="pointer"
      size="xs"
      variant="ghost"
      color="gray.400"
      rounded="full"
    />
  </Square>
)

export default HelpPopoverTrigger
