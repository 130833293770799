/* eslint-disable react/no-unescaped-entities */
import { HStack, Image, Spinner, Text } from '@chakra-ui/react'
import { ApolloQueryResult } from 'apollo-boost'
import { PrimaryButton } from 'components/UI/Buttons'
import { MeQuery } from 'generated/graphql'
import React from 'react'
import strapiHelpers from 'utils/strapiHelpers'

import posthog from 'posthog-js'
import { images } from 'theme'

const WAIT_TIME = 35

export type VerifyEmailProps = {
  meData: MeQuery | undefined
  refetchMeData: any
}

const VerifyEmail: React.FC<{
  meData: MeQuery | undefined
  refetchMeData: any
}> = (props: VerifyEmailProps) => {
  const [verificationInProgress, setVerificationInProgress] = React.useState(false)
  const [verificationComplete, setVerificationComplete] = React.useState(false)

  const [allowResend, setAllowResend] = React.useState(false)

  const interval = React.useRef<NodeJS.Timeout>()
  const [timer, setTimer] = React.useState(WAIT_TIME)

  React.useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  const {
    meData,
    refetchMeData
  }: { meData: MeQuery | undefined; refetchMeData: () => Promise<ApolloQueryResult<MeQuery>> } =
    props
  React.useEffect(() => {
    if (meData?.myUser?.attributes) {
      if ((meData.myUser.attributes as any)?.onboardingInformation?.emailVerified) {
        setVerificationInProgress(false)
        setVerificationComplete(true)

        // clear the interval
        if (interval.current) {
          clearInterval(interval.current)
        }
      }
    }
  }, [meData])

  const sendVerification = () => {
    setVerificationInProgress(true)
    const sendEmail = async (userId: number) => {
      setAllowResend(false)
      setVerificationInProgress(true)
      await strapiHelpers.sendVerificationEmail(userId)

      // wait 35 seconds to prevent spam resends
      setTimeout(() => {
        setAllowResend(true)
        setTimer(WAIT_TIME)
      }, 35000)
    }

    if (meData?.myUser?.id) {
      sendEmail(parseInt(meData.myUser.id))
    }

    interval.current = setInterval(() => {
      refetchMeData()
    }, 5000)
  }

  return (
    <>
      {verificationInProgress && (
        <>
          <Text>
            Your email address is currently being verified. Please check your inbox for a
            verification link and click on it to confirm your email address. Once you've completed
            this step, please return to continue.
          </Text>
          <HStack>
            <Image src={images.verifyEmail} boxSize={180} alt="Email Verification" />
            <Text fontWeight="bold" color="gray.700" fontSize="xs">
              <Spinner color="green.400" size="xs" /> We've sent you an email, go check your inbox
            </Text>
          </HStack>

          {!allowResend && <Text fontSize="sm">You can resend the email in {timer} seconds.</Text>}
        </>
      )}
      {allowResend && !verificationComplete && (
        <>
          <Text fontSize="sm">
            Didn't receive the email? Click the button below to resend the verification email.
          </Text>
          <PrimaryButton
            mt={4}
            alignSelf="center"
            width="70%"
            marginTop={'2vh'}
            bottom={0}
            variant="lg"
            onClick={() => {
              posthog.capture('resend_verification_email')
              sendVerification()
            }}
          >
            Resend verification email
          </PrimaryButton>
        </>
      )}
      {!verificationInProgress && !verificationComplete && (
        <>
          <Text>
            Click the button below to send a verification email to{' '}
            {meData?.myUser?.attributes?.email}. <br />
            Once you've received the verification email, click the link included. You can then come
            back to this page.
          </Text>
          <PrimaryButton
            mt={4}
            alignSelf="center"
            width="70%"
            marginTop={'2vh'}
            bottom={0}
            variant="lg"
            onClick={() => {
              posthog.capture('send_verification_email')
              sendVerification()
            }}
          >
            Send verification email
          </PrimaryButton>
        </>
      )}
      {verificationComplete && (
        <Text>
          We've successfully verified your email address! You can now proceed to the next step.
        </Text>
      )}
    </>
  )
}

export default VerifyEmail
