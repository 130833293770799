import React from 'react'
import { Button, ButtonProps, StackProps, VStack, Text, Icon } from '@chakra-ui/react'

type OutlineButtonProps = ButtonProps

export const OutlineButton: React.FC<OutlineButtonProps> = ({ children, colorScheme, ...rest }) => {
  return (
    <Button
      height={12}
      rounded="lg"
      backgroundColor="white"
      border="2px solid"
      zIndex={48}
      borderColor={`${colorScheme ? colorScheme : 'brand'}.400`}
      color={`${colorScheme ? colorScheme : 'brand'}.400`}
      _hover={{
        backgroundColor: `${colorScheme ? colorScheme : 'brand'}.400`,
        color: 'white',
        borderColor: 'transparent'
      }}
      fontSize="xs"
      {...rest}
    >
      {children}
    </Button>
  )
}

type PrimaryButtonProps = ButtonProps

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({ children, colorScheme, ...rest }) => {
  return (
    <Button
      height={12}
      rounded="lg"
      backgroundColor={`${colorScheme ? colorScheme : 'brand'}.400`}
      border="2px solid"
      zIndex={48}
      borderColor="transparent"
      color="white"
      _hover={{
        backgroundColor: 'white',
        color: `${colorScheme ? colorScheme : 'brand'}.400`,
        borderColor: `${colorScheme ? colorScheme : 'brand'}.400`
      }}
      fontSize="xs"
      {...rest}
    >
      {children}
    </Button>
  )
}

type SecondaryButtonProps = {} & ButtonProps

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      height={12}
      rounded="2xl"
      backgroundColor="gray.100"
      border="1px solid"
      zIndex={48}
      borderColor="gray.200"
      color="gray.700"
      _hover={{
        backgroundColor: 'gray.50',
        borderColor: 'gray.200'
      }}
      fontSize="xs"
      {...rest}
    >
      {children}
    </Button>
  )
}

export const BoxButton: React.FC<StackProps & { title: string; icon?: any }> = ({
  children,
  title,
  icon,
  ...rest
}) => {
  return (
    <VStack
      flex={1}
      rounded="md"
      px={1}
      py={3}
      transition="all 0.2s"
      cursor="pointer"
      _hover={{ bg: 'gray.200' }}
      bg="gray.100"
      spacing={1}
      {...rest}
    >
      <Icon as={icon} fontSize={19} color="green.400" />
      <Text>
        <Text fontSize="x-small" as="span">
          {title}
        </Text>
      </Text>
    </VStack>
  )
}
