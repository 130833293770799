import { IP_API_URL } from '../../constants'
import { useState, useEffect } from 'react'
import { fetch } from 'utils'
import { IPField, IPResponse } from './types'

/**
 * useIPLookup uses https://ipapi.co/json/ to get the user's IP address and location information.
 * It then returns an object with the fields requested.
 * @param fields a set of fields to return, based on the enum RequiredFields
 * @return an object with the fields requested
 */
const useIPLookup = (requestedFields?: IPField[]) => {
  const [ipInformation, setIPInformation] = useState<IPResponse>({})

  useEffect(() => {
    const fetchIPInformation = async () => {
      try {
        const response = await fetch(IP_API_URL) // 30k free requests per month (~1k a day) - at scale, we'd need to pay, or find another service
        setIPInformation(response)
      } catch {
        // fail silently, and set the IP information to an empty object
        setIPInformation({})
      }
    }
    fetchIPInformation()
  }, [])

  const filterIPInformation = (ipInformation: IPResponse, requestedFields: IPField[]) => {
    const deduplicatedFields = requestedFields.filter(
      (field, index) => requestedFields.indexOf(field) === index
    )

    Object.keys(ipInformation).forEach((key: string) => {
      if (!deduplicatedFields.includes(key as IPField)) {
        delete ipInformation[key as IPField]
      }
    })

    return ipInformation
  }

  if (requestedFields) {
    return filterIPInformation(ipInformation, requestedFields)
  }

  return ipInformation
}

export default useIPLookup
