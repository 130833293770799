import { Box, Text, Link } from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'

export const dataCollectorsProfileHelperPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        Use the &apos;resend invite&apos; link if a Data Collector has not yet received an SMS to
        set up their account. If the issue persists, contact our Baotree support team.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const deactivateDataCollectorTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      Deactivating this user will remove their access to the system but will not delete the data
      they have already submitted to your dashboard. Record of their involvement with your
      organisation will remain.
    </Text>
  </Box>
)

export const activateDataCollectorTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      Activating this user will grant them access to the system. They will be able to log in and
      continue submitting data to your dashboard.
    </Text>
  </Box>
)
