import { Flex, Skeleton, SkeletonProps } from '@chakra-ui/react'
import React from 'react'
import { Activity } from 'react-feather'

type TableIconProps = {
  loading?: boolean
  position?: 'left' | 'right'
  icon?: React.ReactNode
} & Omit<SkeletonProps, 'position'>

const TableIcon: React.FC<TableIconProps> = ({ icon, loading, position, ...rest }) => {
  return (
    <Skeleton
      top={0}
      isLoaded={!loading}
      transform="translateY(-50%)"
      left={position === 'left' ? 14 : undefined}
      right={position === 'right' ? 14 : undefined}
      position="absolute"
      borderRadius="xl"
      {...rest}
    >
      <Flex
        direction="column"
        color="green.500"
        bg="white"
        p={5}
        shadow="md"
        rounded="2xl"
        align="center"
      >
        {icon}
      </Flex>
    </Skeleton>
  )
}

TableIcon.defaultProps = {
  icon: <Activity size={20} />,
  position: 'left'
}

export default TableIcon
