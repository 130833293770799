import React from 'react'
import { Box, Heading, Text, Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'
import { useAuthContext } from '../../../context/AuthProvider'
import { Card } from 'components'
import { CardProps } from 'components/UI/Card'
import { PrimaryButton, SecondaryButton } from '../../UI/Buttons/index'

interface GreetingsBanner extends CardProps {
  text: string
}

const GreetingsBanner: React.FC<GreetingsBanner> = ({ text, ...rest }) => {
  const history = useHistory()
  const { user } = useAuthContext()
  return (
    <Card {...rest}>
      <Flex mb={16}>
        <Box>
          <Heading as="h2" color="gray.700" my={3}>
            Hi {user?.attributes?.firstName || user?.attributes?.username}!
          </Heading>
          <Text fontSize="sm" color="gray.500" mb={3}>
            {text}
          </Text>

          <PrimaryButton
            leftIcon={<MdAdd />}
            my={4}
            minW="30%"
            onClick={() => {
              const to = '/auth/create-project'
              history.push(to)
            }}
          >
            Create project
          </PrimaryButton>
        </Box>
        <Box width="20%" p={6} textAlign="center">
          <Text fontSize="50px">
            <span role="img" aria-label="hello">
              👋
            </span>
          </Text>
        </Box>
      </Flex>
      <Box mt={4}>
        <Text mb={2} fontSize="sm">
          Need Help?
        </Text>
        <SecondaryButton
          onClick={() => {
            const to = '/auth/help'
            history.push(to)
          }}
        >
          Go to Help and Training
        </SecondaryButton>
      </Box>
    </Card>
  )
}

GreetingsBanner.defaultProps = {
  bg: 'transparent',
  boxShadow: 'none',
  p: 0,
  mb: 6,
  borderRadius: 'none'
}

export default GreetingsBanner
