import { Box, Text, Link } from '@chakra-ui/react'
import { HelpPopoverTrigger } from 'components'
import CustomPopover from 'components/UX/CustomPopover'
import React from 'react'

export const projectsTitleHelperPopover: React.ReactNode = (
  <CustomPopover customTrigger={HelpPopoverTrigger}>
    <Box px={4} py={6}>
      <Text>
        The <b>Projects</b> page is where you can <b>create, view</b> and <b>manage</b> all your{' '}
        <b>projects</b>. Your <b>projects</b> are where you <b>organise</b> your{' '}
        <b>Task Templates</b> for data collection. Adding a <b>Data Collector</b> to a{' '}
        <b>Project</b> will give them access to the <b>Tasks</b> within that <b>Project</b>. For
        more: Visit our <b>Help and Training</b> section.
      </Text>
      <br />
      <Text>
        For more, visit our{' '}
        <Text as="b">
          <Link mx={2} fontWeight="semibold" color="gray.400" href="/auth/help">
            help and training
          </Link>
        </Text>{' '}
        section.
      </Text>
    </Box>
  </CustomPopover>
)

export const createProjectTooltipContent = (
  <Box px={4} py={6}>
    <Text>
      <b>Use</b> this button to <b>create</b> a new project.
    </Text>
  </Box>
)
